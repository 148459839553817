import { notification } from 'antd';
import { list, findByFilters, tipoDocumento, polo } from '../../service';

export class TableController {
  constructor(props) {
    this.state = props.state;
  }

  async hadleInitData() {
    try {
      this.state.loading.setLoading(true);
      const result = await tipoDocumento();
      result.data.sort((a, b) => a.tipoDocumento > b.tipoDocumento)
      this.state.tipodocumento.setTipoDocumento(result.data);
      const resultPolo = await polo();
      this.state.polo.setPolo(resultPolo?.data?.slice(0, 10));
      const { data } = await list();
      this.state.data.setData(data);
      this.state.loading.setLoading(false);
    } catch (error) {
      return error;
    } finally {
      this.state.loading.setLoading(false);
    }
  }

  async handleSubmit(e) {
    try {
      this.state.loading.setLoading(true);
      const { data } = await findByFilters(e);
      this.state.data.setData(data);
    } catch (error) {
      const response = error.response;
      notification['error']({
        message: `Erro ao Listar filtros`,
        description:
          response?.data?.message || 'Por favor verificar com a equipe tecnica',
      });
    } finally {
      this.state.loading.setLoading(false);
    }
  }
}
