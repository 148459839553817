import api from '../api';
import Swal from 'sweetalert2';

export const fetchReports = async (usuarioPortalId) => {
  return await api.get('/relatorio/');
};

export const fetchReportsFilter = async (params) => {
  return await api.get('/relatorio/filter', { params: { ...params } });
};

export const fetchReport = async (params) => {
  return await api.get(`/relatorio/${params.id}`);
};

export const fetchReportFilters = async (params) => {
  return await api.get(`/relatorio/${params.id}/filtros`);
};

export const fetchProfiles = async () => {
  return await api.get('/gerenciamento/perfil/');
};

export const getFilter = async (params) => {
  return await api.get(
    `relatorio/${params.idRelatorio}/filtros/filtro/${params.idFiltro}/`
  );
};

export const getCategories = async () => await api.get('relatorio/categoria/');

export const updateFilter = async (params) => {
  return await api.put(`/relatorio/filtros/filtro/${params.id}`, params.body);
};

export const createFilter = async (params) => {
  return await api.post(`/relatorio/filtros/`, params.body);
};

export const createReport = async (params) => {
  return await api.post('/relatorio/', params.body);
};

export const updateReport = async (params) => {
  return await api.put(`/relatorio/${params.id}`, params.body);
};

export const listUser = async () => {
  return await api.get(`usuario/findUserByPerfil/1`)
}

export const getReportToADM = async (usuarioPortalId) => {
  return await api.get(`relatorio/findByUserId/${usuarioPortalId}`)
}

export const removeUser = async (usuarioPortalId, reportId) => {
  return await api.put(`relatorio/removeUser/${usuarioPortalId}/${reportId}`).then((result) => {
    Swal.fire({
      position: 'top-end',
      title: `removido com sucesso!`,
      icon: 'success',
      showConfirmButton: false,
      timer: 4000,
    })
    window.setTimeout(function(){ window.location.replace(`/admin/incluir-relatorio/${reportId}`) }, 3500);
  })
  .catch((error) => {{
      Swal.fire({
        position: 'top-end',
        title: error.response.data,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  });
}
