import React from 'react';
import { Content, LineButton } from './styles';
import { Button, Collapse } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useCommonQuestions } from './hook';
import moment from 'moment';

export const CommonQuestions = ({ palavraChave, admin }) => {
  const { faqData, loading, saveLogFAQ } = useCommonQuestions({ palavraChave });

  return (
    <>
      {!faqData?.length ? (
        <></>
      ) : (
        <Collapse style={{ width: '100%' }} onChange={saveLogFAQ}>
          {faqData
            .map((item) => (
              <Collapse.Panel header={item.questao} key={item.id}>
                <Content dangerouslySetInnerHTML={{ __html: item.respostaNovo }} />
                {admin && (
                  <>
                    <LineButton>
                    <Button href={`/admin/faq/${item.id}`}>Editar</Button>
                      {(item.dataExpiracaoRevisao !== undefined ?
                        <p>Data Expiração: {` ${moment(item.dataExpiracaoRevisao, 'DD-MM-YYYY').format('DD-MM-YYYY')}`}</p> : ''
                      )}
                    </LineButton>
                  </>
                )}
              </Collapse.Panel>
            ))}
        </Collapse>
      )}
      {loading && <LoadingOutlined />}
      {!loading && !faqData?.length ? 'Nenhum resultado encontrado.' : <></>}
    </>
  );
};
