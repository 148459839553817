import api from 'services/api';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { moment } from 'services/Gerais'

export const getPolo = async (userPortalID) => {
  return await api.get(`foca/dadosCadastro/${userPortalID}`);
};

export const getCheckins = async (userPortalID, perfilID) => {
  const isGestor = (perfilID == 376 || perfilID == 370 ? true : false)
  return await api.get(`foca?usuarioPortalId=${userPortalID}&isGestor=${isGestor}`);
}

export const getCheckinById = async (id) => {
  return await api.get(`foca/${id}`);
}

export const getFilter = async (searchFilter) => {
  return await api.get(`foca/filter?${searchFilter}`);
}

export const getFilterReport = async (searchFilter) => {
  return await api.get(`foca/filterReport?${searchFilter}`);
}

export const getUsersGestor = async (listPolosIds) => {
  return await api.get(`foca/buscaGestoresDosPolos?polosIds=${listPolosIds}`);
}

export const getExportXLSX = async (filters) => {
  return await api.post(`foca/exportXlsx`, filters);
}

export const exportPDF = async (relatorioConfig) => {
  await api.post(`relatorio/exportCsv`, relatorioConfig).then(resposta => {
    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Relatório gerado com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
    const BOM = '\uFEFF';
    const relatorioDados = BOM + resposta.data;
    const contentType = resposta.headers['content-type'];
    const filename = resposta.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
      .replace('"', '')
      .replace('"', '');
    const blob = new Blob([relatorioDados], {
      type: contentType,
    });
    if(relatorioDados.length == 351) {
      Swal.fire({
        position: 'top-end',
        title: `Sem dados!`,
        text: `Não foi encontrado nenhum dado na pesquisa`,
        icon: 'warning',
        showConfirmButton: false,
        timer: 10000,
      })
      return
    }
    saveAs(blob, `Relatório_FOCA_${moment().format('DD/MM/YYYY HH:mm:ss').replaceAll('/', '').replaceAll(' ', '_').replaceAll(':','')}.csv`);
  })
}

export const getFiltersReport = async (idReport) => {
  return await api.get(`relatorio/adm/${idReport}`);
}

export const getDadaReport = async () => {
  return await api.get(`foca/dadosRelatorio`);
}

export const sendEmail = async (file) => {
  return await api.post(`foca/actionsEmail`, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(response => {
    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Email enviado com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 3000,
    })
    window.location.reload()
  }).catch(error => {
    Swal.fire({
      title: 'Atenção!',
      text: error.response.data.message[0],
      icon: 'error',
    })
  });;
};

export const getImports = async (origem, filters) => {
  return await api.get(`importacaoArquivo/filters?origem=${origem}${filters}`)
}

export const getDataImport = async (id) => {
  return await api.get(`foca/migracaoGn?importacaoId=${id}`)
}

export const cancelImport = async (id) => {
  return await api.patch(`importacaoArquivo/cancelar/${id}`).then(response => {
    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Cancelamento de importação realizado com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    })
  }).catch(error => {
    Swal.fire({
      title: 'Atenção!',
      text: error.response.data.message[0],
      icon: 'error',
    })
  });
}

export const importMigration = async (params) => {
  return await api.post(`foca/migracaoGn`, params).then(response => {
    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Importação de migração feita com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 4000,
    })
  }).catch(error => {
    Swal.fire({
      title: 'Atenção!',
      text: error.response.data.message[0],
      icon: 'error',
    })
  });
}

export const sendReport = async (planosDeAcao) => {
  return await api.post(`foca/importPlanosAcoes`, planosDeAcao).then(response => {
    if(response.status === 200) {
      Swal.fire({
        position: 'top-end',
        title: `Sucesso!`,
        text: 'Importação dos planos de acessão feita com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
    } else if (response.status === 202) {
      Swal.fire({
        position: 'top-end',
        title: `Revisar dados!`,
        text: response.data.error,
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }).catch(error => {
    Swal.fire({
      title: 'Atenção!',
      text: error.response.data.message[0],
      icon: 'error',
    })
  });
}
