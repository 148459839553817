import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Col, Row, Card, Form } from 'antd';
import { changeComunicado } from 'store/actions/Administrativo/Comunicado/ComunicadoActions';

const { Meta } = Card;

const Marca = ({ form }) => {
  const { getFieldDecorator, setFieldsValue } = form;
  let { DadosComunicado, DadosIniciaisComunicado } = useSelector(
    state => state.Comunicado
  );
  const [checkedList, setCheckedList] = useState([]);
  const [plainOptions, setPlainOptions] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let arrPlainOptions = [];
    Object.keys(DadosIniciaisComunicado && DadosIniciaisComunicado.Marca).map(
      function(key) {
        arrPlainOptions.push(key);
        return key;
      }
    );
    setPlainOptions(arrPlainOptions);

    setCheckedList(
      DadosComunicado.lstComunicadoMarca.map(i => i.marca.id.toString())
    );

    if (DadosComunicado && DadosComunicado.lstComunicadoMarca.length > 0) {
      setFieldsValue({ marcas: [{}] });
    }
    // eslint-disable-next-line
  }, []);

  const onChange = checkedList => {
    setCheckedList(checkedList);
    setIndeterminate(
      !!checkedList.length && checkedList.length < plainOptions.length
    );

    setCheckAll(checkedList.length === plainOptions.length);
    setFieldsValue({ marcas: checkedList });
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          lstComunicadoMarca: setMarca(checkedList),
        },
      })
    );
  };

  const setMarca = arrMarca => {
    const arr = [];
    arrMarca.map(id => {
      arr.push({ marca: { id } });
      return id;
    });
    return arr;
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    if (e.target.checked) {
      setFieldsValue({ marcas: plainOptions });
      dispatch(
        changeComunicado({
          prop: 'DadosComunicado',
          value: {
            ...DadosComunicado,
            lstComunicadoMarca: setMarca(plainOptions),
          },
        })
      );
    } else {
      setFieldsValue({ marcas: [] });
      dispatch(
        changeComunicado({
          prop: 'DadosComunicado',
          value: {
            ...DadosComunicado,
            lstComunicadoMarca: [],
          },
        })
      );
    }
  };

  const CheckBoxMarcas = () => {
    let marcas = Object.entries(DadosIniciaisComunicado.Marca).sort((a, b) => (a[1] > b[1] ? 1 : -1));
    return (
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={onChange}
        value={checkedList}
      >
        <Row gutter={[8, 8]}>
          {marcas.map(function(key) {
            let image = '';
            try {
              image = require(`../../../../../../../img/marcas/${key[0]}.jpg`);
            } catch (err) {
              image = require(`../../../../../../../img/semfoto.jpg`);
            }
            return (
              <Col key={key[0]} span={6}>
                <Card
                  hoverable
                  key={`card-${key[0]}`}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                  cover={<img alt="example" src={image} style={{
                    width: '214px',
                    height: '120px'
                  }} />}
                >
                  <Meta
                    key={key[1]}
                    title={
                      <Checkbox value={key[0]} key={`marca-${key[1]}`}>
                        {key[1]}
                      </Checkbox>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    );
  };
  return (
    <div>
      <Form.Item label="Marcas" size="large">
        {getFieldDecorator('marcas', {
          rules: [
            {
              required: true,
              message: 'Por favor Selecione as marcas',
            },
          ],
        })(
          <>
            {plainOptions.length > 0 && <CheckBoxMarcas />}
          </>
        )}
      </Form.Item>
      {/* <div style={{ borderBottom: '1px solid #E9E9E9' }}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Selecionar todos
        </Checkbox>
      </div>
      {plainOptions.length > 0 && <CheckBoxMarcas />} */}
    </div>
  );
};

export default Marca;
