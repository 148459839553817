import React, { useState, useEffect } from 'react';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../services/Gerais';
import FilterForm from './Form';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';

const Cadastro = (props) => {
  const [tituloPagina, setTituloPagina] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [idFilter, setIdFilter] = useState('');
  const [idRelatorioFilter, setIdRelatorioFilter] = useState('');
  useEffect(
    () => {
      if (
        props &&
        props.match &&
        props.match.params &&
        props.match.params.idRelatorio &&
        props.match.params.id
      ) {
        setIdFilter(props.match.params.id);
        setIdRelatorioFilter(props.match.params.idRelatorio);
        setTituloPagina('Editar de Filtro');
      } else if (
        props &&
        props.match &&
        props.match.params &&
        props.match.params.idRelatorio
      ) {
        setTituloPagina('Cadastro de Filtro');
        setIdRelatorioFilter(props.match.params.idRelatorio);
      } else {
        setTituloPagina('Cadastro de Filtro');
      }
    },
    [props]
  );
  return (
    <>
      <Helmet
        key="TitleAdminAgendaCadastro"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- ' + tituloPagina,
          },
          {
            name: 'keywords',
            content: 'cadastro, comunicados, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAdminAgendaCadastro" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminAgendaCadastro" />
          <div className="column is-10 is-fullheight conteudoAdminPagina">
            <div className="columns">
              <div className="column">
                <Hero
                  key="heroAdminListaPortais"
                  pageName={`Administração - ${tituloPagina}`}
                  titulo={tituloPagina}
                />
              </div>
              <div className="columns botoesAcaoCadastro">
                <div className="column">
                  <div
                    className="button-wrapper"
                    id="components-dropdown-demo-dropdown-button"
                  >
                    <Button
                      size="large"
                      type="primary"
                      href={`/admin/filters/${idRelatorioFilter}`}
                    >
                      Voltar
                    </Button>
                  </div>
                </div>
              </div>
          </div>
          <FilterForm
            form={props.form}
            isEdit={isEdit}
            history={props.history}
            idFilter={idFilter}
            idRelatorio={idRelatorioFilter}
            history2={props.history}
          />
        </div>
      </section>
      <FooterAdmin key="FooterAdminUsuariosCadastro" />
    </>
  );
};

export default withRouter(Cadastro);
