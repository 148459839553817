import { useEffect, useState } from 'react';
import { fetchFaqCategories } from 'services/faq';

const useCategories = () => {
  const [faqData, setFaqData] = useState([]);
  const getData = async () => {
    try {
      const { status, data } = await fetchFaqCategories();
      if (status === 200) {
        setFaqData(data);
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return { faqData };
};

export { useCategories };
