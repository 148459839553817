import styled from 'styled-components';

const FieldBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export { FieldBox, ActionContainer };
