import React from "reactn";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { authProvider } from "../../services/authProvider";

// import logo from '../../img/logo_unopar.png'
import crp from "../../img/prisma_logo.png";
import avatar from "../../img/avatar-48.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class HeaderAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      modalAtiva: false,
      menu: "",
      isShowingMore: false,
      notAdm: false,
    };
    this.logout = this.logout.bind(this);
    this.modalPolosCall = this.modalPolosCall.bind(this);
    this.redirecionar = this.redirecionar.bind(this);
    this.onChangePolo = this.onChangePolo.bind(this);
    this.poloEscolhido = JSON.parse(localStorage.getItem("userDataCRP-Polo"));
    this.logInfo = JSON.parse(localStorage.getItem("userDataCRP"));
    this.listaPolosCRP = JSON.parse(localStorage.getItem("userDataCRP-Polos"));

    this.listaPolos = JSON.parse(
      localStorage.getItem("userDataCRP-ListaPolos")
    );
  }

  state = { isActiveHeaderAdmin: false };

  modalPolosCall() {
    this.setState({ modalAtiva: true });
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActiveHeaderAdmin: !prevState.isActiveHeaderAdmin
    }));
  };

  onChangePolo(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value > 0) {
      localStorage.setItem("userDataCRP-Polo", e.target.value);
      this.verificarPolo = localStorage.getItem("userDataCRP-Polo");

      this.listaPolosCRP
        .filter(
          poloCRPHeaderAdmin =>
            poloCRPHeaderAdmin["polo"]["id"] === parseInt(e.target.value)
        )
        .map(poloCRPHeaderAdmin => {
          // if(poloCRPHeaderAdmin['polo']['id'] === parseInt(e.target.value)){
          return localStorage.setItem(
            "userDataCRP",
            JSON.stringify(poloCRPHeaderAdmin)
          );
          // }
        });

      // {this.listaPolosCRP.map(poloCRPHeaderAdmin =>
      //     (poloCRPHeaderAdmin['polo']['id'] === e.target.value && localStorage.setItem('userDataCRP', JSON.stringify(poloCRPHeaderAdmin))
      // )}

      // localStorage.setItem('userDataCRP', JSON.stringify(responseJson[0]));

      this.setState({ modalAtiva: false });
      window.location.reload();
    }
  }

  logout(btnSair = false) {
    localStorage.setItem("userDataCRP-Login", "");
    localStorage.setItem("userDataCRP", "");
    localStorage.setItem("userDataCRP-Polos", "");
    localStorage.setItem("userDataCRP-Polo", "");
    localStorage.setItem('userDataCRP-Perfil', "");
    localStorage.clear();
    btnSair && authProvider.logout();
    this.setState({ redirect: true });
    return <Redirect to={"/login"} />;
  }

  componentWillMount() {
    var dataCRPHeaderAdmin = localStorage.getItem("userDataCRP-Polo");
    var apiHeaderAdmin = this.global.baseUrl + "usuario/loginPolo/";
    var perfil = localStorage.getItem('userDataCRP-Perfil');
    var perfilAdmin = ['1', '999', '503'] // admin e super admin

    this.setState({ isLoadingHeaderAdmin: true });
    if (dataCRPHeaderAdmin !== "" && this.logInfo !== null) {
      axios
        .get(apiHeaderAdmin + this.logInfo["id"], {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("userDataCRP-AuthTk")
          }
        })
        .then(resultHeaderAdmin =>
          this.setState({
            menu: resultHeaderAdmin.data,
            isLoadingHeaderAdmin: false
          })
        )
        .catch(errorHeaderAdmin =>
          this.setState({
            errorHeaderAdmin,
            isLoadingHeaderAdmin: false
          })
        );
    }

    if (!localStorage.getItem("userDataCRP")) {
      this.setState({ redirect: true });
    }

    if(!perfilAdmin.includes(perfil)) {
      this.setState({ notAdm: true });
    }
  }

  redirecionar() {
    const primeiroAcesso = JSON.parse(
      localStorage.getItem("userDataCRP-Login")
    );
    let bloqueio = false;

    if (primeiroAcesso) {
      if (!bloqueio) {
        // eslint-disable-next-line
        primeiroAcesso.map((v, ok) => {
          if (
            v["bloqTelaValidUser"] === "S" ||
            v["usuarioPortal"]["primeiroAcesso"] === "S"
          ) {
            bloqueio = true;
          }
        });
      }
      return bloqueio;
    }
  }

  render() {
    // se logout ou inacessivel
    if (this.state.redirect) {
      return <Redirect to={"/login"} />;
    }

    if (this.state.notAdm) {
      return <Redirect to={"/home"} />;
    }

    if (this.logInfo === null) {
      return <Redirect key="redirect-key-login" to={"/login"} />;
    }

    // se admin / gestor, para exibir painel administrativo
    let liberaAdmin =
      JSON.stringify(this.state.menu.perfil) === "[1]" ? true : false;

    return (
      <nav id="navbar" className="navbar navbarAdmin has-shadow is-spaced">
        <div className="container is-fullhd" data-tut={"menu_prisma"}>
          <div className="navbar-brand">
            <a
              className="navbar-item is-centered"
              href="/home"
              title={"Seja bem vindo ao Portal Prisma"}
            >
              {/* logo prisma */}
              <img
                className="logoKrotonCRP"
                src={crp}
                alt="Admin - Prisma - Kroton"
                height="52"
              />
              {/* logo marca */}
              {/*<img className="logoKroton" src={logo} alt="Admin - Unopar" height="52" />*/}
            </a>
            <div
              className={
                this.state.isActiveHeaderAdmin
                  ? "navbar-burger burger is-active"
                  : "navbar-burger burger"
              }
              data-target="navbarExampleTransparentExample"
              onClick={this.toggleNav}
            >
              <span></span> <span></span> <span></span>
            </div>
          </div>
          <div
            id="navbarBurger"
            className={
              this.state.isActiveHeaderAdmin
                ? "navbar-menu is-active"
                : "navbar-menu"
            }
          >
            <div className="navbar-end">
              <div
                className="acessoUsuario is-hidden-touch"
                data-tut={"menu_usuario"}
              >
                <div className="navbar-item has-dropdown is-hoverable">
                  <span
                    className="navbar-link is-hidden-touch"
                    title="Menu do usuário"
                  >
                    <img
                      className="imagemAvatar"
                      src={avatar}
                      width="30"
                      alt={this.logInfo.usuarioPortal.pessoa["nome"]}
                    />
                  </span>
                  <div className="navbar-dropdown is-right">
                    <a
                      className="navbar-item has-text-centered headerNome"
                      href="/home"
                      title={
                        "Bem vindo ao Painel administrativo, " +
                        this.logInfo.usuarioPortal.pessoa["nome"]
                      }
                    >
                      <FontAwesomeIcon icon={["far", "user-circle"]} /> &nbsp;{" "}
                      {this.logInfo.usuarioPortal.pessoa["nome"]}
                    </a>
                    <hr className="navbar-divider" />
                    {/* se admin / gestor */}
                    {liberaAdmin && (
                      <div>
                        <a
                          className="navbar-item"
                          href="/administracao"
                          title={"Acesse o painel administrativo"}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={["fas", "cog"]} />
                          </span>{" "}
                          &nbsp; Administração
                        </a>
                        <hr className="navbar-divider" />
                      </div>
                    )}
                    <span
                      className="navbar-item"
                      onClick={this.logout}
                      title={"Faça o logout do portal"}
                    >
                      <span className="icon">
                        <FontAwesomeIcon icon={["fas", "sign-out-alt"]} />
                      </span>{" "}
                      &nbsp; Sair
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default HeaderAdmin;
