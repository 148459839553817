import React from 'react';
import styles from './style.module.css';
import ComunicadosTable from '../ComunicadosTable';

const ComunicadosSection = props => {
  return (
    <section className={styles.container}>
      <ComunicadosTable history={props.history} />
    </section>
  );
};

export default ComunicadosSection;
