import { notification } from 'antd';
import { cofre, item, tipoDocumento, register, update } from '../service';

export class FormController {
  constructor(props) {
    this.state = props.state;
    this.history = props.history;
    this.form = props.form;
  }

  async inititalValue() {
    await this.initialValueCofre();
    await this.initialValueTypeDocument();
  }
  async initialValueCofre() {
    try {
      this.state.loading.setLoading(true);
      const { data } = await cofre();
      this.state.cofre.setCofre(data);
    } catch (error) {
      // const response = error.response
    } finally {
      this.state.loading.setLoading(false);
    }
  }

  async initialValueTypeDocument() {
    try {
      this.state.loading.setLoading(true);
      const { data } = await tipoDocumento();
      this.state.typeDocument.setTypeDocument(data);
    } catch (error) {
      // const response = error.response
    } finally {
      this.state.loading.setLoading(false);
    }
  }

  async get(id) {
    this.state.loading.setLoading(true);
    const {
      tipoDocumentoId,
      basePolos,
      cofreD4Sign: cofre,
      templateURL: files,
      signatarios,
    } = await item(id);
    this.form.reset({
      tipoDocumentoId,
      cofre,
      signatarios,
      files,
      basePolos,
    });
    this.state.data.setData(basePolos);
    this.state.loading.setLoading(false);
  }

  async handleSubmit(e, id) {
    if (this.state.data && e.files) {
      const tipoDocumentParsed = e.tipoDocumento
        ? JSON.parse(e.tipoDocumento)
        : undefined;
      const tratamentData = {
        tipoDocumento: tipoDocumentParsed?.id,
        cofreD4Sign: e.cofre,
        signatarios: tipoDocumentParsed?.signatarios
          ? e?.signatarios
          : undefined,
        templateUrl: (e.files.file ? e?.files?.file?.response.link : e.files),
        basePolos: this.state.validData.validData,
      };
      try {
        if (id) {
          await update(tratamentData, id);
        } else {
          await register(tratamentData);
        }

        notification['success']({
          message: `${
            id ? 'Atualização' : 'Cadastro'
          } foi feito(a) com sucesso!`,
          description: `Documento(s) adicionado(s) na fila de geração`,
        });

        this.history.push('/geradoc/envioDocumento');
      } catch (error) {
        notification['error']({
          message: `Erro ao ${id ? 'Atualizar Portal' : 'Cadastrar Portal'}`,
          description: 'Por favor verificar com a equipe tecnica',
        });
      }
    } else {
      notification['error']({
        message: `Verifique o Upload`,
        description:
          'Por favor verificar se os campos de upload estão sendo preenchidos com arquivos corretos',
      });
    }
  }
}
