import React from 'react';

import styles from './style.module.css';
import UsuariosTable from '../UsuariosTable';

const UsuariosSection = props => (
  <section className={styles.container}>
    <UsuariosTable form={props.form} />
  </section>
);

export default UsuariosSection;
