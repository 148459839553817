import { notification } from 'antd';
import { list, findByFilters } from '../../service';

export class FiltersController {
  constructor(props) {
    this.state = props.state;
  }

  async hadleInitData(id) {
    this.state.loading.setLoading(true);
    const result = await list(id);
    this.state.data.setData(result);
    this.state.loading.setLoading(false);
  }
  async handleSubmit(e) {
    try {
      this.state.loading.setLoading(true);
      const result = await findByFilters(e);
      this.state.data.setData(result);
      this.state.loading.setLoading(false);
    } catch (error) {
      notification['error']({
        message: `Erro ao Listar filtros`,
        description: error.message
          ? error.message[0]
          : 'Por favor verificar com a equipe tecnica',
      });
      this.state.loading.setLoading(false);
    }
  }
}
