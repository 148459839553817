import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { models } from 'powerbi-client';
import { Report } from 'powerbi-report-component';
import { Spin, Button } from 'antd';

const ReportComponent = ({ report }) => {
  // const [report, setReport] = useState({ visible: false });
  // const [isFetch, setIsFetch] = useState(true);
  const [dataReport, setDataReport] = useState({})
  const [isVisible, setIsVisible] = useState(false)
  const handleDataSelected = data => {
    // will be called when some chart or data element in your report clicked
  };

  const handleReportLoad = report => {};

  const handleEmbedded = reportBI => {
    setDataReport(reportBI)
    setIsVisible(true);
    let userData = JSON.parse(localStorage.getItem('userDataCRP'));
    // se for Administrador(1), superAdmin(1), GN(67) ou DN(67)
    if (userData.setorFuncao === 1 || userData.setorFuncao === 67 || userData.setorFuncao === 17) {
      return;
    }

    // Validar qual e o relatorio para filtrar
    const filter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'tabela_oficial_polo',
        column: 'id_colaborar',
      },
    };

    // se for Gestor ou coordenador
    let poloSelecionado = [];
    let listOfIdsNotFilter = [1, 2, 501, 502, 503, 506, 999]

    listOfIdsNotFilter.forEach(id => {
      if(!listOfIdsNotFilter.includes(JSON.parse(localStorage.getItem('userDataCRP-Perfil')))) {
        let polos = JSON.parse(localStorage.getItem('userDataCRP-Polos'));
          polos.forEach(function(item){
            poloSelecionado.push(item.polo.id);
          })
      }
    })
    filter.operator = 'Eq';
    filter.values = poloSelecionado;

    reportBI.setFilters([filter]).catch(err => console.error(err));
  };

  const handlePageChange = data => {
    // will be called when pages in your report changes
  };

  const handleTileClicked = data => {
    console.log('Data from tile', data);
  };

  function fullscreen() {
    dataReport.fullscreen();
  }

  const layoutSettings = {
    layoutType: models.LayoutType.Custom,
    customLayout: {
      pageSize: {
        type: models.PageSizeType.Custom,
        width: '100%',
        height: 600,
      },
      displayOption: models.DisplayOption.ActualSize,
      pagesLayout: {
        ReportSection1: {
          defaultLayout: {
            displayState: {
              mode: models.VisualContainerDisplayMode.Hidden,
            },
          },
          visualsLayout: {
            VisualContainer1: {
              x: 1,
              y: 1,
              z: 1,
              width: 400,
              height: 300,
              displayState: {
                mode: models.VisualContainerDisplayMode.Visible,
              },
            },
            VisualContainer2: {
              displayState: {
                mode: models.VisualContainerDisplayMode.Visible,
              },
            },
          },
        },
      },
    },
  };

  const reportStyle = { height: 600};
  const extraSettings = {
    filterPaneEnabled: false, //true
    navContentPaneEnabled: true,
    hideErrors: false, // Use this *only* when you want to override error experience i.e, use onError
    // ... more custom settings
    ...layoutSettings,
  };

  return (
    <>
    <Button
      style={{ float: 'right', display: (isVisible ? 'block' : 'none'), marginRight: '43%' }}
      type="primary"
      onClick={() => fullscreen()}
    >
      Exibir em tela cheia
    </Button>
      <Report
        tokenType="Embed" // "Aad"
        accessToken={report.embedToken} // accessToken goes here
        embedUrl={report.embedUrl} // embedUrl goes here
        embedId={report.reportId} // report or dashboard Id goes here
        // pageName="" // set as current page of the report
        reportMode="view" // open report in a particular mode view/edit/create
        //datasetId={datasetId} // required for reportMode = "create" and optional for dynamic databinding in `report` on `view` mode
        extraSettings={extraSettings}
        permissions="All" // View
        style={reportStyle}
        onLoad={handleEmbedded}
        // onRender={handleReportRender()} // not allowed in `create`
        // onSelectData={handleDataSelected()}
        // onPageChange={handlePageChange()}
        // onTileClicked={handleTileClicked()}
        // onSave={handleReportSave()} // works for edit and create
      />
    </>
  );
};

export default ReportComponent;
