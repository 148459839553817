import styled from 'styled-components';

export const Container = styled.div``;

export const InputWrapper = styled.form`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 12px;
`;

export const SystemDataContainer = styled.div`
  margin-bottom: 24px;
  div {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }

  p {
    margin: 0;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  a {
    all: unset;
  }
`;
