import { BANNER_CHANGE, BANNER_ERROR, BANNER_SUCCESS } from './BannerType';

import api from '../../../../services/api';

export const getBanners = async () => {
  return async function(dispatch) {
    try {
      const res = await api.get(`banner`);

      return dispatch({
        type: BANNER_SUCCESS,
        payload: { ...res.data, carregando: false },
      });
    } catch (error) {
      return dispatch({
        type: BANNER_ERROR,
        payload: { error: error, carregando: false },
      });
    }
  };
};

export const getADMBanners = async () => {
  return async function(dispatch) {
    try {
      const res = await api.get(`banner`);

      return dispatch({
        type: BANNER_SUCCESS,
        payload: { ...res.data, carregando: false },
      });
    } catch (error) {
      return dispatch({
        type: BANNER_ERROR,
        payload: { error: error, carregando: false },
      });
    }
  };
};

export const getBannerId = async id => {
  return async function(dispatch) {
    try {
      const res = await api.get(`banner/${id}`);

      return dispatch({
        type: BANNER_SUCCESS,
        payload: { ...res.data, carregando: false },
      });
    } catch (error) {
      return dispatch({
        type: BANNER_ERROR,
        payload: { error: error, carregando: false },
      });
    }
  };
};

export const salvarBanner = async banner => {
  return async function(dispatch) {
    try {
      const res = await api.post(`banner/saveBanner/`, banner);
      return dispatch({
        type: BANNER_SUCCESS,
        payload: {
          ...res.data.Banner,
          succes: true,
          botaoSalvarHabilitado: true,
        },
      });
    } catch (error) {
      return dispatch({
        type: BANNER_ERROR,
        payload: { error, succes: false, botaoSalvarHabilitado: true },
      });
    }
  };
};

export const alterarBanner = async (data, id) => {
  try {
    await api.put(`banner/saveBanner/${id}`, data);

    return dispatch => {
      dispatch({
        type: BANNER_SUCCESS,
        payload: { succes: true, error: null, botaoSalvarHabilitado: true },
      });
    };
  } catch (error) {
    let erro = error;
    if (erro && erro.response && erro.response.data) {
      erro = error.response.data;
    }
    return dispatch => {
      dispatch({
        type: BANNER_ERROR,
        payload: { error: erro, succes: false, botaoSalvarHabilitado: false },
      });
    };
  }
};

export const changeBanner = ({ prop, value }) => {
  return dispatch => {
    dispatch({ type: BANNER_CHANGE, payload: { prop, value } });
  };
};
