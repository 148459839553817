import React, { Component, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  DatePicker,
  Divider,
  Modal,
  Button,
} from 'antd';
import { cpfMask, telefoneMask } from '../../utils/masks';
import { connect } from 'react-redux';
import {
  changeUsuarioPessoa,
  changeUsuarioSituacao,
  changeUsuarioPortal,
  changeUsuario,
  getPolosByCanal,
  changeDadoPerfil,
  cleanListaUsuarioPolo,
  changeLstPoloCommand,
} from 'store/actions';
import validacaoCpf from 'utils/validadores/CPFValidator';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';

import EmailUsuarioPolo from './components/EmailUsuarioPolo';
import EmailUsuarioAdmin from './components/EmailUsuarioAdmin';

import { getUserProfileByEmail, getUserProfileByMail } from 'services/MsGraph';
import Swal from 'sweetalert2';
import './style.css';
import { Sphere, ContainerSelect, HandleCaptarContainer } from './styles.js';
import { getTokenAppRegistration } from 'services/graphAppRegistration';
import e from 'cors';

import RenderModalCaptar from '../GestaoCaptar';

const { Option } = Select;
const optionStyle = {
  display: 'flex',
  columnGap: '4px',
  alignItems: 'center',
};
const rule = /\s{1,}/g;
class SessaoUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFuncionario: '',
      perfilID: 0,
      isDisabled: true,
      isPoloProprio: false,
      redirectCPF: '',
      isFinishedRedirec: false,
      showSecundaryEmail: false,
      stateColor: [
        { color: '#57e151' },
        { color: '#f1f156' },
        { color: '#ff0000' },
      ],
    };
    this.validaCpf = this.validaCpf.bind(this);
    this.handleChangeTelefone = this.handleChangeTelefone.bind(this);
    this.changeCpf = this.changeCpf.bind(this);
    this.handleColaborador = this.handleColaborador.bind(this);
    this.validaFuncionarioAzureAD = this.validaFuncionarioAzureAD.bind(this);
  }

  componentDidMount() {
    if (this.props.isTelaPolo && !this.props.isEdit) {
      const userData = JSON.parse(localStorage.getItem('userDataCRP'));
      this.handleColaborador(
        userData && userData.usuarioPortal && userData.usuarioPortal.funcionario
      );
    }

    getTokenAppRegistration();

    if (this.props.isTelaPolo) {
      this.disableValidationOfEmailField();
    }
    this.setState({ redirectCPF: localStorage.getItem('redirectCPF') });

    localStorage.setItem('redirectCPF', '');
    this.setState({ perfilID: this.props.perfilID });
    const lstPolos = [];
    this.props.usuario.lstUsuarioPoloCommand.forEach((item) => {
      lstPolos.push(item);
    });
    this.props.usuario.usuarioPortal.lstPolos = lstPolos;
  }

  handleColaborador = (e) => {
    this.props.changeDadoPerfil({
      prop: 'carregando',
      value: true,
    });

    this.props.changeUsuarioPortal({
      prop: 'funcionario',
      value: e,
    });

    this.props.getPolosByCanal(e);
    this.setState(
      {
        isFuncionario: e,
      },
      () => {
        if (
          this.state.isFuncionario !== '' &&
          this.props.form.getFieldValue('email') !== ''
        ) {
          this.props.form.validateFields(['email'], { force: true });
        }
      }
    );
  };

  disableValidationOfEmailField = () => {
    let listPolos = JSON.parse(localStorage.getItem('userDataCRP-Polos'));
    let isProprio = false;
    listPolos.forEach((item) => {
      if (item.polo.id == localStorage.getItem('userDataCRP-Polo')) {
        this.state.isPoloProprio = item.polo.poloProprio == 'S' ? true : false;
      }

      this.render();
    });
  };

  validaFuncionarioAzureAD = async (rule, value, callback) => {
    try {
      if (
        this.state.isFuncionario === 'S' &&
        this.props.form.getFieldValue('email') !== ''
      ) {
        const response = await getUserProfileByEmail(value);
        if (response.data.value.length == 0) {
          const resp = await getUserProfileByMail(value);
          if (resp.data.value.length == 1) {
            this.props.changeUsuarioPortal({
              prop: 'conta',
              value: resp.data.value[0].userPrincipalName.replace(
                '#EXT#@kroton.onmicrosoft.com',
                ''
              ),
            });
            this.props.changeUsuarioPessoa({
              prop: 'email',
              value: resp.data.value[0].userPrincipalName.replace(
                '#EXT#@kroton.onmicrosoft.com',
                ''
              ),
            });
          } else if (
            response.data.value.length == 0 &&
            resp.data.value.length == 0
          ) {
            throw Error('Funcionário não encontrado');
          }
        } else {
          this.props.changeUsuarioPortal({
            prop: 'conta',
            value: response.data.value[0].userPrincipalName.replace(
              '#EXT#@kroton.onmicrosoft.com',
              ''
            ),
          });
          this.props.changeUsuarioPessoa({
            prop: 'email',
            value: response.data.value[0].userPrincipalName.replace(
              '#EXT#@kroton.onmicrosoft.com',
              ''
            ),
          });
        }
      }
      callback();
    } catch (err) {
      console.warn('Colaborador', err);
      callback(err);
    }
  };

  validaCpf = (rule, value, callback) => {
    try {
      if (value.length === 14) {
        if (!validacaoCpf(value.replace(/\D/g, ''))) {
          throw new Error('');
        }
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  validName = (rule, value, callback) => {
    try {
      const regex = /[a-zA-Záàâãéèêíïóôõöúçñ  ]+/i;
      if (!value.match(regex)) {
        throw new Error(' ');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  validaNomeSobrenome = (rule, value, callback) => {
    try {
      const regex = /^[A-Za-záàâãéèêíïóôõöúçñÝÀÂÃÉÈÝÝÓÔÕÖÚÇ ']+$/;
      if (!value.match(regex)) {
        throw new Error(' ');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  handleChangeEmailSecundario = (e) => {
    this.props.changeUsuarioPortal({
      prop: 'emailSecundario',
      value: e.target.value,
    });
  };

  handleChangeTelefone = (e) => {
    const telefoneSemMascara = telefoneMask(e.target.value).replace(/\D/g, '');
    this.props.changeUsuarioPessoa({
      prop: 'telefone',
      value: telefoneSemMascara.substring(2, telefoneSemMascara.length),
    });

    this.props.changeUsuarioPessoa({
      prop: 'ddd',
      value: telefoneSemMascara.substring(0, 2),
    });
  };

  changeCpf = (e, isNew) => {
    let cryptoUsrCad = require('crypto');
    let cpf = isNew
      ? cpfMask(e).replace(/\D/g, '')
      : cpfMask(e.target.value).replace(/\D/g, '');
    const userData = JSON.parse(localStorage.getItem('userDataCRP'));
    this.props.changeUsuarioPessoa({
      prop: 'cpf',
      value: cpf,
    });

    this.props.changeUsuarioPortal({
      prop: 'chave',
      value: cryptoUsrCad.createHash('md5').update(cpf).digest('hex'),
    });
    if (this.props.usuario.usuarioPortal.funcionario === 'N') {
      if (
        this.props.perfilID == 999 ||
        this.props.perfilID == 1 ||
        this.props.perfilID == 2
      ) {
        this.props.changeUsuarioPessoa({
          prop: 'email',
          value: cpf.concat('@aeduead.com.br'),
        });

        if (!this.props.isEdit) {
          this.props.changeUsuarioPortal({
            prop: 'conta',
            value: cpf.concat('@aeduead.com.br'),
          });
        }
      } else if (userData.usuarioPortal.funcionario === 'N') {
        this.props.changeUsuarioPessoa({
          prop: 'email',
          value: cpf.concat('@aeduead.com.br'),
        });

        if (!this.props.isEdit) {
          this.props.changeUsuarioPortal({
            prop: 'conta',
            value: cpf.concat('@aeduead.com.br'),
          });
        }
      }
    }
  };

  situacaoUsuarioOnChange = (e) => {
    const int = parseInt(e);

    if (int === 2) {
      this.props.changeUsuario({
        prop: 'trocouFieldParaInativo',
        value: true,
      });
    } else {
      this.props.changeUsuario({
        prop: 'trocouFieldParaInativo',
        value: false,
      });
    }
    this.props.changeUsuarioSituacao({
      prop: 'id',
      value: int,
    });
  };

  disabledEmail = (isGN, usuario) => {
    /* if(isGN) {
      return true;
    } else if (usuario.usuarioPortal.funcionario == 'N') {
      return true;
    } else {
      return false;
    }*/
  };

  validSecundaryEmail = (rule, value, callback) => {
    try {
      if (value == this.props.usuario.usuarioPortal.conta) {
        throw new Error(' ');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { usuario, dadosPerfil, isTelaPolo } = this.props;
    const { showModalCaptar } = this.state;
    const { isLogCaptar } = this.state;
    const cpfFormatter = (e) => cpfMask(e.target.value);
    const telefoneFormatter = (e) => telefoneMask(e.target.value);
    const telefoneInit = usuario.usuarioPortal.pessoa.ddd
      ? telefoneMask(
          usuario.usuarioPortal.pessoa.ddd
            .toString()
            .concat(usuario.usuarioPortal.pessoa.telefone.toString())
        )
      : '';

    const dataAdmissaoInit = usuario.usuarioPortal.pessoa.admissao
      ? moment(usuario.usuarioPortal.pessoa.admissao, 'DD-MM-YYYY')
      : null;
    const userDataPerfil = this.state.perfilID;
    const isGN = userDataPerfil == 501 || userDataPerfil == 506 ? true : false;

    if (
      this.state.redirectCPF != '' &&
      this.state.redirectCPF != null &&
      !this.state.isFinishedRedirec &&
      !this.props.isEdit
    ) {
      usuario.usuarioPortal.pessoa.cpf = this.state.redirectCPF;
      this.changeCpf(this.state.redirectCPF, true);
      this.setState({ isFinishedRedirec: true });
    }

    if (usuario.usuarioPortal.id > 0) {
      this.state.isFuncionario = usuario.usuarioPortal.funcionario;
    }

    return (
      <div>
        <Row xl={24}>
          <Col xl={11} className="gutter-row">
            <Form.Item label="Nome Completo" size="large">
              {getFieldDecorator('nome', {
                initialValue: usuario.usuarioPortal.pessoa.nome,
                rules: [
                  {
                    required: true,
                    message: ' ',
                  },
                  {
                    validator: this.validaNomeSobrenome,
                    message: 'Por favor insira o nome completo',
                  },
                  {
                    validator: this.validName,
                    message: 'Por favor remover caracteres especiais e numeros',
                  },
                ],
              })(
                <Input
                  placeholder="Nome"
                  onChange={(e) =>
                    this.props.changeUsuarioPessoa({
                      prop: 'nome',
                      value: e.target.value.trim(),
                    })
                  }
                  size="large"
                  onBlur={(e) =>
                    (e.target.value = e.target.value.split(rule).join(' '))
                  }
                  disabled={isGN}
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={4} className="gutter-row margin-1-left">
            <Form.Item label="CPF" size="large">
              {getFieldDecorator('cpf', {
                initialValue: cpfMask(usuario.usuarioPortal.pessoa.cpf),
                getValueFromEvent: cpfFormatter,
                rules: [
                  {
                    required: true,
                    message: 'Por favor insira um cpf válido',
                  },
                  {
                    validator: this.props.isEdit ? null : this.validaCpf,
                    message: 'Por favor insira um cpf válido',
                  },
                  {
                    min: 14,
                    message: 'Por favor insira um cpf válido',
                  },
                ],
              })(
                <Input
                  placeholder="000.000.000-00"
                  onChange={this.changeCpf}
                  disabled={
                    (this.props.isEdit && userDataPerfil != 999) || isGN
                  }
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={4} className="gutter-row margin-1-left">
            <Form.Item label="Colaborador tipo" size="large">
              {getFieldDecorator('funcionario', {
                initialValue:
                  (usuario.usuarioPortal &&
                    usuario.usuarioPortal.funcionario) ||
                  [],
                rules: [
                  {
                    required: true,
                    message: 'Por favor selecione o tipo de Colaborador',
                  },
                ],
              })(
                <Select
                  placeholder="Selecione..."
                  onChange={this.handleColaborador}
                  allowClear
                  disabled={
                    (this.props.isEdit && userDataPerfil != 999) ||
                    (this.props.isTelaPolo && userDataPerfil != 999) ||
                    parseInt(usuario.usuarioPortal.situacaoUsuario.id) != 1 ||
                    isGN
                  }
                  size="large"
                >
                  <Option value="S">Funcionário</Option>
                  <Option value="N">Parceiro</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          {this.props.isEdit && (
            <Col xl={3} className="gutter-row colaborador-tipo margin-1-left">
              <Form.Item label="Situação do Usuario" size="large">
                {getFieldDecorator('situacaoUsuarioId', {
                  initialValue: parseInt(
                    usuario.usuarioPortal.situacaoUsuario.id
                  ),
                })(
                  <Select
                    id="situacaoUsuario"
                    size="large"
                    disabled={isGN}
                    style={{ width: '100%' }}
                  >
                    {Object.keys(dadosPerfil.SituacaoUsuario).map((key) => {
                      return (
                        <Option
                          key={'situacaoUsuario-' + [key]}
                          size="large"
                          value={parseInt(key)}
                          onClick={(e) => this.situacaoUsuarioOnChange(e.key)}
                          style={optionStyle}
                        >
                          <Sphere
                            background={this.state.stateColor[key - 1]?.color}
                          />{' '}
                          {dadosPerfil.SituacaoUsuario[key]}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row xl={24}>
          <Col xl={7} className="gutter-row">
            {isTelaPolo ? (
              <EmailUsuarioPolo
                form={this.props.form}
                isEdit={this.props.isEdit && userDataPerfil != 999}
                validaFuncionarioAzureAD={this.validaFuncionarioAzureAD}
                disabled={this.disabledEmail(isGN, usuario)}
                redirectCPF={this.state.redirectCPF}
              />
            ) : (
              <EmailUsuarioAdmin
                form={this.props.form}
                isEdit={this.props.isEdit && userDataPerfil != 999}
                validaFuncionarioAzureAD={this.validaFuncionarioAzureAD}
                disabled={this.disabledEmail(isGN, usuario)}
                redirectCPF={this.state.redirectCPF}
              />
            )}
          </Col>
          {this.state.isFuncionario === 'N' ? (
            <Col xl={7} className="gutter-row margin-1-left">
              <Form.Item label="E-mail Secundário" size="large">
                {getFieldDecorator('emailSecundario', {
                  initialValue: usuario.usuarioPortal.emailSecundario,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor insira um e-mail válido',
                      type: 'email',
                    },
                    {
                      validator: this.validSecundaryEmail,
                      message: 'Por favor insira um e-mail diferente da conta',
                      type: 'email',
                    },
                  ],
                })(
                  <Input
                    placeholder="E-mail"
                    onChange={this.handleChangeEmailSecundario}
                    size="large"
                  />
                )}
              </Form.Item>
            </Col>
          ) : (
            ''
          )}

          <Col xl={4} className="gutter-row margin-1-left">
            <Form.Item label="Telefone (celular)" size="large">
              {getFieldDecorator('telefone', {
                initialValue: telefoneInit,
                getValueFromEvent: telefoneFormatter,
                rules: [
                  {
                    required: true,
                    message: 'Por favor insira um telefone válido',
                  },
                  {
                    min: 15,
                    message: 'Por favor insira um telefone válido',
                  },
                ],
              })(
                <Input
                  placeholder="(99) 9 9999-9999"
                  onChange={this.handleChangeTelefone}
                  size="large"
                  disabled={isGN}
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={4} className="gutter-row margin-1-left">
            <Form.Item label="Data admissão" size="large">
              {getFieldDecorator('admissao', {
                initialValue: dataAdmissaoInit,
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Por favor insira uma data válida',
                  },
                ],
              })(
                <DatePicker
                  format="DD-MM-YYYY"
                  placeholder="DD-MM-YYYY"
                  locale={locale}
                  onChange={(e) =>
                    this.props.changeUsuarioPessoa({
                      prop: 'admissao',
                      value: e ? e.format('DD-MM-YYYY') : '',
                    })
                  }
                  size="large"
                  disabled={isGN}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row xl={24}>
          {this.props.isEdit && (
            <>
              <Col xl={4} className="gutter-row">
                <Form.Item label="Data de Criação" size="large">
                  {getFieldDecorator('dataCriacao', {
                    initialValue: usuario.usuarioPortal.dataCriacao,
                  })(<Input size="large" disabled />)}
                </Form.Item>
              </Col>
              <Col xl={4} className="gutter-row margin-1-left">
                <Form.Item label="Data ultima modificação" size="large">
                  {getFieldDecorator('lastUpdate', {
                    initialValue: usuario.usuarioPortal.lastUpdated,
                  })(<Input size="large" disabled />)}
                </Form.Item>
              </Col>
              <Col xl={4} className="gutter-row margin-1-left">
                <Form.Item label="Data ultimo acesso" size="large">
                  {getFieldDecorator('ultimoAcesso', {
                    initialValue:
                      usuario.usuarioPortal.ultimoAcesso == null
                        ? 'N/A'
                        : usuario.usuarioPortal.ultimoAcesso,
                  })(<Input size="large" disabled />)}
                </Form.Item>
              </Col>
              <Col xl={10} className="gutter-row margin-1-left margin-1-top">
                {
                  <RenderModalCaptar
                    userDataProfile={usuario.usuarioPortal}
                    toggleCaptar={false}
                    listPolosUser={usuario.lstUsuarioPoloCommand}
                  />
                }
              </Col>
            </>
          )}
        </Row>
        <Divider style={{ margin: '0px 0px 15px' }} />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  usuario: store.Usuario,
  dadosPerfil: store.DadosPerfil,
});

export default connect(mapStateToProps, {
  changeUsuarioPessoa,
  changeUsuarioSituacao,
  changeUsuarioPortal,
  getPolosByCanal,
  changeDadoPerfil,
  cleanListaUsuarioPolo,
  changeUsuario,
  changeLstPoloCommand,
})(SessaoUsuario);
