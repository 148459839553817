import React, { useState, useEffect } from 'react';
import { Table, Button, Icon } from 'antd';

const AgendaTable = ({dataSource}) => {
    const tableColumns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: 'Titulo',
        key: 'titulo',
        dataIndex: 'titulo',
      },
      {
        title: 'Vigência',
        dataIndex: 'dataInicio',
        key: ['dataInicio', 'dataFim'],
        render: (id, record) => (
          (
            <>
              <small>
                {record.dataInicio} a {record.dataFim}
              </small>
            </>
          )
        ),
      },
      {
        title: 'Categoria',
        dataIndex: 'descricao.descricao',
        key: 'agendaTipo.descricao',
        render: (id, record) => (
          (
            <>
              <span>{record.agendaTipo.descricao}</span>
            </>
          )
        ),
      },
      {
        title: 'Canal',
        dataIndex: 'poloProprio',
        key: 'canal',
        render: (canal) =>
          canal == 'T' ? 'Todos' : canal == 'P' ? 'Próprio' : 'Parceiro',
      },
      {
        title: 'Status',
        dataIndex: 'ativo',
        key: 'ativo',
        render: (status) => (status == 'S' ? 'Ativo' : 'Inativo'),
      },
      {
        title: 'Ações',
        dataIndex: 'id',
        key: 'edit',
        render: (id) => (
          <Button
            title="Editar "
            className="button is-small is-warning"
            href={`/admin/agendamento/edit/${id}`}
          >
            <Icon type="save" />
            Editar
          </Button>
        ),
      },
    ];

    return (
      <Table
      columns={tableColumns}
      dataSource={dataSource}
      />
    )
  }

export default AgendaTable;
