import api from 'services/api';
import Swal from 'sweetalert2';

export const getArea = async (area) => {
  return await api.get(`comunicado/area/${(area.descricao != '' ? `?descricao=${area.descricao}` : '')}`);
};

export const getAreaById = async (id) => {
  return await api.get(`comunicado/area/${id}`);
};

export const putArea = async (id, area) => {
  console.log("putArea", area )
  return await api.put(`comunicado/area/${id}`, area).then(() => {
    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Area alterada com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  });
};

export const saveArea = async (area) => {
  if(area.descricao == null) return
  area.ativo = 'S';
  return await api.post(`comunicado/area/`, area).then(() => {
    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Area criada com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  });
}
