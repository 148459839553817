import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Input,
  Col,
  Table,
  Modal,
  Select,
  Upload,
  Icon,
} from 'antd';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuDoc,
  FontAwesomeIcon,
} from '../../../services/Gerais';
import './style.css';
import '../../../styles/components/Admin.scss';
import { FormController } from './FormController';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { UploadForm } from '../../../components/uploadForm/UploadForm';
import { tipoDocumento } from '../../Administrador/DocumentGenerationAndSubmission/service';
import { ControllerD4 } from '../controller.js';

const { Option } = Select;

const BasePolos = () => {
  const [tituloPagina, setTituloPagina] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectState, setSelectState] = useState(null);
  const [typeDocument, setTypeDocument] = useState([]);
  const [uploadError, setUploadError] = useState(true);
  const [fileList, setFileList] = useState([]);
  let demoUrl = process.env.PUBLIC_URL + '/arquivo-demostracao-import-polos-D4.xlsx';

  const {
    handleImportPolos,
    uploadPropsDocs
  } = ControllerD4();

  useEffect(() => {
    setTituloPagina('Base Polos');
  }, []);

  function handleModal() {
    if (isOpen == false) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }

  const tableColumns = [
    {
      title: 'ID Polo',
      key: 'idPolo',
      dataIndex: 'idPolo',
    },
    {
      title: 'Nome Polo',
      key: 'nomePolo',
      dataIndex: 'nomePolo',
    },
    {
      title: 'Última atualização',
      key: 'dataUltimaAtualizacao',
      dataIndex: 'dataUltimaAtualizacao',
      render: (dataInclusao) =>
        moment(dataInclusao, 'DD-MM-YYYY hh:mm:ss').format('DD/MM/YYYY'),
    },
  ];

  const props = {
    state: {
      data: { data, setData },
      loading: { loading, setLoading },
      uploadError: { uploadError, setUploadError },
    },
  };
  const { handleSubmit, control, watch } = useForm();

  const initialValueTypeDocument = async () => {
    try {
      const { data } = await tipoDocumento();
      setTypeDocument(data);
    } catch (error) {
      return error;
    }
  };

  const tableController = new FormController(props);

  useEffect(() => {
    tableController.hadleInitData();
    initialValueTypeDocument();
  }, []);

  return (
    <>
      <Helmet
        key="TitleDocBasePolos"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, usuarios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderDocBasePolos" />
      <section
        key="SectionDocBasePolos"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuDoc key="MenuAdminUsuarioLista" />
        <div className="column is-10 is-fullheight conteudoAdminPagina ">
          <div className="columns ">
            <div className="column ">
              <Hero
                key="heroDocBasePolos"
                pageName="Documentos - Base Polos"
                titulo={tituloPagina}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div
                  className="button-wrapper"
                  id="components-dropdown-demo-dropdown-button"
                >
                  <Button type="primary" size="large" onClick={handleModal}>
                    <FontAwesomeIcon icon={['fas', 'building']} /> &nbsp;
                    Importar Polos
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Form
            onSubmit={handleSubmit((e) => tableController.handleSubmit(e))}
            style={{ marginTop: 20 }}
          >
            <Row gutters={24}>
              <Col span={4}>
                <Form.Item size="large" label="Codigo do Polo">
                  <Controller
                    name="poloId"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        placeholder="Codigo do Polo"
                      />
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={17}>
                <Form.Item
                  size="large"
                  label="Nome do Polo"
                  className="margin-1-left"
                >
                  <Controller
                    name="polo"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} placeholder="Nome do Polo" />
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item size="large">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="margin-button-top margin-1-left"
                  >
                    <FontAwesomeIcon icon={['far', 'search']} />
                    Pesquisar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row gutters={24}>
            <Table
              dataSource={data}
              loading={loading}
              columns={tableColumns}
              size="small"
            />
          </Row>
        </div>
      </section>
      <FooterAdmin key="FooterDocBasePolos" />
      <Modal
        title="Importação de polos"
        visible={isOpen}
        onOk={async () => await handleImportPolos()}
        onCancel={handleModal}
        destroyOnClose={true}
        width={600}
        centered
      >
        <Row>
          <Form.Item
            label="Importar Polos:"
            size="large"
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Upload {...uploadPropsDocs}>
              <Button>
                <Icon type="upload" /> Anexar arquivo
              </Button>
            </Upload>
          </Form.Item>
        </Row>
        <Row>
        <a
            href={demoUrl}
            target='_blank'
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            Baixe aqui o template do arquivo de importação de Polos!
          </a>
        </Row>
      </Modal>
    </>
  );
};

export default Form.create()(BasePolos);
