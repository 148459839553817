import React, { useState } from 'react';
import { Header, Footer, Hero, Helmet, axios, moment, FontAwesomeIcon } from 'services/Gerais';
import 'styles/Styles.scss';
import 'styles/Foca.scss';
import 'antd/dist/antd.css';
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  Spin,
  DatePicker,
  Icon,
  Table,
  Modal,
  Alert,
  Upload,
  Input,
} from 'antd';
import { useFOCA } from './FocaController';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;

const locale = 'pt-BR';

const MigracaoGN = () => {
  const titlePage = 'Migração de chenkins entre GNs';
  const urlFileDemo = process.env.PUBLIC_URL + '/arquivo-demonstracao-migracao-gns.xlsx'
  const [isModalOpen, setIsModalOpen] = useState(false);
  const colums = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Arquivo',
      dataIndex: 'nomeArquivo',
      key: 'nomeArquivo',
      width: 200,
    },
    {
      title: 'Data',
      dataIndex: 'dataInclusao',
      key: 'dataInclusao',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      width: 50,
      render: (row, record, index) => {
        return (
          <>
          <Button
            type="primary"
            size="default"
            className="is-warning"
            onClick={() => handleDownload(record.id, record.nomeArquivo)}
          >
            <FontAwesomeIcon icon={['fas', 'download']} /> &nbsp;
            Download
          </Button>
          {record.status == 'AGUARDANDO_PROCESSAMENTO' ? (
            <Button
            type="dashed"
            size="defaul"
            className='margin-1-left'
            onClick={async () => await handleCancelImport(record.id)}
          >
            <FontAwesomeIcon icon={['fas', 'ban']} />{' '}
            &nbsp; Cancelar
          </Button>
          ) : ''}
          </>
        )
      }
    },
  ];

  const {
    handleImportMigrationGNs,
    handleDownload,
    changeDate,
    changeStatus,
    handleGetImport,
    handleCancelImport,
    clear,
    uploadPropsDocs,
    migrations,
    pagination,
    search
  } = useFOCA();

  const handleModal = () => {
    if (!isModalOpen) {
      setIsModalOpen(true)
    } else {
      setIsModalOpen(false)
    }
  }

  function importMigration() {
    handleImportMigrationGNs()
    handleModal();
  }


  return (
    <>
      <Helmet
        title={titlePage}
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: titlePage + '- Portal Prisma',
          },
          { name: 'keywords', content: 'kroton, educacional, portal, teste' },
        ]}
        key="titleMigracaoGN"
      />
      <Header key="headerMigracaoGN" />
      <Hero key="heroMigracaoGN" pageName={titlePage} titulo={titlePage} />
      <section className="section conteudoPortais" key="MigracaoGN">
        <Row span={24}>
          <Col span={5}>
            <Form.Item label="Data">
            <DatePicker
                style={{ width: "100%" }}
                locale={locale}
                format="DD-MM-YYYY"
                placeholder={'Data de Importação'}
                onChange={changeDate}
              />
            </Form.Item>
          </Col>
          <Col span={7} className="margin-1-left">
            <Form.Item style={{ flex: '0.3' }} label="Status" >
              <Select onChange={changeStatus} defaultValue={search.status}>
                <Select.Option key="AGUARDANDO_PROCESSAMENTO">
                  Aguardando processamento
                </Select.Option>
                <Select.Option key="EM_ANDAMENTO">Em andamento</Select.Option>
                <Select.Option key="PROCESSADO_ERRO">
                  Processado - Erro
                </Select.Option>
                <Select.Option key="PROCESSADO_SUCESSO">
                  Processado - Sucesso
                </Select.Option>
                <Select.Option key="PROCESSAMENTO_CANCELADO">
                  Processamento cancelado
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} className="margin-1-left">
            <Form.Item style={{ marginTop: '38px' }}>
              <Button
                type="primary"
                onClick={handleGetImport}
                style={{
                  'background-color': '#52c41a',
                  border: 'none',
                  marginRight: '8px',
                }}
              >
                Pesquisar
              </Button>
              <Button type="primary" onClick={clear}>Limpar</Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item style={{ marginTop: '38px' }}>
              <Button
                type="primary"
                className="pull-right"
                onClick={handleModal}
                style={{
                  border: 'none',
                  marginRight: '8px',
                }}
              >
                Importar arquivo
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Table
          pagination={pagination}
          columns={colums}
          dataSource={migrations}
        />
        <Modal
          title="Importação de migração de checkin(s)"
          visible={isModalOpen}
          okText="Enviar arquivo"
          onOk={importMigration}
          onCancel={handleModal}
          destroyOnClose={true}
          width={600}
          centered>
          <>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label={<span>Documento</span>} size="large" style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <Upload {...uploadPropsDocs}>
                    <Button>
                      <Icon type="upload" /> Importar documento
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <a
                href={urlFileDemo}
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                target='_blank'>
                  Baixe aqui o template do arquivo de importação!
              </a>
            </Row>
          </>
        </Modal>
      </section>
      <Footer key="FooterMigracaoGN" />
    </>
  );
};

export default MigracaoGN;
