import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from 'services/Gerais';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import ComunicadosSection from './ComunicadosSection';
import './styles.css';

library.add(fab, far, fas);

const Lista = props => {
  const [tituloPagina, setTituloPagina] = useState(0);

  useEffect(() => {
    setTituloPagina('Listar Comunicados');
  }, []);

  return (
    <>
      <Helmet
        key="TitleAdminComunicadoLista"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, comunicados, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAdminComunicadoLista" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminComunicadoLista" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminListaComunicados"
                pageName="Administração - Comunicados"
                titulo={tituloPagina}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div id="components-dropdown-demo-dropdown-button">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      props.history.push('/admin/comunicados/cadastro');
                    }}
                  >
                    <FontAwesomeIcon icon={['far', 'images']} /> &nbsp; Novo
                    Comunicado
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <ComunicadosSection history={props.history} />
        </div>
      </section>
      <FooterAdmin key="FooterAdminComunicadoLista" />
    </>
  );
};

export default Form.create()(Lista);
