import {
  COMUNICADO_CHANGE,
  COMUNICADO_SUCCESS,
  COMUNICADO_ERROR,
  COMUNICADO_DADOS_INICIAIS_SUCCESS,
  COMUNICADO_DADOS_INICIAIS_ERROR,
  COMUNICADO_UPLOAD_SUCCESS,
  COMUNICADO_UPLOAD_ERROR,
  COMUNICADO_SUCCESS_SAVE,
  COMUNICADO_POLO_SUCCESS,
  COMUNICADO_POLO_ERROR,
} from '../../../actions/Administrativo/Comunicado/ComunicadoType';
import moment from 'moment';
const userData = JSON.parse(localStorage.getItem('userDataCRP'));

const initialState = {
  carregandoDadosIniciais: true,
  carregandoUpload: false,
  carregando: true,
  succes: false,
  error: null,
  botaoSalvarHabilitado: true,
  botaoUploadHabilitado: true,
  isEdit: false,
  botaoNextHabilitado: [],
  DadosIniciaisComunicado: {
    Area: [],
    Setor: {},
    Categoria: {},
    Funcao: {},
    SetorFuncao: {},
    Marca: {},
    Tag: [],
  },
  DadosComunicado: {
    comunicado: {
      titulo: '',
      area: {},
      conteudo: '',
      resumo: '',
      anexos: '',
      poloProprio: 'S',
      dataInicio: moment(
        new Date(new Date().getTime()),
        'dd-mm-yyyy hh:mm'
      ).format('DD-MM-YYYY hh:mm'),
      dataFim: '',
      errata: 'N',
      ativo: 'S',
      destaque: 'N',
      tipo: 'C',
      path_thumbnail: '',
      usuarioPortalLog: {
        id:
          userData && userData.usuarioPortalLog && userData.usuarioPortalLog.id,
      },
    },
    lstComunicadoMarca: [],
    lstComunicadoSetorFuncao: [],
    lstComunicadoCategoria: [],
    lstComunicadoTag: [],
    lstComunicadoPolo: [],
    comunicadoSubCategoria: null,
    uploadResults: {},
    tipoComunicado: 'MARCA',
  },
  lstPolosEdit: [],
};

const Comunicado = (state = initialState, action) => {
  switch (action.type) {
    case COMUNICADO_SUCCESS_SAVE:
      return {
        ...state,
        DadosComunicado: initialState.DadosComunicado,
        carregando: false,
        succes: true,
        botaoSalvarHabilitado: true,
        isEdit: false,
      };
    case COMUNICADO_SUCCESS:
      return {
        ...state,
        DadosComunicado: action.payload,
        carregando: false,
        botaoSalvarHabilitado: true,
      };
    case COMUNICADO_ERROR:
      return {
        ...state,
        error: action.payload,
        carregando: false,
        botaoSalvarHabilitado: true,
      };
    case COMUNICADO_CHANGE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case COMUNICADO_DADOS_INICIAIS_SUCCESS:
      return {
        ...state,
        DadosIniciaisComunicado: action.payload,
        carregandoDadosIniciais: false,
      };
    case COMUNICADO_DADOS_INICIAIS_ERROR:
      return {
        ...state,
        DadosIniciaisComunicado: action.payload,
        carregandoDadosIniciais: false,
      };
    case COMUNICADO_UPLOAD_SUCCESS:
      return {
        ...state,
        DadosComunicado: {
          ...state.DadosComunicado,
          lstComunicadoPolo: action.payload.Polos.map((item) => ({
            polo: {
              id: item.id.toString(),
              nome: item.nome,
              existe: item.existe,
              marca: item.marca,
            },
          })),
        },
        uploadResults: action.payload.Resultados,
        carregandoUpload: false,
      };
    case COMUNICADO_UPLOAD_ERROR:
      return { ...state, error: action.payload, carregandoUpload: false };
    case COMUNICADO_POLO_SUCCESS:
      return {
        ...state,
        lstPolosEdit: action.payload.lstComunicadoPolo.map((item) => ({
          id: item.id,
          nome: item.polo.nome,
          ativo: item.ativo,
          poloId: item.polo.id,
          // existe: item.existe,
          // marca: item.marca,
        })),
        carregandoUpload: false,
      };
    case COMUNICADO_POLO_ERROR:
      return { ...state, error: action.payload, carregandoUpload: false };
    default:
      return state;
  }
};

export default Comunicado;
