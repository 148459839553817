import React, { Component } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  AutoComplete,
  DatePicker,
  Table,
  Modal,
  Upload,
  Input,
  Icon
} from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from 'services/Gerais';
import 'pages/Administrador/Workflow/styles.scss';
import { onGetPolos, onGetDocs, onUpdateDocs, exportCSV, onDocsOnBase, onSendDocument } from '../../pages/Administrador/Workflow/FileImport/service.js';


const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;

class WorkFlowTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listPolos: [],
      polos: [],
      codOnBase: 0,
      idDoc: 0,
      fileOnbase: '',
      isOpen: false,
      search: {
        documento: '',
        polo: '',
        status: '',
        validade: '',
      },
      observacao: '',
      listDocs: [],
      listDocsOnBase: []
    }
  }

  componentDidMount() {
    this.getPolos();
    this.getDocsOnBase();
    this.handleSearchDocs();
  }

  getPolos = async () => {
    const data = await onGetPolos();
    this.setState({
      listPolos: data.data.Polos,
      polos: data.data.Polos
     })
  }

  getDocsOnBase = async () => {
    const data = await onDocsOnBase();
    this.setState({ listDocsOnBase: data.data.sort(this.sortDocsOnBase) })
  }

  sortDocsOnBase = (a, b) => {
    if (a.tipoDocumento > b.tipoDocumento) return 1;
    if (a.tipoDocumento < b.tipoDocumento) return -1;
    return 0;
  }

  renderOption = (item) => {
    return (
      <Option key={item.id} text={item.nome}>
        {item['nome'] ? item.nome : item.descricao}
      </Option>
    );
  }

  renderDocs = (item) => {
    return (
      <Option key={item.tipoDocumento} text={item.tipoDocumento}>
        {item['tipoDocumento'] ? item.tipoDocumento : item.tipoDocumento}
      </Option>
    );
  }

  handleSearchDocs = async () => {
    const data = await onGetDocs(this.state.search);
    this.setState({ listDocs: data.data.sort(this.sortDocs) });
    this.render();
  }
  clearSearch() {
    window.location.reload(false);
  }

  sortDocs = (a, b) => {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
    return 0;
  }

  handleExportCSV = async () => {
    await exportCSV(this.state.search);
  }

  handleUpdateTable = async (id, type, value) => {
    await onUpdateDocs(id, type, value);
  }

  handleSendDocument = async () => {
    await onSendDocument(this.state.codOnBase, this.state.fileOnbase);
  }

  handleModal = async (codOnBase, idDoc) => {
    if (this.state.isOpen == true) {
      this.setState({
        isOpen: false,
        codOnBase: 0,
        idDoc: 0
      });
    } else {
      this.setState({
        isOpen: true,
        codOnBase: codOnBase,
        idDoc: idDoc
      });
    }
    this.render();
  }

  anexarArquivosRequest = async ({ file, onSuccess, onError }) => {
    const data = await onSendDocument(this.state.codOnBase, this.state.idDoc, file);
    if(data.status === 200) {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
      Swal.fire({
        title:  `Arquivo enviado com sucesso para o OnBase!`,
        icon: 'success',
        showConfirmButton: false,
        timer: 3000,
      }).then((result) => {
        setInterval(function() {
          window.location.reload();
        }, 2000);
      });
      this.handleModal();
    } else {
      setTimeout(() => {
        onError('ok');
      }, 0);
      Swal.fire({
        title:  `Não foi possível realizar o envio de documentos para a OnBase`,
        text: 'Tente novamente dentro de alguns minutos. Persistindo o problema acione o TechPolos',
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }

  buscaPolo = (search) => {
    if (search && search.length > 1) {
      let optionsArray = this.state.listPolos;

      const searchArray =
        optionsArray.length > 0
          ? optionsArray.filter((item) =>
            item.nome.includes(search.toUpperCase())
          )
          : [];

        this.setState({ listPolos: searchArray });
    } else {
      this.setState({ listPolos: this.state.polos });
    }
  }

  handleSelectPolo = (id) => {
    this.state.listPolos.forEach(item => {
      if(item.id == id) {
        this.state.search.polo = item.nome;
      }
    })
  }

  render() {
    const tableColumns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: 40,
        align: 'center',
      },
      {
        title: 'Polo',
        key: 'polo',
        dataIndex: 'polo',
        align: 'center',
      },
      {
        title: 'Documento',
        key: 'documento',
        dataIndex: 'documento',
        align: 'center',
      },
      {
        title: 'Validade',
        key: 'validade',
        dataIndex: 'validade',
        width: 130,
        align: 'center',
        render: (row, record, index) => {
          return (
            this.props.isAdmin ?
            <Form.Item>
              {getFieldDecorator(`date-${record.id}`, {
                initialValue: moment(row, 'DD/MM/YYYY')
              })(
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  locale={locale}
                  size="default"
                  onBlur={(e) => (
                    e.target.value === row || e.target.value !== undefined ?
                      this.handleUpdateTable(record.id, "validade", e.target.value) :
                      ''
                  )}
                />
              )}
            </Form.Item>
            :
            <label>{row}</label>
          )
        }
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        render: (row, record, index) => {
          return (
            this.props.isAdmin ?
            <Form.Item>
              {getFieldDecorator(`status-${record.id}`, {
                initialValue: row
              })(
                <Select
                  size="default"
                  placeholder="Status"
                  onBlur={(e) => (
                    e === row || e !== undefined ?
                      this.handleUpdateTable(record.id, "status", e) :
                      ''
                  )}
                >
                  <Option key="Não Enviado">Não Enviado</Option>
                  <Option key="Não Preenchido">Não Preenchido</Option>
                  <Option key="Não Assinado">Não Assinado</Option>
                  <Option key="Em Análise">Em Análise</Option>
                  <Option key="Rejeitado">Rejeitado</Option>
                  <Option key="Validado">Validado</Option>
                </Select>
              )}
            </Form.Item>
            :
            <label>{row}</label>
          )
        }
      },
      {
        title: 'Observação',
        key: 'observacao',
        dataIndex: 'observacao',
        width: 200,
        align: 'center',
        render: (row, record) => {
          if (record.status !== 'Em Análise' && record.status !== 'Validado') {
          return (
              <Form.Item>
                {getFieldDecorator(`observacao-${record.id}`, {
                  initialValue: row,
                })(
                  <TextArea
                    size="large"
                    placeholder="Insira aqui a observação"
                    rows={4}
                    maxLength={100}
                    onBlur={(e) => (
                      e === row || e !== undefined ?
                        this.handleUpdateTable(record.id, "observacao", e.target.value) :
                        ''
                    )}
                  />
                )}
              </Form.Item>
              )
            } else {
              return ('');
            }
        },
      },
      {
        title: 'Açoes',
        key: 'acoes',
        dataIndex: 'tipoDocOnbase.habilitaEnvio',
        width: 80,
        align: 'center',
        render: (row, record, index) => {
          if (record.status !== 'Em Análise' && record.status !== 'Validado') {
            return (
              <Button
                type="primary"
                size="default"
                onClick={() => this.handleModal(record.tipoDocOnbase.codOnbase, record.id)}
                style={{
                  backgroundColor: '#dca04b',
                  borderColor: '#dca04b'
                }}
              >
                <FontAwesomeIcon icon={['fas', 'file']} /> &nbsp;
                Enviar documento
              </Button>
            );
          } else {
            return null;
          }
        }
      },
    ]

    const { getFieldDecorator } = this.props.form;
    return (
      <section className="workflowContainer">
        <Row xl={24}>
          <Col xl={5}>
            <Form.Item label="Documento" size="large">
              {this.props.form.getFieldDecorator(`documento`, {
                initialValue: '',
              })(
                <Select
                  size="large"
                  placeholder="Selecione um documento"
                  onChange={(e) => this.state.search.documento = e}
                  style={{ width: '100%' }}
                >
                  {this.state.listDocsOnBase.map(this.renderDocs)}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={5} className="margin-1-left">
            <Form.Item label="Polo" size="large">
              {this.props.form.getFieldDecorator(`polo`, {
                initialValue: '',
              })(
                <AutoComplete
                  size="large"
                  placeholder="Pesquise o Polo"
                  onSelect={(e) =>  this.handleSelectPolo(e)}
                  dataSource={this.state.listPolos.map(this.renderOption)}
                  onSearch={this.buscaPolo}
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={4} className="margin-1-left">
            <Form.Item label="Status" size="large">
              {this.props.form.getFieldDecorator(`status`, {
                initialValue: '',
              })(
                <Select
                  size="large"
                  placeholder="Selecione o status"
                  onChange={(e) => this.state.search.status = e}
                >
                  <Option key="Não Enviado">Não Enviado</Option>
                  <Option key="Não Preenchido">Não Preenchido</Option>
                  <Option key="Não Assinado">Não Assinado</Option>
                  <Option key="Em Análise">Em Análise</Option>
                  <Option key="Expirado">Expirado</Option>
                  <Option key="Rejeitado">Rejeitado</Option>
                  <Option key="Validado">Validado</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={4} className="margin-1-left">
            <Form.Item label="Validade" size="large">
              {this.props.form.getFieldDecorator(`validade`, {
                initialValue: '',
              })(
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  locale={locale}
                  size="large"
                  onChange={(e) => {
                    if (e) {
                      this.state.search.validade = e.format('DD/MM/YYYY');
                    } else {
                      this.state.search.validade = null;
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={2} className="margin-1-left">
            <Button
              type="primary"
              size="large"
              style={{ marginTop: '2.5rem' }}
              onClick={() => this.handleSearchDocs()}
            >
              <FontAwesomeIcon icon={['fas', 'search']} /> &nbsp;
              Pesquisar
            </Button>
          </Col>
          <Col xl={1} className="margin-1-left">
            <Button
                type="primary"
                shape="circle"
                size="large"
                style={{
                  'background-color': '#52c41a',
                  'margin-left': '1.5rem',
                  border: 'none',
                  'margin-top': '2.5rem',
                }}
                onClick={() => this.clearSearch()}
              >
                <Icon type="reload" />
              </Button>
          </Col>
        </Row>
        <Row xl={24} className="margin-1-top">
          <Table
            dataSource={this.state.listDocs}
            columns={tableColumns}
            size="small"
          />
        </Row>
        <Row xl={24}>
          <Button
            type="primary"
            size="default"
            onClick={this.handleExportCSV}
          >
            <FontAwesomeIcon icon={['fas', 'download']} /> &nbsp;
            Exportar
          </Button>
        </Row>
        <Modal
          title="Envio de documentos do polo"
          visible={this.state.isOpen}
          destroyOnClose={true}
          width={600}
          onOk={this.handleModal}
          onCancel={this.handleModal}
          footer={[
            <Button key="back" onClick={this.handleModal}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleModal}>
              Importar Documento
            </Button>
          ]}
          centered
        >
          <Row>
            <Upload  />
            <Dragger
              accept=".pdf, .png, .jpg"
              customRequest={this.anexarArquivosRequest}
            >
              <p>Tipo de arquivo aceito: jpg, jpeg, png e pdf</p>
              <p className="ant-upload-text">Enviar Documento</p>
            </Dragger>
          </Row>
        </Modal>
      </section>
    );
  }
}

export default Form.create()(WorkFlowTable);
