import React from 'react';
import {
  Button,
  Icon,
  Input,
  Modal,
  Select,
  Table,
  DatePicker,
  Form,
  Row,
} from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { ComunicadosColumns } from './comunicadosColumns';
import styles from '../ComunicadosSection/style.module.css';
import { Column, FieldBox, HeaderBox, IconBox } from './styles';
import api from 'services/api';
import moment from 'moment';
const { confirm } = Modal;

class ComunicadosTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      loading: false,
      pagination: { position: 'bottom' },
      lstData: [],
      buscaTitulo: '',
      selecionados: [],
      ativo: undefined,
      date: ['', ''],
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.fetch = this.fetch.bind(this);
    this.inativaOuAtivaComunicados = this.inativaOuAtivaComunicados.bind(this);
  }

  componentDidMount() {
    this.initialFetch();
  }

  initialFetch = async () => {
    try {
      this.setState({ loading: true, selectedRowKeys: [] });
      let res;

      res = await api.get(`comunicado/getAll`, {
        params: {
          page: 0,
          linesPerPage: 10
        },
      });
      this.setState({
        loading: false,
        pagination: {
          total: res.data.totalElements + 4,
          current: 1,
        },
        lstData: res.data.content,
      });
    } catch (error) {
      this.setState({ loading: false });
      Swal.fire({
        title: 'Erro',
        text: 'Algo aconteceu.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  fetch = async (params = {}) => {
    try {
      this.setState({ loading: true, selectedRowKeys: [] });
      let res;

      if (Object.keys(params).length > 0) {
        res = await api.get(`comunicado/filter`, {
          params: {
            titulo: params.busca || undefined,
            ativo: params.ativo || undefined,
            dataInicial: params.date?.[0] || undefined,
            dataFinal: params.date?.[1] || undefined,
            page: params.page || 0,
          },
        });
      } else {
        res = await api.get(`comunicado/page?page=${params.page}`);
      }

      this.setState({
        loading: false,
        pagination: {
          total: res.data.totalElements + 4,
          current: params.page + 1,
        },
        lstData: res.data.content,
      });
    } catch (error) {
      if (error.response.data.message) {
        this.setState({ loading: false });
        Swal.fire({
          Title: 'Atenção',
          text: error?.response?.data?.message?.[0],
        });
        return;
      }
      this.setState({ loading: false });
      Swal.fire({
        title: 'Erro',
        text: 'Algo aconteceu.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  inativaOuAtivaComunicados = async (operacao) => {
    try {
      await api.put(
        `comunicado/${operacao}`,
        this.state.selecionados
      );
      Swal.fire({
        position: 'top-end',
        title: `Operação realizada com sucesso! `,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        window.location.reload();
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi possivel realizar operação.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selecionados: selectedRows.map((item) => (item.id)),
    });
  };

  onChange = (value, field) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current - 1;
    this.setState({ pagination: { pager } });

    this.fetch({
      size: this.state.pagination.pageSize,
      page: pager.current,
      busca: this.state.buscaTitulo !== '' ? this.state.buscaTitulo : null,
      ativo: this.state.ativo,
      date: this.state.date,
    });
  };

  clearFields = () =>
    this.setState({
      buscaTitulo: null,
      ativo: undefined,
      date: ['', ''],
    });

  render() {
    const {
      loading,
      selectedRowKeys,
      pagination,
      lstData,
      buscaTitulo,
      ativo,
      date,
    } = this.state;

    const inativaOuAtivaComunicados = this.inativaOuAtivaComunicados;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'ativo',
          text: (
            <>
              <Icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
              />{' '}
              Ativar Comunicados
            </>
          ),
          onSelect: () => {
            if (selectedRowKeys && selectedRowKeys.length > 0) {
              confirm(
                {
                  centered: true,
                  title: 'Você deseja realmente ATIVAR esses comunicados?',
                  content: 'Essa ação ativa todos os comunicados selecionados',
                  onOk() {
                    inativaOuAtivaComunicados('ativar');
                  },
                },
                this
              );
            }
          },
        },
        {
          key: 'inativar',
          text: (
            <>
              <Icon
                type="minus-circle"
                theme="twoTone"
                twoToneColor="#ff0000"
              />{' '}
              Inativar Comunicados
            </>
          ),
          onSelect: () => {
            if (selectedRowKeys && selectedRowKeys.length > 0) {
              confirm({
                centered: true,
                title: 'Você deseja realmente INATIVAR esses comunicados?',
                content: 'Essa ação inativa todos os comunicados selecionados',
                onOk() {
                  inativaOuAtivaComunicados('inativar ');
                },
              });
            }
          },
        },
      ],
    };

    return (
      <>
          <FieldBox className="column" style={{ textAlign: 'end' }}>
            <Form.Item
              label="Titulo"
              style={{
                textAlign: 'justify',
                marginTop: '-11px',
                width: '50%'
              }}
            >
            <Input
              placeholder="Digite por titulo..."
              className={styles.action}
              value={buscaTitulo}
              onChange={(e) =>
                this.onChange(e.target.value.trim(), 'buscaTitulo')
              }
              onBlur={(e) => (e.target.value = e.target.value.trim())}
              maxLength={100}
            />
            </Form.Item>
            <Form.Item
              style={{
                textAlign: 'justify',
                marginTop: '-11px',
                width: '25%'
              }}
              label="Data do comunicado"
            >
              <DatePicker.RangePicker
                onChange={(dates, dateString) => {
                  this.onChange(dateString, 'date');
                }}
                value={
                  date[0]
                    ? [
                        moment(date[0], 'DD-MM-YYYY'),
                        moment(date[1], 'DD-MM-YYYY'),
                      ]
                    : undefined
                }
                size="large"
                format="DD-MM-YYYY"
                placeholder={['Data início', 'Data fim']}
              />
            </Form.Item>
            <Form.Item
              label="Status"
              style={{
                textAlign: 'justify',
                marginTop: '-11px',
                width: '15%',
              }}
            >
              <Select
              value={ativo}
              onChange={(value) => this.onChange(value, 'ativo')}
              placeholder="Status"
            >
              <Select.Option key="S">ATIVO</Select.Option>
              <Select.Option key="N">INATIVO</Select.Option>
            </Select>
            </Form.Item>
            <IconBox>
              <Button
                icon="search"
                type="primary"
                title="Buscar comunicado por titulo"
                onClick={() =>
                  this.fetch({
                    page: 0,
                    busca: buscaTitulo,
                    ativo,
                    date,
                  })
                }
              />
              <Button
                icon="undo"
                title="Recarregar a lista"
                onClick={() => {
                  this.clearFields();
                  this.initialFetch();
                }}
              />
              <Button title="Limpar" onClick={() => this.clearFields()}>
                <ClearOutlined />
              </Button>
            </IconBox>
          </FieldBox>
          <FieldBox className="column" style={{ textAlign: 'end' }}>
            <Form.Item>
            <b style={{ float: 'left' }}>
              {' '}
              Quantidade de comunicados selecionados: {selectedRowKeys.length}
            </b>
            </Form.Item>
            <Form.Item>
              <Button style={{marginLeft: '20px'}}
                onClick={() => this.inativaOuAtivaComunicados("ativar")}
              >
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
                Ativar Comunicados
              </Button>
              <Button style={{marginLeft: '20px'}}
                onClick={() => this.inativaOuAtivaComunicados("inativar")}
              >
                <Icon
                  type="minus-circle"
                  theme="twoTone"
                  twoToneColor="#ff0000"
                />
                Inativar Comunicados
              </Button>
            </Form.Item>
          </FieldBox>
        <Table
          pagination={pagination}
          dataSource={lstData}
          loading={loading}
          rowSelection={rowSelection}
          columns={ComunicadosColumns}
          onChange={this.handleTableChange}
          size="small"
        />
      </>
    );
  }
}

export default ComunicadosTable;
