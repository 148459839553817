import api from 'services/api';
import { moment } from 'services/Gerais';

export const paramLimit = async () => {
  try {
    const res = await api.get(`usuario/listUsuario/paramLimit`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const list = async () => await api.get(`documentos/`);

export const item = async (id) => {
  try {
    const res = await api.get(`documentos/documento/${id}/edit`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const findByFilters = async (params) => {
  if(params.dataGeracao !== undefined){
    params.dataGeracao = moment(params.dataGeracao).format("DD-MM-YYYY");
  }
  return await api.get('documentos/filter', { params: params });
};

export const register = async (body) => {
  try {
    const res = await api.post(`documento/`, body);
    return res;
  } catch (error) {
    throw error;
  }
};

export const update = async (body, id) => {
  try {
    const res = await api.put(`/documento/${id}`, body);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

//CADASTRO
export const cofre = async () => {
  return await api.get(`documento/cofre/`);
};

//CADASTRO
export const tipoDocumento = async () => {
  return await api.get(`documento/tipo/filter`);
};

export const download = async (id) => {
  return await api.get(`documentos/documento/${id}/download`);
};

export const polo = async (params) => {
  return await api.get(`documento/contrato/basePolo/filter`, {
    params: { ...params },
  });
};

export const enviar = async (id) => {
  return await api.put(`documentos/documento/${id}/enviar`);
};

export const logError = async (id) => {
  return await api.get(`documentos/documento/${id}/log`);
};

export const validaPolo = async (params) => {
  return await api.post(`documento/polobase/`, params)
}

export const onRegerar = async (id, templateUrl) => {
  return await api.put(`documentos/documento/${id}/regerar`, templateUrl, {headers: {'Content-Type': 'application/json'}});
}
