import {
  DADO_AGENDA_SUCCESS,
  DADO_AGENDA_ERROR,
  DADO_AGENDA_CHANGE_POLO
} from "../../../actions/Administrativo/Agenda/AgendaType";

const initialState = {
  carregando: true,
  error:'',
  Polo:[],
  Marca:[],
  Categoria:[]
};

const dadosPerfil = (state = initialState, action) => {
  switch (action.type) {
    case DADO_AGENDA_SUCCESS:
      return action.payload
    case DADO_AGENDA_ERROR:
      return {...state , ...action.payload}
    case DADO_AGENDA_CHANGE_POLO:
      return {
        ...state,
        Polo: action.payload
      }
    default:
      return state;
  }
};

export default dadosPerfil;
