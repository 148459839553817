import React, { useState, useEffect } from 'react';
import { Table, Form, Row, Col, DatePicker, Select, Input } from 'antd';

import { useForm, Controller } from 'react-hook-form';

import { tableColumns } from './TableProps';
import { TableController } from './TableController';
import { Container, ButtonSubmit } from './styled';

const { Option } = Select;

const DocumentTypes = () => {
  const { handleSubmit, control } = useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const props = {
    state: {
      data: { data, setData },
      loading: { loading, setLoading },
    },
  };

  const tableController = new TableController(props);

  useEffect(() => {
    tableController.get();
  }, []);

  return (
    <Container key="HeaderSearchPortais">
      <Form
        onSubmit={handleSubmit((e) => tableController.handleSubmit(e))}
        style={{ marginTop: 20 }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <Form.Item label="Tipo de Documento" size="large">
              <Controller
                name="tipoDocumento"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    disabled={loading}
                    data-testid="teste-titulo"
                    placeholder="Tipo de Documento"
                    size="large"
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={10}>
            <Form.Item label="Status" size="large">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    size="large"
                    {...field}
                    placeholder="Status"
                    disabled={loading}
                  >
                    <Option value="ATIVO">Ativo</Option>
                    <Option value="INATIVO">Inativo</Option>
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} className="submitContainer">
            <ButtonSubmit type="primary" htmlType="submit" loading={loading}>
              Pesquisar
            </ButtonSubmit>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        dataSource={data}
        columns={tableColumns}
        size="small"
      />
    </Container>
  );
};

export default DocumentTypes;
