import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  align-items: center;
  justify-content: center;

  * {
    font-family: 'open_sanssemibold', sans-serif;
  }
`;

export const Line = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;

  .ant-select-selection {
    background-color: #0072c6;
  }

  .ant-select {
    * {
      color: #fff;
    }
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    background-color: #3273dc;
    border: 1px solid #0072c6;
  }
`;

export const Button = styled.button`
  all: unset;
  flex: 1;
  height: 65px;
  background: ${(props) => (props.active ? '#3273dc' : '#cdcdcd')};
  border-bottom: 2px solid #3273dc;
  transition: all ease 0.3s;
  color: ${(props) => (props.active ? '#fff' : '#000')};
  text-align: center;
  cursor: pointer;

  :hover {
    filter: brightness(110%);
  }
`;

export const RegisterBox = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;
