import { useEffect, useState } from 'react';
import { logFAQ } from '../../../../config/logs';
import { fetchFaq, getFaqSearch, getFaqByCategory } from 'services/faq';

const useCommonQuestions = ({ palavraChave }) => {
  const [faqData, setFaqData] = useState();
  const [pastKeys, setPastKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const fetch = !palavraChave ? fetchFaq : getFaqByCategory;
    try {
      var newurl = window.location.protocol + "//" + window.location.host + '/faq';
      window.history.pushState({path:newurl},'',newurl);
      setLoading(true);
      const { status, data } = await fetch(palavraChave);
      if (status === 200) {
        setFaqData(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const saveLogFAQ = async (key) => {
    setPastKeys(key);
    if (key.length < pastKeys.length) return;
    if (key[0] != undefined) {
      let convertKey = parseInt(key[key.length - 1]);
      await logFAQ(convertKey);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return { faqData, loading, saveLogFAQ };
};

export { useCommonQuestions };
