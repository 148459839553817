import React from 'react';
import { useCategory } from './hook';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../services/Gerais';
import { Container, Box } from './styles';
import { Input, Select, Form, Row, Col, Button, Tooltip } from 'antd';

const Category = ({
  match: {
    params: { categoryId },
  },
}) => {
  const {
    buttonDisabled,
    categoryData,
    finish,
    loading,
    onChange,
  } = useCategory({
    categoryId,
  });

  return (
    <Container>
      <Helmet
        key="TitleAdminFaqCategory"
        title="FAQ - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'FAQ - Cadastro Categoria' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'category',
          },
        ]}
      />
      <HeaderAdmin key="HeaderCategory" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminFaqCategory" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroFaqCategory"
                pageName="CATEGORIA"
                titulo={
                  categoryId
                    ? 'FAQ - Edição Categoria'
                    : 'FAQ - Cadastro Categoria'
                }
              />
            </div>
          </div>
          <Form>
            <Row gutter={32}>
              <Col span={8}>
                <Form.Item label="Categoria">
                  <Input
                    value={categoryData.categoria}
                    onChange={(event) =>
                      onChange(event.target.value, 'categoria')
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Palavra Chave">
                  <Input
                    value={categoryData.palavraChave}
                    onChange={(event) =>
                      onChange(event.target.value, 'palavraChave')
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Status">
                  <Select
                    value={categoryData.ativo}
                    onChange={(event) => onChange(event, 'ativo')}
                    placeholder="Selecione"
                    size="large"
                  >
                    <Select.Option key="S">ATIVO</Select.Option>
                    <Select.Option key="N">INATIVO</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" gutter={32}>
              <Col
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                span={32}
              >
                <Tooltip
                  title={
                    buttonDisabled
                      ? 'Todos os campos devem estar preenchidos!'
                      : ''
                  }
                >
                  <Button
                    onClick={async () => await finish()}
                    {...{ loading }}
                    disabled={buttonDisabled}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
      <FooterAdmin key="FooterAdminAtribuiçõesCadastro" />
    </Container>
  );
};

export default Category;
