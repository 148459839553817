import React, { Component } from 'reactn';
import { axios, moment, FontAwesomeIcon } from '../../../../services/Gerais';
import { Row, Col, Button, Form, Select, Input, DatePicker, Icon } from 'antd';

import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import Notificacao from '../../../../components/Notificacao';
import 'antd/dist/antd.css';

const ButtonGroup = Button.Group;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

class NovoEvento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorias: this.getCategorias(),
      tipo: this.props.tipo,
      idTipo: this.props.idTipo,
      atividades: [],
      eventoPai: [],
      labelEvento: 'Macro Atividade',
      alert: {
        show: false,
        message: '',
        description: '',
        type: '',
        icon: false,
      },

      startValue: null,
      endValue: null,
      endOpen: false,
      loading: false,
    };

    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.configRangeDate = this.configRangeDate.bind(this);
    this.resultsDiv = React.createRef();
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.getEventoPai();
  }

  getEventoPai = () => {
    const { tipo, atividadeID, macroID } = this.props;
    switch (tipo) {
      case 'atividade':
        return this.getEventoByID('macroAtividade', macroID, 'Macro Atividade');
      case 'micro-atividade':
        return this.getEventoByID('atividade', atividadeID, 'Atividade');
      default:
        break;
    }
  };

  getEventoByID = (endPoint, id, label) => {
    const url = `${this.global.baseUrl}${endPoint}/${id}`;
    axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then(respMap => {
        let respondeData =
          endPoint === 'atividade' ? respMap.data : respMap.data.MacroAtividade;
        this.setState({
          eventoPai: respondeData,
          labelEvento: label,
          startValue: respondeData.dataInicio,
          endValue: respondeData.dataFim,
          loading: false,
        });
      })
      .catch(errorDisplay =>
        this.setState({
          errorDisplay,
          isLoading: false,
          loading: false,
        })
      );
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const dataInicio = moment(values.dataRange[0]).format('DD-MM-YYYY');
        const dataFim = moment(values.dataRange[1]).format('DD-MM-YYYY');

        const { tipo, idTipo } = this.state;
        const objPost = {
          dataInicio: dataInicio,
          situacao: values.situacao,
          titulo: values.titulo,
          descricao: values.descricao,
          ciclo: values.ciclo,
          usuarioPortalLog: {
            id: this.logInfo['usuarioPortalLog']['id'],
          },
        };

        switch (tipo) {
          case 'atividade':
            Object.assign(objPost, {
              dataFim: dataFim,
              macroAtividade: {
                id: idTipo,
              },
              categoria: {
                id: values.categoria,
              },
            });
            break;
          case 'micro-atividade':
            Object.assign(objPost, {
              dataFim: dataFim,
              atividade: {
                id: idTipo,
              },
            });
            break;
          default:
            Object.assign(objPost, {
              dataFim: dataFim,
              anoVigente: 2019,
              categoria: {
                id: values.categoria,
              },
            });
            break;
        }

        let endPoint = '';

        if (values.eventoTipo === 'macro-atividade') {
          endPoint = 'macroAtividade/';
        } else if (values.eventoTipo === 'evento') {
          endPoint = 'evento/';
        } else {
          switch (tipo) {
            case 'macro-atividade':
              endPoint = 'macroAtividade/';
              break;
            case 'atividade':
              endPoint = 'atividade/';
              break;
            case 'micro-atividade':
              endPoint = 'microAtividade/';
              break;
            case 'evento':
              endPoint = 'evento/';
              break;
            default:
              break;
          }
        }

        axios
          .post(`${this.global.baseUrl}${endPoint}`, objPost, {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
          })
          .then(infosRes => {
            this.setState({
              alert: {
                message: 'Sucesso!',
                description: 'Cadastro efetuado',
                type: 200,
                icon: true,
                loading: false,
              },
            });
            this.alert();
          })
          .catch(error => {
            this.setState({
              alert: {
                message: 'Erro ao cadastrar',
                description: error.response.data.error,
                type: error.response.status,
                icon: true,
                loading: false,
              },
            });
            this.alert();
          });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  getCategorias = () => {
    return [
      {
        value: 1,
        label: 'Comercial',
        color: '#FFECC9',
      },
      {
        value: 2,
        label: 'Operações',
        color: '#EAE2FE',
      },
      {
        value: 3,
        label: 'Acadêmico',
        color: '#C6E8C9',
      },
      {
        value: 4,
        label: 'Financeiro',
        color: '#CFECFF',
      },
      {
        value: 5,
        label: 'Pós Graduação',
        color: '#FFECC9',
      }
    ];
  };

  OptionsCategoria = () => {
    const { categorias } = this.state;
    return categorias.map((key, index) => {
      return (
        <Option key={index} value={key.value}>
          {key.label}
        </Option>
      );
    });
  };

  handleClose = () => {
    this.setState({ alert: { show: false } });
  };

  alert = () => {
    const { message, description, type } = this.state.alert;

    let typeError;
    switch (type) {
      case 200:
        typeError = 'success';
        break;
      case 400:
        typeError = 'warning';
        break;
      case 401:
        typeError = 'error';
        break;
      case 500:
        typeError = 'error';
        break;
      default:
        typeError = 'info';
        break;
    }
    Notificacao(typeError, message, description);
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  disabledDate = current => {
    const { startValue, endValue } = this.state;

    let start = moment(startValue, 'DD-MM-YYYY');
    let end = moment(endValue, 'DD-MM-YYYY');
    if (current < moment(start)) {
      return true;
    } else if (current > moment(end)) {
      return true;
    } else {
      return false;
    }
  };

  configRangeDate = () => {
    const { startValue, endValue } = this.state;
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Por Favor selecione um periodo',
        },
      ],
    };
    if (startValue !== null && endValue !== null) {
      let starDate = moment(startValue, 'DD-MM-YYYY');
      let endDate = moment(endValue, 'DD-MM-YYYY');
      Object.assign(rangeConfig, {
        initialValue: [starDate, endDate],
      });
    }

    return rangeConfig;
  };

  cicloVal = ciclo => {
    switch (ciclo) {
      case 'V':
        return (
          <span>
            <FontAwesomeIcon icon={['fas', 'sun']} color="#ffd43b" /> Verão
          </span>
        );
      case 'I':
        return (
          <span>
            <FontAwesomeIcon icon={['far', 'snowflake']} color="#008dff" />{' '}
            Inverno
          </span>
        );
      case 'A':
        return (
          <span>
            <FontAwesomeIcon icon={['fas', 'sun']} color="#ffd43b" />{' '}
            <FontAwesomeIcon icon={['far', 'snowflake']} color="#008dff" />{' '}
            Ambos
          </span>
        );
      default:
        break;
    }
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      startValue,
      endValue,
    } = this.props.form;
    const { tipo } = this.props;
    const { eventoPai, labelEvento } = this.state;

    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Por Favor selecione um periodo',
        },
      ],
    };

    if (eventoPai.length > 0) {
      let starDate = moment(startValue, 'DD-MM-YYYY');
      let endDate = moment(endValue, 'DD-MM-YYYY');
      Object.assign(rangeConfig, {
        initialValue: [starDate, endDate],
      });
    }

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <div ref={this.resultsDiv}>
            <Row gutter={16}>
              <Col span={12} className="gutter-row">
                <Form.Item label="Titulo Evento" size="large">
                  {getFieldDecorator('titulo', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor insira um titulo',
                      },
                    ],
                  })(<Input placeholder="Titulo evento" size="large" onBlur={e => e.target.value = e.target.value.trim()}/>)}
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                {tipo === 'micro-atividade' || tipo === 'atividade' ? (
                  <div>
                    <Col
                      span={12}
                      key={`${eventoPai.id}-Periodo`}
                      className="gutter-row"
                    >
                      <Form.Item
                        label={`${labelEvento} Selecionada`}
                        size="large"
                      >
                        <Input
                          placeholder={`${eventoPai.id} - ${eventoPai.titulo}`}
                          value={`${eventoPai.id} - ${eventoPai.titulo}`}
                          disabled
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      key={`${eventoPai.id}`}
                      className="gutter-row"
                    >
                      <Form.Item label={`${labelEvento} Periodo`} size="large">
                        <RangePicker
                          defaultValue={[
                            moment(eventoPai.dataInicio, 'DD-MM-YYYY'),
                            moment(eventoPai.dataFim, 'DD-MM-YYYY'),
                          ]}
                          format="DD-MM-YYYY"
                          disabled
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </div>
                ) : (
                  <Form.Item label="Tipo Evento" size="large">
                    {getFieldDecorator('eventoTipo', {
                      rules: [
                        {
                          required: true,
                          message: 'Por Favor Selecione um tipo de Evento',
                        },
                      ],
                    })(
                      <Select placeholder="Selecione um tipo" size="large">
                        <Option value="macro-atividade">Macroatividade</Option>
                        <Option value="evento">Evento</Option>
                      </Select>
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6} className="gutter-row">
                <Form.Item label="Data Inicio / Data Fim" size="large">
                  {getFieldDecorator('dataRange', this.configRangeDate())(
                    <RangePicker
                      locale={locale}
                      onChange={this.onChange}
                      size="large"
                      format="DD-MM-YYYY"
                      disabledDate={this.disabledDate}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6} className="gutter-row">
                <Form.Item label="Ciclo" size="large">
                  {getFieldDecorator('ciclo', {
                    initialValue: eventoPai.ciclo || [],
                    //valuePropName: 'Option',
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecione um Ciclo',
                      },
                    ],
                  })(
                    <Select
                      placeholder="Selecione um ciclo"
                      size="large"
                      disabled={
                        tipo === 'micro-atividade' || tipo === 'atividade'
                          ? true
                          : false
                      }
                    >
                      <Option value="V">{this.cicloVal('V')}</Option>
                      <Option value="I">{this.cicloVal('I')}</Option>
                      <Option value="A">{this.cicloVal('A')}</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {(tipo !== 'micro-atividade' || tipo !== 'atividade') && (
                <Col span={6} className="gutter-row">
                  <Form.Item label="Categoria" size="large">
                    {getFieldDecorator('categoria', {
                      initialValue:
                        (eventoPai.categoria !== undefined &&
                          eventoPai.categoria.id) ||
                        [],
                      rules: [
                        {
                          required: true,
                          message: 'Por favor insira uma categoria',
                        },
                      ],
                    })(
                      <Select
                        size="large"
                        placeholder="Selecione uma Categoria"
                        disabled={
                          tipo === 'micro-atividade' || tipo === 'atividade'
                            ? true
                            : false
                        }
                      >
                        {this.OptionsCategoria()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              )}
              <Col span={6} className="gutter-row">
                <Form.Item label="Situação" size="large">
                  {getFieldDecorator('situacao', {
                    //initialValue: (eventoPai !== undefined) && eventoPai.situacao || [],
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecione a situação do registro',
                      },
                    ],
                  })(
                    <Select size="large" placeholder="Selecione">
                      <Option value="A">
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{' '}
                        Ativo
                      </Option>
                      <Option value="I">
                        <Icon
                          type="close-circle"
                          theme="twoTone"
                          twoToneColor="#ff3860"
                        />{' '}
                        Inativo
                      </Option>
                      <Option value="P">
                        <Icon
                          type="clock-circle"
                          theme="twoTone"
                          twoToneColor="#ffdd57"
                        />{' '}
                        Pendente
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className="gutter-row">
                <Form.Item label="Descrição" size="large">
                  {getFieldDecorator('descricao')(
                    <TextArea
                      size="large"
                      placeholder="Inclua aqui a descrição do Detalhada do Evento"
                      rows={4}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="end" gutter={16}>
              <Col span={5} justify="end" className="gutter-row">
                <Form.Item size="large">
                  <ButtonGroup size="large" block>
                    <Button href="/admin/mapa">
                      <Icon type="stop" />
                      Cancelar
                    </Button>
                    {/*<Button>Novo Eveto</Button>
                  <Button>Nova Atividade</Button>*/}
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        'background-color': '#52c41a',
                        border: 'none',
                      }}
                      disabled={this.hasErrors(getFieldsError())}
                    >
                      <Icon type="save" /> Salvar
                    </Button>
                  </ButtonGroup>
                  <input type="hidden" value="2019" name="anoVigente" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
        <div>
          {/*(tipo === "micro-atividade" || tipo === "atividade") && (
        <Row>
          <Col span={24} justify="end" className="gutter-row">
            <Divider>{`${labelEvento} | ${eventoPai.titulo}`}</Divider>
            <Lista tipo={tipo} id={idTipo} />
          </Col>
        </Row>
      )*/}
        </div>
      </div>
    );
  }
}
export default Form.create()(NovoEvento);
