import React, { Component } from 'reactn';
import { axios, moment, FontAwesomeIcon } from '../../../../services/Gerais';
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  Input,
  DatePicker,
  Spin,
  Icon,
} from 'antd';

import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import 'antd/dist/antd.css';
import Notificacao from '../../../../components/Notificacao';

const ButtonGroup = Button.Group;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

class EditarEvento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorias: this.getCategorias(),
      tipo: this.props.tipo,
      idTipo: this.props.idTipo,
      eventoData: [],
      labelEvento: 'Macro Atividade',
      alert: {
        show: false,
        message: '',
        description: '',
        type: 200,
        icon: false,
      },
      loading: false,
    };

    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.getEvento();
  }

  getEvento = () => {
    const { tipoEvento, eventoID } = this.props;
    switch (tipoEvento) {
      case 'atividade':
        return this.getEventoByID(
          'atividade',
          eventoID,
          'Atividade',
          tipoEvento
        );
      case 'micro-atividade':
        return this.getEventoByID(
          'microAtividade',
          eventoID,
          'Micro Atividade',
          tipoEvento
        );
      case 'evento':
        return this.getEventoByID('evento', eventoID, 'Evento');
      case 'macro-atividade':
        return this.getEventoByID(
          'macroAtividade',
          eventoID,
          'Macro Atividade'
        );
      default:
        break;
    }
  };

  getEventoByID = (endPoint, id, label) => {
    const { tipoEvento } = this.props;
    const url = `${this.global.baseUrl}${endPoint}/${id}`;
    axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then(respMap => {
        switch (endPoint) {
          case 'atividade':
            if (tipoEvento === 'micro-atividade') {
              this.setState({
                eventoAtividade: respMap.data,
                loading: false,
              });
            } else {
              this.setState({
                eventoData: respMap.data,
                loading: false,
              });
            }
            break;
          case 'microAtividade':
            this.setState(
              {
                eventoData: respMap.data,
                loading: false,
              },
              () => {
                //this.getEventoByID('atividade', respMap.data.id, 'Atividade')
              }
            );
            break;
          case 'macroAtividade':
            if (tipoEvento === 'atividade') {
              this.setState({
                eventoMacro: respMap.data.MacroAtividade,
                loading: false,
              });
            } else {
              this.setState({
                eventoData: respMap.data.MacroAtividade,
                loading: false,
              });
            }
            break;
          case 'evento':
            this.setState({
              eventoData: respMap.data,
              loading: false,
            });
            break;
          default:
            break;
        }
      })
      .catch(errorDisplay =>
        this.setState({
          errorDisplay,
          loading: false,
        })
      );
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleSubmit = e => {
    //const objEvento = eventoData !== undefined && eventoData;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const dataInicio = moment(values.dataRange[0]).format('DD-MM-YYYY');
        const dataFim = moment(values.dataRange[1]).format('DD-MM-YYYY');

        const { tipo, eventoData } = this.state;
        const objPost = {
          id: eventoData.id,
          dataInicio: dataInicio,
          situacao: values.situacao,
          titulo: values.titulo,
          descricao: values.descricao,
          ciclo: values.ciclo,
          usuarioPortalLog: {
            id: this.logInfo['usuarioPortalLog']['id'],
          },
        };

        switch (tipo) {
          case 'macro-atividade':
            Object.assign(objPost, {
              dataFim: dataFim,
              categoria: {
                id: values.categoria,
              },
            });
            break;
          case 'atividade':
            Object.assign(objPost, {
              dataFim: dataFim,
            });
            break;
          case 'micro-atividade':
            Object.assign(objPost, {
              dataFim: dataFim,
            });
            break;
          default:
            Object.assign(objPost, {
              dataFim: dataFim,
              anoVigente: 2019,
            });
            break;
        }

        let endPoint = '';

        if (values.eventoTipo === 'macro-atividade') {
          endPoint = 'macroAtividade/';
        } else if (values.eventoTipo === 'evento') {
          endPoint = 'evento/';
        } else {
          switch (tipo) {
            case 'macro-atividade':
              endPoint = 'macroAtividade/';
              break;
            case 'atividade':
              endPoint = 'atividade/';
              break;
            case 'micro-atividade':
              endPoint = 'microAtividade/';
              break;
            case 'evento':
              endPoint = 'evento/';
              break;
            default:
              break;
          }
        }

        axios
          .put(`${this.global.baseUrl}${endPoint}`, objPost, {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (response.status === 200) {
              this.setState({
                alert: {
                  message: 'Sucesso!',
                  description: response.data.mensagem,
                  type: 200,
                  icon: true,
                },
              });
              this.alert();
            }
          })
          .catch(error => {
            this.setState({
              alert: {
                message: 'Erro ao editar',
                description: error.response.data.error,
                type: error.response.status,
                icon: true,
                loading: false,
              },
            });
            this.alert();
          });
      }
    });
  };

  getCategorias = () => {
    return [
      {
        value: 1,
        label: 'Comercial',
        color: '#FFECC9',
      },
      {
        value: 2,
        label: 'Operações',
        color: '#EAE2FE',
      },
      {
        value: 3,
        label: 'Acadêmico',
        color: '#C6E8C9',
      },
      {
        value: 4,
        label: 'Financeiro',
        color: '#CFECFF',
      },
      {
        value: 5,
        label: 'Pós Graduação',
        color: '#FFECC9',
      }
    ];
  };

  optionsCategoria = () => {
    const { categorias } = this.state;
    return categorias.map((key, index) => {
      return <Option value={key.value}>{key.label}</Option>;
    });
  };

  alert = () => {
    const { message, description, type } = this.state.alert;

    let typeError;
    switch (type) {
      case 200:
        typeError = 'success';
        break;
      case 400:
        typeError = 'warning';
        break;
      case 401:
        typeError = 'error';
        break;
      case 500:
        typeError = 'error';
        break;
      default:
        typeError = 'info';
        break;
    }

    Notificacao(typeError, message, description);
  };

  cicloVal = clico => {
    switch (clico) {
      case 'V':
        return (
          <span>
            <FontAwesomeIcon icon={['fas', 'sun']} color="#ffd43b" /> Verão
          </span>
        );
      case 'I':
        return (
          <span>
            <FontAwesomeIcon icon={['far', 'snowflake']} color="#008dff" />{' '}
            Inverno
          </span>
        );
      case 'A':
        return (
          <span>
            <FontAwesomeIcon icon={['fas', 'sun']} color="#ffd43b" />
            <FontAwesomeIcon icon={['far', 'snowflake']} color="#008dff" />
            Ambos
          </span>
        );
      default:
        break;
    }
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { tipo } = this.props;
    const { eventoData } = this.state;

    const colTipoIsMicro =
      tipo !== 'micro-atividade' && tipo !== 'atividade'
        ? { ciclo: 6, situacao: 5 }
        : { ciclo: 8, situacao: 8 };

    const objEvento = eventoData !== undefined && eventoData;

    return (
      <Spin tip="Loading..." spinning={this.state.loading} delay={500}>
        <Form onSubmit={this.handleSubmit}>
          <div>
            <Row gutter={16}>
              <Col span={24} className="gutter-row">
                <Form.Item label="Titulo Evento" size="large">
                  {getFieldDecorator('titulo', {
                    initialValue: objEvento.titulo,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor insira um titulo',
                      },
                    ],
                  })(<Input placeholder="Titulo evento" size="large" onBlur={e => e.target.value = e.target.value.trim()} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8} className="gutter-row">
                <Form.Item label="Data Inicio / Data Fim" size="large">
                  {getFieldDecorator('dataRange', {
                    initialValue: [
                      moment(objEvento.dataInicio, 'DD-MM-YYYY'),
                      moment(objEvento.dataFim, 'DD-MM-YYYY'),
                    ],
                    rules: [
                      {
                        type: 'array',
                        required: true,
                        message: 'Por Favor selecione um periodo',
                      },
                    ],
                  })(
                    <RangePicker
                      locale={locale}
                      size="large"
                      format="DD-MM-YYYY"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={colTipoIsMicro.ciclo} className="gutter-row">
                <Form.Item label="Ciclo" size="large">
                  {getFieldDecorator('ciclo', {
                    initialValue: eventoData !== undefined && eventoData.ciclo,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecione um Ciclo',
                      },
                    ],
                  })(
                    <Select placeholder="Selecione um ciclo" size="large">
                      <Option value="V">{this.cicloVal('V')}</Option>
                      <Option value="I">{this.cicloVal('I')}</Option>
                      <Option value="A">{this.cicloVal('A')}</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {tipo !== 'micro-atividade' && tipo !== 'atividade' && (
                <Col span={5} className="gutter-row">
                  <Form.Item label="Categoria" size="large">
                    {getFieldDecorator('categoria', {
                      initialValue:
                        (eventoData !== undefined &&
                          eventoData.categoria &&
                          eventoData.categoria.id) ||
                        [],
                      rules: [
                        {
                          required: true,
                          message: 'Por favor insira uma categoria',
                        },
                      ],
                    })(
                      <Select
                        size="large"
                        placeholder="Selecione uma Categoria"
                        disabled={
                          tipo === 'micro-atividade' || tipo === 'atividade'
                            ? true
                            : false
                        }
                      >
                        {this.optionsCategoria()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              )}
              <Col span={colTipoIsMicro.situacao} className="gutter-row">
                <Form.Item label="Situação" size="large">
                  {getFieldDecorator('situacao', {
                    initialValue:
                      eventoData !== undefined && eventoData.situacao,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecione a situação do registro',
                      },
                    ],
                  })(
                    <Select size="large" placeholder="Selecione">
                      <Option value="A">Ativo</Option>
                      <Option value="I">Inativo</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className="gutter-row">
                <Form.Item label="Descrição" size="large">
                  {getFieldDecorator('descricao', {
                    initialValue: objEvento.descricao,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor entre com uma descrição do evento',
                      },
                    ],
                  })(
                    <TextArea
                      size="large"
                      placeholder="Inclua aqui a descrição do Detalhada do Evento"
                      rows={4}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={16}>
              <Col span={5} justify="end" className="gutter-row">
                <Form.Item size="large">
                  <ButtonGroup size="large" block>
                    <Button href="/admin/mapa">
                      <Icon type="stop" />
                      Cancelar
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        'background-color': '#52c41a',
                        border: 'none',
                      }}
                      disabled={this.hasErrors(getFieldsError())}
                    >
                      <Icon type="edit" /> Editar
                    </Button>
                  </ButtonGroup>
                  <input type="hidden" value="2019" name="anoVigente" />
                  <input type="hidden" name="id" value={objEvento.id} />
                </Form.Item>
              </Col>
            </Row>
            {/*(tipo === "micro-atividade" || tipo === "atividade") && (
              <Row>
                <Col span={24} justify="end" className="gutter-row">
                  <Divider>{`${labelEvento} | ${objEvento.titulo}`}</Divider>
                  <Lista tipo={tipo} id={idTipo} />
                </Col>
              </Row>
            )*/}
          </div>
        </Form>
      </Spin>
    );
  }
}
export default Form.create()(EditarEvento);
