import React from 'react';

export default function Error(error) {
  let retorno = error.response.data.mensagem
    ? error.response.data.mensagem
    : error.response.data.message;
  return (
    <div className="notification is-danger">
      <strong>
        Ops...{' '}
        <span role="img" aria-label="😶">
          😶
        </span>
      </strong>
      <p>
        Ocorreu uma instabilidade no sistema... tente acessar novamente mais
        tarde.
      </p>
      {/*<input type="hidden" value={error.response.data.erro['mensagem']}/>*/}
      <input
        type="hidden"
        data-path={error.response.data.path}
        data-status={error.response.data.status}
        value={retorno}
      />
    </div>
  );
}
