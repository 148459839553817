import React from 'react';

import styles from './style.module.css';
import FiltersTable from '../FiltersTable';

const FiltersSection = (props) => {
  return (
    <section className={styles.container}>
      <FiltersTable form={props.form} id={props.id} />
    </section>
  );
};

export default FiltersSection;
