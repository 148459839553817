export const AGENDA_CHANGE = 'AGENDA_CHANGE';
export const AGENDA_CHANGE_MARCA = 'AGENDA_CHANGE_MARCA';
export const AGENDA_CHANGE_POLO = 'AGENDA_CHANGE_POLO';
export const AGENDA_ERROR = 'AGENDA_ERROR';
export const AGENDA_SUCCESS = 'AGENDA_SUCCESS';
export const AGENDA_CHANGE_POLO_SELECIONADO = 'AGENDA_CHANGE_POLO_SELECIONADO';
export const AGENDA_CHANGE_MARCA_SELECIONADA = 'AGENDA_CHANGE_MARCA_SELECIONADA'

export const DADO_AGENDA_SUCCESS = 'DADO_AGENDA_SUCCESS';
export const DADO_AGENDA_ERROR = 'DADO_AGENDA_ERROR';
export const DADO_AGENDA_CHANGE_POLO = 'DADO_AGENDA_CHANGE_POLO';
