import styled from 'styled-components';
import { Button } from 'antd';

export const Container = styled.div`
  .is-danger {
    background-color: #b96404;
  }
  .submitContainer {
    padding-top: 39.9999px;
  }
  .ant-table-row-cell-ellipsis {
    vertical-align: middle;
  }
  .ant-table-row-cell-break-word {
    vertical-align: middle;
  }
`;
export const ButtonSubmit = styled(Button)`
  width: 100%;
  height: 40px !important;
`;
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .button.is-success {
    background-color: #22c65b !important;
  }
`;
