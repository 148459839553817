import React from 'react';
import { useSearch } from './hook';
import { Container, Line, Column } from './styles';
import {
  HeaderAdmin,
  Helmet,
  Hero,
  MenuAdmin,
  FooterAdmin,
} from 'services/Gerais';
import { Form, Upload, Button, DatePicker, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

const Search = () => {
  const {
    handleImportFile,
    uploadProps,
    date,
    loading,
    onChange,
    exportButtonDisabled,
    exportCSV,
    importButtonDisabled,
  } = useSearch();
  return (
    <Container>
      <Helmet
        key="TitleAdminPesquisas"
        title="Pesquisas - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Pesquisas',
          },
          {
            name: 'keywords',
            content: 'pesquisas',
          },
        ]}
      />
      <HeaderAdmin key="HeaderPesquisas" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminPesquisas" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              {Hero({
                key: 'heroAdminPesquisas',
                pageName: 'Pesquisas',
                titulo: 'Pesquisas',
              })}
            </div>
          </div>
          <Column>
            <Line>
              <Form.Item
                style={{ marginTop: '-44px' }}
                label="Importar arquivo"
              >
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined />
                    Selecionar arquivo .csv
                  </Button>
                </Upload>
              </Form.Item>
              <Tooltip
                title={importButtonDisabled ? 'Arquivo obrigatório' : ''}
              >
                <Button
                  type="primary"
                  disabled={importButtonDisabled}
                  onClick={async () => await handleImportFile()}
                >
                  Importar arquivo
                </Button>
              </Tooltip>
            </Line>
            <Line>
              <Form.Item
                style={{ marginTop: '-44px' }}
                label="Data Inicio / Data Fim"
                size="large"
              >
                <DatePicker.RangePicker
                  onChange={(dates, dateString) => {
                    onChange(dateString);
                  }}
                  value={
                    date[0]
                      ? [
                          moment(date[0], 'DD-MM-YYYY'),
                          moment(date[1], 'DD-MM-YYYY'),
                        ]
                      : undefined
                  }
                  size="large"
                  format="DD-MM-YYYY"
                  style={{ width: '100%' }}
                  placeholder={['Data início', 'Data fim']}
                />
              </Form.Item>

              <Tooltip
                title={
                  exportButtonDisabled
                    ? 'Os campos de data devem ser preenchidos para exportação!'
                    : ''
                }
              >
                <Button
                loading={loading}
                  type="primary"
                  style={{
                    backgroundColor: '#52c41a',
                    border: 'none',
                  }}
                  disabled={exportButtonDisabled}
                  onClick={async () => await exportCSV()}
                >
                  Exportar CSV
                </Button>
              </Tooltip>
            </Line>
          </Column>
        </div>
      </section>
      <FooterAdmin key="FooterAdminSearch" />
    </Container>
  );
};

export default Search;
