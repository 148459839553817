import moment from 'moment';

const formatterSystemToSend = (formData, post) => ({
  codigo: formData.code,
  sistemaId: +formData.systemId,
  descricao: formData.describe,
  inclusao: formData.include,
  exclusao: formData.delete,
  consulta: formData.search,
  alteracao: formData.update,
  ativo: formData.status,
  usuarioInclusao: +formData.userIncludes,
  perfilId: formData.perfilId,
  usuarioAlteracao: !post ? formData.userIncludes ?? undefined : undefined,
  dataAlteracao: !post ? parseDate(new Date()) : undefined,
  dataInclusao: !formData.dateIncludes
    ? parseDate(new Date())
    : parseDate(formData.dateIncludes),
});

export const parseDate = (date) => {
  return moment(date).format('DD/MM/YYYY hh:mm:ss').replaceAll('/', '-');
};

const formatterSystemToReceive = (data) => ({
  code: data.codigo,
  describe: data.descricao,
  status: data.ativo,
  search: data.consulta,
  include: data.inclusao,
  update: data.alteracao,
  delete: data.exclusao,
  dateIncludes: data.dataInclusao ?? new Date(),
  userIncludes: data.usuarioInclusao,
  userUpdates: data.usuarioAlteracao ?? data.usuarioInclusao,
  systemId: data.sistemaId,
  perfilId: data.perfilId
});

export { formatterSystemToSend, formatterSystemToReceive };
