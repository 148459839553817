import { useState, useCallback } from 'react';
import _debounce from 'lodash/debounce';
import {
  createLinkBusinessManager,
  getUserAutocomplete,
  directorProfileId,
  managerProfileId,
  getInit
} from 'services/linkBusinessManager';
import Swal from 'sweetalert2';
import { useEffect } from 'react';

const useLinkBusinessManager = () => {
  const [listNamesDNs, setNamesDNs] = useState([]);
  const [listNamesGNs, setNamesGNs] = useState([]);
  const [businessDirector, setBusinessDirector] = useState([]);
  const [businessManager, setBusinessManager] = useState([]);
  const [businessManagerOptions, setBusinessManagerOptions] = useState([]);
  const [directorValue, setDirectorValue] = useState();
  const [businessDirectorOptions, setBusinessDirectorOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onHandleGetInit()
  }, [])

  async function onHandleGetInit() {
    const resp = await getInit();
    setNamesDNs(resp.data.businessDirectors);
    setNamesGNs(resp.data.businessManagers);
  }

  const onChangeBusinessDirector = (value) => {
    setBusinessDirector(value);
  };

  const onChangeBusinessManager = (value) => {
    let list = [];
    value.forEach(item => {
      list.push(parseInt(item))
    })
    setBusinessManager(list);
  };

  const handleOnSearchManager = async (value) => {
    try {
      const { data, status } = await getUserAutocomplete({
        profile: managerProfileId,
        value,
      });
      if (status === 200) {
        const {
          Usuarios: { content },
        } = data;
        if (content.length) {
          const handleContent = content.map(
            ({ id, perfilId, nome, cpf, conta }) => ({
              id,
              conta,
              perfilId,
              cpf,
              nome,
            })
          );
          setBusinessManagerOptions(handleContent);
        }
      }
    } catch (error) {
      Swal.fire({
        text: 'Ocorreu um problema ao buscar os dados, tente novamente',
      });
    }
  };

  const handleOnSearchDirector = async (value) => {
    try {
      const { data, status } = await getUserAutocomplete({
        profile: directorProfileId,
        value,
      });
      if (status === 200) {
        const {
          Usuarios: { content },
        } = data;
        if (content.length) {
          const handleContent = content.map(
            ({ id, perfilId, nome, cpf, conta }) => ({
              id,
              conta,
              perfilId,
              cpf,
              nome,
            })
          );
          setBusinessDirectorOptions(handleContent);
        }
      }
    } catch (error) {
      Swal.fire({
        text: 'Ocorreu um problema ao buscar os dados, tente novamente',
      });
    }
  };

  const handleSaveLink = async () => {
    const userSession = localStorage.getItem('userDataCRP');
    const parseSession = userSession ? JSON.parse(userSession) : undefined;
    try {
      setLoading(true);
      const { status } = await createLinkBusinessManager({
        body: {
          idDiretorNegocio: parseInt(businessDirector),
          idGerenteNegocio: businessManager,
          usuarioInclusao: parseSession.usuarioPortal.id,
        },
      }).then(response => {
        Swal.fire({
          position: 'top-end',
          text: 'Vinculo de gestores criado com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000,
        })
        window.location.href = `/admin/usuarios/vinculo-gn/lista/${parseInt(businessDirector)}`;
      })
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: 'top-end',
        text: error.response.data.error,
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const debounceOnSearchManager = useCallback(
    _debounce(handleOnSearchManager, 1000),
    []
  );
  const debounceOnSearchDirector = useCallback(
    _debounce(handleOnSearchDirector, 1000),
    []
  );

  const buttonDisabled = !(businessDirector.length && businessManager.length);

  return {
    businessManagerOptions,
    businessDirectorOptions,
    onChangeBusinessDirector,
    onChangeBusinessManager,
    debounceOnSearchManager,
    debounceOnSearchDirector,
    directorValue,
    handleSaveLink,
    buttonDisabled,
    loading,
    listNamesDNs,
    listNamesGNs
  };
};

export { useLinkBusinessManager };
