import api from 'services/api';

export class UploadFormCalls {

    async remove(response) {
        try {
            const res = await api.post(`file/delete?filePath=${response}`);
            return res;
          } catch (error) {
            return error;
          }
    }

    async removeBanner(id) {
      try {
        const res = await api.put(`banner/removeBanner/${id}/imagem`);
        return res;
      } catch (error) {
        return error;
      }
    }

}
