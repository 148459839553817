import React, { useState, useEffect } from 'react';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Timer } from './styles';

const Stopwatch = ({ onTimeout }) => {
  const userDataCRPLogin = localStorage.getItem('userDataCRP-Login');
  const storedMinutes = JSON.parse(userDataCRPLogin)[0]?.usuarioPortal.duracaoPersonificacao;


  const storedSeconds = (parseInt(storedMinutes) * 60) || 900;
  
  const [seconds, setSeconds] = useState(() => {
    const storedValue = localStorage.getItem('stopwatchSeconds');
    return storedValue ? parseInt(storedValue) : storedSeconds;
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          localStorage.setItem('stopwatchSeconds', String(prevSeconds - 1));
          return prevSeconds - 1;
        } else {
          clearInterval(intervalId);
          localStorage.removeItem('stopwatchSeconds');
          onTimeout();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [onTimeout]);

  useEffect(() => {
    return () => {
      localStorage.setItem('stopwatchSeconds', String(seconds));
    };
  }, [seconds]);

  return (
    <Timer>
      <FieldTimeOutlined /> {formatTime(seconds)}
    </Timer>
  );
};

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;

  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  return formattedTime;
};

export default Stopwatch;
