import styled from 'styled-components';

export const Container = styled.div``;

export const TopLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Line = styled.div`
  flex: ${(props) => props.flex};
  display: flex;
  column-gap: 14px;
  justify-content: flex-start;
  align-items: center;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
