import api from '../api';
import Swal from 'sweetalert2';

export const fetchProfiles = async () => {
  return await api.get('perfil/');
};

export const getProfileById = async (id) => {
  return await api.get(`perfil/${id}`);
};

export const updateProfiles = async (profile) => {
  return await api.put(`perfil/`, profile).then((result) => {
    Swal.fire({
      position: 'top-end',
      title: `Perfil alterado com sucesso!`,
      icon: 'success',
      showConfirmButton: false,
      timer: 3000,
    })
    setInterval(() => {
      window.location.reload();
    }, 1500)
  });
}

export const saveProfiles = async (profile) => {
  return await api.post('perfil/', profile).then((result) => {
    Swal.fire({
      position: 'top-end',
      title: `Perfil criado com sucesso!`,
      icon: 'success',
      showConfirmButton: false,
      timer: 3000,
    })
    setInterval(() => {
      window.location.reload();
    }, 1500)
  });
}
