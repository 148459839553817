import React, { Component } from 'react';
import '../../../styles/Styles.scss';
import { Helmet, Header, FooterAdmin, Hero } from '../../../services/Gerais';
import 'flatpickr/dist/themes/material_blue.css';
import avatar from '../../../img/loadingtwo.gif';
import { connect } from 'react-redux';
import {
  dadosIniciais,
  getUsuario,
  salvaUsuario,
  alteraUsuario,
  changeUsuario,
} from '../../../store/actions';
import { Row, Col, Button, Form, Icon } from 'antd';
import { deletePerfil } from '../../../components/Usuario/controller';
import Swal from 'sweetalert2';
import SessaoPerfil from '../../../components/Usuario/SessaoPerfil';
import SessaoUsuario from '../../../components/Usuario/SessaoUsuario';
import { getParameters } from 'services/resetSenha/service';
const ButtonGroup = Button.Group;

const MsgSalvoComSucesso = ({ history, changeUsuario }) => {
  if (changeUsuario) {
    changeUsuario({
      prop: 'succes',
      value: false,
    });
  }

  Swal.fire({
    position: 'top-end',
    title: `Salvo com sucesso! Redirecionando...`,
    icon: 'success',
    showConfirmButton: false,
    timer: 1500,
  }).then(result => {
    window.location.replace('/usuariosPolo')
  })

  return null;
};

const MgErroAoSalvar = ({ error, changeUsuario }) => {
  let msgErroPrint = '';

  if (changeUsuario) {
    changeUsuario({
      prop: 'error',
      value: null,
    });
  }

  if (error) {
    let msgErroUsrCad = '';
    if (error.error) {
      msgErroUsrCad = error.error;
    }
    if (error.response) {
      if (
        error.response.data.path &&
        error.response.data.path.indexOf('setorByPerfil') > -1
      ) {
        Swal.fire({
          title: 'Ops...!',
          text: 'Erro ao buscar Setor!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })

        return '';
      }
    }

    msgErroPrint = msgErroUsrCad ? msgErroUsrCad : '';

    if (error && error.status === 409) {
      Swal.fire({
        title: 'Alerta!',
        text: `Erro ao salvar! ${error.data.error} Verifique as informações
        inseridas e tente novamente!`,
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }

    if (msgErroUsrCad.mensagemErro) {
      if ((msgErroUsrCad.mensagemErro.includes('Esse CPF já está cadastrado!') || msgErroUsrCad.mensagemErro.includes('Esse E-mail já está cadastrado!')) && msgErroUsrCad.reativaUsuario) {
        let userId = msgErroUsrCad.idUsuarioPortal;

        Swal.fire({
          text: `Funcionário já cadastrado! Continuar para reativar o usuário?`,
          confirmButtonText: 'Reativar',
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace('/usuarios/edit/' + userId);
          }
        })

        return '';
      } else {
        Swal.fire({
          text: 'Funcionário já cadastrado no Prisma',
          confirmButtonText: 'Ok',
        })

        return '';
      }
    }

    if (error && error.status !== 409) {
      Swal.fire({
        text: `${msgErroUsrCad} Verifique as informações
      inseridas e tente novamente!`,
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }

    return '';
  }

  Swal.fire({
    title: 'Ops...!',
    text: error,
    icon: 'error',
    confirmButtonText: 'Ok'
  })

  return null;
};
class Cadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      perfil: {},
      isTelaPolo: false,
      usuarioSituacaoInicial: null,
      isValidResetPassword: true,
      visible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.preparaDadosEdicao = this.preparaDadosEdicao.bind(this);
    this.perfilID = localStorage.getItem('userDataCRP-Perfil');
  }


  hasErrors = fieldsError => {
    const trocouFieldParaInativo =
      this.props.usuario.trocouFieldParaInativo == undefined
        ? false
        : this.props.usuario.trocouFieldParaInativo;
    const erros = Object.keys(fieldsError).some(field => fieldsError[field]);
    const userInicialmenteInativo = this.state.usuarioSituacaoInicial === 2;
    let isNotValid = false;
    this.props.usuario.lstUsuarioPoloCommand.forEach(function (item) {
      if (item?.funcao?.id == '') {
        isNotValid = true;
      }
      if(item.isValidPolo != undefined && item.isValidPolo) {
        isNotValid = true;
      }
    })

    if (this.props.usuario?.lstUsuarioPoloCommand?.length <= 0) {
      isNotValid = true;
    }

    if (erros || isNotValid) {
      return true;
    } else if (trocouFieldParaInativo && userInicialmenteInativo) {
      return true;
    }
  };

  componentDidUpdate() {
    if (!this.props.usuario.carregando && !this.state.usuarioSituacaoInicial) {
      this.setState({
        usuarioSituacaoInicial: this.props.usuario.usuarioPortal.situacaoUsuario
          .id,
      });
    }
  }

  componentDidMount() {
    this.validParametersOfValidResetPassword()
    switch (parseInt(this.perfilID)) {
      case 376:
        break;
      case 370:
          break;
      case 380:
        break;
      case 59:
        break;
      case 500:
        break;
      case 501:
        break;
      case 505:
        break;
      case 506:
        break;
      default:
        window.location.replace("/home");
        break;
    }

    this.setState({ isTelaPolo: true });
    this.props.dadosIniciais(JSON.parse(localStorage.getItem('userDataCRP')));
    this.props.usuario.botaoSalvarHabilitado = false;
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.setState({ isEdit: true });
      this.props.usuario.carregando = true;
      this.props.getUsuario(this.props.match.params.id);
    }
  }

  validParametersOfValidResetPassword = async () => {
    const data = await getParameters();
    this.setState({ isValidResetPassword: (data.data.valor == 'S' ? true : false) })
    this.render();
  };

  validStatusUser = () => {
    let idStatus = parseInt(this.props.usuario.usuarioPortal.situacaoUsuario.id)
    if (idStatus != 1) {
      Swal.fire({
        title: `Situação do funcionario`,
        text: `Você está salvando o cadastro do usuário com o Status ${(idStatus == 2 ? 'INATIVO' : 'BLOQUEADO')}, deseja prosseguir?”`,
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          this.save();
        } else {
          return
        }
      })
    } else {
      this.save();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.validStatusUser();
      }
    });
  };

  validProfile = (listProfile) => {
    let isCorporate = false;
    let profile = {};
    listProfile.forEach(item => {
      if(item.lstUsuarioPoloPerfil[0].perfil.id == 2) {
        isCorporate = true;
        profile = item;
      }
      if(item.usuarioPolo.primeiroAcesso == '' && isCorporate) {
        deletePerfil(profile, true);
      }
    })

    return profile;
  }

  save = () => {
    let { usuario } = this.props;
    this.props.usuario.botaoSalvarHabilitado = true;

    if (this.state.isEdit) {
      let profileRemove = this.validProfile(usuario.lstUsuarioPoloCommand);
      if(profileRemove?.usuarioPolo?.id > 0) {
        usuario.lstUsuarioPoloCommand.forEach((item, index) => {
          if(item.usuarioPolo.id != profileRemove.usuarioPolo.id) {
            usuario.lstUsuarioPoloCommand.splice(0, 1);
          }
        })
      }
      let usuarioEditado = this.preparaDadosEdicao(usuario);
      this.props.alteraUsuario(usuarioEditado, this.props.match.params.id);
    } else if(usuario.usuarioPortal.funcionario == 'N') {
      Swal.fire({
        text: `Estou ciente que como gestor sou o único e responsável pelo cadastro de usuários do meu Polo, e que estes devem ter registrados os respectivos contratos de trabalho sob as regras da CLT e com registro anotado em CTPS (Carteira de Trabalho e Previdência Social), desde o momento da admissão, independentemente da função e da modalidade, do prazo a ser estabelecido, bem como procedendo ao registro de toda e qualquer mão de obra empregada para consecução exclusiva e direta do presente Contrato, com o pagamento tempestivo dos direitos trabalhistas aplicáveis.

            Garantindo Não utilizar e impedir a utilização inadequada dos serviços e informações da IES por partes de seus empregados, funcionários, administradores ou de quaisquer pessoas que se encontrem dentro de suas dependências ou que possam, por qualquer razão, ter acesso total ou parcial aos serviços disponibilizados, incluindo, mas não se limitando a clausula CLÁUSULA DÉCIMA TERCEIRA – DA CONFIDENCIALIDADE E PROPRIEDADE INTELECTUAL do contrato de parceria, sobre os dados e informações (incluindo, sem limitação, senhas de acesso ao Sistema IES) as quais venha a ter acesso em razão desta Parceria, obrigando-se ainda, a não permitir que nenhum de seus empregados ou terceiros sob a sua responsabilidade façam uso destes dados e informações para fins diversos do objeto deste.`,
        confirmButtonText: 'Concordo',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        width: '60%',
      }).then(result => {
        if (result.isConfirmed) {
          this.props.salvaUsuario(usuario);
          this.setState({ isTentaSalvar: true });
        } else {
          this.props.usuario.botaoSalvarHabilitado = false;
          this.forceUpdate()
        }
      })
    } else {
      this.props.salvaUsuario(usuario);
    }
  }

  preparaDadosEdicao = usuario => {
    let usuarioEdicao = Object.assign({}, usuario);
    let usuarioPoloCommand = [];
    let userData = JSON.parse(localStorage.getItem('userDataCRP'));

    usuario.usuarioPortal.usuarioPortalLog = {
      id: userData.usuarioPortalLog.id,
    };

    usuarioEdicao.usuarioPortal.conta = usuarioEdicao.usuarioPortal.pessoa.email;

    usuarioEdicao.lstUsuarioPoloCommand.forEach(item => {
      delete item.usuarioPolo.setorFuncao;
      if (item.lstPerfilSetorFuncaoSistema) {
        delete item.lstPerfilSetorFuncaoSistema;
      }

      if (item.lstUsuarioPoloPerfil) {
        let isAdmOrCorporativo = item.lstUsuarioPoloPerfil[0].perfil.id;
        delete item.lstUsuarioPoloPerfil[0].usuarioPolo;
        item.usuarioPolo.polo =
          isAdmOrCorporativo.toString() === '1' ||
            isAdmOrCorporativo.toString() === '2'
            ? { id: '' }
            : item.usuarioPolo.polo;
      }
      usuarioPoloCommand.push(item)
    });
    usuarioEdicao.lstUsuarioPoloCommand = usuarioPoloCommand;
    return usuarioEdicao;
  };

  render() {
    const { dadosPerfil, usuario } = this.props;
    const { isEdit, isTelaPolo } = this.state;
    const { getFieldsError } = this.props.form;

    if (dadosPerfil.carregandoInicial) {
      return (
        <div className="CRP-loading">
          <img src={avatar} width="100" alt="Carregando..." />
        </div>
      );
    }

    return (
      <div>
        <Helmet
          key="TitleAdminMockup"
          title="Usuários por polo | Portal Prisma"
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: 'Usuários por polo - Portal Prisma',
            },
            {
              name: 'keywords',
              content:
                'usuários por polo, cadastro, kroton, educacional, portal',
            },
          ]}
        />
        <Header key="HeaderAdminUsuarioPolo" />
        <Hero
          key="heroAdminUsuarioPolo"
          pageName="Usuários por polo"
          titulo="Usuários por polo"
        />
        <section className="section conteudoAdmin" key="conteudoPortais">
          <div className="container">
            {usuario.succes && (
              <MsgSalvoComSucesso
                history={this.props.history}
                changeUsuario={this.props.changeUsuario}
              />
            )}
            {!usuario.sucess && usuario.error && (
              <MgErroAoSalvar
                error={usuario.error}
                changeUsuario={this.props.changeUsuario}
              />
            )}
            <Form onSubmit={this.handleSubmit}>
              <SessaoUsuario
                form={this.props.form}
                isEdit={isEdit}
                isTelaPolo={isTelaPolo}
                perfilID={localStorage.getItem('userDataCRP-Perfil')}
              />
              {!usuario.carregando &&
                usuario.usuarioPortal.situacaoUsuario.id.toString() !== '2' && (
                  <SessaoPerfil
                    form={this.props.form}
                    isEdit={isEdit}
                    isTelaPolo={isTelaPolo}
                    perfilID={this.perfilID}
                  />
                )}
              <Row type="flex" style={{ "padding-bottom": "25px" }}>
                <Col span={6} offset={18}>
                  <Form.Item size="large">
                    <ButtonGroup size="large" block>
                      <Button
                      href='/usuariosPolo'
                      >
                        <Icon type="stop" />
                        Cancelar
                      </Button>

                      {usuario.botaoSalvarHabilitado ? (
                        <Button
                          type="primary"
                          size="large"
                          style={{
                            backgroundColor: '#52c41a',
                            border: 'none',
                          }}
                          loading
                        >
                          Carregando
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          size="large"
                          style={{
                            backgroundColor: '#52c41a',
                            border: 'none',
                          }}
                          htmlType="submit"
                          disabled={this.hasErrors(getFieldsError()) || this.perfilID == 501 || this.perfilID == 506}
                        >
                          <Icon type="save" /> Salvar
                        </Button>
                      )}
                    </ButtonGroup>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </section>
        <FooterAdmin key="FooterAdminUsuariosCadastro" />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  dadosPerfil: store.DadosPerfil,
  usuario: store.Usuario,
});

export default connect(mapStateToProps, {
  dadosIniciais,
  getUsuario,
  salvaUsuario,
  alteraUsuario,
  changeUsuario,
})(Form.create()(Cadastro));
