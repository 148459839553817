import React, { useState } from 'react';
import { useFaq } from './hook';
import { Container, RegisterBox } from './styles';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../services/Gerais';
import { Input, Select, Form, Row, Col, Button, Tooltip, DatePicker } from 'antd';
import { ToolbarDraft } from '../../../services/Gerais';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';

const Faq = ({
  match: {
    params: { faqId },
  },
}) => {
  const {
    faqData,
    editor,
    editorChange,
    uploadImageCallBack,
    onChange,
    buttonDisabled,
    finish,
    loading,
    categoryOptions,
  } = useFaq({ faqId });
  const [dataExpiracaoRevisao, setDataExpiracaoRevisao] = useState(faqData.dataExpiracaoRevisao);

  function changeDate(event) {
    setDataExpiracaoRevisao(event)
  }

  async function sendFinish() {
    faqData.dataExpiracaoRevisao = dataExpiracaoRevisao;
    await finish();
  }

  return (
    <Container>
      <Helmet
        key="TitleAdminFaq"
        title="FAQ - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Faq' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'faq',
          },
        ]}
      />
      <HeaderAdmin key="HeaderFaq" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminFaq" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroFaq"
                pageName="FAQ"
                titulo={faqId ? 'FAQ - Edição' : 'FAQ - Cadastro'}
              />
            </div>
          </div>

          <RegisterBox>
            <Button type="primary" href="/admin/categoria">
              Nova Categoria
            </Button>
          </RegisterBox>
          <Form>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Questão">
                  <Input
                    value={faqData.questao}
                    onChange={(event) =>
                      onChange(event.target.value, 'questao')
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Palavra Chave">
                  <Input
                    value={faqData.palavraChave}
                    onChange={(event) =>
                      onChange(event.target.value, 'palavraChave')
                    }
                    placeholder="Ex: Colaborar"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Categoria">
                  <Select
                    value={faqData.categoriaId}
                    onChange={(event) => onChange(event, 'categoriaId')}
                    placeholder="Selecione"
                    size="large"
                  >
                    {categoryOptions.map((item) => (
                      <Select.Option key={String(item.value)}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Data de Expiração/Revisão">
                  <DatePicker
                    size="large"
                    value={moment(faqData.dataExpiracaoRevisao, 'DD-MM-YYYY')}
                    style={{ width: "100%" }}
                    locale={locale}
                    format={'DD/MM/YYYY'}
                    onChange={(event) => changeDate(event)}
                    placeholder={['Data de Expiração/Revisão']}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Resposta">
              <Editor
                editorState={editor}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={editorChange}
                defaultEditorState={`<p>${faqData.respostaNovo}</p>\n`}
                localization={{ locale: 'pt' }}
                toolbar={{
                  ToolbarDraft,
                  image: {
                    className: undefined,
                    urlEnabled: true,
                    component: undefined,
                    popupClassName: undefined,
                    alignmentEnabled: true,
                    uploadEnabled: true,
                    uploadCallback: uploadImageCallBack,
                    previewImage: true,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
                    alt: { present: true, mandatory: false },
                    defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                  },
                }}
              />
              <textarea
                className="textarea is-hidden"
                value={draftToHtml(
                  convertToRaw(editor.getCurrentContent(faqData.respostaNovo))
                )}
                readOnly
                required
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item label="Dúvida Frequente?">
                  <Select
                    value={faqData.duvidaFrequente}
                    onChange={(event) => onChange(event, 'duvidaFrequente')}
                    size="large"
                    placeholder="Selecione"
                  >
                    <Select.Option key="S">SIM</Select.Option>
                    <Select.Option key="N">NÃO</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item label="Ordem Exibição">
                  <Input
                    value={faqData.ordemExibicao}
                    onChange={(event) =>
                      onChange(+event.target.value, 'ordemExibicao')
                    }
                    placeholder="Ex: 1"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" gutter={32}>
              <Col
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                span={32}
              >
                <Tooltip
                  title={
                    buttonDisabled
                      ? 'Todos os campos devem estar preenchidos!'
                      : ''
                  }
                >
                  <Button
                    loading={loading}
                    onClick={async () => await sendFinish()}
                    disabled={buttonDisabled}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
      <FooterAdmin key="FooterAdminAtribuiçõesCadastro" />
    </Container>
  );
};

export default Faq;
