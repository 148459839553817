// Setar a URL local:
import { setGlobal } from 'reactn';
const urlLocalPort = window.location.port;
const urlLocal = window.location.protocol + '//' + window.location.hostname;
let configuracao = {};

const cnfIntial = {
  modalAtiva: true,
  token: '',
  urlMsGraph: 'https://graph.microsoft.com/v1.0/',
};
// token fake
console.log('parseInt(urlLocalPort)', parseInt(urlLocalPort))
console.log('urlLocal', urlLocal)

if (parseInt(urlLocalPort) <= 4999) {
  // se estiver em localhost
  configuracao = {
    //    baseUrl: 'http://10.201.40.37:8080/api/',
    baseUrl: 'http://localhost:8080/api/',
    contexto: 'http://localhost:3000',
  };
} else {
  // se o link for de producao (prisma.kroton...)
  if (urlLocal.includes('prisma.kroton')) {
    configuracao = {
      // baseUrl: window.location.protocol+"//apiprisma.kroton.com.br/api/"
      baseUrl: 'https://apiprisma.kroton.com.br/api/',
      contexto: 'https://prisma.kroton.com.br',
    };
  } else if (urlLocal.includes('10.201.40.65')) {
    // se o link for do ambiente stage
    configuracao = {
      baseUrl: window.location.protocol + '//10.201.40.67:8080/api/',
      contexto: 'https://prismabackstg.krthomolog.com.br',
    };
  } else if (urlLocal.includes('prismadevfront.krthomolog.com.br')) {
    // se o link for do ambiente stage
    console.log('Estou nessa url');
    configuracao = {
      //baseUrl: 'http://localhost:8080/api/',
      baseUrl: '//prismadevback.krthomolog.com.br/api/',
      contexto: 'https://prismadevfront.krthomolog.com.br',
    };
  } else if (urlLocal.includes('prismatstfront.krthomolog.com.br')) {
    // se o link for do ambiente stage
    configuracao = {
      baseUrl: '//prismatstback.krthomolog.com.br/api/',
      contexto: 'https://prismatstfront.krthomolog.com.br',
    };
  } else if (urlLocal.includes('prismafrontstg.krthomolog.com.br')) {
    // se o link for do ambiente stage
    configuracao = {
      baseUrl: '//prismabackstg.krthomolog.com.br/api/',
      contexto: 'https://prismafrontstg.krthomolog.com.br',
    };
  } else if (urlLocal.includes('prismadev.krthomolog.com.br')) {
    // se o link for do ambiente stage
    configuracao = {
      baseUrl: 'http://10.201.40.37:8080/api/',
      contexto: 'https://prismadevfront.krthomolog.com.br',
    };
  } else if(urlLocal.includes('prismamtc.krthomolog')) {
    console.log('entrou no mtc')
    configuracao = {
      baseUrl: 'https://prismabackmtc.krthomolog.com.br/api/',
      contexto: 'https://prismamtc.krthomolog.com.br',
    };
  }
}

console.log('configuracao', configuracao)
const cnf = Object.assign(configuracao, cnfIntial);
setGlobal(cnf);
localStorage.setItem('cnfgDataCRP', JSON.stringify(cnf));

export default cnf;
