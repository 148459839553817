import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Form, Button, Col, Select, Input } from 'antd';
import { Container } from './styles';
import { FormController } from './FormController';
import { useForm, Controller } from 'react-hook-form';
import 'antd/dist/antd.css';
import { SigneeForm, changeDisabledState } from './SigneeSelection';

const FormComponent = ({ id, historys }) => {
  const [listaAssinantes, setListaAssinantes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();
  const { Option } = Select;
  const { handleSubmit, getDocument, setReport, report, loading, subscribe } = FormController(listaAssinantes);


  useEffect(() => {
    if (id.length > 0) {
      getDocument(id)
    }
  }, [id]);

  function handleListaAssinantes(data) {
    setListaAssinantes(data);
  }

  function changeisDisabledState(state) {
    setIsDisabled(state);
  }

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(id.length > 0 ? true : false);
                }
        }
      >
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Tipo de Documento" size="large">
              <Input
                disabled={loading}
                placeholder="Nome da Coluna"
                size="large"
                onChange={(e) => setReport((state) => ({
                  ...state, tipoDocumento: e.target.value
                }))}
                value={report.tipoDocumento}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Status:" size="large">
              <Select
                size="large"
                placeholder="Status"
                disabled={loading}
                onChange={(e) => setReport((state) => ({
                  ...state, status: e
                }))}
                value={report.status}
              >
                <Option key="A">Ativo</Option>
                <Option key="I">Inativo</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Prioridade" size="large">
              <Input
                disabled={loading}
                placeholder="Prioridade"
                size="large"
                type='number'
                onChange={(e) => setReport((state) => ({
                  ...state, prioridade: parseInt(e.target.value)
                }))}
                value={report.prioridade}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Gestor deve ser o primeiro assinador?" size="large">
              <Select
                size="large"
                placeholder="Gestor deve ser o primeiro assinador?"
                disabled={loading}
                onChange={(e) => setReport((state) => ({
                  ...state, gestorPrimeiroAssinador: e
                }))}
                value={report.gestorPrimeiroAssinador}
              >
                <Option key="S">Sim</Option>
                <Option key="N">Não</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Observações" size="large">
              <Input
                disabled={loading}
                placeholder="Observações"
                size="large"
                maxLength={255}
                onChange={(e) => setReport((state) => ({
                  ...state, observacoes: e.target.value
                }))}
                value={report.observacoes}
              />
            </Form.Item>
          </Col>
        </Row>

        <SigneeForm handleListaAssinantes={handleListaAssinantes} subscribes={subscribe} isDisabledState={changeisDisabledState}/>

        <Row type="flex" gutter={16} style={{ textAlign: 'right' }}>
          <Col xs={24} sm={24} md={24}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
              disabled={isDisabled}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>

    </Container>
  );
};

export default FormComponent;
