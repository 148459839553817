import { list, findBy} from "../../service";
import { notification } from "antd";
export class FiltersController {

 constructor(props){
    this.state = props.state;
 }

 async hadleInitData(){
    this.state.loading.setLoading(true)
    const result = await list()
    this.state.data.setData(result)
    this.state.loading.setLoading(false)
  }
  async handleSubmit(e) {
    try {
      this.state.loading.setLoading(true)
        const result = await findBy(e)
        this.state.data.setData(result)
        this.state.loading.setLoading(false)
    } catch (error) {
      notification['error']({
        message: `Erro ao listar parametros!`,
        description: error.response.data.message
          ? error.response.data.message
          : 'Por favor verificar com a equipe tecnica',
      });
      this.state.loading.setLoading(false);
    }
  }
}
