import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Popconfirm, Form, Button, Switch } from 'antd';
import EditableCell from './EditableCell';

import { changeComunicado } from 'store/actions/Administrativo/Comunicado/ComunicadoActions';

const originData = [];

for (let i = 1; i < 100; i++) {
  originData.push({
    key: i.toString(),
    id: i.toString().padStart(4, '0'),
    nome: `Nome Polos no. ${i}`,
    marca: `Marca no. ${i}`,
  });
}

const EditableTable = ({ form }) => {
  let { carregandoUpload, DadosComunicado, isEdit, lstPolosEdit } = useSelector(
    state => state.Comunicado
  );
  const [loadingFilter, setloadingFilter] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = id => {
    setloadingFilter(true);
    const dataSource = [...DadosComunicado.lstComunicadoPolo];
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          lstComunicadoPolo: dataSource.filter(item => item.polo.id !== id),
        },
      })
    );
    setloadingFilter(false);
  };

  const handleInativa = (objeto, valor) => {
    const dataSource = [...lstPolosEdit];

    objeto.ativo = valor ? 'S' : 'N';

    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          lstComunicadoPolo: dataSource.map(lstPolosEdit => {
            if (lstPolosEdit.id === objeto.id) {
              return { ...lstPolosEdit, ...objeto };
            } else {
              return lstPolosEdit;
            }
          }),
        },
      })
    );
  };

  const columnsInsert = [
    {
      title: 'Nome',
      dataIndex: 'polo.nome',
      width: '20',
      editable: true,
    },
    {
      title: 'Marca',
      dataIndex: 'polo.marca',
      width: '20',
      editable: true,
    },
    {
      title: 'Existe',
      dataIndex: 'polo.existe',
      width: '10',
      editable: true,
    },
    {
      title: 'Excluir',
      dataIndex: 'acao',
      render: (text, record) =>
        DadosComunicado.lstComunicadoPolo.length >= 1 ? (
          <Popconfirm
            title="Deseja excluir o registro?"
            onConfirm={() => handleDelete(record.polo.id)}
          >
            <Button
              size="small"
              icon="delete"
              type="danger"
              loading={loadingFilter}
            />
          </Popconfirm>
        ) : null,
    },
  ];

  const columnsAltera = [
    {
      title: 'Polo ID',
      dataIndex: 'poloId',
      width: '10',
      editable: true,
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: '30',
      editable: true,
    },
    {
      title: 'Ativo?',
      dataIndex: 'acao',
      render: (text, record) => (
        <Switch
          checkedChildren="ATIVO"
          unCheckedChildren="INATIVO"
          defaultChecked={record.ativo === 'S'}
          onChange={e => handleInativa(record, e)}
        />
      ),
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        loading={carregandoUpload}
        dataSource={isEdit ? lstPolosEdit : DadosComunicado.lstComunicadoPolo}
        columns={isEdit ? columnsAltera : columnsInsert}
        rowClassName={() => 'editable-row'}
      />
    </Form>
  );
};

export default EditableTable;
