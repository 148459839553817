import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Row,
  Form,
  Tag,
  AutoComplete,
  Select,
  DatePicker,
  Upload,
  Icon,
  Tree,
  Tooltip,
} from 'antd';
import { changeComunicado } from 'store/actions/Administrativo/Comunicado/ComunicadoActions';
import moment from 'moment';
import Swal from 'sweetalert2';
import api from 'services/api';
import { Sphere, ContainerSelect } from './styles';
import { uploadToS3 } from 'services/uploadS3/s3Service';
import { UploadForm } from 'components/uploadForm/UploadForm';

const { Option } = Select;
const { RangePicker } = DatePicker;

let treeData = [
  {
    title: 'Total',
    key: '0-0',
    children: [],
  },
];

function renderOption(item) {
  return (
    <Option key={item.id} text={item.descricao}>
      {item.descricao}
    </Option>
  );
}

const ComunicadoFiltros = ({ form }) => {
  const { getFieldDecorator, setFieldsValue } = form;
  const dispatch = useDispatch();
  const [loadingFile, setLoadingFile] = useState(false);
  const [pathImagem, setPathImagem] = useState('');
  const [nodesTree, setNodesTree] = useState(treeData);
  const [checkados, setCheckados] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchArray, setSearchArray] = useState([]);
  const [lstSubCategoria, setLstSubCategoria] = useState([]);
  let perfilId = localStorage.getItem('userDataCRP-Perfil');

  let { DadosComunicado, DadosIniciaisComunicado, isEdit } = useSelector(
    (state) => state.Comunicado
  );

  useEffect(() => {
    setorFuncaoNodes();
    setorFuncaoSet(DadosComunicado.lstComunicadoSetorFuncao);
  }, []);

  useEffect(
    () => {
      setPathImagem(DadosComunicado.comunicado.path_thumbnail);
    },

    [DadosComunicado.comunicado.path_thumbnail]
  );


  useEffect(
    () => {
      if (isEdit) {
        setorFuncaoSet(DadosComunicado.lstComunicadoSetorFuncao);
      }
    },

    [isEdit]
  );

  useEffect(() => {
    setorFuncaoNodes();
    setorFuncaoSet(DadosComunicado.lstComunicadoSetorFuncao);
  }, [DadosComunicado.comunicado.destaque]);

  function setorFuncaoNodes() {
    const { Setor, SetorFuncao } = DadosIniciaisComunicado;
    let ArraySetorToOperation = [
      'ADMINISTRATIVO',
      'GESTÃO',
      'CORPORATIVO',
      'DIRETORIA',
      'OPERAÇÕES',
    ];
    var aux = 0;
    Object.keys(SetorFuncao).forEach(function (item) {
      aux = item;
    });
    let objMap = Object.keys(Setor).map((k, i) => {
      let setorFcn = SetorFuncao[k];
      let children = [{}];
      Object.keys(setorFcn).map((ks, is) => {
        return (children[is] = { title: setorFcn[ks], key: k + '_' + ks });
      });
      if (
        DadosComunicado.comunicado.destaque == 'S' &&
        ArraySetorToOperation.includes(Setor[k])
      ) {
        return {
          title: Setor[k],
          key: k,
          children,
        };
      } else if (DadosComunicado.comunicado.destaque == 'N') {
        return {
          title: Setor[k],
          key: k,
          children,
        };
      }
    });
    let filterChildren = [];
    objMap.map((item) => {
      if (item == undefined) return;
      filterChildren.push(item);
    });
    let children = filterChildren;
    let objRes = [{ title: 'Todos', key: '0-0', children }];

    treeData = objRes;
    setNodesTree(objRes);
  }

  function configRangeDate() {
    const { dataInicio, dataFim } = DadosComunicado.comunicado;
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Por Favor selecione um periodo',
        },
      ],
    };
    if (dataInicio !== null && dataFim !== '') {
      let starDate = moment(dataInicio, 'DD-MM-YYYY hh:mm:ss');
      let endDate = moment(dataFim, 'DD-MM-YYYY hh:mm:ss');
      Object.assign(rangeConfig, {
        initialValue: [starDate, endDate],
      });
    }

    return rangeConfig;
  }

  function disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  function changeData(value, dateString) {
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          comunicado: {
            ...DadosComunicado.comunicado,
            dataInicio: dateString[0],
            dataFim: dateString[1],
          },
        },
      })
    );
  }

  function changeDataOk(value) {
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          comunicado: {
            ...DadosComunicado.comunicado,
            dataInicio: value[0].format('DD-MM-YYYY hh:mm:ss'),
            dataFim: value[1].format('DD-MM-YYYY hh:mm:ss'),
          },
        },
      })
    );
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Swal.fire({
        title: 'Ops...!',
        text: 'Você só consegue fazer upload no formatoJPG/PNG',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Swal.fire({
        title: 'Ops...!',
        text: 'Arquivo maior que 2MB',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
    return isJpgOrPng && isLt2M;
  }

  async function uploadImageCallBack(info) {
    if (info.file.status === 'uploading') {
      setLoadingFile(true);
      return;
    }

    try {
      let dataImage = new FormData();
      dataImage.append('file', info.file.originFileObj);
      const res = await uploadToS3(dataImage);
      setPathImagem(res.data.link);
      setLoadingFile(false);
      dispatch(
        changeComunicado({
          prop: 'DadosComunicado',
          value: {
            ...DadosComunicado,
            comunicado: {
              ...DadosComunicado.comunicado,
              path_thumbnail: res.data.link,
            },
          },
        })
      );
    } catch (error) {
      setLoadingFile(false);
    }
  }

  function onCheck(checkedKeys, info) {
    let setorFuncao = [];
    if (checkedKeys) {
      setFieldsValue({ setorFuncao: checkedKeys });
      checkedKeys.map((checkados) => {
        let check = checkados.split('_');
        if (check.length > 1) {
          setorFuncao.push({
            setorFuncao: {
              setor: { id: check[0] },
              funcao: { id: check[1] },
            },
          });
        }
        return checkados;
      });
    }
    setCheckados(checkedKeys);
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          lstComunicadoSetorFuncao: setorFuncao,
        },
      })
    );
  }

  function setorFuncaoSet(lstComunicadoSetorFuncao) {
    let arrayForOparacion = [
      '1',
      '2',
      '10',
      '11',
      '12',
      '13',
      '1_1',
      '2_2',
      '2_3',
      '10_6',
      '11_6',
      '11_7',
      '13_9',
    ];

    let arraySetor = [];
    lstComunicadoSetorFuncao.map(({ setorFuncao }) => {

      if (DadosComunicado.comunicado.destaque == 'S') {
        if (
          setorFuncao.id == 1 ||
          setorFuncao.id == 2 ||
          setorFuncao.id == 17 ||
          setorFuncao.id == 66 ||
          setorFuncao.id == 67 ||
          setorFuncao.id == 71
        ) {
          arraySetor.push(setorFuncao.setor.id + '_' + setorFuncao.funcao.id);
        }
      } else {
        arraySetor.push(setorFuncao.setor.id + '_' + setorFuncao.funcao.id);
      }
    });
    setCheckados(arraySetor);
    if (DadosComunicado.comunicado.destaque == 'S') {
      onCheck(arrayForOparacion);
    }
  }

  function handleClose(removedTag) {
    const tags = DadosComunicado.lstComunicadoTag.filter(
      ({ tag }) => tag.descricao !== removedTag.descricao
    );

    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          lstComunicadoTag: tags,
        },
      })
    );
  }

  function showInput() {
    setInputVisible(true);
  }

  function handleInputConfirm(value, opt) {
    let tags = DadosComunicado.lstComunicadoTag;

    let tagExiste = [];

    if (opt) {
      tagExiste = tags.filter(({ tag }) => tag.descricao === opt.props.text);
    } else {
      tagExiste = tags.filter(({ tag }) => tag.descricao === inputValue);
    }

    if (inputValue && tagExiste.length === 0) {
      let tag = {
        descricao: inputValue,
      };
      if (opt) {
        tag.id = value;
        tag.descricao = opt.props.text;
      }
      tags = [...tags, { tag: tag }];
    }

    setInputVisible(false);
    setInputValue('');
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          lstComunicadoTag: tags,
        },
      })
    );
  }

  const optionStyle = {
    display: 'flex',
    columnGap: '4px',
    alignItems: 'center',
  };

  function buscaDescricaoTag(search) {
    if (search && search.length > 1) {
      const optionsArray = DadosIniciaisComunicado.Tag;
      const searchArray =
        optionsArray.length > 0
          ? optionsArray.filter((tag) => tag.descricao.includes(search))
          : [];
      setSearchArray(searchArray);
    } else {
      setSearchArray([]);
    }
  }

  async function getSubCategoria(id) {
    const res = await api.get(`subcategoria/getByCategoria/${id}`);
    setLstSubCategoria(res.data);
  }

  return (
    <>
      <Col span={18}>
        <Row gutter={24}>
          <Col span={5} className="gutter-row">
            <Form.Item label="Canal" size="large">
              {getFieldDecorator('canal', {
                initialValue:
                  DadosComunicado &&
                  DadosComunicado.comunicado &&
                  DadosComunicado.comunicado.poloProprio,
                rules: [
                  {
                    required: true,
                    message: 'Por favor Selecione Canal',
                  },
                ],
              })(
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  placeholder="Selecione o canal"
                  onChange={(e) => {
                    dispatch(
                      changeComunicado({
                        prop: 'DadosComunicado',
                        value: {
                          ...DadosComunicado,
                          comunicado: {
                            ...DadosComunicado.comunicado,
                            poloProprio: e,
                          },
                        },
                      })
                    );
                  }}
                >
                  <Option key="proprioT" value={'T'}>
                    Todos
                  </Option>
                  <Option key="proprioS" value={'S'}>
                    Próprio
                  </Option>
                  <Option key="proprioN" value={'N'}>
                    Parceiro
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={5} className="gutter-row">
            <Form.Item label="Classificação" size="large">
              {getFieldDecorator('critico', {
                initialValue: DadosComunicado?.comunicado?.prioridade,
                rules: [
                  {
                    required: true,
                    message: 'Por favor a Classificação',
                  },
                ],
              })(
                <ContainerSelect>
                  <Select
                    size="large"
                    defaultValue={DadosComunicado?.comunicado?.prioridade}
                    placeholder="Selecione a Classificação"
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      dispatch(
                        changeComunicado({
                          prop: 'DadosComunicado',
                          value: {
                            ...DadosComunicado,
                            comunicado: {
                              ...DadosComunicado.comunicado,
                              prioridade: e,
                            },
                          },
                        })
                      );
                    }}
                  >
                    <Option style={optionStyle} key="Critico" value={'C'}>
                      <Sphere background="#ff0000" /> Crítico
                    </Option>
                    <Option style={optionStyle} key="Acao" value={'A'}>
                      <Sphere background="#ff4500" />
                      Ação
                    </Option>
                    <Option style={optionStyle} key="Informacao" value={'I'}>
                      <Sphere background="#248eff" />
                      Informação
                    </Option>
                    <Option style={optionStyle} key="Reforco" value={'R'}>
                      <Sphere background="#00ff7f" />
                      Reforço
                    </Option>
                  </Select>
                </ContainerSelect>
              )}
            </Form.Item>
          </Col>
          <Col span={5} className="gutter-row">
            <Form.Item label="Categoria" size="large">
              {getFieldDecorator('categoria', {
                initialValue:
                  DadosComunicado &&
                  DadosComunicado.lstComunicadoCategoria &&
                  DadosComunicado.lstComunicadoCategoria.map(
                    (i) => i.categoria.id
                  ),
                rules: [
                  {
                    required: true,
                    message: 'Por favor Selecione Categoria',
                  },
                ],
              })(
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  placeholder="Selecione a categoria"
                  onChange={(e) => {
                    let categoria = [];
                    categoria.push({
                      categoria: {
                        id: e,
                      },
                    });
                    getSubCategoria(e)
                    dispatch(
                      changeComunicado({
                        prop: 'DadosComunicado',
                        value: {
                          ...DadosComunicado,
                          lstComunicadoCategoria: categoria,
                        },
                      })
                    );
                  }}
                >
                  {DadosIniciaisComunicado &&
                    DadosIniciaisComunicado.Categoria &&
                    Object.keys(DadosIniciaisComunicado.Categoria).map(
                      (key) => {
                        return (
                          <Option
                            key={'categoria-' + [key]}
                            value={parseInt(key)}
                          >
                            {DadosIniciaisComunicado.Categoria[key]}
                          </Option>
                        );
                      }
                    )}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Sub-categoria" size="large">
              {getFieldDecorator('subcategoria', {
                initialValue:
                DadosComunicado &&
                DadosComunicado.comunicadoSubCategoria &&
                DadosComunicado.comunicadoSubCategoria.descricao,
              })(
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  placeholder="Selecione a sub-categoria"
                  onChange={(e) => {
                    let subCategoria = {
                      id: e,
                    }
                    dispatch(
                      changeComunicado({
                        prop: 'DadosComunicado',
                        value: {
                          ...DadosComunicado,
                          comunicadoSubCategoria: subCategoria,
                        },
                      })
                    );
                  }}
                >
                  {lstSubCategoria.map(renderOption)}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={9} style={{ paddingRight: 15 }}>
            <Form.Item
              label=" Data início (data-hora) / Data fim (data-hora)"
              size="large"
            >
              {getFieldDecorator(
                'data',
                configRangeDate()
              )(
                <RangePicker
                  defaultValue={[
                    moment(
                      DadosComunicado.comunicado.dataInicio,
                      'DD-MM-YYYY HH:mm:ss'
                    ),
                    DadosComunicado.comunicado.dataFim
                      ? moment(
                          DadosComunicado.comunicado.dataFim,
                          'DD-MM-YYYY HH:mm:ss'
                        )
                      : '',
                  ]}
                  size="large"
                  style={{ width: '100%' }}
                  showTime={{ format: 'HH:mm' }}
                  disabledDate={disabledDate}
                  format="DD-MM-YYYY HH:mm:ss"
                  placeholder={[
                    'Data início (data-hora)',
                    'Data fim (data-hora)',
                  ]}
                  onChange={changeData}
                  onOk={changeDataOk}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Minuto operação" size="large">
              {getFieldDecorator('destaque', {
                initialValue:
                  DadosComunicado &&
                  DadosComunicado.comunicado &&
                  DadosComunicado.comunicado.destaque,
                rules: [
                  {
                    required: true,
                    message: 'Por favor selecione o destaque',
                  },
                ],
              })(
                <Select
                  size="large"
                  placeholder="Selecione o Destaque"
                  style={{ width: '100%' }}
                  disabled={perfilId == '503' ? true : false}
                  onChange={(e) => {
                    dispatch(
                      changeComunicado({
                        prop: 'DadosComunicado',
                        value: {
                          ...DadosComunicado,
                          comunicado: {
                            ...DadosComunicado.comunicado,
                            destaque: e,
                          },
                        },
                      })
                    );
                  }}
                >
                  <Option key="destaqueS" value={'S'}>
                    Sim
                  </Option>
                  <Option key="destaqueN" value={'N'}>
                    Não
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Msg Importante" size="large">
              <Select
                size="large"
                style={{ width: '100%' }}
                disabled={
                  DadosComunicado?.comunicado?.destaque === 'S' ? true : false
                }
                type="text"
                value={
                  DadosComunicado &&
                  DadosComunicado.comunicado &&
                  DadosComunicado.comunicado.destaque === 'S'
                    ? 'C'
                    : DadosComunicado.comunicado.tipo
                }
                placeholder="Digite o Setor"
                onChange={(e) => {
                  dispatch(
                    changeComunicado({
                      prop: 'DadosComunicado',
                      value: {
                        ...DadosComunicado,
                        comunicado: {
                          ...DadosComunicado.comunicado,
                          tipo: e,
                        },
                      },
                    })
                  );
                }}
              >
                <Option key="tipoS" value={'M'}>
                  Sim
                </Option>
                <Option key="tipoN" value={'C'}>
                  Não
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        {DadosComunicado &&
        DadosComunicado.comunicado &&
        DadosComunicado.comunicado.tipo === 'M' &&
        DadosComunicado.comunicado.destaque === 'N' ? (
          <Col span={3}>
            <Form.Item label="Exibir Pop-up" size="large">
              {getFieldDecorator('expiracao', {
                initialValue:
                  DadosComunicado &&
                  DadosComunicado.comunicado &&
                  DadosComunicado.comunicado.qtdDiasPopup,
                rules: [
                  {
                    required: true,
                    message: 'Por favor selecione a quantidade de dias',
                  },
                ],
              })(
                <Select
                  size="large"
                  placeholder="Selecione..."
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    dispatch(
                      changeComunicado({
                        prop: 'DadosComunicado',
                        value: {
                          ...DadosComunicado,
                          comunicado: {
                            ...DadosComunicado.comunicado,
                            qtdDiasPopup: e,
                          },
                        },
                      })
                    );
                  }}
                >
                  <Option value={0}>Não Exibir</Option>
                  <Option value={1}>1 Dias</Option>
                  <Option value={3}>3 Dias</Option>
                  <Option value={5}>5 Dias</Option>
                  <Option value={7}>7 Dias</Option>
                  <Option value={10}>10 Dias</Option>
                  <Option value={15}>15 Dias</Option>
                  <Option value={30}>30 Dias</Option>
                  <Option value={999}>Indeterminado</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        ) : (
          ''
        )}
        {DadosComunicado &&
        DadosComunicado.comunicado &&
        DadosComunicado.comunicado.destaque === 'S' ? (
          <Col span={5}>
            <Form.Item label="Imagem Destaque" size="large" style={{ height: 155}}>
              {getFieldDecorator('imagem', {
                initialValue: DadosComunicado?.comunicado?.path_thumbnail,
                rules: [
                  {
                    required: true,
                    message: 'A imagem e obrigatoria',
                  },
                ],
              })(
                <UploadForm
                  initialValue={DadosComunicado?.comunicado?.path_thumbnail}
                  api="comunicado/comunicadoUploadFile"
                  onChange={(e) => {
                    dispatch(
                      changeComunicado({
                        prop: 'DadosComunicado',
                        value: {
                          ...DadosComunicado,
                          comunicado: {
                            ...DadosComunicado.comunicado,
                            path_thumbnail: e.file?.response?.link,
                          },
                        },
                      })
                    );
                  }}
                />
              )}
            </Form.Item>
          </Col>
        ) : (
          ''
        )}
        {isEdit ? (
          <>
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item label="Errata?" size="large">
                  {getFieldDecorator('errata', {
                    initialValue:
                      DadosComunicado &&
                      DadosComunicado.comunicado &&
                      DadosComunicado.comunicado.errata,
                  })(
                    <Select
                      size="large"
                      placeholder="Errata?"
                      onChange={(e) => {
                        dispatch(
                          changeComunicado({
                            prop: 'DadosComunicado',
                            value: {
                              ...DadosComunicado,
                              comunicado: {
                                ...DadosComunicado.comunicado,
                                errata: e,
                              },
                            },
                          })
                        );
                      }}
                    >
                      <Option key="ErrataS" value={'S'}>
                        Sim
                      </Option>
                      <Option key="ErrataN" value={'N'}>
                        Não
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Ativo?" size="large">
                  {getFieldDecorator('ativo', {
                    initialValue:
                      DadosComunicado &&
                      DadosComunicado.comunicado &&
                      DadosComunicado.comunicado.ativo,
                  })(
                    <Select
                      size="large"
                      placeholder="Ativo?"
                      onChange={(e) => {
                        dispatch(
                          changeComunicado({
                            prop: 'DadosComunicado',
                            value: {
                              ...DadosComunicado,
                              comunicado: {
                                ...DadosComunicado.comunicado,
                                ativo: e,
                              },
                            },
                          })
                        );
                      }}
                    >
                      <Option key="AtivoS" value={'S'}>
                        Sim
                      </Option>
                      <Option key="AtivoN" value={'N'}>
                        Não
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Tags?" size="small">
                  {DadosComunicado &&
                    DadosComunicado.lstComunicadoTag &&
                    DadosComunicado.lstComunicadoTag.map(({ tag }, index) => {
                      const isLongTag = tag.descricao.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag.id}
                          closable={true}
                          onClose={() => handleClose(tag)}
                        >
                          {isLongTag
                            ? `${tag.descricao.slice(0, 20)}...`
                            : tag.descricao}
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag.descricao} key={tag.id}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  {inputVisible && (
                    <AutoComplete
                      dataSource={searchArray && searchArray.map(renderOption)}
                      value={inputValue}
                      onSearch={buscaDescricaoTag}
                      onChange={(e) => setInputValue(e)}
                      placeholder="Digite ou selecione a tag"
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                      onSelect={handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={showInput}>
                      Nova Tag
                    </Tag>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}
        </Row>
      </Col>
      <Col span={6}>
        <Form.Item label="Setor/Função" size="large">
          {getFieldDecorator('setorFuncao', {
            initialValue: checkados,
            rules: [
              {
                required: true,
                message: 'Por favor selecione setor e função',
              },
            ],
          })(
            <Tree
              checkable
              checkedKeys={checkados}
              defaultExpandAll={true}
              selectable={false}
              onCheck={onCheck}
              treeData={nodesTree}
            />
          )}
        </Form.Item>
      </Col>
    </>
  );
};

export default Form.create()(ComunicadoFiltros);
