import styled from 'styled-components';

const Container = styled.div``;

const Line = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  column-gap: 14px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 44px;
`;

export { Container, Line, Column };
