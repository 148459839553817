import api from '../api';

export const fetchCategory = async (params) => {
  return await api.get(
    params?.id ? `faq/categoria/${params.id}` : 'faq/categoria'
  );
};

export const updateCategory = async (params) => {
  return await api.put(`faq/categoria/${params.id}`, params.body);
};

export const createCategory = async (params) => {
  return await api.post('faq/categoria/', params.body);
};
