import React, { useState } from 'react';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../../services/Gerais';
import { FieldBox, Column } from './styles';
import { useFilters } from './hook';
import { Button, Form, Input, Select, Transfer, Tooltip } from 'antd';

const FilterInclusion = ({
  match: {
    params: { idRelatorio, idFiltro },
  },
}) => {
  const paginaTitulo = 'Relatórios / Filtros';
  const {
    form,
    loading,
    onChange,
    requiredOptions,
    statusOptions,
    typeOptions,
    disabledSaveButton,
    postOrUpdateFilter,
  } = useFilters(idRelatorio, idFiltro);
  return (
    <>
      <Helmet
        title={paginaTitulo}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: paginaTitulo + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'relatorios, filtros, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin />
      <section className="columns is-multiline conteudoAdmin" id="admin-app">
        <MenuAdmin />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminRelatoriosInclusao"
                titulo={paginaTitulo}
                pageName={paginaTitulo}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div
                  className="button-wrapper"
                  id="components-dropdown-demo-dropdown-button"
                >
                  <Button
                    size="large"
                    type="primary"
                    href={`/admin/filtros/${idRelatorio}`}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Column>
            <FieldBox>
              <Form.Item style={{ flex: 1 }} label="Nome Exibição">
                <Input
                  placeholder="Digite o nome exibição"
                  value={form.nomeExibicao}
                  onChange={(event) =>
                    onChange(event.target.value, 'nomeExibicao')
                  }
                />
              </Form.Item>
              <Form.Item style={{ flex: 1 }} label="Status">
                <Select
                  value={form.status}
                  onChange={(value) => onChange(value, 'status')}
                  placeholder="Selecione"
                >
                  {statusOptions.map((item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FieldBox>
            <FieldBox>
              <Form.Item style={{ flex: 1 }} label="Filtro">
                <Input
                  value={form.filtro}
                  onChange={(event) => onChange(event.target.value, 'filtro')}
                  placeholder="Digite o filtro"
                />
              </Form.Item>
            </FieldBox>

            <FieldBox>
              <Form.Item style={{ flex: 1 }} label="Condição Where">
                <Input
                  value={form.condicaoWhere}
                  onChange={(event) =>
                    onChange(event.target.value, 'condicaoWhere')
                  }
                  placeholder="Digite a condição where"
                />
              </Form.Item>
            </FieldBox>
            <FieldBox>
              <Form.Item style={{ flex: 1 }} label="Tipo">
                <Select
                  value={form.tipo}
                  onChange={(value) => onChange(value, 'tipo')}
                  placeholder="Selecione"
                >
                  {typeOptions.map((item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ flex: 1 }} label="Obrigatório">
                <Select
                  value={form.obrigatorio}
                  onChange={(value) => onChange(value, 'obrigatorio')}
                  placeholder="Selecione"
                >
                  {requiredOptions.map((item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FieldBox>
            <FieldBox>
              <Form.Item style={{ flex: 1 }} label="Querie Select (SQL)">
                <Input.TextArea
                  value={form.query}
                  onChange={(event) => onChange(event.target.value, 'query')}
                  style={{ resize: 'none' }}
                />
              </Form.Item>
            </FieldBox>
            <Tooltip
              title={
                disabledSaveButton ? 'Todos campos devem ser preenchidos!' : ''
              }
            >
              <Button
                disabled={disabledSaveButton}
                loading={loading}
                onClick={async () => await postOrUpdateFilter()}
                type="primary"
              >
                Salvar
              </Button>
            </Tooltip>
          </Column>
        </div>
      </section>
      <FooterAdmin />
    </>
  );
};

export default FilterInclusion;
