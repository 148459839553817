import React, { Component } from "react";
import { Form, Button, Row, Col } from 'antd';
import {
  Helmet,
  HeaderAdmin,
  Hero,
  MenuDoc,
  FontAwesomeIcon,
} from '../../../services/Gerais';
import '../../../pages/Administrador/Workflow/styles.scss';
import WorkFlowTable from "../../../components/WorkFlow";

class WorkFlow extends Component {
  render() {
    return (
      <>
        <Helmet
          key="Status Documento"
          title="Status Documento"
          meta={[
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1"
            },
            {
              name: "description",
              content: 'Status Documento' + '- Portal Prisma'
            },
            { name: "keywords", content: "kroton, educacional, portal" }
          ]}
        />
        <HeaderAdmin key="HeaderWorkFlow" />
        <section className="columns is-multiline conteudoAdmin">
          <MenuDoc key="MenuWorkFlow" />
          <div className="column is-10 is-fullheight conteudoAdminPagina">
            <div className="columns">
              <div className="column">
                <Hero
                  key="heroAdminUsuarios"
                  pageName={`Importação status de documentos`}
                  titulo={`Documentos do Polo`}
                />
              </div>
              <div className="columns botoesAcaoCadastro">
                <div className="column">
                  <div className="button-wrapper">
                    <Button
                      type="primary"
                      size="large"
                      href={`/geradoc/importararquivo`}
                    >
                      <FontAwesomeIcon icon={['fas', 'file-import']} /> &nbsp;
                      Importação
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <WorkFlowTable isAdmin={true}/>
          </div>
        </section>
      </>
    );
  }
}

export default WorkFlow;
