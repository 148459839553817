import { Form, Modal, Upload, Spin } from 'antd';
import React, { Children, useEffect, useState } from 'react';
import Icon from 'react-multi-date-picker/components/icon';
import config from '../../config/config';
import Swal from 'sweetalert2';
import { UploadFormCalls } from './UploadFormCalls';
const uploadFormUpload = new UploadFormCalls();

export const UploadForm = (props) => {
  const {
    initialValue,
    api,
    limitSize,
    onChange,
    typeFormat,
    name,
    limitUpload,
    removeOn,
    listType,
    field,
    classStyle,
    children,
    multipl,
    BeforeUpload,
    accept,
  } = props;
  const [upload, setUpload] = useState();
  const [fileList, setFileList] = useState([]);
  const [remove, setRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const limiteUploads = limitUpload ? limitUpload : 1;

  useEffect(() => {
    if (fileList.length === 0 && initialValue?.length > 0 && !remove) {
      setRemove(false);
      if (fileList !== undefined && fileList !== []) {
        const imageName = initialValue.split('/').at(-1);
        setFileList([
          {
            uid: imageName.replace(/.+\?/g, ''),
            name: imageName,
            status: 'done',
            url: initialValue,
          },
        ]);
      }
    }
  });

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onRemove = async (e) => {
    setIsLoading(true);
    let resultRemoveFile = '';
    if (e.response) {
      resultRemoveFile = await uploadFormUpload.remove(e?.response.link);
      setIsLoading(false);
    } else {
      resultRemoveFile = await uploadFormUpload.remove(e?.url);
      setIsLoading(false);
    }

    if (resultRemoveFile.status === 200) {
      const result = fileList.filter((item) => item.uid !== e.uid);
      setRemove(true);
      setFileList(result);
    } else {
      Swal.fire('Erro ao Deletar', `error`);
    }
  };

  const onRemoveImgFromBanner = async (id) => {
    setIsLoading(true);
    const data = await uploadFormUpload.removeBanner(id);
    setIsLoading(false);
    setRemove(true);
    window.location.reload();
  }

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setUpload({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const beforeUpload = (file) => {
    let tipeList =
      typeFormat && typeFormat?.length > 0
        ? typeFormat
        : ['image/jpeg', 'image/png'];
    const isFomart = tipeList.includes(file.type);
    if (!isFomart) {
      Swal.fire({
        title: 'Ops...!',
        text: `Você só consegue fazer upload no formato ${file.type}`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      console.log(123);
      return false;
    }
    const limit = limitSize ? limitSize : 2;
    const isLtM = file.size / 1024 / 1024 < limit;
    if (!isLtM) {
      Swal.fire({
        title: 'Ops...!',
        text: `Arquivo maior que ${limit}MB`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      return false;
    }
    return isFomart && isLtM;
  };
  return (
    console.log('fileList', fileList),
    <>
      <Form.Item size="large">
        <Spin spinning={isLoading}>
        <Upload
          accept={accept}
          name={name ? name : 'file'}
          {...field}
          type="file"
          className={classStyle ? classStyle : 'avatar-uploader bannerForm'}
          headers={{
            authorization: `Bearer ${localStorage.getItem(
              'userDataCRP-AuthTk'
            )}`,
          }}
          action={`${config.baseUrl}${api ? api : 'file/upload'}`}
          listType={listType ? listType : 'picture-card'}
          fileList={fileList}
          multiple={false}
          onPreview={onPreview}
          beforeUpload={(e) => {
            beforeUpload(e);
            BeforeUpload && BeforeUpload(e);
          }}
          onChange={(e, fileList2, event) => {
            setFileList(e.fileList.slice());
            onChange && onChange(e, fileList2, event);
          }}
          onRemove={(e) =>{
            if(window.location.pathname.split('/')[2] == 'banner' &&
               window.location.pathname.split('/')[3] == 'edit'){
                onRemoveImgFromBanner(window.location.pathname.split('/')[4]);
            } else {
              onRemove(e)
            }
          }
          }
        >
          {children === undefined ? (
            fileList.length >= limiteUploads ? null : (
              <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
              </div>
            )
          ) : fileList.length >= limiteUploads ? null : (
            children
          )}
        </Upload>
        </Spin>
      </Form.Item>

      <Modal
        visible={upload?.previewVisible ? true : false}
        footer={null}
        onCancel={() => setUpload({ previewVisible: false })}
      >
        <img
          style={{ width: '100%' }}
          src={upload?.previewImage ? upload.previewImage : ''}
        />
      </Modal>
    </>
  );
};
