import React, { useState, useEffect } from 'reactn';
import { Header, Footer, Hero, Helmet } from '../../services/Gerais';
import { Button, Icon, List } from 'antd';
import api from '../../services/api';
import avatar from '../../img/loadingtwo.gif';
import ImagemSemFoto from '../../img/semfoto.jpg';
import SistemaCarousel from '../../components/SistemasCarousel';
import './styles.scss';

function Portais() {
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState({});
  const [iconDisplay, setIconDisplay] = useState(<Icon type="unordered-list" />);
  const [iconOrder, setIconOrder] = useState(<Icon type="sort-descending" />);
  const [displayList, setDisplayList] = useState(false);

  const logInfo = JSON.parse(localStorage.getItem('userDataCRP'));

  useEffect(() => {
    async function buscarDados(id) {
      try {
        const response = await api.get(`portal/findByUsuarioPoloId/${id}`);
        setMenu(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    const dataCRP = localStorage.getItem('userDataCRP-Polo');
    if (dataCRP !== '' && logInfo !== null) {
      buscarDados(logInfo.id);
    }

  }, []);

  function handleChangeDisplay() {
    if (displayList == false) {
      setDisplayList(true);
      setIconDisplay(<Icon type="table" />);
    } else {
      setDisplayList(false);
      setIconDisplay(<Icon type="unordered-list" />);
    }
  }

  if (loading) {
    return (
      <div className="CRP-loading">
        <img src={avatar} width="100" alt="Carregando..." />
      </div>
    );
  }

  return (
    <>
      <Helmet
        title="Portais - Portal Prisma"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content:
              'Portais SSO (single sign-on) acessíveis pelo Portal Prisma',
          },
          { name: 'keywords', content: 'kroton, educacional, portal, teste' },
        ]}
        key="titlePortais"
      />
      <Header key="HeaderPortais" />
      <Hero key="heroPortais" pageName="Portais" titulo="Portais" />
      <section className="section conteudoPortais" key="conteudoPortais">
        <Button
          className='button-List'
          onClick={handleChangeDisplay}
        >
          {iconDisplay}
        </Button>
        <div className="container is-full-fullhd">
          {displayList ?
            <>
            {menu.SistemasIntegrados && menu.SistemasIntegrados.length > 0 ? (
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={menu.SistemasIntegrados}
                header={<h1>Sistemas integrados</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={
                        <>
                          <img src={(item.imagem != null ? item.imagem : ImagemSemFoto)} width="60" height="60"/>
                          <a href={item.link}>{item.nome}</a>
                        </>
                    }
                    />
                  </List.Item>
                )}
              >
              </List>
              ) : (
                'Nenhum Sistema com acesso integrado.'
              )}
              {menu.SistemasNaoIntegrados &&
                menu.SistemasNaoIntegrados.length > 0 ? (
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={menu.SistemasNaoIntegrados}
                header={<h1>Sistemas não integrados</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={
                      <>
                        <img src={item.imagem} width="60" height="60"/>
                        <a href={item.link}>{item.nome}</a>
                      </>}
                    />
                  </List.Item>
                )}
              >
              </List>
              ) : (
                ''
              )}
              {menu.Transmissoes && menu.Transmissoes.length > 0 ? (
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={menu.Transmissoes}
                header={<h1>Transmissões</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={
                        <>
                          <img src={item.imagem} width="60" height="60"/>
                          <a href={item.link}>{item.nome}</a>
                        </>

                    }
                    />
                  </List.Item>
                )}
              >
              </List>
              ) : (
                ''
              )}
              {menu.Gravacoes && menu.Gravacoes.length > 0 ? (
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={menu.Gravacoes}
                header={<h1>Gravações</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={
                        <>
                          <img src={item.imagem} width="60" height="60" />
                          <a href={item.link}>{item.nome}</a>
                        </>
                    }
                    />
                  </List.Item>
                )}
              >
              </List>
              ) : (
                ''
              )}
            </>
            :
            <>
              {menu.SistemasIntegrados && menu.SistemasIntegrados.length > 0 ? (
                <SistemaCarousel
                  sistemas={menu.SistemasIntegrados}
                  titulo="Portais com Acesso Integrado:"
                  isPortalOff={false}
                  widthCard={170}
                  isLink={true}
                />
              ) : (
                'Nenhum Sistema com acesso integrado.'
              )}
              {menu.SistemasNaoIntegrados &&
                menu.SistemasNaoIntegrados.length > 0 ? (
                <SistemaCarousel
                  sistemas={menu.SistemasNaoIntegrados}
                  titulo="Portais sem Acesso Integrado:"
                  isPortalOff={false}
                  widthCard={170}
                  isLink={true}
                />
              ) : (
                ''
              )}
              {menu.Transmissoes && menu.Transmissoes.length > 0 ? (
                <SistemaCarousel
                  sistemas={menu.Transmissoes}
                  titulo="Transmissões ao Vivo:"
                  isPortalOff={false}
                  widthCard={170}
                  isLink={true}
                />
              ) : (
                ''
              )}
              {menu.Gravacoes && menu.Gravacoes.length > 0 ? (
                <SistemaCarousel
                  sistemas={menu.Gravacoes}
                  titulo="Gravações:"
                  isPortalOff={true}
                  widthCard={170}
                  isLink={true}
                />
              ) : (
                ''
              )}
            </>
          }

        </div>
      </section>
      <hr className="hrEspacamento" key="hrEspacamentoPortais" />
      <Footer key="FooterPortais" />
    </>
  );
}

export default Portais;
