import React, { Component } from 'reactn';
import { FontAwesomeIcon } from 'services/Gerais';
import Swal from 'sweetalert2';
import './style.css';

class BotaoCapu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dadosPerfil: '',
    };

    this.alertCapu = this.alertCapu.bind(this);
  }

  componentDidMount() {
    this.setState({
      dadosPerfil: JSON.parse(localStorage.getItem('userDataCRP')),
    });
  }

  alertCapu() {
    Swal.fire({
      text:
        'Antes de iniciar o atendimento com a CAPU, leia nossa FAQ, você poderá esclarescer as principais dúvidas.\n Iniciar atendimento?',
      showCancelButton: true,
      titleText: 'Atendimento CAPU',
      denyButtonText: 'Falar com a CAPU',
      showDenyButton: true,
      confirmButtonText: 'Consultar a FAQ',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isDismissed) {
        Swal.close();
      }
      if (result.isDenied) {
        window.open(
          `https://krotoneducacional.virtualinteractions.com.br/pt_BR/avi.html?id=238&source=1&target=1&channel=1&nome=${this.state.dadosPerfil.usuarioPortal.pessoa.nome}&email=${this.state.dadosPerfil.usuarioPortal.conta}&launchType=popup&ms=${new Date().getMilliseconds()}`
          );
      }
      if (result.isConfirmed) {
        window.location.href = '/faq';
      }
    });
  }

  render() {
    return (
      <div id="capuSidenav" className="sidenav">
        <a href="#" onClick={this.alertCapu} id="botaoCapu">
          <span>Olá, Seja bem vindo ao Chat CAPU, como podemos te ajudar?</span>
          <FontAwesomeIcon icon={['fas', 'comments']} />
          <span>Chat CAPU</span>
        </a>
      </div>
    );
  }
}

export default BotaoCapu;
