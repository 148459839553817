import { getByFiltersCPF, resetPassword } from "services/userPolo/service";
import Swal from 'sweetalert2';

export async function onGetFiltersByCPF(cpf) {
  await getByFiltersCPF(cpf)
}

export function showConfirmModalReset(usuario) {
  Swal.fire({
    text: `Deseja realmente resetar a senha do usuario(a) ${usuario.nome}?
    Através dessa ação o gestor responsável ira receber a nova senha do usuario.`,
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then(result => {
    if (result.isConfirmed) {
      resetPassword(usuario)
    }
  })
};
