import React, { Component } from 'react';
import '../../../../styles/Styles.scss';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../services/Gerais';
import 'flatpickr/dist/themes/material_blue.css';
import avatar from '../../../../img/loadingtwo.gif';
import { connect } from 'react-redux';
import {
  dadosIniciais,
  getUsuario,
  salvaUsuario,
  alteraUsuario,
  changeUsuario,
} from '../../../../store/actions';
import { Button, Form, Icon } from 'antd';
import SessaoPerfil from '../../../../components/Usuario/SessaoPerfil';
import SessaoUsuario from '../../../../components/Usuario/SessaoUsuario';
import Swal from 'sweetalert2';
import { Box, ButtonGroup } from './styles';
import { Link } from 'react-router-dom';

const MsgSalvoComSucesso = ({ history }) => {
  window.setTimeout(() => {
    history.push('/admin/usuario');
  }, 2000);

  Swal.fire({
    position: 'top-end',
    title: `Salvo com sucesso!`,
    icon: 'success',
    showConfirmButton: false,
    timer: 1500,
  });
  return null;
};

const MgErroAoSalvar = ({ error, changeUsuario }) => {
  let msgErroPrint = '';

  if (changeUsuario) {
    changeUsuario({
      prop: 'error',
      value: null,
    });
  }

  if (error) {
    let msgErroUsrCad = '';
    if (error.error) {
      msgErroUsrCad = error.error;
    }
    if (error.response) {
      if (
        error.response.data.path &&
        error.response.data.path.indexOf('setorByPerfil') > -1
      ) {
        Swal.fire({
          title: 'Ops...!',
          text: 'Erro ao buscar Setor!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return '';
      }
    }
    msgErroPrint = msgErroUsrCad ? msgErroUsrCad : '';

    if (msgErroUsrCad.mensagemErro) {
      Swal.fire({
        title: 'Alerta!',
        text: msgErroPrint.mensagemErro,
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      return '';
    }
    if (error && error.status === 409) {
      Swal.fire({
        title: 'Alerta!',
        text: `Erro ao salvar! ${error.data.error} Verifique as informações
        inseridas e tente novamente!`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      return '';
    } else {
      Swal.fire({
        text: `${msgErroPrint} Verifique as informações
        inseridas e tente novamente!`,
        confirmButtonText: 'Ok',
        icon:'warning'
      });
      return '';
    }
  }

  Swal.fire({
    title: 'Ops...!',
    text: `Erro ao salvar! ${msgErroPrint}`,
    icon: 'error',
    confirmButtonText: 'Ok',
  });

  return null;
};
class Cadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      perfil: {},
      isTelaPolo: false,
      usuarioSituacaoInicial: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.preparaDadosEdicao = this.preparaDadosEdicao.bind(this);
  }

  hasErrors = (fieldsError) => {
    const trocouFieldParaInativo = this.props.usuario.trocouFieldParaInativo;
    const erros = Object.keys(fieldsError).some((field) => fieldsError[field]);
    const userInicialmenteInativo = this.state.usuarioSituacaoInicial === 2;
    let isNotValid = false;
    if(this.props.usuario.lstUsuarioPoloCommand.length == 0 && this.props.usuario.usuarioPortal.situacaoUsuario.id == 1) {
      isNotValid = true;
    }
    this.props.usuario.lstUsuarioPoloCommand.forEach(function (item) {
      if (item?.funcao?.id == '') {
        isNotValid = true;
      }
      if(item.isValidPolo != undefined && item.isValidPolo) {
        isNotValid = true;
      } else if(item.isValidPolo != undefined && !item.isValidPolo) {
        isNotValid = false;
      }
      switch (item?.lstUsuarioPoloPerfil?.[0]?.perfil?.id) {
        case '1':
          isNotValid = false;
          break;
        case '2':
          isNotValid = false;
          break;
        case '999':
          isNotValid = false;
        case '503':
          isNotValid = false;
        case '502':
          isNotValid = false;
          break;
      }
    });
    if (erros || isNotValid) {
      return true;
    } else if (trocouFieldParaInativo && userInicialmenteInativo) {
      return true;
    }
  };

  componentDidUpdate() {
    if (!this.props.usuario.carregando && !this.state.usuarioSituacaoInicial) {
      this.setState({
        usuarioSituacaoInicial: this.props.usuario.usuarioPortal.situacaoUsuario
          .id,
      });
    }
  }

  componentDidMount() {
    this.setState({ isTelaPolo: false });
    this.props.dadosIniciais(JSON.parse(localStorage.getItem('userDataCRP')));
    this.props.usuario.botaoSalvarHabilitado = false;
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.setState({ isEdit: true });
      this.props.usuario.carregando = true;
      this.props.getUsuario(this.props.match.params.id);
    }
    // this.props.form.validateFields();
  }

  validStatusUser = () => {
    let idStatus = parseInt(this.props.usuario.usuarioPortal.situacaoUsuario.id)
    if (idStatus != 1) {
      Swal.fire({
        title: `Situação do funcionario`,
        text: `Você está salvando o cadastro do usuário com o Status ${(idStatus == 2 ? 'INATIVO' : 'BLOQUEADO')}, deseja prosseguir?”`,
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          this.save();
        } else {
          return
        }
      })
    } else {
      this.save();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.validStatusUser();
      }
    });
  };

  save = () => {
    let { usuario } = this.props;
    this.props.usuario.botaoSalvarHabilitado = true;
    if (this.state.isEdit) {
      let usuarioEditado = this.preparaDadosEdicao(usuario);
      this.props.alteraUsuario(usuarioEditado, this.props.match.params.id);
    } else {
      this.props.salvaUsuario(usuario);
      this.setState({ isTentaSalvar: true });
    }
  }

  preparaDadosEdicao = (usuario) => {
    let usuarioEdicao = Object.assign({}, usuario);
    let userData = JSON.parse(localStorage.getItem('userDataCRP'));

    usuario.usuarioPortal.usuarioPortalLog = {
      id: userData.usuarioPortalLog.id,
    };

    usuarioEdicao.lstUsuarioPoloCommand.forEach((item) => {
      delete item.usuarioPolo.setorFuncao;
      if (item.lstPerfilSetorFuncaoSistema) {
        delete item.lstPerfilSetorFuncaoSistema;
      }

      if (item.lstUsuarioPoloPerfil) {
        let isAdmOrCorporativo = item.lstUsuarioPoloPerfil[0].perfil.id;
        delete item.lstUsuarioPoloPerfil[0].usuarioPolo;
        item.usuarioPolo.polo =
          isAdmOrCorporativo.toString() === '1' ||
          isAdmOrCorporativo.toString() === '2'
            ? { id: '' }
            : item.usuarioPolo.polo;
      }
    });
    return usuarioEdicao;
  };

  renderSessaoPerfil = () => {
    const { usuario } = this.props;
    if (
      usuario.usuarioPortal.funcionario &&
      !usuario.carregando
    ) {
      return (
        <SessaoPerfil
          form={this.props.form}
          isEdit={this.state.isEdit}
          isTelaPolo={this.state.isTelaPolo}
          perfilID={localStorage.getItem('userDataCRP-Perfil')}
        />
      );
    }
  };

  render() {
    const { dadosPerfil, usuario } = this.props;
    const { isEdit, isTelaPolo } = this.state;
    const { getFieldsError } = this.props.form;
    if (dadosPerfil.carregandoInicial) {
      return (
        <div className="CRP-loading">
          <img src={avatar} width="100" alt="Carregando..." />
        </div>
      );
    }

    return (
      <div>
        <Helmet
          title={`Cadastro de Usuário | Portal Prisma`}
          key="TitleAdminUsuarioCadastro"
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: 'Cadastro de usuário - Portal Prisma',
            },
            {
              name: 'keywords',
              content: 'cadastro, usuário, kroton, educacional, portal',
            },
          ]}
        />
        <HeaderAdmin key="HeaderAdminCadastroUsuarios" />
        <section
          key="SectionAdmin"
          className="columns is-multiline conteudoAdmin"
          id="admin-app"
        >
          <MenuAdmin key="MenuAdminUsuarioCadastro" />
          <div className="column is-10 is-fullheight conteudoAdminPagina">
            <Hero
              key="heroAdminUsuarios"
              pageName={`Cadastro de Usuário`}
              titulo={`Cadastro de Usuário`}
            />

            {usuario.succes && (
              <MsgSalvoComSucesso history={this.props.history} />
            )}
            {!usuario.sucess && usuario.error && (
              <MgErroAoSalvar
                error={usuario.error}
                changeUsuario={this.props.changeUsuario}
              />
            )}
            <Form onSubmit={this.handleSubmit}>
              <SessaoUsuario
                form={this.props.form}
                isEdit={isEdit}
                isTelaPolo={isTelaPolo}
                perfilID={localStorage.getItem('userDataCRP-Perfil')}
              />
              {this.renderSessaoPerfil()}
              <br />
              <Box space={this.props.match.params.profileId}>
                {this.props.match.params.profileId && (
                  <Link
                    to={`/admin/usuarios/vinculo-gn/lista/${usuario.usuarioPortal.id}`}
                  >
                    <Button size="large" type="primary">
                      Gestores Vinculados
                    </Button>
                  </Link>
                )}

                <ButtonGroup>
                  <Button
                    size="large"
                    onClick={() => this.props.history.push('/admin/usuario')}
                  >
                    <Icon type="stop" />
                    Cancelar
                  </Button>

                  {usuario.botaoSalvarHabilitado ? (
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        'background-color': '#52c41a',
                        border: 'none',
                      }}
                      loading
                    >
                      Carregando
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        'background-color': '#52c41a',
                        border: 'none',
                      }}
                      htmlType="submit"
                      disabled={this.hasErrors(getFieldsError())}
                    >
                      <Icon type="save" /> Salvar
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            </Form>
          </div>
        </section>
        <FooterAdmin key="FooterAdminUsuariosCadastro" />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  dadosPerfil: store.DadosPerfil,
  usuario: store.Usuario,
});

export default connect(mapStateToProps, {
  dadosIniciais,
  getUsuario,
  salvaUsuario,
  alteraUsuario,
  changeUsuario,
})(Form.create()(Cadastro));
