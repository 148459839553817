import React, { Component } from 'reactn';
import {
  Error,
  Header,
  Footer,
  library,
  FontAwesomeIcon,
  $,
  Helmet,
  axios,
  TAGS,
  ComunicadoDisplay,
} from '../../services/Gerais';
import {
  faCheck,
  faArrowLeft,
  faArrowRight,
  faUserCircle,
  faShare,
  faBookmark,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonContainer } from './styles';
import BotaoCapu from '../../components/BotaoCapu';
import ReactGA from 'react-ga';
import {
  Popover,
  Button,
  Select,
  Row,
  Col,
  Icon,
  Input,
  AutoComplete,
  Form,
} from 'antd';
import Swal from 'sweetalert2';
import '../../styles/Styles.scss';
import '../../styles/ComunicadosBusca.scss';
import moment from 'moment/moment';
import api from 'services/api';
library.add({
  faCheck,
  faBookmark,
  faArrowLeft,
  faArrowRight,
  faUserCircle,
  faShare,
});

const { Option } = Select;

export default class ComunicadosBusca extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location);
    const params = this.props.match.params;
    this.state = {
      page: query.get('page') || 0,
      size: query.get('size') || 12,
      idUsuarioPortal: JSON.parse(localStorage.getItem('userDataCRP'))
        .usuarioPortal.id,
      pilarPublicacao: params?.id ? params.id : '',
      subcategoria: params?.subcategoriaId ? params.subcategoriaId : '',
      nomeComunicado: query.get('nomeComunicado'),
      idComunicado: query.get('idComunicado'),
      prioridade: query.get('prioridade'),
      lido: query.get('lido') || 'N',
      favorito: query.get('favorito'),
      asc: query.get('asc') || 'false',
      dataInicial:
        query.get('dataInicial') ||
        moment(new Date())
          .subtract(90, 'days')
          .format('YYYY-MM-DD')
          .replace(/-/g, '/') ||
        '',
      dataFinal:
        query.get('dataFinal') ||
        moment(new Date()).format('YYYY-MM-DD').replace(/-/g, '/') ||
        '',
      buscaPadrao: query.get('buscaPadrao') || false,

      comunicados: [],
      showComunicado: [],
      error: [],
      props: props,
      isActived: false,
      tags: [],
      filteredTags: [],
      tagId: '',
      getComunicados: {},
      exibePesquisa: false,
      tituloComunicados: '',
      pagina: parseInt(query.get('page') || 0) + 1,
      paginaAnterior: parseInt(query.get('page') || 0),
      areas: [],
      pathArray: window.location.pathname.split('/'),
      isChecked: false,
      linkPaginacao: '',
      lstCategoria: [],
      lstSubCategoria: [],
    };
    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.mudarPagina = this.mudarPagina.bind(this);
    this.paginacaoBusca = this.paginacaoBusca.bind(this);
    this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this);
    this.updateStateBusca = this.updateStateBusca.bind(this);
    this.disableFavoritos = this.disableFavoritos.bind(this);
    this.buscaTags = this.buscaTags.bind(this);
    this.handleChangeCategoria = this.handleChangeCategoria.bind(this);
    this.handleChangeSubCategoria = this.handleChangeSubCategoria.bind(this);
  }

  handleChangeCategoria = (e) => {
    this.setState({ pilarPublicacao: e.target.value})
    this.getSubCategoriaById(e);
  }

  handleChangeSubCategoria = (e) => {
    this.setState({ subcategoria: e.target.value})
    this.getSubCategoriaById(e);
  }

  copyCodeToClipboard = (comunicado) => {
    let textField = document.createElement('textarea');
    textField.innerText = `${window.location.origin}/comunicados/${comunicado.id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    Swal.fire({
      position: 'top-end',
      title: `Copiado!`,
      text: 'URL do comunicado foi copiada.',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  geraComunicado = (e, comunicado, btn) => {
    e.preventDefault();

    let lido = comunicado.lido;
    let favorito = comunicado.favorito;

    if (btn === 'like') {
      if (favorito !== 'S') {
        favorito = 'S';
        lido = 'S';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'fas');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z'
          );
      } else {
        favorito = 'N';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'far');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z'
          );
      }
    } else {
      lido = 'S';
      $(document)
        .find('[data-comunicado-lido="' + comunicado.id + '"]')
        .addClass('lido');
      this.toggleNaved();
    }

    // marcando como lido
    const dataComunicadoUsuario = [
      {
        usuarioPortal: { id: this.logInfo['usuarioPortal']['id'] },
        comunicado: { id: comunicado.id },
        favorito: favorito,
        comunicadoLido: lido,
        usuarioPortalLog: { id: this.logInfo['usuarioPortal']['id'] },
      },
    ];

    axios
      .post(
        this.global.baseUrl + 'comunicadoUsuario/saveComunicadoUsuario',
        dataComunicadoUsuario[0],
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then((result) => {
        ReactGA.modalview('../../comunicados/comunicado/' + comunicado.codigo);
        axios
          .get(
            this.global.baseUrl +
              'comunicadoUsuario/getComunicadoByComunicadoAndUsuario/' +
              this.logInfo['usuarioPortal']['id'] +
              '/' +
              comunicado.id,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
              },
            }
          )
          .then((infosRes) =>
            this.setState({
              showComunicado: infosRes.data,
              isLoading: false,
            })
          )
          .catch((error) =>
            this.setState({
              error,
              isLoading: false,
            })
          );
      })
      .catch((error) => {
        // console.error(error)
      });

    //this.componentDidMount();
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    this.getInit();
    if (query.get('nomeComunicado'))
      document.getElementById('nomeComunicado').value = query.get(
        'nomeComunicado'
      );

    this.searchComunicados();
    $('.react-tags__selected-tag').prop('title', 'Clique para excluir');

    axios
      .get(this.global.baseUrl + 'tag/', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then((res) => {
        this.setState({
          tags: res.data,
          filteredTags: res.data,
        });
      });

    this.render();
  }

  toggleNaved = () => {
    this.setState((prevState) => ({ isActived: !prevState.isActived }));
  };

  getInit = async () => {
    const resp = await api.get('comunicado/getInitDataComunicados');
    this.setState({
      lstCategoria: resp.data.categorias,
      lstSubCategoria: resp.data.subcategorias,
    });

    this.render();
  };

  mudarPagina = (e, pg) => {
    e.preventDefault();
    this.setState({
      page: pg - 1,
      pagina: pg,
      paginaAnterior: pg - 1,
    });
    this.searchComunicados();
  };

  paginacaoBusca = (pagina, total, size) => {
    let lista = [];
    let children = [];
    let calculo = total / size + 1;
    for (let j = 1; j < calculo; j++) {
      if (j >= pagina - 3 && j <= pagina + 3) {
        children.push(
          <a
            href="javascript:void(0)"
            onClick={(e) => this.mudarPagina(e, j)}
            className={'pagination-link ' + (pagina === j && 'is-current')}
            key={'paginationList' + j}
          >
            {j}
          </a>
        );
      }
    }
    lista.push(
      <ul key={'paginationList'} className="pagination-list">
        {children}

        <div className="label-box-announcements">
          <div className="border-box-announcements">
            <div className="box-label-announcements">
              <div className="box-announcements critical" />
              <p>CRÍTICO</p>
            </div>
            <div className="box-label-announcements">
              <div className="box-announcements action" />
              <p>AÇÃO</p>
            </div>
            <div className="box-label-announcements">
              <div className="box-announcements info" />
              <p>INFORMAÇÃO</p>
            </div>
            <div className="box-label-announcements">
              <div className="box-announcements reinforcement" />
              <p>REFORÇO</p>
            </div>
          </div>
        </div>
      </ul>
    );
    return lista;
  };

  searchComunicados = () => {
    this.setState({
      exibePesquisa: false,
    });

    let polos = JSON.parse(localStorage.getItem('userDataCRP-Polos'));
    let polo = ''; // mantém caso não haja polo

    if (polos.length == 1 && polos[0].polo) polo = polos[0].polo.id; //polo unico
    if (polos.length > 1 && document.querySelector('.selectPoloHeader select'))
      polo = document.querySelector('.selectPoloHeader select').value; // multiplos polos. Usa o polo selecionado pelo usuário
    let perfil = localStorage.getItem('userDataCRP-Perfil');
    let isPerfilADM = false;

    if (
      perfil == 1 ||
      perfil == 2 ||
      perfil == 999 ||
      perfil == 501 ||
      perfil == 506 ||
      perfil == 502 ||
      perfil == 503
    ) {
      isPerfilADM = true;
    }

    let queryString = [
      `?page=${this.state.page}`,
      `&size=${this.state.size}`,
      `&idUsuarioPortal=${this.state.idUsuarioPortal}`,
      `&buscaPadrao=${this.state.buscaPadrao}`,
      polo ? `&poloId=${polo}` : '',
      this.state.pilarPublicacao
        ? `&pilarPublicacao=${this.state.pilarPublicacao}`
        : '',
      this.state.subcategoria
        ? `&subcategoriaId=${this.state.subcategoria}`
        : '',
      this.state.nomeComunicado
        ? `&nomeComunicado=${this.state.nomeComunicado}`
        : '',
      this.state.idComunicado ? `&idComunicado=${this.state.idComunicado}` : '',
      this.state.prioridade ? `&prioridade=${this.state.prioridade}` : '',
      this.state.lido ? `&lido=${this.state.lido}` : '',
      this.state.favorito ? `&favorito=${this.state.favorito}` : '',
      this.state.asc ? `&asc=${this.state.asc}` : '',
      this.state.tagId ? `&tagsIds=${this.state.tagId}` : '',
      this.state.dataInicial ? `&dataInicial=${this.state.dataInicial}` : '',
      this.state.dataFinal ? `&dataFinal=${this.state.dataFinal}` : '',
    ].join('');
    axios
      .get(this.global.baseUrl + 'comunicado/buscarComunicados' + queryString, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then((result) =>
        this.setState({
          getComunicados: result.data,
          exibePesquisa: true,
        })
      )
      .catch((erro) => {
        this.setState({
          error: erro,
        });
      });

    this.render();
  };

  updateStateBusca = (e) => {
    e.preventDefault();
    const publicadoDe = document.getElementById('dataInicial').value;
    const publicadoAte = document.getElementById('dataFinal').value;
    const period = moment(publicadoDe).add(90, 'days');
    if (moment(publicadoAte).diff(period, 'days') > 0) {
      return Swal.fire({
        title: 'Atenção',
        text:
          'As datas "Publicado de" e "Publicado até" devem ter o periodo máximo entre 90 dias.',
      });
    }
    this.setState(
      {
        getComunicados: {},
        page: 0,
        pagina: 1,
        paginaAnterior: 0,
        buscaPadrao: false,
        pilarPublicacao: document.getElementById('pilarPublicacao').value,
        subcategoria: document.getElementById('subcategoria').value,
        nomeComunicado: document.getElementById('nomeComunicado').value.trim(),
        idComunicado: document.getElementById('idComunicado').value.trim(),
        prioridade: document.getElementById('prioridade').value,
        lido: document.getElementById('lido').value,
        favorito: document.getElementById('favorito').value,
        asc: document.getElementById('asc').value,
        tagId: document.getElementById('tag').value,
        dataInicial: publicadoDe.replace(/-/g, '/') || '',
        dataFinal: publicadoAte.replace(/-/g, '/') || '',
      },
      () => {
        this.searchComunicados();
      }
    );
  };

  buscaTags = (search) => {
    if (search && search.length > 1) {
      const optionsArray = this.state.tags;

      const searchArray =
        optionsArray.length > 0
          ? optionsArray.filter((item) =>
              item.descricao.toUpperCase().includes(search.toUpperCase())
            )
          : [];

      this.setState({
        filteredTags: searchArray,
      });
    } else {
      this.setState({
        filteredTags: this.state.tags,
      });
    }
  };

  changeTag(e) {
    document.querySelector('#tag').value = e;
  }

  clearBusca(e) {
    this.setState({
      getComunicados: { content: [] },
    });
  }

  disableFavoritos(e) {
    let lido = e.target;
    if (lido.value == 'N') {
      this.setState({ favorito: '' });
      document.getElementById('favorito').disabled = true;
      document.getElementById('favorito').value = '';
    } else {
      document.getElementById('favorito').disabled = false;
    }
  }

  getSubCategoriaById = async (id) => {

    const resp = await api.get(
      `subcategoria/getByCategoria/${id.target.value}`
    );
    console.log('id.target.value', id.target.value)
    this.setState({
      lstSubCategoria: resp.data,
    });
  };

  render() {
    const {
      error,
      getComunicados,
      showComunicado,
      pagina,
      paginaAnterior,
      size,
      exibePesquisa,
      filteredTags,
    } = this.state;

    let perfilId = parseInt(localStorage.getItem('userDataCRP-Perfil'));
    let renderCAPU = '';
    if (perfilId !== 504) {
      renderCAPU = <BotaoCapu key="BotaoCapuHome" />;
    } else {
      renderCAPU = '';
    }

    return [
      <Helmet
        title="Busca de Comunicados - Portal Prisma - Kroton"
        key="titleComunicadosBusca"
        meta={[
          {
            name: 'description',
            content:
              'Comunicados por categoria - Portal Unificado da Kroton Educacional',
          },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'keywords',
            content: 'comunicados, busca, kroton, educacional, portal',
          },
        ]}
      />,
      <Header key="HeaderBuscaComunicados" />,
      <section className="hero is-crp" key="heroComunicados">
        <div className="hero-body">
          <div className="container is-fullhd">
            <nav
              className="breadcrumb is-left"
              aria-label="breadcrumbs"
              key={'navegacao'}
            >
              <ul>
                <li>
                  <a href="./../home">Home</a>
                </li>
                <li>
                  <a href="./../comunicados" aria-current="page">
                    Comunicados (Geral)
                  </a>
                </li>
                <li className="is-active">
                  <a href="./" aria-current="page">
                    Busca de Comunicados
                  </a>
                </li>
              </ul>
            </nav>
            <h1 className="is-title is-12">Busca de Comunicados</h1>
          </div>
        </div>
      </section>,
      <section
        className="section comunicadosDestaque comunicadosCategoria"
        key="conteudoComunicados"
      >
        <div className="listaCategoriaComunicados">
          <div className="field buscaComunAdv">
            <form
              onSubmit={(e) => this.updateStateBusca(e)}
              id="formPesquisaComunicados"
            >
              <div className="columns is-multiline ">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="column is-12 is-relative"
                >
                  <p className="subtitle">
                    Você pode encontrar o comunicado que precisa combinando os
                    campos abaixo:
                  </p>
                  <Button
                    type="primary"
                    className="is-aligned-up-right"
                    href="../../comunicados"
                  >
                    Busca Antiga
                  </Button>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="pilarPublicacao">Categoria</label>
                    <div className="control">
                      <div className="select fullwidth">
                        <select
                          name="pilarPublicacao"
                          value={this.state.pilarPublicacao}
                          id="pilarPublicacao"
                          className="fullwidth"
                          onChange={this.handleChangeCategoria}
                        >
                          <option key={''} value={''}>
                            Selecione...
                          </option>
                          {this.state?.lstCategoria?.map((key) => {
                            return (
                              <option key={key.id} value={key.id}>
                                {key.descricao}
                              </option>
                            );
                          })}{' '}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="subcategoria">Sub-Categoria</label>
                    <div className="control">
                      <div className="select fullwidth">
                        <select
                          name="subcategoria"
                          id="subcategoria"
                          className="fullwidth"
                          value={this.state?.subcategoria}
                          onChange={this.handleChangeSubCategoria}
                        >
                          <option key={''} value={''}>
                            Selecione...
                          </option>
                          {this.state?.lstSubCategoria?.map((key) => {
                            return (
                              <option key={key.id} value={key.id}>
                                {key.descricao}
                              </option>
                            );
                          })}{' '}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="nomeComunicado">
                      Assunto (Palavra Chave)
                    </label>
                    <input
                      name="nomeComunicado"
                      id="nomeComunicado"
                      type="text"
                      className="input"
                    />
                  </div>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="tag">#Hashtag</label>
                    <input
                      name="tag"
                      id="tag"
                      type="hidden"
                      className="input"
                    />
                    <AutoComplete
                      dataSource={filteredTags.map((key) => {
                        return <Option key={[key.id]}>{key.descricao}</Option>;
                      })}
                      onChange={(e) => this.changeTag(e)}
                      onSearch={this.buscaTags}
                    />
                  </div>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="prioridade">Criticidade</label>
                    <div className="control">
                      <div className="select fullwidth">
                        <select
                          name="prioridade"
                          id="prioridade"
                          className="fullwidth"
                        >
                          <option value="">Selecione...</option>
                          <option value="C">Crítico</option>
                          <option value="A">Ação</option>
                          <option value="I">Informação</option>
                          <option value="R">Reforço</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-1">
                  <div className="field">
                    <label htmlFor="lido">Lido</label>
                    <div className="control">
                      <div className="select fullwidth">
                        <select
                          name="lido"
                          id="lido"
                          defaultValue="N"
                          className="fullwidth"
                          onChange={(e) => this.disableFavoritos(e)}
                        >
                          <option value="">Selecione...</option>
                          <option value="S">Sim</option>
                          <option value="N">Não</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-1">
                  <div className="field">
                    <label htmlFor="favorito">Favorito</label>
                    <div className="control">
                      <div className="select fullwidth">
                        <select
                          name="favorito"
                          id="favorito"
                          className="fullwidth"
                        >
                          <option value="">Selecione...</option>
                          <option value="S">Sim</option>
                          <option value="N">Não</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="dataInicial">Publicado De</label>
                    <input
                      required
                      defaultValue={moment(new Date())
                        .subtract(90, 'days')
                        .format('YYYY-MM-DD')}
                      name="dataInicial"
                      id="dataInicial"
                      type="date"
                      className="input"
                    />
                  </div>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="dataInicial">Publicado Até</label>
                    <input
                      required
                      defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                      name="dataFinal"
                      id="dataFinal"
                      type="date"
                      className="input"
                    />
                  </div>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="asc">Ordenação</label>
                    <div className="control">
                      <div className="select fullwidth">
                        <select
                          defaultValue="false"
                          name="asc"
                          id="asc"
                          className="fullwidth"
                        >
                          <option value="">Selecione...</option>
                          <option value="true">Mais antigo</option>
                          <option value="false">Mais recente</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-2">
                  <div className="field">
                    <label htmlFor="idComunicado">Código do comunicado</label>
                    <input
                      name="idComunicado"
                      id="idComunicado"
                      type="text"
                      className="input"
                    />
                  </div>
                </div>
                <ButtonContainer>
                  <Button
                    type="primary"
                    htmlType="reset"
                    onClick={(e) => this.clearBusca(e)}
                  >
                    Limpar
                  </Button>
                  <Button
                    id="btnPesquisarComunicados"
                    type="primary"
                    htmlType="submit"
                  >
                    Pesquisar
                  </Button>
                </ButtonContainer>

                <div className="label-box-announcements">
                  <div className="border-box-announcements">
                    <div className="box-label-announcements">
                      <div className="box-announcements critical" />
                      <p>CRÍTICO</p>
                    </div>
                    <div className="box-label-announcements">
                      <div className="box-announcements action" />
                      <p>AÇÃO</p>
                    </div>
                    <div className="box-label-announcements">
                      <div className="box-announcements info" />
                      <p>INFORMAÇÃO</p>
                    </div>
                    <div className="box-label-announcements">
                      <div className="box-announcements reinforcement" />
                      <p>REFORÇO</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {error.response ? <Error {...error} /> : null}
          {getComunicados.hasOwnProperty('content') ? (
            getComunicados.content.length ? (
              <div className="column">
                {exibePesquisa ? (
                  <div className="columns is-multiline">
                    {getComunicados['content'].map((comunicado) => {
                      let boxAlerta = '';
                      let boxAlertaSpan = '';
                      let listaTags;
                      let errata = '';
                      let lido = 'check';

                      if (comunicado.lido === 'S') {
                        lido = 'check lido';
                      } else {
                        lido = 'check';
                      }

                      let isMinutoOperacao =
                        comunicado.destaque == 'S' ? true : false;

                      switch (comunicado.prioridade) {
                        case 'C':
                          boxAlertaSpan = (
                            <span className="alerta alerta-red">
                              <FontAwesomeIcon
                                icon={['fas', 'thermometer-full']}
                                className="icon-thermometer"
                                color="#ffffff"
                              />
                            </span>
                          );
                          boxAlerta = 'boxAlerta-red boxAlertaSemCarousel';
                          break;
                        case 'A':
                          boxAlertaSpan = (
                            <span className="alerta alerta-orange">
                              <FontAwesomeIcon
                                icon={['fas', 'thermometer-three-quarters']}
                                className="icon-thermometer"
                                color="#ffffff"
                              />
                            </span>
                          );
                          boxAlerta = 'boxAlerta-orange boxAlertaSemCarousel';
                          break;
                        case 'I':
                          boxAlertaSpan = (
                            <span className="alerta alerta-blue">
                              <FontAwesomeIcon
                                icon={['fas', 'thermometer-half']}
                                className="icon-thermometer"
                                color="#ffffff"
                              />
                            </span>
                          );
                          boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                          break;
                        case 'R':
                          boxAlertaSpan = (
                            <span className="alerta alerta-green">
                              <FontAwesomeIcon
                                icon={['fas', 'thermometer-quarter']}
                                className="icon-thermometer"
                                color="#ffffff"
                              />
                            </span>
                          );
                          boxAlerta = 'boxAlerta-green boxAlertaSemCarousel';
                          break;
                        default:
                          boxAlertaSpan = (
                            <span className="alerta alerta-blue">
                              <FontAwesomeIcon
                                icon={['fas', 'thermometer-half']}
                                className="icon-thermometer"
                                color="#ffffff"
                              />
                            </span>
                          );
                          boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                          break;
                      }

                      if (comunicado.errata === 'S') {
                        errata = 'ATUALIZAÇÃO - ';
                      }

                      return (
                        <div
                          className="column is-one-quarter item"
                          data-index={comunicado.codigo}
                          key={comunicado.codigo}
                        >
                          <span
                            className="salvarComunicadoRelativo"
                            data-comunicado-favorito={comunicado.id}
                            onClick={(e) =>
                              this.geraComunicado(e, comunicado, 'like')
                            }
                            title={
                              comunicado.favorito === 'S'
                                ? 'Desmarcar favorito'
                                : 'Marcar como favorito'
                            }
                          >
                            <FontAwesomeIcon
                              icon={[
                                comunicado.favorito === 'S' ? 'fas' : 'far',
                                'bookmark',
                              ]}
                            />
                          </span>
                          <div className={'box bgComunicados ' + boxAlerta}>
                            <div
                              onClick={(e) =>
                                this.geraComunicado(e, comunicado, 'titulo')
                              }
                            >
                              {boxAlertaSpan}
                              <article className="media">
                                <div className="media-content">
                                  <div className="content">
                                    <b className="title is-6 is-spaced comunicadosInfos">
                                      <label
                                        className={lido}
                                        data-comunicado-lido={comunicado.id}
                                      >
                                        <FontAwesomeIcon
                                          icon={['fas', 'check']}
                                        />
                                        <FontAwesomeIcon
                                          icon={['fas', 'check']}
                                        />
                                      </label>
                                      <small>{comunicado.codigo}</small>
                                    </b>
                                    <hr className="hrNoBorder" />
                                    <h5
                                      className="title is-6 is-spaced"
                                      data-comunicado={comunicado.id}
                                    >
                                      <span>
                                        {errata}
                                        {comunicado.titulo &&
                                        comunicado.titulo.length > 60
                                          ? comunicado.titulo
                                              .slice(0, 60)
                                              .concat('...')
                                          : comunicado.titulo}
                                      </span>
                                    </h5>
                                    <small
                                      className="smallComunicado"
                                      dangerouslySetInnerHTML={{
                                        __html: comunicado.resumoNovo,
                                      }}
                                    ></small>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <div className="content is-small referenciaComunicados">
                              {listaTags}
                              <hr />
                              {isMinutoOperacao ? (
                                ''
                              ) : (
                                <Button
                                  key={`edit_${comunicado.id}`}
                                  title={'Compartilhar esse comunicado'}
                                  size="small"
                                  type="primary"
                                  onClick={() =>
                                    this.copyCodeToClipboard(comunicado)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={['fas', 'share-alt']}
                                  />
                                  <span style={{ paddingLeft: 5 }}>
                                    Compartilhar
                                  </span>
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>Carregando...</p>
                )}

                <div className="columns">
                  <div className="column">
                    <nav
                      className="pagination is-centered"
                      role="navigation"
                      aria-label="pagination"
                    >
                      {paginaAnterior > 1 && (
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => this.mudarPagina(e, pagina - 1)}
                          className="button pagination-previous is-info"
                        >
                          Página anterior ({pagina - 1})
                        </a>
                      )}
                      {pagina !== getComunicados['totalElements'] &&
                      getComunicados['totalElements'] > size &&
                      pagina <= getComunicados['totalElements'] / size ? (
                        <a
                          href="./"
                          onClick={(e) => this.mudarPagina(e, pagina + 1)}
                          className="button pagination-next is-info"
                        >
                          Próxima página ({pagina + 1})
                        </a>
                      ) : null}
                      {this.paginacaoBusca(
                        pagina,
                        getComunicados['totalElements'],
                        size
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            ) : (
              <div className="notification is-warning">
                <strong>
                  Sentimos muito...
                  <span role="img" aria-label="😶">
                    😶
                  </span>
                </strong>
                <p>Nenhum comunicado encontrado com os parametros acima!</p>
              </div>
            )
          ) : (
            <p>Carregando...</p>
          )}
          <div
            className={
              this.state.isActived ? 'conteudoLink is-active' : 'conteudoLink'
            }
          >
            <div className=" is-fullhd">
              <div className="columns is-gapless">
                <div
                  className="is-transparent"
                  id="columnClose"
                  style={{ width: '15%' }}
                  onClick={this.toggleNaved}
                />
                <ComunicadoDisplay
                  comunicado={showComunicado}
                  isDestaque={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>,
      <Footer key="FooterBuscaComunicados" />,
      <div>{renderCAPU}</div>,
    ];
  }
}
