import { Button, Icon, Modal, notification, Row } from 'antd';
import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { enviar, logError, download, onRegerar } from '../../service';
import { ActionContainer } from './styled';
import { UploadForm } from 'components/uploadForm/UploadForm';
import Swal from 'sweetalert2';

let ConvertStringToHTML = function (str) {
  let parser = new DOMParser();
  let doc = parser.parseFromString(str, 'text/html');
  return doc.body;
};

let templateUrl = '';

async function downloadFile(id) {
  const data = await download(id);
  window.open(data.data, '_blank')
}

export async function regerar (id, templateUrl) {
  const data = await onRegerar(id, templateUrl);
  if(data.status == 200) {
    Swal.fire({
      position: 'top-end',
      title: `Template atualizado com sucesso!`,
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    });
  }
}

export const tableColumns = [
  {
    title: 'ID',
    key: 'id',
    width: 50,
    dataIndex: 'id',
    valueType: 'indexBorder',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Polo',
    key: 'polo',
    dataIndex: 'polo',
    valueType: 'polo',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Tipo Docto',
    key: 'tipoDocumento',
    dataIndex: 'tipoDocumento',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Cofre D4',
    key: 'cofreD4Sign',
    dataIndex: 'cofreD4Sign',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Data Geração',
    key: 'dataGeracao',
    dataIndex: 'dataGeracao',
    copyable: true,
    ellipsis: true,
    render: (row) => {
      if(row != undefined) {
        return row.split(' ')[0];
      }
    }
  },
  {
    title: 'Data Envio',
    key: 'dataEnvio',
    dataIndex: 'dataEnvio',
    copyable: true,
    ellipsis: true,
    render: (row) => {
      if(row != undefined) {
        return row.split(' ')[0];
      }
    }
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Ação',
    valueType: 'action',
    dataIndex: 'id',
    width: 300,
    key: 'edit',
    render: (_, record) => {
      return (
        <ActionContainer key={`action_${record.id}`}>
          {record?.acoes?.includes('Download') && (
            <Button
              onClick={() => { downloadFile(record.id) }}
              className="button is-small is-success"
              size="small"
              type="default"
            >
              <Icon type="download" />
              Download
            </Button>
          )}
          {record?.acoes?.includes('Editar') && (
            <Button
              href={`/geradoc/envioDocumento/edit/${record?.documentoId}`}
              type="dashed"
              className="button is-small is-info"
              size="small"
            >
              <Icon type="edit" />
              Editar
            </Button>
          )}
          {record?.acoes?.includes('Enviar') && (
            <Button
              onClick={async () => {
                try {
                  const result = await enviar(record?.id);
                  notification['success']({
                    message: `${result.data}`,
                  });
                } catch (error) {
                  notification['error']({
                    message: `Erro ao processar envio`,
                    description: `${error.message}`,
                  });
                }
              }}
              type="dashed"
              className="button is-small is-danger"
              size="small"
            >
              <Icon type="upload" />
              Enviar
            </Button>
          )}
          {record?.acoes?.includes('Log') && (
            <Button
              onClick={async () => {
                try {
                  const result = await logError(record?.id);
                  Modal.warning({
                    title: `Este são os logs de erro`,
                    width: '80%',
                    content: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: result.data.join('<br/>').toString(),
                        }}
                      ></div>
                    ),
                    onOk() { },
                  });
                } catch (error) {
                  notification['error']({
                    message: `Erro ao processar envio`,
                    description: `${error.message}`,
                  });
                }
              }}
              type="dashed"
              className="button is-small is-danger"
              size="small"
            >
              <Icon type="file-excel" />
              Log
            </Button>
          )}
          {record?.acoes?.includes('Regerar') && (
            <Button
              onClick={async () => {
                try {
                  const result = await logError(record?.id);
                  Modal.warning({
                    title: `Houve um erro no template`,
                    width: '50%',
                    content: (
                      <>
                        <Row>
                          <UploadForm
                            name="file"
                            accept=".docx"
                            listType="defaultFileList"
                            onChange={(e) => templateUrl = e.file?.response?.link}
                            typeFormat={[
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            ]}
                            api="file/upload"
                          >
                            <Button
                              icon={<UploadOutlined />}
                              size="large"
                            >
                              Localizar Arquivo
                            </Button>
                          </UploadForm>
                        </Row>
                      </>
                    ),
                    onOk() {
                      regerar(record.id, templateUrl);
                     },
                  });
                } catch (error) {
                  notification['error']({
                    message: `Erro ao processar envio`,
                    description: `${error.message}`,
                  });
                }
              }}
              type="dashed"
              className="button is-small is-warning"
              size="small"
            >
              <Icon type="file-sync" />
              Regerar
            </Button>
          )}
        </ActionContainer>
      );
    },
  },
];
