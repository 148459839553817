import { useState } from 'react';
import {
  createSystemAssignment,
  fetchAssignment,
  updateSystemAssignment,
} from 'services/systemAssignments';
import {
  formatterSystemToSend,
  formatterSystemToReceive,
} from 'utils/formatters/systemAssignments';
import Swal from 'sweetalert2';

const optionValue = {
  yes: 'S',
  no: 'N',
};

const useNewSystemAssignments = (
  id,
  postOrPut,
  systemId,
  assignmentId,
  profileId
) => {
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (value, field) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  const toggleLoading = () => setIsLoading((state) => !state);
  const createAssignment = async () => {
    try {
      toggleLoading();
      const updateOrCreate =
        +postOrPut === 1 ? createSystemAssignment : updateSystemAssignment;
      const editOrUpdateLabel = +postOrPut === 1 ? 'criada' : 'editada';
      updateOrCreate({
        body: formatterSystemToSend(
          { ...form },
          +postOrPut === 1 ? true : undefined
        ),
        id: assignmentId,
      }).then(() => {
        Swal.fire({
          text: `Atribuição ${editOrUpdateLabel} com sucesso.`,
        });
        window.location.href = '/admin/perfis';
      });

      toggleLoading();
    } catch (error) {
      Swal.fire({
        text: 'Ocorreu um problema ao criar essa atribuição, tente novamente.',
      });

      toggleLoading();
      return error;
    }
  };

  const fullFilledInputs = () => {
    const keys = Object.keys(form);
    if (keys.length < 7) return false;
    return !keys.some(
      (field) =>
        form[field] === null ||
        form[field] === 0 ||
        form[field] === undefined ||
        form[field] === ''
    );
  };
  const getAssignment = async (id) => {
    const userSession = localStorage.getItem('userDataCRP-Login');
    const parseSession = userSession ? JSON.parse(userSession) : undefined;
    try {
      const { status, data } = await fetchAssignment({
        firstId: assignmentId,
        secondId: systemId,
        thirdId: profileId,
      });
      if (status === 200) {
        setForm(
          formatterSystemToReceive({
            ...data[0],
            sistemaId: systemId,
            perfilId: +profileId,
          })
        );
      }
    } catch (error) {
      setForm(
        formatterSystemToReceive({
          sistemaId: systemId,
          usuarioInclusao: parseSession?.[0]?.id,
          perfilId: +profileId,
        })
      );
      return error;
    }
  };
  return {
    onChange,
    form,
    createAssignment,
    getAssignment,
    fullFilledInputs,
    isLoading,
  };
};

export { useNewSystemAssignments, optionValue };
