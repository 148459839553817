import React from 'react';

export default function Categorias({ colors, changeFiltros }) {
  let classe = '';
  if(colors[0] != undefined) {
    switch (colors[0].id) {
      case 1:
        classe = 'bt-treinamentos'
        break;
      case 2:
        classe = 'bt-webinars'
        break;
      case 3:
        classe = 'bt-lembretes'
        break;
      case 4:
        classe = 'bt-eventos'
        break;
      case 5:
        classe = 'bt-agenda'
        break;
    }
  }
  return (
    <div>
      <h4 className="title is-5 paddingAgendaTitle">
        Categorias <small>(clique para filtrar)</small>
      </h4>
      <div className="agendaCategorias">
        {colors.map((item, index) => (
          <div key={index}>
            <span className={`button ` + (item.id === 1 ? 'bt-treinamentos' : 
            (item.id === 2 ? 'bt-webinars' : (item.id === 3 ? 'bt-lembretes' : 
            (item.id === 4 ? 'bt-eventos' : (item.id === 5 ? 'bt-agenda' : '')))))}>
              <label>
                <input
                  className="calFilter"
                  type="checkbox"
                  value={item.cor}
                  defaultChecked={true}
                  onClick={changeFiltros}
                />
                <span>{item.descricao}</span>
              </label>
            </span>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}
