import Swal from "sweetalert2";
import { deleteFile } from "../service";
import { message } from "antd";
export class FormRepository {
  constructor(props) {
    this.state = props.state;
  }

  handleCancel = () => this.state.upload.setUpload({ previewVisible: false });

  handleRemove = async (e) => {
    const resultRemoveFile = await deleteFile(e?.url)
    if(resultRemoveFile.status === 200){
      const result = this.state.file.fileList.filter(
          (item) => item.uid !== e.uid
        );
        this.state.file.setFileList(result);
    } else {
      Swal.fire(
        'Erro ao Deletar',
        `error`
      );
    }
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.state.upload.setUpload({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };


  handleChange = ({ fileList: newFileList }) =>  {
    this.state.file.setFileList(newFileList);
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
