import React, { useState, useCallback, useEffect } from 'react';
import {
  fetchReports,
  fetchReportsFilter,
  getCategories,
} from '../../../services/reports/service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Button } from 'antd';
import { pipe, removeEmptyOrNullFromJson } from '../../../utils/formatters';
import { ActionContainer } from './styles';
const useReports = () => {
  const [form, setForm] = useState({
    nome: undefined,
    categoria: undefined,
    adm: undefined,
    ativo: undefined,
    usuarioPortalId: undefined,
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const idUser = localStorage.getItem('userDataCRP-Perfil');
  const idUserData = JSON.parse(localStorage.getItem('userDataCRP'))

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 10,
    },
    {
      title: 'Relatório',
      dataIndex: 'nomeRelatorio',
      key: 'nomeRelatorio',
      width: 100,
    },
    {
      title: 'Data Publicação',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (lastUpdated) =>
        lastUpdated
          ? moment(lastUpdated, 'DD-MM-YYYY').format('DD/MM/YYYY')
          : '',
      width: 50,
    },
    {
      title: 'Categoria',
      dataIndex: 'categoriaRelatorio',
      key: 'categoriaRelatorio',
      render: (categoriaRelatorio) => categoriaRelatorio?.categoria,
      width: 10,
    },
    {
      title: 'Status',
      dataIndex: 'ativo',
      key: 'ativo',
      width: 10,
      render: (ativo) => {
        return (ativo == 'S' ? 'SIM' : 'NÃO')
      }
    },
    {
      title: 'Ação',
      width: 10,
      render: (row) => {
        return (
          <ActionContainer key={`action_${row.id}`}>
            <Button
              href={`/admin/incluir-relatorio/${row.id}`}
              type="dashed"
              className="button is-small is-warning"
              size="small"
            >
              {idUser == 999 ? 'Editar' : 'Visualizar'}
            </Button>
          </ActionContainer>
        );
      },
    },
  ];

  const getOptions = async () => {
    try {
      const { data } = await getCategories();
      setCategoryOptions(
        data.map((item) => ({ value: item.id, label: item.categoria }))
      );
    } catch (error) {
      return error;
    }
  };

  const onChange = useCallback(
    (value, field) => {
      setForm((state) => ({ ...state, [field]: value }));
    },
    [form]
  );

  const cleanFields = useCallback(
    () =>
      setForm({
        relatorio: undefined,
        categoria: undefined,
        admin: undefined,
        status: undefined,
      }),
    [form]
  );

  const getReports = useCallback(
    async (withParams) => {
        setLoading(true);
        if(withParams == undefined) {
          const {data} = await fetchReports()
          setDataSource(data.sort((a, b) => a.id < b.id));
        } else {
          const { data } = await fetchReportsFilter({
            ...pipe(
              { ...form, categoria: undefined, id: form.categoria },
              removeEmptyOrNullFromJson
            )})

            setDataSource(data.sort((a, b) => a.id < b.id));
        }
        setLoading(false);
      },
    [form]
  );

  useEffect(() => {
    getOptions();
    getReports();
  }, []);
  return {
    columns,
    dataSource,
    loading,
    idUser,
    categoryOptions,
    form,
    onChange,
    cleanFields,
    getReports
  };
};
export { useReports };
