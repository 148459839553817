import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ActionContainer } from './styles';
import { FontAwesomeIcon } from '../../../../../services/Gerais';
import moment from 'moment';
import Swal from 'sweetalert2';
import { fetchCategory } from 'services/category';

const useListFaq = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [form, setForm] = useState({
    categoria: '',
    ativo: '',
  });
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      key: 'categoria',
      width: 100,
    },
    {
      title: 'Ultima Alteração',
      render: (row) =>
        moment(
          !row.dataAlteracao ? row.dataInclusao : row.dataAlteracao,
          'DD/MM/YYYY'
        ).format('DD/MM/YYYY'),
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'ativo',
      key: 'id',
      width: 100,
      render: (cell) => (cell === 'S' ? 'ATIVO' : 'INATIVO'),
    },
    {
      title: 'Ações',
      width: 100,
      render: (row) => {
        return (
          <ActionContainer key={`action_${row.id}`}>
            <Button
              type="dashed"
              href={`/admin/categoria/${row.id}`}
              className="button is-small is-warning"
              size="small"
            >
              <FontAwesomeIcon icon={['fas', 'user-cog']} /> &nbsp; Editar
            </Button>
          </ActionContainer>
        );
      },
    },
  ];

  const orderByCategory = (a, b) => {
    if (a.categoria > b.categoria) return 1;
    if (a.categoria < b.categoria) return -1;
    return 0;
  }

  const getData = async () => {
    try {
      const { status, data } = await fetchCategory();
      if (status === 200) {
        const orderData = data.sort(orderByCategory);
        setCategoryOptions(
          orderData.map((item) => ({
            value: item.id,
            label: item.categoria,
          }))
        );
        setData(orderData);
        setFilteredData(orderData);
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocorreu um problema ao buscar os dados, tente novamente',
      });
    }
  };

  const onChange = (value, field) =>
    setForm((state) => ({ ...state, [field]: value }));

  const filterData = () => {
    const result = [...data];
    if (form.categoria || form.ativo) {
      let resultFilter = [];
      const parseStr = (str) => str?.toLowerCase().trim();
      const filterCategory = result.filter(
        (item) => item.id === +form.categoria
      );
      const filterActive = result.filter((item) =>
        parseStr(item.ativo).includes(parseStr(form.ativo))
      );
      const filterAll = result.filter(
        (item) =>
          item.id === +form.categoria &&
          parseStr(item.ativo).includes(parseStr(form.ativo))
      );
      if (form.categoria && form.ativo) {
        resultFilter = filterAll;
      } else if (form.categoria) {
        resultFilter = filterCategory;
      } else {
        resultFilter = filterActive;
      }
      setFilteredData(resultFilter);
      return;
    }
    setFilteredData(result);
  };

  useEffect(() => {
    getData();
  }, []);

  const cleanFields = () =>
    setForm({
      categoria: '',
      ativo: '',
    });

  return {
    categoryOptions,
    columns,
    data,
    filterData,
    form,
    onChange,
    filteredData,
    cleanFields,
  };
};

export { useListFaq };
