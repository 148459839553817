import React, { Component } from 'reactn';
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Button, message } from 'antd';

import avatar from '../../../img/loadingtwo.gif';
import {
  Error,
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
  FontAwesomeIcon,
} from '../../../services/Gerais';

import 'antd/dist/antd.css';

import Lista from './components/lista';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comunicadosMapa: [],
      isLoadingAgenda: true,
      errorMOIdx: [],
      menu: this.menuRender(),
      moment: '2019',
    };
  }
  componentWillMount() {
    this.setState({ isLoadingAgenda: false });
  }

  handleMenuClick = e => {
    message.info(`Cadastar ${e.item.props.desc}`);
    this.props.history.push(`./mapaoperacoes/cadastro/${e.key}`);
  };

  handleChange = val => {};

  menuRender = () => {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="macro-atividade" desc="Macro Atividade">
          <FontAwesomeIcon icon={['fas', 'calendar']} /> &nbsp; Macro Atividade
        </Menu.Item>
        {/*<Menu.Item key="atividade" desc="Atividade">
          <FontAwesomeIcon icon={["fas", "calendar-week"]} /> &nbsp; Atividade
        </Menu.Item>
        <Menu.Item key="micro-atividade" desc="Micro Atividade">
          <FontAwesomeIcon icon={["fas", "calendar-day"]} /> &nbsp; Micro
          Atividade
    </Menu.Item>*/}
        <Menu.Item key="evento" desc="Evento VG">
          <FontAwesomeIcon icon={['fas', 'calendar-check']} /> &nbsp; Evento VG
        </Menu.Item>
      </Menu>
    );

    return menu;
  };

  onChange = value => {};

  onBlur = () => {};

  onFocus = () => {};

  onSearch = val => {};

  render() {
    const { isLoadingAgenda, errorMOIdx } = this.state;

    if (isLoadingAgenda) {
      return (
        <div className="CRP-loading loadingAgenda">
          <img src={avatar} width="100" alt="Carregando..." />
        </div>
      );
    }

    return [
      <Helmet
        key="TitleAdminMapa"
        title="Administração - Mapa de Operações | Portal Prisma - Kroton"
        meta={[
          { name: 'description', content: 'Mapa de Operações - Portal Prisma' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'keywords',
            content: 'Mapa de Operações, kroton, educacional, portal',
          },
        ]}
      />,
      <HeaderAdmin key="HeaderAdminMapa" />,
      <section
        key="SectionAdminMapa"
        className="columns is-multiline conteudoAdmin conteudoAdminAgenda"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminMapa" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          {errorMOIdx.response ? <Error {...errorMOIdx} /> : null}
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminAgenda"
                pageName="Administração - Mapa de Operações"
                titulo="Administração - Mapa de Operações"
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div id="components-dropdown-demo-dropdown-button">
                  <Dropdown overlay={this.state.menu}>
                    <Button type="primary" size="large">
                      <FontAwesomeIcon icon={['far', 'calendar-alt']} /> &nbsp;
                      Novo Cadastro
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Lista />
            </div>
          </div>
        </div>
      </section>,
      <FooterAdmin key="FooterAdminMapa" />,
    ];
  }
}
export default withRouter(Index);
