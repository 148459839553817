import React, { useState, useEffect } from 'react';
import { Container, Line, Column } from './styles';
import {
  HeaderAdmin,
  Helmet,
  Hero,
  MenuAdmin,
  FooterAdmin,
} from 'services/Gerais';
import { Button, Form, Input, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { getUserProfileByEmail, getUserProfileByMail } from 'services/MsGraph';

import { getTokenAppRegistration } from 'services/graphAppRegistration';
import { patchUserPersonification } from 'services/Personification'

const LoginAsAnotherUserPage = () => {

  const getUserData =  async (user, informedMail) => {
    const propertyEmail = "mail";
    const propertyUserPrincipalName = "userPrincipalName";
    let userDataToLoad = {};

    if(informedMail === user[propertyUserPrincipalName]){
      
      userDataToLoad = {
        mail: user[propertyUserPrincipalName],
        timerToLogout: formData.timeOfUse,
        isPersonification: 'S'
      }
    } else {
       userDataToLoad = {
            mail: user[propertyEmail],
            timerToLogout: formData.timeOfUse,
            isPersonification: 'S'
          }
      }      
    
   await patchUserPersonification(userDataToLoad).then(
      Swal.fire({
        title: 'Tudo pronto!',
        text: `Você está usando o Prisma como ${formData.email}. O acesso estará ativo pelos próximos ${formData.timeOfUse} minutos.`,
        icon: 'success',
        confirmButtonText: 'OK',
        timer: 3000,
        timerProgressBar: true,
      })
    );
 }

 const testIfUserExists = async (useremail) => {
   try {
    let userData = null;

    const response = await getUserProfileByEmail(useremail);
    if (response.data.value.length === 1) {
      userData = response.data.value[0];
          } else if (response.data.value.length === 0) {
      const resp = await getUserProfileByMail(useremail);
      if (resp.data.value.length === 1) {
        userData = resp.data.value[0];
              } else {
        Swal.fire({
          title: 'E-mail não encontrado',
          text: `O e-mail ${useremail} não foi localizado.`,
          icon: 'warning',
          confirmButtonText: 'OK',
          timerProgressBar: true,
        })
      }
    }
    if(userData != null) {
      getUserData(userData, useremail);
    }
   } catch (error) {
     Swal.fire({
       title: 'Atenção',
       text: error?.response?.data?.message || error.message,
     });
   }
 };

  useEffect(() => {
    getTokenAppRegistration();
  }, [])

  const [formData, setFormData] = useState({
    email: '',
    timeOfUse: 15,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      await testIfUserExists(formData.email);
    }
    catch (error) {
      Swal.fire({
        title: 'Erro ao logar',
        text: 'Não foi possível acessar o usuário selecionado. Por favor, tente novamente.',
        icon: 'error',
        confirmButtonText: 'Voltar'
      });
    }
  };

  return (
    <Container>

      <Helmet
        key="TitleAdminLoginAsAnotherUser"
        title="Acesso ao Prisma como outro usuário - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Acesso como outro usuário',
          },
          {
            name: 'keywords',
            content: 'Acesso como outro usuário',
          },
        ]}
      />
      <HeaderAdmin key="HeaderLoginAsAnotherUser" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminLoginAsAnotherUser" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              {Hero({
                key: 'heroAdminLoginAsAnotherUser',
                pageName: 'Acesso ao Prisma como outro usuário',
                titulo: 'Acesso ao Prisma como outro usuário',
              })}
            </div>
          </div>
          <Column>
              <p>Para utilizar o Prisma como outro usuário, insira abaixo o e-mail do usuário desejado e o tempo, em minutos, que usará o sistema como essa pessoa.</p>
          <Form
              name="LoginAsAnotherUserPageForm"
              layout="inline"
              initialValues={{
                useSystemAs: {
                  email: null,
                  timeOfUse: '15',
                },
              }}
              onSubmit={handleSubmit}
            >

              <Line>
                <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="E-mail do usuário"
                    prefix={<UserOutlined />}
                  />

                <Select
                  name="timeOfUse"
                  value={formData.timeOfUse}
                  onChange={(value) =>
                    setFormData({ ...formData, timeOfUse: value })
                  }
                  style={{ width: 120 }}
                >
                  <Select.Option key="fifteenMinutes" value={15}>
                    15 min
                  </Select.Option>
                  <Select.Option key="tenMinutes" value={10}>
                    10 min
                  </Select.Option>
                  <Select.Option key="fiveMinutes" value={5}>
                    5 min
                  </Select.Option>
                </Select>

                  <Button type="primary" htmlType="submit">
                  Fazer login
                  </Button>
                  </Line>
          </Form>

          </Column>
        </div>
      </section>
      <FooterAdmin key="FooterAdminLoginAsAnotherUser" />
    </Container>
  );
};

export default LoginAsAnotherUserPage;
