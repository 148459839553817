import React, { useState, useCallback, useEffect } from 'react';
import { fetchReportFilters } from '../../../../../services/reports/service';
import Swal from 'sweetalert2';
import { Button } from 'antd';
import { ActionContainer } from './styles';

const useFilters = (idRelatorio) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Nome',
      dataIndex: 'nomeExibicao',
      key: 'nomeExibicao',
      width: 100,
    },
    {
      title: 'Filtro',
      dataIndex: 'filtro',
      width: 100,
    },
    {
      title: 'Condição (Where)',
      dataIndex: 'condicaoWhere',
      key: 'condicaoWhere',
      width: 100,
    },
    {
      title: 'Query (SQL)',
      dataIndex: 'query',
      key: 'query',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: 'Ação',
      width: 100,
      render: (row) => {
        return (
          <ActionContainer key={`action_${row.id}`}>
            <Button
              href={`/admin/incluir-filtro/${idRelatorio}/${row.id}`}
              type="dashed"
              className="button is-small is-warning"
              size="small"
            >
              Editar
            </Button>
          </ActionContainer>
        );
      },
    },
  ];

  const getFilters = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchReportFilters({
        id: idRelatorio,
      });
      if (data) {
        setDataSource(data.sort((a, b) => (a.id < b.id ? 1 : -1)));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        title: error.response?.data?.error,
        text: error.response?.data?.message,
      });
    }
  }, []);

  useEffect(() => {
    getFilters();
  }, []);
  return { loading, dataSource, columns };
};

export { useFilters };
