import React, { useEffect, useState } from 'react';
import { Header, Footer, Hero, Helmet, moment } from 'services/Gerais';
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  Spin,
  DatePicker,
  Icon,
  AutoComplete,
  Table
} from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import './styles/style.scss';
import Swal from 'sweetalert2';
import {
  exportXLSXRelatorio,
  onGetFiltersReport,
  onPdf,
} from './FocaController';
import { getDadaReport, getExportXLSX, getFilter, getFilterReport } from 'services/foca/FocaService';

const { RangePicker } = DatePicker;
const { Option } = Select;

const listFiltrosEscolhidos = [];

const RelatorioFoca = () => {
  const [titlePage, setTitlePage] = useState('FOCA - Extração de Relatório');
  const [lstParametros, setLstParametros] = useState([]);
  const [relatorioConfig, setRelatorioConfig] = useState([]);
  const [diretoresNegocios, setDiretoresNegocios] = useState([]);
  const [gerentesNegocios, setGerentesNegocios] = useState([]);
  const [polos, setPolos] = useState([]);
  const [isSpinning , setIsSpinning] = useState(false)
  const [gruposEconomicos, setgruposEconomicos] = useState([]);
  const [relatorioId, setRelatorioId] = useState(59);
  const [pagination, setPagination] = useState({ position: 'bottom' });
  const [isLoading, setIsLoading] = useState(true);
  const [ltsCheckins, setLtsCheckins] = useState([]);
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({
    prazoInicio: null,
    prazoFim: null,
    dataInicio: null,
    dataFim: null,
    foca: null,
    modalidade: null,
    tipoVisita: null,
    statusCheckin: null,
    poloId: null,
    grupoEconomico: null,
    gerenteNegocio: null,
    diretorNegocio: null,
  });
  const perfilId = localStorage.getItem('userDataCRP-Perfil')
  const [columns, setColumns] = useState([
    {
      title: 'ID Check-in',
      key: 'id',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Polo(s)',
      key: 'checkinPolos',
      dataIndex: 'checkinPolos',
      width: 150,
      render: (polos) => {
        let listPolos = [];
        listPolos.push(polos)
        let renderPolos = [];
        let polosOrder = listPolos.sort((a, b) => a.polo.nome > b.polo.nome ? 1 : -1);
        polosOrder.forEach(polo => {
          renderPolos.push(
            <small key={'polo-' + polo[0].id}>{polo[0]?.polo?.nome}</small>,
            <br key={'quebra-' + polo[0].id} />
          )
        });
        return renderPolos;
      },
    },
    {
      title: 'Data visita',
      key: 'dataVisita',
      dataIndex: 'dataVisita',
      width: 150,
      sorter: (a, b) => orderByVisitDate(a, b),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (status) => {
        if (status !== null) {
          switch (status) {
            case 'A':
              return <span>Agendado</span>;
              break;
            case 'C':
              return <span>Concluído</span>;
              break;
            case 'E':
              return <span>Em andamento</span>;
              break;
          }
        }
      },
    },
    {
      title: 'Ação',
      key: 'id',
      dataIndex: 'id',
      width: 150,
      render: (id, record) =>
        perfilId == 376 ? (
          record.liberarPdf == 'S' ? (
            <>
            <Button
              title="Editar "
              className="button is-small is-warning"
              href={`/cadastro/FOCA/${id}`}
            >
              <Icon type="save" />
              Visualizar
            </Button>
            <Button
              title="PDF "
              className="button is-small is-warning margin-1-left"
              target="_blank"
              href={`/FocaPdf/${id}`}
            >
              <Icon type="file-pdf" />
              PDF
            </Button>
            </>
          ) : (
            <Button
              title="Editar "
              className="button is-small is-warning"
              href={`/cadastro/FOCA/${id}`}
            >
              <Icon type="save" />
              Visualizar
            </Button>
          )
        ) : (
          <>
            <Button
              title="Editar "
              className="button is-small is-warning"
              href={`/cadastro/FOCA/${id}`}
            >
              <Icon type="save" />
              Visualizar
            </Button>
            <Button
              title="PDF "
              className="button is-small is-warning margin-1-left"
              target="_blank"
              href={`/FocaPdf/${id}`}
            >
              <Icon type="file-pdf" />
              PDF
            </Button>
          </>
        ),
    },
  ]);

  useEffect(() => {
    getFiltrosRelatorio();
    getData();
    getCheckins();
  }, []);

  useEffect(() => {
    initFilters();
  }, [lstParametros]);

  async function getFiltrosRelatorio() {
    const resp = await onGetFiltersReport(59);
    const lstParametros = iniciaParametros(
      resp.data['relatorioAdministrativoFiltros']
    );
    await setLstParametros(lstParametros);
  }

  function handleTableChange(pagination) {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    setPage(pagination.current - 1)

  }

  useEffect(() => {
    getCheckins();
  }, [page])

  async function getCheckins() {
    setIsLoading(true);
    let search = [
      `page=${page}`,
      `&size=10`,
      `&isFocaScreen=false`,
      `&isFirstOpen=${
        filters.prazoInicio == null &&
        filters.prazoFim == null &&
        filters.dataInicio == null &&
        filters.dataFim == null &&
        filters.foca == null &&
        filters.modalidade == null &&
        filters.tipoVisita == null &&
        filters.statusCheckin == null &&
        filters.poloId == null &&
        filters.grupoEconomico == null &&
        filters.gerenteNegocio == null &&
        filters.diretorNegociov  == null
          ? true
          : false
      }`,
      filters.prazoInicio != null ? `&prazoInicial=${filters.prazoInicio}` : '',
      filters.prazoFim != null ? `&prazoFim=${filters.prazoFim}` : '',
      filters.dataInicio != null
        ? `&dataVisitaInicial=${filters.dataInicio}`
        : '',
      filters.dataFim != null ? `&dataVisitaFinal=${filters.dataFim}` : '',
      filters.foca != null ? `&foca=${filters.foca}` : '',
      filters.modalidade != null ? `&modalidade=${filters.modalidade}` : '',
      filters.tipoVisita != null ? `&tipoVisita=${filters.tipoVisita}` : '',
      filters.statusCheckin != null ? `&status=${filters.statusCheckin}` : '',
      filters.poloId != null ? `&poloId=${filters.poloId}` : '',
      filters.grupoEconomico != null
        ? `&grupoEconomico=${filters.grupoEconomico}`
        : '',
      filters.gerenteNegocio != null
        ? `&gerenteNegocio=${filters.gerenteNegocio}`
        : '',
      filters.diretorNegocio != null
        ? `&diretorNegocio=${filters.diretorNegocio}`
        : '',
    ].join('');
    const resp = await getFilterReport(search);
    setLtsCheckins(resp.data.content);
    let paginationObj = {
      total: resp.data.totalElements,
      current: resp.data.number + 1,
    };
    setPagination(paginationObj);
    setIsLoading(false);
  }

  function orderByVisitDate(a, b) {
    const dateA = a.dataVisita.split('-');
    const dateB = b.dataVisita.split('-');
    if (dateA[2] > dateB[2]) {
      return 1;
    } else if (dateA[2] == dateB[2] && dateA[1] > dateB[1]) {
      return 1;
    } else if (
      dateA[2] == dateB[2] &&
      dateA[1] == dateB[1] &&
      dateA[0] > dateB[0]
    ) {
      return 1;
    } else {
      return -1;
    }
  }

  function orderByPolo(a, b) {
    if (a.polo.nome > b.polo.nome) return 1;
    if (a.polo.nome < b.polo.nome) return -1;
    return 0;
  }

  async function initFilters() {
    if (lstParametros[0] == undefined) return;
    await getReport(
      lstParametros[0].id,
      lstParametros[0].nome,
      lstParametros[0].valor ? lstParametros[0].valor : ''
    );
    await getReport(
      lstParametros[1].id,
      lstParametros[1].nome,
      lstParametros[1].valor ? lstParametros[1].valor : ''
    );
    await getReport(
      lstParametros[2].id,
      lstParametros[2].nome,
      lstParametros[2].valor ? lstParametros[2].valor : ''
    );
    await getReport(
      lstParametros[3].id,
      lstParametros[3].nome,
      lstParametros[3].valor ? lstParametros[3].valor : ''
    );
    await getReport(
      lstParametros[4].id,
      lstParametros[4].nome,
      lstParametros[4].valor ? lstParametros[4].valor : ''
    );
    await getReport(
      lstParametros[5].id,
      lstParametros[5].nome,
      lstParametros[5].valor ? lstParametros[5].valor : ''
    );
    await getReport(
      lstParametros[6].id,
      lstParametros[6].nome,
      lstParametros[6].valor ? lstParametros[6].valor : ''
    );
    await getReport(
      lstParametros[7].id,
      lstParametros[7].nome,
      lstParametros[7].valor ? lstParametros[7].valor : ''
    );
    await getReport(
      lstParametros[8].id,
      lstParametros[8].nome,
      lstParametros[8].valor ? lstParametros[8].valor : ''
    );
    await getReport(
      lstParametros[9].id,
      lstParametros[9].nome,
      lstParametros[9].valor ? lstParametros[9].valor : ''
    );
    await getReport(
      lstParametros[10].id,
      lstParametros[10].nome,
      lstParametros[10].valor ? lstParametros[10].valor : ''
    );
    await getReport(
      lstParametros[11].id,
      lstParametros[11].nome,
      lstParametros[11].valor ? lstParametros[11].valor : ''
    );
  }

  function iniciaParametros(parametrosIniciais) {
    const lstParametros = [];
    parametrosIniciais.map((item, index) => {
      const filtroIiniciado = {
        id: item.id,
        nome: item.nomeExibicao,
        valor: '',
      };
      lstParametros.push(filtroIiniciado);
    });
    return lstParametros;
  }

  function getReport(id, nome, valor) {
    if (valor === null) {
      valor = '';
    }
    const filtroEscolhido = {
      id: id,
      nome: nome,
      valor: id == 20 ? `%${valor}%` : valor,
    };
    const filtrosEscolhidos = listFiltrosEscolhidos;
    if (filtrosEscolhidos) {
      const filtrosIdsAdicionados = filtrosEscolhidos.filter((objeto) => {
        return objeto.id === id;
      });
      const filtroIndex = filtrosEscolhidos.findIndex(
        (objeto) => objeto.id === id
      );
      if (filtrosIdsAdicionados.length) {
        listFiltrosEscolhidos[filtroIndex] = filtroEscolhido;
      } else {
        listFiltrosEscolhidos.push(filtroEscolhido);
      }
      const relatorioConfigAtualizada = {
        relatorioId: relatorioId,
        lstParametros: filtrosEscolhidos,
      };
      setRelatorioConfig(relatorioConfigAtualizada);
    }
  }

  function changeTerm(date) {
    if (date[0] != undefined) {
      setFilters((state) => ({
        ...state,
        prazoInicio: moment(date[0], 'DD-MM-YYYY').format('DD-MM-YYYY'),
      }));
      setFilters((state) => ({
        ...state,
        prazoFim: moment(date[1], 'DD-MM-YYYY').format('DD-MM-YYYY'),
      }));
    } else {
      setFilters((state) => ({ ...state, prazoInicio: '' }));
      setFilters((state) => ({ ...state, prazoFim: '' }));
    }

    getReport(
      lstParametros[9].id,
      lstParametros[9].nome,
      moment(date[0], 'DD-MM-YYYY').format('DD-MM-YYYY')
    );
    getReport(
      lstParametros[10].id,
      lstParametros[10].nome,
      moment(date[1], 'DD-MM-YYYY').format('DD-MM-YYYY')
    );
  }

  function changeDateVisit(date) {
    if (date[0] != undefined) {
      setFilters((state) => ({
        ...state,
        dataInicio: moment(date[0], 'DD-MM-YYYY').format('DD-MM-YYYY'),
      }));
      setFilters((state) => ({
        ...state,
        dataFim: moment(date[1], 'DD-MM-YYYY').format('DD-MM-YYYY'),
      }));
    } else {
      setFilters((state) => ({ ...state, dataInicio: '' }));
      setFilters((state) => ({ ...state, dataFim: '' }));
    }

    getReport(
      lstParametros[0].id,
      lstParametros[0].nome,
      moment(date[0], 'DD-MM-YYYY').format('DD-MM-YYYY')
    );
    getReport(
      lstParametros[1].id,
      lstParametros[1].nome,
      moment(date[1], 'DD-MM-YYYY').format('DD-MM-YYYY')
    );
  }

  function changeModality(modality) {
    setFilters((state) => ({ ...state, modalidade: modality }));
    getReport(lstParametros[2].id, lstParametros[2].nome, modality);
  }

  function changeFOCA(foca) {
    setFilters((state) => ({ ...state, foca: foca }));
    getReport(lstParametros[3].id, lstParametros[3].nome, foca);
  }

  function changeTypeVisit(typeVisit) {
    setFilters((state) => ({ ...state, tipoVisita: typeVisit }));
    getReport(lstParametros[4].id, lstParametros[4].nome, typeVisit);
  }

  function changeCheckin(checkin) {
    setFilters((state) => ({ ...state, statusCheckin: checkin }));
    getReport(lstParametros[5].id, lstParametros[5].nome, checkin);
  }

  function changePolo(polo) {
    setFilters((state) => ({ ...state, poloId: polo }));
    getReport(lstParametros[11].id, lstParametros[11].nome, polo);
  }

  function changeEconomicGroup(group) {
    setFilters((state) => ({ ...state, grupoEconomico: group }));
    getReport(lstParametros[6].id, lstParametros[6].nome, group);
  }

  function changeGN(gn) {
    setFilters((state) => ({ ...state, gerenteNegocio: gn }));
    getReport(lstParametros[7].id, lstParametros[7].nome, gn);
  }

  function changeDN(dn) {
    setFilters((state) => ({ ...state, diretorNegocio: dn }));
    getReport(lstParametros[8].id, lstParametros[8].nome, dn);
  }

  async function exportPDF() {
    setIsSpinning(true)
    await onPdf(relatorioConfig);
    await setIsSpinning(false)
  }

  async function handleExportXLSX() {
    setIsSpinning(true)
    const data = await getExportXLSX(relatorioConfig);
    if (data.status == 200) {
      if (data.data.relatorioFocaCompleto.length === 0) {
        Swal.fire({
          position: 'top-end',
          title: `Sem dados!`,
          text: `Não foi encontrado nenhum dado na pesquisa`,
          icon: 'warning',
          showConfirmButton: false,
          timer: 10000,
        });
      } else {
        await exportXLSXRelatorio(data.data.relatorioFocaCompleto);
      }
    }
    await setIsSpinning(false)
  }

  function renderOption(item) {
    return (
      <Option key={item.id} text={item.nome}>
        {item['nome'] ? item.nome : item.nome}
      </Option>
    );
  }

  async function getData() {
    const data = await getDadaReport();
    setDiretoresNegocios(data.data.diretoresNegocios.sort());
    setGerentesNegocios(data.data.gerentesNegocios.sort());
    setPolos(data.data.polos.sort());
    setgruposEconomicos(data.data.gruposEconomicos.sort());
  }

  function buscaPolo(search) {
    if (search && search.length > 1) {
      const searchArray =
        polos.length > 0
          ? polos.filter((item) => item.nome.includes(search.toUpperCase()))
          : [];
      return searchArray;
    }
  }

  function clear() {
    window.location.reload(false);
  }

  return (
    <>
      <Helmet
        title={titlePage}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: titlePage + '- Portal Prisma',
          },
          { name: 'keywords', content: 'kroton, educacional, portal, teste' },
        ]}
        key="titleRelatorioFoca"
      />
      <Header key="headerRelatorioFoca" />
      <Hero key="heroRelatorioFoca" pageName={titlePage} titulo={titlePage} />
      <Form className={'container-foca'} key="RelatorioFoca">
        <Spin tip="Baixando relatório, favor aguarde..." spinning={isSpinning}>
          {' '}
          <Row md={24} className={'space-row'}>
            <Col md={4} sm={24}>
              <p>Data da Visita</p>
              <RangePicker
                id="dataVisita"
                locale={locale}
                format="DD-MM-YYYY"
                allowClear={false}
                onChange={(e) => changeDateVisit(e)}
              />
            </Col>
            <Col md={4} sm={24}>
              <p>Prazo</p>
              <RangePicker
                locale={locale}
                format="DD-MM-YYYY"
                allowClear={false}
                onChange={(e) => changeTerm(e)}
              />
            </Col>
            <Col md={3} sm={24} className={'margin-1rem-left'}>
              <p>Modalidade</p>
              <Select
                placeholder="Selecione a Modalidade"
                onChange={(e) => changeModality(e)}
              >
                <Option value="Graduação">Graduação</Option>
                <Option value="Pós Graduação">Pós Graduação</Option>
                <Option value="EJA">EJA</Option>
                <Option value="Cursos Técnicos">Cursos Técnicos</Option>
              </Select>
            </Col>
            <Col md={3} sm={24} className={'margin-1rem-left'}>
              <p>FOCA</p>
              <Select
                onChange={(e) => changeFOCA(e)}
                placeholder="Selecione o FOCA"
              >
                <Option key="F" value="F">
                  Financeiro
                </Option>
                <Option key="O" value="O">
                  Operacional
                </Option>
                <Option key="C" value="C">
                  Comercial
                </Option>
                <Option key="A" value="A">
                  Acadêmico
                </Option>
              </Select>
            </Col>
            <Col md={3} sm={24} className={'margin-1rem-left'}>
              <p>Tipo Visita</p>
              <Select
                onChange={(e) => changeTypeVisit(e)}
                placeholder="Selecione o Tipo da visita"
              >
                <Option value="P">Presencial</Option>
                <Option value="O">Online</Option>
              </Select>
            </Col>
            <Col md={3} sm={24} className={'margin-1rem-left'}>
              <p>Status Checkin</p>
              <Select
                onChange={(e) => changeCheckin(e)}
                placeholder="Selecione o Checkin"
              >
                <Option value="A">Agendado</Option>
                <Option value="E">Em acompanhamento</Option>
                <Option value="C">Concluido</Option>
              </Select>
            </Col>
          </Row>
          <Row md={24} className={'space-row'}>
            <Col md={11}>
              <p>Polo</p>
              <AutoComplete
                dataSource={polos.map(renderOption)}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={(e) => changePolo(e)}
                placeholder="Digite ou selecione"
              />
            </Col>
            <Col md={11} className={'margin-1rem-left'}>
              <p>Grupo econômico</p>
              <AutoComplete
                dataSource={Object.keys(gruposEconomicos).map((key) => {
                  return (
                    <Option key={gruposEconomicos[key]}>
                      {gruposEconomicos[key]}
                    </Option>
                  );
                })}
                filterOption={(inputValue, option) =>
                  option.props.children != null
                    ? option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : ''
                }
                onChange={(e) => changeEconomicGroup(e)}
                placeholder="Digite ou selecione"
              />
            </Col>
          </Row>
          <Row md={24} className={'space-row'}>
            <Col md={11}>
              <p>Gerente de negócios</p>
              <AutoComplete
                dataSource={Object.keys(gerentesNegocios).map((key) => {
                  return (
                    <Option key={gerentesNegocios[key]}>
                      {gerentesNegocios[key]}
                    </Option>
                  );
                })}
                filterOption={(inputValue, option) =>
                  option.props.children != null
                    ? option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : ''
                }
                onChange={(e) => changeGN(e)}
                placeholder="Digite ou selecione"
              />
            </Col>
            <Col md={11} className={'margin-1rem-left'}>
              <p>Diretor de negócios</p>
              <AutoComplete
                dataSource={Object.keys(diretoresNegocios).map((key) => {
                  return (
                    <Option key={diretoresNegocios[key]}>
                      {diretoresNegocios[key]}
                    </Option>
                  );
                })}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={(e) => changeDN(e)}
                placeholder="Digite ou selecione"
              />
            </Col>
          </Row>
          <Row md={24} className={'space-row'}>
            {perfilId == 999 || perfilId == 376 ? (
              <Button
                type="primary"
                className="pull-right margin-05-right"
                size="large"
                href="/migracaoGn"
              >
                Migrar Checkin(s)
              </Button>
            ) : (
              ''
            )}
            <Button
              type="primary"
              className="pull-right margin-05-right is-warning"
              size="large"
              style={{
                border: 'none',
              }}
              onClick={() => handleExportXLSX()}
            >
              <Icon type="download" />
              Exportar .XLSX
            </Button>
            <Button
              type="primary"
              className="pull-right margin-05-right is-warning"
              size="large"
              style={{
                border: 'none',
              }}
              onClick={() => exportPDF(59)}
            >
              <Icon type="download" />
              Exportar .csv
            </Button>
            <Button
              type="primary"
              className="pull-right margin-05-right is-warning"
              size="large"
              style={{
                border: 'none',
              }}
              onClick={clear}
            >
              <Icon type="delete" />
              Limpar pesquisa
            </Button>
            <Button
              type="primary"
              className="pull-right margin-05-right is-warning"
              size="large"
              style={{
                'background-color': '#52c41a',
                border: 'none',
              }}
              onClick={getCheckins}
            >
              <Icon type="search" />
              Pesquisar
            </Button>
          </Row>
          <Row span={24}>
            <Table
              pagination={pagination}
              loading={isLoading}
              columns={columns}
              dataSource={ltsCheckins.sort((a, b) =>
                a.id < b.id ? 1 : a.id > b.id ? -1 : 0
              )}
              onChange={handleTableChange}
              size="small"
              className="margin-1rem-top"
            />
          </Row>
        </Spin>
      </Form>
      <Footer key="FooterRelatorioFoca" />
    </>
  );
};

export default RelatorioFoca;
