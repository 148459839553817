import { Form, Input, Select, Button, Table, Col, Row } from 'antd';
import React from 'react';
import { Line, RegisterBox } from './styles';
import { useListFaq } from './hook';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../../../services/Gerais';

const ListFaq = () => {
  const {
    columns,
    filteredData,
    filterData,
    form,
    onChange,
    categoryOptions,
    cleanFields,
  } = useListFaq();
  return (
    <>
      <Helmet
        key="TitleAdminFaqList"
        title="FAQ Listagem - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Faq listagem' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'faq-list',
          },
        ]}
      />
      <HeaderAdmin key="HeaderFaqList" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminFaqList" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroFaq"
                pageName="FAQ - Listagem"
                titulo="FAQ / Categorias - Listagem"
              />
            </div>
          </div>
          <RegisterBox>
            <Button href="/admin/list/faq" type="primary">
              FAQ
            </Button>
            <Button href="/admin/categoria" type="primary">
              Cadastrar Categoria
            </Button>

            <Button href="/admin/faq" type="primary">
              Cadastrar FAQ
            </Button>
          </RegisterBox>

          <Form style={{ width: '100%' }}>
            <Line>
              <Form.Item style={{ flex: '0.3' }} label="Categoria">
                <Select
                  value={form.categoria}
                  onChange={(event) => onChange(event, 'categoria')}
                >
                  {categoryOptions.map((item) => (
                    <Select.Option key={String(item.value)}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item style={{ flex: '0.3' }} label="Ativo">
                <Select
                  value={form.ativo}
                  onChange={(event) => onChange(event, 'ativo')}
                >
                  <Select.Option value={null}>Selecione</Select.Option>
                  <Select.Option key="S">ATIVO</Select.Option>
                  <Select.Option key="N">INATIVO</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: '-14px' }}>
                <Button
                  type="primary"
                  style={{
                    'background-color': '#52c41a',
                    border: 'none',
                    marginRight: '8px',
                  }}
                  onClick={() => filterData()}
                >
                  Pesquisar
                </Button>
                <Button type="primary" onClick={cleanFields}>
                  Limpar
                </Button>
              </Form.Item>
            </Line>
          </Form>

          <Table
            loading={false}
            columns={columns}
            dataSource={filteredData}
            size="small"
          />
        </div>
      </section>
      <FooterAdmin key="FooterAdminAtribuiçõesCadastro" />
    </>
  );
};

export default ListFaq;
