import React, { useEffect, useState } from 'react';

import { Container, Title, News, Arrows } from './styles';
import { ComunicadoDisplay, FontAwesomeIcon } from '../../services/Gerais';

function LastNews(props) {
  const [count, setCount] = useState(0);
  const [lista, setList] = useState([]);
  const [modal, setModal] = useState({
    opened: false,
  });
  const handleLeftClick = () => {
    if (count === lista.length) {
      setCount(0);
    } else if (count <= 0) {
      setCount(0);
    } else {
      setCount(count - 1);
    }
  };

  const handleRightClick = () => {
    if(lista.length == []) return;
    if (count === lista.length - 1) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
  };

  useEffect(() => {
    const intervalSlide = setInterval(() => {
      handleRightClick();
    }, 30000);
    return () => {
      clearInterval(intervalSlide);
    };
  }, [count]);

  useEffect(() => {
    call();
  }, [props]);

  const call = async () => {
    await setList(props?.comunicados?.Comunicados?.Comunicados);
  };

  const handleClickModal = (info) => {
    if (info) {
      setModal({
        opened: true,
        info: info,
      });
    }
  };

  return (

    <Container style={{ padding: '0.3rem !important', marginTop: 0 }}>
      <Title>ÚLTIMAS NOTICIAS</Title>
      <News>
        <a onClick={() => handleClickModal(lista[count]?.comunicado)}>
          {lista?.length > 0
            ? lista[count]?.comunicado?.titulo
            : 'Aguarde Carregando....'}
        </a>
      </News>
      <Arrows>
        <button
          disabled={lista?.length > 0 ? false : true}
          onClick={handleLeftClick}
        >
          <FontAwesomeIcon icon={['fa', 'arrow-left']} />
        </button>
        <button
          disabled={lista?.length > 0 ? false : true}
          onClick={handleRightClick}
        >
          <FontAwesomeIcon icon={['fa', 'arrow-right']} />
        </button>
      </Arrows>
      <div
        className={modal?.opened ? 'conteudoLink is-active' : 'conteudoLink'}
      >
        <div className="is-fullhd">
          <div className="columns is-gapless">
            <div
              className="is-transparent"
              style={{ width: '15%',}}
              id="columnClose"
              onClick={() =>
                setModal((prevState) => ({ ...prevState, opened: false }))
              }
            />
            <ComunicadoDisplay
              comunicado={modal?.info ? modal?.info : false}
              isDestaque={false}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default LastNews;
