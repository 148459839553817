import styled from 'styled-components';

export const Container = styled.div``;

export const Line = styled.div`
  flex: ${(props) => props.flex};
  display: flex;
  column-gap: 14px;
  justify-content: flex-start;
  align-items: center;

  .ant-form-item {
    flex: 0.3;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

