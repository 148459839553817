import React from 'react';
import { Row } from 'antd';
const Content = ({ children, extraContent }) => {
  return (
    <Row className="content" type="flex">
      <div className="main" style={{ flex: 1 }}>
        {children}
      </div>
      <div
        className="extra"
        style={{
          marginLeft: 150,
        }}
      >
        {extraContent ? extraContent : ' '}
      </div>
    </Row>
  );
};

export default Content;
