import { useState, useCallback, useEffect } from 'react';
import {
  createFilter,
  updateFilter,
  getFilter as _getFilter,
} from '../../../../../services/reports/service';
import Swal from 'sweetalert2';

const useFilters = (idRelatorio, idFiltro) => {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    idRelatorio,
    nomeExibicao: undefined,
    status: undefined,
    filtro: undefined,
    condicaoWhere: undefined,
    tipo: undefined,
    obrigatorio: undefined,
    query: undefined,
  });

  const statusOptions = [
    {
      value: 'INATIVO',
      label: 'INATIVO',
    },
    {
      value: 'ATIVO',
      label: 'ATIVO',
    },
  ];

  const typeOptions = [
    {
      value: 'DATE',
      label: 'DATE',
    },
    {
      value: 'SELECT',
      label: 'SELECT',
    },
    {
      value: 'BOOLEAN',
      label: 'BOOLEAN',
    },
  ];

  const requiredOptions = [
    {
      value: 'SIM',
      label: 'SIM',
    },
    {
      value: 'NÃO',
      label: 'NÃO',
    },
  ];
  const onChange = useCallback(
    (value, field) => {
      setForm((state) => ({ ...state, [field]: value }));
    },
    [form]
  );

  const disabledSaveButton = !Object.keys(form).every((key) => {
    if (key === 'query') return true;
    const item = form[key];
    return (
      item !== undefined && item !== '' && item !== null && item !== 0 && item
    );
  });

  const getFilter = useCallback(async () => {
    try {
      const { data } = await _getFilter({ idFiltro, idRelatorio });
      setForm(data);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    getFilter();
  }, []);

  const postOrUpdateFilter = useCallback(async () => {
    try {
      setLoading(true);
      const postOrUpdate = !idFiltro ? createFilter : updateFilter;
      const { status } = await postOrUpdate({
        body: {
          ...form,
          idRelatorio: !idRelatorio ? undefined : +idRelatorio,
        },
        id: idFiltro,
      });
      if (status === 200 || status === 201) {
        Swal.fire({
          title: `Filtro ${idFiltro ? 'alterado' : 'criado'} com sucesso!`,
          willClose: () =>
            (window.location.href = `/admin/filtros/${idRelatorio}`),
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        title: error.response?.data?.error,
        text: error.response?.data?.message,
      });
    }
  }, [form, loading]);

  return {
    loading,
    requiredOptions,
    typeOptions,
    statusOptions,
    form,
    onChange,
    disabledSaveButton,
    postOrUpdateFilter,
  };
};

export { useFilters };
