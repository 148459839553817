import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '../../../services/Gerais';
import { Button } from 'antd';
import { ActionContainer } from './styles';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getImports, cancelImport } from 'services/importFiles';
import { LoadingOutlined } from '@ant-design/icons';


const useImportUsers = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [form, setForm] = useState({
    data: '',
    status: '',
  });
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState({
    value: false,
    index: 0,
  });
  const [data, setData] = useState([]);
  const importStatus = {
    ['AGUARDANDO_PROCESSAMENTO']: 'Aguardando processamento',
    ['PROCESSADO_SUCESSO']: 'Processado - sucesso',
    ['PROCESSADO_ERRO']: 'Processado - erro',
    ['PROCESSAMENTO_CANCELADO']: 'Processamento cancelado',
    ['EM_ANDAMENTO']: 'Em andamento',
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Arquivo',
      dataIndex: 'nomeArquivo',
      key: 'nomeArquivo',
      width: 200,
    },
    {
      title: 'Data',
      dataIndex: 'dataInclusao',
      key: 'dataInclusao',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status) => importStatus[status],
    },
    {
      title: 'Ações',
      width: 50,
      render: (row, record, index) => {
        return (
          <ActionContainer key={`action_${row.id}`}>
            {row.usuarioUploadResponseDTOList && (
              <Button
                type="dashed"
                className="button is-small is-warning"
                size="small"
                onClick={async () =>
                  await downloadData(
                    row.usuarioUploadResponseDTOList,
                    row.nomeArquivo
                  )
                }
              >
                <FontAwesomeIcon icon={['fas', 'download']} /> &nbsp; Download
              </Button>
            )}

            {row.exibeBotaoCancelar && (
              <Button
                type="dashed"
                className="button is-small is-light"
                size="small"
                onClick={async () => await cancelData(row.id, index)}
              >
                {loadingCancel.index === index && loadingCancel.value ? (
                  <LoadingOutlined />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'ban']} />
                )}{' '}
                &nbsp; Cancelar
              </Button>
            )}
          </ActionContainer>
        );
      },
    },
  ];

  const cleanFields = () => {
    setForm({ data: '', status: '' });
  };

  const onChange = (value, field) => {
    setForm((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const parseDateFull = (date) => {
    const splitedDate = date.split('-');
    const year = splitedDate[0];
    const month = splitedDate[1];
    const day = splitedDate[2];
    return `${day}/${month}/${year}`;
  };

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { status, data } = await getImports({
        dataInclusao: form.data ? parseDateFull(form.data) : undefined,
        status: form.status || undefined,
      });
      if (status === 200 || status === 201) {
        const orderData = data.sort((a, b) => (a.id > b.id ? -1 : 1));
        setData(orderData);
        setFilteredData(orderData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        text:
          'Ocorreu um problema ao buscar os usuários importados, tente novamente!',
      });
    }
  }, [form]);

    const exportXLSXRelatorio = async (data) => {
    const XlsxPopulate = require('xlsx-populate');
    const workbook = await XlsxPopulate.fromBlankAsync();
    const sheet = workbook.sheet(0);

    sheet.cell('A1').value('CPF');
    sheet.cell('B1').value('E-MAIL');
    sheet.cell('C1').value('NOME');
    sheet.cell('D1').value('VÍNCULO');
    sheet.cell('E1').value('STATUS DO USUÁRIO');
    sheet.cell('F1').value('DDD');
    sheet.cell('G1').value('TELEFONE');
    sheet.cell('H1').value('ID DO POLO');
    sheet.cell('I1').value('STATUS DO POLO');
    sheet.cell('J1').value('DATA DA ALTERAÇÃO');
    sheet.cell('K1').value('DATA DA CRIAÇÃO');
    sheet.cell('L1').value('REGISTROS DE ERRO');
    sheet.cell('M1').value('PROCESSADO?')


    let i = 2;
    data.forEach((item) => {
      sheet.cell(`${'A' + i}`).value(item.cpf);
      sheet.cell(`${'B' + i}`).value(item.email);
      sheet.cell(`${'C' + i}`).value(item.nome);
      sheet.cell(`${'D' + i}`).value(item.tipoColaborar);
      sheet.cell(`${'E' + i}`).value(item.situacaoUsuario);
      sheet.cell(`${'F' + i}`).value(item.ddd);
      sheet.cell(`${'G' + i}`).value(item.telefone);
      sheet.cell(`${'H' + i}`).value(item.poloId);
      sheet.cell(`${'I' + i}`).value(item.situacaoPolo);
      sheet.cell(`${'J' + i}`).value(item.dataHora);
      sheet.cell(`${'K' + i}`).value(item.dataCriacao);
      sheet.cell(`${'L' + i}`).value(item.logErro);
      sheet.cell(`${'M' + i}`).value(item.processado);
      i++;
    });

     const blob = await workbook.outputAsync();

    //LINK PRA DOWNLOAD
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `IMPORTACAO_USUARIOS_${moment().format('DD/MM/YYYY HH:mm:ss').replaceAll('/', '').replaceAll(' ', '_').replaceAll(':','')}.xlsx`;
    a.click();
  }

  const downloadData = useCallback(async (jsonCsv) => {
    exportXLSXRelatorio(jsonCsv)
  }, []);


  const cancelData = useCallback(async (id, index) => {
    try {
      setLoadingCancel({
        value: true,
        index,
      });
      const { status } = await cancelImport({ id });
      if (status === 200 || status === 201) {
        Swal.fire({
          text: 'Importação cancelada com sucesso!',
        });
        await getData();
      }
      setLoadingCancel({
        value: false,
        index,
      });
    } catch (error) {
      setLoadingCancel({
        value: false,
        index,
      });
      Swal.fire({
        text:
          'Ocorreu um problema ao cancelar o processamento da importação, tente novamente!',
      });
    }
  });

  useEffect(() => {
    getData();
  }, []);

  return {
    columns,
    data,
    filteredData,
    form,
    onChange,
    cleanFields,
    getData,
    loading,
  };
};

export { useImportUsers };
