import React, { useEffect, useState } from 'react';
import { ActionContainer } from './styles';
import { Link } from 'react-router-dom';
import { Button, Icon, Tooltip } from 'antd';
import {
  fetchLinkBusinessManager,
  deleteLinkBusinessManager,
} from 'services/linkBusinessManager';
import { FontAwesomeIcon } from '../../../services/Gerais';
import Swal from 'sweetalert2';

const useManagerBinding = (userId) => {
  const [businessManagerData, setBusinessManagerData] = useState([]);
  const [businessDirectorData, setBusinessDirectorData] = useState();

  const handleDelete = async (id) => {
    try {
      const { status } = await deleteLinkBusinessManager({
        id,
      })
      if (status === 200) {
        Swal.fire({
          position: 'top-end',
          text: 'Vinculo de GN removido com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000,
        });
        setTimeout(function(){
          window.location.reload();
       }, 3000);
      }
    } catch (error) {
      Swal.fire({
        position: 'top-end',
        text: 'Ocorreu um problema ao deletar gerente, tente novamente',
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleGetManagers = async () => {
    try {
      const { data, status } = await fetchLinkBusinessManager({
        id: userId,
      });
      if (status === 200) {
        setBusinessDirectorData(data.diretorNegocio);
        setBusinessManagerData(data.gerenteNegocio);
      }
    } catch (error) {
      Swal.fire({
        text: 'Ocorreu um problema ao buscar gerentes, tente novamente',
      });
    }
  };

  useEffect(() => {
    handleGetManagers();
  }, []);

  const columns = [
    {
      title: 'Gestores de Negócio',
      dataIndex: 'nome',
      key: 'nome',
      width: '80%'
    },
    {
      title: 'Ações',
      width: '20%',
      render: (row) => {
        return (
          <ActionContainer key={`action_${row.id}`}>
            <Link to={`/admin/usuarios/edit/${row.usuarioId}`}>
              <Button
                type="dashed"
                className="button is-small is-warning"
                size="small"
              >
                <FontAwesomeIcon icon={['fas', 'user-cog']} /> &nbsp; Editar
                perfil
              </Button>
            </Link>
            <Tooltip title="Deletar vinculo de Gestor de Negócio">
              <Button onClick={() => handleDelete(row.id)} type="danger" size="small">
                <Icon type="delete" />
              </Button>
            </Tooltip>


          </ActionContainer>
        );
      },
    },
  ];
  return { columns, businessDirectorData, businessManagerData };
};

export { useManagerBinding };
