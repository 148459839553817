import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row,
  Form,
  Select,
  Button,
  Col,
  Input
} from 'antd';
import { Container } from './styles';
import { FormController } from './FormController';
import { useForm, Controller } from 'react-hook-form';
import 'antd/dist/antd.css';


const FilterForm = ({ idFilter, history2, idRelatorio }) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, reset } = useForm();

  const props = {
    state: {
      loading: {loading, setLoading}
    },
    history: history2,
    form: {
      handleSubmit, control, reset
    },
    dispatch,
  };

  const filterController = new FormController(props);
  useEffect(() => {
    if (idRelatorio.length > 0 && idFilter.length > 0) {
      filterController.get(idFilter, idRelatorio)
    }
  }, [idFilter, idRelatorio]);



    return (
      <Container>
        <Form onSubmit={handleSubmit((e) => filterController.handleSubmit(e, idFilter.length > 0 ? true : false, idRelatorio))}>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Nome Exibição" size="large">
                <Controller
                  name="nomeExibicao"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input {...field} data-testid="teste-titulo" placeholder="Nome Exibição" size="large" disabled={loading} />
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Filtro" size="large">
                <Controller
                  name="filtro"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input {...field} data-testid="teste-titulo" placeholder="Filtro" size="large" disabled={loading} />
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Status" size="large">
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select size="large" {...field} placeholder="Status" disabled={loading}>
                      <Option value="ATIVO">Ativo</Option>
                      <Option value="INATIVO">Inativo</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item label="Condição Where" size="large">
                <Controller
                  name="condicaoWhere"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                      <Input {...field} data-testid="teste-titulo" placeholder="Condição Where" size="large" disabled={loading} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Tipo" size="large">
              <Controller
                  name="tipo"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select size="large" {...field}  placeholder="Tipo" disabled={loading}>
                      <Option value="BOOLEAN">BOOLEAN</Option>
                      <Option value="SELECT">SELECT</Option>
                      <Option value="DATE">DATE</Option>
                      <Option value="VARCHAR">VARCHAR</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Obrigatorio" size="large">
                <Controller
                  name="obrigatorio"
                  placeholder="Obrigatorio"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select size="large" {...field}  placeholder="Obrigatorio" disabled={loading}>
                      <Option value="S">Sim</Option>
                      <Option value="N">Não</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item label="Query Select (SQL)" size="large">
                <Controller
                  name="query"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      rows={15}
                      disabled={loading}
                      placeholder="Query Select (SQL)"
                      style={{ minHeight: '100px' }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16} style={{ textAlign: 'right' }}>
            <Col xs={24} sm={24} md={24}>
              <Button type="primary" size="large" htmlType="submit" loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
};

export default FilterForm;
