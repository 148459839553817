import React, { useEffect } from 'react';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../services/Gerais';
import { SystemDataContainer, Column, Line, ButtonContainer } from './styles';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import { optionValue, useNewSystemAssignments } from './hook';
import { LoadingOutlined } from '@ant-design/icons';

const NewSystemAssignments = (props) => {
  const { Option } = Select;
  const {
    match: {
      params: {
        id,
        system,
        profile,
        postOrPut,
        systemId,
        assignmentId,
        profileId,
      },
    },
  } = props;
  const {
    form,
    onChange,
    getAssignment,
    createAssignment,
    isLoading,
    fullFilledInputs,
  } = useNewSystemAssignments(id, postOrPut, systemId, assignmentId, profileId);

  useEffect(() => {
    getAssignment(id);
  }, []);
  return (
    <>
      <Helmet
        key="TitleAdminAtribuiçõesCadastro"
        title="Cadastro de Atribuições - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Cadastro de Atribuições' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, usuarios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderCadastroAtribuições" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminCadastroAtribuições" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroCadastroAtribuições"
                pageName="Cadastro de Atribuições"
                titulo="Cadastro de Atribuições"
              />
            </div>
          </div>
          <SystemDataContainer>
            <div>
              <strong>Perfil:</strong>
              <p>{profile}</p>
            </div>

            <div>
              <strong>Sistema:</strong>
              <p>{system}</p>
            </div>
          </SystemDataContainer>
          <Column>
            <Line>
              <Form layout="vertical">
                <Line>
                  <Form.Item
                    style={{ width: '240px' }}
                    label="Código de atribuição"
                  >
                    <Input
                      onChange={(event) => onChange(event.target.value, 'code')}
                      value={form.code}
                      placeholder="Código de atribuição"
                    />
                  </Form.Item>

                  <Form.Item style={{ width: '240px' }} label="Descrição">
                    <Input
                      onChange={(event) =>
                        onChange(event.target.value, 'describe')
                      }
                      value={form.describe}
                      placeholder="Descrição"
                    />
                  </Form.Item>

                  <Form.Item style={{ width: '240px' }} label="Status">
                    <Select
                      placeholder="Status"
                      value={form.status}
                      onChange={(value) => onChange(value, 'status')}
                    >
                      <Option value={optionValue.yes}>Ativo</Option>
                      <Option value={optionValue.no}>Inativo</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item style={{ width: '240px' }} label="Consulta">
                    <Select
                      value={form.search}
                      onChange={(value) => onChange(value, 'search')}
                      placeholder="Consulta"
                    >
                      <Option value={optionValue.yes}>Sim</Option>
                      <Option value={optionValue.no}>Não</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item style={{ width: '240px' }} label="Inclusão">
                    <Select
                      value={form.include}
                      onChange={(value) => onChange(value, 'include')}
                      placeholder="Inclusão"
                    >
                      <Option value={optionValue.yes}>Sim</Option>
                      <Option value={optionValue.no}>Não</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item style={{ width: '240px' }} label="Alteração">
                    <Select
                      value={form.update}
                      onChange={(value) => onChange(value, 'update')}
                      placeholder="Alteração"
                    >
                      <Option value={optionValue.yes}>Sim</Option>
                      <Option value={optionValue.no}>Não</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ width: '240px' }} label="Exclusão">
                    <Select
                      value={form.delete}
                      onChange={(value) => onChange(value, 'delete')}
                      placeholder="Exclusão"
                    >
                      <Option value={optionValue.yes}>Sim</Option>
                      <Option value={optionValue.no}>Não</Option>
                    </Select>
                  </Form.Item>
                </Line>
              </Form>
            </Line>
          </Column>

          <ButtonContainer
            onClick={async () =>
              fullFilledInputs() && (await createAssignment())
            }
          >
            <Tooltip
              title={
                fullFilledInputs()
                  ? ''
                  : 'Todos os campos devem estar preenchidos!'
              }
            >
              <Button disabled={!fullFilledInputs()} type="primary">
                {isLoading ? <LoadingOutlined /> : 'Salvar'}
              </Button>
            </Tooltip>
          </ButtonContainer>
        </div>
      </section>
      <FooterAdmin key="FooterAdminAtribuiçõesCadastro" />
    </>
  );
};

export default NewSystemAssignments;
