import styled from 'styled-components';

export const Container = styled.div``;

export const Box = styled.div`
  margin-bottom: 24px;
  div {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    justify-content: center;
  }

  p {
    margin: 0;
  }
`;
