const csvJSON = (csv) => {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',').map((val) => val.replace(/\r$/, ''));
  lines
    .filter((item) => item !== '' && item)
    .forEach((_, index) => {
      const obj = {};
      const currentline = lines[index]
        .split(',')
        .map((val) => val.replace(/\r$/, ''));
      headers.forEach((_, index) => {
        obj[headers[index]] = currentline[index];
      });
      result.push(obj);
    });
  return result;
};

export { csvJSON };
