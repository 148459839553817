import React, { Component } from 'reactn';
import avatar from '../../../img/loadingtwo.gif';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
  axios,
} from '../../../services/Gerais';
import EditarEvento from './components/editar-evento';

class Editar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorias: [],
      isLoading: false,
      error: [],
      hojeAgendaCad: new Date(new Date().getTime()),
      busca: [],
      pageParans: [{}],
      eventoID: this.props.match.params.eventoID,
      tipoEvento: this.props.match.params.tipoEvento,
      tipo: this.props.match.params.tipoEvento,
      macroID: this.props.match.params.macroID,
      atividadeID: this.props.match.params.atividadeID,
    };
    this.pathArrayAgCad = window.location.pathname.split('/');
    this.logInfoAgCad = JSON.parse(localStorage.getItem('userDataCRP'));

    this.setParamsPage = this.setParamsPage.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }

  componentDidMount() {
    this.setState({
      pageParans: this.setParamsPage(),
      tipo: this.props.match.params.tipoEvento,
      macroID: this.props.match.params.macroID,
      atividadeID: this.props.match.params.atividadeID,
    });
  }
  getListMapaOperacoes = () => {
    var urlListMapaOperacoes =
      this.global.baseUrl + 'mapaOperacoes/consultarMapaOperacoes';
    axios
      .get(urlListMapaOperacoes, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then(respMap => {
        this.setState({
          listMapaOperacoes: respMap.data.MacroAtividade,
          listEventos: respMap.data.Evento,
        });
      })
      .catch(errorDisplay =>
        this.setState({
          errorDisplay,
          isLoading: false,
        })
      );
  };

  onChange = selected => {
    this.setState({ selected });
  };

  setParamsPage = () => {
    const tipoEvento = this.props.match.params.tipoEvento;
    switch (tipoEvento) {
      case 'macro-atividade':
        return { titulo: 'Macro Atividade' };
      case 'atividade':
        return { titulo: 'Atividade' };
      case 'micro-atividade':
        return { titulo: 'Micro Atividade' };
      case 'evento':
        return { titulo: 'Evento' };
      default:
        return { titulo: 'Macro Atividade' };
    }
  };

  checkAll(e) {
    var checkboxes = [];
    checkboxes = document.querySelectorAll('input[class="checkMarca"]');

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type === 'checkbox') {
        checkboxes[i].checked = e.target.checked;
      }
    }
  }

  render() {
    const { isLoading, pageParans, tipoEvento, eventoID } = this.state;

    const TituloPagina = `Edição de Mapa de Operações - ${
      pageParans.titulo
    }| Portal Prisma`;

    if (isLoading) {
      return (
        <div className="CRP-loading">
          <img src={avatar} width="100" alt="Carregando..." />
        </div>
      );
    }

    return [
      <Helmet
        key="TitleAdminAgendaCadastro"
        title={TituloPagina}
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { name: 'description', content: TituloPagina },
          {
            name: 'keywords',
            content: 'Edição, Mapa de Operações, kroton, educacional, portal',
          },
        ]}
      />,
      <HeaderAdmin key="HeaderAdminAgendaCadastro" />,
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminAgendaCadastro" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <Hero
            key="heroAdminCadastroAgenda"
            pageName="Administração - Mapa de Operações"
            titulo="Administração - Mapa de Operações"
          />
          <div id="return" />
          <div id="response" />
          <div className="columns">
            <div className="column">
              <h1>{`Edição - ${pageParans.titulo}`}</h1>
            </div>
          </div>
          <EditarEvento
            {...this.state}
            eventoID={eventoID}
            tipoEvento={tipoEvento}
          />
        </div>
      </section>,
      <FooterAdmin key="FooterAdminAgendaCadastro" />,
    ];
  }
}
export default Editar;
