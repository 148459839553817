import React, { useState, useCallback, useEffect } from 'react';
import Swal from 'sweetalert2';
import {
  createReport,
  fetchProfiles,
  fetchReport,
  updateReport,
  getCategories,
  listUser,
  removeUser,
} from '../../../../../services/reports/service';
import { pipe, removeEmptyOrNullFromJson } from '../../../../../utils/formatters';
import { Button } from 'antd';

const useReportInclusion = (reportId) => {
  const [form, setForm] = useState({
    administrativo: undefined,
    categoria: undefined,
    status: undefined,
    nomeRelatorio: undefined,
    codigoGrupo: undefined,
    codigoRelatorio: undefined,
    query: undefined,
    posicao: undefined,
    responsavel: undefined,
    perfilRelatorio: [],
    usuariosVinculados: [],
  });
  const isReadOnly = (localStorage.getItem('userDataCRP-Perfil') == 999 ? false : true);
  const [profiles, setProfiles] = useState([]);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [listUsersAux, setListUsersAux] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: 'E-mail',
      key: 'conta',
      dataIndex: 'conta',
      width: '80%',
    },
    {
      title: 'Remover',
      key: 'id',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <Button type='danger' key={id} onClick={() => removeRelease(id)} disabled={isReadOnly}>
            Remover Liberação
          </Button>
        )
      },
    },
  ]);

  const isNonEmptyString = (value) => typeof value === 'string' && value.trim() !== '';

  const isValidForm = () => {
    if (form.administrativo === 'N') {
      return (
        isNonEmptyString(form.nomeRelatorio) &&
        isNonEmptyString(form.responsavel) &&
        isNonEmptyString(form.status) &&
        form.perfilRelatorio.length > 0 &&
        isNonEmptyString(form.codigoGrupo) &&
        isNonEmptyString(form.codigoRelatorio)
      );
    } else if (form.administrativo === 'S') {
      return (
        isNonEmptyString(form.nomeRelatorio) &&
        isNonEmptyString(form.responsavel) &&
        isNonEmptyString(form.status) &&
        isNonEmptyString(form.query) &&
        form.usuariosVinculados?.length > 0
      );
    }
    return false;
  };

  useEffect(() => {
    setIsDisabled(!isValidForm());
  }, [form])

   const onChange = useCallback(
    (value, field) => {
      if (field === 'administrativo') {
        if (value === 'S') {
          setForm((state) => ({ ...state, [field]: value, query: undefined }));
        } else {
          setForm((state) => ({
            ...state,
            [field]: value,
            codigoGrupo: undefined,
            codigoRelatorio: undefined,
            perfilRelatorio: [],
          }));
        }
      }
      setForm((state) => ({ ...state, [field]: value }));
    },
    [form]
  );

  const cleanFields = useCallback(
    () =>
      setForm({
        relatorio: undefined,
        categoria: undefined,
        admin: undefined,
        status: undefined,
      }),
    [form]
  );

  const getProfiles = useCallback(async () => {
    try {
      setLoadingProfiles(true);
      const { data } = await fetchProfiles();
      if (data) {
        setProfiles(
          data
            .map((item) => ({ ...item, key: item.id }))
            .sort((a, b) => (a.perfil > b.perfil ? 1 : -1))
        );
      }
      setLoadingProfiles(false);
    } catch (error) {
      setLoadingProfiles(false);
      return Swal.fire({
        title: error.response?.data?.error,
        text: error.response?.data?.message,
      });
    }
  }, [form]);

  const getOptions = async () => {
    try {
      const { data } = await getCategories();
      setCategoryOptions(
        data.map((item) => ({ value: item.id, label: item.categoria }))
      );
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getOptions();
    reportId && getReport();
    getProfiles();
    getUserList();
  }, []);

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  async function getUserList() {
    const { data } = await listUser();
    setListUsers(data);
    setListUsersAux(data)
  }

  async function removeRelease(id) {
    await removeUser(id, reportId)
  }

  function handleSelectedUser(id) {
    if(!validUserList(id)) {
      listUsers.map(item => {
        if(item.id == id) {
          setUsersSelected((state) => [...state, item])
        }
      })
    }
  }

  function validUserList(id) {
    let valid = false;
    usersSelected.map(user => {
      if(user.id == id) {
        Swal.fire({
          position: 'top-end',
          title: `O usuário ja esta vinculado a este relatorio`,
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000,
        });
        valid = true;
      }
    })

    return valid;
  }

  const filterUsers = (search) => {
    if (search) {
      const optionsArray = listUsersAux;
      const searchArray =
        optionsArray.length > 0
          ? optionsArray.filter((item) =>
              item.conta.includes(search)
            )
          : [];
          setListUsers(searchArray)
    } else {
      setListUsers(listUsersAux);
    }
  };

  const getReport = useCallback(async () => {
    try {
      const { data } = await fetchReport({ id: reportId });
      setForm(
        pipe(
          {
            administrativo: data.tipo === 'Power BI' ? 'N' : 'S',
            categoria: String(data?.categoriaRelatorio?.id),
            status: data.status === 'ATIVO' ? 'S' : 'N',
            nomeRelatorio: data.nomeRelatorio,
            codigoGrupo: data.codigoGrupo,
            codigoRelatorio: data.codigoRelatorio,
            responsavel: data.responsavel,
            posicao: data.posicao,
            query: data.query,
            perfilRelatorio:
              data?.perfilLiberados?.length > 0
                ? data.perfilLiberados.map((item) => item.perfilId)
                : [],
          },
          removeEmptyOrNullFromJson
        )
      );
      setUsersSelected(data.usuariosVinculados)
    } catch (error) {
      return Swal.fire({
        title: error.response?.data?.error,
        text: error.response?.data?.message,
      });
    }
  }, [form]);

  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;

  useEffect(() => {setForm((state) => ({...state, usuariosVinculados: usersSelected}))}, [usersSelected])

  const postOrUpdateReport = useCallback(async () => {
    try {
      setLoading(true);
      const formattedForm =
        form.administrativo === 'N'
          ? {
              ...form,
              perfilRelatorio: form.perfilRelatorio?.map((id) => ({
                perfilId: +id,
              })),
              query: undefined,
            }
          : {
              ...form,
              perfilRelatorio: undefined,
              codigoGrupo: undefined,
              codigoRelatorio: undefined,
            };

      const postOrUpdate = !reportId ? createReport : updateReport;
      const { status } = await postOrUpdate({
        body: {
          ...pipe(
            {
              ...formattedForm,
              categoria: +formattedForm.categoria,
              usuarioPortalLogId: parseSession?.[0]?.usuarioPortalLog?.id,
              usuariosVinculados: usersSelected,
            },
            removeEmptyOrNullFromJson
          ),
        },
        id: reportId,
      });
      if (status === 200 || status === 201) {
        Swal.fire({
          title: `Relatório ${reportId ? 'alterado' : 'criado'} com sucesso!`,
          willClose: () => (window.location.href = '/admin/relatorios'),
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        title: error.response?.data?.error,
        text: error.response?.data?.message,
      });
    }
  }, [form]);

  return {
    loading,
    loadingProfiles,
    categoryOptions,
    columns,
    profiles,
    form,
    selectedKeys,
    listUsers,
    usersSelected,
    isReadOnly,
    isDisabled,
    cleanFields,
    postOrUpdateReport,
    onChange,
    onSelectChange,
    filterUsers,
    handleSelectedUser
  };
};

export { useReportInclusion };
