import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  Row,
  Col,
  Input,
  Select,
} from 'antd';

import { useForm, Controller } from 'react-hook-form';

import { tableColumns } from './PortaisTableProps';
import { PortaisTableController } from './PortaisTableController';
import { Container, ButtonSubmit } from './styled';

const { Option } = Select;

const PortaisTable = () => {
  const { handleSubmit, control } = useForm();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);

  const props = {
    state:{
      data: {data, setData}
    }
  }

  const portaisTableController = new PortaisTableController(props);

  useEffect(() => {
      portaisTableController.hadleInitData()
  }, [])

  return (
    <Container key="HeaderSearchPortais">
      <Form
        onSubmit={handleSubmit((e) => portaisTableController.handleSubmit(e))}
        style={{ marginTop: 20 }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16}>
            <Form.Item label="Sistema/Portal" size="large">
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <Input {...field} data-testid="teste-titulo" placeholder="Sistema/Portal" size="large" />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <Form.Item label="Status" size="large">
              <Controller
                name="ativo"
                control={control}
                render={({ field }) => (
                  <Select size="large" {...field} placeholder="Status">
                    <Option value="S">Ativo</Option>
                    <Option value="N">Inativo</Option>
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} className="submitContainer">
            <ButtonSubmit type="primary" htmlType="submit">
              Pesquisar
            </ButtonSubmit>
          </Col>
        </Row>

      </Form>
      <Table
        dataSource={data}
        loading={loading}
        columns={tableColumns}
        size="small"
      />
    </Container>
  );
};

export default PortaisTable;
