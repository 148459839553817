import React, { useState, useEffect } from 'react';
import { Button, Icon, List } from 'antd';
import { Header, Footer, Hero, Helmet } from '../../../services/Gerais';
import api from '../../../services/api';
import './styles.scss';
import avatar from '../../../img/loadingtwo.gif';
import SistemaCarousel from '../../../components/SistemasCarousel';

const RelatoriosHome = () => {
  const [loading, setLoading] = useState(false);
  const [academic, setAcademic] = useState({});
  const [commercial, setCommercial] = useState({});
  const [strategic, setStrategic] = useState({});
  const [financial, setFinancial] = useState({});
  const [alphabeticalOrder, setAlphabeticalOrder] = useState(true);
  const [acessOrder, setAcessOrder] = useState(true);
  const [iconOrder, setIconOrder] = useState(<Icon type="sort-descending" />);
  const [iconOrderAcess, setIconOrderAcess] = useState(<Icon type="rise" />);
  const [displayList, setDisplayList] = useState(false);
  const [iconDisplay, setIconDisplay] = useState(<Icon type="unordered-list" />);
  const logInfo = JSON.parse(localStorage.getItem('userDataCRP'));

  useEffect(() => {
    async function buscarDados() {
      try {
        const response = await api.get(`relatorio/usuariopolo/${logInfo.id}`);
        response.data.CategoriaRelatorio.forEach(item => {
          if (item.id == 1) {
            setFinancial(item.relatorios);
          } else if (item.id == 2) {
            setAcademic(item.relatorios);
          } else if (item.id == 3) {
            setCommercial(item.relatorios);
          } else {
            setStrategic(item.relatorios);
          }
        })
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    buscarDados();
  }, []);

  useEffect(() => {
    console.log('render academic', academic)
  }, [academic])

  function handleChangeDisplay() {
    if (displayList == false) {
      setDisplayList(true);
      setIconDisplay(<Icon type="table" />);
    } else {
      setDisplayList(false);
      setIconDisplay(<Icon type="unordered-list" />);
    }
  }

  function handleChangeOrderAcess() {
    if (acessOrder == false) {
      setAcademic(academic.sort(sorterUpAcess));
      setCommercial(commercial.sort(sorterUpAcess));
      setStrategic(strategic.sort(sorterUpAcess));
      setFinancial(financial.sort(sorterUpAcess));
      setAcessOrder(true);
      setIconOrderAcess(<Icon type="fall" />);
    } else {
      setAcademic(academic.sort(sorterDownAcess));
      setCommercial(commercial.sort(sorterDownAcess));
      setStrategic(strategic.sort(sorterDownAcess));
      setFinancial(financial.sort(sorterDownAcess));
      setAcessOrder(false);
      setIconOrderAcess(<Icon type="rise" />);
    }
  }

  function sorterUpName (a, b)  {
    if (a.nomeRelatorio < b.nomeRelatorio) return 1;
    if (a.nomeRelatorio > b.nomeRelatorio) return -1;
    return 0;
  };

  function sorterDownName (a, b)  {
    if (a.nomeRelatorio > b.nomeRelatorio) return 1;
    if (a.nomeRelatorio < b.nomeRelatorio) return -1;
    return 0;
  };

  function sorterUpAcess (a, b)  {
    if (a.nAcessos < b.nAcessos) return 1;
    if (a.nAcessos > b.nAcessos) return -1;
    return 0;
  };

  function sorterDownAcess (a, b)  {
    if (a.nAcessos > b.nAcessos) return 1;
    if (a.nAcessos < b.nAcessos) return -1;
    return 0;
  };

  function handleChangeOrder() {
    if(alphabeticalOrder) {
      setAcademic(academic.sort(sorterUpName));
      setCommercial(commercial.sort(sorterUpName));
      setStrategic(strategic.sort(sorterUpName));
      setFinancial(financial.sort(sorterUpName));
      setAlphabeticalOrder(false);
      setIconOrder(<Icon type="sort-ascending" />)
    } else {
      setAcademic(academic.sort(sorterDownName));
      setCommercial(commercial.sort(sorterDownName));
      setStrategic(strategic.sort(sorterDownName));
      setFinancial(financial.sort(sorterDownName));
      setAlphabeticalOrder(true);
      setIconOrder(<Icon type="sort-descending" />)
    }
  }

  if (loading) {
    return (
      <div className="CRP-loading">
        <img src={avatar} width="100" alt="Carregando..." />
      </div>
    );
  }

  return (
    <>
      <Helmet
        title="Relatorios - Portal Prisma"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content:
              'Relatorios acessíveis pelo Portal Prisma',
          },
          { name: 'keywords', content: 'kroton, educacional, portal, teste' },
        ]}
        key="titleRelatorios"
      />
      <Header key="HeaderRelatorios" />
      <Hero key="heroRelatorios" pageName="Relatorios" titulo="Relatorios" />
      <section className="section conteudoRelatorios" key="conteudoRelatorios">
        <Button
          className='button-List'
          onClick={handleChangeDisplay}
          title={`Alterar exibição para modo de ${(displayList ? 'cards' : 'listagem')}`}
        >
          {iconDisplay}
        </Button>
        <Button
          className='button-List'
          onClick={handleChangeOrder}
          title={`Ordenar exibição por ordem ${(alphabeticalOrder ? 'crescente' : 'decrescente')}`}
        >
          {iconOrder}
        </Button>
        <Button
          className='button-List'
          onClick={handleChangeOrderAcess}
          title={`Ordenar exibição por ${(acessOrder ? 'mais acessados' : 'menos acessados')}`}
        >
          {iconOrderAcess}
        </Button>
        <div className="container is-full-fullhd">
          {displayList ?
            <>
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={academic}
                header={<h1>Acadêmico</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={<a href={`/relatorio/${item.id}`}>{item.nomeRelatorio}</a>}
                    />
                  </List.Item>
                )}
              >
              </List>
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={commercial}
                header={<h1>Comercial</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={<a href={`/relatorio/${item.id}`}>{item.nomeRelatorio}</a>}
                    />
                  </List.Item>
                )}
              >
              </List>
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={strategic}
                header={<h1>Estratégico</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={<a href={`/relatorio/${item.id}`}>{item.nomeRelatorio}</a>}
                    />
                  </List.Item>
                )}
              >
              </List>
              <List
                className='list-Container'
                itemLayout="horizontal"
                dataSource={financial}
                header={<h1>Financeiro</h1>}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      description={<a href={`/relatorio/${item.id}`}>{item.nomeRelatorio}</a>}
                    />
                  </List.Item>
                )}
              >
              </List>
            </>

            :
            <>
              <SistemaCarousel
                sistemas={academic}
                titulo="Acadêmico:"
                isPortalOff={false}
                widthCard={170}
                isLink={false}
              />
              <SistemaCarousel
                sistemas={commercial}
                titulo="Comercial:"
                isPortalOff={false}
                widthCard={170}
                isLink={false}
              />
              <SistemaCarousel
                sistemas={strategic}
                titulo="Estratégico:"
                isPortalOff={false}
                widthCard={170}
                isLink={false}
              />
              <SistemaCarousel
                sistemas={financial}
                titulo="Financeiro:"
                isPortalOff={false}
                widthCard={170}
                isLink={false}
              />
            </>
          }

        </div>
      </section>
      <hr className="hrEspacamento" key="hrEspacamentoPortais" />
      <Footer key="FooterPortais" />
    </>
  );
}

export default RelatoriosHome;
