import React, { useEffect, useState } from 'react';
import logo from '../../img/crp_logo_rodape.png';
import prisma from '../../img/prisma_logo_footer.png';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFolder,
  faQuestionCircle,
  faCommentAlt,
} from '@fortawesome/free-regular-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'services/Gerais';
library.add({ faFolder, faQuestionCircle, faCommentAlt, faGlobe });

function Footer() {
  const [dadosPerfil, setDadosPerfil] = useState('');
  const [perfil, setPerfil] = useState(0);

  useEffect(() => {
    setDadosPerfil(JSON.parse(localStorage.getItem('userDataCRP')));
    setPerfil(localStorage.getItem('userDataCRP-Perfil'));
  }, []);

  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;

  const columns = [
    {
      title: 'Atendimento',
      img: <FontAwesomeIcon icon={['fas', 'comment-alt']} />,
      links: [
        {
          label: 'CAPU',
          link: `https://krotoneducacional.virtualinteractions.com.br/pt_BR/avi.html?id=238&source=1&target=1&channel=1&nome=${parseSession?.[0]?.usuarioPortal?.pessoa?.nome}&email=${parseSession?.[0]?.usuarioPortal.conta}&launchType=popup&ms=${new Date().getMilliseconds()}`
        },
        {
          label: 'Consultoria de Infra',
          link: 'https://express.adobe.com/page/ASWv8VjFf8OKD/',
        },
        {
          label: 'FAQ',
          link: '/faq',
        }
      ],
    },
    {
      title: 'Capacitação',
      img: <FontAwesomeIcon icon={['fas', 'graduation-cap']} />,
      links: [
        {
          label: 'Unico',
          link: 'https://unico.cogna.com.br/',
        },
      ],
    },
    {
      title: 'Links Úteis',
      img: <FontAwesomeIcon icon={['fas', 'globe']} />,
      links: [
        {
          label: 'Vestibulares',
          link: 'https://www.vestibulares.com.br/',
        },
        {
          label: 'Ampli',
          link: 'https://www.ampli.com.br/',
        },
        {
          label: 'Unopar',
          link: 'https://www.unopar.com.br/',
        },
        {
          label: 'Anhanguera',
          link: 'https://www.anhanguera.com/',
        },
        {
          label: 'Portal de Pós',
          link: 'https://www.portalpos.com.br/',
        },
        {
          label: 'MEC',
          link: 'https://emec.mec.gov.br/',
        },
      ],
    },
    {
      title: 'Biblioteca',
      img: <FontAwesomeIcon icon={['fas', 'folder']} />,
      links: [
        {
          label: 'Catalogo online',
          link:
            'https://catalogoonline.kroton.com.br/pergamum/biblioteca/index.php?id=UNOPAR',
        },
        {
          label: 'Renovação',
          link:
            'https://catalogoonline.kroton.com.br/pergamum/biblioteca_s/php/login_usu.php?flag=index.php',
        },
        {
          label: 'Biblioteca Virtual',
          link: 'https://biblioteca-virtual.com/unopar',
        },
      ],
    },

  ];

  return (
    <footer className="footer">
      <div className="container">
        {columns.map((item, index) => (
          <div key={index} className="column">
            <span>
              {item.img}
              {item.title}
            </span>
            {item.links.map((item, index) => (
              <a key={index} onClick={() => window.open(item.link)}>
                {item.label}
              </a>
            ))}
          </div>
        ))}

        <div className="column">
          <span>Todos direitos reservados</span>
          <img src={prisma} width="160" alt="Portal Prisma" />
        </div>
      </div>
      <div className="line-footer">
        <span
          onClick={() => {
            window.open('https://www.canaldatransparencia.com.br/cogna/');
          }}
        >
          Portal de Privacidade
        </span>
      </div>
    </footer>
  );
}

export default Footer;
