import { useCallback, useEffect, useState } from 'react';
import {
  fetchFaq,
  createFaq as _createFaq,
  updateFaq as _updateFaq,
} from 'services/faq';
import api from 'services/api';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Swal from 'sweetalert2';
import { fetchCategory } from '../../../services/category';
import moment from 'moment';
import { uploadToS3 } from 'services/uploadS3/s3Service';

const useFaq = ({ faqId }) => {
  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState({
    categoriaId: null,
    duvidaFrequente: null,
    ordemExibicao: null,
    palavraChave: null,
    questao: null,
    respostaNovo: null,
    ativo: 'S',
    dataExpiracaoRevisao: moment(new Date(), 'DD-MM-YYYY')
  });

  useEffect(() => {
    if (faqId) {
      setFaqData((state) => ({
        ...state,
        usuarioAlteracao: parseSession?.[0]?.id,
      }));
      return;
    }
    setFaqData((state) => ({
      ...state,
      usuarioInclusao: parseSession?.[0]?.id,
    }));
  }, []);
  const getData = async () => {
    try {
      const { status, data } = await fetchFaq(faqId);
      if (status === 200) {
        const {
          categoriaId,
          duvidaFrequente,
          ordemExibicao,
          palavraChave,
          questao,
          respostaNovo,
          ativo,
          usuarioAlteracao,
          usuarioInclusao,
          dataExpiracaoRevisao,
        } = data;
        const formatData = {
          categoriaId: String(categoriaId),
          duvidaFrequente,
          ordemExibicao,
          palavraChave,
          questao,
          respostaNovo,
          ativo,
          dataExpiracaoRevisao,
          usuarioAlteracao: usuarioAlteracao ?? usuarioInclusao,
        };
        const blocksFromHTML = htmlToDraft((respostaNovo !== undefined ? respostaNovo : ''));
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );

        setEditor(EditorState.createWithContent(contentState));
        setFaqData(formatData);
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    faqId && getData();
    getCategoryOptions();
  }, []);

  const updateFaq = useCallback(async () => {
    if(faqData.dataExpiracaoRevisao._d != undefined) {
      faqData.dataExpiracaoRevisao = `${moment(faqData.dataExpiracaoRevisao).format('DD-MM-YYYY')} ${moment(faqData.dataExpiracaoRevisao).format('hh:mm:ss')}`;
    }
    try {
      setLoading(true);
      const { status } = await _updateFaq({
        body: { ...faqData, categoriaId: +faqData.categoriaId },
        id: faqId,
      });
      if (status === 200 || status === 201) {
        window.location.href = '/admin/list/faq';
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [faqData]);

  const createFaq = useCallback(async () => {
    faqData.dataExpiracaoRevisao = `${moment(faqData.dataExpiracaoRevisao).format('DD-MM-YYYY')} ${moment(faqData.dataExpiracaoRevisao).format('hh:mm:ss')}`;
    try {
      setLoading(true);
      const { status, data } = await _createFaq({
        body: { ...faqData, categoriaId: +faqData.categoriaId },
      });
      if (status === 200 || status === 201) {
        window.location.href = '/admin/list/faq';
      }
      else if(status === 202 ) {
         Swal.fire({
          text: data.error,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error)
      setLoading(false);
    }
  }, [faqData]);

  const uploadImageCallBack = async (file) => {
    try {
      let dataImage = new FormData();
      dataImage.append('file', file);
      const res = await uploadToS3(dataImage);

      return {
        data: {
          link: res.data.link,
        },
      };
    } catch (error) {}
  };

  const onChange = (value, field) => {
    setFaqData((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const getCategoryOptions = async () => {
    try {
      const { status, data } = await fetchCategory();
      if (status === 200) {
        setCategoryOptions(
          data.map((item) => ({
            value: item.id,
            label: item.categoria,
          }))
        );
      }
    } catch (error) {
      return error;
    }
  };

  const editorChange = (editorState) => {
    setEditor(editorState);

    onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
      'respostaNovo'
    );
  };

  const allFieldsFilled = () => {
    const keys = Object.keys(faqData);
    return keys.every((item) =>
      faqData?.[item] === 0 ? true : faqData?.[item] && faqData[item] !== ''
    );
  };
  const buttonDisabled = !allFieldsFilled();
  const finish = !faqId ? createFaq : updateFaq;

  return {
    faqData,
    editorChange,
    uploadImageCallBack,
    editor,
    onChange,
    buttonDisabled,
    finish,
    loading,
    categoryOptions,
  };
};

export { useFaq };
