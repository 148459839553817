import React from 'react';
import 'antd/dist/antd.css';
import { Table, Input, Button, Popconfirm, Form, Typography, Icon } from 'antd';
import { deleteSubscribe } from 'services/workFlow/service';
import { getUserProfileByEmail, getUserProfileByMail } from 'services/MsGraph';
import Swal from 'sweetalert2';
import { exception } from 'react-ga';

const { Title } = Typography;

const EditableContext = React.createContext();

let listaAssinantes = [];
let isDisabledState = false;

function EditableRow({ form, index, ...props }) {
  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
}

function changeDisabledState(isDisabled) {
  isDisabledState = isDisabled;
}

function checkIfUserIsAlreadyInTable(useremail, dataArray) {
  return dataArray.find((user) => user.email === useremail);
}

function reOrderPosition(array, value) {
  array.forEach((item) => {
    if (item.posicao > value.posicao) {
      item.posicao = item.posicao + 1;
    }
  });
}

const testIfUserExists = async (useremail) => {
  try {
    let userData = null;

    const response = await getUserProfileByEmail(useremail);
    if (response.data.value.length === 1) {
      userData = response.data.value[0];
      changeDisabledState(false);
    } else if (response.data.value.length === 0) {
      const resp = await getUserProfileByMail(useremail);
      if (resp.data.value.length === 1) {
        userData = resp.data.value[0];
        changeDisabledState(false);
      } else {
        Swal.fire({
          title: 'E-mail não encontrado',
          text: `O e-mail ${useremail} não foi localizado.`,
          icon: 'warning',
          confirmButtonText: 'OK',
          timerProgressBar: true,
        });
        changeDisabledState(true);
      }
    }
    if (userData != null) {
      Swal.fire({
        title: 'Assinante adicionado',
        text: `O e-mail ${useremail} foi adicionado com sucesso à lista de assinantes.`,
        icon: 'success',
        confirmButtonText: 'OK',
        timerProgressBar: true,
      });
    }
  } catch (error) {
    Swal.fire({
      title: 'Atenção',
      text: error?.response?.data?.message || error.message,
    });
  }
};

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave, dataIndex } = this.props;

    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;

    const typeOfInput = dataIndex === 'posicao' ? 'number' : 'email';
    const placeholder = dataIndex === 'email' ? 'Email do assinante' : null;
    const minChar = dataIndex === 'email' ? 7 : 1;

    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
                   rules: [
            {
              required: true,
              message: `${title} é obrigatório.`,
              min: minChar,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            type={typeOfInput}
            placeholder={placeholder}            
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24, height: 31 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class SigneeForm extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Email do assinante',
        key: 'email',
        dataIndex: 'email',
        width: '50%',
        editable: true,
      },
      {
        title: 'Ordem de assinatura',
        key: 'posicao',
        dataIndex: 'posicao',
        editable: true,
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.posicao - b.posicao,
      },
      {
        dataIndex: 'operation',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm
              title="Confirma a exclusão?"
              onConfirm={() => this.handleDelete(record.key)}
            >
              <Button title="Excluir" type="danger">
                Excluir assinante
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          ) : null,
      },
    ];

    this.state = {
      dataSource: [],
      count: 1,
      isFinishedSearch: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    if (this.state.dataSource != prevState.dataSource) {
      this.props.handleListaAssinantes(this.state.dataSource);
    }
    if (this.props.subscribes.length > 0 && !this.state.isFinishedSearch) {
      this.handleDataSource(this.props.subscribes);
    }
    this.props.isDisabledState(isDisabledState);
  }

  validSequence = () => {
    const dataSource = [...this.state.dataSource].sort(
      (a, b) => a.posicao - b.posicao
    );
    let count = 1;
    dataSource.forEach((item) => {
      item.posicao = parseInt(item.posicao);
      item.key = parseInt(item.posicao);
      if (item.posicao == count) {
        count++;
      } else if (item.posicao > count) {
        item.posicao = count++;
      }
    });
  };

  handleDataSource = (data) => {
    this.state.dataSource = data;
    this.props.handleListaAssinantes(this.state.dataSource);
    this.state.isFinishedSearch = true;
  };

  handleDelete = async (key) => {
    const dataSource = [...this.state.dataSource];
    const subscribe = dataSource.filter((item) => item.key === key);
    if (subscribe[0].tipoDocumento == undefined) {
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
        count: this.state.count - 1,
      });
    } else {
      await deleteSubscribe(subscribe[0].id, subscribe[0].tipoDocumento);
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
        count: this.state.count - 1,
      });
    }
  };

  handleAdd = () => {
    const { count, email, dataSource } = this.state;
    const newData = {
      key: dataSource.length + 1,
      email: '',
      posicao: dataSource.length + 1,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  handleSave = async (row, dataIndex) => {
    const newData = [...this.state.dataSource];

    if (dataIndex === 'email') {
      const emailExists = await checkIfUserIsAlreadyInTable(row.email, newData);
      if (emailExists) {
        return Swal.fire({
          title: 'E-mail já existe para o documento.',
          text: `O e-mail ${row.email} já foi adicionado na lista de assinantes.`,
          icon: 'warning',
          confirmButtonText: 'OK',
          timerProgressBar: true,
        });
      }
    }
    const index = newData.findIndex((item) => row.key == item.key);
    const indexChange = newData.findIndex((item) => row.posicao == item.key);

    const item = newData[index];
    const itemChange = newData[indexChange];

    if (itemChange != undefined) {
      itemChange.posicao = item.posicao;
    }

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    if (dataIndex === 'email') {
      await testIfUserExists(newData[index].email);
    }

    this.setState({ dataSource: newData });
    this.props.handleListaAssinantes(newData);
  };

  render() {
    const { dataSource } = this.state;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    this.validSequence();
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          onfocus: this.handleSave,
        }),
      };
    });
    return (
      <>
        <div>
          <Title level={3}>
            Cadastre os assinantes para este tipo de documento:
          </Title>
          <p>
            Cadastre os assinantes de acordo com a ordem em que devem assinar o
            documento.
          </p>
          <Button
            onClick={this.handleAdd}
            type="primary"
            style={{ marginBottom: 16 }}
          >
            Adicionar novo assinante
          </Button>
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={columns}
          />
        </div>
      </>
    );
  }
}
export {
  EditableCell,
  SigneeForm,
  changeDisabledState,
  EditableRow,
  listaAssinantes,
};
