import styled from 'styled-components';
import {Button} from "antd";

export const Container = styled.div`
.submitContainer{
    padding-top: 39.9999px
  }
  .ant-table-row-cell-ellipsis{
    vertical-align: middle;
  }
  .ant-table-row-cell-break-word{
    vertical-align: middle;
  }
`
export const ButtonSubmit = styled(Button)`
  width: 100%;
  padding: 15px 0;
  height: 40px !important;
`;
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
