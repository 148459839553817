import config from '../config/config';
import axios from 'axios';

const apiMsGraph = axios.create({
  baseURL: config.urlMsGraph,
});

apiMsGraph.interceptors.request.use(async config => {
  const token = localStorage.getItem('userDataCRP-AuthTk-Graph');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
export default apiMsGraph;
