import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import config from '../../../../config/config';
import {
  Row,
  Form,
  Select,
  Button,
  Icon,
  Col,
  Input,
  Upload,
  Modal,
} from 'antd';
import { Container } from './styles';
import { UploadInitialState } from './FormProps';
import { FormRepository } from './FormRepository';
import { FormController } from './FormController';
import { useForm, Controller } from 'react-hook-form';
import 'antd/dist/antd.css';


const PortaisForm = ({ idPortal, history2 }) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;
  const { handleSubmit, control, reset } = useForm();
  const [upload, setUpload] = useState(UploadInitialState);
  const [fileList, setFileList] = useState([]);

  const props = {
    state: {
      upload: { upload, setUpload },
      file: { fileList, setFileList }
    },
    history: history2,
    form: {
      handleSubmit, control, reset
    },
    dispatch,
  };

  const formPortalRepository = new FormRepository(props);
  const portaisController = new FormController(props);
  useEffect(() => {
    if (idPortal.length > 0) {
      portaisController.get(idPortal)
    }
  }, [idPortal]);



    return (
      <Container>
        <Form onSubmit={handleSubmit((e) => portaisController.handleSubmit(e, idPortal.length > 0 ? true : false))}>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={9}>
              <Form.Item label="Portal" size="large">
                <Controller
                  name="portal"
                  control={control}

                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input {...field} data-testid="teste-titulo" placeholder="Portal" size="large" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9}>
              <Form.Item label="Chave do Portal" size="large">
                <Controller
                  name="chave"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input {...field} data-testid="teste-titulo" placeholder="Chave do Portal" size="large" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item label="Status" size="large">
                <Controller
                  name="ativo"

                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select size="large" {...field} placeholder="Status">
                      <Option value="ativo">Ativo</Option>
                      <Option value="inativo">Inativo</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={18}>
              <Form.Item label="Descrição do Portal" size="large">
                <Controller
                  name="descricao"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      rows={15}
                      placeholder="Descrição do Portal"
                      style={{ minHeight: '100px' }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item label="Integração Prisma" size="large">
                <Controller
                  name="integracaoPrisma"
                  placeholder="Integração Prisma"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select size="large" {...field}  placeholder="Integração Prisma">
                      <Option value="S">Sim</Option>
                      <Option value="N">Não</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={18}>
              <Form.Item label="Link do Portal" size="large">
                <Controller
                  name="link"
                  placeholder="Link do Portal"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input {...field} data-testid="teste-titulo" placeholder="Link do Portal" size="large" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item label="SSO" size="large">
                <Controller
                  name="sso"
                  placeholder="SSO"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select size="sso" {...field}  placeholder="SSO">
                      <Option value="S">Sim</Option>
                      <Option value="N">Não</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={24} md={18}>
              <Form.Item label="Upload de Imagem" size="large">
                <Controller
                  name="file"
                  type="file"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      headers={{
                        authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`
                      }}
                      action={`${config.baseUrl}file/upload`}
                      listType="picture-card"
                      fileList={fileList}
                      multiple={false}
                      onPreview={formPortalRepository.handlePreview}
                      onChange={(e) => {
                        setFileList(e.fileList.slice())
                        field.onChange({ target: { value: fileList }})
                        }}
                      onRemove={formPortalRepository.handleRemove}
                    >
                      {fileList.length >= 1 ? null : (<div>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Upload</div>
                      </div>)}
                    </Upload>
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item label="Permissão" size="large">
                <Controller
                  name="permissao"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select size="large" {...field} placeholder="Permissão">
                      <Option value="PolosProprios">PolosPróprios</Option>
                      <Option value="Todos">Todos</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16} style={{ textAlign: 'right' }}>
            <Col xs={24} sm={24} md={24}>
              <Button type="primary" size="large" htmlType="submit">
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          visible={upload.previewVisible}
          footer={null}
          onCancel={formPortalRepository.handleCancel}
        >
          <img
            style={{ width: '100%' }}
            src={upload.previewImage}
          />
        </Modal>
      </Container>
    );
};

export default PortaisForm;
