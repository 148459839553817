import React from 'reactn';
import {
  Header,
  Footer,
  Hero,
  Helmet,
  axios,
  $,
  moment,
} from '../../services/Gerais';
import '../../styles/EstruturaPadrao.scss';
import { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import {
  Select,
  Input,
  Button,
  Tabs,
  Table,
  Icon,
  Row,
  Col,
  InputNumber,
  Spin,
  Modal,
} from 'antd';
import { currencyMask } from '../../utils/masks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api';
import Swal from 'sweetalert2';
import { faThinkPeaks } from '@fortawesome/free-brands-svg-icons';
import { saveAs } from 'file-saver';
const { Option } = Select;
const { TabPane } = Tabs;
const locale = 'pt-BR';

const EstruturaPadrao = ({ item }) => {
  const titlePage = 'Estrutura Padrão';
  var params = {};

  const url = JSON.parse(localStorage.getItem('cnfgDataCRP'));
  const poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
  let lstPolos = JSON.parse(localStorage.getItem('userDataCRP-ListaPolos'));
  const estPolo = JSON.parse(localStorage.getItem('EstPolo'));
  const perfilId = localStorage.getItem('userDataCRP-Perfil');
  const userData = JSON.parse(localStorage.getItem('userDataCRP'));

  const [polo, setPolo] = useState([]);
  const [periodo, setPeriodo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ position: 'bottom' });
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState([]);
  const [userAdm, setUserAdm] = useState([]);
  const [userAcad, setUserAcad] = useState([]);
  const [lstLaboratorio, setLstLaboratorio] = useState([]);
  const [receitasDespesas, setReceitasDespesas] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [idEstPolo, setIdEstPolo] = useState(0);
  const [dataSearch, setDataSearch] = useState([]);
  const [listUserDelete, setListUserDelete] = useState([]);
  const [isPeriod, setIsPeriod] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sectionTimeCourse, setSectionTimeCourse] = useState(
    estPolo.idEstPoloVigencia
  );
  const tableColumnsAdmistrativa = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Funcionario',
      key: 'nomeFuncionario',
      dataIndex: 'nomeFuncionario',
    },
    {
      title: 'Area',
      key: 'area',
      dataIndex: 'estruturaPoloAreaAdm.descrArea',
    },
    {
      title: 'Cargo',
      key: 'descrCargoAdm',
      dataIndex: 'descrCargoAdm',
    },
    {
      title: 'Tipo Registro',
      key: 'tipoRegistro',
      dataIndex: 'tipoRegistro',
      render: (type) => {
        switch (type) {
          case 'C':
            return 'CLT';
          case 'T':
            return 'Terceiro/Familiar';
          case 'P':
            return 'Promotor';
          default:
            break;
        }
      },
    },
    {
      title: 'Salario',
      key: 'vlrSalario',
      dataIndex: 'vlrSalario',
      render: (salario) => {
        return currencyMask(salario);
      },
    },
    {
      title: 'Rateio',
      key: 'prcRateio',
      dataIndex: 'prcRateio',
      render: (rateio) => {
        if ((rateio == null) | (rateio == 0)) return `100%`;
        return `${rateio}%`;
      },
    },
    {
      title: isPeriod == false ? '' : 'Editar',
      key: 'id',
      dataIndex: 'id',
      render: (id) => {
        validPeriod(periodo);
        if (isPeriod == true) {
          return (
            <Button
              type="dashed"
              className="button is-small is-warning"
              href={`/usuario-estrutura/edit/AD/${id}`}
            >
              <FontAwesomeIcon icon={['fas', 'user']} /> &nbsp; Editar
            </Button>
          );
        }
      },
    },
    {
      title: isPeriod == false ? '' : 'Remover',
      dataIndex: 'id',
      render: (id) =>
        isPeriod ? (
          <Button
            title="Deletar Linha"
            type="danger"
            onClick={() => deleteUser(id)}
          >
            <Icon type="delete" />
          </Button>
        ) : (
          ''
        ),
    },
  ];

  const tableColumnsAcademica = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Funcionario',
      key: 'nomeFuncionario',
      dataIndex: 'nomeFuncionario',
    },
    {
      title: 'Area',
      key: 'area',
      dataIndex: 'estruturaPoloAreaAcad.descrArea',
    },
    {
      title: 'Tipo Registro',
      key: 'tipoRegistro',
      dataIndex: 'tipoRegistro',
      render: (type) => {
        switch (type) {
          case 'C':
            return 'CLT';
          case 'T':
            return 'Terceiro/Familiar';
          case 'P':
            return 'Promotor';
          default:
            break;
        }
      },
    },
    {
      title: 'Formato Salario',
      key: 'formatoSalarioAcad',
      dataIndex: 'formatoSalarioAcad',
      render: (type) => {
        switch (type) {
          case 'M':
            return 'Mensal';
          case 'H':
            return 'Hora';
          case 'A':
            return 'Ambos';
          default:
            break;
        }
      },
    },
    {
      title: 'Salario Mensal',
      key: 'vlrSalario',
      dataIndex: 'vlrSalario',
      render: (salario) => {
        return currencyMask(salario);
      },
    },
    {
      title: 'R$ Hora Aula',
      key: 'vlrHoraAulaAcad',
      dataIndex: 'vlrHoraAulaAcad',
      render: (valor) => {
        return currencyMask(valor);
      },
    },
    {
      title: 'QTD Hora Aula Mes',
      key: 'qtdHoraAulaMesAcad',
      dataIndex: 'qtdHoraAulaMesAcad',
    },
    {
      title: 'Rateio',
      key: 'prcRateio',
      dataIndex: 'prcRateio',
      render: (rateio) => {
        if ((rateio == null) | (rateio == 0)) return `100%`;
        return `${rateio}%`;
      },
    },
    {
      title: isPeriod == false ? '' : 'Editar',
      dataIndex: 'id',
      render: (id) => {
        validPeriod(periodo);
        if (isPeriod) {
          return (
            <Button
              type="dashed"
              className="button is-small is-warning"
              href={`/usuario-estrutura/edit/AC/${id}`}
            >
              <FontAwesomeIcon icon={['fas', 'user']} /> &nbsp; Editar
            </Button>
          );
        }
      },
    },
    {
      title: isPeriod == false ? '' : 'Remover',
      dataIndex: 'id',
      render: (id) =>
        isPeriod ? (
          <Button
            title="Deletar Linha"
            type="danger"
            onClick={() => deleteUser(id)}
          >
            <Icon type="delete" />
          </Button>
        ) : (
          ''
        ),
    },
  ];

  const tableColumnsReceitas = [
    {
      title: 'Codigo',
      key: 'estruturaPoloDespesa.codReduzido',
      dataIndex: 'estruturaPoloDespesa.codReduzido',
      render: (id, reacord) =>
        reacord.estruturaPoloDespesa.tipoConta == 'T' ? (
          <strong>
            <span>{id}</span>
          </strong>
        ) : (
          <span>{id}</span>
        ),
    },
    {
      title: 'Conta',
      key: 'estruturaPoloDespesa.descrDespesa',
      dataIndex: 'estruturaPoloDespesa.descrDespesa',
      render: (conta, reacord) =>
        reacord.estruturaPoloDespesa.tipoConta == 'T' ? (
          <strong>
            <span>{conta}</span>
          </strong>
        ) : (
          <span>
            <Icon type="enter" className="inverter-img" /> {conta}
          </span>
        ),
    },
    {
      title: 'Valor',
      key: 'vlrDespesa',
      dataIndex: 'vlrDespesa',
      render: (valor, reacord) => {
        if (reacord.estruturaPoloDespesa.tipoConta == 'C') {
          return currencyMask(valor);
        }
      },
    },
    {
      title: 'Rateio',
      key: 'prcRateio',
      dataIndex: 'prcRateio',
      render: (rateio, reacord) => {
        if (reacord.estruturaPoloDespesa.tipoConta == 'C') {
          if ((rateio == null) | (rateio == 0)) return `100%`;
          return `${rateio}%`;
        }
      },
    },
    {
      title: isPeriod == false ? '' : 'Editar',
      dataIndex: 'id',
      render: (id, reacord) =>
        isPeriod ? (
          reacord.estruturaPoloDespesa.tipoConta == 'T' ? (
            ''
          ) : (
            <Button
              title="Editar "
              className="button is-small is-warning"
              href={`/usuario-estrutura/despesas/${id}`}
            >
              <Icon type="save" />
              Editar
            </Button>
          )
        ) : (
          ''
        ),
    },
  ];

  const tableColumnsLaboratorio = [
    {
      title: 'Laboratorio',
      key: 'estPoloCadLaboratorio',
      dataIndex: 'estPoloCadLaboratorio.descricao',
    },
    {
      title: 'Modelo',
      key: 'estPoloCadModelo',
      dataIndex: 'estPoloCadModelo.descricao',
    },
    {
      title: 'CAPE',
      key: 'cape',
      dataIndex: 'cape',
      render: (cape) => {
        if (cape == 'S') {
          return 'SIM';
        } else {
          return 'NÃO';
        }
      },
    },
    {
      title: 'Convênio',
      key: 'convenio',
      dataIndex: 'convenio',
      render: (convenio) => {
        if (convenio == 'S') {
          return 'SIM';
        } else {
          return 'NÃO';
        }
      },
    },
    {
      title: 'Capacidade',
      key: 'capacidade',
      dataIndex: 'capacidade',
    },
    {
      title: 'Metragem (M²)',
      key: 'metragem',
      dataIndex: 'metragem',
    },
    {
      title: 'Data de inclusão',
      key: 'dataInclusao',
      dataIndex: 'dataInclusao',
      render: (data, record) => {
        if (data == null) {
          return moment(record.dataAlteracao).format('DD/MM/YYYY');
        }
        return moment(data).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Editar',
      key: 'id',
      dataIndex: 'id',
      render: (id, record) => (
        <Button
          title="Editar "
          className="button is-small is-warning"
          href={`/usuario-estrutura/laboratorio/edit/${id}`}
        >
          <Icon type="save" />
          Editar
        </Button>
      ),
    },
    {
      title: 'Excluir',
      key: 'id',
      dataIndex: 'id',
      render: (id, record) => (
        <Button
          title="Deletar Linha"
          type="danger"
          onClick={() => deleteLaboratorio(id)}
        >
          <Icon type="delete" />
        </Button>
      ),
    },
  ];

  function getLaboratorio(id) {
    axios
      .get(
        `${url.baseUrl}estruturaPolo/laboratorios/findAllByEstPoloId/${id}`,
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then((result) => {
        setLstLaboratorio(result.data);
      });
  }

  useEffect(() => {
    if (lstPolos == null) {
      lstPolos = JSON.parse(localStorage.getItem('userDataCRP-Polos'))[0].polo;
      setPolo((lstPolos.nome != null ? lstPolos.nome : 'POLO SEM NOME CADASTRADO'));
      return;
    }
    lstPolos.forEach((item) => {
      if (item.id.id === poloId) {
        setPolo(item.nome.nome);
        return;
      }
    });
  }, []);

  useEffect(() => {
    console.log('BUSCOU')
    axios
      .get(`${url.baseUrl}estruturaPolo/buscarVigencia/${poloId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then((result) => {
        if (result.data === '') {
          Swal.fire({
            position: 'top-end',
            title: 'Ops...!',
            text: 'Não a vigencia a ser lançadas',
            icon: 'warning',
            showConfirmButton: false,
            timer: 4000,
          }).then((result) => {
            setInterval(() => {window.location.href = '../home'}, 2000);
          });
        } else {
          setPeriodo(result.data.estruturaPoloVigencia);
          setLoading(false);
          validPeriod(result.data.estruturaPoloVigencia);
          result.data.estruturaPoloVigencia.forEach(function (item) {
            if (
              estPolo &&
              estPolo.idEstPoloVigencia &&
              estPolo.idEstPoloVigencia === item.id
            ) {
              setSectionTimeCourse(item.id);
            }
          });
        }
      });
    if (estPolo && estPolo.idPolo != null) {
      submitPeriodo(estPolo.idEstPoloVigencia, poloId);
      newSearch();
    }
  }, [sectionTimeCourse]);

  useEffect(() => {
    validPeriod(periodo);
  }, [isPeriod]);

  function renderOption(item) {
    return (
      <Option value={item.id} key={item.id} text={item.vigencia}>
        {item['vigencia'] ? item.vigencia : item.vigencia}
      </Option>
    );
  }

  function changeCpf(cpf) {
    var test = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return cpf;
  }

  async function submitPeriodo(data, idPolo) {
    params.idEstPoloVigencia = parseInt(data);
    params.idPolo = idPolo;
    setDataSearch(params);
    await localStorage.setItem('EstPolo', JSON.stringify(params));
    getAreaAcad();
    getAreaAdm();
    if (parseInt(data) != estPolo.idEstPoloVigencia) {
      window.location.href = '../estrutura';
    }
  }

  function validPeriod(periodo) {
    let currentDate = new moment(new Date());
    periodo.forEach(function (item) {
      let startDate = new moment(item.dataInicio, 'DD-MM-YYYY');
      let endDate = new moment(item.dataFim, 'DD-MM-YYYY');
      if (item.id == estPolo.idEstPoloVigencia) {
        if (currentDate._d >= startDate._d && currentDate._d <= endDate._d) {
          setIsPeriod(true);
        } else {
          setIsPeriod(false);
        }
      }
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let list = [];
      selectedRows.forEach((item) => {
        list.push(item.id);
      });
      setListUserDelete(list);
    },

    getCheckboxProps: (record) => ({
      disabled: isPeriod ? false : true,
    }),
  };

  function handleModal() {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      saveFees();
      setIsOpen(false);
    }
  }

  function deleteListUser() {
    Swal.fire({
      title: `Remoção de usuário`,
      text: 'Deseja remover estes usuários da lista?',
      icon: 'warning',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    })
      .then((result) => {
        if (result.isConfirmed === true) {
          $.ajax({
            type: 'DELETE',
            url: `${url.baseUrl}estruturaPolo/excluirFuncionarios`,
            contentType: 'application/json; charset=utf-8',
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
            data: JSON.stringify(listUserDelete),
            success: function (resp) {
              Swal.fire({
                position: 'top-end',
                title: `Usuario(s) removido(s) com sucesso !`,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
              window.setTimeout(function () {
                window.location.href = '../estrutura';
              }, 1000);
            },
          });
        }
      })
      .catch();
  }

  async function newSearch() {
    const data = await api.post(
      'estruturaPolo/iniciarPesquisa',
      dataSearch.length < 1 ? estPolo : dataSearch
    );
    setSearch(data.data.EstruturaPolo);
    setUserAdm(data.data.EstruturaPoloPessoasAdm);
    setUserAcad(data.data.EstruturaPoloPessoasAcad);
    setReceitasDespesas(data.data.EstruturaPoloDespesaPeriodo);
    setIdEstPolo(parseInt(data.data.EstruturaPolo.id));
    getLaboratorio(parseInt(data.data.EstruturaPolo.id));
    if (
      data.data.EstruturaPolo.txEncargoAdm != null ||
      data.data.EstruturaPolo.regimeTributario != null ||
      data.data.EstruturaPolo.tipoImovel != null
    ) {
      setIsVisible(true);
    }

  }

  function saveFees() {
    var list = {
      idEstPolo: search.id,
      txEncargoAdm: search.txEncargoAdm != null ? search.txEncargoAdm : 0,
      txEncargoAcad: search.txEncargoAcad != null ? search.txEncargoAcad : 0,
      regimeTributario:
        search.regimeTributario.id !== undefined
          ? search.regimeTributario.id
          : search.regimeTributario,
      vlMetaGanho: search.vlMetaGanho,
      tipoImovel: search.tipoImovel,
      estruturaCompartilhada: search.estruturaCompartilhada,
      idUsuarioLogado: userData.usuarioPortal.id
    };

    $.ajax({
      type: 'PUT',
      url: `${url.baseUrl}estruturaPolo/atualizarTaxasMetas`,
      contentType: 'application/json; charset=utf-8',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      },
      data: JSON.stringify(list),
      success: function (resp) {
        setIsVisible(true);
        Swal.fire({
          position: 'top-end',
          title: `taxas salvas com sucesso !`,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
        });

        window.location.reload();
      },
      error: function (error) {
        Swal.fire({
          position: 'top-end',
          title: 'Ops...!',
          text: error.responseJSON.Errors,
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      },
    });
  }

  function searchUsers(classification) {
    var data = {
      classificacao: classification,
      idEstPoloVigencia: search.estruturaPoloVigencia.id,
      idPolo: poloId,
      idUsuarioLogado: userData.usuarioPortal.id,
      taxaEncargoAdm: search.txEncargoAdm != null ? search.txEncargoAdm : 0,
      taxaEncargoAcad:
        search.taxaEncargoAcad != null ? search.taxaEncargoAcad : 0,
      valorMetaGanho: search.vlMetaGanho,
    };

    $.ajax({
      type: 'POST',
      url: `${url.baseUrl}estruturaPolo/incluirFuncionariosUsuarios`,
      contentType: 'application/json; charset=utf-8',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      },
      data: JSON.stringify(data),
      success: function (resp) {
        if (classification === 'AD') {
          setUserAdm(resp.EstruturaPoloPessoa);
          return;
        } else if (classification === 'AC') {
          setUserAcad(resp.EstruturaPoloPessoa);
          return;
        }
      },
    });
  }

  function deleteUser(id) {
    if (id < 0) return;
    var search = {
      idEstPoloPessoa: id,
    };

    Swal.fire({
      title: `Remoção de usuário`,
      text: 'Deseja remover este usuário da lista?',
      icon: 'warning',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    })
      .then((result) => {
        if (result.isConfirmed === true) {
          $.ajax({
            type: 'DELETE',
            url: `${url.baseUrl}estruturaPolo/excluirFuncionario`,
            contentType: 'application/json; charset=utf-8',
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
            data: JSON.stringify(search),
            success: function (resp) {
              Swal.fire({
                position: 'top-end',
                title: `Usuario removido com sucesso !`,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
              window.setTimeout(function () {
                window.location.href = '../estrutura';
              }, 1000);
            },
            error: function (error) {
              Swal.fire({
                position: 'top-end',
                title: 'Ops...!',
                text: error.responseJSON.error,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            },
          });
        }
      })
      .catch();
  }

  async function exportCSV() {
    let obj = {
      relatorioId: 25,
      lstParametros: [
        {
          id: 12,
          nome: 'Estrutura polo ID',
          valor: idEstPolo,
        },
      ],
    };
    const resposta = await api.post('relatorio/exportCsv', obj);

    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Relatório gerado com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });

    const BOM = '\uFEFF';
    const relatorioDados = BOM + resposta.data;
    const contentType = resposta.headers['content-type'];
    const filename = resposta.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
      .replace('"', '')
      .replace('"', '');
    const blob = new Blob([relatorioDados], {
      type: contentType,
    });
    saveAs(blob, filename);
  }

  function deleteLaboratorio(id) {
    Swal.fire({
      title: `Remoção de Laboratório`,
      text: 'Deseja remover este laboratório da lista?',
      icon: 'warning',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    })
      .then((result) => {
        if (result.isConfirmed === true) {
          $.ajax({
            type: 'DELETE',
            url: `${url.baseUrl}estruturaPolo/laboratorios/${id}`,
            contentType: 'application/json; charset=utf-8',
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
            success: function (resp) {
              Swal.fire({
                position: 'top-end',
                title: `Laboratorio removido com sucesso !`,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
              window.location.href = '../estrutura';
            },
            error: function (error) {
              Swal.fire({
                position: 'top-end',
                title: 'Ops...!',
                text: error.responseJSON.error,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            },
          });
        }
      })
      .catch();
  }

  async function getAreaAcad() {
    const resp = await api.get(`estruturaPolo/buscaAreaAcad`);
    localStorage.setItem('AreaAcademica', JSON.stringify(resp.data.data));
    validPeriod(periodo);
  }

  async function sendEmail() {
    await api.post(`estruturaPolo/sendEmail?poloId=${poloId}&nomePolo=${polo}&idGestor=${userData.usuarioPortal.id}`).then(response => {
      Swal.fire({
        position: 'top-end',
        title: 'Pesquisa finalizada com sucesso!',
        icon: 'sucess',
        timer: 3000,
      })
    }).catch(error => {
      Swal.fire({
        position: 'top-end',
        title: (error.error != null ? error.error : error.response.data.error),
        icon: 'error',
        timer: 3000,
      })
    })
  }

  async function endSearch() {
    await api.put(`estruturaPolo/finalizarPesquisa`, {idEstPoloVigencia: estPolo.idEstPoloVigencia, idPolo: poloId}).then(resp => {
      sendEmail()
    }).catch(error => {
      Swal.fire({
        position: 'top-end',
        title: "Não foi possivel finalizar a pesquisa",
        icon: 'error',
        timer: 3000,
      })
    })


  }

  async function getAreaAdm() {
    const resp = await api.get(`estruturaPolo/buscaAreaAdm`);
    localStorage.setItem('AreaAdministrativa', JSON.stringify(resp.data.data));
    validPeriod(periodo);
  }

  function currencyFormatter(value) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  function currencyParser(e) {
    if (e == null) return;
    const isNumber = typeof e === 'number';
    let val = '';
    if (isNumber) {
      val = Math.floor(100 * e);
    } else {
      val = e.target.value;
    }

    var campo = val.toString();
    campo = campo.replace(/\D/g, '');
    const valor = parseInt(campo) / 100;
    campo =
      campo.length > 8
        ? campo.replace(/(\d)(\d{3})(\d{5})$/, '$1.$2.$3')
        : campo.replace(/(\d)(\d{5})$/, '$1.$2');
    campo = campo.replace(/(\d)/, 'R$ $1');
    campo = campo.replace(/(\d)(\d{2})$/, '$1,$2');
    search.vlMetaGanho = valor;
    if (isNumber) {
      return campo;
    } else {
      e.target.value = campo;
    }

    return;
  }

  function syncAccount() {
    var obj = {
      idEstPolo: search.id,
      regimeTributario: search.regimeTributario.id,
      vlMetaGanho: search.vlMetaGanho,
    };
    $.ajax({
      type: 'PUT',
      url: `${url.baseUrl}estruturaPolo/sincronizarContas`,
      contentType: 'application/json; charset=utf-8',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      },
      data: JSON.stringify(obj),
      success: function (resp) {
        setReceitasDespesas(resp.EstruturaPoloDespesaPeriodo);
      },
    });
  }

  function appendUser(type) {
    let classification = type == 'AD' ? 'Administrativa' : 'Acadêmica';
    Swal.fire({
      title: `Inclusão de usuário`,
      text: `Incluiremos na lista de usuários da estrutura ${classification} todos os usuários deste Polo, deseja prosseguir?`,
      icon: 'warning',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    })
      .then((result) => {
        if (result.isConfirmed === true) {
          searchUsers(type);
        }
      })
      .catch();
  }

  return (
    <>
      <Helmet
        title={titlePage}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: titlePage + '- Portal Prisma',
          },
          { name: 'keywords', content: 'kroton, educacional, portal, teste' },
        ]}
        key="titleEstrutura"
      />
      <Header />
      <Hero
        key="heroEstrutura"
        pageName="Estrutura Padrão"
        titulo="Estrutura Padrão"
      />
      <section className="section conteudoPortais" key="conteudoEstrutura">
        <Spin spinning={loading}>
          <div className="is-full-fullhd">
            <div className="estrutura-intro">
              <label>Polo: </label>
              <Input
                type="text"
                placeholder="Digite o Polo"
                style={{ width: '25rem' }}
                disabled
                value={polo}
                className="resp-width-100"
              />
            </div>
            <div className="width-100">
              <label className="gutter-row">*</label>
              <label>Periodo: </label>
              <Select
                placeholder="Selecione..."
                id="periodo"
                style={{ width: '13rem' }}
                defaultValue={
                  sectionTimeCourse > 0 ? parseInt(sectionTimeCourse) : ''
                }
                className="resp-width-100"
                size="default"
                onChange={(e) => submitPeriodo(e, poloId)}
              >
                {periodo.map((item) => renderOption(item))}
              </Select>
            </div>
          </div>
          <div className={`margin-1rem-top width-100`}>
            <>
              <Row span={24}>
                <Col span={5}>
                  <label>*Taxa Encargos CLT - ADM:</label>
                  <InputNumber
                    className="ant-input width-15 height-35"
                    min={0}
                    max={100}
                    style={{ width: '3.5rem' }}
                    formatter={(e) => `${e}%`}
                    parser={(e) => e.replace('%', '')}
                    value={search.txEncargoAdm}
                    disabled
                    onChange={(e) => (search.txEncargoAdm = e)}
                  />
                </Col>
                <Col span={5}>
                  <label>*Taxa Encargos CLT - ACAD: </label>
                  <InputNumber
                    className="ant-input width-15 height-35"
                    min={0}
                    max={100}
                    style={{ width: '3.5rem' }}
                    formatter={(e) => `${e}%`}
                    parser={(e) => e.replace('%', '')}
                    disabled
                    value={search.txEncargoAcad > 0 ? search.txEncargoAcad : 0}
                    onChange={(e) => (search.txEncargoAcad = e)}
                  />
                </Col>
                <Col span={6}>
                  <label>*Quer ganhar quanto: </label>
                  <Input
                    id="currency"
                    type="text"
                    className="ant-input width-15 height-35"
                    style={{ width: '8rem' }}
                    value={currencyParser(search.vlMetaGanho)}
                    onChange={(e) => currencyParser(e)}
                    disabled
                    onBlur={(e) => currencyParser(e)}
                  />
                </Col>
                <Col span={6}>
                  <label className="width-100 margin-05rem-left">*</label>
                  <label>Regime Tributário: </label>
                  <Select
                    placeholder="Selecione..."
                    style={{ width: '10rem' }}
                    size="default"
                    disabled
                    value={search.regimeTributario?.id}
                    onChange={(e) => {
                      search.regimeTributario = e;
                    }}
                  >
                    <Option value={1}>Simples Nacional</Option>
                    <Option value={2}>Lucro Presumido</Option>
                    <Option value={3}>Lucro Real</Option>
                  </Select>
                </Col>
              </Row>
              <Row span={24} className="margin-1rem-top">
                <Col span={5}>
                  <label className="width-100">*</label>
                  <label>Tipo de imovel: </label>
                  <Select
                    placeholder="Selecione..."
                    style={{ width: '7rem' }}
                    size="default"
                    disabled
                    value={search.tipoImovel}
                    onChange={(e) => (search.tipoImovel = e)}
                  >
                    <Option value="P">Proprio</Option>
                    <Option value="A">Alugado</Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <label className="width-100 ">*</label>
                  <label>Estrutura Compartilhada?: </label>
                  <Select
                    placeholder="Selecione..."
                    style={{ width: '7rem' }}
                    size="default"
                    disabled
                    value={search.estruturaCompartilhada}
                    onChange={(e) => (search.estruturaCompartilhada = e)}
                  >
                    <Option value="S">Sim</Option>
                    <Option value="N">Não</Option>
                  </Select>
                </Col>
                {isPeriod ? (
                  <Col span={11}>
                    <Button
                      type="primary"
                      size="default"
                      className={`margin-05rem-left resp-margin-1rem-top pull-right ${
                        isPeriod === false ? 'display-none' : ''
                      }`}
                      onClick={() => handleModal()}
                      style={{
                        backgroundColor: '#52c41a',
                        border: 'none',
                      }}
                    >
                      Atualizar Taxas
                    </Button>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </>
          </div>
          <div className="margin-1rem-top width-100">
            {isVisible === true ? (
              <>
                {perfilId == 376 || perfilId == 370 ? (
                  <Button
                    type="primary"
                    size="default"
                    className="margin-1rem-bottom pull-right"
                    onClick={() => endSearch()}
                  >
                    Finalizar Pesquisa
                  </Button>
                ) : (
                  ''
                )}

                <Tabs defaultActiveKey="1" className="width-100">
                  <TabPane tab="Estrutura Administrativa" key="1" id="tableADM">
                    <Button
                      title="Deletar Linha"
                      type="danger"
                      className={isPeriod == false ? 'no-visibility' : ''}
                      onClick={() => deleteListUser()}
                      disabled={listUserDelete.length == 0 ? true : false}
                    >
                      <Icon type="delete" />
                      Remover usuário(s)
                    </Button>
                    <Button
                      type="primary"
                      size="default"
                      className={`pull-right margin-1rem-bottom ${
                        isPeriod === false ? 'display-none' : ''
                      }`}
                      href={`/usuario-estrutura/cadastro/AD/${idEstPolo}`}
                    >
                      Incluir Funcionários
                    </Button>
                    <Button
                      type="primary"
                      size="default"
                      className={`pull-right margin-05rem-right margin-1rem-bottom ${
                        isPeriod === false ? 'display-none' : ''
                      }`}
                      onClick={() => appendUser('AD')}
                    >
                      Buscar Usuário
                    </Button>
                    <Table
                      pagination={pagination}
                      loading={loading}
                      dataSource={userAdm.sort((a, b) => b.id - a.id)}
                      columns={tableColumnsAdmistrativa}
                      rowSelection={rowSelection}
                      size="small"
                      className="margin-2rem-top"
                    />
                  </TabPane>
                  <TabPane tab="Estrutura Acadêmica" key="2" id="tableACAD">
                    <Button
                      title="Deletar Linha"
                      type="danger"
                      onClick={() => deleteListUser()}
                      className={isPeriod == false ? 'no-visibility' : ''}
                      disabled={listUserDelete.length == 0 ? true : false}
                    >
                      <Icon type="delete" />
                      Remover usuário(s)
                    </Button>
                    <Button
                      type="primary"
                      size="default"
                      className={`pull-right margin-1rem-bottom ${
                        isPeriod === false ? 'display-none' : ''
                      }`}
                      href={`/usuario-estrutura/cadastro/AC/${idEstPolo}`}
                    >
                      Incluir Funcionários
                    </Button>
                    <Button
                      type="primary"
                      size="default"
                      className={`pull-right margin-05rem-right margin-1rem-bottom ${
                        isPeriod === false ? 'display-none' : ''
                      }`}
                      onClick={() => appendUser('AC')}
                    >
                      Buscar Usuário
                    </Button>
                    <Table
                      pagination={pagination}
                      loading={loading}
                      dataSource={userAcad.sort((a, b) => b.id - a.id)}
                      columns={tableColumnsAcademica}
                      rowSelection={rowSelection}
                      size="small"
                      className="margin-2rem-top"
                    />
                  </TabPane>
                  <TabPane tab="Receitas e Despesas" key="3" id="tableFees">
                    <div className="margin-1rem-bottom">
                      <Button
                        type="primary"
                        size="default"
                        className={`pull-right margin-1rem-bottom ${
                          isPeriod === false ? 'display-none' : ''
                        }`}
                        onClick={() => syncAccount()}
                      >
                        Sincronizar contas
                      </Button>
                    </div>
                    <div className=" margin-05rem-top"></div>
                    <Table
                      pagination={pagination}
                      loading={loading}
                      dataSource={receitasDespesas}
                      columns={tableColumnsReceitas}
                      size="small"
                      className="margin-2rem-top"
                    />
                  </TabPane>
                  <TabPane tab="Laboratórios" key="4" id="tableLaboratorio">
                    <div className="margin-1rem-bottom">
                      <Button
                        type="primary"
                        size="default"
                        className={`pull-right margin-1rem-bottom ${
                          isPeriod === false ? 'display-none' : ''
                        }`}
                        href={`/usuario-estrutura/laboratorio/${idEstPolo}`}
                      >
                        Novo Cadastro
                      </Button>
                      <Button
                        type="primary"
                        size="default"
                        className="pull-right margin-05rem-right margin-1rem-bottom"
                        onClick={() => exportCSV()}
                      >
                        Gerar CSV
                      </Button>
                    </div>
                    <div className=" margin-05rem-top"></div>
                    <Table
                      pagination={pagination}
                      loading={loading}
                      dataSource={lstLaboratorio}
                      columns={tableColumnsLaboratorio}
                      size="small"
                      className="margin-2rem-top"
                    />
                    <label>CAPE: Aluguel de laboratorio</label>
                  </TabPane>
                </Tabs>
              </>
            ) : (
              <div className="height-100"></div>
            )}
          </div>
        </Spin>
      </section>
      <Footer key="FooterEstrutura" />
      <Modal
        title="Atualizar Taxas"
        visible={isOpen}
        okText={'Incluir taxas'}
        onOk={handleModal}
        onCancel={handleModal}
        destroyOnClose={true}
        width={600}
        centered
      >
        <Row span={24}>
          <Col span={11}>
            <label>*Taxa Encargos CLT - ADM:</label>
            <InputNumber
              className="ant-input width-15 height-35"
              min={0}
              max={100}
              style={{ width: '100%' }}
              formatter={(e) => `${e}%`}
              parser={(e) => e.replace('%', '')}
              defaultValue={search.txEncargoAdm}
              onChange={(e) => (search.txEncargoAdm = e)}
            />
          </Col>
          <Col span={10} className="margin-05rem-left">
            <label>*Taxa Encargos CLT - ACAD: </label>
            <InputNumber
              className="ant-input width-15 height-35"
              min={0}
              max={100}
              style={{ width: '100%' }}
              formatter={(e) => `${e}%`}
              parser={(e) => e.replace('%', '')}
              defaultValue={search.txEncargoAcad > 0 ? search.txEncargoAcad : 0}
              onChange={(e) => (search.txEncargoAcad = e)}
            />
          </Col>
        </Row>
        <Row span={24}>
          <Col span={11}>
            <label>*Quer ganhar quanto: </label>
            <Input
              id="currency"
              type="text"
              className="ant-input width-15 height-35"
              style={{ width: '100%' }}
              defaultValue={currencyParser(search.vlMetaGanho)}
              onChange={(e) => currencyParser(e)}
              onBlur={(e) => currencyParser(e)}
            />
          </Col>
          <Col span={10} className="margin-05rem-left">
            <label className="width-100 margin-05rem-left">*</label>
            <label>Regime Tributário: </label>
            <Select
              placeholder="Selecione..."
              style={{ width: '100%' }}
              size="default"
              defaultValue={search.regimeTributario?.id}
              onChange={(e) => {
                search.regimeTributario = e;
              }}
            >
              <Option value={1}>Simples Nacional</Option>
              <Option value={2}>Lucro Presumido</Option>
              <Option value={3}>Lucro Real</Option>
            </Select>
          </Col>
        </Row>
        <Row span={24} className="margin-1rem-top">
          <Col span={11}>
            <label className="width-100">*</label>
            <label>Tipo de imovel: </label>
            <Select
              placeholder="Selecione..."
              style={{ width: '100%' }}
              size="default"
              defaultValue={search.tipoImovel}
              onChange={(e) => (search.tipoImovel = e)}
            >
              <Option value="P">Proprio</Option>
              <Option value="A">Alugado</Option>
            </Select>
          </Col>
          <Col span={10} className="margin-05rem-left">
            <label className="width-100 ">*</label>
            <label>Estrutura Compartilhada?: </label>
            <Select
              placeholder="Selecione..."
              style={{ width: '100%' }}
              size="default"
              defaultValue={search.estruturaCompartilhada}
              onChange={(e) => (search.estruturaCompartilhada = e)}
            >
              <Option value="S">Sim</Option>
              <Option value="N">Não</Option>
            </Select>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EstruturaPadrao;
