// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_header__3Acm- {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.style_title__3lyYu {\n  margin-right: auto;\n}\n\n.style_action__2hMZU {\n  padding-left: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "style_header__3Acm-",
	"title": "style_title__3lyYu",
	"action": "style_action__2hMZU"
};
module.exports = exports;
