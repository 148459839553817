import api from '../api';

export const directorProfileId = 502;
export const managerProfileId = 501;

export const fetchLinkBusinessManager = async (params) => {
  return await api.get(`/vinculoDn/dn/usuario/${params.id}`);
};

export const deleteLinkBusinessManager = async (params) => {
  return await api.delete(`/vinculoDn/gn/${params.id}`);
};

export const updateLinkBusinessManager = async (params) => {
  return await api.put(`/vinculoDn/dn/${params.id}`, params.body);
};
export const createLinkBusinessManager = async (params) => {
  return await api.post(`/vinculoDn/`, params.body);
};

export const getInit = async () => {
  return await api.get(`/vinculoDn/getGnsDnsNames`)
}

export const getUserAutocomplete = async (params) => {
  return await api.get(
    `/usuario/findByFilters/?page=0&nome=${params.value
      ?.trim()
      ?.toLowerCase()}&perfil=${params.profile}`
  );
};
