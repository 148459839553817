import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { changeUsuarioPessoa, changeUsuarioPortal } from 'store/actions';

function EmailUsuarioPolo({ form, isEdit, validaFuncionarioAzureAD, disabled }) {
  const dispatch = useDispatch();

  let { usuarioPortal } = useSelector(state => state.Usuario);

  const { getFieldDecorator } = form;
  const userData = JSON.parse(localStorage.getItem('userDataCRP'));
  const funcionario = userData.usuarioPortal.funcionario;
  return (
    <Form.Item size="large" label="E-mail">
        {getFieldDecorator('email', {
          initialValue: usuarioPortal.pessoa.email,
          rules: [
            {
              type: 'email',
              message: 'Por favor insira um e-mail valido',
            },
            {
              validator: validaFuncionarioAzureAD,
            },
            {
              required: funcionario === 'S' ? true : false,
              message: 'Por favor insira um e-mail válido',
            },
          ],
        })(
          <Input
              placeholder="Email"
              disabled={funcionario === 'N' || isEdit}
              size="large"
              onChange={e => {
                if (funcionario === 'S') {
                  dispatch(
                    changeUsuarioPessoa({
                      prop: 'email',
                      value: e.target.value.trim(),
                    })
                  );

                  if (!isEdit) {
                    dispatch(
                      changeUsuarioPortal({
                        prop: 'conta',
                        value: e.target.value,
                      })
                    );
                  }
                }
              }}
            />
        )}
      </Form.Item>
  );
}

EmailUsuarioPolo.propTypes = {
  form: PropTypes.object,
  isEdit: PropTypes.bool,
  validaFuncionarioAzureAD: PropTypes.func,
};

export default EmailUsuarioPolo;
