import api from '../api';
import Swal from 'sweetalert2';

const createImport = async (params) => {
  let apiResponse;
  try {
    apiResponse = await api.post(`/importacao/usuario/upload`, params.body);

  }
   catch (error) {
    
    Swal.fire({
      text: error.response.data.error,
    });


  }
  return apiResponse.status

};


const downloadImport = async (params) => {
  return await api.get(`/importacao/download`, params.body);
};

const cancelImport = async (params) => {
  return await api.put(`/importacao/usuario/cancelar/${params.id}`);
};

const getImports = async (params) => {
  return await api.get(`/importacao/usuario/filter`, { params: { ...params } });
};

const getProfiles = async (params) => {
  const poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
  const userData = JSON.parse(localStorage.getItem('userDataCRP'));
  return await api.get(
    `usuario/cadastrarUsuario/${userData.usuarioPortal.id}` +
      `${poloId > 0 ? `?poloId=${poloId}` : ''}`
  );
};

const getSectorByProfile = async (params) => {
  return await api.get(`/setor/funcao/perfil/${params.id}`);
};

export {
  createImport,
  downloadImport,
  getImports,
  cancelImport,
  getProfiles,
  getSectorByProfile,
};
