import React, { Component } from 'reactn';
import {
  Header,
  Footer,
  Helmet,
  BannerHome,
  ComunicadosCarousel,
  axios,
  $
} from '../../services/Gerais';
import {
  Input,
  Modal,
  Form,
  Col,
  Row,
  Button
} from 'antd';
import 'antd/dist/antd.css';
import Countdown from 'antd/lib/statistic/Countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

import ComunicadosCarouselDiversos from '../../components/ComunicadosCarousel/ComunicadosCarouselDiversos';
import ComunicadosCarouselDestaque from '../../components/ComunicadosCarousel/ComunicadosCarouselDestaque';
import BotaoCapu from '../../components/BotaoCapu';
import { telefoneMask } from '../../utils/masks';
import LastNews from '../../components/LastNews/lastNews';
import api from 'services/api';

import './../../styles/Styles.scss';
import './../../styles/ComunicadosBusca.scss';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isActive: false,
      modalAtiva: false,
      isTourOpen: false,
      isShowingMore: false,
      isButtonDisabled: true,
      showValidationCode: false,
      carregando: true,
      banners: [],
      comunicados: [],
      mensagensImportantes: [],
      emailSecundario: '',
      telefone: '',
      ddd: '',
      codigoValido: '',
      timer: 5,
      verificado: '',
      funcionario: ''
    };

    this.handleChangeTelefone = this.handleChangeTelefone.bind(this);
    this.limparCampos = this.limparCampos.bind(this);
    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));

    this.labelCaption = (
      <div className="label-box-announcements">
        <div className="border-box-announcements" data-tut="legenda_comunicados">
          <div className="box-label-announcements">
            <div className="box-announcements critical" />
            <p>CRÍTICO</p>
          </div>
          <div className="box-label-announcements">
            <div className="box-announcements action" />
            <p>AÇÃO</p>
          </div>
          <div className="box-label-announcements">
            <div className="box-announcements info" />
            <p>INFORMAÇÃO</p>
          </div>
          <div className="box-label-announcements">
            <div className="box-announcements reinforcement" />
            <p>REFORÇO</p>
          </div>
        </div>
      </div>
    );
  }

  efetuaBusca(e) {
    e.preventDefault();

    let queryParam = `?nomeComunicado=${document
      .getElementById('nomeComunicado')
      .value.trim()}&buscaPadrao=false&asc=false`;

    window.location.assign('../busca/comunicados' + queryParam);
  }

  componentDidMount() {
    Promise.all([
      localStorage.getItem('userDataCRP'),
      localStorage.getItem('userDataCRP-Polo'),
    ]).then((values) => {
      this.logInfo = JSON.parse(values[0]);
      this.poloId = JSON.parse(values[1]);
    });

    axios
    .get(
      this.global.baseUrl +
        'comunicado/getComunicadoUsuario?id=' +
        this.logInfo['id'] +
        '&page=0&linesPerPage=8' +
        (this.poloId > 0 ? `&poloId=${this.poloId}` : ''),
      {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      }
    )
    .then((result) =>
      this.setState({
        comunicados: result.data,
      })
    );

  axios
    .get(
      this.global.baseUrl +
        'comunicado/getComunicadoUsuario?id=' +
        this.logInfo['id'] +
        '&page=0&linesPerPage=5&tipoComunicado=M' +
        (this.poloId > 0 ? `&poloId=${this.poloId}` : ''),
      {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      }
    )
    .then((resultado) =>
      this.setState({
        mensagensImportantes: resultado.data,
      })
    );

    api.get(`usuario/getUsuario/${this.logInfo.usuarioPortal.id}`)
      .then((result) => {
        let newState = {
            telefone: Number(result.data.Usuario.usuarioPortal.pessoa.telefone),
            ddd: Number(result.data.Usuario.usuarioPortal.pessoa.ddd),
            verificado: result.data.Usuario.usuarioPortal.verificado,
            funcionario: result.data.Usuario.usuarioPortal.funcionario
        };

        if (result.data.Usuario.usuarioPortal.emailSecundario !== null) {
            newState.emailSecundario = String(result.data.Usuario.usuarioPortal.emailSecundario);
        }

        this.setState(newState);
        this.setNavbarZIndex();
    });
  }

  handleChangeEmailSecundario = (e) => {
    this.setState({ emailSecundario: e.target.value });
  };

  handleChangeCodigoValido = (e) => {
    this.setState({
        codigoValido: e.target.value,
        timer: 0
    });
};

  handleChangeTelefone = (e) => {
    const telefoneSemMascara = telefoneMask(e.target.value).replace(/\D/g, '');
    this.setState({
      telefone: telefoneSemMascara.substring(2, telefoneSemMascara.length),
      ddd: telefoneSemMascara.substring(0, 2),
    });
  };

  validSecundaryEmail = (rule, value, callback) => {
    try {
      if (value == this.logInfo.usuarioPortal.conta) {
        throw new Error(' ');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  startTimer = () => {
    this.myInterval = setInterval(() => {
      if (this.state.timer > 0) {
          this.setState(prevState => ({
              timer: prevState.timer - 1
          }));
      } else {
          clearInterval(this.myInterval);
          this.setState({ isButtonDisabled: false });
      }
    }, 1000);
}

  setNavbarZIndex = () => {
    const navbar = document.getElementById('navbar');

    if (this.state.verificado === 'N') {
      navbar.style.setProperty('z-index', '1000', 'important');
    } else if (this.state.verificado === 'S') {
      navbar.style.setProperty('z-index', '2000', 'important');
    }
  }

  gerarCodigo = async () => {
    const data = {
      emailSecundario: this.state.emailSecundario,
      ddd: this.state.ddd,
      telefone: this.state.telefone,
      usuarioPortalId: this.logInfo.usuarioPortal.id
    }

    try {
      const response = await api.post('usuario/sendValidationCode', data);

      if (response.status === 200 && response.data.includes('Email não permitido como email secundário')) {
        return Swal.fire({
          icon: 'error',
          title: 'E-mail secundário inválido',
          text: 'Email não permitido como email secundário, utilize um email pessoal, por exemplo: gmail, hotmail etc...',
        });
      } else if (response.status === 200 && response.data.includes('Código de verificação enviado com sucesso!')) {
        this.setState({ showValidationCode: true, isButtonDisabled: true });

        return Swal.fire({
          icon: 'success',
          title: 'Código enviado',
          text: `Enviamos um código de verificação para o e-mail (${this.state.emailSecundario}). Gentileza preencher e confirmar o código recebido`,
        }).then(() => {
          this.startTimer();
        });
      } else if (response.status === 200 && response.data.includes('Email secundário já cadastrado!')) {
        return Swal.fire({
          icon: 'error',
          title: 'E-mail secundário já cadastrado',
          text: 'E-mail secundário já cadastrado, favor utilizar um outro e-mail como secundário',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  onResendCodeClick = async () => {
    this.setState({ isButtonDisabled: true, timer: 120 });
    await this.gerarCodigo();
  };

  validarCodigo = () => {
    api.patch(`usuario/validateCode/${this.logInfo.usuarioPortal.id}/${this.state.codigoValido}`)
      .then(response => {
        if (response.status === 200 && response.data === 'Codigo validado com sucesso.') {
          Swal.fire({
            icon: 'success',
            title: 'Código validado com sucesso',
            text: 'Seu código foi validado com sucesso!',
          });
          this.setState({ verificado: 'S' });
        } else if (response.status === 200 && response.data === 'Código inválido.') {
          Swal.fire({
            icon: 'error',
            title: 'Código inválido',
            text: 'Enviamos um código de verificação para o e-mail informado acima. Gentileza preencher e confirmar o código recebido',
          });
        }
      })
  };

  limparCampos = () => {
    this.setState({
      emailSecundario: '',
      codigoValido: 'N',
      showValidationCode: false
    });

    this.props.form.resetFields('emailSecundario');

    this.props.form.setFields({
      emailSecundario: {
        value: '',
        errors: [new Error('Por favor insira um e-mail válido')]
      }
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    let perfilId = localStorage.getItem('userDataCRP-Perfil');

    const isOpenModal = perfilId !== '376' && this.state.verificado === 'N' && this.state.funcionario === 'N' ? true : false

    const telefoneInit = this.state.ddd
      ? telefoneMask(
        this.state.ddd
            .toString()
            .concat(this.state.telefone.toString())
        )
      : '';

    return (
      <>
        <Helmet
          key="TitleLogin"
          title="Bem vindo! | Portal Prisma - Kroton"
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: 'Portal Unificado da Kroton Educacional',
            },
            { name: 'keywords', content: 'kroton, educacional, portal' },
          ]}
        />
        <Header key="HeaderHome" />
        <BannerHome key="BannerHome" />
        {this.logInfo['id'] == null ? (
          ''
        ) : (
          <LastNews
            id={this.logInfo['id']}
            comunicados={this.state.comunicados}
          />
        )}
        <div
          className="buscaHomeContainer container-header is-fullhd"
          style={{ marginBottom: '1rem' }}
        >
          <div className="columns is-multiline">
            <div className="buscaHome column is-8 is-offset-2 padding-container">
              <input
                type="text"
                id="nomeComunicado"
                placeholder="Busca de comunicados"
              ></input>
              <a href="" onClick={this.efetuaBusca}>
                <FontAwesomeIcon
                  icon={['fas', 'search']}
                  className="fas fa-search"
                  color="#FFFFFF"
                />
              </a>
            </div>
          </div>
        </div>
        {/*({perfilId == 500 || perfilId == 501 ||perfilId == 502 ?
          <ComunicadosCarouselDestaque key="ComunicadosCarouselDestaque" /> : ''
        })*/}
        <ComunicadosCarouselDestaque
          key="ComunicadosCarouselDestaque"
          comunicados={this.state.comunicados}
        />
        <ComunicadosCarouselDiversos
          id="2"
          categoria="Mensagens Importantes"
          key="Mensagens Importantes"
          comunicados={this.state.mensagensImportantes}
        />
        {this.state.mensagensImportantes.PosGraduacao &&
        this.state.mensagensImportantes.PosGraduacao.Comunicados.length > 0 ? (
          <ComunicadosCarouselDiversos
            id="ComunicadoPos"
            categoria="Comunicados Pós Graduação"
            key="Comunicados Pós Graduação"
            comunicados={this.state.mensagensImportantes}
          />
        ) : (
          ''
        )}
        <ComunicadosCarousel
          key="ComunicadosCarousel"
          comunicados={this.state.comunicados}
        />
        {this.labelCaption}
        <hr className="hrEspacamento" key="hrEspacamentoHome" />{' '}
        <div
          className={this.state.modalAtiva ? 'modal is-active' : 'modal'}
          key="modalSelecaoPortal"
          onLoad={this.isTourOpen}
        >
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Selecione um polo inicial:</p>
            </header>
            <section className="modal-card-body">
              <div className="field">
                <div className="control">
                  <div className="select is-info is-fullwidth">
                    <select name="" id="" onChange={this.onChangePolo}>
                      <option value="0">Selecionar</option>
                      <option value="1">Polo 01</option>
                      <option value="2">Polo 02</option>
                      <option value="3">Polo 03</option>
                      <option value="4">Polo 04</option>
                    </select>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Modal
          title={<p className='ant-modal-title'>Você possui pendências cadastrais!</p>}
          visible={isOpenModal}
          width={1000}
          centered
          footer={null}
          closable={false}
          style={{ zIndex: 2000}}

        >
          <p style={{marginBottom: '10px'}}><strong>Para continuar utilizando o Prisma, é necessário o preenchimento e validação dos dados abaixo:</strong></p>
          <p style={{marginBottom: '0px'}}>Forneça os seguintes dados para futuras recuperações de conta:</p>
          <p style={{marginBottom: '0px'}}><strong>E-mail secundário</strong>: Diferente do e-mail utilizado para acessar o Prisma;</p>
          <p style={{marginBottom: '10px'}}><strong>Telefone celular:</strong> <strong>DDD(Não utilizar o 0) + Telefone (9 Dígitos).</strong> <strong>Exemplo:</strong> 11xxxxxxxxx;</p>
          <p style={{marginBottom: '10px'}}><strong style={{color: '#FF0000'}}>ATENÇÃO: O preenchimento incorreto dos dados mencionados acarretará em bloqueio de acesso ao Prisma caso sua senha seja esquecida.</strong></p>

          <Row xl={24}>
            <Col xl={10} className="gutter-row margin-1-left">
              <Form.Item label="E-mail Secundário" size="large">
                {getFieldDecorator('emailSecundario', {
                  initialValue: this.state.emailSecundario,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor insira um e-mail válido',
                      type: 'email',
                    },
                    {
                      validator: this.validSecundaryEmail,
                      message: 'Por favor insira um e-mail diferente da conta',
                      type: 'email',
                    },
                  ],
                })(
                  <Input
                    placeholder="E-mail"
                    size="large"
                    onChange={this.handleChangeEmailSecundario}
                    disabled={this.state.showValidationCode}
                  />
                )}
              </Form.Item>
            </Col>

            <Col xl={10} className="gutter-row margin-1-left">
              <Form.Item label="Telefone" size="large">
                {getFieldDecorator('telefone', {
                  initialValue: telefoneInit,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor insira um número de telefone válido',
                    },
                  ],
                })(
                  <Input
                    placeholder="Telefone"
                    size="large"
                    onChange={this.handleChangeTelefone}
                    disabled={this.state.showValidationCode}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <div>
            <Button
            type="primary"
            onClick={this.gerarCodigo}
            size="large"
            className="bg-color-yellow margin-1-left"
          >
            Confirmar
          </Button>

          <Button
            type="primary"
            onClick={this.limparCampos}
            size="large"
            className="bg-color-yellow margin-1-left"
            disabled={!this.state.showValidationCode}
          >
            Limpar
          </Button>
        </div>

        {this.state.showValidationCode && (
          <>
            <Row xl={24} style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
              <Col xl={9} className="gutter-row margin-1-left">
                <Form.Item label="Codigo de Validação" size="large">
                  {getFieldDecorator('codigoValidacao', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Insira o código de validação recebido no seu e-mail',
                      },
                    ],
                  })(
                    <Input
                      placeholder="Codigo de Validação"
                      size="large"
                      onChange={this.handleChangeCodigoValido}
                      width={50}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col xl={4} style={{marginTop: '0.7rem'}} className="gutter-row margin-1-left">
                <Button
                  type="primary"
                  onClick={this.onResendCodeClick}
                  size="large"
                  className="bg-color-yellow margin-1-left"
                  disabled={this.state.isButtonDisabled}
                >
                  Reenviar Código
                </Button>
              </Col>

              <Col xl={2} className="gutter-row margin-1-left">
                <Countdown key={this.state.timer} title="Tempo Restante" value={Date.now() + this.state.timer * 1000} format="mm:ss" />
              </Col>
            </Row>

            <Button
              type="primary"
              onClick={this.validarCodigo}
              size="large"
              className="bg-color-yellow margin-1-left"
            >
              Confirmar
            </Button>
          </>
          )
        }
        </Modal>
        <Footer key="FooterHome" />
        {perfilId !== 504 ? <BotaoCapu key="BotaoCapuHome" /> : ''}
      </>
    );
  }
}

export default Form.create()(Home);
