import React, { Component } from "reactn";
import { Redirect } from "react-router-dom";
import "../../styles/Styles.scss";
import avatar from '../../img/loadingtwo.gif';
// import { Helmet, HeaderAdmin, FooterAdmin, Hero, Error, MenuAdmin, FontAwesomeIcon, library, axios } from "../../services/Gerais";
import { Helmet, HeaderAdmin, FooterAdmin, Hero, Error, MenuAdmin, FontAwesomeIcon, library } from "../../services/Gerais";
import { faUser, faCheck, faBan, faMapMarkedAlt, faExclamationTriangle, faUsers, faCoins, faIdCard, faExpand, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import PersonifiedBanner from "../PersonifiedBanner";
library.add({ faUser, faCheck, faBan, faMapMarkedAlt, faExclamationTriangle, faUsers, faCoins, faIdCard, faExpand, faNewspaper, faCalendarAlt })

class AdministracaoIndex extends Component {

    constructor(props){
        super(props);
        this.state = {
            comunicadosAdmin: [], agendamentos: [], usuarios: [], isLoadingAdmin: true, errorAdmin: [],
        };
        this.logInfoAdmin        = JSON.parse(localStorage.getItem('userDataCRP'));
    }

    componentWillMount() {
        this.setState({ isLoadingAdmin: false, errorAdmin: null });
        // this.setState({ isLoadingAdmin: true });

        // axios.all([
        //     axios.get(this.global.baseUrl+'comunicado/', {
        //         headers: { 'Authorization': 'Bearer '+ localStorage.getItem('userDataCRP-AuthTk') }
        //     }),
        //     axios.get(this.global.baseUrl+'agenda/', {
        //         headers: { 'Authorization': 'Bearer '+ localStorage.getItem('userDataCRP-AuthTk') }
        //     }),
        //     axios.get(this.global.baseUrl+'usuario/listUsuario/10', {
        //         headers: { 'Authorization': 'Bearer '+ localStorage.getItem('userDataCRP-AuthTk') }
        //     })
        // ]).then(axios.spread((comunRes,agendaRes,usuarioRes) => {
        //     this.setState({
        //         comunicadosAdmin: comunRes.data, agendamentos: agendaRes.data,
        //         usuarios: usuarioRes.data, isLoadingAdmin: false
        //     })
        // })).catch(error => this.setState({
        //     errorAdmin: error, isLoadingAdmin: false
        // }));
    }

    render() {
        const { comunicadosAdmin, agendamentos, usuarios, isLoadingAdmin, errorAdmin } = this.state;
        if(this.logInfoAdmin === null){ return (<Redirect key="admin-redirect-key" to={'/login'}/>) }
        if(isLoadingAdmin){ return ( <div className="CRP-loading"><img src={avatar} width="100" alt="Carregando..." /></div>)}
        return [
            <Helmet meta={[
                { name: 'description', content: 'Painel Administrativo | Dashboard - Portal Prisma' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'keywords', content: 'kroton, educacional, portal, painel administrativo, dashboard' },
            ]} key="TitleAdminHome" title="Admin | Portal Prisma - Kroton" />,
            <PersonifiedBanner />,
            <HeaderAdmin key="HeaderAdmin" />,
            <section key="conteudoAdmin" className="columns is-multiline conteudoAdmin" id="admin-app">
                <MenuAdmin key="menuKeyAdmin" />
                <div className="column is-10 is-fullheight conteudoAdminPagina">
                    <section className="columns is-multiline">
                        <div className="column">
                            <Hero key="heroAdminIndex" pageName="Administração" titulo="Administração" />
                        </div>
                        <div className="column has-text-right">
                            <h3 className="intro-admin">Seja bem vindo, <strong>{this.logInfoAdmin.usuarioPortal.pessoa['nome']}</strong>!</h3>
                        </div>
                    </section>
                    <hr />
                    { errorAdmin ? <Error {...errorAdmin} /> : null }
                    <section className="columns is-multiline">
                        <div className="column">
                            <section className="columns is-multiline">
                                <div className="column is-one-third">
                                    <strong>STATUS ATUAL</strong>
                                    <p>Gráfico de desenvolvimento</p>
                                </div>
                                <div className="column">
                                    <progress className="progress is-primary" value="50" max="100">50%</progress>
                                </div>
                            </section>
                            <hr />
                            <section className="columns is-multiline">
                                <div className="column is-half">
                                    <nav className="panel">
                                        <p className="panel-heading">Informações gerais</p>
                                        <span className="panel-block">
                                            <table className="table is-fullwidth">
                                                <tbody>
                                                    <tr>
                                                        <td><span className="icon"><FontAwesomeIcon icon={["far", "calendar-alt"]}/></span> Agenda</td>
                                                        <td><span className="icon has-text-warning"><FontAwesomeIcon icon={["fas", "exclamation-triangle"]}/></span> Desenvolvimento</td>
                                                        <td className="has-text-centered">({Object.keys(agendamentos).length} cadastros)</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="icon"><FontAwesomeIcon icon={["fas", "newspaper"]}/></span> Comunicados</td>
                                                        <td><span className="icon has-text-success"><FontAwesomeIcon icon={["fas", "check"]}/></span> Finalizado</td>
                                                        <td className="has-text-centered">({Object.keys(comunicadosAdmin).length} cadastros)</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="icon"><FontAwesomeIcon icon={["far", "user"]}/></span> Usuários</td>
                                                        <td><span className="icon has-text-success"><FontAwesomeIcon icon={["fas", "check"]}/></span> Finalizado</td>
                                                        <td className="has-text-centered">({Object.keys(usuarios).length} cadastros)</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="icon"><FontAwesomeIcon icon={["fas", "map-marked-alt"]}/></span> Mapa de operações</td>
                                                        <td><span className="icon has-text-warning"><FontAwesomeIcon icon={["fas", "exclamation-triangle"]}/></span> Desenvolvimento</td>
                                                        <td className="has-text-centered">-</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                    </nav>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </section>,
            <FooterAdmin key="FooterAdmin" />
        ];
    }
}

export default AdministracaoIndex;
