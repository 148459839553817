import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader, Icon, Button, Typography, Form } from 'antd';

import EditableTable from './EditableTable';
import Content from '../../utils/Content';
import Marca from './SectionMarca';
import UploadPolos from './UploadPolos';

import { changeComunicado } from 'store/actions/Administrativo/Comunicado/ComunicadoActions';

const { Paragraph } = Typography;

const ComunicadoTipos = ({ form }) => {
  const { setFieldsValue } = form;
  let { DadosComunicado, isEdit } = useSelector(state => state.Comunicado);

  const tipo = DadosComunicado.tipoComunicado;
  const dispatch = useDispatch();

  const content = (
    <div className="content">
      {isEdit ? '' :
      <Paragraph>
      Selecione qual marca poderá visualizar este comunicado, ou envie um
      arquivo CSV para carregar os polos que poderão visualizar o comunicado
    </Paragraph>}

    </div>
  );

  const ButtonTipo = ({ arrButtons = [] }) => {
    return arrButtons.map(btn => {
      return (
        <Button
          key={btn.value}
          type={btn.value === tipo && 'primary'}
          onClick={() => {
            if (btn.value === 'MARCA') {
              setFieldsValue({ lotePorPolo: [{}] });
            } else {
              setFieldsValue({ marcas: [{}] });
            }
            dispatch(
              changeComunicado({
                prop: 'DadosComunicado',
                value: {
                  ...DadosComunicado,
                  tipoComunicado: btn.value,
                  lstComunicadoMarca: [],
                  lstComunicadoPolo: [],
                },
              })
            );
          }}
          icon={btn.icon}
          size="large"
        >
          {' '}
          {btn.text}{' '}
        </Button>
      );
    });
  };

  return (
    <>
      {isEdit ? (
        <PageHeader
          title="Marcas"
          style={{
            border: '1px solid rgb(235, 237, 240)',
          }}
          avatar={{
            icon: <Icon type="wechat" />,
            style: { backgroundColor: '#87d068' },
          }}
        >
          <Content>
            {content}
            {tipo === 'LOTE' ? (
              <EditableTable form={form} />
            ) : (
              <Marca form={form} />
            )}
          </Content>
        </PageHeader>
      ) : (
        <PageHeader
          title="Marca"
          style={{
            border: '1px solid rgb(235, 237, 240)',
          }}
          extra={[
            <ButtonTipo
              arrButtons={[
                { text: 'Por marca', value: 'MARCA', icon: 'shop' },
                {
                  text: 'Em lote segmentado',
                  value: 'LOTE',
                  icon: 'cloud-upload',
                },
              ]}
            />,
          ]}
          avatar={{
            icon: <Icon type="wechat" />,
            style: { backgroundColor: '#87d068' },
          }}
        >
          <Content
            extraContent={tipo === 'LOTE' && <UploadPolos form={form} />}
          >
            {content}
            {tipo === 'LOTE' ? (
              <EditableTable form={form} />
            ) : (
              <Marca form={form} />
            )}
          </Content>
        </PageHeader>
      )}
    </>
  );
};

export default Form.create()(ComunicadoTipos);
