import api from '../../services/api';

export const list = async () => {
  try {
    const res = await api.get(`documento/contrato/basePolo/filter`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const item = async (id) => {
  try {
    const res = await api.get(`documento/polo/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const importPolos = async (params) => {
  return await api.post(`/documento/contrato/basePolo/`, params);
};

export const findByFilters = async (params) => {
  const cleanParams = {
    nomePolo: params.polo || undefined,
    poloId: params.poloId || undefined,
  };
  return await api.get(`documento/contrato/basePolo/filter`, {
    params: cleanParams,
  });
};
