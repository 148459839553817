import { listPortais, findByFilters} from "../../service";

export class PortaisTableController {

 constructor(props){
    this.state = props.state;
 }

 async hadleInitData(){
    const result = await listPortais()
    this.state.data.setData(result)
  }
  async handleSubmit(e) {
    try {
        console.log(e)
        const result = await findByFilters(e)
        this.state.data.setData(result)
        console.log(result)
    } catch (error) {

    }
  }
}
