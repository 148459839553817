import React, {useState, useEffect} from 'react';
import { Header, Hero, Helmet } from 'services/Gerais';
import { Container } from './styles';
import UsuarioPreCadastroForm from 'components/UsuarioPreCadastro';

const UsuarioPrecadastro = () => {

  return (
    <>
      <Helmet
        title="Cadastro de Usuário - Portal Prisma - Kroton"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: 'Cadastro de Usuário - Portal Unificado da Kroton Educacional',
          },
          { name: 'keywords', content: 'agenda, kroton, educacional, portal' },
        ]}
        key="titleAgenda"
      />
      <section className="section conteudoPortais" key="conteudoPortais">
        <Container>
          <UsuarioPreCadastroForm />
        </Container>
      </section>
    </>
  );
};

export default UsuarioPrecadastro;
