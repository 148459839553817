import React, { useState } from 'react';
import { fetchProfiles, getProfileById, saveProfiles, updateProfiles } from 'services/accessProfiles';
import { Button, Tag } from 'antd';
import { FontAwesomeIcon } from 'services/Gerais'

const useAccessProfiles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [perfilAux, setPerfilAux] = useState({usuarioPortalLog: JSON.parse(localStorage.getItem('userDataCRP')).usuarioPortal});

  const toggle = () => setIsLoading((state) => !state);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'perfil.id',
      key: 'perfil.id',
    },
    {
      title: 'Perfil',
      dataIndex: 'perfil.descricao',
      key: 'perfil.descricao',
    },
    {
      title: 'Habilita polo',
      dataIndex: 'perfil.habilitaPolo',
      key: 'perfil.habilitaPolo',
      render: (habilitaPolo) => (habilitaPolo === 'S' ? 'Sim' : 'Não'),
    },
    {
      title: 'Ultima Alteração',
      dataIndex: 'perfil.lastUpdated',
      key: 'perfil.habilitaPolo',
    },
    {
      title: 'Status',
      dataIndex: 'perfil.status',
      key: 'perfil.status',
      render: (status) => {
        if (status !== null) {
          const objTag =
            status === 'S'
              ? { tag: 'Sim', color: '#87d068' }
              : { tag: 'Não', color: 'volcano' };
          return (
            <span>
              <Tag color={objTag.color} key={objTag.tag}>
                {objTag.tag.toUpperCase()}
              </Tag>
            </span>
          );
        }
      }
    },
     {
       title: 'Ação',
       render: (profile) => {
         return (
           <Button
             key={`edit_${profile.id}`}
             onClick={() => handleModal(profile.id)}
             type="dashed"
             className="button is-small is-warning"
             size="small"
           >
             <FontAwesomeIcon icon={['fas', 'user-cog']} /> &nbsp; Editar perfil
           </Button>
         );
       },
     },
  ];

  async function handleModal(id) {
    if(typeof id == 'number') {
      await handleGetProfileById(id)
    }

    if(isActive) {
      setIsActive(false);
      setPerfilAux({usuarioPortalLog: JSON.parse(localStorage.getItem('userDataCRP')).usuarioPortal})
    } else {
      setIsActive(true);
    }
  }

  const getProfiles = async () => {
    try {
      toggle();
      const { status, data } = await fetchProfiles();
      if (status === 200) {
        setDataSource(data);
      }
      toggle();
    } catch (error) {
      toggle();
      return error;
    }
  };

  async function handleSaveProfile(profile) {
    await saveProfiles(profile);
  }

  async function handleUpdateProfile(profile) {
    await updateProfiles(profile);
  }

  async function handleGetProfileById(id) {
    const resp = await getProfileById(id);
    setPerfilAux(resp.data);
  }

  return {
    columns,
    dataSource,
    isLoading,
    isActive,
    perfilAux,
    getProfiles,
    handleModal,
    handleSaveProfile,
    handleGetProfileById,
    handleUpdateProfile
  };
};

export { useAccessProfiles };
