import api from 'services/api';
import Swal from 'sweetalert2';

export const sendPreRegistration = (user) => {
  api
    .post('usuario/saveUsuarioPreCadastro', user)
    .then((response) => {
      if (response.status === 200) {
        Swal.fire({
          position: 'top-end',
          title: 'Acesso liberado com sucesso!',
          text: 'Refaça o login para acessar o sistema.',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000,
        });
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      } else if (response.status === 202) {
        Swal.fire({
          position: 'top-end',
          title: 'Revisar dados!',
          text: response.data.error,
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        const errorObject = error.response?.data;
        const errorMessage = errorObject.error || 'Ocorreu um erro ao cadastrar o usuário.';        Swal.fire({
          position: 'top-end',
          title: errorMessage,
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
};

