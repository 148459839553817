import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  justify-content: ${(props) => (props.space ? 'space-between' : 'flex-end')};
  align-items: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
`;
