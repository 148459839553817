import React, { useEffect, useRef } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import {
  Row,
  Col,
  Button,
  Input,
} from 'antd';
import { Header, Footer, Hero, Helmet } from 'services/Gerais';
import logoKRTN from 'img/logomarca-kroton.png';
import { useState } from 'react';
import { moment } from 'services/Gerais';
import { getCheckinById, sendEmail } from 'services/foca/FocaService';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';

const colors = {
  DarkBlue: '#0D3749',
  MediumBlue: '#79A1B8',
  LightBlue: '#D6C3FF',
};



function TemplateFoca(props) {
  const [checkin, setCheckin] = useState({
    agenda: null,
    ativo: null,
    checkinPertenceAoUsuarioLogado: null,
    checkinPolos: [],
    dataAlteracao: null,
    dataInclusao: null,
    dataVisita: null,
    ganho: null,
    id: null,
    idUsuarioLogado: null,
    liberarPdf: null,
    participantes: null,
    planosDeAcao: [],
    polosIds: null,
    resumo: null,
    ritual: null,
    status: null,
    tempoVisita: null,
    tipoVisita: null,
    usuarioAlteracao: null,
    usuarioInclusao: { pessoa: {} },
  });
  const [idCheckin, setIdCheckin] = useState(props.idCheckin == undefined ? window.location.href.substr(window.location.href.lastIndexOf('/') + 1) : props.idCheckin);
  const [plansAction, setPlansAction] = useState('');
  const [renderPDFPage, setRenderPDFPage] = useState('');
  const [showScreen, setShowScreen] = useState(true);
  const [titlePage, setTitlePage] = useState('Envio de plano de ação')
  const [dateGeneneration, setDateGeneneration] = useState(moment(new Date()));
  const pdfRef = useRef(null);
  let fileBlob = ''
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      color: 'white',
    },
    header: {
      backgroundColor: colors.DarkBlue,
      height: '20%',
      color: colors.LightBlue,
      flexDirection: 'row',
      width: 'auto',
    },
    headerInfo: {
      flexDirection: 'column',
      width: '60%',
    },
    headerImg: {
      flex: 1,
      backgroundColor: colors.MediumBlue,
    },
    headerVisita: {
      marginTop: '15%',
      marginLeft: '10%',
    },
    headerFoca: {
      marginTop: '2%',
      marginLeft: '10%',
      fontWeight: 'bold',
    },
    headerDate: {
      marginTop: '2%',
      marginLeft: '10%',
    },
    headerLogo: {
      height: '30%',
      width: '60%',
      margin: 'auto',
      objectFit: 'cover',
    },
    infoVisit: {
      flexDirection: 'row',
      flex: 9,
      margin: 20,
      padding: 10,
      border: `1px solid ${colors.LightBlue}`,
      height: '2rem',
    },
    infoVisitTitle: {
      backgroundColor: colors.DarkBlue,
      marginTop: '3rem',
      marginLeft: '5rem',
      color: colors.LightBlue,
      width: '20%',
      textAlign: 'center',
    },
    infoVisitValue: {
      backgroundColor: colors.LightBlue,
      color: colors.DarkBlue,
      paddingVertical: 10,
      paddingLeft: 20,
    },
    viewer: {
      width: (props.isSendEmail || props.isSendEmail != undefined ? 900 : window.innerWidth),
      height: (props.isSendEmail || props.isSendEmail != undefined ? 350 : window.innerHeight),
    },
    section: {
      margin: 0,
      padding: 0,
      flexGrow: 1,
      backgroundColor: colors.LightBlue,
      height: '100%',
    },
    tableHeightMax: {
      height: '100px',
    },
    table: {
      display: 'table',
      width: 'auto',
      margin: '0',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableRowPlanAction: {
      flexDirection: 'row',
    },
    tableCell: {
      margin: '0 0 1px 0',
      padding: 5,
      fontSize: 11,
      width: '180px',
      height: '30px',
      backgroundColor: colors.DarkBlue,
      color: colors.LightBlue,
      verticalAlign: 'middle',
      display: 'flex',
    },
    tableCellHeightMax: {
      margin: '0 0 1px 0',
      padding: 5,
      fontSize: 11,
      width: '180px',
      backgroundColor: colors.DarkBlue,
      color: colors.LightBlue,
      verticalAlign: 'middle',
      display: 'flex',
      height: '144px',
    },
    tableCellValueDark: {
      margin: '0 0 1px 0',
      padding: 5,
      fontSize: 11,
      flex: 1,
      display: 'flex',
      color: colors.DarkBlue,
      backgroundColor: colors.MediumBlue,
    },
    tableCellValueDarkMax: {
      margin: '0 0 1px 0',
      padding: 5,
      fontSize: 11,
      flex: 1,
      display: 'flex',
      color: colors.DarkBlue,
      backgroundColor: colors.MediumBlue,
      height: '144px',
    },
    tableCellValue: {
      margin: '0 0 1px 0',
      padding: 5,
      fontSize: 11,
      flex: 1,
      display: 'flex',
      color: colors.DarkBlue,
      backgroundColor: colors.MediumBlue,
    },
    tablePlanAction: {
      display: 'table',
      width: 'auto',
      margin: '0',
    },
    tableCellPlanAction: {
      margin: '0 0 1px 0',
      padding: 5,
      fontSize: 11,
      width: '150px',
      height: '70px',
      backgroundColor: colors.DarkBlue,
      color: colors.LightBlue,
      verticalAlign: 'middle',
      display: 'flex',
    },
    tableCellPlanActionLineTitle: {
      margin: '0 3px 1px 0',
      padding: 5,
      fontSize: 11,
      flex: 1,
      height: '50px',
      backgroundColor: colors.DarkBlue,
      color: colors.LightBlue,
      verticalAlign: 'middle',
      display: 'flex',
      textAlign: 'center',
    },
    tableCellPlanActionLineTitleRight: {
      margin: '0 0px 1px 0',
      padding: 5,
      fontSize: 11,
      flex: 1,
      height: '50px',
      backgroundColor: colors.DarkBlue,
      color: colors.LightBlue,
      verticalAlign: 'middle',
      display: 'flex',
      textAlign: 'center',
    },
    tableCellPlanActionDescription: {
      margin: '0 0 1px 0',
      padding: 5,
      fontSize: 11,
      width: '150px',
      height: '200px',
      backgroundColor: colors.DarkBlue,
      color: colors.LightBlue,
      verticalAlign: 'middle',
      display: 'flex',
    },
    tableCellValuePlanAction: {
      margin: 0,
      padding: 5,
      fontSize: 11,
      flex: 1,
      display: 'flex',
      height: '70px',
      color: colors.DarkBlue,
      backgroundColor: colors.MediumBlue,
    },
    tableCellValuePlanActionLineValue: {
      margin: '0 3px 0 0',
      padding: 5,
      fontSize: 11,
      flex: 1,
      display: 'flex',
      height: '70px',
      color: colors.DarkBlue,
      backgroundColor: colors.MediumBlue,
      textAlign: 'center',
    },
    tableCellValuePlanActionLineValueRigth: {
      margin: 0,
      padding: 5,
      fontSize: 11,
      flex: 1,
      display: 'flex',
      height: '70px',
      color: colors.DarkBlue,
      backgroundColor: colors.MediumBlue,
      textAlign: 'center',
    },
    tableCellValuePlanActionDescription: {
      margin: 0,
      padding: 5,
      fontSize: 11,
      flex: 1,
      display: 'flex',
      height: '200px',
      color: colors.DarkBlue,
      backgroundColor: colors.MediumBlue,
    },
    planAction: {
      margin: 0,
      padding: 0,
      flexGrow: 1,
      backgroundColor: colors.LightBlue,
      height: '100%',
    },
    planActionTitle: {
      margin: '0',
      height: '50px',
      backgroundColor: colors.DarkBlue,
      display: 'flex',
    },
    planActionTitleTxt: {
      color: colors.LightBlue,
      textAlign: 'center',
      top: '30%',
    },
    textCenter: {
      top: '40%',
    },
    textCenter20: {
      top: '20%',
    },
    footerPlainAction: {
      position: 'fixed',
      width: '100%',
      height: '30px',
      verticalAlign: 'bottom',
      flexGrow: 1,
      marginTop: '223px',
    },
    footer: {
      position: 'fixed',
      width: '100%',
      height: '30px',
      verticalAlign: 'bottom',
      flexGrow: 1,
      marginTop: '53px',
    },
    footerColor: {
      backgroundColor: colors.MediumBlue,
      width: '100%',
      height: '100%',
    },
    footerTxt: {
      color: colors.DarkBlue,
      fontSize: 11,
      marginBottom: '5px',
      textAlign: 'right',
      top: '30%',
    },
  });

  useEffect(() => {
    onGetCheckinById();
  }, [idCheckin]);

  useEffect(() => {
    if (checkin.id == null) return;
    renderPDF();
    const timeoutId = setTimeout(() => {
      setShowScreen(true);
    }, 30000);

    return () => clearTimeout(timeoutId);
  }, [checkin]);

  async function onGetCheckinById() {
    const data = await getCheckinById(idCheckin);
    const formatCheckin = {
      agenda: data.data.agenda,
      ativo: data.data.ativo,
      checkinPertenceAoUsuarioLogado: data.data.checkinPertenceAoUsuarioLogado,
      checkinPolos: polos(data.data.checkinPolos),
      dataAlteracao: data.data.dataAlteracao,
      dataInclusao: data.data.dataInclusao,
      dataVisita: moment(data.data.dataVisita, 'DD/MM/YYYY').format(
        'DD/MM/YYYY'
      ),
      ganho: data.data.ganho,
      id: data.data.id,
      idUsuarioLogado: data.data.idUsuarioLogado,
      liberarPdf: data.data.liberarPdf,
      participantes: data.data.participantes,
      planosDeAcao: data.data.planosDeAcao,
      polosIds: data.data.polosIds,
      resumo: (data.data.resumo != null ? data.data.resumo.replace(/(\r\n|\n|\r)/gm, '') : ''),
      ritual: validRitual(data.data.ritual),
      status: data.data.status,
      tempoVisita: data.data.tempoVisita,
      tipoVisita: validVisit(data.data.tipoVisita),
      usuarioAlteracao: data.data.usuarioAlteracao,
      usuarioInclusao: data.data.usuarioInclusao,
    };
    await setCheckin(formatCheckin);
  }

  async function sendPDF() {
    const formData = new FormData();
    await formData.append('file', fileBlob);
    await formData.append('checkinId', idCheckin)
    const resp = await sendEmail(formData)
  }

  function polos(checkinPolos) {
    let list = [];
    checkinPolos.forEach((item) => {
      list.push(`${item.polo.nome},  `);
    });
    return list;
  }

  function formatMoney(value, decimalPlaces = 2, currencySymbol = '$') {
    if (typeof value !== 'number') {
      return 'NaN';
    }
    const formattedValue = value
      .toFixed(decimalPlaces)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return `${currencySymbol} ${formattedValue}`;
  }

  function validVisit(value) {
    switch (value.toLowerCase()) {
      case 'p':
        return 'Presencial';
      case 'o':
        return 'Online';
      default:
        return '';
    }
  }

  function validRitual(value) {
    switch (value.toLowerCase()) {
      case 'ri':
        return 'Radar de Indicadores';
      case 'rm':
        return 'Reunião de Monitoramento';
      case 'vt':
        return 'Visita Técnica';
      default:
        return '';
    }
  }

  function validPilar(value) {
    switch (value.toLowerCase()) {
      case 'v':
        return 'Volume';
      case 'i':
        return 'Inadimplência';
      case 'r':
        return 'Retenção';
      case 'e':
        return 'Estrutura Padrão';
      case 'l':
        return 'LTR';
      case 'o':
        return 'Outros';
      default:
        return '';
    }
  }

  function validFOCA(value, isExport) {
    switch (value.toLowerCase()) {
      case 'f':
        return 'Financeiro'.toLocaleUpperCase();
      case 'o':
        return 'Operacional'.toLocaleUpperCase();
      case 'c':
        return 'Comercial'.toLocaleUpperCase();
      case 'a':
        return 'Academico'.toLocaleUpperCase();
      default:
        return '';
    }
  }

  function validActive(value) {
    switch (value.toLowerCase()) {
      case 'n':
        return 'Não';
      case 's':
        return 'Sim';
      default:
        return '';
    }
  }

  function validModalidade(value) {
    switch (value.charAt(0).toLowerCase()) {
      case 'g':
        return 'Graduação';
      case 'p':
        return 'Pós Graduação';
      case 'e':
        return 'EJA';
      case 'c':
        return 'Cursos Técnicos';
      default:
        return '';
    }
  }

  async function renderPDF() {
    let file = [];
    const priorityMap = {
      F: 1,
      O: 2,
      C: 3,
      A: 4,
    };

    const orderFOCA = checkin.planosDeAcao.sort(
      (a, b) => priorityMap[a.foca] - priorityMap[b.foca]
    );

    orderFOCA.map((plano) => {
      const renderPlan = (
        <Page size="A4" style={styles.viewer} key={plano.id}>
          <View style={styles.planAction}>
            <View style={styles.planActionTitle}>
              <Text style={styles.planActionTitleTxt}>
                PLANOS DE AÇÃO - {validFOCA(plano.foca)}
              </Text>
            </View>
            <View style={styles.tablePlanAction}>
              <View style={styles.tableRowPlanAction}>
                <View style={styles.tableCellPlanAction}>
                  <Text style={styles.textCenter}>PILAR</Text>
                </View>
                <View style={styles.tableCellValuePlanAction}>
                  <Text style={styles.textCenter}>
                    {validPilar(plano.pilar)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowPlanAction}>
                <View style={styles.tableCellPlanAction}>
                  <Text style={styles.textCenter}>MODALIDADE</Text>
                </View>
                <View style={styles.tableCellValuePlanAction}>
                  <Text style={styles.textCenter}>
                    {validModalidade(plano.modalidade)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowPlanAction}>
                <View style={styles.tableCellPlanAction}>
                  <Text style={styles.textCenter}>RESPONSÁVEL</Text>
                </View>
                <View style={styles.tableCellValuePlanAction}>
                  <Text style={styles.textCenter}>{plano.responsavel}</Text>
                </View>
              </View>
              <View style={styles.tableRowPlanAction}>
                <View style={styles.tableCellPlanActionDescription}>
                  <Text style={styles.textCenter}>DESCRIÇÃO DE AÇÃO</Text>
                </View>
                <View style={styles.tableCellValuePlanActionDescription}>
                  <Text>{plano.acao}</Text>
                </View>
              </View>
              <View style={styles.tableRowPlanAction}>
                <View style={styles.tableCellPlanActionLineTitle}>
                  <Text style={styles.textCenter}>PRAZO</Text>
                </View>
                <View style={styles.tableCellPlanActionLineTitleRight}>
                  <Text style={styles.textCenter}>GANHO ESTIMADO</Text>
                </View>
              </View>
              <View style={styles.tableRowPlanAction}>
                <View style={styles.tableCellValuePlanActionLineValue}>
                  <Text style={styles.textCenter}>
                    {moment(plano.prazo, 'DD/MM/YYYY').format('DD/MM/YYYY')}
                  </Text>
                </View>
                <View style={styles.tableCellValuePlanActionLineValueRigth}>
                  <Text style={styles.textCenter}>
                    {formatMoney(plano.ganhoEstimado, 2, 'R$')}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.footerPlainAction}>
              <View style={styles.footerColor}>
                <Text style={styles.footerTxt}>
                  Documento gerado em:{' '}
                  {moment(dateGeneneration, 'DD/MM/YYYY').format('DD/MM/YYYY')}{' '}
                  ás {moment(dateGeneneration, 'HH:MM').format('HH:MM')}hrs.
                </Text>
              </View>
            </View>
          </View>
        </Page>
      );
      file.push(renderPlan);
    }, setRenderPDFPage(file));
  }

  const onRender = (file) => {
    fileBlob = file.blob
  }

  const template = (
    <PDFViewer style={styles.viewer}>
    <Document ref={pdfRef} onRender={onRender}>
      <Page size="A4" style={styles.viewer}>
        <View style={styles.header}>
          <View style={styles.headerInfo}>
            <Text style={styles.headerVisita}>RELATÓRIO DE VISITA</Text>
            <Text style={styles.headerFoca}>FOCA</Text>
            <Text style={styles.headerDate}>
              REFERÊNCIA:
              {moment(checkin.dataVisita, 'DD/MM/YYYY')
                .format('MMM/YYYY')
                .toLocaleUpperCase()}
            </Text>
          </View>
          <View style={styles.headerImg}>
            <Image style={styles.headerLogo} src={logoKRTN} />
          </View>
        </View>
        <View style={styles.page}>
          <View style={styles.section}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.textCenter20}>DATA VISITA</Text>
                </View>
                <View style={styles.tableCellValue}>
                  <Text style={styles.textCenter20}>{checkin.dataVisita}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.textCenter20}>TIPO VISITA</Text>
                </View>
                <View style={styles.tableCellValueDark}>
                  <Text style={styles.textCenter20}>{checkin.tipoVisita}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.textCenter20}>RITUAL</Text>
                </View>
                <View style={styles.tableCellValue}>
                  <Text style={styles.textCenter20}>{checkin.ritual}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.textCenter20}>ID CHECK-IN </Text>
                </View>
                <View style={styles.tableCellValueDark}>
                  <Text style={styles.textCenter20}>{checkin.id}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.textCenter20}>GERENTE DE NEGÓCIOS</Text>
                </View>
                <View style={styles.tableCellValue}>
                  <Text style={styles.textCenter20}>
                    {checkin.usuarioInclusao.pessoa.nome}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCellHeightMax}>
                  <Text style={styles.textCenter}>POLO(S)</Text>
                </View>
                <View style={styles.tableCellValueDarkMax}>
                  <Text>{checkin.checkinPolos}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCellHeightMax}>
                  <Text style={styles.textCenter}>PARTICIPANTE(S)</Text>
                </View>
                <View style={styles.tableCellValue}>
                  <Text>{checkin.participantes}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCellHeightMax}>
                  <Text style={styles.textCenter}>RESUMO DA VISITA</Text>
                </View>
                <View style={styles.tableCellValueDark}>
                  <Text>{checkin.resumo}</Text>
                </View>
              </View>
            </View>
            <View style={styles.footer}>
              <View style={styles.footerColor}>
                <Text style={styles.footerTxt}>
                  Documento gerado em:{' '}
                  {moment(dateGeneneration, 'DD/MM/YYYY').format('DD/MM/YYYY')}{' '}
                  ás {moment(dateGeneneration, 'HH:MM').format('HH:MM')}hrs.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
      {renderPDFPage}
    </Document>
    </PDFViewer>
  );

  return (
    <>
      {template}
      {props.isSendEmail ? (
        <>
          <Button type="primary" onClick={sendPDF}>
            Enviar
          </Button>
          <span>
            {'  '}O PDF do relatório será enviado no email do Responsável pelo
            Check-in
          </span>
        </>
      ) : (
        ''
      )}
    </>
  );
}
export default TemplateFoca;
