import React, { useEffect, useState } from 'react';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from 'services/Gerais';
import {
  Input,
  Modal,
  Form,
  Row,
  Col,
  Button,
  Table,
  Select,
  Icon
} from 'antd';
import './styles.scss';
import { getArea, getAreaById, putArea, saveArea } from 'services/AreaSolicitante/areaService';

const { Option } = Select;

const AreaSolicitante = () => {
  const [tableColumns, setTableColumns] = useState([
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Area',
      key: 'descricao',
      dataIndex: 'descricao',
    },
    {
      title: 'Situação',
      dataIndex: 'ativo',
      key: 'ativo',
      render: (ativo) => (
        (ativo == 'S' ? "Ativo" : "Inativo")
      )
    },
    {
      title: 'Data Alteração',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Button
          type="dashed"
          className="button is-small is-warning"
          size="small"
          onClick={() => editArea(id)}
        >
          <Icon type="file-search" />
          Editar
        </Button>
      )
    },
  ]);
  const [dataSource, setDataSource] = useState([])
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({});
  const [description, setDescription] = useState({descricao: '', ativo: 'S'});
  const [idArea, setIdArea] = useState(0);

  async function editArea(id) {
    setIdArea(id)
    const data = await getAreaById(id);
    setSearch(data.data)
    handleModalUpdate();
  }

  function newArea() {
    setSearch({});
    handleModalUpdate();
  }

  function handleModalUpdate() {
    if (visible) {
      setVisible(false);
      return;
    }
    setVisible(true);
  }

  useEffect(() => {
    handleGetArea();
  }, []);

  async function handleGetArea() {
    const data = await getArea(description);
    setDataSource(data.data);
  }

  async function handleSaveArea() {
    if(search.id != undefined) {
      await putArea(idArea, search);
    } else {
      await saveArea(search);
    }
    handleGetArea();
    handleModalUpdate();
  }

  const onChange = (value, field) => {
    setSearch((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const onChangeDescription = (value, field) => {
    setDescription((state) => ({
      ...state,
      [field]: value,
    }));
  };


  return (
    <div>
      <Helmet
        key="TitleAdminAreaSolicitante"
        title="Area Solicitante - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Area Solicitante' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'Area Solicitante',
          },
        ]}
      />
      <HeaderAdmin key="HeaderArea" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminArea" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroArea"
                pageName="Area Solicitante"
                titulo={'Area Solicitante'}
              />
            </div>
          </div>
          <Row xl={24}></Row>
          <Row xl={24}>
            <Col xl={18} md={24}>
              <Form.Item label="Area">
                <Input
                  type="text"
                  className="ant-input height-40"
                  placeholder="Area"
                  onChange={(value) => onChangeDescription(value.target.value, 'descricao')}
                />
              </Form.Item>
            </Col>
            <Col xl={3} md={24}>
              <Form.Item>
                <Button
                  type="primary"
                  className="float-right margin-1-left"
                  onClick={handleGetArea}
                  style={{
                    backgroundColor: '#22c65b',
                    borderColor: ' #22c65b',
                    width: '90%',
                  }}
                >
                  Pesquisar
                </Button>
              </Form.Item>
            </Col>
            <Col xl={3} md={24}>
              <Button
                type="primary"
                style={{ width: '90%' }}
                className="float-right"
                onClick={newArea}
              >
                Novo
              </Button>
            </Col>
          </Row>
          <Row xl={24}>
            <Table columns={tableColumns} dataSource={dataSource} />
          </Row>
        </div>
      </section>
      <Modal
        title="Cadastro e Edição de Áreas"
        visible={visible}
        width={700}
        onCancel={handleModalUpdate}
        onOk={handleSaveArea}
        destroyOnClose={true}
        centered
      >
        <Row xl={24}>
          <Col xl={19}>
            <Form.Item label="Area">
              <Input
                defaultValue={search.descricao}
                placeholder="Selecione"
                onChange={(value) => onChange(value.target.value, 'descricao')}
              />
            </Form.Item>
          </Col>
          <Col xl={4} className="margin-1-left">
            <Form.Item label="Situação">
              <Select
                defaultValue={search.ativo}
                placeholder="Selecione"
                onChange={(value) => onChange(value, 'ativo')}
              >
                <Option value="S">Ativo</Option>
                <Option value="N">Inativo</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
      <FooterAdmin key="FooterAdminArea" />
    </div>
  );
};

export default AreaSolicitante;
