import $ from 'jquery';

let BaseURL = 'http://10.201.40.37:8080/api/';
const urlLocalPort = window.location.port;
const urlLocal = window.location.protocol + '//' + window.location.hostname;

if (parseInt(urlLocalPort) <= 4999) {
  // se estiver em localhost
  BaseURL = 'http://localhost:8080/api/';
} else {
  // se o link for de producao (prisma.kroton...)
  if (urlLocal.includes('prisma.kroton')) {
    // BaseURL = window.location.protocol+"//apiprisma.kroton.com.br/api/"
    BaseURL = 'https://apiprisma.kroton.com.br/api/';
  } else if (urlLocal.includes('prismafrontstg.krthomolog.com.br')) {
    // se o link for do ambiente stage
    BaseURL =
      window.location.protocol + '//prismabackstg.krthomolog.com.br/api/';
  } else {
    // ambientes de teste (AKA jenkins)
    BaseURL = urlLocal + ':8080/api/';
  }
}
if(urlLocal.includes('prismamtc.krthomolog')) {
  BaseURL = 'https://prismabackmtc.krthomolog.com.br/api/';
}

function haveTags() {
  if (localStorage.getItem('userDataCRP-AuthTk')) {
    var result = false;
    $.ajax({
      type: 'GET',
      url: BaseURL + 'tag/',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      async: false,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      },
      success: function(data, textStatus, jQxhr) {
        result = JSON.parse(JSON.stringify(data), (key, val) =>
          typeof val !== 'object' && val !== null ? String(val) : val
        );
      },
      error: function(errormessage) {
        result = [];
      },
    });
    return result;
  } else {
    return [
      {
        '-': 'Não existem tags cadastradas',
      },
    ];
  }
}

// export const TAGS = []
export const TAGS = haveTags();
