import { Button, Tag, Icon, Switch } from 'antd';
import React from 'react';
import { ActionContainer } from './styled';
import { onExecuteJob } from '../../service'

export const tableColumns = [
  {
    title: 'ID',
    key: 'id',
    width: 50,
    dataIndex: 'id',
    valueType: 'indexBorder',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Valor',
    key: 'valor',
    dataIndex: 'valor',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Descrição',
    key: 'descricao',
    dataIndex: 'descricao',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Observação',
    key: 'observacao',
    dataIndex: 'observacao',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Status',
    key: 'ativo',
    dataIndex: 'ativo',
    width: 100,
    render: (_, record) => {
      const objTag =
        record.ativo === 'S'
          ? { tag: 'Ativo', color: '#87d068' }
          : { tag: 'Inativo', color: 'volcano' };
      return (
        <span>
          <Tag color={objTag.color} key={objTag.tag}>
            {objTag.tag.toUpperCase()}
          </Tag>
        </span>
      );
    },
  },
  {
    title: 'Ação',
    valueType: 'action',
    dataIndex: 'id',
    key: 'edit',
    width: 130,
    render: (_, record) => (
      <ActionContainer key={`action_${record.id}`}>
        <Button
          href={`/admin/parameters/edit/${record?.id}`}
          type="dashed"
          className="button is-small is-warning"
          size="small"
        >
          <Icon type="file-search" />
          Editar
        </Button>
      </ActionContainer>
    ),
  },
  {
    title: 'JOB',
    key: 'executeJob',
    dataIndex: 'executeJob',
    width: 100,
    render: (executeJob, record) =>
      executeJob == 'S' && record.ativo == "S" ? (
        <Button
          type="dashed"
          className="button is-small is-success"
          size="small"
          onClick={() => onExecuteJob(record.id)}
        >
          <Icon type="rise" />
          Executar
        </Button>
      ) : (
        ''
      ),
  },
];
