import React, { useState, useEffect } from 'react';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../services/Gerais';
import PortaisForm from './Form';
import { withRouter } from 'react-router-dom'

const Cadastro = props => {
  const [tituloPagina, setTituloPagina] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [idPortal, setIdPortal] = useState('');
  useEffect(
    () => {
      if (props && props.match && props.match.params && props.match.params.id) {
        setIsEdit(true);
        setTituloPagina('Editar Portais');
        setIdPortal(props.match.params.id);
      } else {
        setTituloPagina('Cadastro de Portal');
      }
    },
    // eslint-disable-next-line
    [props]
  );

  return (
    <>
      <Helmet
        key="TitleAdminAgendaCadastro"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'cadastro, comunicados, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAdminAgendaCadastro" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminAgendaCadastro" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <Hero
            key="heroAdminCadastroAgenda"
            pageName="Administração - Portais"
            titulo={tituloPagina}
          />
          <PortaisForm
            form={props.form}
            isEdit={isEdit}
            history={props.history}
            idPortal={idPortal}
            history2={props.history}
          />
        </div>
      </section>
      <FooterAdmin key="FooterAdminUsuariosCadastro" />
    </>
  );
};

export default withRouter(Cadastro);
