import React from 'react';
import { Container, Line } from './styles';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useCategories } from './hook';
import { Button } from 'antd';

export const Categories = ({ admin }) => {
  const { faqData } = useCategories();
  return (
    <Container style={{ width: '100%' }}>
      {!faqData?.length ? (
        <></>
      ) : (
        faqData.map((item) => (
          <Line>
            <Line
              animation
              style={{ display: 'flex' }}
              onClick={() => {
                window.location.href = `/faq/${item.id}`;
              }}
            >
              <ArrowRightOutlined />
              <p>{item.categoria}</p>
            </Line>
            {admin && (
              <Button href={`/admin/categoria/${item.id}`}>Editar</Button>
            )}
          </Line>
        ))
      )}
    </Container>
  );
};
