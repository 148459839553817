import api from 'services/api';
import Swal from 'sweetalert2';

const getUserID = () => {
    if (localStorage.getItem('userDataCRP')) {
      const usrLogin = JSON.parse(localStorage.getItem('userDataCRP-Login'));

      let poloID = '';
      let poloIDC = '';
      let usrID = null;
      let pessoaNome = '';

      if (usrLogin) {
        usrLogin.map(v => {
          usrID = v.usuarioPortal.id;
          poloID = v.polo !== null ? '-' + v.polo.id : '';
          poloIDC = v.polo !== null ? v.polo.id : '';
          pessoaNome = v.usuarioPortal.pessoa.nome;
          return v;
        });
      }
      let objUsrAuth = {
        usrID: usrID,
        poloID: poloIDC,
        usuarioName: pessoaNome,
      };
      return { userId: `${objUsrAuth.usrID}${objUsrAuth.poloID}` };
    }
    return false;
  };

   export async function patchUserPersonification(userData) {
      try {
        const { mail, timerToLogout, isPersonification } = userData;
        const userObjID = getUserID();
        const realUserID = userObjID.userId;
        await api.patch(`usuario/personificarUsuario/${realUserID}`, {
          email: mail,
          tempo: timerToLogout,
          isPersonificado: isPersonification
        });
        await window.close();
        await localStorage.clear()
        window.open('/login', '_self');
      } catch (error) {
        Swal.fire({
          title: 'Erro de servidor!',
          text: 'Não foi possível conectar ao servidor neste momento. Tente novamente depois',
          icon: 'error',
            })
        };
      }
