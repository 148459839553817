const pipe = (data, ...formatters) =>
  formatters.reduce((data, formatter) => formatter?.(data) ?? data, data);

const removeEmptyOrNullFromJson = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((v) =>
        v && !(v instanceof Date) && typeof v === 'object'
          ? removeEmptyOrNullFromJson(v)
          : v
      )
      .filter((v) => v);
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [
        k,
        v && !(v instanceof Date) && typeof v === 'object'
          ? removeEmptyOrNullFromJson(v)
          : v,
      ])
      .reduce(
        (a, [k, v]) =>
          typeof v !== 'boolean' && typeof v !== 'number' && !v
            ? a
            : ((a[k] = v), a),
        {}
      );
  }
};
export { pipe, removeEmptyOrNullFromJson };
export * from './systemAssignments';
