function cpfMask (value) {
  const retorno =  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

  return retorno
}

function cnpjMask (value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

function telefoneMask (value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

function currencyMask (value) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })

  var currency = formatter.format(value);

    return currency
}

function currencyMaskFormat(value) {
  value = value.replace(/\D/g, "");
  value = value.length > 8 ? value.replace(/(\d)(\d{3})(\d{5})$/, "$1.$2.$3") : value.replace(/(\d)(\d{5})$/, "$1.$2")
  value = value.replace(/(\d)/, "R$ $1")
  value = value.replace(/(\d)(\d{2})$/, "$1,$2")
}

export {
  cpfMask,
  cnpjMask,
  telefoneMask,
  currencyMask,
  currencyMaskFormat
}
