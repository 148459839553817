import { useCallback, useState } from 'react';
import { csvJSON } from '../../../utils/formatters/csv';
import { createImport, exportCsv } from '../../../services/search';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { csv2json } from '../../../utils/csvToJson';
import config from '../../../config/config';

const useSearch = () => {
  const [fileList, setFileList] = useState([]);
  const [fileJson, setFileJson] = useState({ data: [] });
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;
  const handleImportFile = useCallback(async () => {
    try {
      const allUploads = [];
      const errors = [];
      fileJson.data.forEach((item) => {
        if (item.name && !item.data.length) {
          errors.push(item.name);
        }
        item.data.forEach((data) => {
          if (!data.TEXTO_PESQUISA) {
            errors.push(item.name);
          }
          allUploads.push({
            botao1: data.BOTAO1,
            botao2: data.BOTAO2,
            botao3: data.BOTAO3,
            respondeu: data.RESPONDEU,
            textoPesquisa: data.TEXTO_PESQUISA,
            tituloPesquisa: data.TITULO_PESQUISA,
            usuarioPortalId: data.USUARIO_PORTAL_ID,
            dataInclusao: data.DATA_INCLUSAO,
            dataResposta: data.DATA_RESPOSTA,
            link: data.LINK,
          });
        });
      });
      if (!allUploads.length > 0 || errors.length > 0) {
        return Swal.fire({
          title: 'Atenção',
          text: `Formato do arquivo ${errors[0]} inválido!`,
        });
      }
      const body = {
        pesquisas: allUploads,
      };
      const { data } = await createImport({ body });

    } catch (error) {
    }
  }, [fileJson.data]);

  const uploadProps = {
    name: 'file',
    action: `${config.baseUrl}file/upload`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`
    },
    accept: '.csv',
    maxCount: 1,
    multiple: false,
    onChange(info) {
      const result = [];
      info.fileList.forEach((item) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csv = e.target.result;
          result.push({
            name: item.name,
            data: csv2json(csv, { parseNumbers: true }),
          });
        };
        reader.readAsText(item.originFileObj);
      });

      setFileJson({ data: result });
      setFileList(info.fileList);
    },
  };

  const uploadPropsDocs = {
    name: 'file',
    action: `${config.baseUrl}file/upload`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`
    },
    accept: '.csv',
    maxCount: 1,
    multiple: false,
    onChange(info) {
      const result = [];
      info.fileList.forEach((item) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csv = e.target.result;
            console.log('csv', csv)
        };
        reader.readAsText(item.originFileObj);
      });

      setFileJson({ data: result });
      setFileList(info.fileList);
    },
  };


  const onChange = (value) => {
    setDate(value);
  };

  const exportCSV = useCallback(async () => {
    try {
      setLoading(true);
      const { status, data, headers } = await exportCsv({
        body: {
          relatorioId: 33,
          lstParametros: [
            {
              id: 16,
              nome: 'Data Inicial',
              valor: date[0],
            },
            {
              id: 17,
              nome: 'Data final',
              valor: date[1],
            },
          ],
        },
      });
      if (status === 200 || status === 201) {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Relatório gerado com sucesso!',
        });
        const BOM = '\uFEFF';
        let conteudo = data.replace(/\n/gm, '');
        const relatorioDados = BOM + conteudo.replace(/,/g, ' ');
        const filename = headers['content-disposition']
          .split(';')
          .find((n) => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .replace('"', '')
          .replace('"', '');
        const blob = new Blob([relatorioDados], {
          type: 'text/csv',
        });
        saveAs(blob, filename);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Atenção',
        text: error?.response?.data?.message,
      });
    }
  }, [date]);
  const exportButtonDisabled = !!(!date?.[0] && !date?.[1]);
  const importButtonDisabled = !!!fileList.length;
  return {
    handleImportFile,
    loading,
    uploadProps,
    uploadPropsDocs,
    exportCsv,
    onChange,
    date,
    exportCSV,
    exportButtonDisabled,
    importButtonDisabled,
  };
};
export { useSearch };
