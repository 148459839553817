import React, { useEffect, useState } from 'react';
import { Timeline, Icon, Modal, Select, Button, InputNumber, Spin } from 'antd';
import {
  getLicensesForPolo,
  getLogDynamics,
  saveDynamics,
  editDynamics,
  getUserDynamicsData,
  findSistemsByPerfil,
} from 'services/Captar';
import Swal from 'sweetalert2';
import {
  LicensesContainer,
  LicenceWrapper,
  HandleCaptarContainer,
  ButtonsCaptarContainer,
} from './styles.js';

const { Option } = Select;
const SISTEMA_ATHENAS = 7;
const SISTEMA_CAPTAR = 60;
const SISTEMA_GESTOR = 82;
const SISTEMA_ACADEMICO = 83;

function RenderModalCaptar(props) {
  const { userDataProfile, listPolosUser } = props;

  const [dynamicsUserLog, setDynamicsUserLog] = useState([]);
  const [poloInfo, setPoloInfo] = useState({});
  const [lstPolosUser, setLstPolosUser] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisponivel, setIsDisponivel] = useState(true);
  const [isOpenDynamics, setIsOpenDynamics] = useState(false);
  const [sistema, setSistema] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [activeLicenses, setActiveLicenses] = useState({});
  const [hasActiveLicenses, setHasActiveLicenses] = useState({});
  const [isLog, setIsLog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [perfilIdLocal, setPerfilIdLocal] = useState(null);

  const userEditor = JSON.parse(localStorage.getItem('userDataCRP')).id;
  const useActiveLicense = activeLicenses[sistema];

  useEffect(() => {
    const currentLicense = activeLicenses[sistema];
    const hasLicense = currentLicense?.acessaDynamics === 'S';
    setHasActiveLicenses(hasLicense);
  }, [sistema, activeLicenses]);

  useEffect(() => {
    if (hasActiveLicenses) {
      getData();
    } else if (userDataProfile?.id) {
      getDynamicsData();
    }
  }, [userDataProfile, hasActiveLicenses, sistema]);

  useEffect(() => {
    if (listPolosUser.length > 0) {
      handleListPolo();
    }
  }, [listPolosUser]);

  useEffect(() => {
    poloInfo.licencasDisponiveis === 0
      ? setIsDisponivel(false)
      : setIsDisponivel(true);
  }, [poloInfo]);

  useEffect(() => {
    const hasActiveLicense = activeLicenses[sistema]?.acessaDynamics === 'S';
    setShowButton(hasActiveLicense);
  }, [sistema, useActiveLicense]);

  function handleListPolo() {
    const filteredPolos = listPolosUser.filter(
      (item) =>
        item.usuarioPolo.polo?.id !== '' && item.usuarioPolo.polo !== null
    );
    const polos = filteredPolos.map((item) => item.usuarioPolo.polo);
    setLstPolosUser(polos);
  }

  async function getData() {
    setIsLoading(true);
    if (useActiveLicense?.id) {
      const result = await getLogDynamics(useActiveLicense?.id, sistema);
      setDynamicsUserLog(result.data);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getDynamicsData() {
    try {
      const res = await getUserDynamicsData(userDataProfile?.id);
      if (!res) return;

      const systems = [
        { id: SISTEMA_CAPTAR, valueId: 60 },
        { id: SISTEMA_ATHENAS, valueId: 7 },
        { id: SISTEMA_GESTOR, valueId: 82 },
        { id: SISTEMA_ACADEMICO, valueId: 83 },
      ];

      const licenses = systems.reduce((acc, system) => {
        const license = res.find((item) => item.sistema.id === system.id);
        acc[system.valueId] = license || {};
        return acc;
      }, {});

      setActiveLicenses(licenses);
    } catch (error) {
      Swal.fire({
        title: 'Erro ao buscar dados!',
        text: `Erro ao buscar dados do usuário ${userDataProfile?.conta}. Tente novamente.`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  }

  function getTitleModalSystem(isLog, sistema) {
    switch (true) {
      case isLog && sistema === SISTEMA_CAPTAR:
        return 'HISTÓRICO CAPTAR';
      case isLog && sistema === SISTEMA_ATHENAS:
        return 'HISTÓRICO ATHENAS';
      case isLog && sistema === SISTEMA_GESTOR:
        return 'HISTÓRICO GESTOR DE POLOS';
      case isLog && sistema === SISTEMA_ACADEMICO:
        return 'HISTÓRICO PORTAL ACADÊMICO';
      case !isLog && sistema === SISTEMA_CAPTAR:
        return 'GESTÃO CAPTAR';
      case !isLog && sistema === SISTEMA_ATHENAS:
        return 'GESTÃO ATHENAS';
      case !isLog && sistema === SISTEMA_GESTOR:
        return 'GESTÃO GESTOR DE POLOS';
      case !isLog && sistema === SISTEMA_ACADEMICO:
        return 'GESTÃO PORTAL ACADÊMICO';
      default:
        return '';
    }
  }

  async function getDataBypoloId(poloId) {
    const result = await getLicensesForPolo(poloId, sistema);
    setPoloInfo(result);
  }

  function openLogModal() {
    setIsLog(true);
    setIsOpenDynamics(true);
  }

  async function openLicenseModal() {
    setIsLog(false);
    setIsOpenDynamics(true);
  }

  function handleButtonPress(action) {
    setPerfilIdLocal(localStorage.getItem('userDataCRP-Perfil'));
    if (action === 'log') {
      openLogModal();
    } else if (action === 'license') {
      openLicenseModal();
    }
  }

  async function handleButtonManagement(sistema, perfilId) {
    const result = await findSistemsByPerfil(perfilId);
    let sistemaNome = '';

    switch (sistema) {
      case 60:
        sistemaNome = "CAPTAR";
        break;
      case 7:
        sistemaNome = "ATHENAS";
        break;
      case 82:
        sistemaNome = "GESTOR DE POLOS";
        break;
      case 83:
        sistemaNome = "PORTAL ACADÊMICO";
        break;
      default:
        sistemaNome = "Desconhecido";
    }

    if (!result.includes(sistema)) {
      return Swal.fire({
        title: 'Atenção!',
        text: `Perfil de acesso do colaborador não permite acesso ao: ${sistemaNome}!`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }

    setSistema(sistema);
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
    setIsLog(false);
    setIsOpenDynamics(false);
    setPoloInfo({});
  }

  function renderOption(item) {
    return (
      <Option key={item.id} text={item.nome}>
        {item['nome'] ? item.nome : item.nome}
      </Option>
    );
  }

  function successMessage(title, text) {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'success',
      confirmButtonText: 'Ok',
      timer: 2000,
    });
  }

  function errorMessage(title, text) {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      confirmButtonText: 'Ok',
      timer: 2000,
    });
  }

  async function addLicense(idPolo, idUser, action, sistemaId) {
    if (idPolo === undefined) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Selecione um polo para adicionar acesso.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }

    let dataToHandle = {
      usuarioPortal: idUser,
      acessaDynamics: action === 'remove' ? 'N' : 'S',
      usuarioUltimaAlteracao: userEditor,
      polo: action === 'edit' ? useActiveLicense?.polo.id : idPolo,
      sistema: sistemaId,
    };

    try {
      switch (action) {
        case 'save':
          await saveDynamics(idPolo, dataToHandle);
          setIsOpen(false);
          successMessage(
            'Acesso atribuído com sucesso!',
            'Salve as alterações de usuário para confirmar.'
          );
          window.location.reload();
          await getDynamicsData();
          setPoloInfo({});
          break;
        case 'edit':
          if (
            useActiveLicense.polo.id === idPolo &&
            useActiveLicense.acessaDynamics === 'S'
          ) {
            return Swal.fire({
              title: 'Atenção!',
              text: 'O usuário já tem acesso ao polo selecionado.',
              icon: 'warning',
              confirmButtonText: 'Ok',
            });
          }

          await editDynamics(idPolo, dataToHandle);
          setIsOpen(false);
          successMessage(
            'Acesso atribuído com sucesso!',
            'Salve as alterações de usuário para confirmar.'
          );
          window.location.reload();
          await getDynamicsData();
          setPoloInfo({});
          break;
        case 'remove':
          await editDynamics(idPolo, dataToHandle);
          setIsOpen(false);
          successMessage(
            'Acesso removido com sucesso!',
            'Salve as alterações para confirmar a remoção.'
          );
          window.location.reload();
          await getDynamicsData();
          setPoloInfo({});
          break;
      }
    } catch (error) {
      errorMessage(
        'Erro ao executar ação!',
        `Erro ao ${
          action === 'remove' ? 'remover' : 'atribuir'
        } acesso. Tente novamente.`
      );
    }
  }

  const hasActiveLog = Object.values(activeLicenses).some(
    (license) => license?.id
  );

  return (
    <>
      <HandleCaptarContainer>
        <Button
          type="primary"
          onClick={() => handleButtonPress('license')}
          size="large"
          className="bg-color-yellow"
        >
          Gestão Dynamics
        </Button>
        {hasActiveLog ? (
          <Button
            type="primary"
            onClick={() => handleButtonPress('log')}
            size="large"
          >
            Histórico Dynamics
          </Button>
        ) : null}
      </HandleCaptarContainer>

      <Modal
        title={`GESTÃO DE ACESSO AO DYNAMICS`}
        width={500}
        visible={isOpenDynamics}
        centered
        destroyOnClose
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
      >
        <p>
          <b>{`Usuário Selecionado: ${userDataProfile?.conta}`}</b>
        </p>

        <ButtonsCaptarContainer className="margin-1-top">
          <div style={{width: '100%'}}>
            <Button
              type="primary"
              style={{ width: '100%', marginBottom: '0.8rem' }}
              onClick={() =>
                handleButtonManagement(SISTEMA_CAPTAR, perfilIdLocal)
              }
            >
              CAPTAR
            </Button>

            <Button
              type="primary"
              style={{ width: '100%', marginBottom: '0.8rem' }}
              onClick={() =>
                handleButtonManagement(SISTEMA_ATHENAS, perfilIdLocal)
              }
            >
              ATHENAS
            </Button>
          </div>
          <div style={{width: '100%'}}>
            <Button
              type="primary"
              style={{ width: '100%', marginBottom: '0.8rem' }}
              onClick={() =>
                handleButtonManagement(SISTEMA_GESTOR, perfilIdLocal)
              }
            >
              GESTOR DE POLOS
            </Button>
            <Button
              type="primary"
              style={{ width: '100%', marginBottom: '0.8rem' }}
              onClick={() =>
                handleButtonManagement(SISTEMA_ACADEMICO, perfilIdLocal)
              }
            >
              PORTAL ACADÊMICO
            </Button>
          </div>
        </ButtonsCaptarContainer>
      </Modal>

      <Modal
        title={getTitleModalSystem(isLog, sistema)}
        width={900}
        visible={isOpen}
        centered
        destroyOnClose
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
      >
        <>
          <p>
            <b>{`Usuário Selecionado: ${userDataProfile?.conta}`}</b>
          </p>

          {isLog ? (
            <>
              {isLoading ? (
                <Spin
                  size="large"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                />
              ) : (
                <Timeline className="margin-1-top">
                  {hasActiveLicenses? (
                    dynamicsUserLog.map((item) => (
                      <Timeline.Item
                        key={item.id}
                        dot={
                          <Icon
                            type="right-circle"
                            style={{ fontSize: '16px' }}
                            theme="twoTone"
                          />
                        }
                      >
                        <h3>{item.descricaoEvento}</h3>
                        <p>
                          {item.dataHoraEvento} - {item.descricaoEvento}
                        </p>
                      </Timeline.Item>
                    ))
                  ) : (
                    <Timeline.Item>Nenhum evento encontrado</Timeline.Item>
                  )}
                </Timeline>
              )}
            </>
          ) : (
            <>
              {hasActiveLicenses && (
                <div>
                  <p>
                    Atenção: este usuário já tem um acesso{' '}
                    {useActiveLicense?.sistema?.descricao} ativo vinculado ao
                    polo <b>{useActiveLicense?.polo?.nome}</b>. Você pode{' '}
                    <b>transferir</b> o acesso ativo para o polo selecionado ou{' '}
                    <b>remover</b> o acesso ativo.
                  </p>
                </div>
              )}
              <p>Polos do usuário:</p>
              <Select
                id="PolosListWithLicenses"
                placeholder="Selecione um polo"
                onChange={(e) => getDataBypoloId(e)}
              >
                {lstPolosUser?.map((polo) => renderOption(polo))}
              </Select>
              {!isDisponivel && (
                <p>
                  <b>Não há acessos disponíveis para o polo selecionado.</b>
                </p>
              )}
              <p>
                <strong>
                  Disponibilidade de licenças para o polo selecionado:
                </strong>
              </p>
              <LicensesContainer>
                <LicenceWrapper>
                  <span>Total de Acessos</span>
                  <InputNumber disabled value={poloInfo?.licencasLiberadas} />
                </LicenceWrapper>
                <LicenceWrapper>
                  <span>Acessos Consumidos.</span>
                  <InputNumber disabled value={poloInfo?.licencasUtilizadas} />
                </LicenceWrapper>
                <LicenceWrapper>
                  <span>Acessos Disponíveis</span>
                  <InputNumber disabled value={poloInfo?.licencasDisponiveis} />
                </LicenceWrapper>
              </LicensesContainer>
              <p>
                <strong>
                  ATENÇÃO: Nesta tela, vincule o usuário a apenas um dos polos
                  aos quais ele tem acesso para associar uma licença Dynamics.
                  Não é necessário vincular uma licença Dynamics em todos os
                  polos do usuário, automaticamente os demais polos vinculados
                  ao usuário também serão liberados. Após adicionar o acesso
                  aqui no Prisma, aguardar o prazo de 1 hora para a liberação no
                  Dynamics. Em caso de dúvidas acionar o atendimento CAPU pelo
                  Chat.
                </strong>
              </p>
              <ButtonsCaptarContainer>
                {showButton && (
                  <Button
                    type="danger"
                    onClick={() =>
                      addLicense(
                        useActiveLicense.polo.id,
                        userDataProfile?.id,
                        'remove',
                        poloInfo.sistema?.id
                      )
                    }
                    disabled={useActiveLicense?.polo?.id !== poloInfo.polo?.id}
                  >
                    Remover acesso
                  </Button>
                )}

                <Button
                  type="primary"
                  onClick={() =>
                    addLicense(
                      poloInfo.polo?.id,
                      userDataProfile?.id,
                      useActiveLicense?.acessaDynamics === 'N' ||
                        useActiveLicense?.acessaDynamics === 'S'
                        ? 'edit'
                        : 'save',
                      poloInfo.sistema?.id
                    )
                  }
                  disabled={!isDisponivel}
                >
                  {hasActiveLicenses ? 'Transferir acesso' : 'Adicionar acesso'}
                </Button>
              </ButtonsCaptarContainer>
            </>
          )}
        </>
      </Modal>
    </>
  );
}

export default RenderModalCaptar;
