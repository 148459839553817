import React from 'react';

import styles from './style.module.css';
import PortaisTable from '../PortaisTable';

const PortaisSection = props => (
  <section className={styles.container}>
    <PortaisTable form={props.form} />
  </section>
);

export default PortaisSection;
