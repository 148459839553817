import React, { useEffect, useState } from 'react';
import {
  fetchSystemAssignment,
  updateSystemAssignment,
} from 'services/systemAssignments';
import { Link } from 'react-router-dom';
import { Button, Switch } from 'antd';
import { ActionContainer } from './styles';
import { FontAwesomeIcon } from '../../../services/Gerais';
import { parseDate } from '../../../utils/formatters';

const useSystemAssignment = (id, system, profile, profileId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [form, setForm] = useState({
    description: '',
    status: '',
    code: '',
  });

  const toggle = () => setIsLoading((state) => !state);
  const getSystemAssignment = async (id) => {
    try {
      toggle();
      const { status, data } = await fetchSystemAssignment({ id, profileId });
      if (status === 200) {
        setDataSource(data);
        setFilteredDataSource(data.map((item) => ({ ...item })));
        setDataSource(data.map((item) => ({ ...item })));
      }
      toggle();
    } catch (error) {
      toggle();
      return error;
    }
  };

  useEffect(() => {
    getSystemAssignment(id);
  }, []);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Código atribuição',
      dataIndex: 'codigo',
      key: 'codigo',
      width: 100,
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
      width: 100,
    },
    {
      title: 'Consulta',
      dataIndex: 'consulta',
      key: 'consulta',
      render: (consulta) => (consulta === 'S' ? 'Sim' : 'Não'),
      width: 100,
    },
    {
      title: 'Insere',
      dataIndex: 'inclusao',
      key: 'inclusao',
      render: (inclusao) => (inclusao === 'S' ? 'Sim' : 'Não'),
      width: 100,
    },
    {
      title: 'Altera',
      dataIndex: 'alteracao',
      key: 'alteracao',
      render: (alteracao) => (alteracao === 'S' ? 'Sim' : 'Não'),
      width: 100,
    },
    {
      title: 'Exclui',
      dataIndex: 'exclusao',
      key: 'exclusao',
      render: (exclusao) => (exclusao === 'S' ? 'Sim' : 'Não'),
      width: 100,
    },
    {
      title: 'Ações',
      width: 100,
      render: (row) => {
        const findData = dataSource.find((item) => item.id === row.id);
        return (
          <ActionContainer key={`action_${row.id}`}>
            <Switch
              checked={findData?.ativo === 'S'}
              onChange={async () => {
                const newDataSource = dataSource.map((item) =>
                  item.id === row.id
                    ? { ...item, ativo: item.ativo === 'S' ? 'N' : 'S' }
                    : item
                );
                setDataSource(newDataSource);
                await updateSystemAssignment({
                  id: row.id,
                  body: {
                    ...row,
                    dataAlteracao: parseDate(row.dataAlteracao),
                    sistemaId: +id,
                    dataInclusao: parseDate(row.dataInclusao),
                    id: undefined,
                    ativo: findData.ativo === 'S' ? 'N' : 'S',
                    perfilId: +profileId,
                    usuarioAlteracao: row.usuarioInclusao,
                  },
                });
              }}
            />
            <Link
              to={`/admin/cadastro/atribuicao/2/${system}/${profile}/${id}/${row.id}/${profileId}`}
            >
              <Button
                type="dashed"
                className="button is-small is-warning"
                size="small"
              >
                <FontAwesomeIcon icon={['fas', 'user-cog']} /> &nbsp; Editar
                atribuição
              </Button>
            </Link>
          </ActionContainer>
        );
      },
    },
  ];

  const onChange = (value, field) => {
    onFilteredChangeCustom(value, field);
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleFilter = () => {
    const cleanString = (str) => str?.toLocaleLowerCase().trim();
    if (!form.description && !form.code && !form.status)
      return setFilteredDataSource(dataSource);
    const result = dataSource.filter(
      (item) =>
        (form.description &&
          cleanString(item.descricao).includes(
            cleanString(form.description)
          )) ||
        (form.status &&
          item.ativo.includes(form.status === 'active' ? 'S' : 'N')) ||
        (form.code && cleanString(item.codigo).includes(cleanString(form.code)))
    );
    setFilteredDataSource(result);
  };

  const onFilteredChangeCustom = (value, accessor, field) => {
    let filtered = [...filteredDataSource];
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        filter[field] = value;
      });
    }

    setFilteredDataSource(filtered);
  };

  return {
    columns,
    dataSource,
    getSystemAssignment,
    isLoading,
    onChange,
    form,
    handleFilter,
    onFilteredChangeCustom,
    filteredDataSource,
  };
};

export { useSystemAssignment };
