import api from 'services/api';

export const paramLimit = async () => {
  try {
    const res = await api.get(`usuario/listUsuario/paramLimit`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const list = async () => {
  try {
    const res = await api.get(`documento/tipo/filter`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const item = async (id) => {
  try {
    const res = await api.get(`documento/tipo/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const findByFilters = async (params) => {
  try {
    const cleanParams = {
      status: params.status || undefined,
      tipoDocumento: params.tipoDocumento || undefined,
    };
    const res = await api.get(`documento/tipo/filter`, { params: cleanParams });
        return res.data;
  } catch (error) {
    return error;
  }
};

export const register = async (body, userLogged) => {
  body.usuarioInclusao = userLogged;
  try {
    const res = await api.post(`documento/tipo/`, body);
    return res;
  } catch (error) {
    throw error;
  }
};

export const update = async (body, userLogged) => {
  body.usuarioAlteracao = userLogged;
  try {
    await api.put(`documento/tipo/${body.id}`, body);
  } catch (error) {
    throw error?.response?.data;
  }
};
