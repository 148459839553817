import styled from 'styled-components';

export const LicensesContainer = styled.div`
        display: flex;
        justify-content: space-evenly;
        margin: 1rem;
`;

export const LicenceWrapper = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
`;

export const HandleCaptarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: bottom;
  gap: 8px;
  margin: 0;
  margin-top: 1.4rem;
  float: right;
`

export const ButtonsCaptarContainer = styled.div`
display: flex;
gap: 8px;

`



