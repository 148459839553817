import {
  USUARIO_CHANGE_PESSOA,
  USUARIO_SITUACAO_USUARIO_CHANGE,
  USUARIO_SUCCESS,
  USUARIO_ERROR,
  USUARIO_CHANGE,
  USUARIO_LIST_POLO_COMMAND_CHANGE,
  USUARIO_LIST_POLO_COMMAND_ADD,
  USUARIO_LIST_POLO_COMMAND_REMOVE,
  USUARIO_PORTAL_CHANGE,
  USUARIO_LIST_POLO_COMMAND_CLEAN,
  USUARIO_ALERTA,
} from '../../../actions/Administrativo/Usuario/UsuarioType';
const userData = JSON.parse(localStorage.getItem('userDataCRP'));
const initialState = {
  botaoNovaLinhaDisable: true,
  carregando: false,
  succes: false,
  error: null,
  botaoSalvarHabilitado: true,
  lstUsuarioPoloCommand: [
    {
      lstUsuarioPoloPerfil: [
        {
          perfil: { id: '' },
        },
      ],
      usuarioPolo: {
        primeiroAcesso: '',
        polo: {
          id: '',
        },
        funcsituacao: 'N',
      },
      setor: {
        id: '',
      },
      funcao: { id: '' },
      setores: [],
    },
  ],
  usuarioPortal: {
    funcionario: '',
    chave: '',
    usuarioPortalLog: {
      id: userData && userData.usuarioPortalLog && userData.usuarioPortalLog.id,
    },
    conta: '',
    pessoa: {
      nome: '',
      emailComplemento: '@aeduead.com.br',
      cpf: '',
      email: '',
      admissao: '',
      ddd: '',
      telefone: '',
    },
    situacaoUsuario: {
      id: '1',
    },
  },
};

const usuario = (state = initialState, action) => {
  switch (action.type) {
    case USUARIO_SUCCESS:
      return { ...state, ...action.payload };
    case USUARIO_ERROR:
      return { ...state, ...action.payload };
    case USUARIO_ALERTA:
      return { ...state, ...action.payload };
    case USUARIO_CHANGE_PESSOA:
      return {
        ...state,
        usuarioPortal: {
          ...state.usuarioPortal,
          pessoa: {
            ...state.usuarioPortal.pessoa,
            [action.payload.prop]: action.payload.value,
          },
        },
      };
    case USUARIO_CHANGE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case USUARIO_SITUACAO_USUARIO_CHANGE:
      return {
        ...state,
        usuarioPortal: {
          ...state.usuarioPortal,
          situacaoUsuario: {
            ...state.usuarioPortal.situacaoUsuario,
            [action.payload.prop]: action.payload.value,
          },
        },
      };
    case USUARIO_PORTAL_CHANGE:
      return {
        ...state,
        usuarioPortal: {
          ...state.usuarioPortal,
          [action.payload.prop]: action.payload.value,
        },
      };
    case USUARIO_LIST_POLO_COMMAND_ADD:
      let novoObjeto = Object.assign({}, initialState.lstUsuarioPoloCommand[0]);
      novoObjeto.key = action.payload.key;
      return {
        ...state,
        lstUsuarioPoloCommand: [...state.lstUsuarioPoloCommand, novoObjeto],
      };
    case USUARIO_LIST_POLO_COMMAND_CHANGE:
      return {
        ...state,
        lstUsuarioPoloCommand: state.lstUsuarioPoloCommand.length
          ? state.lstUsuarioPoloCommand.map((lstUsuarioPoloCommand) => {
              if (lstUsuarioPoloCommand.key === action.payload.key) {
                return { ...lstUsuarioPoloCommand, ...action.payload };
              } else {
                return lstUsuarioPoloCommand;
              }
            })
          : [action.payload],
      };
    case USUARIO_LIST_POLO_COMMAND_CLEAN:
      return {
        ...state,
        lstUsuarioPoloCommand: initialState.lstUsuarioPoloCommand,
      };
    case USUARIO_LIST_POLO_COMMAND_REMOVE:
      return {
        ...state,
        lstUsuarioPoloCommand: state.lstUsuarioPoloCommand.filter(
          (el, index) => el.key !== action.payload.key
        ),
      };
    default:
      return state;
  }
};

export default usuario;
