import React, { useState, useEffect } from 'react';
import { UserTile, Container, Countdown } from './styles';
import { Button } from 'antd';
import Stopwatch from '../Stopwatch';
import Swal from 'sweetalert2';
import api from 'services/api';


const PersonifiedBanner = () => {


  const [timeout, setTimeout] = useState(false);
  const [userName, setUserName] = useState('Desconhecido')
  const [userPersonificado, setUserPersonificado] = useState('N')
  const userDataCRPLogin = localStorage.getItem('userDataCRP-Login');
  const loggedUserData = JSON.parse(userDataCRPLogin)

  const personificationData = localStorage.getItem('userDataCRP-UsuarioLogado');
  const realUserData = JSON.parse(personificationData)

  useEffect(() => {

    setUserPersonificado(loggedUserData[0]?.usuarioPortal.personificado);
    setUserName(loggedUserData[0]?.usuarioPortal.pessoa.nome);
  },[window.onload]);

  const handleTimeout = () => {
    setTimeout(true);
    logoutUserPersonification();
  };
  useEffect(() => {
    if (timeout) {
      logoutUserPersonification();
        }
  }, [timeout]);



  const getProfileType = (profile) => {
    const typeProfiles = {
      "1": "ADMINISTRATIVO",
      "504": "AMPLI",
      "42": "AVALIAÇÃO/PROVAS",
      "7": "COMERCIAL",
      "505": "COORDENADOR COMERCIAL",
      "63": "COORDENADOR ESTAGIO",
      "59": "COORDENADOR/GERENTE",
      "2": "CORPORATIVO",
      "502": "DIRETOR DE NEGOCIOS",
      "500": "DIRETOR UNIDADE",
      "25": "ESTAGIO",
      "501": "GERENTE DE NEGOCIOS",
      "506": "GERENTE COMERCIAL",
      "370": "GESTOR SECUNDARIO",
      "377": "GESTOR SECUNDARIO",
      "376": "GESTOR/GERAL",
      "380": "GESTOR/GERAL - DISTRATADO",
      "371": "OPERAÇÕES ACADEMICAS",
      "372": "OPERAÇÕES FINANCEIRAS",
      "6": "SECRETARIA",
      "999": "SUPER ADMIN",
      "34": "TUTOR",
      "382": "teste Perfil 2",
      "381": "teste perfil",
      "383": "teste perfil novo",
      "387": "teste teste 3"
    };

    return typeProfiles[profile] || "Tipo de Perfil Desconhecido";
  };



  const logoutUserPersonification = async () => {
    const realUserID = realUserData.id;
    const idUser = realUserID.toString();
    const userEmail = JSON.parse(userDataCRPLogin)[0]?.usuarioPortal.emailPersonificacao;
    const strUserEmail = userEmail.toString();
    const userTime = JSON.parse(userDataCRPLogin)[0]?.usuarioPortal.duracaoPersonificacao;

    try {
      await api.patch(`usuario/personificarUsuario/${idUser}`, {
        email: strUserEmail,
        tempo: userTime,
        isPersonificado: 'N'
      });
      setUserPersonificado('N')
      setUserName("Nome de usuário desconhecido");
      await window.close();
      await localStorage.clear()
      window.open('/login', '_self');
    } catch (error) {
      Swal.fire({
        title: 'Erro de servidor!',
        text: 'Não foi possível conectar ao servidor neste momento. Tente novamente depois',
        icon: 'error',
      });
    }
  };

  const perfil = localStorage.getItem('userDataCRP-Perfil');
  const userProfile = getProfileType(perfil);

  if( userPersonificado === 'S')  {
    return (
      <UserTile>
        <Container>
          <p> Você está utilizando o usuário {userName} e perfil {userProfile}</p>
          <Countdown>
            <Stopwatch onTimeout={handleTimeout} />
            <Button
              type="primary"
              size="small"
              title="Sair do usuário"
              onClick={logoutUserPersonification}
              disabled={timeout}
            >
              Sair do usuário
            </Button>
          </Countdown>
        </Container>
      </UserTile>
    );
    } else {
      return(
        <>
        </>
      )
  }
};


export default PersonifiedBanner;
