import Error from '../components/Error/Error';
import Header from '../components/Header/Header';
import HeaderAdmin from '../components/HeaderAdmin/HeaderAdmin';
import Footer from '../components/Footer/Footer';
import FooterAdmin from '../components/FooterAdmin/FooterAdmin';
import MenuAdmin from '../components/Administracao/MenuAdmin/MenuAdmin';
import Hero from '../components/Hero/Hero';
import ComunicadoDisplay from '../components/ComunicadosCarousel/ComunicadoDisplay';
import MapaDisplay from '../components/MapaDisplay/MapaDisplay';
import MenuDoc from '../components/Documentos/MenuDoc/MenuDoc';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Helmet from 'react-helmet';
import { bulmaCalendar } from 'bulma-extensions';
import $ from 'jquery';
import axios from 'axios/index';
import moment from 'moment/moment';
import Scrollbar from 'react-scrollbars-custom';
import BannerHome from '../components/BannerHome/BannerHome';
import ComunicadosCarousel from '../components/ComunicadosCarousel/ComunicadosCarousel';
import ReactCardFlip from 'react-card-flip';
import Flatpickr from 'react-flatpickr';
import ReactTags from 'react-tag-autocomplete';
import Tour from 'reactour';
import { DataTablesLangBr } from './../services/DataTablesLangBR';
import { ToolbarDraft } from './../services/ToolbarDraft';
import { TAGS } from './../services/Tags';
import { POLOS } from './../services/Polos';
import DualListBox from 'react-dual-listbox';
import queryString from 'query-string';

export { $ };
export { library };
export { FontAwesomeIcon };
export { Header };
export { HeaderAdmin };
export { Footer };
export { FooterAdmin };
export { MenuAdmin };
export { MenuDoc }
export { Hero };
export { ComunicadoDisplay };
export { MapaDisplay };
export { Helmet };
export { bulmaCalendar };
export { axios };
export { moment };
export { Scrollbar };
export { Error };
export { BannerHome };
export { ComunicadosCarousel };
export { ReactCardFlip };
export { Flatpickr };
export { ReactTags };
export { Tour };
export { DataTablesLangBr };
export { ToolbarDraft };
export { TAGS };
export { POLOS };
export { DualListBox };
export { queryString };
