import React, { Component } from 'react';
import iconePrisma from '../../../img/icone-prisma.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'antd';

export default class MenuAdmin extends Component {
  render() {
    const perfil = localStorage.getItem('userDataCRP-Perfil');
    const isSuperAdmin = perfil === '999';
    const isAdmin = perfil === '1';

    return (
      <aside key="AsideMenuAdmin" className="column is-2 aside hero is-fullheight">
        <div>
          <div className="compose has-text-centered">
            <a href={'/administracao'} className="button is-danger is-block is-bold">
              <strong>Dashboard</strong>
            </a>
          </div>
          <div className="main">
            <a href={'/admin/agenda'} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['far', 'calendar-alt']} />
              </span>
              <span className="name">Agenda</span>
            </a>
            <a href={'/admin/banners'} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['fa', 'images']} />
              </span>
              <span className="name">Banners</span>
            </a>
            <a href={'/admin/comunicado'} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['fas', 'newspaper']} />
              </span>
              <span className="name">Comunicados</span>
            </a>
            <a href={'/admin/list/faq '} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['fa', 'question']} />
              </span>
              <span className="name">FAQ</span>
            </a>
            {isSuperAdmin && (
              <div>
                <a href={'/admin/mapa'} className="item">
                  <span className="icon">
                    <FontAwesomeIcon icon={['fas', 'layer-group']} />
                  </span>
                  <span className="name">Mapa Operações</span>
                </a>
                <a href={'/admin/perfis'} className="item">
                  <span className="icon">
                    <FontAwesomeIcon icon={['fa', 'user']} />
                  </span>
                  <span className="name">Perfis de Acesso</span>
                </a>
                <a href={'/admin/parameters'} className="item">
                  <span className="icon">
                    <FontAwesomeIcon icon={['fa', 'database']} />
                  </span>
                  <span className="name">Parâmetros</span>
                </a>
                <a href={'/admin/search'} className="item">
                  <span className="icon">
                    <FontAwesomeIcon icon={['fa', 'search']} />
                  </span>
                  <span className="name">Pesquisas</span>
                </a>
                <a href={'/admin/portais'} className="item">
                  <span className="icon">
                    <FontAwesomeIcon icon={['fa', 'list']} />
                  </span>
                  <span className="name">Portais</span>
                </a>
              </div>
            )}
            <a href={'/admin/relatorios'} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['fa', 'money-check']} />
              </span>
              <span className="name">Relatórios</span>
            </a>
            { (isSuperAdmin || isAdmin) && (
              <>
                <a href={'/admin/usuario'} className="item">
                  <span className="icon">
                    <FontAwesomeIcon icon={['fas', 'users']} />
                  </span>
                  <span className="name">Usuários</span>
                </a>
              </>
            )}
            { isSuperAdmin && (
              <>
                <a href={'/admin/personification'} className="item">
                  <span className="icon">
                  <FontAwesomeIcon icon={['fas', 'users-cog']} />
                  </span>
                  <span className="name">Simular usuário</span>
                </a>
              </>
            )}
            <a href={'/home'} className="item">
              <span className="imagemPrisma">
                <img src={iconePrisma} width="24" alt="Prisma" />
              </span>
              <span className="name">Voltar ao portal</span>
            </a>
          </div>
        </div>
      </aside>
    );
  }
}
