import React, { useEffect } from 'react';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../services/Gerais';
import { Table, Input, Select, Button, Form } from 'antd';
import { InputWrapper, SystemDataContainer } from './styles';
import { useSystemAssignment } from './hook';
import { Link } from 'react-router-dom';

const SystemAssignments = (props) => {
  const {
    match: {
      params: { id, system, profile, profileId },
    },
  } = props;
  const {
    columns,
    isLoading,
    form,
    onChange,
    handleFilter,
    filteredDataSource,
    onFilteredChangeCustom,
  } = useSystemAssignment(id, system, profile, profileId);
  const { Option } = Select;

  return (
    <>
      <Helmet
        key="TitleAdminAtribuições"
        title="Atribuições do Sistema - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Atribuições' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, usuarios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAtribuições" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminPerfis" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAtribuições"
                pageName="Atribuições"
                titulo="Atribuições do Sistema"
                button={
                  <Link
                    to={`/admin/cadastro/atribuicao/1/${system}/${profile}/${id}/${profileId}`}
                  >
                    <Button type="primary" size="large">
                      Nova atribuição
                    </Button>
                  </Link>
                }
              />
            </div>
          </div>
          <SystemDataContainer>
            <div>
              <strong>Perfil:</strong>
              <p>{profile}</p>
            </div>

            <div>
              <strong>Sistema:</strong>
              <p>{system}</p>
            </div>
          </SystemDataContainer>
          <Form>
            <InputWrapper>
              <Form.Item style={{ width: '100%' }} label="Código">
                <Input
                  value={form.code}
                  onChange={(event) => {
                    onChange(event.target.value, 'code');
                  }}
                  placeholder="Código"
                  size="large"
                />
              </Form.Item>

              <Form.Item style={{ width: '100%' }} label="Descrição">
                <Input
                  value={form.description}
                  onChange={(event) =>
                    onChange(event.target.value, 'description')
                  }
                  placeholder="Descrição"
                  size="large"
                />
              </Form.Item>

              <Form.Item style={{ width: '100%' }} label="Status">
                <Select
                  value={form.status}
                  onChange={(value) => onChange(value, 'status')}
                  size="large"
                  placeholder="Status"
                >
                  <Option value="active">Ativo</Option>
                  <Option value="inactive">Inativo</Option>
                </Select>
              </Form.Item>

              <Button onClick={handleFilter}>Pesquisar</Button>
            </InputWrapper>
          </Form>

          <Table
            loading={isLoading}
            columns={columns}
            dataSource={filteredDataSource}
            size="small"
          />
        </div>
      </section>
      <FooterAdmin key="FooterAdminAtribuiçõesLista" />
    </>
  );
};

export default SystemAssignments;
