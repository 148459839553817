import React from 'react';
import AgendaDia from '../AgendaDia';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ptBr from '@fullcalendar/core/locales/pt-br';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import '../../../styles/components/Agenda.scss';

export default function MiniCalendar({ calendarRef, poloIdSelecionado }) {
  return (
    <>
      <div id="miniCalendar" key="calendar">
        <FullCalendar
          defaultView="dayGridMonth"
          titleFormat={{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
          header={{
            left: 'title',
            right: 'prev,next',
            locale: 'pt-br',
          }}
          height="500"
          contentHeight="500"
          locale={ptBr}
          theme={false}
          selectable
          navLinks={false}
          select={function (info) {
            calendarRef.calendar.changeView('timeGrid', info.start);
          }}
          plugins={[interactionPlugin, dayGridPlugin]}
        />
      </div>
      <h4 className="title is-5 paddingAgendaTitle agendaEventosTitle">
        Eventos do dia
      </h4>
      <AgendaDia poloSelecionado={poloIdSelecionado} />
    </>
  );
}
