// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_header__1Bpuv {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.style_title__1TX7X {\n  margin-right: auto;\n}\n\n.style_action__G9y6l {\n  padding-left: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "style_header__1Bpuv",
	"title": "style_title__1TX7X",
	"action": "style_action__G9y6l"
};
module.exports = exports;
