import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import config from '../../../config/config';
import readXlsxFile from 'read-excel-file';
import {
  createImport,
  getProfiles as _getProfiles,
  getSectorByProfile,
} from 'services/importFiles';



function VerifyFileFields(list){
  const defaultValues = "cpf,email,emailSecundario,nome,tipoColaborar,situacaoUsuario,ddd,telefone,poloId,situacaoPolo"

  const listToVerify = list[0].toString()
  if(listToVerify === defaultValues) {
    return true
  } else {
    Swal.fire({
      text: 'O arquivo enviado não está de acordo com o template. Por favor, corrija e envie novamente.',
      willClose: () => {
      window.location.href = '/admin/importacao-arquivos';
      },
    });
    return false
  }
}



const useImportFiles = () => {

  const clearDataBeforeImport = async () => {
    try {
      setFileList([])
      setFileJson([])
    } catch {
      Swal.fire({
        title: 'Erro ao importar',
        willClose: () => {
          window.location.href = '/admin/importacao-arquivos';
    }})
    }
  }


  const [form, setForm] = useState({
    atualizarDadosPessoa: '',
    perfilId: '',
    funcaoSetorId: '',
    funcaoDescricao: ''
  });
  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;
  const [fileList, setFileList] = useState([]);
  const [functionOptions, setFunctionOptions] = useState([]);
  const [profileOptions, setProfileOptions] = useState([]);
  const [fileJson, setFileJson] = useState([]);
  const [disableFileButton, setDisableFileButton] = useState(false);


     const uploadProps = {
      name: 'file',
      action: `${config.baseUrl}file/upload`,
      headers: {
        authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`,
      },
      accept: '.xlsx',

      onRemove(){
          clearDataBeforeImport()
          setDisableFileButton(false)
        },


      onChange(info) {

        const fileExtension = (info.file.name).split('.').pop();

        if(fileExtension != 'xlsx'){
          clearDataBeforeImport()
          Swal.fire({
            text: 'O arquivo enviado não é um arquivo XLSX. Por favor, corrija e envie novamente.',
            willClose: () => {
            window.location.href = '/admin/importacao-arquivos';
            },
          });
          return
        }

        if (!info.file.status) {
          return;
        }

        if (info.file.status === 'done') {



          if (!info.file.alreadyProcessed) {
            info.file.alreadyProcessed = true;

            const dataArray = [];

            readXlsxFile(info.file.originFileObj)
          .then((rows) => {
            if (!VerifyFileFields(rows)) {
              clearDataBeforeImport();
              return;
            }

            rows.forEach((item) => {
              if (item[1] === 'email') return;

              dataArray.push({
                cpf: item[0],
                email: item[1],
                emailSecundario: item[2],
                nome: item[3],
                tipoColaborar: item[4],
                situacaoUsuario: item[5],
                ddd: item[6],
                telefone: item[7],
                poloId: item[8],
                situacaoPolo: item[9],
              });
            });

            setFileJson({ data: dataArray });
          })
          .catch((error) => {
            Swal.fire({
              text: 'Ocorreu um erro ao ler o arquivo. Por favor, verifique o formato do arquivo e tente novamente.',
              willClose: () => {
                window.location.href = '/admin/importacao-arquivos';
              },
            });
          });

            setFileList(info.fileList);
            setDisableFileButton(true)
          }
        }
      },
    };




  const handleSectorByProfile = async (id) => {
    try {
      const { status, data } = await getSectorByProfile({ id });
      if (status === 200 || status === 201) {
        setFunctionOptions(data);
        onChange(data[0].id, 'funcaoSetorId');
        onChange(data[0].funcao.descricao, 'funcaoDescricao')


      }
    } catch (error) {}
  };

  useEffect(() => {
    getProfiles();
  }, []);

  const changeSetorFuncaoId = (funcaoId) => {
    functionOptions.forEach(item => {
      if(item.funcao.id == funcaoId) {
        onChange(item.id, 'funcaoSetorId')
      }
    })
  }

  const onChange = (value, field) => {
    setForm((state) => ({
      ...state,
      [field]: value,
    }));
  };


  const getProfiles = async () => {
    try {
      const { status, data } = await _getProfiles();
      if (status === 200) {
        const profiles = Object.keys(data.Perfil);
        const functions = Object.keys(data.Funcao);
        const parseOptions = (array, data) => {
          const result = [];
          array.map((id) => {
            result.push({
              value: +id,
              label: data[+id],
            });
          });
          return result;
        };
        setProfileOptions(
          parseOptions(profiles, data.Perfil).sort((a, b) =>
            a.label > b.label ? 1 : -1
          )
        );
      }
    } catch (error) {
      Swal.fire({
        text: 'Ocorreu um problema ao buscar os perfis, tente novamente!',
      });
    }
  };


  const importFile = async () => {
    try {
      const allUploads = [];

      fileJson.data.forEach((item) => {
       allUploads.push({ ...item, poloId: item.poloId || null })


      });

       const body = {
        nomeArquivo: fileList[0].name,
        atualizarDadosPessoa: form.atualizarDadosPessoa,
        perfilId: +form.perfilId,
        funcaoSetorId: +form.funcaoSetorId,
        usuarioInclusao: parseSession[0]?.usuarioPortal?.id,
        usuarioUpload: allUploads,
      };

      const status = await createImport({ body });

       if (status === 200 || status === 201) {
        Swal.fire({
          title: 'Importação criada com sucesso!',
          willClose: () => {
            window.location.href = '/admin/importacao-usuarios';
          },
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocorreu um erro na importação. Por favor, tente novamente.',
        willClose: () => {
          window.location.href = '/admin/importacao-arquivos';
        },
      });
    }
  };

  const buttonDisabled =
    !fileList.length ||
    !form.atualizarDadosPessoa ||
    !form.funcaoSetorId ||
    !form.perfilId;

  return {
    uploadProps,
    fileList,
    fileJson,
    disableFileButton,
    form,
    buttonDisabled,
    profileOptions,
    functionOptions,
    handleSectorByProfile,
    changeSetorFuncaoId,
    importFile,
    onChange
  };
};

export { useImportFiles };


