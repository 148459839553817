import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;

  * {
    font-family: 'open_sanssemibold', sans-serif;
  }
`;