import {
  USUARIO_SUCCESS,
  USUARIO_ERROR,
  USUARIO_CHANGE_PESSOA,
  USUARIO_SITUACAO_USUARIO_CHANGE,
  USUARIO_LIST_POLO_COMMAND_CHANGE,
  USUARIO_LIST_POLO_COMMAND_ADD,
  USUARIO_LIST_POLO_COMMAND_REMOVE,
  USUARIO_PORTAL_CHANGE,
  USUARIO_CHANGE,
  USUARIO_LIST_POLO_COMMAND_CLEAN,
  USUARIO_ALERTA,
} from './UsuarioType';

import api from '../../../../services/api';

import { getPolosByCanal } from './DadosPerfilAction';

export const getUsuario = async id => {
  return async function(dispatch) {
    try {
      const res = await api.get(`usuario/getUsuario/${id}`);

      if (
        res.data &&
        res.data.Usuario &&
        res.data.Usuario.lstUsuarioPoloCommand.length > 0
      ) {
        res.data.Usuario.lstUsuarioPoloCommand.forEach((item, index) => {
          item.key = `${index}_${new Date().getTime()}`;
          item.usuarioPolo.funcsituacao = 'A';
        });
      }

      dispatch(getPolosByCanal(res.data.Usuario.usuarioPortal.funcionario));

      return dispatch({
        type: USUARIO_SUCCESS,
        payload: {
          ...res.data.Usuario,
          carregando: false,
          botaoSalvarHabilitado: false,
        },
      });
    } catch (error) {
      return dispatch({ type: USUARIO_ERROR, payload: { error: error } });
    }
  };
};

export const getSetores = async (perfilId, key) => {
  try {
    const res = await api.get(`setor/setorByPerfil/${perfilId}`);

    return dispatch => {
      dispatch({
        type: USUARIO_LIST_POLO_COMMAND_CHANGE,
        payload: { setores: res.data.setores, key },
      });
    };
  } catch (error) {
    return dispatch => {
      dispatch({
        type: USUARIO_ERROR,
        payload: {
          error: error,
          carregando: false,
        },
      });
    };
  }
};

export const salvaUsuario = async data => {
  try {
    const res = await api.post(`usuario/saveUsuario`, data, {});
    return dispatch => {
      dispatch({
        type: USUARIO_SUCCESS,
        payload: {
          ...res.data,
          succes: true,
          carregando: false,
          error: null,
          botaoSalvarHabilitado: false,
        },
      });
    };
  } catch (error) {
    let erro = error;
    if (erro && erro.response && erro.response.data) {
      erro = erro.response.data;
    }

    if(error && error.response && error.response.status === 409){
      return dispatch => {
        dispatch({
          type: USUARIO_ERROR,
          payload: {
            error: error.response,
            succes: false,
            carregando: false,
            botaoSalvarHabilitado: false,
          },
        });
      };
    } else {
      return dispatch => {
        dispatch({
          type: USUARIO_ALERTA,
          payload: {
            error: erro,
            succes: false,
            carregando: false,
            botaoSalvarHabilitado: false,
          },
        });
      };
    }
  }
};

export const alteraUsuario = async (data, id) => {
  try {
    await api.put(`usuario/saveUsuario/${id}`, data);

    return dispatch => {
      dispatch({
        type: USUARIO_SUCCESS,
        payload: { succes: true, carregando: false, error: null },
      });
    };
  } catch (error) {
    let erro = error;
    if (erro && erro.response && erro.response.data) {
      erro = error.response.data;
    }

    if(error && error.response && error.response.status === 409){
      return dispatch => {
        dispatch({
          type: USUARIO_ERROR,
          payload: {
            error: error.response,
            succes: false,
            carregando: false,
            botaoSalvarHabilitado: false,
          },
        });
      };
    } else {
      return dispatch => {
        dispatch({
          type: USUARIO_ALERTA,
          payload: {
            error: erro,
            succes: false,
            carregando: false,
            botaoSalvarHabilitado: false,
          },
        });
      };
    }
  }
};

export const cleanListaUsuarioPolo = () => {
  return dispatch => {
    dispatch({ type: USUARIO_LIST_POLO_COMMAND_CLEAN });
  };
};

export const changeUsuarioPessoa = ({ prop, value }) => {
  return dispatch => {
    dispatch({ type: USUARIO_CHANGE_PESSOA, payload: { prop, value } });
  };
};

export const changeUsuario = ({ prop, value }) => {
  return dispatch => {
    dispatch({ type: USUARIO_CHANGE, payload: { prop, value } });
  };
};

export const changeUsuarioSituacao = ({ prop, value }) => {
  return dispatch => {
    dispatch({
      type: USUARIO_SITUACAO_USUARIO_CHANGE,
      payload: { prop, value },
    });
  };
};

export const changeLstPoloCommand = lstPoloCommand => {
  return dispatch => {
    dispatch({
      type: USUARIO_LIST_POLO_COMMAND_CHANGE,
      payload: lstPoloCommand,
    });
  };
};

export const addLstPoloCommand = obj => {
  return dispatch => {
    dispatch({ type: USUARIO_LIST_POLO_COMMAND_ADD, payload: obj });
  };
};

export const removeLstPoloCommand = key => {
  return dispatch => {
    dispatch({ type: USUARIO_LIST_POLO_COMMAND_REMOVE, payload: { key } });
  };
};

export const changeUsuarioPortal = ({ prop, value }) => {
  return dispatch => {
    dispatch({ type: USUARIO_PORTAL_CHANGE, payload: { prop, value } });
  };
};

