import React, { useState, useEffect } from 'react';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../services/Gerais';
import ParameterForm from './Form';
import { withRouter } from 'react-router-dom'

const Cadastro = props => {
  const [tituloPagina, setTituloPagina] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [idParameter, setIdParameter] = useState('');
  useEffect(
    () => {
      if (props && props.match && props.match.params && props.match.params.id) {
        setIsEdit(true);
        setTituloPagina('Editar Parametro');
        setIdParameter(props.match.params.id);
      } else {
        setTituloPagina('Cadastro de Parametro');
      }
    },
    [props]
  );

  return (
    <>
      <Helmet
        key="TitleAdminAgendaCadastro"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- ' + tituloPagina,
          },
          {
            name: 'keywords',
            content: 'cadastro, comunicados, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAdminAgendaCadastro" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminAgendaCadastro" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <Hero
            key="heroAdminCadastroAgenda"
            pageName={`Administração - ${tituloPagina}`}
            titulo={tituloPagina}
          />
          <ParameterForm
            form={props.form}
            isEdit={isEdit}
            history={props.history}
            idParameter={idParameter}
            history2={props.history}
          />
        </div>
      </section>
      <FooterAdmin key="FooterAdminUsuariosCadastro" />
    </>
  );
};

export default withRouter(Cadastro);
