import { Button, FontAwesomeIcon } from 'antd';
import React from 'react';
import { Scrollbar, moment } from '../../../services/Gerais';
import '../../../styles/Agenda.scss';
export default function AgendaDisplay({
  agendaDisplay,
  conteudoLink,
  toggleNavedAgenda,
  isEdit,
}) {
  return (
    <div key={'displayAgenda'} className={`conteudoLink ${conteudoLink}`}>
      {typeof agendaDisplay !== 'undefined' && (
        <div className="container is-fullhd">
          <div className="columns is-gapless">
            <div
              className="column is-transparent"
              id="columnClose"
              onClick={toggleNavedAgenda}
            />
            <div className={'column informacoesPortal informacoesComunicados'}>
              <span
                className="fechar"
                title="Fechar"
                onClick={toggleNavedAgenda}
              >
                {' '}
                X{' '}
              </span>
              <Button
                type="primary"
                size="small"
                className={(isEdit == true && agendaDisplay.type == "#FF8C00" ? "edit-agenda" : "is-invisible")}
                href={`./cadastro/agenda/${agendaDisplay.id}`}>
                Editar
              </Button>
              <Scrollbar
                style={{
                  width: '100%',
                  height: '40vw',
                  minHeight: 300,
                }}
              >
                <br />
                <div className="has-text-justified descricaoComunicados ScrollbarsCustom native mostraCategoria">
                  <h5 className="title is-spaced comunicadoCat">
                    {agendaDisplay.titleDisplay}
                  </h5>
                  <div className="content comunicadoCat">
                    <p>
                      Início:{' '}
                      <strong>
                        {moment(
                          agendaDisplay.dataInicio,
                          'DD-MM-YYYY HH:mm:ss'
                        ).format('DD-MM-YYYY HH:mm')}
                      </strong>
                    </p>
                    <p>
                      Término:{' '}
                      <strong>
                        {moment(
                          agendaDisplay.dataFim,
                          'DD-MM-YYYY HH:mm:ss'
                        ).format('DD-MM-YYYY HH:mm')}
                      </strong>
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: agendaDisplay.conteudo,
                      }}
                    />
                  </div>
                  <hr />
                  {agendaDisplay.urlSite && (
                    <a
                      className="button is-text-centered is-fullwidth"
                      href={agendaDisplay.urlSite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Acesse!
                    </a>
                  )}
                </div>
              </Scrollbar>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
