import React from 'reactn';
import {
  $,
  axios,
  FontAwesomeIcon
} from '../../../services/Gerais';
import Swal from 'sweetalert2';

class ListaSala extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salas: [],
    }
    this.poloId = '';
  }

  componentDidMount() {
    this.poloId = localStorage.getItem('userDataCRP-Polo') || $('.selectPoloHeader select').val();

    this.setState({
      salas: this.props.lista,
    })
  }

  checkNegativo(e) {
    let input = $(e.target);
    if(input.val() < 0) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Valor não pode ser menor que 0!',
        icon: 'error',
      })
      input.val('');
    }
  }

  handleSelect(e) {
    let select = $(e.target);
    let nomeSala = select.closest('.columns').find('#nomeSala');

    if(select.val() == 'new') {
      nomeSala.attr('disabled', false);
      nomeSala.val('');
    } else {
      nomeSala.attr('disabled', true);
      if(select.val()) nomeSala.val(select.find('option:selected').html())
    }
  }

  removeSala(e) {
    let icon = e.target;
    $(icon.closest('.salaRow')).removeClass('salaRow').hide();
  }

  render() {
    const { salas } = this.state;

    return (
      <>
      <div className="columns is-multiline salaRow">
        <div className="column is-4">
          <div className="field">
            <label htmlFor="sala">Sala*</label>
            <div className="control">
              <div className="select fullwidth">
                <select name="sala" id="sala" className="fullwidth" onChange={this.handleSelect}>]
                  <option value="">Selecione...</option>
                  <option value="new" style={{ 'background-color': '#888888', 'color': '#fff'  }}>Incluir Nova Sala</option>
                  {salas.map(sala => {
                    return (
                      <option value={sala.id}>{sala.nomeSala}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <label htmlFor="nomeSala">Nome da sala*</label>
            <input name="nomeSala" id="nomeSala" type="text" className="input" disabled/>
          </div>
        </div>
        <div className="column is-2">
          <div className="field">
            <label htmlFor="capacidade">Capacidade*</label>
            <input name="capacidade" id="capacidade" type="number" min="0" className="input" onChange={this.checkNegativo}/>
          </div>
        </div>
        <span className="icon removeHorario" onClick={this.removeSala}><FontAwesomeIcon icon={["fas", "times-circle"]}/></span>
      </div>
      </>
    )
  }
}
export default ListaSala;
