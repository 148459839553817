import React, { Component } from 'react';
import { Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class MenuDoc extends Component {
  render() {
    return [
      <aside
        key="AsideMenuDoc"
        className="column is-2 aside hero is-fullheight"
      >
        <div>
          <div className="main">
            <a href={'/geradoc/envioDocumento'} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['far', 'file']} />
              </span>
              <span className="name">Geradora</span>
            </a>
            <a href={'/geradoc/basePolos'} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['fa', 'building']} />
              </span>
              <span className="name">Base Polos</span>
            </a>
            <a href={'/geradoc/tipoDocumento'} className="item">
              <span className="icon">
                <FontAwesomeIcon icon={['fa', 'filter']} />
              </span>
              <span className="name">Tipo de Documentos</span>
            </a>
            <a href={'/geradoc/workflow'} className="item" style={{ paddingLeft: 38 }}>
              <span className="iconMenuDoc">
                <Icon type="deployment-unit" />
              </span>
              <span className="name">Documentos</span>
            </a>
          </div>
        </div>
      </aside>,
    ];
  }
}
