import React, { Component } from 'reactn';
import { Header, Footer, Hero, Helmet, $ } from '../../services/Gerais';
import '../../styles/EstruturaPadrao.scss';
import 'antd/dist/antd.css';
import { Row, Col, Form, Select, Input, Button, InputNumber, AutoComplete, Checkbox } from 'antd';
import api from '../../services/api';
import { cpfMask } from '../../utils/masks';
import Swal from 'sweetalert2';

import validacaoCpf from '../../utils/validadores/CPFValidator';
const { Option } = Select;
const locale = "pt-BR";

class EstruturaPadraoUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            isUserPortal: false,
            expense: {
                "estrururaPoloDespesa": {}
            },
            laboratorios: [],
            modelos: [],
            isCAPE: false,
            isConvenio: false,
            lstPolos: [],
            laboratorioInfo: {
                cape: 'N',
                convenio: 'N'
            },
            isLaboratorioProprio: false,
            enableCAPE: true,
            enableConvenio: true
        };
        this.area = (this.props.match.params.area == "AD" ? JSON.parse(localStorage.getItem('AreaAdministrativa')) : JSON.parse(localStorage.getItem('AreaAcademica')))
        this.titlePage = (this.props.match.params.type == "despesas" ? "Despesas" : "Cadastro de usuário");
        this.url = JSON.parse(localStorage.getItem('cnfgDataCRP'));
        this.location = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
        this.estruturaPoloArea = "";
        this.laboratorio = {};
        this.userLogged = JSON.parse(localStorage.getItem('userDataCRP'));
    }

    save = () => {
        var list = {
            "idPessoa": this.state.user.idPessoa,
            "id": this.state.user.id,
            "estruturaPolo": {
                "id": (this.props.match.params.type == "cadastro" ? window.location.href.substr(window.location.href.lastIndexOf('/') + 1) : this.state.user.estruturaPolo.id)
            },
            "nomeFuncionario": this.state.user.nomeFuncionario,
            "cpf": this.state.user.cpf,
            "classificacao": (this.state.user.classificacao ? this.state.user.classificacao : this.props.match.params.area),
            "tipoRegistro": this.state.user.tipoRegistro,
            "prcRateio": this.state.user.prcRateio,
            "idUsuarioInclusao": this.state.user.idUsuarioInclusao,
            "dataInclusao": this.state.user.dataInclusao,
        };
        if (this.state.user.qtdHoraAulaMesAcad > 0 && this.state.user.classificacao == "AC") {
            if (this.state.user.formatoSalarioAcad == 'M') {
                list.qtdHoraAulaMesAcad = 0;
            } else {
                list.qtdHoraAulaMesAcad = this.state.user.qtdHoraAulaMesAcad;
            }
        }
        if (this.state.user.vlrHoraAulaAcad > 0 && this.state.user.classificacao == "AC") {
            if (this.state.user.formatoSalarioAcad == 'M') {
                list.vlrHoraAulaAcad = 0;
            } else {
                list.vlrHoraAulaAcad = this.state.user.vlrHoraAulaAcad;
            }
        }
        if (this.state.user.idEstPoloAreaAcad > 0 && this.state.user.classificacao == "AC") {
            list.idEstPoloAreaAcad = this.state.user.idEstPoloAreaAcad;
        }
        if (this.state.user.formatoSalarioAcad != null && this.state.user.classificacao == "AC") {
            list.formatoSalarioAcad = this.state.user.formatoSalarioAcad;
        }
        if (this.state.user.vlrSalario != null) {
            if (this.state.user.formatoSalarioAcad == 'H') {
                list.vlrSalario = 0;
            } else {
                list.vlrSalario = this.state.user.vlrSalario;
            }
        }
        if (this.state.user.prcRateio == null | this.state.user.prcRateio == 0) {
            list.prcRateio = 100;
        }
        if (this.state.user.descrCargoAdm != null && this.state.user.classificacao == "AD") {
            list.descrCargoAdm = this.state.user.descrCargoAdm;
        }
        if (this.estruturaPoloArea > 0 && this.state.user.classificacao == "AC") {
            list.estruturaPoloAreaAcad = {
                "id": parseInt(this.estruturaPoloArea)
            }
        }
        if (this.estruturaPoloArea && this.estruturaPoloArea.id > 0 && this.state.user.classificacao == "AC") {
            list.estruturaPoloAreaAcad = this.estruturaPoloArea
        }
        if (this.estruturaPoloArea > 0 && this.state.user.classificacao == "AD") {
            list.estruturaPoloAreaAdm = {
                "id": parseInt(this.estruturaPoloArea)
            }
        }
        if (this.estruturaPoloArea && this.estruturaPoloArea.id > 0 && this.state.user.classificacao == "AD") {
            list.estruturaPoloAreaAdm = this.estruturaPoloArea
        }

        if (this.props.match.params.type == "cadastro") {
            $.ajax({
                type: 'POST',
                url: `${this.url.baseUrl}estruturaPolo/persistirFuncionario/${this.userLogged.usuarioPortal.id}`,
                contentType: 'application/json; charset=utf-8',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk')
                },
                data: JSON.stringify(list),
                success: function (resp) {
                    Swal.fire({
                        position: 'top-end',
                        title: `Usuario criado com successo !`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(result => {
                        window.location.replace('/estrutura')
                    })
                },
                error: function (error) {
                    Swal.fire({
                        position: 'top-end',
                        title: error.responseJSON.Errors,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }
            });
        } else {
            $.ajax({
                type: 'PUT',
                url: `${this.url.baseUrl}estruturaPolo/atualizarFuncionario/${this.userLogged.usuarioPortal.id}`,
                contentType: 'application/json; charset=utf-8',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk')
                },
                data: JSON.stringify(list),
                success: function (resp) {
                    Swal.fire({
                        position: 'top-end',
                        title: `Usuario atualizado com successo !`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(result => {
                        window.location.replace('/estrutura')
                    })
                },
                error: function (error) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Ops...!',
                        text: error.responseJSON.error,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            });
        }
    }

    saveExpense = () => {
        if (this.props.match.params.area == 'cadastro') {
            let list = {
                "estruturaPolo": { "id": this.props.match.params.idEstPolo },
                "estruturaPoloDespesa": {
                    "tipoConta": this.state.expense.tipoConta,
                    "codReduzido": this.state.expense.codReduzido,
                    "descrDespesa": this.state.expense.descrDespesa,
                    "ativo": "S"
                },
                "vlrDespesa": this.state.expense.vlrDespesa,
                "prcRateio": this.state.expense.prcRateio
            }
            $.ajax({
                type: 'POST',
                url: `${this.url.baseUrl}estruturaPolo/saveDespesaReceita`,
                contentType: 'application/json; charset=utf-8',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk')
                },
                data: JSON.stringify(list),
                success: function (resp) {
                    Swal.fire({
                        position: 'top-end',
                        title: `Despesa salva com successo !`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(result => {
                        window.location.replace('/estrutura')
                    })
                }
            });
        } else {
            var list = {
                "idEstPoloDespesaPeriodo": this.state.expense.id,
                "vlrDespesa": this.state.expense.vlrDespesa,
                "prcRateio": this.state.expense.prcRateio
            }
            $.ajax({
                type: 'PUT',
                url: `${this.url.baseUrl}estruturaPolo/atualizarDespesa/${this.userLogged.usuarioPortal.id}`,
                contentType: 'application/json; charset=utf-8',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk')
                },
                data: JSON.stringify(list),
                success: function (resp) {
                    Swal.fire({
                        position: 'top-end',
                        title: `Despesa salva com successo !`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(result => {
                        window.location.replace('/estrutura')
                    })
                }
            });
        }
    }

    saveLaboratorio = () => {
        this.state.laboratorioInfo.idUsuarioLogado = this.userLogged.id
        if (this.props.match.params.type == 'laboratorio' && this.props.match.params.area == 'edit') {
            $.ajax({
                type: 'PUT',
                url: `${this.url.baseUrl}estruturaPolo/laboratorios/${this.props.match.params.idEstPolo}`,
                contentType: 'application/json; charset=utf-8',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk')
                },
                data: JSON.stringify(this.state.laboratorioInfo),
                success: function (resp) {
                    Swal.fire({
                        position: 'top-end',
                        title: `Laboratório alterado com successo !`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(result => {
                        window.location.replace('/estrutura')
                    })
                }
            });
        } else {
            this.state.laboratorioInfo.estruturaPolo = { "id": parseInt(this.props.match.params.idEstPolo) }
            if (this.state.laboratorioInfo.cape == 'N') {
                this.state.laboratorioInfo.idPoloCape = null;
            }
            if (this.state.laboratorioInfo.convenio == 'N') {
                this.state.laboratorioInfo.convenioEmpresa = "";
            }

            $.ajax({
                type: 'POST',
                url: `${this.url.baseUrl}estruturaPolo/laboratorios`,
                contentType: 'application/json; charset=utf-8',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk')
                },
                data: JSON.stringify(this.state.laboratorioInfo),
                success: function (resp) {
                    Swal.fire({
                        position: 'top-end',
                        title: `Laboratório salvo com successo !`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(result => {
                        window.location.replace('/estrutura')
                    })
                }
            });
        }
    }

    changeCpf = (e) => {
        var cpf = e.replace(/\D/g, '');
        this.state.user.cpf = cpf;
        return e;
    };

    renderOption = (item) => {
        return (
            <Option key={item.id} text={item.descrArea} >
                {item['descrArea'] ? item.descrArea : item.descrArea}
            </Option>
        );
    }

    renderLaboratorios = (item) => {
        return (
            <Option key={item.id} text={item.descricao} >
                {item['descricao'] ? item.descricao : item.descricao}
            </Option>
        );
    }

    componentDidMount() {
        this.getUser();
        this.getLaboratorio();
        this.getPolo();
        if (this.props.match.params.type == 'laboratorio' && this.props.match.params.area == 'edit') {
            this.getLaboratorioId(this.props.match.params.idEstPolo);
        }
        switch (this.props.match.params.type) {
          case "despesas":
            this.titlePage = 'Cadastro de Despesas';
            break;
          case "laboratorio":
            this.titlePage = 'Cadastro de Laboratório';
            break;
        }
        this.render();
    }

    getPolo = async () => {
        const resp = await api.get('polo/')
        this.setState({ lstPolos: resp.data })
    }

    getUser = async () => {
        if (this.props.match.params.type == "cadastro") {
            if (this.props.match.params.area == "AD") {
                this.state.user.classificacao = "AD";
            } else if (this.props.match.params.area == "AC") {
                this.state.user.classificacao = "AC";
            }
            this.render();
            return;
        }
        if (this.props.match.params.type == "despesas") {
            const resp = await api.get(`estruturaPolo/buscaEstPoloDespesa/${(window.location.href.substr(window.location.href.lastIndexOf('/') + 1))}`)
            this.getExpense(resp.data.EstruturaPoloDespesaPeriodo);
            this.render();
            return
        }
        const resp = await api.get(`estruturaPolo/buscarEstPolo/${(window.location.href.substr(window.location.href.lastIndexOf('/') + 1))}`)
        this.getData(resp.data.EstruturaPoloPessoa);
        if (resp.data.EstruturaPoloPessoa.classificacao == "AC") {
            this.getAreaAcad();
            this.estruturaPoloArea = resp.data.EstruturaPoloPessoa.estruturaPoloAreaAcad;
            this.render();
        } else if (resp.data.EstruturaPoloPessoa.classificacao == "AD") {
            this.getAreaAdm();
            this.estruturaPoloArea = resp.data.EstruturaPoloPessoa.estruturaPoloAreaAdm;
            this.render();
        }
        this.render();
    }

    getData = (params) => {
        this.setState({
            user: params,
            isUserPortal: (params.idPessoa == null ? false : true)
        })
    }

    getExpense = (params) => {
        this.setState({
            expense: params
        })

        this.render();
    }

    getAreaAcad = () => {
        this.area = JSON.parse(localStorage.getItem('AreaAcademica'));
        if (this.state.user && this.state.user.estruturaPoloAreaAcad && this.state.user.estruturaPoloAreaAcad.id > 0) {
            this.estruturaPoloArea = this.state.user.estruturaPoloAreaAcad;
            if(document.getElementById("area") == null) return
            document.getElementById("area").value = this.estruturaPoloArea.descrArea;
        } else if(this.props.match.params.type !== 'laboratorio'){
            this.estruturaPoloArea = this.area[0];
            document.getElementById("area").value = this.area[0].id;
        }
        this.render();
    }

    getAreaAdm = () => {
        this.area = JSON.parse(localStorage.getItem('AreaAdministrativa'));
        if (this.estruturaPoloArea == "") this.estruturaPoloArea = this.area[0];
        this.render();
    }

    getLaboratorio = async () => {
        const resp = await api.get(`estruturaPolo/laboratorios/buscaLaboratoriosModelos`)
        this.setState({
            laboratorios: resp.data.laboratorios,
            modelos: resp.data.modelos
        })
        this.render();
    }

    getLaboratorioId = async (id) => {
        const resp = await api.get(`estruturaPolo/laboratorios/${id}`)
        this.setState({
            laboratorioInfo: resp.data,
            enableCAPE: (resp.data.estPoloCadModelo.id == 3 ? false : true),
            enableConvenio: (resp.data.estPoloCadModelo.id  == 2 ||
                resp.data.estPoloCadModelo.id  == 4 ||
                resp.data.estPoloCadModelo.id  == 5 ? false : true),
        })

        this.render();
    }

    hasErrors = fieldsError => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    };

    changeClassification = (type) => {
        this.state.user.classificacao = type;
        if (type == "AC") {
            this.getAreaAcad();
        } else if (type == "AD") {
            this.getAreaAdm();
        }
    }

    currencyFormatter = (value) => {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: 'BRL'
        }).format(value);
    };

    currencyParser = (val, type) => {
        if (val == undefined) return;
        const isNumber = typeof val === "number";
        if (isNumber) val = Math.floor(val * 100);
        var campo = val.toString();
        campo = campo.replace(/\D/g, "");
        const valor = (parseInt(campo) / 100);
        campo = campo.length > 8 ? campo.replace(/(\d)(\d{3})(\d{5})$/, "$1.$2.$3") : campo.replace(/(\d)(\d{5})$/, "$1.$2")
        campo = campo.replace(/(\d)/, "R$ $1")
        campo = campo.replace(/(\d)(\d{2})$/, "$1,$2")
        switch (type) {
            case "vlrDespesa":
                this.state.expense.vlrDespesa = valor;
                document.getElementById("vlrDespesa").value = campo;
                return campo;
            case "vlrSalario":
                this.state.user.vlrSalario = valor;
                document.getElementById("vlrSalario").value = campo;
                return campo;
            case "vlrHoraAulaAcad":
                this.state.user.vlrHoraAulaAcad = valor;
                document.getElementById("vlrHoraAulaAcad").value = campo;
                return campo;
        }
    };

    validField = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.save();
            }
        });
    }

    validFieldLab = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.saveLaboratorio()
            }
        });
    }

    validaCpf = (rule, value, callback) => {
        try {
            if (value.length === 14) {
                if (!validacaoCpf(value.replace(/\D/g, ''))) {
                    throw new Error('');
                }
            }
            callback();
        } catch (err) {
            callback(err);
        }
    }

    changeCAPE = (value) => {
        this.state.laboratorioInfo.cape = (value == true ? 'S' : 'N');
        this.setState({ isCAPE: value });
        this.render();
    }

    changeConvenio = (value) => {
        this.state.laboratorioInfo.convenio = (value == true ? 'S' : 'N');
        this.setState({ isConvenio: value });
        this.render();
    }

    changeModelo = (e) => {
        this.state.laboratorioInfo.estPoloCadModelo = { "id": parseInt(e) }

        this.setState({
            isLaboratorioProprio: (parseInt(e) == 1 ? true : false),
            enableCAPE: (parseInt(e) == 3 ? false : true),
            enableConvenio: (parseInt(e) == 2 || parseInt(e) == 5 || parseInt(e) == 4 ? false : true),
        })

        if(parseInt(e) == 3) {
            this.state.laboratorioInfo.convenio = 'N';
            this.state.laboratorioInfo.cape = 'S';
            this.state.laboratorioInfo.convenioEmpresa = '';
            this.state.laboratorioInfo.metragem = 0;
            this.state.laboratorioInfo.capacidade = 0
        } else if(parseInt(e) == 2 || parseInt(e) == 4 || parseInt(e) == 5) {
            this.state.laboratorioInfo.cape = 'N';
            this.state.laboratorioInfo.convenio = 'S';
            this.state.laboratorioInfo.polo = null;
            this.state.laboratorioInfo.metragem = 0;
            this.state.laboratorioInfo.capacidade = 0
        } else if(parseInt(e) == 1) {
            this.state.laboratorioInfo.convenio = 'N';
            this.state.laboratorioInfo.convenioEmpresa = '';
            this.state.laboratorioInfo.cape = 'N';
            this.state.laboratorioInfo.polo = null;

        }

        this.render();
    }

    renderPolos = (item) => {
        return (
            <Option key={`${item.id}, ${item.nome}`} text={item.nome} >
                {item['nome'] ? item.nome : item.nome}
            </Option>
        );
    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const cpfFormatter = e => cpfMask(e.target.value);
        return (
            <>
                <Helmet
                    title={this.titlePage}
                    meta={[
                        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                        {
                            name: 'description',
                            content: this.titlePage + '- Portal Prisma',
                        },
                        { name: 'keywords', content: 'kroton, educacional, portal, teste' },
                    ]}
                    key="titleEstruturaUsuario"
                />
                <Header key="headerEstruturaUsuario" />
                <Hero key="heroEstruturaUsuario" pageName={this.titlePage} titulo={this.titlePage} />
                <section className="section conteudoPortais container-estrutura" key="conteudoEstruturaUsuario">
                    {(this.props.match.params.type == "laboratorio" ?
                        <div className="width-100">
                            <strong>Cadastro e edição de laboratórios</strong>
                            <Row gutter={20}>
                                <Col span={12} className="gutter-row">
                                    <Form.Item label="Laboratórios" size="large">
                                        {getFieldDecorator('estPoloCadLaboratorio', {
                                            initialValue: this.state.laboratorioInfo &&
                                                this.state.laboratorioInfo.estPoloCadLaboratorio &&
                                                this.state.laboratorioInfo.estPoloCadLaboratorio.descricao,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Por Favor selecione uma opção',
                                                },
                                            ],
                                        })(
                                            <Select
                                                placeholder="Selecione..."
                                                size="large"
                                                onChange={(e) => this.state.laboratorioInfo.estPoloCadLaboratorio = { "id": parseInt(e) }}
                                            >
                                                {this.state.laboratorios.map((e) => this.renderLaboratorios(e))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="gutter-row">
                                    <Form.Item label="Modelos" size="large">
                                        {getFieldDecorator('estPoloCadModelo', {
                                            initialValue: this.state.laboratorioInfo &&
                                                this.state.laboratorioInfo.estPoloCadModelo &&
                                                this.state.laboratorioInfo.estPoloCadModelo.descricao,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Por Favor selecione uma opção',
                                                },
                                            ],
                                        })(
                                            <Select
                                                placeholder="Selecione..."
                                                size="large"
                                                onChange={(e) => this.changeModelo(e)}
                                            >
                                                {this.state.modelos.map((e) => this.renderLaboratorios(e))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={28}>
                            <label className="margin-1-left">Alugel de laboratorio</label>
                                <Col span={28} className="gutter-row">
                                    <Col span={3} className="no-padding">
                                        <Form.Item size="large">
                                            {getFieldDecorator('cape', {
                                                initialValue: this.state.laboratorioInfo &&
                                                    (this.state.laboratorioInfo.cape == 'S' ? true : false),
                                            })(
                                              <>
                                              <Checkbox
                                                    onChange={(e) => this.changeCAPE(e.target.checked)}
                                                    disabled={this.state.enableCAPE}
                                                    checked={!this.state.enableCAPE}>
                                                    CAPE
                                                </Checkbox>
                                              </>

                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={21}>
                                        <Form.Item size="large">
                                            {getFieldDecorator('polo', {
                                                initialValue: this.state.laboratorioInfo
                                                && this.state.laboratorioInfo.polo
                                                && this.state.laboratorioInfo.polo.nome,
                                                rules: [
                                                    {
                                                        required: !this.state.enableCAPE,
                                                        message: 'Por Favor selecione um polo',
                                                    },
                                                ],
                                            })(
                                                <AutoComplete
                                                    placeholder="Informe o nome da instituição"
                                                    size="large"
                                                    onChange={(e) => this.state.laboratorioInfo.polo = {
                                                                            'id': parseInt(e.split(',')[0]),
                                                                            'nome': e.split(',')[1]}}
                                                    disabled={this.state.enableCAPE}
                                                    dataSource={this.state.lstPolos.map((e) => this.renderPolos(e))}
                                                    filterOption={(value, option) =>
                                                        option.props.children.toUpperCase().indexOf(value.toUpperCase()) !== -1
                                                    }
                                                >

                                                </ AutoComplete>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Col>
                            </Row>
                            <Row gutter={28} className="no-padding">
                                <Col span={28} className="gutter-row">
                                    <Col span={3} className="no-padding">
                                        <Form.Item size="large">
                                            <Checkbox
                                                onChange={(e) => this.changeConvenio(e.target.checked)}
                                                disabled={this.state.enableConvenio}
                                                checked={!this.state.enableConvenio}>
                                                Convênio
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={21}>
                                        <Form.Item size="large">
                                            {getFieldDecorator('convenioEmpresa', {
                                                initialValue: this.state.laboratorioInfo && this.state.laboratorioInfo.convenioEmpresa,
                                                rules: [
                                                    {
                                                        required: !this.state.enableConvenio,
                                                        message: 'Por Favor informe o convênio',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Informe o nome do convênio"
                                                    size="large"
                                                    disabled={this.state.enableConvenio}
                                                    onChange={(e) => this.state.laboratorioInfo.convenioEmpresa = e.target.value}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Col>
                            </Row>
                            <Row gutter={28} className="margin-2rem-top">
                                <Col span={12} className="gutter-row">
                                    <Form.Item label="CAPACIDADE (Quantidade de pessoas)" size="large">
                                        {getFieldDecorator('capacidade', {
                                            initialValue: this.state.laboratorioInfo && this.state.laboratorioInfo.capacidade,
                                            rules: [
                                                {
                                                    required: this.state.isLaboratorioProprio,
                                                    message: 'Por Favor informe a capacidade',
                                                },
                                            ],
                                        })(
                                            <Input
                                                type="number"
                                                placeholder="Capacidade"
                                                disabled={!this.state.isLaboratorioProprio}
                                                onChange={(e) => this.state.laboratorioInfo.capacidade = parseInt(e.target.value)}
                                                size="large"
                                            />
                                        )}
                                    </Form.Item>
                                </ Col>
                                <Col span={12} className="gutter-row">
                                    <Form.Item label="METRAGEM (Metros quadrados)" size="large">
                                        {getFieldDecorator('metragem', {
                                            initialValue: this.state.laboratorioInfo && this.state.laboratorioInfo.metragem,
                                            rules: [
                                                {
                                                    required: this.state.isLaboratorioProprio,
                                                    message: 'Por Favor informe a metragem',
                                                },
                                            ],
                                        })(
                                            <InputNumber
                                                type="number"
                                                placeholder="Metragem"
                                                className="width-100"
                                                disabled={!this.state.isLaboratorioProprio}
                                                onChange={(e) => this.state.laboratorioInfo.metragem = e}
                                                size="large"
                                            />
                                        )}
                                    </Form.Item>
                                </ Col>
                            </Row>
                            <Row gutter={28}>
                                <Col span={28}>
                                    <>
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="pull-right margin-2-5rem-top"
                                            onClick={() => this.validFieldLab()}
                                            style={{
                                                'backgroundColor': '#52c41a',
                                                border: 'none',
                                            }}
                                        >
                                            Salvar
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="pull-right margin-05rem-right margin-2-5rem-top"
                                            style={{
                                                'backgroundColor': '#747474',
                                                border: 'none',
                                            }}
                                            href="/estrutura"
                                        >
                                            Voltar
                                        </Button>
                                    </>

                                </Col>
                            </Row>
                        </div>
                        :
                        (this.props.match.params.type == "despesas" ?
                            <div className="width-100">
                                <Row gutter={16}>
                                    <Col span={6} className="gutter-row">
                                        <Form.Item label="Codigo" size="large">
                                            {getFieldDecorator('codigo', {
                                                initialValue: this.state.expense && this.state.expense.estruturaPoloDespesa &&
                                                    this.state.expense.estruturaPoloDespesa.codReduzido,
                                            })(
                                                <Input
                                                    placeholder="Codigo"
                                                    disabled={true}
                                                    size="large"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="gutter-row">
                                        <Form.Item label="Despesa" size="large">
                                            {getFieldDecorator('despesa', {
                                                initialValue: this.state.expense && this.state.expense.estruturaPoloDespesa &&
                                                    this.state.expense.estruturaPoloDespesa.descrDespesa,
                                            })(
                                                <Input
                                                    placeholder="Despesa"
                                                    size="large"
                                                    disabled={true}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="gutter-row">
                                        <Form.Item label="Valor das despesas" size="large">
                                            {getFieldDecorator('vlrDespesa', {
                                                initialValue: this.state.expense && this.currencyFormatter(this.state.expense.vlrDespesa),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Por Favor inserir o valor da despesa',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    type="text"
                                                    className="ant-input width-15 height-40"
                                                    formatter={(e) => this.currencyFormatter(e)}
                                                    onChange={(e) => this.currencyParser(e.target.value, "vlrDespesa")}

                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="gutter-row">
                                        <Form.Item label="Rateio %" size="large">
                                            {getFieldDecorator('rateio', {
                                                initialValue: (this.state.expense.prcRateio != null ? this.state.expense.prcRateio : 100),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Por Favor inserir rateio',
                                                    },
                                                ],
                                            })(
                                                <InputNumber
                                                    className="ant-input width-15 height-40"
                                                    min={0}
                                                    max={100}
                                                    formatter={e => `${e}%`}
                                                    parser={e => e.replace('%', '')}
                                                    onChange={(e) => this.state.expense.prcRateio = e}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24} className="gutter-row">
                                        <Form.Item size="large">
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="pull-right margin-2-5rem-top"
                                                style={{
                                                    'backgroundColor': '#52c41a',
                                                    border: 'none',
                                                }}
                                                onClick={() => this.saveExpense()}
                                            >
                                                Salvar
                                            </Button>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="pull-right margin-05rem-right margin-2-5rem-top"
                                                style={{
                                                    'backgroundColor': '#747474',
                                                    border: 'none',
                                                }}
                                                href="/estrutura"
                                            >
                                                Voltar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div> :
                            <div className="width-100">
                                <Row gutter={16}>
                                    <Col span={15} className="gutter-row">
                                        <Form.Item label="Nome Completo" size="large">
                                            {getFieldDecorator('nome', {
                                                initialValue: this.state.user.nomeFuncionario,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Por Favor inserir o nome do funcionario',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Nome"
                                                    onChange={e => this.state.user.nomeFuncionario = e.target.value.trim()}
                                                    size="large"
                                                    disabled={this.state.isUserPortal}
                                                    onBlur={e => e.target.value = e.target.value.trim()}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="gutter-row">
                                        <Form.Item label="CPF" size="large">
                                            {getFieldDecorator('cpf', {
                                                initialValue: cpfMask(this.state.user && this.state.user.cpf ? this.state.user.cpf : ''),
                                                getValueFromEvent: cpfFormatter,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Por favor insira um cpf válido',
                                                    },
                                                    {
                                                        validator: this.validaCpf,
                                                        message: 'Por favor insira um cpf válido',
                                                    },
                                                    {
                                                        min: 14,
                                                        message: 'Por favor insira um cpf válido',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="000.000.000-00"
                                                    onChange={(e) => this.changeCpf(e.target.value)}
                                                    size="large"
                                                    disabled={(this.props.match.params.type == "cadastro" ? false : true)}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={6} className="gutter-row">
                                        <Form.Item label="Classificaçao" size="large">
                                            {getFieldDecorator('classificacao', {
                                                initialValue:
                                                    (this.state.user &&
                                                        this.state.user.classificacao) || this.props.match.params.area,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Por Favor selecione uma classificação',
                                                    }
                                                ]
                                            })(
                                                <Select
                                                    placeholder="Selecione..."
                                                    onChange={(e) => this.changeClassification(e)}
                                                    allowClear
                                                    className="width-15"
                                                    size="large"
                                                    disabled={true}
                                                >
                                                    <Option value="AC">Academico</Option>
                                                    <Option value="AD">Administrativo</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="gutter-row">
                                        {(this.state.user.classificacao == 'AC' ?
                                            <Form.Item label="Area" id="area" size="large">
                                                {getFieldDecorator('area', {
                                                    initialValue: (this.state.user &&
                                                        this.state.user.estruturaPoloAreaAcad &&
                                                        this.state.user.estruturaPoloAreaAcad.descrArea) ||
                                                        this.estruturaPoloArea &&
                                                        this.estruturaPoloArea.id,

                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Por Favor selecione uma area',
                                                        }
                                                    ]
                                                })(
                                                    <Select
                                                        placeholder="Selecione..."
                                                        onChange={(e) => this.estruturaPoloArea = e}
                                                        className="width-15"
                                                        size="large"
                                                    >
                                                        {this.area.map((e) => this.renderOption(e))}
                                                    </Select>
                                                )}

                                            </Form.Item> :
                                            <Form.Item label="Area" size="large">
                                                {getFieldDecorator('area', {
                                                    initialValue: (this.state.user &&
                                                        this.state.user.estruturaPoloAreaAdm &&
                                                        this.state.user.estruturaPoloAreaAdm.descrArea) ||
                                                        this.estruturaPoloArea &&
                                                        this.estruturaPoloArea.id,

                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Por Favor selecione uma area',
                                                        }
                                                    ]
                                                })(
                                                    <Select
                                                        placeholder="Selecione..."
                                                        onChange={(e) => this.estruturaPoloArea = e}
                                                        className="width-15"
                                                        size="large"
                                                    >
                                                        {this.area.map((e) => this.renderOption(e))}
                                                    </Select>
                                                )}

                                            </Form.Item>)}

                                    </Col>
                                    {(this.state.user.classificacao != "AC" ?
                                        <Col span={11} className="gutter-row">
                                            <Form.Item label="Cargo" size="large">
                                                {getFieldDecorator('descrCargoAdm', {
                                                    initialValue: this.state.user.descrCargoAdm,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Por Favor insira o nome do cargo',
                                                        }
                                                    ]
                                                })(
                                                    <Input
                                                        placeholder="Digite o nome do cargo"
                                                        onChange={(e) => this.state.user.descrCargoAdm = e.target.value}
                                                        size="large"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        : '')}
                                </Row>
                                <Row>
                                    <Col span={6} className="gutter-row">
                                        <Form.Item label="Tipo de Registro" size="large">
                                            {getFieldDecorator('tipoRegistro', {
                                                initialValue:
                                                    (this.state.user &&
                                                        this.state.user.tipoRegistro) ||
                                                    [],
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Por Favor selecione um tipo do registro',
                                                    }
                                                ]
                                            })(
                                                <Select
                                                    placeholder="Selecione..."
                                                    onChange={(e) => this.state.user.tipoRegistro = e}
                                                    allowClear
                                                    className="width-15"
                                                    size="large"
                                                >
                                                    <Option value="C">CLT</Option>
                                                    <Option value="T">Terceiro/Familiar</Option>
                                                    <Option value="P">Promotor</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    {(this.state.user.classificacao == "AC" ?
                                        <Col span={6} className="gutter-row">
                                            <Form.Item label="Formato Salarial" size="large">
                                                {getFieldDecorator('formatoSalarioAcad', {
                                                    initialValue: this.state.user.formatoSalarioAcad,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Por Favor inserir formato do salario',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        placeholder="Selecione..."
                                                        onChange={(e) => this.state.user.formatoSalarioAcad = e}
                                                        allowClear
                                                        className="width-15"
                                                        size="large"
                                                    >
                                                        <Option value="M">Mensal</Option>
                                                        <Option value="H">Hora</Option>
                                                        <Option value="A">Ambos</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        : '')}
                                    {(this.state.user.classificacao != "AC" || this.state.user.formatoSalarioAcad == "M" || this.state.user.formatoSalarioAcad == "A" ?
                                        <Col span={6} className="gutter-row">
                                            <Form.Item label="Salario" size="large">
                                                {getFieldDecorator('vlrSalario', {
                                                    initialValue: this.currencyParser(this.state.user.vlrSalario, "vlrSalario"),
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Por Favor inserir salario',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        type="text"
                                                        className="ant-input width-15 height-40"
                                                        formatter={(e) => this.currencyFormatter(e)}
                                                        onChange={(e) => this.currencyParser(e.target.value, "vlrSalario")}
                                                    //onChange={(e) => this.state.user.vlrSalario = e}
                                                    />
                                                )}

                                            </Form.Item>
                                        </Col> : '')}
                                    <Col span={6} className="gutter-row">
                                        <Form.Item label="Rateio" size="large">
                                            {getFieldDecorator('prcRateio', {
                                                initialValue: (this.state.user.prcRateio != null ? this.state.user.prcRateio : 100),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Por Favor inserir o rateio',
                                                    },
                                                ],
                                            })(
                                                <InputNumber
                                                    className="ant-input width-15 height-40"
                                                    min={0}
                                                    max={100}
                                                    formatter={e => `${e}%`}
                                                    parser={e => e.replace('%', '')}
                                                    onChange={(e) => this.state.user.prcRateio = e}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    {(this.state.user.formatoSalarioAcad == "H" || this.state.user.formatoSalarioAcad == "A" ?
                                        <>
                                            <Col span={6} className="gutter-row">
                                                <Form.Item label="R$ Hora Aula" size="large">
                                                    {getFieldDecorator('vlrHoraAulaAcad', {
                                                        initialValue: this.state.user.vlrHoraAulaAcad,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Por Favor inserir o valor da aula',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            type="text"
                                                            className="ant-input width-15 height-40"
                                                            formatter={(e) => this.currencyFormatter(e)}
                                                            onChange={(e) => this.currencyParser(e.target.value, "vlrHoraAulaAcad")}
                                                        //onChange={(e) => this.state.user.vlrHoraAulaAcad = e}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={(this.state.user.formatoSalarioAcad == "A" ? 24 : 6)} className="gutter-row">
                                                <Form.Item label="QTD Hora Aula Mês" size="large">
                                                    {getFieldDecorator('qtdHoraAulaMesAcad', {
                                                        initialValue: this.state.user.qtdHoraAulaMesAcad,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Por Favor inserir a quantidade de aula por mês',
                                                            },
                                                        ],
                                                    })(
                                                        <InputNumber
                                                            className="ant-input width-15 height-40"
                                                            onChange={(e) => this.state.user.qtdHoraAulaMesAcad = e}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </> : '')}
                                    <Col span={(this.state.user.formatoSalarioAcad == "H" ||
                                        this.state.user.formatoSalarioAcad == "A" ? 24 : 6)}
                                        className="gutter-row">
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="pull-right margin-2-5rem-top"
                                            style={{
                                                'backgroundColor': '#52c41a',
                                                border: 'none',
                                            }}
                                            disabled={this.hasErrors(getFieldsError())}
                                            onClick={() => this.validField()}
                                        //onClick={() => this.save()}
                                        >
                                            Salvar
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="pull-right margin-05rem-right margin-2-5rem-top"
                                            style={{
                                                'backgroundColor': '#747474',
                                                border: 'none',
                                            }}
                                            href="/estrutura"
                                        >
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                </section>
                <Footer key="FooterEstruturaUsuario" />
            </>
        )
    }
}

export default Form.create()(EstruturaPadraoUsuario);
