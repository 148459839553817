import React from 'react';
import { Header, Footer, Helmet } from '../../services/Gerais';
import ComunicadosCarouselDiversos from '../../components/ComunicadosCarousel/ComunicadosCarouselDiversos';

class Sidebar extends React.Component {
  constructor() {
    super();
    this.state = { active: 'maisRecentes' };
  }

  toggleClassComunicados(tab) {
    this.setState({ active: tab });
  }

  render() {
    const { active } = this.state;
    return (
      <ul>
        <TabLabel
          onClick={this.toggleClassComunicados.bind(this, 'maisRecentes')}
          active={active === 'maisRecentes'}
          text="Mais recentes"
          slug="comunicados"
        />
        <TabLabel
          active={active === 'financeiro'}
          text="Financeiro"
          slug="financeiro"
        />
        <TabLabel
          active={active === 'operacoes'}
          text="Operações"
          slug="operacoes"
        />
        <TabLabel
          active={active === 'comercial'}
          text="Comercial"
          slug="comercial"
        />
        <TabLabel
          active={active === 'academico'}
          text="Acadêmico"
          slug="academico"
        />
        <TabLabel
          active={active === 'posGraduacao'}
          text="Pós Graduação"
          slug="pos"
        />
      </ul>
    );
  }
}

const TabLabel = ({ active, text, onClick, slug }) => {
  if (active === true && slug === 'comunicados') {
    return (
      <li onClick={onClick} className={active ? 'is-active' : null}>
        <a href={'./comunicados/'}>{text}</a>
      </li>
    );
  } else {
    return (
      <li onClick={onClick} className={active ? 'is-active' : null}>
        <a href={'./../lista-comunicados/' + slug}>{text}</a>
      </li>
    );
  }
};

// const Comunicados = () => [
class Comunicados extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isActived: false, idRedirecinamento: '' };
  }
  componentWillMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      localStorage.setItem(
        'redirect',
        `${window.location.origin}/comunicados/${this.props.match.params.id}`
      );
      this.setState({ idRedirecinamento: this.props.match.params.id });
    }
  }

  toggleNavedComun = () => {
    this.setState(prevState => ({ isActived: !prevState.isActived }));
  };

  render() {
    return [
      <Helmet
        key="titleComunicados"
        title="Comunicados - Portal Prisma - Kroton"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: 'Comunicados - Portal Unificado da Kroton Educacional',
          },
          {
            name: 'keywords',
            content: 'comunicados, kroton, educacional, portal',
          },
        ]}
      />,
      <Header key="HeaderComunicados" />,
      <section className="hero is-crp" key="heroComunicados">
        <div className="hero-body">
          <div className="container is-fullhd">
            <nav
              className="breadcrumb is-left"
              aria-label="breadcrumbs"
              key={'navegacao'}
            >
              <ul>
                <li>
                  <a href="./home">Home</a>
                </li>
                <li className="is-active">
                  <a href="./comunicados" aria-current="page">
                    Comunicados
                  </a>
                </li>
              </ul>
            </nav>
            <h1 className="title is-1">Comunicados</h1>
          </div>
        </div>

        <div className="hero-foot">
          <nav className="tabs is-boxed is-fullwidth">
            <div className="container">
              <div className="columns is-gapless">
                <div className="column">
                  <Sidebar />
                </div>
                <div className="column"></div>
              </div>
            </div>
          </nav>
        </div>
      </section>,
      <section className="section" key="conteudoComunicados">
        <ComunicadosCarouselDiversos
          id="2"
          categoria="Mensagens Importantes"
          key="Mensagens Importantes"
        />
        <ComunicadosCarouselDiversos
          id="4"
          categoria="Financeiro"
          key="Financeiro"
          idRedirecinamento={this.state.idRedirecinamento}
        />
        <ComunicadosCarouselDiversos
          id="5"
          categoria="Operações"
          key="Operações"
        />
        <ComunicadosCarouselDiversos
          id="1"
          categoria="Comercial"
          key="Comercial"
        />
        <ComunicadosCarouselDiversos
          id="3"
          categoria="Acadêmico"
          key="Acadêmico"
        />
        <ComunicadosCarouselDiversos
          id="6"
          categoria="Pós Graduação"
          key="Pós Graduação"
        />
      </section>,
      <hr className="hrEspacamento" key="hrEspacamentoComunicados" />,
      <Footer key="FooterComunicados" />,
    ];
  }
}

export default Comunicados;
