import { root } from 'react-dom/client';
import api from 'services/api';
import Swal from 'sweetalert2';

export async function getLogDynamics(acessosDynamicsId, sistemaId) {
  return await api.get(`dynamics/getLogDynamics/${acessosDynamicsId}/${sistemaId}`);
}

export async function getUserDynamicsData(idUser) {
  try {
    const response = await api.get(`dynamics/usuarioDynamics/${idUser}`); 
    return response.data;  
    
  } catch (error) {
     throw new Error('Erro ao buscar licenças do polo');
  }
}

export async function getLicensesForPolo(poloId, sistemaId) {
  try {
    const result = await api.get(`dynamics/${poloId}/${sistemaId}`);
    if (result.data === null || result.data === undefined) {
      return 'Não existem liçencas para o polo selecionado';
    }
    return result.data;
  } catch (error) {
    Swal.fire({
      text:
        error.response.data.message,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      icon: 'warning'
    })
  }
}

export async function RenderPolosForDynamicEdit(arrayPolos) {
  const container = document.getElementById('PolosListWithLicenses');

  const render = (content) => {
    if (container) {
      root(container).render(content);
    } else {
      console.error('Container element not found.');
    }
  };

  const polosUserHasForCaptarList = [];

  arrayPolos.forEach(async (polo) => {
    let polosUserHasForCaptarObj;

    let result = await getLicensesForPolo(polo.usuarioPolo.polo.id);

    polosUserHasForCaptarObj = {
      title: polo.usuarioPolo.polo.nome,
      description: polo.lstUsuarioPoloPerfil[0].perfil.descricao,
      portalCaptar: polo.portalCaptar,
      key: polo.usuarioPolo.polo.id,
      disabled: false,
      totalLicenses: result.licensasLiberadas,
      usedLicenses: result.licensasUtilizadas,
    };

    polosUserHasForCaptarList.push(polosUserHasForCaptarObj);
  });

  if (polosUserHasForCaptarList.length > 0) {
    {
      polosUserHasForCaptarList.map((polo) =>
        console.log(
          `${polo.title} - ${polo.usedLicenses}/${polo.totalLicenses}`
        )
      );
    }
  }
}

export async function saveDynamics(idPoloCaptar, dataToSave) {
  try {
    const result = await api.post(`dynamics/${idPoloCaptar}`,dataToSave);
    return result.data;
  } catch (error) {
    throw new Error('Erro ao salvar licença');
  }
}

export async function editDynamics(idPoloCaptar, dataToEdit) {
  try {
    const result = await api.put(
      `dynamics/${idPoloCaptar}`,dataToEdit);
    return result.data;
  } catch (error) {
    throw new Error('Erro ao editar licença');
  }
}

export async function findSistemsByPerfil(perfilId) {
  try {
    const result = await api.get(`sistema/${perfilId}/sistemas`);
    return result.data;
  } catch (error) {
    throw new Error('Erro ao buscar sistemas');
  }
}
