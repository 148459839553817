const gregorian_br_lowercase = {
    name: "gregorian_en_lowercase",
    months: [
      ["janeiro", "jan"],
      ["fevereiro", "fev"],
      ["março", "mar"],
      ["abril", "abr"],
      ["maio", "mai"],
      ["junho", "jun"],
      ["julho", "jul"],
      ["agosto", "ago"],
      ["setembro", "set"],
      ["outubro", "out"],
      ["novembro", "nov"],
      ["dezembro", "dez"],
    ],
    weekDays: [
      ["sábado", "sab"],
      ["domingo", "dom"],
      ["segunda-feira", "seg"],
      ["terça-feira", "ter"],
      ["quarta-feira", "qua"],
      ["quinta-feira", "qui"],
      ["sexta-feira", "sex"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "am"],
      ["PM", "pm"],
    ],
  };
  
  export default gregorian_br_lowercase;