import apiMsGraph from '../services/apiMsGraph';
export const getUserProfileByEmail = async email => {
  try {
    const res = await apiMsGraph.get(`/users?$filter=startswith(userPrincipalName,'${email}')`);
    return res;
  } catch (error) {
    console.warn('Azure AD Err: ', error.response);
    return error;
  }
};

export const getUserProfileByMail = async email => {
  try {
    const res = await apiMsGraph.get(`/users?$filter=startswith(mail,'${email}')`);
    return res;
  } catch (error) {
    console.warn('Azure AD Err: ', error.response);
    return error;
  }
};

