import React from 'react';
import { Header, Footer, Hero, FontAwesomeIcon, Helmet } from "../../services/Gerais";
import noticias from "../../img/laptop-notebook-clean-hero.jpg";
import noticiaBig from "../../img/img-noticia.png";

class Noticias extends React.Component {
    constructor() {
        super()
        this.state = { isActivedNoticias: false, }
    }
    toggleNavedNoticias = () => { this.setState(prevState => ({ isActivedNoticias: !prevState.isActivedNoticias })) }
    render() {
        return [
            <Helmet title="Notícias - Portal Prisma - Kroton"
                meta = {[ { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'description', content: 'Noticias - Portal Unificado da Kroton Educacional' },
                    { name: 'keywords', content: 'kroton, educacional, portal, teste' },
                ]} key = "titleNoticias" />,
            <Header key="HeaderNoticias" />,
            <Hero key="heroNoticias" pageName="Notícias" titulo="Notícias" />,
            <section className="section noticiasDestaquePagina" key="conteudoNoticias">
                <div className="container is-fullhd">
                    <div className="columns">
                        <div className="column">
                            <div className="columns is-multiline">
                                <div className="column is-one-third item">
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-content">
                                                <figure className="image">
                                                    <span className="noticiaCategoria has-text-white">
                                                        <a href="./home" className="button is-small is-rounded is-outlined tagsNoticia">Universidade Kroton</a>
                                                        <i className="icon salvarNoticia"><FontAwesomeIcon icon={["far", "bookmark"]} /></i>
                                                    </span>
                                                    <img src={noticias} alt="Imagem teste"/>
                                                </figure>
                                                <div className="content is-small">
                                                    <small className="dataNoticia">11/09/2018</small>
                                                </div>
                                                <h5 className="title has-text-left is-spaced">
                                                    <a href="./home" onClick={this.toggleNavedNoticias}>Já explorou as Trilhas de Excelência na UK?</a>
                                                </h5>
                                                <div className="content">
                                                    <p className="previaNoticia">Para garantir a transformação do futuro de nossa parceria, do Polo e de nossos alunos, é essencial buscar a excelência dos processos operacional, acadêmica, comercial e financeira.</p>
                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-right">
                                                        <a className="level-item" href="./home" aria-label="comment-alt">
                                                          <span className="icon is-small"><FontAwesomeIcon icon={["far", "comment-alt"]}/></span>
                                                        </a>
                                                        <a className="level-item" href="./home" aria-label="like">
                                                          <span className="icon is-small"><FontAwesomeIcon icon={["fas", "share"]}/></span>
                                                          <sup className="noticiaSup"> &nbsp; Compartilhar</sup>
                                                        </a>
                                                    </div>
                                                </nav>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="column is-one-third item">
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-content">
                                                <figure className="image">
                                                    <span className="noticiaCategoria has-text-white">
                                                        <a href="./home" className="button is-small is-rounded is-outlined tagsNoticia">Be Digital</a>
                                                        <i className="icon salvarNoticia"><FontAwesomeIcon icon={["far", "bookmark"]} /></i>
                                                    </span>
                                                    <img src={noticias} alt="Imagem teste"/>
                                                </figure>
                                                <div className="content is-small">
                                                    <small className="dataNoticia">11/09/2018</small>
                                                </div>
                                                <h5 className="title has-text-left is-spaced">
                                                    <a href="./home" onClick={this.toggleNavedNoticias}>Acabamos de nos conectar ao maior centro de empreendedorismo tecnológico da América Latina!</a>
                                                </h5>
                                                <div className="content">
                                                    <p className="previaNoticia">Agora, a Kroton é parceria do Cubo Itaú, um lançamento que nos impulsiona ainda mais para nos tornarmos a empresa de educação mais digital e inovadora do mundo.</p>
                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-right">
                                                        <a className="level-item" href="./home" aria-label="comment-alt">
                                                            <span className="icon is-small"><FontAwesomeIcon icon={["far", "comment-alt"]}/></span>
                                                        </a>
                                                        <a className="level-item" href="./home" aria-label="like">
                                                            <span className="icon is-small"><FontAwesomeIcon icon={["fas", "share"]}/></span>
                                                            <sup className="noticiaSup"> &nbsp; Compartilhar</sup>
                                                        </a>
                                                    </div>
                                                </nav>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="column is-one-third item">
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-content">
                                                <figure className="image">
                                                    <span className="noticiaCategoria has-text-white">
                                                        <a href="./home" className="button is-small is-rounded is-outlined tagsNoticia">Prêmio</a>
                                                        <i className="icon salvarNoticia"><FontAwesomeIcon icon={["far", "bookmark"]} /></i>
                                                    </span>
                                                    <img src={noticias} alt="Imagem teste"/>
                                                </figure>
                                                <div className="content is-small">
                                                    <small className="dataNoticia">11/09/2018</small>
                                                </div>
                                                <h5 className="title has-text-left is-spaced">
                                                    <a href="./home" onClick={this.toggleNavedNoticias}>Você faz parte dessa conquista! Kroton, campeã na categoria educação e ensino.</a>
                                                </h5>
                                                <div className="content">
                                                    <p className="previaNoticia">Foco no sucesso do aluno é apoiar a transformação do futuro de milhares de estudantes, inovar e abraçar desafios, com a certeza de que juntos</p>
                                                </div>
                                                {/*<nav className="level is-mobile noticiaSocial">*/}
                                                    {/*<div className="level-right">*/}
                                                        {/*<a className="level-item" href="./home" aria-label="comment-alt">*/}
                                                            {/*<span className="icon is-small"><FontAwesomeIcon icon={["far", "comment-alt"]}/></span>*/}
                                                        {/*</a>*/}
                                                        {/*<a className="level-item" href="./home" aria-label="like">*/}
                                                            {/*<span className="icon is-small"><FontAwesomeIcon icon={["fas", "share"]}/></span>*/}
                                                            {/*<sup className="noticiaSup"> &nbsp; Compartilhar</sup>*/}
                                                        {/*</a>*/}
                                                    {/*</div>*/}
                                                {/*</nav>*/}
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-one-quarter sidebarNoticias">
                            <div className="content"><h4 className="title is-4">Categorias</h4></div>
                            <article className="media categoriasNoticiasList">
                                <div className="media-content">
                                    <a href="./home" className="button is-small is-rounded categoriasNoticias">Be digital</a>
                                    <a href="./home" className="button is-small is-rounded categoriasNoticias">Prêmio</a>
                                    <a href="./home" className="button is-small is-rounded categoriasNoticias">Kroton</a>
                                    <a href="./home" className="button is-small is-rounded categoriasNoticias">Universidade Kroton</a>
                                    <a href="./home" className="button is-small is-rounded categoriasNoticias">Transformação digital</a>
                                </div>
                            </article>
                            <hr />
                            <div className="content">
                                <h4 className="title is-4">+ Lidas</h4>
                            </div>
                            <article className="media">
                                <div className="media-left">
                                    <strong className="title is-1 has-lidas-numeros">01</strong>
                                </div>
                                <div className="media-content noticiaSidebarLink">
                                    <a href="./home" >Lorem ipsum dolor sit amet, consectetur adipisicing elit.</a>
                                    <strong className="content is-small">11/09/2018 | Financeiro</strong>
                                </div>
                            </article>
                            <article className="media">
                                <div className="media-left">
                                    <strong className="title is-1 has-lidas-numeros">02</strong>
                                </div>
                                <div className="media-content noticiaSidebarLink">
                                    <a href="./home" >Lorem ipsum dolor sit amet, consectetur adipisicing elit.</a>
                                    <strong className="content is-small">11/09/2018 | Financeiro</strong>
                                </div>
                            </article>
                            <hr/>
                            <div className="content"><h4 className="title is-4">Destaques</h4></div>
                            <article className="media">
                                <div className="media-left">
                                    <p className="image is-64x64"><img src={noticias} alt="Imagem teste"/></p>
                                </div>
                                <div className="media-content">
                                    <strong>Lorem ipsum dolor sit amet, consectetur.</strong>
                                    <div className="content is-small has-text-grey-light">11/09/2018 | Financeiro</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>

                <div className={this.state.isActivedNoticias ? 'conteudoLink is-active' : 'conteudoLink'}>
                    <div className="container is-fullhd">
                        <div className="columns is-gapless">
                            <div className="column informacoesPortal informacoesNoticias">
                                <a href="./home" className="fechar" onClick={this.toggleNavedNoticias}>X</a>
                                <div className="has-text-justified descricaoNoticias">
                                    <div className="title is-6 is-spaced noticiasInfos">
                                        <label className="check"><FontAwesomeIcon icon={["fas", "check"]}/><FontAwesomeIcon icon={["fas", "check"]}/></label>
                                        <small>visualizado 07/09/2018 - 19h20</small>
                                        <a href="./home" className="salvarNoticia"><FontAwesomeIcon icon={["far", "bookmark"]}/></a>
                                        <small className="codigoNoticia">
                                            Código do Comunicado: <strong>182-O-27JUL-196-U</strong>
                                        </small>
                                    </div>
                                    <h5 className="title is-spaced has-text-centered">Intensivo da Semana da Independência</h5>
                                    <small className="smallNoticiaDescricao has-text-centered">06/09/2018 - 10h20 | Diretoria Acadêmica</small>
                                    <p className="has-text-centered"><img src={noticiaBig} alt="Noticia ampliada"/></p>
                                    <p>
                                        De 03/09 a 10/09, serão disponibilizadas 6 listas de exercícios e 1 questão Desafio. As listas valem 50 (cinquenta) pontos pela...
                                    </p>
                                    <a className="compartilharNoticia" href="./home" aria-label="like">
                                        <small><FontAwesomeIcon icon={["far", "comment-alt"]}/> &nbsp;</small>
                                    </a>
                                    <a className="compartilharNoticia" href="./home" aria-label="share">
                                        <small><FontAwesomeIcon icon={["fas", "share"]}/> &nbsp; Compartilhar</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>,
            <hr className="hrEspacamento" key="hrEspacamentoNoticias" />,
            <Footer key="FooterNoticias" />
        ]
    }
}
export default Noticias