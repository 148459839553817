import api from 'services/api';
import Swal from 'sweetalert2';

const loginInfo = JSON.parse(localStorage.getItem('userDataCRP'));

export const getByFiltersCPF = async (cpf) => {
  const res = await api.get(`usuario/gestor/findByCpf?cpf=${cpf}&usuarioPortalId=${loginInfo['usuarioPortal']['id']}`);
    if(res.data?.usuarioPortal?.id != undefined) {
      Swal.fire({
        position: 'top-end',
        title: `Usuario encontrado! Redirecionando...`,
        icon: 'success',
        showConfirmButton: false,
        timer: 3000,
      })
      window.setTimeout(function(){ window.location.href = '/usuarios/edit/' + res.data.usuarioPortal.id; }, 2500);
    } else if (res.data?.error != undefined) {
      Swal.fire({
        position: 'top-end',
        title: res.data?.error,
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000,
      })
    } else if (res.data?.usuarioPortal?.id == undefined) {
      Swal.fire({
        position: 'top-end',
        title: `Nenhum usuario encontrado com este CPF; redirecionando para realizar o cadastro`,
        icon: 'success',
        showConfirmButton: false,
        timer: 3000,
      })
      localStorage.setItem('redirectCPF', cpf)
      window.setTimeout(function(){ window.location.href = '/usuarios/cadastro'; }, 2500);
    }
}

export const findByFilters = async (params) => {
  return await api.get(`/usuario/findByFilters${params}`
  );
}

export const resetPassword = async (usuario) => {
  try {
    await api.post('usuario/resetSenha', {
      email: usuario.conta,
    });
    Swal.fire({
      position: 'top-end',
      title: `Feito!`,
      text: 'A nova senha de acesso do colaborador foi enviada para o seu e-mail Prisma. Acesse via Office 365.',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  } catch (error) {
    Swal.fire({
      title: 'Erro',
      text: `Erro ao realizar reset de senha. ${error.response.data.mensagem}`,
      icon: 'error',
      confirmButtonText: 'Ok'
    })
  }
}

export const onInativationUser = async (lstUsers) => {
  await api.put(`usuario/gestor/${loginInfo['usuarioPortal']['id']}/polo`,lstUsers).then(result => {
    Swal.fire({
      position: 'top-end',
      title: `Feito!`,
      text: 'Os usuarios foram desvinculados da sua gerencia',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
    window.setTimeout(function(){ window.location.href = "../polos"; }, 2000);
  })
}
