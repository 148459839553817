import {notification} from 'antd';
import { registerPortal, portalItem, updatePortal } from '../service';

export class FormController {
  constructor(props) {
    this.state = props.state;
    this.history = props.history
    this.form = props.form;
  }

  async get(id) {
    const item = await portalItem(id);
    this.form.reset(item);
    if (item.imagem) {
      const imageName = item.imagem.split('/').at(-1);
      this.state.file.setFileList([
        {
          uid: imageName.replace(/.+\?/g, ''),
          name: imageName,
          status: 'done',
          url: item.imagem,
        },
      ]);
    }
  }

  async handleSubmit(e, edit) {
    try {
      if(e.file){
        e.imagem = e?.file[0]?.response?.link;
      }
      delete e.file;

      if (edit) {
        await updatePortal(e, e.id);
      } else {
        await registerPortal(e);
      }

      notification["success"]({
        message: `${edit ? 'Atualização' : 'Cadastro'} foi feito(a) com sucesso!`,
        description:
          `O portal ${e.portal} foi ${edit ? 'atualizado' : 'cadastrado'} com sucesso`,
      });

      this.history.push("/admin/portais")

    } catch (error) {
      notification["error"]({
        message: `Erro ao ${edit ? 'Atualizar Portal' : 'Cadastrar Portal'}`,
        description:
        error.message ? error.message[0] : 'Por favor verificar com a equipe tecnica',
      });
    }
  }
}
