import React from 'reactn';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../../services/Gerais';
import api from '../../../../../services/api';
import { Select, Spin, DatePicker, Button, Icon, Input } from 'antd';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import '../../../../../styles/RelatoriosAdm.scss';
const { Option } = Select;

class RelatoriosAdm extends React.Component {
  constructor() {
    super();
    this.state = {
      filtrosEscolhidos: [],
      relatorioConfig: null,
      relatorioId: null,
      relatoriosDisponiveis: [],
      filtrosDisponiveis: [],
      carregando: true,
      filtrosExibidos: false,
    };
    this.idUserData = JSON.parse(localStorage.getItem('userDataCRP'))
    this.perfilId = localStorage.getItem('userDataCRP-Perfil')
  }

  componentDidMount() {
    this.carregarRelatoriosDisponiveis();
  }

  carr = (i) => {
    this.setState({ carregando: i });
  };

  erro = () => {
    this.carr(false);
    Swal.fire({
      title: 'Erro',
      text: 'Não foi possivel realizar operação',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
  };

  carregarRelatoriosDisponiveis = async () => {
    this.carr(true);
    try {
      const resposta = await api.get(`relatorio/extracaoRelatorioAdm/${this.idUserData.usuarioPortal.id}/${this.perfilId}`);
      this.carr(false);
      this.setState({
        relatoriosDisponiveis: resposta.data['relatorioAdministrativo'],
      });
    } catch (error) {
      this.erro();
    }
  };

  filtroRelatorio = async (relatorioId) => {
    this.setState({
      relatorioConfig: null,
      filtrosEscolhidos: [],
    });
    if (relatorioId !== null) {
      this.carr(true);
      try {
        const resposta = await api.get(`relatorio/adm/${relatorioId}`);
        this.carr(false);

        const lstParametros = this.iniciaParametros(
          resposta.data['relatorioAdministrativoFiltros']
        );

        const relatorioConfigInicio = {
          relatorioId: relatorioId,
          lstParametros: lstParametros,
        };

        this.setState({
          filtrosDisponiveis: resposta.data['relatorioAdministrativoFiltros'],
          relatorioId: relatorioId,
          filtrosExibidos: true,
          relatorioConfig: relatorioConfigInicio,
          filtrosEscolhidos: lstParametros,
        });
      } catch (error) {
        this.erro();
      }
    }
  };

  iniciaParametros(parametrosIniciais) {
    const lstParametros = [];
    parametrosIniciais.map((item, index) => {
      const filtroIiniciado = {
        id: item.id,
        nome: item.nomeExibicao,
        valor: '',
      };
      lstParametros.push(filtroIiniciado);
    });
    return lstParametros;
  }

  gerarRelatorio = async () => {
    const resposta = await api.post(
      'relatorio/exportCsv',
      this.state.relatorioConfig
    );

    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Relatório gerado com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
    const BOM = '\uFEFF';
    const relatorioDados = BOM + resposta.data;
    const contentType = resposta.headers['content-type'];
    const filename = resposta.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
      .replace('"', '')
      .replace('"', '');
    const blob = new Blob([relatorioDados], {
      type: contentType,
    });
    saveAs(blob, filename);
  };

  itensMenuRelatorio() {
    const menuArray = this.state.relatoriosDisponiveis;
    const menuItens = menuArray.map((item, index) => {
      return (
        <Option key={index} value={item.id}>
          {item.nomeRelatorio}
        </Option>
      );
    });
    return menuItens;
  }

  itensMenuSelect(listParametrosSelect) {
    const menuItens = listParametrosSelect.map((item, index) => {
      return (
        <Option key={index} value={item.id}>
          {item.nome}
        </Option>
      );
    });
    return menuItens;
  }

  addFiltroEscolhido = (id, nome, valor) => {
    if (valor === null) {
      valor = '';
    }
    const filtroEscolhido = {
      id: id,
      nome: nome,
      valor: valor,
    };
    const filtrosEscolhidos = this.state.filtrosEscolhidos;
    if (filtrosEscolhidos) {
      const filtrosIdsAdicionados = filtrosEscolhidos.filter((objeto) => {
        return objeto.id === id;
      });
      const filtroIndex = filtrosEscolhidos.findIndex(
        (objeto) => objeto.id === id
      );
      if (filtrosIdsAdicionados.length) {
        filtrosEscolhidos[filtroIndex] = filtroEscolhido;
      } else {
        filtrosEscolhidos.push(filtroEscolhido);
      }
      const relatorioConfigAtualizada = {
        relatorioId: this.state.relatorioId,
        lstParametros: filtrosEscolhidos,
      };
      this.setState({
        relatorioConfig: relatorioConfigAtualizada,
      });
    }
  };

  montarFiltrosDisponiveis = () => {
    let componente = null;
    const camposFiltros = this.state.filtrosDisponiveis.map((item, index) => {
      if (item.tipo === 'DATE') {
        componente = (
          <div className="relatorioFiltro" key={index}>
            <label className="relatorioFiltroLabel">{item.nomeExibicao}</label>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="DD-MM-YYYY"
              size="large"
              onChange={(value) => {
                if (value) {
                  this.addFiltroEscolhido(
                    item.id,
                    item.nomeExibicao,
                    value.format('YYYY-MM-DD hh:mm:ss')
                  );
                } else {
                  this.addFiltroEscolhido(item.id, item.nomeExibicao, value);
                }
              }}
            />
          </div>
        );
      } else if (item.tipo === 'SELECT' && item.parametrosSelect) {
        componente = (
          <div className="relatorioFiltro" key={index}>
            <label className="relatorioFiltroLabel">
              {item.nomeExibicao} :{' '}
            </label>
            <Select
              showSearch
              style={{ width: 220 }}
              placeholder="Selecione uma opção"
              optionFilterProp="children"
              onChange={(value) =>
                this.addFiltroEscolhido(item.id, item.nomeExibicao, value)
              }
            >
              {this.itensMenuSelect(item.parametrosSelect.parametros)}
            </Select>
          </div>
        );
      } else if (item.tipo === 'BOOLEAN') {
        componente = (
          <div className="relatorioFiltro" key={index}>
            <label className="relatorioFiltroLabel">
              {item.nomeExibicao} :{' '}
            </label>
            <Select
              showSearch
              style={{ width: 220 }}
              placeholder="Selecione uma opção"
              optionFilterProp="children"
              onChange={(value) =>
                this.addFiltroEscolhido(item.id, item.nomeExibicao, value)
              }
            >
              <Option value="S">SIM</Option>
              <Option value="N">NÃO</Option>
            </Select>
          </div>
        );
      } else if (item.tipo === 'VARCHAR') {
        componente = (
          <div className="relatorioFiltro" key={index}>
            <label className="relatorioFiltroLabel">
              {item.nomeExibicao} :{' '}
            </label>
            <Input
              style={{ width: 220 }}
              placeholder="Digite o campo Varchar"
              onChange={(value) =>
                this.addFiltroEscolhido(item.id, item.nomeExibicao, value)
              }
             />
          </div>
        );
      } else {
        componente = (
          <div key={index}>
            <label>{item.nomeExibicao}</label>
            <label>{'Campo a implementar...'}</label>
          </div>
        );
      }
      return componente;
    });
    return camposFiltros;
  };

  render() {
    const paginaTitulo = 'Gerador de Relatórios';
    return (
      <>
        <Helmet
          title={paginaTitulo}
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: paginaTitulo + '- Portal Prisma',
            },
            {
              name: 'keywords',
              content: 'relatorios, kroton, educacional, portal',
            },
          ]}
        />
        <HeaderAdmin />
        <section className="columns is-multiline conteudoAdmin" id="admin-app">
          <MenuAdmin />
          <div className="column is-10 is-fullheight conteudoAdminPagina">
            <div className="columns">
              <div className="column">
                <Hero
                  key="heroAdminRelatorios"
                  pageName="Administração - Relatórios"
                  titulo={paginaTitulo}
                />

                <div className="relatorioIntro">
                  Escolha o relatório que deseja gerar
                </div>
                <Spin
                  tip="Loading..."
                  spinning={this.state.carregando}
                  delay={500}
                >
                  <Select
                    showSearch
                    style={{ width: 220 }}
                    placeholder="Selecione um relatório"
                    optionFilterProp="children"
                    onChange={(e) => this.filtroRelatorio(e)}
                  >
                    {this.itensMenuRelatorio()}
                  </Select>
                  {this.state.filtrosExibidos && (
                    <div className="relatorioFiltros">
                      <label className="relatorioFiltrosLabel">
                        Filtros disponíveis
                      </label>

                      {this.montarFiltrosDisponiveis()}
                    </div>
                  )}
                  {this.state.filtrosExibidos && (
                    <div className="relatorioBtn">
                      <Button
                        type="primary"
                        style={{
                          'background-color': '#52c41a',
                          border: 'none',
                        }}
                        onClick={() => this.gerarRelatorio()}
                      >
                        <Icon type="save" />
                        Gerar relatório
                      </Button>
                    </div>
                  )}
                </Spin>
              </div>
              <div className="columns botoesAcaoCadastro">
                <div className="column">
                  <div
                    className="button-wrapper"
                    id="components-dropdown-demo-dropdown-button"
                  >
                    <Button
                      size="large"
                      type="primary"
                      href="/admin/relatorios"
                    >
                      Voltar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterAdmin />
      </>
    );
  }
}

export default RelatoriosAdm;
