import React from 'react';
import { Redirect } from 'react-router-dom';
import api from 'services/api';

const logRelatorio = async (id) => {
  const userData = JSON.parse(localStorage.getItem('userDataCRP'));
  const params = {
    usuarioPortal: {
      id: userData.usuarioPortal.id,
    },
    nomeTabela: 'relatorio',
    idRegistro: id,
  };
  await api.post('visualizacao', params);

  window.location.href = `/relatorio/${id}`;
};

const logFAQ = async (id) => {
  const userData = JSON.parse(localStorage.getItem('userDataCRP'));
  const params = {
    usuarioPortal: {
      id: userData.usuarioPortal.id,
    },
    nomeTabela: 'FAQ',
    idRegistro: id,
  };
  await api.post('visualizacao', params);
};

const logBanner = async (id) => {
  const userData = JSON.parse(localStorage.getItem('userDataCRP'));
  const params = {
    usuarioPortal: {
      id: userData.usuarioPortal.id,
    },
    nomeTabela: 'Banner',
    idRegistro: id,
  };
  await api.post('visualizacao', params);
};

export { logRelatorio, logFAQ, logBanner };
