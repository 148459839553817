import {
  DADO_PERFIL_ERRO,
  DADO_PERFIL_SUCCESS,
  DADO_PERFIL_CHANGE,
  DADO_POLO,
} from './DadosPerfilType';

import api from '../../../../services/api';

export const dadosIniciais = async logInfo => {
  const poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
  try {
    const res = await api.get(
      `usuario/cadastrarUsuario/${logInfo.usuarioPortal.id}` + `${(poloId > 0 ? `?poloId=${poloId}` : '')}`
    );

    delete res.data.Polo;

    return dispatch => {
      dispatch({
        type: DADO_PERFIL_SUCCESS,
        payload: { ...res.data, carregandoInicial: false },
      });
    };
  } catch (error) {
    return dispatch => {
      dispatch({
        type: DADO_PERFIL_ERRO,
        payload: { error: error, carregandoInicial: false },
      });
    };
  }
};

export const getPolosByCanal = async canal => {
  try {
    const res = await api.get(`polo/getPoloByTipoPolo/${canal}`);
    return dispatch => {
      dispatch({
        type: DADO_POLO,
        payload: { ...res.data, carregando: false },
      });
    };
  } catch (error) {
    return dispatch => {
      dispatch({
        type: DADO_PERFIL_ERRO,
        payload: { error: error, carregando: false },
      });
    };
  }
};

export const changeDadoPerfil = ({ prop, value }) => {
  return dispatch => {
    dispatch({ type: DADO_PERFIL_CHANGE, payload: { prop, value } });
  };
};
