import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Welcome from './pages/Welcome/Welcome';
import Home from './pages/Home/Home';
import Polos from './pages/Polos/Polos';
import Login from './pages/Login/Login';
import Comunicados from './pages/Comunicados/Comunicados';
import Categoria from './pages/Comunicados/Categoria';
import ComunicadosBusca from './pages/Comunicados/ComunicadosBusca';
import Noticias from './pages/Noticias/Noticias';
import Agenda from './pages/Agenda';
import MapaOperacoes from './pages/MapaOperacoes/MapaOperacoes';
import Transmissoes from './pages/Transmissoes/Transmissoes';
import Portais from './pages/Portais/Portais';
import Admin from './components/Administracao';
import AdminUsuarioList from 'pages/Administrador/Usuario/List';
import AgendaProvas from './pages/AgendaProvas/AgendaProvas';
import EstruturaPadrao from 'pages/EstruturaPadrao';
import AdminSearch from 'pages/Administrador/Search';
import AccessProfiles from 'pages/Administrador/AccessProfiles';
import SystemAssignments from 'pages/Administrador/SystemAssignments';
import AdminFaq from 'pages/Administrador/Faq';
import AdminFaqList from 'pages/Administrador/Faq/components/List';
import ImportUsers from 'pages/Administrador/ImportUsers';
import ImportFiles from 'pages/Administrador/ImportFiles';
import AdminCategoryList from 'pages/Administrador/Category/components/List';
import AdminCategoria from 'pages/Administrador/Category';
import Personification from 'pages/Administrador/Personification';

import Faq from 'pages/Faq';
import NewSystemAssignments from 'pages/Administrador/NewSystemAssignments';
import LinkBusinessManager from 'pages/Administrador/LinkBusinessManager';
import ManagerBinding from 'pages/Administrador/ManagerBinding';
import EstruturaPadraoUsuario from 'pages/EstruturaPadrao/edit';
import Foca from 'pages/foca/index';
import RelatorioFoca from 'pages/foca/relatorio';
import Checkin from 'pages/foca/checkin';

import AdminUsuarioIndexCadastro from './pages/Administrador/Usuario/Cadastro';
import UsuarioPrecadastro from './pages/Login/PreCadastro';

//*********************************PORTAIS****************************************************/
import AdminPortaisList from 'pages/Administrador/Portais/List';
import AdminPortaisIndexCadastro from 'pages/Administrador/Portais/Cadastro';
//*******************************PORTAIS****************************************************/

//*********************************FILTROS****************************************************/
import AdminFiltersList from 'pages/Administrador/Filters/List';
import AdminFiltersListCadastro from 'pages/Administrador/Filters/Cadastro';
//*******************************FILTROS****************************************************/

//*********************************DOCUMENTTYPE****************************************************/
import AdminDocumentsTypeList from 'pages/Administrador/DocumentsType/List';
import AdminDocumentsTypeListCadastro from 'pages/Administrador/DocumentsType/Cadastro';
//*******************************DOCUMENTTYPE****************************************************/

//*********************************DOCUMENTGENERATIONANDSUBMISSION****************************************************/
import AdminDocumentGenerationAndSubmissionList from 'pages/Administrador/DocumentGenerationAndSubmission/List';
import AdminDocumentGenerationAndSubmissionCadastro from 'pages/Administrador/DocumentGenerationAndSubmission/Cadastro';
//*******************************DOCUMENTGENERATIONANDSUBMISSION****************************************************/


//*********************************PARAMETROS****************************************************/
import AdminParametersList from 'pages/Administrador/Parameters/List';
import AdminParametersListCadastro from 'pages/Administrador/Parameters/Cadastro';
//*******************************FPARAMETROS****************************************************/





import BasePolos from 'pages/Documentos/BasePolos/index';

import AdminBannerCadastro from 'pages/Administrador/Banner/Cadastro';
import AdminBannerList from 'pages/Administrador/Banner/List';
import Reports from 'pages/Administrador/RelatoriosAdm';
import {
  ReportExtraction,
  ReportInclusion,
  Filters,
  FilterInclusion,
} from 'pages/Administrador/RelatoriosAdm/components';

import AdminComunicadoCadastro from 'pages/Administrador/Comunicados/Cadastro';
import AdminMapaIndex from './components/Administracao/MapaOperacoes';
import AdminMapaCadastro from './components/Administracao/MapaOperacoes/cadastro';
import AdminMapaEditar from './components/Administracao/MapaOperacoes/editar';
import AdminAgendaIndex from './pages/Administrador/Agenda/Filtro';
import AdminAgenda from 'pages/Administrador/Agenda/Cadastro';
import AgendaPolo from 'pages/Agenda/AgendaPolo';
import usuariosPoloIndex from './components/UsuariosPolo/index';
import usuarioPolo from './pages/UsuarioPolo/Cadastro';
import NotFound from './pages/NotFound/NotFound';
import AdminComunicados from 'pages/Administrador/Comunicados/List';

import Relatorios from 'pages/Relatorios';

import GetUserAAD from 'pages/AzureAD/Test';
import checkin from 'pages/foca/checkin';
import Error from 'pages/Error/error';
import Workflow from 'pages/Administrador/Workflow';
import WorkFlowUser from './pages/WorkFlow/index.js';
import ImportFilesWorkFlow from 'pages/Administrador/Workflow/FileImport/index.js';
import RelatorioHome from 'pages/Relatorios/RelatorioHome/index.js';
import MeusDados from 'pages/MeusDados/index.js';
import AreaSolicitante from 'pages/Administrador/AreaSolicitante';
import FocaPDF from 'pages/foca/pdfFoca';
import MigracaoGN from 'pages/foca/migracaoGN';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Welcome} />
      <Route path="/home" component={Home} />
      <Route path="/polos" component={Polos} />
      <Route path="/login" component={Login} />
      <Route path="/relatorio/:id" component={Relatorios} />
      <Route path="/comunicados/:id" component={Comunicados} />
      <Route path="/comunicados" component={Comunicados} />
      <Route path="/comunicados/:binId" component={Categoria} />
      <Route path="/lista-comunicados/:binId" component={Categoria} />
      <Route path="/comunicados-tags/:binId" component={Categoria} />
      <Route exact path="/busca/comunicados" component={ComunicadosBusca} />
      <Route exact path="/busca/comunicados/:id" component={ComunicadosBusca} />
      <Route exact path="/busca/comunicados/:id/:subcategoriaId" component={ComunicadosBusca} />
      <Route path="/admin/comunicados/areaSolicitante" component={AreaSolicitante} />
      <Route path="/noticias" component={Noticias} />
      <Route path="/transmissoes" component={Transmissoes} />
      <Route path="/agenda" component={Agenda} />
      <Route path="/cadastro/agenda" component={AgendaPolo} />
      <Route path="/cadastro/agenda/:id" component={AgendaPolo} />
      <Route path="/agendar/provas/" component={AgendaProvas} />
      <Route path="/mapa" component={MapaOperacoes} />
      <Route path="/portais" component={Portais} />
      <Route path="/estrutura" component={EstruturaPadrao} />
      <Route path="/geradoc/basePolos" component={BasePolos} />
      <Route
        path="/usuario-estrutura/edit/:area/:id"
        component={EstruturaPadraoUsuario}
      />
      <Route
        path="/usuario-estrutura/:type/:area/:idEstPolo"
        component={EstruturaPadraoUsuario}
      />
      <Route
        path="/usuario-estrutura/:type/:idEstPolo"
        component={EstruturaPadraoUsuario}
      />
      <Route path="/FOCA" component={Foca} />
      <Route path="/migracaoGn" component={MigracaoGN} />
      <Route path="/extracaoRelatorioFoca" component={RelatorioFoca} />
      <Route path="/cadastro/FOCA" component={checkin} />
      <Route path="/cadastro/FOCA/:id" component={checkin} />
      <Route path="/FocaPdf/:id" component={FocaPDF} />

      <Route path="/administracao" component={Admin} />
      <Route path="/admin/portais" exact component={AdminPortaisList} />
      <Route path="/admin/portais/cadastro" component={AdminPortaisIndexCadastro} />
      <Route
        path="/admin/portais/edit/:id"
        component={AdminPortaisIndexCadastro}
      />
      <Route path="/admin/filters" exact component={AdminFiltersList} />
      <Route path="/admin/filters/:id" exact component={AdminFiltersList} />
      <Route path="/admin/filters/cadastro" component={AdminFiltersListCadastro} />
      <Route path="/admin/filters/cadastroRelatorio/:idRelatorio" component={AdminFiltersListCadastro} />
      <Route path="/admin/filters/edit/:idRelatorio/:id" component={AdminFiltersListCadastro} />
      <Route path="/admin/parameters" exact component={AdminParametersList} />
      <Route path="/admin/parameters/cadastro" component={AdminParametersListCadastro} />
      <Route path="/admin/parameters/edit/:id" component={AdminParametersListCadastro} />
      <Route path="/geradoc/envioDocumento" exact component={AdminDocumentGenerationAndSubmissionList} />
      <Route path="/geradoc/envioDocumento/cadastro" component={AdminDocumentGenerationAndSubmissionCadastro} />
      <Route path="/geradoc/envioDocumento/edit/:id" component={AdminDocumentGenerationAndSubmissionCadastro} />
      <Route path="/geradoc/tipoDocumento" exact component={AdminDocumentsTypeList} />
      <Route path="/geradoc/tipoDocumento/cadastro" component={AdminDocumentsTypeListCadastro} />
      <Route path="/geradoc/tipoDocumento/edit/:id" component={AdminDocumentsTypeListCadastro} />
      <Route path="/admin/usuario" component={AdminUsuarioList} />
      <Route
        path="/admin/usuarios/cadastro"
        component={AdminUsuarioIndexCadastro}
      />
      <Route
        path="/admin/usuarios/edit/:id/:profileId"
        component={AdminUsuarioIndexCadastro}
      />
      <Route
        path="/admin/usuarios/edit/:id"
        component={AdminUsuarioIndexCadastro}
      />
      <Route
        path="/admin/personification"
        component={Personification}
      />
      <Route
        path="/precadastro"
        component={UsuarioPrecadastro}
      />
      <Route path="/admin/comunicado" component={AdminComunicados} />
      <Route
        path="/admin/comunicados/cadastro"
        component={AdminComunicadoCadastro}
      />

      <Route
        path="/admin/comunicados/edit/:id"
        component={AdminComunicadoCadastro}
      />

      <Route path="/admin/search" component={AdminSearch} />

      <Route path="/admin/banners" component={AdminBannerList} />
      <Route path="/admin/relatorios" component={Reports} />
      <Route path="/admin/extracao-relatorios" component={ReportExtraction} />
      <Route
        exact
        path="/admin/incluir-relatorio"
        component={ReportInclusion}
      />
      <Route exact path="/admin/incluir-filtro" component={FilterInclusion} />
      <Route
        exact
        path="/admin/incluir-filtro/:idRelatorio"
        component={FilterInclusion}
      />
      <Route
        exact
        path="/admin/incluir-filtro/:idRelatorio/:idFiltro"
        component={FilterInclusion}
      />
      <Route exact path="/admin/filtros/:id" component={Filters} />
      <Route exact path="/admin/filtros" component={Filters} />

      <Route
        exact
        path="/admin/incluir-relatorio/:reportId"
        component={ReportInclusion}
      />

      <Route path="/admin/banner/cadastro" component={AdminBannerCadastro} />
      <Route path="/admin/banner/edit/:id" component={AdminBannerCadastro} />

      <Route path="/admin/mapa" component={AdminMapaIndex} />
      <Route
        path="/admin/mapaoperacoes/cadastro/:tipoEvento?/:macroID?/:atividadeID?"
        component={AdminMapaCadastro}
      />
      <Route
        path="/admin/mapaoperacoes/editar/:tipoEvento?/:eventoID?"
        component={AdminMapaEditar}
      />
      <Route path="/admin/agenda" component={AdminAgendaIndex} />
      <Route path="/admin/agendamento/cadastro" component={AdminAgenda} />
      <Route path="/admin/perfis" component={AccessProfiles} />
      <Route
        path="/admin/atribuicao/:id/:system/:profile/:profileId"
        exact
        component={SystemAssignments}
      />

      <Route
        path="/admin/cadastro/atribuicao/:postOrPut/:system/:profile/:systemId/:assignmentId/:profileId"
        component={NewSystemAssignments}
      />
      <Route
        path="/admin/cadastro/atribuicao/:postOrPut/:system/:profile/:systemId/:profileId"
        component={NewSystemAssignments}
      />

      <Route exact path="/admin/faq" component={AdminFaq} />
      <Route path="/admin/list/faq" component={AdminFaqList} />
      <Route exact path="/admin/faq/:faqId" component={AdminFaq} />

      <Route exact path="/admin/list/categoria" component={AdminCategoryList} />
      <Route exact path="/admin/categoria" component={AdminCategoria} />
      <Route
        exact
        path="/admin/categoria/:categoryId"
        component={AdminCategoria}
      />
      <Route path="/faq/:palavraChave" component={Faq} />
      <Route path="/faq" component={Faq} />
      <Route path="/admin/importacao-usuarios" component={ImportUsers} />
      <Route path="/admin/importacao-arquivos" component={ImportFiles} />

      <Route
        path="/admin/usuarios/vinculo-gn/lista/:userId"
        component={ManagerBinding}
      />
      <Route
        path="/admin/usuarios/vincular-gn"
        component={LinkBusinessManager}
      />
      <Route path="/meusdados" component={MeusDados} />
      <Route path="/admin/agendamento/edit/:id" component={AdminAgenda} />
      <Route path="/usuariosPolo" component={usuariosPoloIndex} />
      <Route path="/usuarios/cadastro" name="polo" component={usuarioPolo} />
      <Route path="/usuarios/edit/:id" name="polo" component={usuarioPolo} />
      <Route path="/error" component={Error} />
      <Route path="/workflow" component={WorkFlowUser} />
      <Route path="/geradoc/workflow" component={Workflow} />
      <Route path="/geradoc/importararquivo" component={ImportFilesWorkFlow} />
      <Route path="/relatorio" component={RelatorioHome} />
      <Route path="*" component={NotFound} />

      <Route path="/test/getUSerAzureAD" component={GetUserAAD} />


    </Switch>
  </Router>
);
export default Routes;
