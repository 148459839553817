import React from 'react';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../services/Gerais';
import { FieldBox } from './styles';
import { useReports } from './hook';
import { Button, Form, Input, Select, Table } from 'antd';

const Reports = () => {
  const paginaTitulo = 'Relatórios';
  const {
    columns,
    dataSource,
    loading,
    form,
    categoryOptions,
    idUser,
    onChange,
    cleanFields,
    getReports,
  } = useReports();
  return (
    <>
      <Helmet
        title={paginaTitulo}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: paginaTitulo + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'relatorios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin />
      <section className="columns is-multiline conteudoAdmin" id="admin-app">
        <MenuAdmin />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminRelatorios"
                pageName="Relatórios"
                titulo={paginaTitulo}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div
                  className="button-wrapper"
                  id="components-dropdown-demo-dropdown-button"
                >
                  <Button
                    size="large"
                    type="primary"
                    href="/admin/extracao-relatorios"
                  >
                    Extrair relatórios
                  </Button>
                  {idUser == 999 ? (
                    <Button
                      size="large"
                      type="primary"
                      href="/admin/incluir-relatorio"
                    >
                      Incluir relatório
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <FieldBox>
            <Form.Item style={{ flex: 0.5 }} label="Nome do Relatório">
              <Input
                value={form.nome}
                onChange={(event) => onChange(event.target.value, 'nome')}
                placeholder="Digite o nome do relatório"
              />
            </Form.Item>
            <Form.Item style={{ flex: 0.3 }} label="Categoria">
              <Select
                value={form.categoria}
                onChange={(value) => onChange(value, 'categoria')}
                placeholder="Selecione"
              >
                {categoryOptions.map((item) => (
                  <Select.Option key={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item style={{ flex: 0.3 }} label="Administrativo">
              <Select
                value={form.adm}
                onChange={(value) => onChange(value, 'adm')}
                placeholder="Selecione"
              >
                <Select.Option key="S">SIM</Select.Option>
                <Select.Option key="N">NAO</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ flex: 0.2 }} label="Status">
              <Select
                value={form.ativo}
                onChange={(value) => onChange(value, 'ativo')}
                placeholder="Selecione"
              >
                <Select.Option key="S">ATIVO</Select.Option>
                <Select.Option key="N">INATIVO</Select.Option>
              </Select>
            </Form.Item>
            <Button
              style={{
                'background-color': '#52c41a',
                border: 'none',

                flex: 0.1,
                marginTop: '14px',
              }}
              onClick={async () => await getReports(true)}
              type="primary"
            >
              Pesquisar
            </Button>
            <Button
              style={{
                flex: 0.1,
                marginTop: '14px',
              }}
              onClick={cleanFields}
              type="primary"
            >
              Limpar
            </Button>
          </FieldBox>
          <Table {...{ columns, dataSource, loading }} size="small" />
        </div>
      </section>
      <FooterAdmin />
    </>
  );
};

export default Reports;
