import styled from 'styled-components';

const HeaderBox = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const FieldBox = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  width: 100%;
`;

const IconBox = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const Column = styled.div`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
`;

export { HeaderBox, Column, FieldBox, IconBox };
