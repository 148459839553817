import Swal from 'sweetalert2';
import { $ } from '../../services/Gerais';

export function deletePerfil (reacord, isUpdate) {
  const url = JSON.parse(localStorage.getItem('cnfgDataCRP'));
  if(reacord == undefined || reacord.usuarioPolo.id == undefined) return
  $.ajax({
    url:
      url.baseUrl +
      `usuario/inativaUsuario/${reacord.usuarioPolo.id}/${reacord.lstUsuarioPoloPerfil[0].perfil.id}`,
    type: 'PATCH',
    headers: {
      Authorization:
        'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(reacord.usuarioPolo.usuarioPortal),
    success: function (data) {
      if(!isUpdate) {
        Swal.fire({
          position: 'top-end',
          title: `Perfil inativado com sucesso !`,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    error: function (error) {
      if(!isUpdate) {
        Swal.fire({
          position: 'top-end',
          title: 'Ops...!',
          text: 'Não foi possivel inativar',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      }

    },
  });
}
