import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axios } from '../../../../../services/Gerais';
import api from '../../../../../services/api';
import {
  Table,
  Button,
  Icon,
  Tag,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tooltip,
  AutoComplete,
} from 'antd';

import { changeUsuario } from '../../../../../store/actions/Administrativo/Usuario/UsuarioAction';

import { cpfMask } from '../../../../../utils/masks';

import Swal from 'sweetalert2';

import { listUsuarioPortal, findByFilters } from '../../service';
import { set } from 'react-ga';

const { Option } = Select;

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
};

const UsuariosTable = ({ form }) => {
  const { getFieldDecorator, getFieldsError } = form;
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({});

  const [lstData, setLstData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ position: 'bottom' });
  const [dadosPerfil, setDadosPerfil] = useState({});
  const [polosPerfil, setPolosPerfil] = useState({});
  const [poloId, setPoloId] = useState(
    JSON.parse(localStorage.getItem('userDataCRP-Polo'))
  );

  useEffect(() => {
    fetch({ page: 0, isTelaUsuariosPolo: false, isFirstOpen: true });

    dispatch(
      changeUsuario({
        prop: 'carregando',
        value: true,
      })
    );
    if(userData.usuarioPortal == null ? window.location.reload(false) : '')
    console.log('chamando api')
    api
      .get(
        `usuario/cadastrarUsuario/${userData.usuarioPortal.id}` +
          `${poloId > 0 ? `?poloId=${poloId}` : ''}`
      )
      .then((res) => {
        setDadosPerfil({
          ...res.data,
        });
      });

    api
      .get(`polo/getPoloByTipoPolo/${userData.usuarioPortal.funcionario}`)
      .then((res) => {
        setPolosPerfil({
          ...res.data,
        });
      });
    // eslint-disable-next-line
  }, []);

  const buscaPolo = (search) => {
    if (search && search.length > 1) {
      const optionsArray = polosPerfil.Polos;

      const searchArray =
        optionsArray.length > 0
          ? optionsArray.filter((item) =>
              item.nome.includes(search.toUpperCase())
            )
          : [];

      setPolosPerfil(
        Object.assign(polosPerfil, { PolosPesquisa: searchArray })
      );
    } else {
      setPolosPerfil({ Polos: polosPerfil.Polos });
    }
  };

  const userDataPerfil = localStorage.getItem('userDataCRP-Perfil');
  const userData = JSON.parse(localStorage.getItem('userDataCRP'));
  const url = JSON.parse(localStorage.getItem('cnfgDataCRP'));

  const tableColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      valueType: 'indexBorder',
      hideInSearch: true,
      hideInTable: true,
      width: 60,
    },
    {
      title: 'Nome Completo',
      key: 'nome',
      dataIndex: 'nome',
      copyable: true,
      ellipsis: true,
    },
    {
      title: 'Email',
      key: 'conta',
      dataIndex: 'conta',
      copyable: true,
      ellipsis: true,
    },
    {
      title: 'CPF',
      key: 'cpf',
      dataIndex: 'cpf',
      copyable: true,
      ellipsis: true,
      width: 150,
      render: (cpf) => {
        return cpfMask(cpf);
      },
    },
    {
      title: 'Status',
      key: 'situacaoUsuarioId',
      dataIndex: 'situacaoUsuarioId',
      width: 90,
      render: (ativo) => {
        if (ativo !== null) {
          let objTag = {};
          switch (ativo) {
            case 1:
              objTag = { tag: 'ativo', color: '#87d068' };
              break;
            case 2:
              objTag = { tag: 'inativo', color: 'volcano' };
              break;
            case 3:
              objTag = { tag: 'bloqueado', color: 'purple' };
              break;
            default:
              break;
          }
          return (
            <span>
              <Tag color={objTag.color} key={objTag.tag}>
                {objTag?.tag?.toUpperCase()}
              </Tag>
            </span>
          );
        }
      },
    },
    {
      title: 'Colaborador',
      dataIndex: 'funcionario',
      width: 100,
      render: (funcionario) => {
        if (funcionario !== null) {
          let objTag = {};
          switch (funcionario) {
            case 'S':
              objTag = { tag: 'Funcionário', color: 'geekblue' };
              break;
            case 'N':
              objTag = { tag: 'Parceiro', color: 'gold' };
              break;
            default:
              break;
          }
          return (
            <span>
              <Tag color={objTag.color} key={objTag.tag}>
                {objTag?.tag?.toUpperCase()}
              </Tag>
            </span>
          );
        }
      },
    },
    {
      title: 'Ação',
      valueType: 'action',
      dataIndex: 'id',
      key: 'edit',
      width: 80,
      render: (id) => {
        const urlToForward =
          payload.perfil === '502'
            ? `/admin/usuarios/edit/${id}/502`
            : `/admin/usuarios/edit/${id}`;
        return (
          <Button
            key={`edit_${id}`}
            href={urlToForward}
            type="dashed"
            className="button is-small is-warning"
            size="small"
          >
            <FontAwesomeIcon icon={['fas', 'user-cog']} /> &nbsp; Editar
          </Button>
        );
      },
    },
  ];
  if (userDataPerfil == 999) {
    tableColumns.push({
      title: 'Excluir',
      valueType: 'action',
      dataIndex: 'id',
      key: 'delete',
      width: 80,
      render: (id) => {
        return (
          <Button
            key={`delete_${id}`}
            type="dashed"
            className="button is-small is-warning"
            onClick={() => openModal(id)}
            size="small"
          >
            <FontAwesomeIcon icon={['fas', 'user-minus']} /> &nbsp; Excluir
          </Button>
        );
      },
    });
  }

  function openModal(id) {
    Swal.fire({
      title: `Deseja excluir definitivamente o usuário ${id}?`,
      text:
        'Após a exclusão dos dados não será possível sua recuperação, Deseja Prosseguir com a Exclusão?',
      icon: 'warning',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed === true) {
        axios
          .delete(url.baseUrl + `usuario/${id}`, {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
          })
          .then((result) =>
            Swal.fire({
              position: 'top-end',
              title: `Usuário excluido com sucesso! Redirecionando...`,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              window.location.replace('/admin/usuario');
            })
          )
          .catch((error) =>
            Swal.fire({
              position: 'top-end',
              title: `Erro ao excluir o Usuário, favor tente novamente`,
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            })
          );
      }

      return;
    });
  }

  const handleTableChange = (pagination) => {
    const pager = { ...pagination };
    pager.current = pagination.current - 1;
    setPagination({
      pager,
    });
    fetch({
      page: pager.current,
    });
  };

  const fetch = async (params = {}) => {
    let search = payload;
    search.isTelaUsuariosPolo = false
    search.isFirstOpen = (Object.keys(search).length >= 4 ? false : true);

    search.page = params.page;
    setPayload({ ...payload, ...params });
    try {
      setLoading(true);
      const res = await findByFilters(search);
      setPagination({
        total: res.total,
        current: res.page + 1,
      });

      setLoading(false);
      setLstData(res.data);
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleSubmit = (e) => {
    const pager = { ...pagination };
    pager.current = 0;
    setPagination({
      pager,
    });
    e.preventDefault();
    form.validateFields((err, _values) => {
      if (!err) {
        fetch({ page: 0 });
      }
    });
  };

  const changeCpf = (e) => {
    setPayload({
      ...payload,
      cpf: cpfMask(e.target.value.trim()).replace(/\D/g, ''),
    });
  };


  return (
    <div key="Seila">
      {/*JSON.stringify(payload)*/}
      <Form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item size="large">
              {getFieldDecorator('nome')(
                <Input
                  placeholder="Nome usuário"
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      nome: e.target.value.trim(),
                    })
                  }
                  allowClear
                  onBlur={(e) => (e.target.value = e.target.value.trim())}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item size="large">
              {getFieldDecorator('email')(
                <Input
                  placeholder="email@email"
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      email: e.target.value.trim().toLowerCase(),
                    })
                  }
                  allowClear
                  onBlur={(e) => (e.target.value = e.target.value.trim())}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('cpf', {
                getValueFromEvent: (e) => cpfMask(e.target.value),
                rules: [
                  {
                    min: 14,
                    message: 'Por favor insira um cpf válido',
                  },
                ],
              })(
                <Input
                  placeholder="CPF usuário"
                  onChange={changeCpf}
                  allowClear
                  onBlur={(e) => (e.target.value = e.target.value.trim())}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('funcionario')(
                <Select
                  placeholder="Colaborador tipo"
                  onChange={(e) => setPayload({ ...payload, funcionario: e })}
                  allowClear
                >
                  <Option value="S">Funcionário</Option>
                  <Option value="N">Parceiro</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('status')(
                <Select
                  placeholder="Status"
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      situacaoUsuario: e,
                    })
                  }
                  allowClear
                >
                  <Option value="1">ATIVO</Option>
                  <Option value="2">INATIVO</Option>
                  <Option value="3">BLOQUEADO</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <Form.Item size="large">
              {getFieldDecorator(`perfil`, {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                dadosPerfil.Perfil ? (
                  <AutoComplete
                    dataSource={Object.keys(dadosPerfil.Perfil).map((key) => {
                      return (
                        <Option key={[key]}>{dadosPerfil.Perfil[key]}</Option>
                      );
                    })}
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        perfis: e,
                      })
                    }
                    allowClear
                    placeholder="Selecione um perfil"
                  />
                ) : (
                  <AutoComplete placeholder="Selecione um perfil" />
                )
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              {getFieldDecorator(`polo`, {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                polosPerfil.Polos ? (
                  <AutoComplete
                    dataSource={
                      polosPerfil.PolosPesquisa
                        ? polosPerfil.PolosPesquisa.map((item) => {
                            return <Option key={[item.id]}>{item.nome}</Option>;
                          })
                        : polosPerfil.Polos.map((item) => {
                            return <Option key={[item.id]}>{item.nome}</Option>;
                          })
                    }
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        polosIds: e,
                      })
                    }
                    allowClear
                    onSearch={buscaPolo}
                    placeholder="Pesquise o Polo"
                  />
                ) : (
                  <AutoComplete placeholder="Pesquise o Polo" />
                )
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              {getFieldDecorator(`poloProprio`, {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Select
                  placeholder="Tipo de Polo"
                  value={payload.tipoPolo}
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      poloProprio: e,
                    })
                  }
                >
                  <Option key="S">Próprio</Option>
                  <Option key="N">Parceiro</Option>
                  <Option key="RS">Revenue Share</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={3} style={{ textAlign: 'center' }}>
            <Form.Item size="small">
              <Button.Group>
                <Tooltip placement="topLeft" title="Filtrar">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                  >
                    <Icon type="search" />
                  </Button>
                </Tooltip>
                <Tooltip placement="topRight" title="Limpar Filtros">
                  <Button
                    type="dashed"
                    htmlType="button"
                    disabled={hasErrors(getFieldsError())}
                    onClick={() => {
                      setPayload({
                        page: 0,
                      });
                      form.resetFields();
                    }}
                  >
                    <Icon type="undo" />
                  </Button>
                </Tooltip>
              </Button.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
         pagination={pagination}
        dataSource={lstData}
        loading={loading}
        columns={tableColumns}
        onChange={handleTableChange}
        size="small"
      />
    </div>
  );
};

export default UsuariosTable;
