import styled from 'styled-components';

export const Container = styled.div`
    .card-container > .ant-tabs-card > .ant-tabs-bar {
        border: none;
        text-align: left;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
        border-radius: 0px 10px 0px 0px !important;
        -moz-border-radius: 0px 10px 0px 0px !important;
        -webkit-border-radius: 0px 10px 0px 0px !important;
        background-color: #f9f9f9;
        border-color: #d9d9d9 !important;
        color: #a0a0a0;
        margin-left: 10px !important;

    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane, .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
        border-color: transparent;
        background: transparent;
        border: 1px solid #d9d9d9;
        margin-bottom: 20px;
        border-radius: 4px;
        margin-top: -16px;
        padding: 15px;
        text-align: left;
    }

`;
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
