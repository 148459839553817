import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Form, Steps, Button, Icon, Col } from 'antd';
import Swal from 'sweetalert2';
import ComunicadoTipos from './components/Steps/ComunicadoTipos';
import avatar from 'img/loadingtwo.gif';
import {
  salvarComunicado,
  alterarComunicado,
  getComunicadoId,
  getDadosIniciaisComunicado,
  changeComunicado,
  getLstComunicadoPolo,
} from 'store/actions/Administrativo/Comunicado/ComunicadoActions';

import ComunicadoConteudo from './components/Steps/ComunicadoConteudo';
import ComunicadoFiltros from './components/Steps/ComunicadoFiltros';
import 'antd/dist/antd.css';
import './index.scss';
const { Step } = Steps;

const ButtonGroup = Button.Group;

const stepStyle = {
  // marginBottom: 60,
  // boxShadow: '0px -1px 0 0 #e8e8e8 inset',
};

const MsgSalvoComSucesso = ({ history, changeComunicado }) => {
  const dispatch = useDispatch();
  if (changeComunicado) {
    dispatch(
      changeComunicado({
        prop: 'succes',
        value: false,
      })
    );
  }

  Swal.fire({
    position: 'top-end',
    title:  `Salvo com sucesso! Redirecionando...`,
    icon: 'success',
    showConfirmButton: false,
    timer: 1500,
  }).then(result => {
    window.location.replace('/admin/comunicado')
  })
  return null;
};

const MgErroAoSalvar = ({ error, changeComunicado }) => {
  const dispatch = useDispatch();
  if (changeComunicado) {
    dispatch(
      changeComunicado({
        prop: 'error',
        value: null,
      })
    );
  }

  if (error) {
    Swal.fire({
      title: 'Erro',
      text: error,
      icon: 'error',
      confirmButtonText: 'Ok'
    })
  }
  return null;
};

const ComunicadoForm = ({ form, isEdit, history, idComunicado }) => {
  let {
    carregandoDadosIniciais,
    botaoSalvarHabilitado,
    DadosComunicado,
    succes,
    error,
    carregandoUpload,
  } = useSelector((state) => state.Comunicado);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const steps = [
    {
      title: 'Comunicado',
      content: <ComunicadoConteudo formm={form} />,
      disabled: carregandoUpload,
      icon: <Icon type="message" />,
      status: 'finish',
    },
    {
      title: 'Marca',
      content: <ComunicadoTipos form={form} />,
      disabled: !isEdit,
      icon: <Icon type="notification" />,
      status: 'process',
    },
    {
      title: 'Informações',
      content: <ComunicadoFiltros form={form} />,
      disabled: !isEdit,
      icon: <Icon type="smile-o" />,
      status: 'wait',
    },
  ];

  useEffect(() => {
    dispatch(
      changeComunicado({
        prop: 'carregando',
        value: true,
      })
    );
    dispatch(getDadosIniciaisComunicado());
  }, [dispatch]);

  useEffect(
    () => {
      if (idComunicado) {
        dispatch(
          changeComunicado({
            prop: 'carregando',
            value: true,
          })
        );
        dispatch(
          changeComunicado({
            prop: 'isEdit',
            value: true,
          })
        );
        dispatch(getComunicadoId(idComunicado));
      }
    },
    // eslint-disable-next-line
    [idComunicado]
  );

  // https://codesandbox.io/s/xv74yy0lpp?file=/First.js:425-437
  const next = () => {
    if (current === 0) {
      form.validateFieldsAndScroll(
        ['titulo', 'area', 'conteudo', 'resumo'],
        (err, values) => {
          if (!err) {
            if (isEdit) {
              dispatch(
                changeComunicado({
                  prop: 'carregandoUpload',
                  value: true,
                })
              );
              dispatch(getLstComunicadoPolo(idComunicado));
            }
            setCurrent(current + 1);
          }
        }
      );
    } else if (current === 1) {
      form.validateFieldsAndScroll(['marcas', 'lotePorPolo'], (err, values) => {
        if (!err) {
          setCurrent(current + 1);
        }
      });
    }
  };
  const prev = () => setCurrent(current - 1);

  const onChange = (current) => {
    setCurrent(current);
  };

  const handleSubmit = (e) => {
    form.validateFieldsAndScroll(
      [
        'canal',
        'critico',
        'categoria',
        'data',
        'setorFuncao',
        'destaque',
        'imagem',
        'expiracao'
      ],
      (err, values) => {
        if (!err) {
          dispatch(
            changeComunicado({
              prop: 'botaoSalvarHabilitado',
              value: false,
            })
          );
          if (isEdit) {
            dispatch(alterarComunicado(DadosComunicado, idComunicado));
          } else {
            dispatch(salvarComunicado(DadosComunicado));
          }
        }
      }
    );
  };

  const estadoCarregando = () => {
    let retorno = false;
    if (isEdit) {
      if (
        carregandoDadosIniciais ||
        DadosComunicado.comunicado.conteudo === ''
      ) {
        retorno = true;
      }
    } else {
      if (carregandoDadosIniciais) {
        retorno = true;
      }
    }
    return retorno;
  };

  return (
    <Form>
      <Row gutter={16} justify="center">
        {succes && (
          <MsgSalvoComSucesso
            history={history}
            changeComunicado={changeComunicado}
          />
        )}
        {!succes && error && (
          <MgErroAoSalvar error={error} changeComunicado={changeComunicado} />
        )}
        {estadoCarregando() ? (
          <Row type="flex" justify="center" gutter={16}>
            <div className="CRP-loading">
              <img src={avatar} width="100" alt="Carregando..." />
            </div>
          </Row>
        ) : (
          <>
            <Steps
              current={current}
              onChange={onChange}
              style={stepStyle}
              type="navigation"
            >
              {steps.map((item) => (
                <Step
                  key={item.title}
                  status={item.status}
                  icon={item.icon}
                  form={form}
                  disabled={item.disabled}
                  title={item.title}
                />
              ))}
            </Steps>

            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              <Row type="flex" justify="end" gutter={16}>
                <Col span={8} justify="end" className="gutter-row">
                  {current < steps.length - 1 && (
                    <Button
                      type="primary"
                      justify="end"
                      size="large"
                      onClick={next}
                      disabled={carregandoUpload}
                    >
                      Próximo
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <ButtonGroup size="large" block>
                      <Button onClick={() => history.push('/admin/comunicado')}>
                        <Icon type="stop" />
                        Cancelar
                      </Button>

                      {!botaoSalvarHabilitado ? (
                        <Button
                          type="primary"
                          size="large"
                          style={{
                            'background-color': '#52c41a',
                            border: 'none',
                          }}
                          loading
                        >
                          Carregando
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          size="large"
                          style={{
                            'background-color': '#52c41a',
                            border: 'none',
                          }}
                          htmlType="submit"
                          onClick={handleSubmit}
                        >
                          <Icon type="save" /> Salvar
                        </Button>
                      )}
                    </ButtonGroup>
                  )}

                  {current > 0 && (
                    <Button
                      size="large"
                      style={{ margin: 8 }}
                      onClick={() => prev()}
                      disabled={carregandoUpload}
                    >
                      Anterior
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </>
        )}
      </Row>
    </Form>
  );
};

export default ComunicadoForm;
