import api from '../api';

export const fetchSystemAssignment = async (params) => {
  return await api.get(`/atribuicao/sistema/${params.id}/perfil/${params.profileId}`);
};

export const fetchAssignment = async (params) => {
  return await api.get(
    `/atribuicao/${params.firstId}/sistema/${params.secondId}/perfil/${params.thirdId}`
  );
};

export const deleteSystemAssignment = async (params) => {
  return await api.delete(`/atribuicao/${params.id}`);
};

export const updateSystemAssignment = async (params) => {
  return await api.put(`/atribuicao/${params.id}`, params.body);
};
export const createSystemAssignment = async (params) => {
  return await api.post(`/atribuicao/`, params.body);
};
