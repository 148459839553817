import api from '../../../services/api';

export const list = async () => {
  try {
    const res = await api.get(`parametrosprisma/`);
    return res.data
  } catch (error) {
    throw error;
  }
};

export const filterById = async (id) => {
  try {
    const res = await api.get(`parametrosprisma/${id}`);
    return res.data
  } catch (error) {
    throw error;
  }
};

export const findBy = async (params) => {
  try {
    const res = await api.get(`parametrosprisma/filter`, { params: params });
    return res.data
  } catch (error) {
    return error;
  }
};

export const register = async (body) => {
  try {
    const res = await api.post(`parametrosprisma/`, body);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export const update = async (body, id) => {
  try {
    const res = await api.put(`parametrosprisma/${id}`, body);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export const onExecuteJob = async (idJob) => {
  await api.put(`job/${idJob}`)
}
