import React, { Component } from "react";
import "./../../styles/Styles.scss";
import logo from "../../img/PRISMA_erro.png";
import { library, Helmet } from "../../services/Gerais";
import { faGrinBeamSweat } from "@fortawesome/free-regular-svg-icons";
library.add({ faGrinBeamSweat });

class NotFound extends Component {
  render() {
    return (
      <div className="container welcome-screen">
        <Helmet
          key="NotFoundPage"
          title="404 - Página não encontrada - Portal Prisma - Kroton"
          meta={[
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1"
            },
            {
              name: "description",
              content: "Portal Unificado da Kroton Educacional"
            },
            { name: "keywords", content: "kroton, educacional, portal" }
          ]}
        />
        <div className="column has-text-centered">
          <div class="column is-3 is-offset-two-fifths has-text-centered">
            <figure class="image is-square">
              <img src={logo} alt="Portal Unificado da Kroton Educacional" />
            </figure>
          </div>
          <hr />
          <h2 className="title" id="welcomeText">
            Ops, página não encontrada...
          </h2>
          <div class="column has-text-centered">
            <p>
              Voltar para <a href="/home">Home</a>?
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
