import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  height: 77px;
  align-items: center;
  margin-top: 12px;
  column-gap: 14px;
`;

export { ButtonContainer };
