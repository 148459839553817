import React, { useState, useEffect } from 'react';
import { Header, Hero, Helmet } from '../../services/Gerais';
import WorkFlow from '../../components/WorkFlow/index.js';

const WorkFlowUser = props => {

  return (
    <>
      <Helmet
        title="WorkFlow - Portal Prisma - Kroton"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: 'WorkFlow - Portal Unificado da Kroton Educacional',
          },
          { name: 'keywords', content: 'agenda, kroton, educacional, portal' },
        ]}
        key="titleWorkFlow"
      />
      <Header key="HeaderWorkFlow" />
      <Hero key="heroWorkFlow" pageName="WorkFlow" titulo={`WorkFlow`} />
      <section className="conteudoPortais" key="conteudoPortais" style={{ marginTop: '0.5rem'}}>
        <div className="container is-full-fullhd">
          <WorkFlow isAdmin={false} />
        </div>
      </section>
    </>
  );
};

export default WorkFlowUser;
