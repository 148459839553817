import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  padding: 30px;
  line-height: 1.5;
  font-size: 14px;

  h1 {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }

  h2 {
    font-weight: bold;
    margin-top: 10px;
  }

  p > strong,
  p > b {
    font-weight: bold;
  }
`;

export const LineButton = styled.div`
  display: flex;
  p {
    display: flex;
    justify-content: end !important;
    width: 100%;
  }
`;
