export const COMUNICADO_SUCCESS = 'COMUNICADO_SUCCESS';
export const COMUNICADO_SUCCESS_SAVE = 'COMUNICADO_SUCCESS_SAVE';
export const COMUNICADO_ERROR = 'COMUNICADO_ERROR';
export const COMUNICADO_CHANGE = 'COMUNICADO_CHANGE';
export const COMUNICADO_DADOS_INICIAIS_SUCCESS =
  'COMUNICADO_DADOS_INICIAIS_SUCCESS';
export const COMUNICADO_DADOS_INICIAIS_ERROR =
  'COMUNICADO_DADOS_INICIAIS_ERROR';
export const COMUNICADO_UPLOAD_SUCCESS = 'COMUNICADO_UPLOAD_SUCCESS';
export const COMUNICADO_UPLOAD_ERROR = 'COMUNICADO_UPLOAD_ERROR';
export const COMUNICADO_POLO_SUCCESS = 'COMUNICADO_POLO_SUCCESS';
export const COMUNICADO_POLO_ERROR = 'COMUNICADO_POLO_ERROR';
