// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import configuracao from '../config/config';

const loginPage = configuracao.contexto + '/login';

const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/common', //process.env.REACT_APP_AUTHORITY,
    // clientId: "087fdd24-595a-4c5f-85ed-8e9cf4b7f919", //process.env.REACT_APP_AAD_APP_CLIENT_ID,
    clientId: 'c90b68b7-bba0-4e71-892a-d5ac84c3d9c5', //process.env.REACT_APP_AAD_APP_CLIENT_ID,
    postLogoutRedirectUri: loginPage,
    redirectUri: loginPage,
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const authenticationParameters = {
  scopes: ['openid', 'https://graph.microsoft.com/.default'],
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  LoginType.Redirect
);
