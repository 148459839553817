import { axios } from './Gerais';
import jwt from 'jwt-decode';
import React, { Component } from 'reactn';

export function PostAuthPrisma(endPoint, userData) {
  // let BaseURL = 'http://prisma.krthomolog.com.br:8080/api/';
  let BaseURL = 'http://10.201.40.37:8080/api/';

  // Setar a URL local:
  const urlLocalPort = window.location.port;
  const urlLocal = window.location.protocol + '//' + window.location.hostname;

  if (urlLocalPort !== '' && parseInt(urlLocalPort) <= 999) {
    BaseURL = 'http://localhost:8080/api/';
    // BaseURL = "http://10.201.3.83:8080/api/"
  } else {
    // se o link for de producao (prisma.kroton...)
    if (urlLocal.includes('prisma.kroton')) {
      // BaseURL = window.location.protocol+"//apiprisma.kroton.com.br/api/"
      BaseURL = 'https://apiprisma.kroton.com.br/api/';
    } else if (urlLocal.includes('10.201.40.65')) {
      // se o link for do ambiente stage
      BaseURL = window.location.protocol + '//10.201.40.67:8080/api/';
    } else if (urlLocal.includes('prismadevfront.krthomolog.com.br')) {
      // se o link for do ambiente stage
      BaseURL = '//prismadevback.krthomolog.com.br/api/';
    } else if (urlLocal.includes('prismatstfront.krthomolog.com.br')) {
      // se o link for do ambiente stage
      BaseURL = '//prismatstback.krthomolog.com.br/api/';
    } else if (urlLocal.includes('prismafrontstg.krthomolog.com.br')) {
      // se o link for do ambiente stage
      BaseURL = '//prismabackstg.krthomolog.com.br/api/';
    } else if (urlLocal.includes('prismafrontstg2.krthomolog.com.br')) {
      // se o link for do ambiente stage
      BaseURL = '//prismabackstg2.krthomolog.com.br/api/';
    } else {
      BaseURL = 'http://localhost:8080/api/';
    }
  }
  if(urlLocal.includes('prismamtc.krthomolog')) {
    BaseURL = 'https://prismabackmtc.krthomolog.com.br/api/';
  }

  return new Promise((resolve, reject) => {
    let data = JSON.stringify({
      token: userData,
    });
    let urlPost = BaseURL + endPoint;
    axios
      .post(urlPost, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers':
            'content-type,Authorization,authorization',
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Expose-Headers':
            'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Access-Control-Allow-Credentials, Authorization, authorization',
        },
        validateStatus: function (status) {
          //return status >= 200 && status < 400;
          return status;
        },
        // crossdomain: true,
      })
      .then((response) => {
        if (response.status == 401) {
          resolve({ status: 401 });
        } else {
          localStorage.setItem(
            'userDataCRP-AuthTk',
            response.headers.authorization.replace('Bearer ', '')
          );
          resolve(response);
        }
      })
      .catch((response) => {
        if(response.status === 500){
          resolve({ status: 500 });
          return;
        }
        else if (response.message === 'Network Error') {
          resolve({ status: 400 });
        }
      });
  });
}
