import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Table,
  Button,
  Icon,
  Tag,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tooltip,
} from 'antd';

import { changeBanner } from 'store/actions/Administrativo/Banner/BannerActions';

import {
  buscaDadosIniciaisBanner,
  buscaBanners,
  findByFilters,
} from '../../service';

import moment from 'moment';

const { Option } = Select;

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

const BannersTable = ({ form }) => {
  const { getFieldDecorator, getFieldsError } = form;
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(false);

  const [lstSetor, setLstSetor] = useState([]);
  const [lstPerfil, setLstPerfil] = useState([]);

  const [payload, setPayload] = useState({});

  const [lstData, setLstData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ position: 'bottom' });

  const buscaDados = async () => {
    try {
      const res = await buscaDadosIniciaisBanner();
      dispatch(
        changeBanner({
          prop: 'carregando',
          value: false,
        })
      );
      setLstSetor(res.lstSetor);
      setLstPerfil(res.lstPerfil);
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(
      changeBanner({
        prop: 'carregando',
        value: true,
      })
    );
    buscaDados();
    // eslint-disable-next-line
  }, []);

  const tableColumns = [
    {
      title: 'COD',
      key: 'COD',
      dataIndex: 'id',
      valueType: 'indexBorder',
      hideInSearch: true,
      hideInTable: true,
    },
    {
      title: 'Titulo',
      key: 'titulo',
      dataIndex: 'titulo',
      copyable: true,
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Ativo',
      key: 'ativo',
      dataIndex: 'ativo',
      render: ativo => {
        if (ativo !== null) {
          const objTag =
            ativo === 'S'
              ? { tag: 'ativo', color: '#87d068' }
              : { tag: 'inativo', color: 'volcano' };
          return (
            <span>
              <Tag color={objTag.color} key={objTag.tag}>
                {objTag.tag.toUpperCase()}
              </Tag>
            </span>
          );
        }
      },
    },
    {
      title: 'Canal',
      dataIndex: 'canal',
      render: canal => {
        if (canal !== null) {
          let objTag = {};
          switch (canal) {
            case 'S':
              objTag = { tag: 'Próprio', color: 'geekblue' };
              break;
            case 'N':
              objTag = { tag: 'Parceiro', color: 'gold' };
              break;
            case 'T':
              objTag = { tag: 'Todos', color: 'cyan' };
              break;
            default:
              break;
          }
          return (
            <span>
              <Tag color={objTag.color} key={objTag.tag}>
                {objTag.tag.toUpperCase()}
              </Tag>
            </span>
          );
        }
      },
    },
    {
      title: 'Prioritário',
      dataIndex: 'prioritario',
      render: prioritario => {
        if (prioritario !== null) {
          const objTag =
            prioritario === 'S'
              ? { tag: 'sim', color: 'green' }
              : { tag: 'não', color: '' };
          return (
            <span>
              <Tag color={objTag.color} key={objTag.tag}>
                {objTag.tag.toUpperCase()}
              </Tag>
            </span>
          );
        }
      },
    },
    {
      title: 'Data Criação',
      key: 'dataCriacao',
      dataIndex: 'dataCriacao',
      valueType: 'dateTime',
      render: dataCriacao => {
        return moment(dataCriacao, 'DD-MM-YYYY').format('DD/MM/YYYY');
      },
    },
    {
      title: 'Ação',
      valueType: 'action',
      dataIndex: 'id',
      key: 'edit',
      render: id => {
        return (
          <Button
            key={`edit_${id}`}
            href={`/admin/banner/edit/${id}`}
            type="dashed"
            className="button is-small is-warning"
            size="small"
          >
            <Icon type="file-search" />
            Editar
          </Button>
        );
      },
    },
  ];

  const handleTableChange = pagination => {
    const pager = { ...pagination };
    pager.current = pagination.current - 1;
    setPagination({
      pager,
    });
    fetch({
      page: pager.current,
    });
  };

  const fetch = async (params = {}) => {
    try {
      setLoading(true);
      const res = !filter
        ? await buscaBanners(params)
        : await findByFilters(payload, params);

      setPagination({
        total: res.total,
        current: params.page + 1,
      });

      setLoading(false);
      setLstData(res.data);
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleSubmit = e => {
    const pager = { ...pagination };
    pager.current = 0;
    setPagination({
      pager,
    });
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        fetch({ page: 0 });
      }
    });
  };

  useEffect(
    () => {
      if (!filter) {
        fetch();
      }
    },
    // eslint-disable-next-line
    [filter]
  );

  return (
    <div key="Seila">
      <Form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
        <Row gutter={16}>
          <Col span={5}>
            <Form.Item size="large">
              {getFieldDecorator('titulo')(
                <Input
                  placeholder="Titulo banner"
                  onChange={e =>
                    setPayload({ ...payload, titulo: e.target.value.trim() })
                  }
                  allowClear
                  onBlur={e => e.target.value = e.target.value.trim()}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('lstBannerPerfilBanner')(
                <Select
                  mode="tags"
                  placeholder="Selecione os perfis"
                  allowClear
                  onChange={e => {
                    if (e) {
                      let perfis = [];
                      e.map(perfilId =>
                        perfis.push({ perfil: { id: perfilId } })
                      );
                      setPayload({ ...payload, perfis: perfis });
                    }
                  }}
                >
                  {lstPerfil &&
                    lstPerfil.length > 0 &&
                    lstPerfil.map(key => {
                      return (
                        <Option
                          key={'perfil-' + [key.id]}
                          value={key.id.toString()}
                        >
                          {key.descricao}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('lstBannerSetorBanner')(
                <Select
                  mode="multiple"
                  placeholder="Selecione os setores"
                  allowClear
                  onChange={e => {
                    if (e) {
                      let setores = [];
                      e.map(setorId =>
                        setores.push({ setor: { id: setorId } })
                      );
                      setPayload({ ...payload, setores: setores });
                    }
                  }}
                >
                  {lstSetor &&
                    lstSetor.length > 0 &&
                    lstSetor.map(key => {
                      return (
                        <Option
                          key={'setor-' + [key.id]}
                          value={key.id.toString()}
                        >
                          {key.descricao}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('canal')(
                <Select
                  placeholder="Selecione um Canal"
                  onChange={e => setPayload({ ...payload, canal: e })}
                  allowClear
                >
                  <Option value="T">Todas</Option>
                  <Option value="S">Próprio</Option>
                  <Option value="N">Parceiro</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('prioritario')(
                <Select
                  placeholder="Prioritário"
                  onChange={e => setPayload({ ...payload, prioritario: e })}
                  allowClear
                >
                  <Option value="S">SIM</Option>
                  <Option value="N">NÃO</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
        <Col span={4}>
            <Form.Item size="large">
              {getFieldDecorator('ativo')(
                <Select
                  placeholder="Ativo"
                  onChange={e => setPayload({ ...payload, ativo: e })}
                  allowClear
                >
                  <Option value="S">SIM</Option>
                  <Option value="N">NÃO</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={20} style={{ textAlign: 'end' }}>
            <Form.Item size="small">
              <Button.Group>
                <Tooltip placement="topLeft" title="Filtrar">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                    onClick={() => {
                      setFilter(true);
                    }}
                  >
                    <Icon type="search" />
                  </Button>
                </Tooltip>
                <Tooltip placement="topRight" title="Limpar Filtros">
                  <Button
                    type="dashed"
                    htmlType="button"
                    disabled={hasErrors(getFieldsError())}
                    onClick={() => {
                      setFilter(false);
                      form.resetFields();
                    }}
                  >
                    <Icon type="undo" />
                  </Button>
                </Tooltip>
              </Button.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        pagination={pagination}
        dataSource={lstData}
        loading={loading}
        columns={tableColumns}
        onChange={handleTableChange}
        size="small"
      />
    </div>
  );
};

export { BannersTable };
