import React, { useState, useEffect } from 'react';
import { Header, Hero, Helmet } from '../../services/Gerais';
import api from '../../services/api';
import Main from './components/Main';

const Relatorios = props => {
  const [nomeRelatorio, setNomeRelatorio] = useState('');
  const [emailResponsavel, setEmailResponsavel] = useState('');
  const [responsavelRelatorio, setResponsavelRelatorio] = useState('');

  useEffect( () => {
    async function getNomeRelatorio(){
      const usuario = JSON.parse(localStorage.getItem('userDataCRP'));
      if (usuario) {
        const res = await api.get(`relatorio/usuariopolo/${usuario.id}`);
        res.data.CategoriaRelatorio.forEach(item => {
          item.relatorios.forEach(relatorio => {
            if(relatorio.id == props.match.params.id) {
              setNomeRelatorio(relatorio.nomeRelatorio);
              setEmailResponsavel(relatorio.categoriaRelatorio.usuarioPortalLog.conta)
              setResponsavelRelatorio(relatorio.responsavel);
            }
          })
        });
      }
    }
    getNomeRelatorio();
  }, []);
  return (
    <>
      <Helmet
        title="Relatórios - Portal Prisma - Kroton"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: 'Relatório - Portal Unificado da Kroton Educacional',
          },
          { name: 'keywords', content: 'agenda, kroton, educacional, portal' },
        ]}
        key="titleAgenda"
      />
      <Header key="HeaderAgenda" />
      <Hero key="heroAgenda" pageName="Relatório" titulo={`Relatório: ${nomeRelatorio}`} email={emailResponsavel} responsavel={responsavelRelatorio}/>
      <section className="conteudoPortais" key="conteudoPortais" style={{ marginTop: '0.5rem'}}>
        <div className="container is-full-fullhd">
          <Main props={props} />
        </div>
      </section>
    </>
  );
};

export default Relatorios;
