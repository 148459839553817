import api from '../../../services/api';

export const paramLimit = async () => {
  try {
    const res = await api.get(`usuario/listUsuario/paramLimit`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const list = async (id) => {
  try {
    if (id) {
      const res = await api.get(`relatorio/${id}/filtros`);
      return res.data;
    } else {
      const res = await api.get(`relatorio/15/filtros`);
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

export const filterItem = async (idRelatorio, id) => {
  try {
    const res = await api.get(`relatorio/${idRelatorio}/filtros/filtro/${id}/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const findByFilters = async (params, id) => {
  try {
    const res = await api.get(`relatorio/${id}/filtros`, { params: params });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const registerFilter = async (body) => {
  try {
    const res = await api.post(`relatorio/filtros/`, body);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updatePortal = async (body) => {
  try {
    const res = await api.put(`relatorio/filtros/filtro/${body.id}`, body);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};
