import axios from 'axios';
import Swal from 'sweetalert2';
import { moment } from 'services/Gerais';

import { authProvider } from '../services/authProvider';
import config from '../config/config';
import jwtDecode from 'jwt-decode';

const api = axios.create({
  baseURL: config.baseUrl,
});

api.interceptors.request.use(async config => {
  if(localStorage.getItem('userDataCRP-AuthTk') != null) {
    const jwt = jwtDecode(localStorage.getItem('userDataCRP-AuthTk'))
    const timestamp = jwt.exp
    let time = new Date(0);
    const expirationTimeJwt = new Date(time.setUTCSeconds(timestamp))
    if(expirationTimeJwt.getTime() <= new Date().getTime()) {
      Swal.fire({
        position: 'top-end',
        title:  `Sua sessão foi encerrada, favor efetuar o login novamente!`,
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000,
      }).then(result => {
        localStorage.clear();
        authProvider.logout();
        window.location.replace('/login')
      })
    }
  }
  if (localStorage.getItem('userDataCRP-AuthTk')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      'userDataCRP-AuthTk'
    )}`;
  }

  return config;
}, async error => {
});

/*
api.interceptors.response.use(async config => {
  return config
}, async error => {
  let err = JSON.parse(JSON.stringify(error));
  if(err.message == "Request failed with status code 403"
    || err.message == "Request failed with status code 401") {

  }
})*/

export default api;
