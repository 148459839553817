import api from 'services/api';
import Swal from 'sweetalert2';

export const fetchFaq = async (params) => {
  return await api.get(!params ? 'faq' : `faq/${params}`);
};

export const fetchFaqCategories = async () => {
  return await api.get('faq/categoria');
};

export const getFaqSearch = async (params) => {
  return await api.get(`faq/filter?palavraChave=${params}`);
};

export const getFaqByCategory = async (id) => {
  return await api.get(`faq/categoria/${id}/faq`);
};

export const createFaq = async (params) => {
  return await api.post(`faq/`, params.body).catch(async (error) => {
      Swal.fire({
        text: error.response.data.error,
      });
    });
};

export const updateFaq = async (params) => {
  return await api.put(`faq/${params.id}`, params.body).catch(async (error) => {
    Swal.fire({
      text: error.response.data.error,
    });
  });
};
