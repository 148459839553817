import React, { Component } from 'reactn';
import ValidaLoginAzureAd from './ValidaLoginAzureAd';
import './../../styles/Styles.scss';
import { library, Helmet } from '../../services/Gerais';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import "../../styles/cookiesConsent.scss"

library.add({ faArrowLeft, faArrowRight });

class Login extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div key="CRPLogin" className="CRP-Login is-fullheight is-fullwidth">
        <Helmet
          key="TitleLogin"
          title="Portal Prisma - Kroton"
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: 'Portal Unificado da Kroton Educacional',
            },
            { name: 'keywords', content: 'kroton, educacional, portal' },
          ]}
        />
        <div className="bgLogin">
          <div className="columns is-gapless">
            <div className="column tituloPortalLogin">
              <section className="hero is-bold has-text-left">
                <div className="hero-body">
                  <h1 className="title is-3 has-text-white">
                    Bem-vindo ao Portal Prisma
                  </h1>
                  <p className="is-size-4 has-text-white">
                    Um Portal Único para facilitar seu dia a dia!
                  </p>
                </div>
              </section>
            </div>
            <div className="column has-background-white columnFormLogin has-text-centered">
              <div className="loginFormTransicao">
                <ValidaLoginAzureAd
                  baseUrl={this.global.baseUrl}
                  login={this.state}
                />
              </div>
              
              <div>
                {!getCookieConsentValue('cookieConsent') ? (
                  <CookieConsent 
                    location="bottom" 
                    buttonText="ok" 
                    cookieName="cookieConsent"
                    buttonWrapperClasses="cookieButtonWrapper"
                    buttonClasses="cookieButton"
                    disableButtonStyles='true'
                    contentStyle={{ 'flex': '1 0', 'width': '70%'}}
                  > 
                      <h3>Esse Site usa Cookies</h3>

                      <p>Esse site utiliza cookies para oferecer uma melhor experiência de navegação, analisar como você interage em nosso site, 
                      otimizar o desempenho e personalizar o conteúdo. Ao utilizar este site, você concorda com o uso de cookies.
                      Saiba mais em nosso <b style={{"color": "#3273dc"}}><a href="https://www.canaldatransparencia.com.br/cogna/" target='_blank'>Portal de Privacidade.</a></b></p>
                  </CookieConsent>
                  ) : null
                }
              </div>
            </div>
             
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
