import React, { Component } from 'reactn';
import { Header, Footer, Hero, Helmet, axios, moment } from 'services/Gerais';
import 'styles/Styles.scss';
import 'styles/Foca.scss';
import 'antd/dist/antd.css';
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  Spin,
  DatePicker,
  Icon,
  Table,
  Modal,
  Alert,
  Upload,
  Input,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  onFilter,
  onGetCheckins,
  onGetFiltersReport,
  onGetPolos,
  onPdf,
  useFOCA,
} from './FocaController';
import { useState } from 'react';
import { useEffect } from 'react';
import { exportXLSX } from './FocaController';
import TemplateFoca from './pdfFoca';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;

const locale = 'pt-BR';

const Foca = () => {
  const { uploadProps, handleImportFile } = useFOCA();
  const [ltsCheckins, setLtsCheckins] = useState([]);
  const [lstPlanosAcao, setLstPlanosAcao] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [polos, setPolos] = useState([]);
  const [allPolos, setAllPolos] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [user, setUser] = useState([]);
  const [idCheckinToSend, setIdCheckinToSend] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [expense, setExpense] = useState({});
  const [columns, setColumns] = useState([
    {
      title: 'ID Check-in',
      key: 'id',
      dataIndex: 'id',
      width: 50,
    },
    {
      title: 'Polo(s)',
      key: 'checkinPolos',
      dataIndex: 'checkinPolos',
      width: 150,
      render: (polos) => {
        let renderPolos = [];
        let polosOrder = polos.sort(orderByPolo);
        polosOrder.map((polo) => [
          renderPolos.push(
            <small key={'polo-' + polo.id}>{polo.polo.nome}</small>,
            <br key={'quebra-' + polo.id} />
          ),
        ]);
        return renderPolos;
      },
    },
    {
      title: 'Data visita',
      key: 'dataVisita',
      dataIndex: 'dataVisita',
      width: 150,
      sorter: (a, b) => orderByVisitDate(a, b),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (status) => {
        if (status !== null) {
          switch (status) {
            case 'A':
              return <span>Agendado</span>;
              break;
            case 'C':
              return <span>Concluído</span>;
              break;
            case 'E':
              return <span>Em andamento</span>;
              break;
          }
        }
      },
    },
    {
      title: 'Ação',
      key: 'id',
      dataIndex: 'id',
      width: 150,
      render: (id, record) =>
        perfilId == 376 || perfilId == 370 ? (
          record.liberarPdf == 'S' ? (
            <>
              <Button
                title="Editar "
                className="button is-small is-warning"
                href={`/cadastro/FOCA/${id}`}
              >
                <Icon type="save" />
                Editar
              </Button>
              <Button
                title="PDF "
                className="button is-small is-warning margin-1-left"
                target="_blank"
                href={`/FocaPdf/${id}`}
              >
                <Icon type="file-pdf" />
                PDF
              </Button>
            </>
          ) : (
            <Button
              title="Editar "
              className="button is-small is-warning"
              href={`/cadastro/FOCA/${id}`}
            >
              <Icon type="save" />
              Editar
            </Button>
          )
        ) : (
          <>
            <Button
              title="Editar "
              className="button is-small is-warning"
              href={`/cadastro/FOCA/${id}`}
            >
              <Icon type="save" />
              Editar
            </Button>
            <Button
              title="PDF "
              className="button is-small is-warning margin-1-left"
              target="_blank"
              href={`/FocaPdf/${id}`}
            >
              <Icon type="file-pdf" />
              PDF
            </Button>
            {record.dataEnvioEmail != null &&
            moment(record.dataEnvioEmail, 'DD/MM/YYYY HH:mm:ss')
              .startOf('minutes')
              .fromNow()
              .split(' ')[1] >= 15 &&
            (moment(record.dataEnvioEmail, 'DD/MM/YYYY HH:mm:ss')
              .startOf('minutes')
              .fromNow()
              .split(' ')[2] == 'minutos' ||
            moment(record.dataEnvioEmail, 'DD/MM/YYYY HH:mm:ss')
              .startOf('minutes')
              .fromNow()
              .split(' ')[2] == 'horas'
              ? true
              : false) ? (
              <Button
                title="Envio de Plano de Ação"
                className="button is-small is-warning margin-1-left"
                target="_blank"
                onClick={() => handleModal(record.id)}
              >
                <Icon type="file-pdf" />
                Envio de Plano de Ação
              </Button>
            ) : record.dataEnvioEmail == null ? (
              <Button
                title="Envio de Plano de Ação"
                className="button is-small is-warning margin-1-left"
                target="_blank"
                onClick={() => handleModal(record.id)}
              >
                <Icon type="file-pdf" />
                Envio de Plano de Ação
              </Button>
            ) : (
              ''
            )}

          </>
        ),
    },
  ]);
  const [pagination, setPagination] = useState({ position: 'bottom' });
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [lstParametros, setLstParametros] = useState([]);
  const [lstParametrosPA, setLstParametrosPA] = useState([]);
  const [lstPolosIds, setLstPolosIds] = useState([]);
  const [filtrosEscolhidos, setFiltrosEscolhidos] = useState([]);
  const [relatorioConfig, setRelatorioConfig] = useState(null);
  const [relatorioId, setRelatorioId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({
    poloId: null,
    prazoInicial: null,
    prazoFinal: null,
    dataVisita: null,
    status: null,
    acao: null,
  });
  const listFiltrosEscolhidos = [];

  const titlePage = 'FOCA';
  const perfilId = localStorage.getItem('userDataCRP-Perfil');
  const url = JSON.parse(localStorage.getItem('cnfgDataCRP'));
  const userLogged = JSON.parse(localStorage.getItem('userDataCRP-Login'));
  const urlDemo = '/public/demo-foca-plano-acao.csv';
  const props = {
    defaultFileList: [
      {
        uid: '1',
        name: 'arquivoDemostracao.csv',
        status: 'done',
        url: urlDemo,
      },
    ],
  };

  function handleModal(id) {
    if (!isOpen) {
      setIsOpen(true);
      setIdCheckinToSend(id);
    } else {
      setIsOpen(false);
      setIdCheckinToSend(0);
    }
  }

  function orderByVisitInclusing(a, b) {
    const replaceA = a.dataInclusao.substring(0, 10);
    const replaceB = b.dataInclusao.substring(0, 10);
    const dateA = replaceA.split('-');
    const dateB = replaceB.split('-');
    if (dateA[2] > dateB[2]) {
      return 1;
    } else if (dateA[2] == dateB[2] && dateA[1] > dateB[1]) {
      return 1;
    } else if (
      dateA[2] == dateB[2] &&
      dateA[1] == dateB[1] &&
      dateA[0] > dateB[0]
    ) {
      return 1;
    } else {
      return -1;
    }
  }

  useEffect(() => {
    getFiltrosRelatorio();
    getFiltrosRelatorioPA();
    getCheckins();
  }, []);

  useEffect(() => {
    if (lstParametros.length > 0) {
      getReports();
      getPolo();
    }
  }, [lstParametrosPA]);

  async function getPolo() {
    const data = await onGetPolos(userLogged[0].usuarioPortal.id);
    setPolos(data.data.polos.sort(sorter));
    let listPolo = [];
    polos.forEach((polo) => {
      listPolo.push(polo.id);
    });
    await getReport(
      lstParametros[4].id,
      lstParametros[4].nome,
      JSON.stringify(listPolo).replace('[', '').replace(']', '')
    );
    await getReport(
      lstParametrosPA[1].id,
      lstParametrosPA[1].nome,
      JSON.stringify(listPolo).replace('[', '').replace(']', '')
    );
    await getReport(
      lstParametrosPA[0].id,
      lstParametrosPA[0].nome,
      userLogged[0].usuarioPortal.id
    );
  }

  async function getCheckins() {
    const resp = await onGetCheckins(userLogged[0].usuarioPortal.id, perfilId);
    let listPlans = [];
    setLtsCheckins(resp.data.checkins);
    setLstPolosIds(resp.data.polosIds);
    setAllPolos(resp.data.polosIds);
    resp.data.checkins.forEach((item) => {
      item.planosDeAcao.forEach((param) => {
        listPlans.push(param);
      });
    });
    setLstPlanosAcao(listPlans);
    search.poloId = resp.data.polosIds;
    setLoading(false);
  }

  async function onImportFile() {
    setLoadingModal(true);
    await handleImportFile();
    setLoadingModal(false);
    handleModalUpdate();
  }

  async function getReports() {
    getReport(
      lstParametros[0].id,
      lstParametros[0].nome,
      userLogged[0].usuarioPortal.id
    );
    getReport(lstParametros[1].id, lstParametros[1].nome, null);
    getReport(lstParametros[2].id, lstParametros[2].nome, null);
    getReport(lstParametros[3].id, lstParametros[3].nome, null);
    getReport(lstParametros[4].id, lstParametros[4].nome, null);
    getReport(lstParametros[5].id, lstParametros[5].nome, null);
    getReport(lstParametros[6].id, lstParametros[6].nome, null);
    getReport(lstParametros[7].id, lstParametros[7].nome, 'S');
  }

  function sorter(a, b) {
    if (a.nome > b.nome) return 1;
    if (a.nome < b.nome) return -1;
    return 0;
  }

  function orderByVisitDate(a, b) {
    const dateA = a.dataVisita.split('-');
    const dateB = b.dataVisita.split('-');
    if (dateA[2] > dateB[2]) {
      return 1;
    } else if (dateA[2] == dateB[2] && dateA[1] > dateB[1]) {
      return 1;
    } else if (
      dateA[2] == dateB[2] &&
      dateA[1] == dateB[1] &&
      dateA[0] > dateB[0]
    ) {
      return 1;
    } else {
      return -1;
    }
  }

  function getReport(id, nome, valor) {
    if (valor === null) {
      valor = '';
    }
    const filtroEscolhido = {
      id: id,
      nome: nome,
      valor: id == 20 ? `%${valor}%` : valor,
    };
    const filtrosEscolhidos = listFiltrosEscolhidos;
    if (filtrosEscolhidos) {
      const filtrosIdsAdicionados = filtrosEscolhidos.filter((objeto) => {
        return objeto.id === id;
      });
      const filtroIndex = filtrosEscolhidos.findIndex(
        (objeto) => objeto.id === id
      );
      if (filtrosIdsAdicionados.length) {
        listFiltrosEscolhidos[filtroIndex] = filtroEscolhido;
      } else {
        listFiltrosEscolhidos.push(filtroEscolhido);
      }
      const relatorioConfigAtualizada = {
        relatorioId: relatorioId,
        lstParametros: filtrosEscolhidos,
      };
      setRelatorioConfig(relatorioConfigAtualizada);
    }
  }

  function orderByPolo(a, b) {
    if (a.polo.nome > b.polo.nome) return 1;
    if (a.polo.nome < b.polo.nome) return -1;
    return 0;
  }

  async function getFilter() {
    let filters = [
      search.poloId != null
        ? `polosIds=${search.poloId}`
        : `polosIds=${allPolos}`,
      `&isFocaScreen=true`,
      search.prazoInicial != null ? `&prazoInicial=${search.prazoInicial}` : '',
      search.prazoFinal != null ? `&prazoFinal=${search.prazoFinal}` : '',
      search.dataVisita != null ? `&dataVisita=${search.dataVisita}` : '',
      search.status != null ? `&status=${search.status}` : '',
      search.acao != null ? `&acao=${search.acao}` : '',
    ].join('');
    const resp = await onFilter(filters);
    setLtsCheckins(resp.data);
  }

  function handleModalUpdate() {
    if (visible) {
      setVisible(false);
      return;
    }
    setVisible(true);
  }

  const userManualClick = () => {
    window.open(
      'https://kroton.sharepoint.com/:f:/s/KrotonDigital2/Et5tU3HvxwpFmRVrWGtTIgQBe3G2NCp0WqjkKcmKkjCZ6g?e=KC6J6y',
      '_blank'
    );
  };

  function renderOption(item) {
    return (
      <Option key={item.id} text={item.nome}>
        {item['nome'] ? item.nome : item.nome}
      </Option>
    );
  }

  /*async function pdf(id) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', `${url.baseUrl}foca/checkin/pdf/${id}`, true);
    xhr.setRequestHeader(
      'Authorization',
      'Bearer ' + localStorage.getItem('userDataCRP-AuthTk')
    );
    xhr.responseType = 'arraybuffer';
    xhr.onload = function (e) {
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    };
    xhr.send();
  }*/

  async function exportPDF(idRelatorio) {
    setRelatorioId(idRelatorio);
    relatorioConfig.relatorioId = idRelatorio;
    if (relatorioConfig == undefined && idRelatorio == 18) {
      await getReport(
        lstParametros[0].id,
        lstParametros[0].nome,
        lstParametros[0].valor ? lstParametros[0].valor : null
      );
      await getReport(
        lstParametros[1].id,
        lstParametros[1].nome,
        lstParametros[1].valor ? lstParametros[1].valor : null
      );
      await getReport(
        lstParametros[2].id,
        lstParametros[2].nome,
        lstParametros[2].valor ? lstParametros[2].valor : null
      );
      await getReport(
        lstParametros[3].id,
        lstParametros[3].nome,
        lstParametros[3].valor ? lstParametros[3].valor : null
      );
      await getReport(
        lstParametros[4].id,
        lstParametros[4].nome,
        lstParametros[4].valor ? lstParametros[4].valor : search.poloId
      );
      await getReport(
        lstParametros[5].id,
        lstParametros[5].nome,
        lstParametros[5].valor ? lstParametros[5].valor : null
      );
      await getReport(
        lstParametros[6].id,
        lstParametros[6].nome,
        lstParametros[6].valor ? lstParametros[6].valor : null
      );
      await getReport(
        lstParametros[7].id,
        lstParametros[7].nome,
        lstParametros[7].valor ? lstParametros[7].valor : 'S'
      );
      await getReport(
        lstParametros[8].id,
        lstParametros[8].nome,
        lstParametros[8].valor ? lstParametros[8].valor : null
      );
    }
    relatorioConfig.relatorioId = idRelatorio;
    await onPdf(relatorioConfig);
  }

  function changePolo(id) {
    search.poloId = parseInt(id);
    getReport(lstParametros[0].id, lstParametros[0].nome, parseInt(id));
    getReport(lstParametrosPA[0].id, lstParametrosPA[0].nome, parseInt(id));
  }

  function changeDate(date) {
    if (date[0] != undefined) {
      search.prazoInicial = date[0].format('DD-MM-YYYY');
      search.prazoFinal = date[1].format('DD-MM-YYYY');
    } else {
      search.prazoInicial = '';
      search.prazoFinal = '';
    }

    getReport(lstParametros[5].id, lstParametros[5].nome, search.prazoInicial);
    getReport(lstParametros[6].id, lstParametros[6].nome, search.prazoFinal);
  }

  function changeDateVisita(date) {
    search.dataVisita = date.format('DD-MM-YYYY');
    getReport(lstParametros[3].id, lstParametros[3].nome, search.dataVisita);
  }

  function changeStatus(e) {
    search.status = e;
    getReport(lstParametros[2].id, lstParametros[2].nome, search.status);
  }

  function changeAcao(e) {
    search.acao = e.target.value;
    getReport(lstParametros[1].id, lstParametros[1].nome, search.acao);
  }

  async function getFiltrosRelatorio() {
    const resp = await onGetFiltersReport(18);
    const lstParametros = iniciaParametros(
      resp.data['relatorioAdministrativoFiltros']
    );
    setLstParametros(lstParametros);
  }

  async function getFiltrosRelatorioPA() {
    const resp = await onGetFiltersReport(36);
    const lstParametrosPA = iniciaParametros(
      resp.data['relatorioAdministrativoFiltros']
    );
    setLstParametrosPA(lstParametrosPA);
  }

  function iniciaParametros(parametrosIniciais) {
    const lstParametros = [];
    parametrosIniciais.map((item, index) => {
      const filtroIiniciado = {
        id: item.id,
        nome: item.nomeExibicao,
        valor: '',
      };
      lstParametros.push(filtroIiniciado);
    });
    return lstParametros;
  }

  return (
    <>
      <Helmet
        title={titlePage}
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: titlePage + '- Portal Prisma',
          },
          { name: 'keywords', content: 'kroton, educacional, portal, teste' },
        ]}
        key="titleEstruturaUsuario"
      />
      <Header key="headerEstruturaUsuario" />
      <Hero
        key="heroEstruturaUsuario"
        pageName={titlePage}
        titulo={titlePage}
      />
      <Spin spinning={loading}>
        <section className="section conteudoPortais" key="conteudoFoca">
          <div>
            <Row gutter={24}>
              <span className="title-foca-info">Listar Check-ins</span>
              {perfilId == 501 && (
                <>
                  <Button
                    type="primary"
                    className="pull-right"
                    size="large"
                    href={`/cadastro/FOCA/`}
                    style={{
                      border: 'none',
                    }}
                    onClick={() => getFilter()}
                  >
                    Novo Checkin
                  </Button>
                  <Button
                    type="primary"
                    className="pull-right margin-05-right"
                    size="large"
                    style={{
                      border: 'none',
                    }}
                    onClick={() => handleModalUpdate()}
                  >
                    Importar Plano de ação
                  </Button>
                  <Button
                    type="primary"
                    className="pull-right margin-05-right"
                    size="large"
                    onClick={() =>
                      exportXLSX(lstPlanosAcao, 'TEMPLATE_FOCA_PLANODEACAO')
                    }
                  >
                    <Icon type="download" />
                    Exportar Planos de ações
                  </Button>
                  <Button
                    type="primary"
                    className="pull-right margin-05-right"
                    size="large"
                    onClick={userManualClick}
                  >
                    Manual de Utilização
                  </Button>
                </>
              )}
            </Row>
          </div>
          <div className="width-100">
            <Row xl={24}>
              <Col xl={24}>
                <Row>
                  <Col xl={11} md={11}>
                    <Form.Item label="Polo" size="large">
                      <Select
                        size="large"
                        placeholder="Pesquise o Polo"
                        onChange={(e) => changePolo(e)}
                        disabled={isEdit}
                        style={{ width: '100%' }}
                      >
                        {polos?.map((e) => renderOption(e))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={11} md={11} className="margin-1-left">
                    <Form.Item label="Prazo (SLA)" size="large">
                      <RangePicker
                        size="large"
                        format="DD-MM-YYYY"
                        style={{ width: '100%' }}
                        placeholder={['Data início (data)', 'Data fim (data)']}
                        onChange={(e) => changeDate(e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row xl={24}>
                  <Col xl={8} md={11}>
                    <Form.Item label="Ação">
                      <Input
                        type="text"
                        className="ant-input height-40"
                        placeholder="Ação"
                        onChange={(e) => changeAcao(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={6} md={11} className="gutter-row margin-1-left">
                    <Form.Item
                      label="Data visita"
                      size="large"
                      className="width-100"
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        className="width-100"
                        placeholder="DD-MM-YYYY"
                        locale={locale}
                        size="large"
                        onChange={(e) => changeDateVisita(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={6} md={11} className="gutter-row margin-1-left">
                    <Form.Item label="Status">
                      <Select
                        placeholder="Selecione..."
                        size="large"
                        onChange={(e) => changeStatus(e)}
                      >
                        <Option value="A">Agendado</Option>
                        <Option value="E">Em andamento</Option>
                        <Option value="C">Concluido</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={3}>
                    <Button
                      type="primary"
                      className="pull-right margin-1-left"
                      size="large"
                      style={{
                        'background-color': '#52c41a',
                        border: 'none',
                        'margin-top': '2.5rem',
                      }}
                      onClick={() => getFilter()}
                    >
                      <Icon type="search" />
                      Pesquisar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={30} className="gutter-row">
                <Form.Item label="" size="large">
                  {
                    <Table
                      pagination={pagination}
                      loading={loading}
                      columns={columns}
                      dataSource={ltsCheckins.sort((a, b) =>
                        a.id < b.id ? 1 : a.id > b.id ? -1 : 0
                      )}
                      size="small"
                      className="margin-1rem-top"
                    />
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} className="gutter-row">
                <Form.Item label="" size="large">
                  {perfilId == 501 && (
                    <Button
                      type="primary"
                      className="pull-right margin-05-right is-warning"
                      size="large"
                      style={{
                        border: 'none',
                      }}
                      onClick={() => exportPDF(18)}
                    >
                      <Icon type="download" />
                      Exportar Pesquisa
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </section>
      </Spin>
      <Modal
        title="Importar Checkin"
        visible={visible}
        onCancel={handleModalUpdate}
        onOk={onImportFile}
        okText="Importar Plano de Ações"
        destroyOnClose={true}
        centered
      >
        <Spin spinning={loadingModal}>
          <Row>
            <Alert
              message="Arquivo de demonstração"
              description="Para fazer o upload dos planos de ação, favor baixe o arquivo de demonstração no link a baixo"
              type="warning"
            />
          </Row>
          <Row>
            <strong>Arquivo de demonstração</strong>
            <a onClick={() => exportXLSX([], 'TEMPLATE_FOCA_PLANODEACAO')}>
              {' '}
              Clique aqui para baixar
            </a>
          </Row>
          <Row className="margin-1-top">
            <Upload {...uploadProps}>
              <Button>
                <UploadOutlined />
                Selecionar arquivo .xlsx
              </Button>
            </Upload>
          </Row>
        </Spin>
      </Modal>
      <Footer key="FooterEstruturaUsuario" />
      <Modal
        title="Enviar Relatório para o email do Responsável"
        visible={isOpen}
        destroyOnClose={true}
        width={900}
        centered
        footer={null}
        onCancel={handleModal}
      >
        <TemplateFoca isSendEmail={true} idCheckin={idCheckinToSend} />
      </Modal>
    </>
  );
};

export default Foca;
