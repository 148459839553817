import api from '../../../services/api';

export const paramLimit = async () => {
  try {
    const res = await api.get(`usuario/listUsuario/paramLimit`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const listUsuarioPortal = async (params = {}) => {
  try {
    const res = await api.get(`usuario/listUsuarioPortal`, {
      params: {
        ...params,
      },
    });
    return {
      data: res.data.UsuariosPortal.content.filter((item) => {
        if (!params.keyWord) {
          return true;
        }
        if (item.titulo.includes(params.keyWord)) {
          return true;
        }
        return false;
      }),
      page: res.data.UsuariosPortal.number,
      success: true,
      total: res.data.UsuariosPortal.totalElements,
    };
  } catch (error) {
    return error;
  }
};

export const findByFilters = async (params = {}) => {
  try {
    const res = await api.get(`usuario/findByFilters/`, { params: params });
    return {
      data: res.data.content.filter((item) => {
        if (!params.keyWord) {
          return true;
        }
        if (item.titulo.includes(params.keyWord)) {
          return true;
        }
        return false;
      }),
      page: res.data.number,
      success: true,
      total: res.data.totalElements,
    };
  } catch (error) {
    return error;
  }
};
