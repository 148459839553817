import React from 'reactn';
// import {FontAwesomeIcon, Scrollbar, moment, $, axios} from "../../services/Gerais";
import {FontAwesomeIcon, Scrollbar, moment } from "../../services/Gerais";

class MapaDisplay extends React.Component {
    constructor(props) {
        super(props)
        this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
        this.toggleMapaDisplay = this.toggleMapaDisplay.bind(this)
    }

    toggleMapaDisplay = () => {
        // column is-transparent
        var link = document.getElementById('columnClose');
        link.click()
    }

    geraMapaDisplay = (e,cDisplay,btn) => {
        e.preventDefault();

        // let lido = cDisplay.lido
        // let favorito = cDisplay.favorito
        //
        // if(btn === 'like'){
        //     if(favorito !== 'S'){
        //         favorito = 'S'; lido = 'S';
        //         $(document).find('[data-favorito="'+cDisplay.id+'"] svg').data('prefix','fas');
        //         $(document).find('[data-favorito="'+cDisplay.id+'"] svg path').attr('d','M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z');
        //     } else {
        //         favorito = 'N';
        //         $(document).find('[data-favorito="'+cDisplay.id+'"] svg').data('prefix','far');
        //         $(document).find('[data-favorito="'+cDisplay.id+'"] svg path').attr('d','M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z');
        //     }
        // }
        //
        // // marcando como lido
        // const dataComunicadoUsuario = [{
        //     "usuarioPortal": { "id": this.logInfo['usuarioPortal']['id'] },
        //     "comunicado": { "id": (cDisplay.id) },
        //     "favorito": favorito,
        //     "comunicadoLido": lido,
        //     "usuarioPortalLog": { "id": this.logInfo['usuarioPortal']['id'] }
        // }]
        //
        // axios.post(this.global.baseUrl+'comunicadoUsuario/saveComunicadoUsuario', dataComunicadoUsuario[0], {
        //     headers: { 'Authorization': 'Bearer '+ localStorage.getItem('userDataCRP-AuthTk') }
        // }).then(resultDisplay => {
        //     axios.get(this.global.baseUrl+'comunicadoUsuario/getComunicadoByComunicadoAndUsuario/'+this.logInfo['usuarioPortal']['id']+'/'+cDisplay.id, {
        //         headers: { 'Authorization': 'Bearer '+ localStorage.getItem('userDataCRP-AuthTk') }
        //     }).then(infosResDisplay => this.setState({
        //         showComunicado: infosResDisplay.data, isLoading: false
        //     })).catch(errorDisplay => this.setState({
        //         errorDisplay, isLoading: false
        //     }));
        // }).catch(errorDisplay => {
        //     // console.error(error)
        // });
    }

    render() {
        // let showComunicado = this.props['comunicado']
        // let comunDisplay = showComunicado['area']
        // let tamanhoTags = (showComunicado['lstTag'] ? showComunicado['lstTag'].length : 0)
        let tamanhoTags = 0
        const datetime = moment().format('DD-MM-YYYY HH:mm:ss');

        // return [
        //     <div key={'displayComunicado'} className={showComunicado['prioridade'] === "S" ? 'column informacoesPortal informacoesComunicados boxAlerta' : 'column informacoesPortal informacoesComunicados'}>
        //         <span className={showComunicado['prioridade'] === "S" ? 'alerta' : ''}></span>
        //         <span className="fechar" title="Fechar" onClick={this.toggleMapaDisplay}> X </span>
        //         <Scrollbar style={ {width: '100%', height: '40vw', minHeight: 300} } >
        //             <div className="has-text-justified descricaoComunicados ScrollbarsCustom native mostraCategoria">
        //                 <div className="title is-6 is-spaced comunicadosInfos">
        //                     <label className="check"> <FontAwesomeIcon icon={["fas", "check"]}/><FontAwesomeIcon icon={["fas", "check"]}/> </label>
        //                     <small>visualizado {datetime}</small>
        //                     <span className="salvarComunicado" data-favorito={showComunicado.id} onClick={((e) => this.geraMapaDisplay(e,showComunicado,'like'))} title={(showComunicado.favorito === 'S' ? 'Desmarcar favorito' : 'Marcar como favorito')}>
        //                         <FontAwesomeIcon icon={[(showComunicado.favorito === 'S' ? 'fas' : 'far'), "bookmark"]}/>
        //                     </span>
        //                     <small className="codigoComunicado">Código do Comunicado: <strong>{showComunicado['codigo']}</strong></small>
        //                 </div>
        //                 <h5 className="title is-spaced comunicadoCat">{showComunicado['errata'] === "S" && "Atualização - "}{showComunicado['titulo']}</h5>
        //                 <small className="smallComunicadoDescricao comunicadoCat">{showComunicado['dataInicio']} {(comunDisplay ? ' | '+comunDisplay['descricao'] : "")}</small>
        //                 <div className="content comunicadoCat"><p dangerouslySetInnerHTML={{ __html: showComunicado['conteudo'] }}></p></div>
        //                 <hr/>
        //                 {/*<a className="compartilharComunicado comunicadoCat" href="./home" aria-label="like">*/}
        //                 {/*<small><FontAwesomeIcon icon={["fas", "share"]}/> Compartilhar</small>*/}
        //                 {/*</a>*/}
        //                 {tamanhoTags > 0 &&
        //                 <div className="content is-small referenciaComunicados comunicadoCat">
        //                     <strong>TAGS:</strong> &nbsp;
        //                     {showComunicado['lstTag'].map((tg) => {
        //                         return (<a href={"./comunicados-tags/"+tg['id']}
        //                                    key={"tagRel"+tg['id']}
        //                                    className="is-small is-rounded is-outlined comunicadoCat tagsComunicado">{tg['descricao']}</a>)
        //                     })}
        //                 </div>
        //                 }
        //             </div>
        //         </Scrollbar>
        //     </div>
        // ]

        return [
            <div key={'displayComunicado'} className={'column informacoesPortal informacoesComunicados'}>
                <span className="fechar" title="Fechar" onClick={this.toggleMapaDisplay}> X </span>
                <Scrollbar style={ {width: '100%', height: '40vw', minHeight: 300} } >
                    <div className="has-text-justified descricaoComunicados ScrollbarsCustom native mostraCategoria">
                        <div className="title is-6 is-spaced comunicadosInfos">
                            <label className="check">
                                <FontAwesomeIcon icon={["fas", "check"]}/><FontAwesomeIcon icon={["fas", "check"]}/>
                            </label>
                            <small>visualizado {datetime}</small>
                            <small className="codigoComunicado">
                                <div className="field" key={"areaOption"}>
                                    <input type="checkbox" name="areaInput" className={"is-checkradio is-small has-background-color "}
                                        // checked={isChecked}
                                        id={"areaInput"} />
                                    <label htmlFor={"areaInput"}>Marcar atividade como concluída</label>
                                </div>
                            </small>
                        </div>
                        <h5 className="title is-spaced comunicadoCat">Titulo</h5>
                        <small className="smallComunicadoDescricao comunicadoCat">{datetime}</small>
                        <div className="content comunicadoCat">
                            <b>Atividades:</b>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque enim itaque minima neque, nisi odit officia officiis optio pariatur perspiciatis, quasi quibusdam unde, veniam voluptas voluptate. Maiores quae recusandae reiciendis?
                            </p>
                            <b>Compartilhar Tarefa</b><br />
                            <strong>
                                <small>Você pode marcar o colaborador para atuar nessa tarefa!</small>
                            </strong>
                        </div>
                        <hr/>
                        {/*<a className="compartilharComunicado comunicadoCat" href="./home" aria-label="like">*/}
                        {/*<small><FontAwesomeIcon icon={["fas", "share"]}/> Compartilhar</small>*/}
                        {/*</a>*/}
                        {tamanhoTags >= 0 &&
                        <div className="content is-small referenciaComunicados comunicadoCat">
                            <b>TAGS:</b> &nbsp;
                        </div>
                        }
                    </div>
                </Scrollbar>
            </div>
        ]
    }
}
export default MapaDisplay
