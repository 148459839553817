import { notification } from 'antd';
import { register, item, update } from '../service';
import { getUserProfileByEmail, getUserProfileByMail } from 'services/MsGraph';
import React, { useEffect, useState } from 'react';
import { getTokenAppRegistration } from 'services/graphAppRegistration';
import Swal from 'sweetalert2';
import { listaAssinantes } from './SigneeSelection';

export function FormController(listaAssinantes) {
  const [loading, setLoading] = useState(false);
  const [subscribe, setSubscribe] = useState([]);
  const [signeesList, setSigneesList] = useState([])
  const [report, setReport] = useState({
    id: null,
    prioridade: null,
    tipoDocumento: null,
    status: null,
    gestorPrimeiroAssinador: null,
    usuarioAlteracao: null,
    observacoes: null,
    assinantes: null,
    usuarioInclusao: null
  });
  const userData = JSON.parse(localStorage.getItem('userDataCRP'))

  useEffect(() => {
    getTokenAppRegistration()
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [report]);

  useEffect(() => {
    let list = [];
    listaAssinantes.forEach(item => {
      let obj = { email: item.email, posicao: parseInt(item.posicao), id: item.id}
      list.push(obj);
    });
    setReport((state) => ({...state, assinantes:list}))
  }, [listaAssinantes]);



  async function getDocument(id) {
    setLoading(true);
    const dataItem = await item(id);
    setReport(dataItem);
    let assinantes = [];
    dataItem.assinantes.forEach(item => {
      item.key = item.posicao;
      assinantes.push(item);
    })
    setSubscribe(assinantes)
  }

  async function handleSubmit(edit) {
    let user = report.assinantes.find((item) => item.email === '')
    if(user){
       return 
    }
    try {
      setLoading(true);
      if (edit) {
        await update(report, userData.usuarioPortal.id);
      } else {
        await register(report, userData.usuarioPortal.id);
      }

      Swal.fire({
        title: edit ? 'Atualização' : 'Cadastro',
        text: "Foi realizado com sucesso!",
        icon: "success",
        showConfirmButton: false,
        timer: 5000
      })

      setLoading(false);

      window.location.href = '/geradoc/tipoDocumento';
    } catch (error) {
      Swal.fire({
        title: `Erro ao ${edit ? 'Atualizar Tipo de Documento' : 'Cadastrar Tipo de documento'}`,
        text: error?.error,
        icon: "error",
        showConfirmButton: false,
        timer: 5000
      })

      setLoading(false);
    }
  }

  return {handleSubmit, getDocument, setReport, report, loading, subscribe};
}
