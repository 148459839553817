import React, { Component } from 'react';
import { Row, Col, Input, Form, DatePicker, Button } from 'antd';
import './styles.scss';
import { getUserProfileByEmail, getUserProfileByMail } from 'services/MsGraph';
import { getTokenAppRegistration } from 'services/graphAppRegistration';
import api from 'services/api';
import { cpfMask, telefoneMask } from '../../utils/masks';
import { sendPreRegistration } from 'services/preRegistration';
import Swal from 'sweetalert2';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';


const { Item }  = Form;
let cryptoUsrCad = require('crypto');
class UsuarioPreCadastroForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoa: {
        nome: '',
        emailComplemento: '@aeduead.com.br',
        cpf: '',
        email: '',
        admissao: '',
        ddd: '',
        telefone: '',
      },
      user: {
        botaoNovaLinhaDisable: true,
        carregando: false,
        succes: false,
        error: null,
        botaoSalvarHabilitado: true,
        lstUsuarioPoloCommand: [
          {
            lstUsuarioPoloPerfil: [
              {
                perfil: { id: '2' },
              },
            ],
            usuarioPolo: {
              primeiroAcesso: '',
              polo: {
                id: '',
              },
              funcsituacao: 'N',
            },
            setor: { id: 10, descricao: 'CORPORATIVO' },
            funcao: { id: 6, descricao: 'CORPORATIVO' },
            setores: [
              {
                id: 10,
                descricao: 'CORPORATIVO',
                funcoes: [
                  {
                    id: 6,
                    descricao: 'CORPORATIVO',
                    sistemas: [
                      {
                        id: 31,
                        descricao: 'Microsoft Teamss',
                        lastUpdated: '19-01-2023 09:44:56',
                        usuarioPortalLog: {
                          id: 29971,
                          conta: 'ines.belleze@kroton.com.br',
                          integradoK360: 'N',
                          ultimoAcesso: '02/09/2023 07:52:48',
                        },
                        chave: 'TEAMS',
                        imagem:
                          'https://cdnprisma.kroton.com.br/2020/05/26/20200526100823122.jpg',
                        link: 'https://teams.microsoft.com',
                        nome: 'Teams',
                        integracaoPrisma: 'S',
                        sso: 'S',
                        permissao: 'TODOS',
                        ativo: 'S',
                      },
                      {
                        id: 5,
                        descricao: 'Colaborar Extranet',
                        lastUpdated: '22-03-2023 20:46:05',
                        usuarioPortalLog: {
                          id: 26177,
                          conta: 'valdenir.rocha@kroton.com.br',
                          integradoK360: 'N',
                          ultimoAcesso: '05/09/2023 23:09:28',
                        },
                        chave: 'COLABORAR',
                        imagem:
                          'https://cdnprisma.kroton.com.br/2020/05/26/20200526100805247.jpg',
                        link: 'https://extranet.colaboraread.com.br/',
                        nome: 'Colaborar Extranet',
                        integracaoPrisma: 'S',
                        sso: 'S',
                        permissao: 'TODOS',
                        ativo: 'S',
                      },
                      {
                        id: 14,
                        descricao: 'PORTAL PAD - Perfil administrativo',
                        lastUpdated: '22-03-2023 20:48:40',
                        usuarioPortalLog: {
                          id: 26177,
                          conta: 'valdenir.rocha@kroton.com.br',
                          integradoK360: 'N',
                          ultimoAcesso: '05/09/2023 23:09:28',
                        },
                        chave: 'PAD',
                        imagem:
                          'https://cdnprisma.kroton.com.br/2020/05/26/20200526100731816.jpg',
                        link: 'https://kroton.service-now.com/pad',
                        nome: 'Portal de Atendimento(PAD)',
                        integracaoPrisma: 'S',
                        sso: 'S',
                        permissao: 'POLOSPROPRIOS',
                        ativo: 'S',
                      },
                      {
                        id: 58,
                        descricao: 'Microsoft Office 365',
                        lastUpdated: '06-01-2021 16:00:00',
                        usuarioPortalLog: {
                          id: 2,
                          conta: 'thiago.hluz@kroton.com.br',
                          integradoK360: 'N',
                          ultimoAcesso: '08/01/2019',
                        },
                        chave: 'OFFICE',
                        imagem:
                          'https://cdnprisma.kroton.com.br/2020/05/26/20200526100823122.jpg',
                        link: 'https://outlook.office.com/',
                        nome: 'Office 365',
                        integracaoPrisma: 'S',
                        sso: 'S',
                        permissao: 'TODOS',
                        ativo: 'S',
                      },
                      {
                        id: 59,
                        descricao: 'Portal Pós 360º',
                        lastUpdated: '05-07-2023 20:49:53',
                        usuarioPortalLog: {
                          id: 28476,
                          conta: 'artur.s.ribeiro@kroton.com.br',
                          integradoK360: 'N',
                          ultimoAcesso: '25/08/2023 09:37:51',
                        },
                        chave: 'Portal Pós',
                        imagem:
                          'https://s3.amazonaws.com/kroton-s3-production-files-prisma/file/1688590149230/logo-360-original.png',
                        link:
                          'https://kroton.sharepoint.com/sites/portaleducacaocontinuada/SitePages/home.aspx?_drc=1&_drc=1',
                        nome: 'Portal Pós 360º',
                        integracaoPrisma: 'S',
                        sso: 'S',
                        permissao: 'TODOS',
                        ativo: 'S',
                      },
                    ],
                    portalCaptar: 'N',
                  },
                ],
              },
            ],
            pontoFocal: {
              "id": 7,
              "descricao": "Não",
              "status": "A",
              "unico": "N"
          },
          },

        ],
        usuarioPortal: {
          funcionario: 'S',
          chave: '',
          usuarioPortalLog: {
            id: 1,
          },
          conta: '',
          pessoa: {},
          situacaoUsuario: {
            id: '1',
          },
        },
      },
    };
    this.disabledButtonSave = true;
  }

  componentDidMount() {
    getTokenAppRegistration();
  }

  saveUser = async () => {
    this.state.user.usuarioPortal.pessoa = this.state.pessoa;
    this.state.user.usuarioPortal.conta = this.state.pessoa.email;
    this.state.user.usuarioPortal.chave = cryptoUsrCad
      .createHash('md5')
      .update(this.state.pessoa.cpf)
      .digest('hex');

    try {
      await sendPreRegistration(this.state.user);
    } catch (error) {
      Swal.fire({
        title: `Ops!`,
        text: `Algo errado aconteceu!`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  changeName(name) {
    this.state.pessoa.nome = name;
  }

  changeCpf = (e) => {
    var cpf = e.replace(/\D/g, '');
    this.state.pessoa.cpf = cpf;
    return e;
  };

  changePhone(phone) {
    const [ddd, telefone] = phone.split(' ');
    const cleanDdd = ddd.replace(/\(|\)/g, '');
    const cleanTelefone =
      telefone !== null && typeof telefone === 'string'
        ? telefone.replace(/-/g, '')
        : telefone;

    this.setState({
      pessoa: {
        ...this.state.pessoa,
        ddd: cleanDdd,
        telefone: cleanTelefone,
      },
    });
  }

  validaNomeSobrenome = (value, callback) => {
    try {
      const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇ ']+$/;
      if (!value.match(regex)) {
        throw new Error('remova caracteres especiais e numeros');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  validaFuncionarioAzureAD = async (value) => {
    if (
      value === undefined ||
      value === null ||
      value.target === null ||
      value.target.value === null
    ) {
      return;
    }

    const searchEmail = value.target.value;

    if (searchEmail.includes('@parceiro-kroton.com.br')) {
      this.disabledButtonSave = true;
      Swal.fire({
        title: 'Email não localizado',
        text: 'revise o e-mail informado e tente novamente',
        confirmButtonText: 'Ok',
      });
      this.render();
      return;
    }

    const response = await getUserProfileByEmail(searchEmail);

  if (response.data.value.length === 1) {
    this.state.pessoa.email = response.data.value[0].userPrincipalName;
    this.disabledButtonSave = false;
    this.render();
  } else if (response.data.value.length === 0) {
    const resp = await getUserProfileByMail(searchEmail);

    if (resp.data.value.length === 1) {
      this.state.pessoa.email = resp.data.value[0].userPrincipalName.replace(
        '#EXT#@kroton.onmicrosoft.com',
        ''
      );
      this.disabledButtonSave = false;
      this.render();
    } else if (response.data.value.length === 0 && resp.data.value.length === 0) {
      this.disabledButtonSave = true;
      this.render();
    }
  };
};

  cancelButton = () => {
    window.location.href = '/';
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const cpfFormatter = (e) => cpfMask(e.target.value);
    const telefoneFormatter = (e) => telefoneMask(e.target.value);
    return (
      <Form>
        <Row md={24} sm={24}>
          <Col md={15} sm={24}>
            <Form.Item label="Nome usuario" name="nome">
              {getFieldDecorator('nome', {
                rules: [
                  {
                    required: true,
                    message: ' ',
                  },
                  {
                    validator: this.validaNomeSobrenome,
                    message: 'Por favor insira o nome completo',
                  },
                ],
              })(
                <Input
                  placeholder="Nome"
                  size="default"
                  onChange={(e) => this.changeName(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={8} sm={24} className="margin-1rem-left">
            <Form.Item label="CPF" name="cpf">
              {getFieldDecorator('cpf', {
                getValueFromEvent: cpfFormatter,
                rules: [
                  {
                    required: true,
                    message: 'Por favor insira um cpf válido',
                  },
                  {
                    min: 14,
                    message: 'Por favor insira um cpf válido',
                  },
                ],
              })(
                <Input
                  placeholder="000.000.000-00"
                  size="default"
                  onChange={(e) => this.changeCpf(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row md={24} sm={24}>
          <Col md={10} sm={24}>
            <Form.Item label="E-mail" name="email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: ' ',
                  },
                  {
                    validator: this.validaFuncionarioAzureAD(),
                    message: 'Por favor insira um e-mail',
                  },
                ],
              })(
                <Input
                  form={this.state.user}
                  placeholder="Favor inserir um e-mail"
                  isEdit={false}
                  onBlur={async (e) => {
                    try {
                      await this.validaFuncionarioAzureAD(e);
                    } catch (error) {
                      console.error(error);
                      Swal.fire({
                        title: 'Email não localizado',
                        text: 'revise o e-mail informado e tente novamente',
                        confirmButtonText: 'Ok',
                      });
                    }
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const atIndex = inputValue.indexOf('@');

                    if (atIndex !== -1) {
                      const email = inputValue.substring(0, atIndex);

                      this.setState({
                        pessoa: {
                          ...this.state.pessoa,
                          email: email,
                        },
                      });
                    } else {
                      this.setState({
                        pessoa: {
                          ...this.state.pessoa,
                          email: inputValue,
                        },
                      });
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={4} sm={24} className="margin-1rem-left">
            <Form.Item label="Telefone (celular)" name="telefone">
              {getFieldDecorator('telefone', {
                getValueFromEvent: telefoneFormatter,
                rules: [
                  {
                    required: true,
                    message: 'Por favor insira um telefone válido',
                  },
                  {
                    min: 15,
                    message: 'Por favor insira um telefone válido',
                  },
                ],
              })(
                <Input
                  id="telefone"
                  placeholder="(99) 9 9999-9999"
                  size="default"
                  onChange={(e) => this.changePhone(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={4} sm={24} className="margin-1rem-left">
            <Form.Item label="Data admissão" name="dataAdmissao">
              {getFieldDecorator('admissao', {
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Por favor insira uma data válida',
                  },
                ],
              })(
                <DatePicker
                  format="DD-MM-YYYY"
                  placeholder="DD-MM-YYYY"
                  size="default"
                  allowClear={false}
                  onChange={(date, dateString) => {
                    this.setState({
                      pessoa: {
                        ...this.state.pessoa,
                        admissao: dateString,
                      },
                    });
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={2} sm={24} style={{ marginTop: '40px' }} className="pull-right" onClick={this.saveUser}>
          <Button
              type="primary"
              size="default"
              disabled={this.disabledButtonSave}
            >
              Salvar
            </Button>
          </Col>
          <Col md={2} sm={24} style={{ marginTop: '40px' }} className="pull-right">
          <Button
              type="danger"
              size="default"
              onClick={this.cancelButton}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
};

const PreCadastroForm = Form.create()(UsuarioPreCadastroForm);
export default PreCadastroForm;
