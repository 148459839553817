import React from 'react';
import AgendaDia from './AgendaDia';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AgendaHome = props => [
  <div
    className="colunaAgenda"
    key="AgendaHome"
    data-tut="agenda"
  >
    <h3 className="title paddingAgendaTitle">Programe-se!</h3>
    <h5 className="subtitle paddingAgendaSubtitle">Confira a sua agenda:</h5>
    <div className="timeline">
      <AgendaDia isAgenda={props.isAgenda} />
      {props.isAgenda && (
        <div className="timeline-item linkAgenda">
          <div className="timeline-marker" />
          <div className="timeline-content">
            <p className="heading">
              <a href="./agenda">
                <small className="linkAgendaCompleta">
                  AGENDA COMPLETA{' '}
                  <FontAwesomeIcon
                    className="seta"
                    icon={['fas', 'arrow-right']}
                  />{' '}
                </small>
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  </div>,
];

export default AgendaHome;
