import React from 'react';
import { Button, Icon, Tag, Popover, Row, Col, Input } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from 'services/Gerais';

const ButtonGroup = Button.Group;

let openedPopoverId = 0;

const handlePopoverOpen = id => {
  openedPopoverId = id;
};

const copyCodeToClipboard = id => {
  let textField = document.createElement('textarea');
  textField.innerText = `${window.location.origin}/comunicados/${id}`;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  Swal.fire({
    position: 'top-end',
    title:  `URL do comunicado foi copiada.`,
    icon: 'success',
    showConfirmButton: false,
    timer: 1500,
  })
};

export const ComunicadosColumns = [
  {
    title: 'Titulo',
    dataIndex: 'titulo',
    copyable: true,
    ellipsis: true,
    width: 250,
  },
  {
    title: 'Código',
    dataIndex: 'codigo',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Data Atualização',
    key: 'lastUpdated',
    dataIndex: 'lastUpdated',
    valueType: 'dateTime',
    render: lastUpdated => {
      return moment(lastUpdated, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY');
    },
  },
  {
    title: 'Destaque',
    dataIndex: 'destaque',
    width: 80,
    render: destaque => {
      if (destaque !== null) {
        const objTag =
          destaque === 'S'
            ? { tag: 'Sim', color: '#87d068' }
            : { tag: 'Não', color: 'volcano' };
        return (
          <span>
            <Tag color={objTag.color} key={objTag.tag}>
              {objTag.tag.toUpperCase()}
            </Tag>
          </span>
        );
      }
    },
  },
  {
    title: 'Classificação',
    dataIndex: 'prioridade',
    width: 100,
    render: prioridade => {
      if (prioridade !== null) {
        var objTag = {}
        switch (prioridade) {
          case 'C':
            objTag = { tag: 'Crítico', color: '#ff0000' };
            break;
          case 'A':
            objTag = { tag: 'Ação', color: '#ff4500' };
            break;
          case 'I':
            objTag = { tag: 'Informação', color: '#248eff' };
            break;
          case 'R':
            objTag = { tag: 'Reforço', color: '#00FF7F' };
            break;
          default:
            objTag = { tag: 'Informação', color: '#248eff' };
            break;
        }
        return (
          <span>
            <Tag color={objTag.color} key={objTag.tag}>
              {objTag.tag.toUpperCase()}
            </Tag>
          </span>
        );
      }
    },
  },
  {
    title: 'Importante',
    dataIndex: 'tipo',
    width: 100,
    render: tipo => {
      if (tipo !== null) {
        const objTag =
          tipo === 'M'
            ? { tag: 'Sim', color: '#87d068' }
            : { tag: 'Não', color: 'volcano' };
        return (
          <span>
            <Tag color={objTag.color} key={objTag.tag}>
              {objTag.tag.toUpperCase()}
            </Tag>
          </span>
        );
      }
    },
  },
  {
    title: 'Ativo',
    dataIndex: 'ativo',
    width: 80,
    render: prioridade => {
      if (prioridade !== null) {
        const objTag =
          prioridade === 'S'
            ? { tag: 'Ativo', color: '#87d068' }
            : { tag: 'Inativo', color: 'volcano' };
        return (
          <span>
            <Tag color={objTag.color} key={objTag.tag}>
              {objTag.tag.toUpperCase()}
            </Tag>
          </span>
        );
      }
    },
  },
  {
    title: 'Ação',
    valueType: 'action',
    dataIndex: 'id',
    key: 'edit',
    render: id => {
      return (
        <>
          <ButtonGroup>
            <Button
              key={`edit_${id}`}
              href={`/admin/comunicados/edit/${id}`}
              type="dashed"
              className="button is-small  is-warning"
              size="small"
            >
              <Icon type="file-search" />
              Editar
            </Button>
            <Popover
              content={
                <>
                  <Row>
                    <Col span={24}>
                      Links:
                      <Input
                        defaultValue={`${window.location.origin}/comunicados/${id}`}
                        block="true"
                        readOnly
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                        onClick={() => copyCodeToClipboard(id)}
                        type="primary"
                        block
                      >
                        <Icon type="copy" />
                        Copiar
                      </Button>
                    </Col>
                  </Row>
                </>
              }
              title="Copiar Link de compartilhamento"
              trigger="click"
              open={openedPopoverId === id}
            >
              <Button
                key={`edit_${id}`}
                title={'Compartilhar esse comunicado'}
                onClick={e => handlePopoverOpen(id)}
                className="button is-small  "
                style={{ backgroundColor: '#40A9FF' }}
                size="small"
                type="primary"
              >
                <i>
                  <FontAwesomeIcon color="#fff" icon={['fas', 'share-alt']} />
                </i>{' '}
              </Button>
            </Popover>
          </ButtonGroup>
        </>
      );
    },
  },
];
