import React from "react";
import { Tag }  from 'antd';

const ShowImportedFieldsOnScreen = [
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
    },
    {
      title: 'Nome Completo',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'E-mail Secundário',
      dataIndex: 'emailSecundario',
      key: 'emailSecundario',
    },
    {
      title: 'DDD',
      dataIndex: 'ddd',
      key: 'ddd',
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
    },
    {
      title: 'Vínculo',
      dataIndex: 'tipoColaborar',
      key: 'tipoColaborar',
    },
    {
      title: 'Status do Usuário',
      dataIndex: 'situacaoUsuario',
      key: 'situacaoUsuario',    
      width: 100,
      render: (_, record) => {
        const objTag =
          record.situacaoUsuario === 'ATIVO'
            ? { tag: 'Ativo', color: '#87d068' }
            : { tag: 'Inativo', color: 'volcano' };
        return (
          <span>
            <Tag color={objTag.color} key={objTag.tag}>
              {objTag.tag.toUpperCase()}
            </Tag>
          </span>
        );
      },
    },
    {
      title: 'ID do Polo',
      dataIndex: 'poloId',
      key: 'poloId',
    },
    {
      title: 'Status do Polo',
      dataIndex: 'situacaoPolo',
      key: 'situacaoPolo',
      width: 100,
      render: (_, record) => {
        const objTag =
          record.situacaoPolo === 'ATIVO'
            ? { tag: 'Ativo', color: '#87d068' }
            : { tag: 'Inativo', color: 'volcano' };
        return (
          <span>
            <Tag color={objTag.color} key={objTag.tag}>
              {objTag.tag.toUpperCase()}
            </Tag>
          </span>
        );
      },
    },
  
  
  ];

  export default ShowImportedFieldsOnScreen;