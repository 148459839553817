import React, { Component } from 'reactn';
import avatar from 'img/loadingtwo.gif';
import { Input, Select, DatePicker, Form, Row, Col, Button, Icon, Table } from 'antd';
import { Column, FieldBox, HeaderBox, IconBox } from './styles';
import {
  Error,
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
  FontAwesomeIcon,
  axios,
  moment,
  DataTablesLangBr,
} from 'services/Gerais';
import AgendaTable from '../../../../components/Administracao/Agenda/AgendaTable';
import api from 'services/api';
const { Option } = Select;
const locale = 'pt-BR';
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comunicadosAgenda: [],
      isLoadingAgenda: true,
      errorAgIdx: [],
      langAgendaIndex: DataTablesLangBr[0],
      searchText: '',
      listMarcas: [],
      listCategorias: [],
      search: {
        title: '',
        validity: '',
        status: '',
        categories: '',
        channel: '',
        brand: '',
        initDate: '',
        endDate: '',
        page: 0
      },
    };
    this.pagination = {}
    this.tableColumns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: 'Titulo',
        key: 'titulo',
        dataIndex: 'titulo',
      },
      {
        title: 'Vigência',
        dataIndex: 'dataInicio',
        key: ['dataInicio', 'dataFim'],
        render: (id, record) => (
          <>
            <small>
              {record.dataInicio} a {record.dataFim}
            </small>
          </>
        ),
      },
      {
        title: 'Categoria',
        dataIndex: 'descricao.descricao',
        key: 'agendaTipo.descricao',
        render: (id, record) => (
          <>
            <span>{record.agendaTipo.descricao}</span>
          </>
        ),
      },
      {
        title: 'Canal',
        dataIndex: 'poloProprio',
        key: 'canal',
        render: (canal) =>
          canal == 'T' ? 'Todos' : canal == 'P' ? 'Próprio' : 'Parceiro',
      },
      {
        title: 'Status',
        dataIndex: 'ativo',
        key: 'ativo',
        render: (status) => (status == 'S' ? 'Ativo' : 'Inativo'),
      },
      {
        title: 'Ações',
        dataIndex: 'id',
        key: 'edit',
        render: (id) => (
          <Button
            title="Editar "
            className="button is-small is-warning"
            href={`/admin/agendamento/edit/${id}`}
          >
            <Icon type="save" />
            Editar
          </Button>
        ),
      },
    ];
  }
  componentDidMount() {
    this.getInit();
    this.setState({ isLoadingAgenda: true });
    //this.getFilter(true);
    this.fetch(true);
  }

  getInit = async () => {
    const data = await api.get(`agenda/getDadosCadInitAgenda`);
    this.setState({
      listMarcas: data.data.Marca,
      listCategorias: data.data.Categoria,
    });
  };

  renderOption = (item) => {
    return (
      <Option key={item.id} text={item.descricao}>
        {item['descricao'] ? item.descricao : item.descricao}
      </Option>
    );
  };

  renderOptionMarca = (item) => {
    return (
      <Option key={item} text={this.state.listMarcas[item]}>
        {this.state.listMarcas[item]}
      </Option>
    );
  };

  changeDate = (listValue) => {
    if (listValue.length != 0) {
      this.state.search.initDate = moment(listValue[0]).format('L');
      this.state.search.endDate = moment(listValue[1]).format('L');
    } else {
      this.state.search.initDate = '';
      this.state.search.endDate = '';
    }
  };

  getFilter = async (isInit) => {
    let search = [
      this.state.search.page ? `page=${this.state.search.page}` : '',
      '&size=10',
      this.state.search.title ? `&titulo=${this.state.search.title}` : '',
      this.state.search.brand ? `&marca=${this.state.search.brand}` : '',
      this.state.search.categories
        ? `&categoria=${this.state.search.categories}`
        : '',
      this.state.search.channel ? `&canal=${this.state.search.channel}` : '',
      this.state.search.status ? `&ativo=${this.state.search.status}` : '',
      this.state.search.initDate
        ? `&dataInicio=${this.state.search.initDate}`
        : '',
      this.state.search.endDate
        ? `&dataFim=${this.state.search.endDate}`
        : '',
    ].join('');

    await axios
      .get(`${this.global.baseUrl}agenda/findByFilters?${search}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then((result) => {
        this.pagination = {
          total: result.data.totalElements,
          current: result.data.number + 1,
        }
        this.setState({
          comunicadosAgenda: result.data.content,
          isLoadingAgenda: false,
        });
      });
    this.render();
  };

  handleTableChange = (pagination) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    this.pagination = pager;
    this.state.search.page = pagination.current - 1
    this.fetch(false);
    this.render();
  };

  fetch = async (isInit) => {
    try {
      await this.getFilter(isInit);
      this.state.search.page = ''
      this.render();
    } catch (error) {
      console.log('Error', error);
    }
  };

  render() {
    const { isLoadingAgenda, errorAgIdx } = this.state;
    if (isLoadingAgenda) {
      return (
        <div className="CRP-loading loadingAgenda">
          <img src={avatar} width="100" alt="Carregando..." />
        </div>
      );
    }

    return [
      <Helmet
        key="TitleAdminAgendaIndex"
        title="Administração - Agendamentos | Portal Prisma - Kroton"
        meta={[
          { name: 'description', content: 'Agendamentos - Portal Prisma' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'keywords',
            content: 'agendamentos, kroton, educacional, portal',
          },
        ]}
      />,
      <HeaderAdmin key="HeaderAdminAgendaIndex" />,
      <section
        key="SectionAdminAgendaIndex"
        className="columns is-multiline conteudoAdmin conteudoAdminAgenda"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminAgendaIndex" />

        <div className="column is-10 is-fullheight conteudoAdminPagina">
          {errorAgIdx.response ? <Error {...errorAgIdx} /> : null}
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminAgenda"
                pageName="Administração - Agenda"
                titulo="Administração - Agenda"
              />
            </div>
            <div className="column is-one-quarter botoesAcaoCadastro">
              <a
                href={'./agendamento/cadastro'}
                className="button is-info"
                title="Novo cadastro de agenda"
              >
                <i>
                  <FontAwesomeIcon icon={['far', 'plus-square']} />
                </i>{' '}
                &nbsp; Nova Agenda
              </a>
            </div>
          </div>
          <Row>
            <Col xs={10}>
              <Form.Item label="Titulo">
                <Input
                  placeholder="Digite por titulo..."
                  className="action"
                  onChange={(e) => (this.state.search.title = e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={6} className="margin-1-left">
              <Form.Item label="Vigência">
                <DatePicker.RangePicker
                  allowClear={true}
                  onChange={(e) => this.changeDate(e)}
                  locale={locale}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col xs={6} className="margin-1-left">
              <Form.Item label="Status">
                <Select
                  placeholder="Status"
                  onChange={(e) => (this.state.search.status = e)}
                >
                  <Select.Option key="S">ATIVO</Select.Option>
                  <Select.Option key="N">INATIVO</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <Form.Item label="Categoria">
                <Select
                  placeholder="Categoria"
                  onChange={(e) => (this.state.search.categories = e)}
                >
                  {this.state.listCategorias.map((item) =>
                    this.renderOption(item)
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={5} className="margin-1-left">
              <Form.Item label="Canal">
                <Select
                  placeholder="Canal"
                  onChange={(e) => (this.state.search.channel = e)}
                >
                  <Select.Option key="N">Parceiro</Select.Option>
                  <Select.Option key="S">Próprio</Select.Option>
                  <Select.Option key="T">Todos</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={5} className="margin-1-left">
              <Form.Item label="Marca">
                <Select
                  placeholder="Marca"
                  onChange={(e) => (this.state.search.brand = e)}
                >
                  {Object.keys(this.state.listMarcas)
                    .sort(this.sorter)
                    .map((item) => {
                      return (
                        <Option key={item} text={this.state.listMarcas[item]}>
                          {this.state.listMarcas[item]}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={7}>
              <Button
                type="primary"
                className="pull-right margin-1-left"
                size="large"
                style={{
                  'background-color': '#52c41a',
                  border: 'none',
                  'margin-top': '2.5rem',
                }}
                onClick={() => this.getFilter(false)}
              >
                <Icon type="search" />
                Pesquisar
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.tableColumns}
            dataSource={this.state.comunicadosAgenda}
            onChange={this.handleTableChange}
            pagination={this.pagination}
          />
        </div>
      </section>,
      <FooterAdmin key="FooterAdminAgendaIndex" />,
    ];
  }
}
export default Index;
