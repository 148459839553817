import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Form, Select, Button, Col, Input } from 'antd';
import { Container } from './styles';
import { FormController } from './FormController';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import 'antd/dist/antd.css';

const ParameterForm = ({ idParameter, history2 }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    include: false,
    created: false
  });
  const { Option } = Select;
  const { handleSubmit, control, reset } = useForm();

  const props = {
    state: {
      loading: { loading, setLoading },
      dates: { dates, setDates },
    },
    history: history2,
    form: {
      handleSubmit,
      control,
      reset,
    },
    dispatch,
  };

  const filterController = new FormController(props);
  useEffect(() => {
    if (idParameter.length > 0) {
      filterController.get(idParameter);
    }
  }, [idParameter]);

  return (
    <Container>
      <Form
        onSubmit={handleSubmit((e) =>
          filterController.handleSubmit(
            e,
            idParameter.length > 0 ? true : false
          )
        )}
      >
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={24} md={(dates?.created || dates?.include) ? 8 : 12}>
            <Form.Item label="Valor do Parametro:" size="large">
              <Controller
                name="valor"
                control={control}
                rules={{ required: true, maxLength: 1000 }}
                render={({ field }) => (
                  <Input
                    {...field}
                    data-testid="teste-titulo"
                    placeholder="Valor do Parametro"
                    size="large"
                    disabled={loading}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={(dates?.created || dates?.include) ? 8 : 12}>
            <Form.Item label="Descrição do Parametro:" size="large">
              <Controller
                name="descricao"
                control={control}
                rules={{ required: true, maxLength: 400 }}
                render={({ field }) => (
                  <Input
                    {...field}
                    data-testid="teste-titulo"
                    placeholder="Descrição do Parametro"
                    size="large"
                    disabled={loading}
                  />
                )}
              />
            </Form.Item>
          </Col>
          {dates?.created && (
            <Col xs={10} sm={10} md={4}>
              <Form.Item label={`Data de Inclusão`} size="large">
                <Controller
                  name="dataInclusao"
                  control={control}
                  rules={{ required: true, maxLength: 400 }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      data-testid="teste-titulo"
                      placeholder="Data de Inclusão"
                      size="large"
                      disabled={true}
                      value={field.value ? moment(field.value).format('DD/MM/YYYY HH:mm:ss') : ''}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          )}
          {dates?.include && (
            <Col xs={10} sm={10} md={4}>
              <Form.Item label={`Data de Alteração`} size="large">
                <Controller
                  name="dataAlteracao"
                  control={control}
                  rules={{ required: true, maxLength: 400 }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      data-testid="teste-titulo"
                      placeholder="Data de Alteração"
                      size="large"
                      disabled={true}
                      value={field.value ? moment(field.value).format('DD/MM/YYYY HH:mm:ss') : ''}
                      />
                  )}
                />
              </Form.Item>
            </Col>
            )}
        </Row>
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Observação do Parametro:" size="large">
              <Controller
                name="observacao"
                control={control}
                rules={{ required: true, maxLength: 2000 }}
                render={({ field }) => (
                  <Input
                    {...field}
                    data-testid="teste-titulo"
                    placeholder="Observação do Parametro"
                    size="large"
                    disabled={loading}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={6} sm={6} md={6}>
            <Form.Item label="Status" size="large">
              <Controller
                name="ativo"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="large"
                    {...field}
                    placeholder="Status"
                    disabled={loading}
                  >
                    <Option value="S">Ativo</Option>
                    <Option value="N">Inativo</Option>
                  </Select>
                )}
             />
            </Form.Item>
          </Col>
          <Col xs={6} sm={6} md={6}>
            <Form.Item label="Job" size="large">
            <Controller
                name="executeJob"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="large"
                    {...field}
                    placeholder="Job"
                    disabled={loading}
                  >
                    <Option value="S">Sim</Option>
                    <Option value="N">Não</Option>
                  </Select>
                )}
             />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" gutter={16} style={{ textAlign: 'right' }}>
          <Col xs={24} sm={24} md={24}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ParameterForm;
