import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  Table,
  Input,
  Icon,
  Modal,
  Spin,
  Tag,
} from 'antd';
import {
  Helmet,
  Header,
  Footer,
  Hero,
  FontAwesomeIcon,
} from '../../services/Gerais';
import BotaoCapu from 'components/BotaoCapu';
import 'styles/Styles.scss';
import 'styles/UsuarioPolo.scss';
import { onGetFiltersByCPF, showConfirmModalReset } from './userController';
import { findByFilters, onInativationUser } from 'services/userPolo/service';
import { cpfMask } from 'utils/masks';
import { getParameters } from 'services/resetSenha/service';

const { Option } = Select;

let search = {};

function UsuarioPolo() {
  const [pagination, setPagination] = useState({ position: 'bottom' });
  const [dataSource, setDataSource] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [salvarURLUsrPoloIdx, setSalvarURLUsrPoloIdx] = useState('');
  const [isSpinning, setIsSpinning] = useState(false);
  const [searchCPF, setSearchCPF] = useState('');
  const [polos, setPolos] = useState([]);
  const [listPolosIDs, setPolosIDs] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [isValidResetPassword, setIsValidResetPassword] = useState(true);
  const [listPolos, setListPolos] = useState(
    JSON.parse(localStorage.getItem('userDataCRP-ListaPolos'))
  );
  const [perfilId, setPerfil] = useState(
    localStorage.getItem('userDataCRP-Perfil')
  );





  const [columns, setColumns] = useState([
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 70,
    },
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      width: 170,
    },
    {
      title: 'E-mail',
      key: 'conta',
      dataIndex: 'conta',
      width: 170,
    },
    {
      title: 'CPF',
      key: 'cpf',
      dataIndex: 'cpf',
      width: 150,
      render: (cpf) => {
        return cpfMask(cpf);
      },
    },
    {
      title: 'Polos',
      key: 'lstPolos',
      dataIndex: 'lstPolos',
      width: 230,
      render: (listPolos, record) => {
        let polos = [];
        if (listPolos == undefined) return <small>Sem Polo vinculado</small>;
        listPolos.map((polo) => {
          polos.push(
            <small key={'polo-' + polo.id}>{polo.nome}</small>,
            <br key={'quebra-' + polo.id} />
          );
        });
        return polos;
      },
    },
    {
      title: 'Acesso',
      key: 'situacaoUsuarioId',
      dataIndex: 'situacaoUsuarioId',
      render: (situacaoUsuarioId) => {
        let situacaoUsuario = '';
        if (situacaoUsuarioId == 1) {
          situacaoUsuario = 'ATIVO';
        } else if (situacaoUsuarioId == 2) {
          situacaoUsuario = 'INATIVO';
        } else if (situacaoUsuarioId == 3) {
          situacaoUsuario = 'BLOQUEADO';
        }
        return situacaoUsuario;
      },
    },
    {
      title: 'Dynamics',
      key: 'acessaDynamics',
      dataIndex: 'acessaDynamics',
      render: (acessaDynamics) => {
        return acessaDynamics == 'A' ? 'CAPTAR/ATHENAS' : (acessaDynamics == 'C' ? 'CAPTAR' : (acessaDynamics == 'T' ? 'ATHENAS' : 'NÃO'));
      },
    },
    {
      title: 'Ação',
      key: 'id',
      dataIndex: 'id',
      render: (id, record) => (
        <>
          <Row>
            <Button
              className="button is-small is-warning"
              href={'./usuarios/edit/' + id}
            >
              <small>
                <FontAwesomeIcon icon={['far', 'edit']} />
                &nbsp; Editar
              </small>
            </Button>
            {isValidResetPassword ? (
              <Button
                className="button is-small is-info"
                onClick={() => showConfirmModalReset(record)}
                style={{ marginLeft: '0.2rem' }}
              >
                <small>
                  <i>
                    <FontAwesomeIcon icon={['far', 'user']} />
                  </i>{' '}
                  &nbsp; Resetar senha
                </small>
              </Button>
            ) : (
              ''
            )}
          </Row>
        </>
      ),
    },
  ]);

  function onSelectChange(selectedRowKeys) {
    setSelectedRowKeys(selectedRowKeys);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideDefaultSelections: true,
  };

  async function handleFindByFilters() {
    if (listPolosIDs == undefined || listPolosIDs.length == 0) return;
    setIsSpinning(true);
    let queryString = [
      `?isTelaUsuariosPolo=${true}`,
      `&isFirstOpen=${false}`,
      `&page=${search.page === undefined ? 0 : search.page}`,
      `&size=${10}`,
      `&perfis=${[perfilId]}`,
      search.nome ? `&nome=${search.nome}` : '',
      search.email ? `&email=${search.email}` : '',
      search.cpf
        ? `&cpf=${search.cpf.replaceAll('.', '').replaceAll('-', '')}`
        : '',
      search.situacaoUsuario
        ? `&situacaoUsuario=${search.situacaoUsuario}`
        : '',
      search.polosIds == undefined
        ? `&polosIds=${listPolosIDs}`
        : search.polosIds.length == 0
        ? `&polosIds=${listPolosIDs}`
        : `&polosIds=${search.polosIds}`,
    ].join('');
    const data = await findByFilters(queryString);
    let paginationObj = {
      total: data.data.totalElements,
      current: data.data.number + 1,
    };
    setPagination(paginationObj);
    setDataSource(data.data.content.sort(orderByName));
    setIsSpinning(false);
    setSelecteds([]);
    setSelectedRowKeys([]);
  }




  function orderByName(a, b) {
    if (a.nome > b.nome) return 1;
    if (a.nome < b.nome) return -1;
  }

  function handleTableChange(pagination) {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    search.page = pagination.current - 1;
    handleFindByFilters();
  }


  useEffect(() => {
    validAcess();
    getPolosIds();
    validParametersOfValidResetPassword();
    handleFindByFilters();
  }, []);

  function validAcess() {
    const listOfProfilesThatAcess = [59, 63, 370, 376, 500, 501, 504, 505, 506];
    if (!listOfProfilesThatAcess.includes(parseInt(perfilId))) {
      window.location.href = '/home';
    }
  }


  useEffect(() => {
    let auxColumns = columns;
    auxColumns[7] = {
      title: 'Ação',
      key: 'id',
      dataIndex: 'id',
      width: 230,
      render: (id, record) => (
        <>
          <Row>
            <Button
              className="button is-small is-warning"
              href={'./usuarios/edit/' + id}
            >
              <small>
                <FontAwesomeIcon icon={['far', 'edit']} />
                &nbsp; Editar
              </small>
            </Button>
            {isValidResetPassword ? (
              <Button
                className="button is-small is-info"
                onClick={() => showConfirmModalReset(record)}
                style={{ marginLeft: '0.2rem' }}
              >
                <small>
                  <i>
                    <FontAwesomeIcon icon={['far', 'user']} />
                  </i>{' '}
                  &nbsp; Resetar senha
                </small>
              </Button>
            ) : (
              ''
            )}
          </Row>
        </>
      ),
    };
    setColumns(auxColumns);
  }, [isValidResetPassword]);

  useEffect(() => {
    handleFindByFilters();
  }, [listPolosIDs]);

  useEffect(() => {
    findUserIndex();
  }, [selecteds]);

  useEffect(() => {
    if (usersSelected.length != 0) {
      onInativationUser(usersSelected);
    }
  }, [usersSelected]);

  function getPolosIds() {
    let listPolosId = [];
    listPolos.forEach((item) => {
      setPolos((state) => [...state, item.id]);
      setPolosIDs((state) => [...state, item.id.id]);
    });
    search.polosIds = listPolosId;
    search.page = 0;
  }


  function handleModal() {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }

  async function validParametersOfValidResetPassword() {
    const data = await getParameters();
    setIsValidResetPassword(data.data.valor == 'S' ? true : false);
  }

  function renderOption(item) {
    return (
      <Option key={item.id} text={item.nome}>
        {item['nome'] ? item.nome : item.nome}
      </Option>
    );
  }

  async function findUserByCPF() {
    setIsSpinning(true);
    await onGetFiltersByCPF(searchCPF);
    setIsSpinning(false);
  }

  function inactivateUser() {
    findUsersId();
  }

  async function findUsersId() {
    let finish = false;
    let page = search.page + 1;
    for (let i = 0; i < selectedRowKeys.length; i++) {
      if (page > 1) {
        let id = `${page - 1}${selectedRowKeys[i]}`;
        setSelecteds((state) => [...state, id]);
      } else if (page == 1) {
        let id = selectedRowKeys[i] * page;
        setSelecteds((state) => [...state, id]);
      }
    }
    finish = true;
    await finish;
  }

  async function findUserIndex() {
    let isFinish = false;
    selecteds.forEach((item) => {
      setUsersSelected((state) => [...state, dataSource[item].id]);
    });
    isFinish = true;
    await (isFinish === true);
  }

  return (
    <>
      <Helmet
        key="TitleAdminUsuario"
        title="Usuários por polo | Portal Prisma - Kroton"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: 'Portal Unificado da Kroton Educacional',
          },
          { name: 'keywords', content: 'kroton, educacional, portal' },
        ]}
      />
      <Header key="HeaderAdminUsuarioPoloIndex" />
      <Hero
        key="heroAdminUsuarioPoloIndex"
        pageName="Usuários por polo"
        titulo="Usuários por polo"
      />
      <div className="container">
        <div className="columns">
          <div className="column">
            <Spin tip="Buscando..." spinning={isSpinning}>
              <form
                id="dataForm"
                autoComplete="no"
                className="dataForm"
                encType="application/json"
                action={salvarURLUsrPoloIdx}
              >
                <Row>
                  <div className="">
                    {perfilId == 501 || perfilId == 506 ? (
                      ''
                    ) : (
                      <div className="field">
                        <a
                          onClick={handleModal}
                          className="button is-info is-pulled-right btn-create-user"
                          title="Novo cadastro de usuário"
                        >
                          <i>
                            <FontAwesomeIcon icon={['far', 'plus-square']} />
                          </i>{' '}
                          &nbsp; Novo Cadastro
                        </a>
                      </div>
                    )}
                  </div>
                </Row>
                <Row xs={24}>
                  <Col xs={6}>
                    <Form.Item label="Nome" size="large">
                      <Input
                        size="large"
                        placeholder="Pesquise o nome"
                        onChange={(e) => (search.nome = e.target.value)}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      label="CPF"
                      size="large"
                      className="margin-1-left"
                    >
                      <Input
                        size="large"
                        placeholder="Pesquise o CPF"
                        onChange={(e) => (search.cpf = e.target.value)}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="E-mail"
                      size="large"
                      className="margin-1-left"
                    >
                      <Input
                        size="large"
                        placeholder="Pesquise o E-mail"
                        onChange={(e) => (search.email = e.target.value)}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row xs={24}>
                  <Col xs={6}>
                    <Form.Item label="Polo" size="large">
                      <Select
                        size="large"
                        placeholder="Pesquise o Polo"
                        onChange={(e) => (search.polosIds = e)}
                        style={{ width: '100%' }}
                      >
                        {polos?.map((e) => renderOption(e))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      label="Ativo"
                      size="large"
                      className="margin-1-left"
                    >
                      <Select
                        size="large"
                        placeholder="Acesso"
                        onChange={(e) => (search.situacaoUsuario = e)}
                        style={{ width: '100%' }}
                      >
                        <Option value={1}>ATIVO</Option>
                        <Option value={2}>INATIVO</Option>
                        <Option value={3}>BLOQUEADO</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item size="large">
                      <Button
                        type="primary"
                        className="pull-right"
                        size="large"
                        onClick={handleFindByFilters}
                        style={{
                          border: 'none',
                          margin: '2.7rem 0 0 0',
                        }}
                      >
                        <Icon type="search" />
                        Pesquisar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Row xs={24}>
                  <Table
                    pagination={pagination}
                    columns={columns}
                    dataSource={dataSource}
                    rowSelection={rowSelection}
                    onChange={handleTableChange}
                    size="small"
                    className="margin-1rem-top"
                  />
                </Row>
                <Button onClick={inactivateUser}>Desvincular Usuario(s)</Button>
              </form>
            </Spin>
          </div>
        </div>
        <Modal
          title="Pesquisa por CPF"
          visible={isOpen}
          onOk={handleModal}
          onCancel={handleModal}
          destroyOnClose={true}
          width={400}
          centered
        >
          <Spin tip="Buscando..." spinning={isSpinning}>
            <Row xl={24}>
              <Form.Item label="Busca de usuario por CPF">
                <Input
                  type="text"
                  name="cpf"
                  className="is-pulled-left"
                  placeholder="000.000.000-00"
                  style={{ width: 180 }}
                  maxLength={14}
                  onChange={(e) =>
                    setSearchCPF(
                      e.target.value.replaceAll('.', '').replaceAll('-', '')
                    )
                  }
                />
                <a
                  className="button is-info is-pulled-left"
                  title="Buscar CPF"
                  onClick={findUserByCPF}
                >
                  <i>
                    <FontAwesomeIcon icon={['fas', 'search']} />
                  </i>{' '}
                  &nbsp; Buscar
                </a>
              </Form.Item>
            </Row>
          </Spin>
        </Modal>

      </div>
      <hr className="hrEspacamento" key="hrEspacamentoUsuariosPolo" />
      <Footer key="FooterAdminUsuarioPoloIndex" />
      {perfilId !== 504 ? <BotaoCapu key="BotaoCapuHome" /> : ''}
    </>
  );
}

export default UsuarioPolo;
