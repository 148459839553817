import React from 'react';

import styles from './style.module.css';
import FiltersTable from '../Table';

const Section = props => (
  <section className={styles.container}>
    <FiltersTable form={props.form} />
  </section>
);

export default Section;
