import { notification } from 'antd';
import { register, filterById, update } from '../service';

export class FormController {
  constructor(props) {
    this.state = props.state;
    this.history = props.history;
    this.form = props.form;
  }

  async get(id) {
    this.state.loading.setLoading(true);
    const item = await filterById(id);
    this.form.reset(item);
    console.log(item)
    this.state.dates.setDates({
      include: item.dataAlteracao ? item.dataAlteracao : false,
      created: item.dataInclusao ? item.dataInclusao : false
    })
    this.state.loading.setLoading(false);
  }

  async handleSubmit(e, edit) {
    try {
      this.state.loading.setLoading(true);
      if (edit) {
        const data = {
          valor: e.valor,
          descricao: e.descricao,
          observacao: e.observacao,
          ativo: e.ativo,
          executeJob: e.executeJob,
        }
        await update(data, e.id);
      } else {
        await register(e);
      }

      notification['success']({
        message: `${
          edit ? 'Atualização' : 'Cadastro'
        } foi feito(a) com sucesso!`,
        description: `O parametro ${e.valor} foi ${
          edit ? 'atualizado' : 'cadastrado'
        } com sucesso`,
      });
      this.state.loading.setLoading(false);
      this.history.push('/admin/parameters');
    } catch (error) {
      notification['error']({
        message: `Erro ao ${edit ? 'Atualizar' : 'Cadastrar'} parametro!`,
        description: error.message
          ? error.message
          : 'Por favor verificar com a equipe tecnica',
      });
      this.state.loading.setLoading(false);
    }
  }
}
