import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Line = styled.div`
  flex: 1;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-start;
  transition: all ease 0.3s;

  :hover {
    transform: ${(props) => props.animation && 'translateX(4px)'};
    color: #000;
  }
  cursor: pointer;

  p {
    font-weight: bold;
    margin: 0;
    transition: all ease 0.3s;
  }

  svg {
    transition: all ease 0.3s;
  }
`;
