import styled from 'styled-components';

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const FieldBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;

  textarea.ant-input {
    height: 140px !important;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
`;

export { ActionContainer, Column, FieldBox };
