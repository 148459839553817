import React, { Component } from 'react';
import Routes from './routes';
import Scrollbar from 'react-scrollbars-custom';
import { Redirect } from 'react-router-dom';

import { authProvider } from './services/authProvider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

let objUsrAuth = {};

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '6db9cb2e-98a1-4eb5-8c34-fb6b65ed95fd',
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

const getUserID = () => {
  if (localStorage.getItem('userDataCRP')) {
    const usrLogin = JSON.parse(localStorage.getItem('userDataCRP-Login'));

    let poloID = '';
    let poloIDC = '';
    let usrID = null;
    let pessoaNome = '';

    if (usrLogin) {
      usrLogin.map(v => {
        usrID = v.usuarioPortal.id;
        poloID = v.polo !== null ? '-' + v.polo.id : '';
        poloIDC = v.polo !== null ? v.polo.id : '';
        pessoaNome = v.usuarioPortal.pessoa.nome;
        return v;
      });
    }

    objUsrAuth = {
      usrID: usrID,
      poloID: poloIDC,
      usuarioName: pessoaNome,
    };
    return { userId: `${usrID}${poloID}` };
  }

  return false;
};

var usrIDpoloID = getUserID();

ReactGA.initialize('UA-149075455-1', {
  //UA-150296524-1
  debug: false,
  gaOptions: usrIDpoloID,
});

if (objUsrAuth) {
  ReactGA.set({ dimension1: objUsrAuth.poloID });

  ReactGA.set({ dimension4: objUsrAuth.usuarioName });
  if (objUsrAuth.usrID) {
    ReactGA.set({ dimension2: objUsrAuth.usrID });
    ReactGA.set({ dimension5: usrIDpoloID });
  }
}

const tagManagerArgs = { gtmId: 'GTM-PQRKCZC' };
TagManager.initialize(tagManagerArgs);
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  constructor() {
    super();
    this.state = {
      appName: 'Portal Prisma - Kroton Educacional',
      redirect: false,
      modalAtivaLogout: false,
      home: false,
    };
  }
  // periodo de inatividade
  inactivityTime() {
    var selfCall = this;
    var time;

    window.onload = resetTimer;
    // eventos DOM
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function logout() {
      selfCall.setState({ modalAtivaLogout: true });
      setTimeout(function() {
        localStorage.setItem('userDataCRP', '');
        localStorage.setItem('userDataCRP-Login', '');
        localStorage.setItem('userDataCRP-Polo', '');
        localStorage.setItem('userDataCRP-Polos', '');
        localStorage.setItem('userDataCRP-Perfil', '');
        localStorage.clear();
        authProvider.logout();
        selfCall.setState({
          modalAtivaLogout: false,
          redirect: true,
        });
        return <Redirect to={'/login'} />;
      }, 30000);
    }
    function resetTimer() {
      // 1000 milisegundos = 1 segundo
      clearTimeout(time);
      time = setTimeout(logout, 7200000);
      // time = setTimeout(logout, 3000)
    }
  }
  render() {
    return (
      <Scrollbar
        key="Scrollbar"
        contentProps={{ className: 'AppContent' }}
        // onMouseMove={this.inactivityTime()}
      >
        <Routes key="routes-dash" name={this.state.appName} />
        {/* modal de logout */}
        <div
          className={this.state.modalAtivaLogout ? 'modal is-active' : 'modal'}
          key="modalSelecaoPortal"
        >
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Atingido o limite de timeout</p>
            </header>
            <section className="modal-card-body">
              <p>Redirecionando, aguarde...</p>
            </section>
          </div>
        </div>
      </Scrollbar>
    );
  }
}

export default App;
