import React, { Component } from 'react';
import avatar from 'img/loadingtwo.gif';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Form,
  Icon,
  Checkbox,
  Select,
  DatePicker,
  TimePicker,
  Divider,
  Input,
} from 'antd';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
  FontAwesomeIcon,
  DualListBox,
  ToolbarDraft,
  moment,
} from 'services/Gerais';
import {
  getDadosCadInitAgenda,
  changeAgenda,
  changeLstMarca,
  findAllByTipoPoloAndMarca,
  changeLstPolo,
  getAgenda,
  changeLstPoloSelecionado,
  changeLstMarcaSelecionada,
  salvaAgenda,
  alteraAgenda,
} from 'store/actions';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import Swal from 'sweetalert2';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import api from 'services/api';
import 'styles/Styles.scss';
import { uploadToS3 } from 'services/uploadS3/s3Service';

const ButtonGroup = Button.Group;

const { Option } = Select;
const { RangePicker } = DatePicker;
const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
};

const MsgSalvoComSucesso = ({ history }) => {
  window.setTimeout(() => {
    history.push('/admin/agenda');
  }, 2000);

  Swal.fire({
    position: 'top-end',
    title: `Salvo com sucesso!`,
    icon: 'success',
    showConfirmButton: false,
    timer: 1500,
  });
  return null;
};

const MgErroAoSalvar = ({ error, changeUsuario }) => {
  if (changeUsuario) {
    changeUsuario({
      prop: 'error',
      value: null,
    });
  }

  if (error) {
    let msgErroUsrCad = '';
    if (error.error) {
      msgErroUsrCad = error.error;
    }
    if (error.response) {
      if (error.response.data.path.indexOf('setorByPerfil') > -1) {
        Swal.fire({
          title: 'Ops...!',
          text: 'Erro ao buscar Setor!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return '';
      }
    }

    Swal.fire({
      title: 'Ops...!',
      text: `Erro ao salvar! ${msgErroUsrCad} Verifique as informações
      inseridas e tente novamente!`,
      icon: 'error',
      confirmButtonText: 'Ok',
    });

    return '';
  }

  Swal.fire({
    title: 'Ops...!',
    text: `Erro ao salvar! ${error.error || ''}`,
    icon: 'error',
    confirmButtonText: 'Ok',
  });

  return null;
};

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      editorState: EditorState.createEmpty(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selecionaPolo = this.selecionaPolo.bind(this);
    this.selecionaMarca = this.selecionaMarca.bind(this);
    this.onCheckAllChange = this.onCheckAllChange.bind(this);
    this.buscaPolosPorMarca = this.buscaPolosPorMarca.bind(this);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    this.changeData = this.changeData.bind(this);
    this.changeDataOk = this.changeDataOk.bind(this);
    this.configRangeDate = this.configRangeDate.bind(this);
    this.alteraMarca = this.alteraMarca.bind(this);
    this.categoria = [];
    this.horaInicio = moment(new Date(), 'HH:mm');
    this.horaFim = moment(new Date(), 'HH:mm');
  }

  async componentDidMount() {
    this.props.getDadosCadInitAgenda();

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      await this.props.getAgenda(this.props.match.params.id);
      const blocksFromHTML = htmlToDraft(this.props.agenda.agenda.conteudoNovo);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      this.setState({
        isEdit: true,
        editorState: EditorState.createWithContent(contentState),
      });
    } else {
      this.props.agenda.carregando = false;
    }
    if(this.props.agenda.agenda.dataFim._d == undefined && this.props.agenda.agenda.dataFim.split(' ')[1] != undefined){
      this.horaFim = this.props.agenda.agenda.dataFim.split(' ')[1]
    }
    if(this.props.agenda.agenda.dataInicio._d == undefined && this.props.agenda.agenda.dataInicio.split(' ')[1] != undefined){
      this.horaInicio = this.props.agenda.agenda.dataInicio.split(' ')[1]
    }
  }

  updadeTime = () => {
    let dateEnd = (this.props.agenda.agenda.dataFim._d != undefined
      ? this.props.agenda.agenda.dataFim.format('DD-MM-YYYY')
      : this.props.agenda.agenda.dataFim.split(' ')[0]);
    let hourEnd = (this.horaFim !== ''
    ? this.horaFim._d != undefined
      ? this.horaFim.format('HH:mm')
      : this.horaFim
    : this.props.agenda.agenda.dataFim.split(' ')[1])

    let dateStart = (this.props.agenda.agenda.dataInicio._d != undefined
      ? this.props.agenda.agenda.dataInicio.format('DD-MM-YYYY')
      : this.props.agenda.agenda.dataInicio.split(' ')[0]);
    let hourStart = (this.horaInicio !== ''
    ? this.horaInicio._d != undefined
      ? this.horaInicio.format('HH:mm')
      : this.horaInicio
    : this.props.agenda.agenda.dataInicio.split(' ')[1]);

    this.props.agenda.agenda.dataInicio = `${dateStart} ${hourStart}`;
    this.props.agenda.agenda.dataFim = `${dateEnd} ${hourEnd}`;
  }

  handleSubmit = (e) => {
    this.updadeTime();
    this.props.agenda.agenda.agendaTipo = {
      id: this.props.agenda.agenda.categoria.id,
    };
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { agenda } = this.props;
        agenda.botaoSalvarHabilitado = false;
        if (agenda.lstAgendaMarca.length === 0) {
          Swal.fire({
            title: 'Ops...!',
            text: 'Campo Marca não informado',
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
          agenda.botaoSalvarHabilitado = true;
          return;
        }
        if (agenda.lstAgendaPolo.length === 0) {
          Swal.fire({
            title: 'Ops...!',
            text: 'Campo Polo não informado',
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
          agenda.botaoSalvarHabilitado = true;
          return;
        }

        if (agenda.agenda.conteudoNovo.length < 10) {
          Swal.fire({
            title: 'Ops...!',
            text: 'Campo Conteudo não informado',
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
          agenda.botaoSalvarHabilitado = true;
          return;
        }

        if (this.state.isEdit) {
          this.props.alteraAgenda(agenda, this.props.match.params.id);
        } else {
          this.props.salvaAgenda(agenda);
        }
      }
    });
  };

  selecionaPolo = (selected) => {
    let polos = selected.map((value) => {
      return {
        polo: {
          id: value,
        },
      };
    });

    this.props.changeLstPolo(polos);
    this.props.changeLstPoloSelecionado(selected);
  };

  canalChange = (e) => {
    this.props.changeAgenda({
      prop: 'poloProprio',
      value: e,
    });

    this.buscaPolosPorMarca(this.props.agenda.marcaSelecionada);
  };

  selecionaMarca = (checkedList) => {
    this.alteraMarca(checkedList);
    this.props.changeLstPoloSelecionado([]);
  };

  alteraMarca = (marcas) => {
    let lstMarcas = marcas.map((value) => {
      return {
        marca: {
          id: value,
        },
      };
    });

    this.props.changeLstMarca(lstMarcas);
    this.props.changeLstMarcaSelecionada(marcas);
    this.buscaPolosPorMarca(marcas);
    this.setState({
      indeterminate:
        !!marcas.length &&
        marcas.length < Object.keys(this.props.dadosAgenda.Marca).length,
      checkAll: marcas.length === this.props.dadosAgenda.Marca.length,
    });
  };

  buscaPolosPorMarca = (marcas) => {
    let tipoPolo = this.props.agenda.agenda.poloProprio;
    if (marcas.length > 0 && tipoPolo !== 'T') {
      this.props.findAllByTipoPoloAndMarca({
        lstMarcaId: marcas,
        poloProprio: tipoPolo,
      });
    } else if (marcas.length > 0 && tipoPolo === 'T') {
      this.props.findAllByTipoPoloAndMarca({
        lstMarcaId: marcas,
      });
    } else if (marcas.length === 0 && tipoPolo !== 'T') {
      this.props.findAllByTipoPoloAndMarca({
        poloProprio: tipoPolo,
      });
    } else {
      this.props.getDadosCadInitAgenda();
      return false;
    }
  };

  onCheckAllChange = (e) => {
    if (e.target.checked) {
      this.alteraMarca(
        Object.keys(this.props.dadosAgenda.Marca).map((value) => value)
      );
    } else {
      this.alteraMarca([]);
    }

    this.setState({
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    this.props.changeAgenda({
      prop: 'conteudoNovo',
      value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  changeTimeStart = (time) => {
    this.horaInicio = time.format('HH:mm');
  };

  changeTimeEnd = (time) => {
    this.horaFim = time.format('HH:mm');
  };

  uploadImageCallBack = async (file) => {
    try {
      let dataImage = new FormData();
      dataImage.append('file', file);
      const res = await uploadToS3(dataImage)

      return {
        data: {
          link: res.data.link,
        },
      };
    } catch (error) {}
  };

  configRangeDate = () => {
    const { dataInicio, dataFim } = this.props.agenda.agenda;
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Por Favor selecione um periodo',
        },
      ],
    };
    if (dataInicio !== null && dataFim !== '') {
      let starDate = moment(dataInicio, 'DD-MM-YYYY hh:mm');
      let endDate = moment(dataFim, 'DD-MM-YYYY hh:mm');
      Object.assign(rangeConfig, {
        initialValue: [starDate, endDate],
      });
    }

    return rangeConfig;
  };
  changeData = (value, dateString) => {
    this.props.changeAgenda({
      prop: 'dataInicio',
      value: dateString[0],
    });

    this.props.changeAgenda({
      prop: 'dataFim',
      value: dateString[1],
    });
  };

  changeDataOk = (value) => {
    this.props.changeAgenda({
      prop: 'dataInicio',
      value: value[0].format('DD-MM-YYYY hh:mm'),
    });

    this.props.changeAgenda({
      prop: 'dataFim',
      value: value[1].format('DD-MM-YYYY hh:mm'),
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { agenda, dadosAgenda } = this.props;
    const { isEdit, editorState } = this.state;
    this.categoria = dadosAgenda.Categoria;
    const categorias = [
      'Treinamentos',
      'Webinars',
      'Lembretes',
      'Eventos',
      'Agenda Polo',
    ];
    if (agenda.carregando || dadosAgenda.carregando) {
      return (
        <div className="CRP-loading">
          <img src={avatar} width="100" alt="Carregando..." />
        </div>
      );
    }

    return (
      <>
        <Helmet
          key="TitleAdminAgendaCadastro"
          title="Cadastro de Agenda | Portal Prisma"
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: 'Cadastro de agenda - Portal Prisma',
            },
            {
              name: 'keywords',
              content: 'cadastro, agenda, kroton, educacional, portal',
            },
          ]}
        />
        <HeaderAdmin key="HeaderAdminAgendaCadastro" />
        <section
          key="SectionAdmin"
          className="columns is-multiline conteudoAdmin"
          id="admin-app"
        >
          <MenuAdmin key="MenuAdminAgendaCadastro" />
          <div className="column is-10 is-fullheight conteudoAdminPagina">
            <Hero
              key="heroAdminCadastroAgenda"
              pageName="Administração - Agenda"
              titulo="Administração - Agenda"
            />
            <div id="return"></div>
            <div id="response"></div>
            <div className="columns">
              <div className="column">
                <h1 className="title">
                  {isEdit ? 'Editar agendamento' : 'Cadastro de agendamento'}
                </h1>
              </div>
            </div>
            {agenda.succes && (
              <MsgSalvoComSucesso history={this.props.history} />
            )}
            {!agenda.sucess && agenda.error && (
              <MgErroAoSalvar
                error={agenda.error}
                changeUsuario={this.props.changeAgenda}
              />
            )}
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col span={12} style={{ paddingRight: 15 }}>
                  <Form.Item label="Título" size="large">
                    {getFieldDecorator('titulo', {
                      initialValue: agenda.agenda.titulo,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor selecione um Título',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Insira um título"
                        onChange={(e) =>
                          this.props.changeAgenda({
                            prop: 'titulo',
                            value: e.target.value.trim(),
                          })
                        }
                        size="large"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="URL do evento (se houver)" size="large">
                    {getFieldDecorator('url', {
                      initialValue: agenda.agenda.url,
                      rules: [
                        {
                          type: 'url',
                          message:
                            'A URL do evento tem que seguir este padrão: https://seusite.com',
                        },
                      ],
                    })(
                      <Input
                        placeholder="https://seusite.com"
                        onChange={(e) =>
                          this.props.changeAgenda({
                            prop: 'url',
                            value: e.target.value.trim(),
                          })
                        }
                        size="large"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                      />
                    )}
                    {/* <Input
                      type="url"
                      value={agenda.agenda.url}
                      placeholder="https://seusite.com"
                      onChange={e =>
                        this.props.changeAgenda({
                          prop: 'url',
                          value: e.target.value.trim(),
                        })
                      }
                      size="large"
                      onBlur={e => e.target.value = e.target.value.trim()}
                    /> */}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item label="Conteúdo do evento" size="large">
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateChange}
                      defaultEditorState={agenda.agenda.conteudoNovo}
                      localization={{ locale: 'pt' }}
                      toolbar={{
                        ToolbarDraft,
                        image: {
                          className: undefined,
                          urlEnabled: true,
                          component: undefined,
                          popupClassName: undefined,
                          alignmentEnabled: true,
                          uploadEnabled: true,
                          uploadCallback: this.uploadImageCallBack,
                          previewImage: true,
                          inputAccept:
                            'image/gif,image/jpeg,image/jpg,image/png',
                          alt: { present: true, mandatory: false },
                          defaultSize: {
                            height: 'auto',
                            width: 'auto',
                          },
                        },
                      }}
                    />
                    <textarea
                      className="textarea is-hidden"
                      value={draftToHtml(
                        convertToRaw(
                          editorState.getCurrentContent(agenda.agenda.conteudoNovo)
                        )
                      )}
                      readOnly
                      required
                    ></textarea>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={7} style={{ paddingRight: 10 }}>
                  <Form.Item label="Ativo">
                    <Select
                      size="large"
                      defaultValue={agenda.agenda.ativo}
                      onChange={(e) =>
                        this.props.changeAgenda({
                          prop: 'ativo',
                          value: e,
                        })
                      }
                    >
                      <Option value={'S'}>Sim</Option>
                      <Option value={'N'}>Não</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 15 }}>
                  <Form.Item
                    label="Data início (data-hora) / Data fim (data-hora)"
                    size="large"
                  >
                    {getFieldDecorator(
                      'data',
                      this.configRangeDate()
                    )(
                      <RangePicker
                        defaultValue={[
                          moment(
                            agenda.agenda.dataInicio,
                            'DD-MM-YYYY HH:mm:ss'
                          ),
                          agenda.agenda.dataFim
                            ? moment(
                                agenda.agenda.dataFim,
                                'DD-MM-YYYY HH:mm:ss'
                              )
                            : '',
                        ]}
                        size="large"
                        format="DD-MM-YYYY"
                        placeholder={[
                          'Data início (data-hora)',
                          'Data fim (data-hora)',
                        ]}
                        onChange={this.changeData}
                        onOk={this.changeDataOk}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={9} className="padding-right">
                  <Form.Item label="Hora início (Hora) / Hora fim (Hora)">
                    <TimePicker
                      defaultValue={(agenda.agenda.dataInicio._d == undefined ?
                        moment(agenda.agenda.dataInicio.split(' ')[1], 'HH:mm') :
                        moment(agenda.agenda.dataInicio, 'HH:mm') )}
                      size="large"
                      format={'HH:mm'}
                      placeholder="Hora início (Hora)"
                      className="timePicker"
                      onChange={this.changeTimeStart}
                    />

                    <TimePicker
                      defaultValue={(agenda.agenda.dataFim._d == undefined ?
                        moment(agenda.agenda.dataFim.split(' ')[1], 'HH:mm') :
                        moment(agenda.agenda.dataFim, 'HH:mm') )}
                      size="large"
                      format={'HH:mm'}
                      placeholder="Hora fim (Hora)"
                      className="timePicker margin-0-5-left"
                      onChange={this.changeTimeEnd}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Divider />
              <Row>
                <Col span={6} style={{ paddingRight: 10 }}>
                  <Form.Item label="Categoria" size="large">
                    {getFieldDecorator('categoria', {
                      initialValue:
                        agenda.agenda.categoria && agenda.agenda.categoria.id,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor selecione uma categoria valida',
                        },
                      ],
                    })(
                      <Select
                        size="large"
                        onChange={(e) =>
                          this.props.changeAgenda({
                            prop: 'categoria',
                            value: { id: e },
                          })
                        }
                      >
                        <Option value=""> Selecione</Option>
                        {Object.keys(this.categoria).map((item) => {
                          return (
                            <Option
                              key={'categoria-' + item.id}
                              value={this.categoria[item].id}
                            >
                              {this.categoria[item].descricao}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item label="Canal:">
                    <Select
                      size="large"
                      defaultValue={agenda.agenda.poloProprio}
                      onChange={this.canalChange}
                    >
                      <Option value="T">Todos</Option>
                      <Option value="S">Próprio</Option>
                      <Option value="N">Parceiro</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Marca" validateStatus="error" size="large">
                    <div>
                      <div style={{ borderBottom: '1px solid #E9E9E9' }}>
                        <Checkbox
                          indeterminate={this.state.indeterminate}
                          onChange={this.onCheckAllChange}
                          checked={this.state.checkAll}
                        >
                          Selecionar todos
                        </Checkbox>
                      </div>
                      <br />
                      <Checkbox.Group
                        value={agenda.marcaSelecionada}
                        onChange={this.selecionaMarca}
                      >
                        {Object.keys(dadosAgenda.Marca).map((item) => {
                          return (
                            <Row>
                              <Col span={24}>
                                <Checkbox value={item}>
                                  {dadosAgenda.Marca[item]}
                                </Checkbox>
                              </Col>
                            </Row>
                          );
                        })}
                      </Checkbox.Group>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <label>Polo:</label>
                  <DualListBox
                    options={dadosAgenda.Polo}
                    key={'DualListBoxCad'}
                    canFilter={true}
                    filterPlaceholder={'Busca'}
                    selected={agenda.polosSelecionados}
                    onChange={this.selecionaPolo}
                    icons={{
                      moveLeft: (
                        <i key="l">
                          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                        </i>
                      ),
                      moveAllLeft: [
                        <i key="ll">
                          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                        </i>,
                        <i key="lll">
                          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                        </i>,
                      ],
                      moveRight: (
                        <i key="r">
                          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                        </i>
                      ),
                      moveAllRight: [
                        <i key="rr">
                          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                        </i>,
                        <i key="rrr">
                          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                        </i>,
                      ],
                      moveDown: (
                        <i key="mu">
                          <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                        </i>
                      ),
                      moveUp: (
                        <i key="md">
                          <FontAwesomeIcon icon={['fas', 'chevron-up']} />
                        </i>
                      ),
                    }}
                  />
                </Col>
              </Row>
              <Divider />
              <Row type="flex" justify="end" gutter={16}>
                <Col span={6} justify="end" className="gutter-row">
                  <Form.Item size="large">
                    <ButtonGroup size="large" block>
                      <Button
                        onClick={() => this.props.history.push('/admin/agenda')}
                      >
                        <Icon type="stop" />
                        Cancelar
                      </Button>

                      {!agenda.botaoSalvarHabilitado ? (
                        <Button
                          type="primary"
                          size="large"
                          style={{
                            'background-color': '#52c41a',
                            border: 'none',
                          }}
                          loading
                        >
                          Carregando
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          size="large"
                          style={{
                            'background-color': '#52c41a',
                            border: 'none',
                          }}
                          htmlType="submit"
                          disabled={hasErrors(getFieldsError())}
                        >
                          <Icon type="save" /> Salvar
                        </Button>
                      )}
                    </ButtonGroup>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </section>
        <FooterAdmin key="FooterAdminUsuariosCadastro" />
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  agenda: store.Agenda,
  dadosAgenda: store.DadosAgenda,
});

export default connect(mapStateToProps, {
  getDadosCadInitAgenda,
  changeAgenda,
  changeLstMarca,
  findAllByTipoPoloAndMarca,
  changeLstPolo,
  getAgenda,
  changeLstPoloSelecionado,
  changeLstMarcaSelecionada,
  salvaAgenda,
  alteraAgenda,
})(Form.create()(Agenda));
