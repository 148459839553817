import React from 'reactn';
import {
  FontAwesomeIcon,
  Scrollbar,
  moment,
  $,
  axios,
} from '../../services/Gerais';
import { Popover, Button, Row, Col, Icon, Input, Upload } from 'antd';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga';

class ComunicadoDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.toggleNavedisActivedDisplay = this.toggleNavedisActivedDisplay.bind(
      this
    );
    this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this);
  }

  copyCodeToClipboard = (comunicado) => {
    let textField = document.createElement('textarea');
    textField.innerText = `${window.location.origin}/comunicados/${comunicado.id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    Swal.fire({
      title:  `Copiado!`,
      text: `URL do comunicado foi copiada.`,
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  };

  toggleNavedisActivedDisplay = () => {
    let columName = 'columnClose';

    if (this.props.isDestaque) {
      columName = 'columnCloseDestaque';
    }
    if (this.props.categoriaID) {
      columName = columName + '_' + this.props.categoriaID;
    }
    var link = document.getElementById(columName);
    link.click();
  };

  geraComunicadoDisplay = (e, cDisplay, btn) => {
    e.preventDefault();

    let lido = cDisplay.lido;
    let favorito = cDisplay.favorito;

    if (btn === 'like') {
      if (favorito !== 'S') {
        favorito = 'S';
        lido = 'S';
        $(document)
          .find('[data-favorito="' + cDisplay.id + '"] svg')
          .data('prefix', 'fas');
        $(document)
          .find('[data-favorito="' + cDisplay.id + '"] svg path')
          .attr(
            'd',
            'M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z'
          );
      } else {
        favorito = 'N';
        $(document)
          .find('[data-favorito="' + cDisplay.id + '"] svg')
          .data('prefix', 'far');
        $(document)
          .find('[data-favorito="' + cDisplay.id + '"] svg path')
          .attr(
            'd',
            'M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z'
          );
      }
    }

    // marcando como lido
    const dataComunicadoUsuario = [
      {
        usuarioPortal: { id: this.logInfo['usuarioPortal']['id'] },
        comunicado: { id: cDisplay.id },
        favorito: favorito,
        comunicadoLido: lido,
        usuarioPortalLog: { id: this.logInfo['usuarioPortal']['id'] },
      },
    ];

    axios
      .post(
        this.global.baseUrl + 'comunicadoUsuario/saveComunicadoUsuario',
        dataComunicadoUsuario[0],
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then((resultDisplay) => {
        ReactGA.modalview('/comunicado/' + cDisplay.codigo);
        axios
          .get(
            this.global.baseUrl +
              'comunicadoUsuario/getComunicadoByComunicadoAndUsuario/' +
              this.logInfo['usuarioPortal']['id'] +
              '/' +
              cDisplay.id,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
              },
            }
          )
          .then((infosResDisplay) => {
            this.setState({
              showComunicado: infosResDisplay.data,
              isLoading: false,
            });
          })
          .catch((errorDisplay) =>
            this.setState({
              errorDisplay,
              isLoading: false,
            })
          );
      })
      .catch((errorDisplay) => {
      });
  };

  render() {
    const anexosLista = this.props['comunicado'].anexos;
    let showComunicado = this.props['comunicado'];
    let comunDisplay = showComunicado['area'];
    let tamanhoTags = showComunicado['lstTag']
      ? showComunicado['lstTag'].length
      : 0;
    const isMinutoOperacao = (this.props['comunicado']?.destaque == 'S' ? true : false);
    const datetime = moment().format('DD-MM-YYYY HH:mm:ss');
    let boxAlerta = 'column informacoesPortal informacoesComunicados ';
    let boxAlertaSpan = '';
    switch (showComunicado['prioridade']) {
      case 'C':
        boxAlertaSpan =
        <span className="alerta alerta-red">
          <FontAwesomeIcon icon={['fas', 'thermometer-full']} className="icon-thermometer"color="#ffffff"/>
        </span>;
        boxAlerta = 'boxAlerta-red boxAlertaSemCarousel';
        break;
      case 'A':
        boxAlertaSpan =
        <span className="alerta alerta-orange">
          <FontAwesomeIcon icon={['fas', 'thermometer-three-quarters']} className="icon-thermometer" color="#ffffff"/>
        </span>;
        boxAlerta = 'boxAlerta-orange boxAlertaSemCarousel';
        break;
      case 'I':
        boxAlertaSpan =
        <span className="alerta alerta-blue">
          <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff"/>
        </span>;
        boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
        break;
      case 'R':
        boxAlertaSpan =
        <span className="alerta alerta-green">
          <FontAwesomeIcon icon={['fas', 'thermometer-quarter']} className="icon-thermometer" color="#ffffff"/>
        </span>;
        boxAlerta = 'boxAlerta-green boxAlertaSemCarousel';
        break;
      default:
        boxAlertaSpan =
        <span className="alerta alerta-blue">
          <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff"/>
        </span>;
        boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
        break;
    }

    return [
      <div
        key={'displayComunicado'}
        style={{ marginRight: '15%' }}
        className={' column informacoesPortal informacoesComunicados ' + boxAlerta}
      >
        {boxAlertaSpan}
        <span
          className="fechar"
          title="Fechar"
          onClick={this.toggleNavedisActivedDisplay}
        >
          {' '}
          X{' '}
        </span>
        <Scrollbar style={{ width: '100%', height: '40vw', minHeight: 300 }}>
          <div className="has-text-justified descricaoComunicados ScrollbarsCustom native mostraCategoria">
            <div className="title is-6 is-spaced comunicadosInfos">
              <label className="check">
                {' '}
                <FontAwesomeIcon icon={['fas', 'check']} />
                <FontAwesomeIcon icon={['fas', 'check']} />{' '}
              </label>
              <small>visualizado {datetime}</small>
              <small className="codigoComunicado">
                Código do Comunicado:{' '}
                <strong>{showComunicado['codigo']}</strong>
              </small>
            </div>
            <h5 className="title is-spaced comunicadoCat text-center">
              {showComunicado['errata'] === 'S' && 'Atualização - '}
              {showComunicado['titulo']}
            </h5>
            <small className="smallComunicadoDescricao comunicadoCat text-center">
              {showComunicado['dataInicio']}{' '}
              {comunDisplay ? ' | ' + comunDisplay['descricao'] : ''}
            </small>
            <div className="content comunicadoCat">
              <p
                dangerouslySetInnerHTML={{ __html: showComunicado['conteudoNovo'] }}
              />
            </div>
            {anexosLista && (
              <div className="content comunicadoCat">
                <div>
                  <b>Arquivos anexos</b>
                </div>
                <div>
                  <Upload
                    fileList={JSON.parse(anexosLista)}
                    showUploadList={{
                      showRemoveIcon: false,
                      showDownloadIcon: true,
                    }}
                    disabled={true}
                  ></Upload>
                </div>
              </div>
            )}
            {tamanhoTags > 0 && (
              <div className="content is-small referenciaComunicados comunicadoCat">
                <strong>TAGS:</strong> &nbsp;
                {showComunicado['lstTag'].map((tg) => {
                  return (
                    <a
                      href={'./comunicados-tags/' + tg['id']}
                      key={'tagRel' + tg['id']}
                      className="is-small is-rounded is-outlined comunicadoCat tagsComunicado"
                    >
                      {tg['descricao']}
                    </a>
                  );
                })}
              </div>
            )}
            {
              (isMinutoOperacao ? '' :
                <Button
                  key={`edit_${showComunicado.id}`}
                  title={'Compartilhar esse comunicado'}
                  size="small"
                  type="primary"
                  onClick={() => this.copyCodeToClipboard(showComunicado)}
                >
                  <FontAwesomeIcon icon={['fas', 'share-alt']} />
                  <span style={{ paddingLeft: 5 }}>Compartilhar</span>
                </Button>
              )
            }
          </div>
        </Scrollbar>
      </div>,
    ];
  }
}
export default ComunicadoDisplay;
