import { notification } from 'antd';
import { findByFilters, list, importPolos } from '../service';
import readXlsxFile from 'read-excel-file';

export class FormController {
  constructor(props) {
    this.state = props.state;
  }

  async hadleInitData() {
    this.state.loading.setLoading(true);
    const result = await list();
    this.state.data.setData(result);
    this.state.loading.setLoading(false);
  }

  async importPolos(info) {
    try {
      if (info.file.status !== 'uploading') {
        const dataArray = [];
        let error = false;
        readXlsxFile(info.file.originFileObj)
          .then(async (rows) => {
            rows.map((item, index) => {
              if (error) return;
              if (index === 0) {
                if (!item[0]?.toLocaleLowerCase().includes('id')) {
                  error = true;
                } else {
                  error = false;
                }
              }
              if (item.length >= 3 && item[1] != null) {
                dataArray.push({
                  idPolo: item[0],
                  nomePolo: item[1],
                  razaoSocialEmpresa: item[2],
                  municipioRazaoSocial: item[3],
                  ufRazaoSocial: item[4],
                  enderecoRazaoSocial: item[5],
                  numeroRazaoSocial: item[6],
                  bairroRazaoSocial: item[7],
                  cepRazaoSocial: item[8],
                  cnpj: item[9],
                  cpfGestorPolo: item[10],
                  nomeGestorPolo: item[11],
                  dataRepasse: item[12],
                  dataAssinaturaAtualModeloCp: item[13],
                  razaoSocialNovo: item[14],
                  cidadeRazaoNovo: item[15],
                  estadoRazaoNovo: item[16],
                  enderecoRazaoNovo: item[17],
                  bairroRazaoNovo: item[18],
                  cepRazaoNovo: item[19],
                  cnpjRazaoNovo: item[20],
                  representanteLegalNovo: item[21],
                  enderecoTeleaula: item[22],
                  numeroTeleaula: item[23],
                  complementoTeleaula: item[24],
                  bairroTeleaula: item[25],
                  cepTeleaula: item[26],
                  municipioTeleaula: item[27],
                  estadoTeleaula: item[28],
                  enderecoPoloNovo: item[29],
                  complementoPoloNovo: item[30],
                  bairroPoloNovo: item[31],
                  cepPoloNovo: item[32],
                  cidadePoloNovo: item[33],
                  ufPoloNovo: item[34],
                  cicloAtivacao: item[35],
                  dataDisparo: item[36],
                  dataSistema: item[43],
                  ciclo1: item[37],
                  ciclo2: item[38],
                  ciclo3: item[39],
                  queda1: item[40],
                  queda2: item[41],
                  queda3: item[42],
                  enderecoPolo: item[44],
                  numeroPolo: item[45],
                  complementoPolo: item[46],
                  bairroPolo: item[47],
                  cepPolo: item[48],
                  cidadePolo: item[49],
                  ufPolo: item[50],
                  complementoRazaoSocial: item[51],
                  cpfRepresentanteLegal: item[52],
                  nomeRepresentanteLegal: item[53],
                  complementoRazaoNovo: item[54],
                  cpfRepresentanteLegalNovo: item[55],
                });
              }
            });
            if (dataArray.length > 0 && !error) {
              dataArray.shift();
              await importPolos(dataArray);
              this.state.uploadError.setUploadError(false);
              notification['success']({
                message: `Polos importados com sucesso`,
              });
              await this.hadleInitData();

              return;
            }
            notification['error']({
              message: 'Formato de documento inválido',
            });
            this.state.uploadError.setUploadError(true);
          })
          .catch(() => {
            this.state.uploadError.setUploadError(true);
            notification['error']({
              message: 'Formato de documento inválido',
            });
          });
      }
    } catch (error) {
      this.state.uploadError.setUploadError(true);
      notification['error']({
        message: `Erro ao importar polos`,
        description: 'Por favor verificar com a equipe tecnica',
      });
    }
  }

  async handleSubmit(e) {
    try {
      this.state.loading.setLoading(true);
      const { data } = await findByFilters(e);
      this.state.data.setData(data);
    } catch (error) {
      notification['error']({
        message: `Erro ao Listar filtros`,
        description:
          error?.message?.[0] || 'Por favor verificar com a equipe tecnica',
      });
    } finally {
      this.state.loading.setLoading(false);
    }
  }
}
