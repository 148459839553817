import { Menu } from 'antd';
import React, { useEffect } from 'react';

function MenuDefalt({ item }) {
  let data = item.menuFilho;

  let defineUrl =
    item.url != null
      ? item.url.includes('comunicado')
        ? `/${item.url}/${item.id}`
        : item.url
      : '';

  const hasMenuFilho = item.menuFilho.length != null ? true : false;
  useEffect(() => {
    return '';
  }, []);

  if (data && data.length > 0) {
    return (
      <>
        <Menu mode="horizontal">
          {hasMenuFilho ? (
            <Menu.SubMenu
              title={item.descricao}
              onTitleClick={() => (window.location.href = item.url)}
            >
              {data.length > 0 ? (
                data.map((params) => {
                  const url = (params.url ? params.url?.replace("https://prisma.kroton.com.br/", "https://prismadevfront.krthomolog.com.br/") : "")
                  if (params.subCategoria != null) {
                    return (
                      <Menu.SubMenu
                        title={params.descricao}
                        onTitleClick={() =>
                          (window.location.href = `/${url}/${params.id}`)
                        }
                      >
                        {params.subCategoria.map((sub) => {
                          return (
                            <Menu.Item key={sub.descricao}>
                              <a
                                href={`/${url}/${params.id}/${sub.id}`}
                                title={sub.descricao}
                              >
                                {sub.descricao}
                              </a>
                            </Menu.Item>
                          );
                        })}
                      </Menu.SubMenu>
                    );
                  } else {
                    if (url?.includes('comunicado')) {
                      defineUrl = `/${url}/${params.id}`;
                    }
                    return (
                      <Menu.Item key={params.descricao}>
                        {url?.includes('https://prisma') ? (
                          <a
                            data-tut={'menu_' + params.id}
                            href={url}
                            key={'linkMenuPai-' + params.id}
                            title={params.descricao}
                          >
                            {params.descricao}
                          </a>
                        ) : (
                          <a
                            data-tut={'menu_' + params.id}
                            href={url}
                            target={'_blank'}
                            key={'linkMenuPai-' + params.id}
                            title={params.descricao}
                          >
                            {params.descricao}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  }
                })
              ) : (
                <></>
              )}{' '}
              :{}
            </Menu.SubMenu>
          ) : (
            ''
          )}
        </Menu>
      </>
    );
  } else if (data && data.length == 0) {
    return (
      <Menu mode="horizontal">
        <Menu.Item key={item.descricao}>
          <a
            data-tut={'menu_' + item.id}
            href={'/' + item.url}
            key={'linkMenuPai-' + item.id}
            title={item.descricao}
          >
            {item.descricao}
          </a>
        </Menu.Item>
      </Menu>
    );
  }
}

export default MenuDefalt;
