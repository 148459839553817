import styled from 'styled-components';

const Sphere = styled.div`
  width: 8px;
  height: 8px;
  background: ${(props) => props.background};
  border-radius: 50%;
  margin: 0px 4px;
`;

const ContainerSelect = styled.div`
  .ant-select-selection-selected-value {
    display: flex !important;
    align-items: center !important;
    column-gap: 4px !important;
  }
`;

export { Sphere, ContainerSelect };
