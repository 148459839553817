import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import timeGridPlugin from '@fullcalendar/timegrid';

import ptBr from '@fullcalendar/core/locales/pt-br';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import '../../../styles/components/Agenda.scss';

export default function AgendaCalendar({
  setCalendarRef,
  filtraPorCategoria,
  getCalendarData,
  changeAgendaDisplay,
}) {
  return (
    <div id="calendar" key="calendar">
      <FullCalendar
        defaultView="dayGridMonth"
        ref={(ref) => {
          setCalendarRef(ref);
        }}
        header={{
          left: 'title',
          right: 'timeGridWeek,dayGridMonth,prev,next,',
          // locale: 'pt-br',
        }}
        locale={ptBr}
        timezone="America/Sao_Paulo"
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: true,
          meridiem: 'short',
        }}
        theme={false}
        editable={false}
        eventLimit={true}
        allDaySlot={false}
        scrollTime="07:00:00"
        minTime="07:00:00"
        maxTime="22:00:01"
        contentHeight="auto"
        plugins={[timeGridPlugin, dayGridPlugin]}
        weekends={true}
        events={(fetchInfo, successCallback, failureCallback) =>
          getCalendarData(fetchInfo, successCallback, failureCallback)
        }
        eventRender={(calEvent, element, view) => {
          return filtraPorCategoria(calEvent);
        }}
        eventClick={(info) => {
          if (info && info.event) {
            console.log('info', info)
            changeAgendaDisplay(info.event._def);
          }
          return false;
        }}
      />
    </div>
  );
}
