import React from 'react';
import { Header, Hero, Helmet } from '../../services/Gerais';
import { Categories, CommonQuestions } from './components';
import { Button as StyledButton, Container, Line, RegisterBox } from './styles';
import { useFaq } from './hook';
import { Link } from 'react-router-dom';
import { Form, Select, Button } from 'antd';

const Faq = ({
  match: {
    params: { palavraChave },
  },
}) => {
  const { Option } = Select;
  const {
    debounceSearch,
    onChange,
    searchValue,
    active,
    setActive,
    admin,
    options,
  } = useFaq({ palavraChave });

  function handleSearchSelected() {
    setActive({
      questions: true,
      categories: false,
    });
    window.location.href = '/faq';
  }

  return (
    <>
      <Helmet
        title="FAQ - Portal Prisma - Kroton"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: 'FAQ - Portal Unificado da Kroton Educacional',
          },
          { name: 'keywords', content: 'agenda, kroton, educacional, portal' },
        ]}
        key="titleAgenda"
      />
      <Header key="HeaderFAQ" />
      <Hero key="heroAgenda" pageName="FAQ" titulo="Central de Ajuda" />
      <section className="section conteudoPortais" key="conteudoPortais">
        <div className="container is-full-fullhd">
          <Container>
            {admin && (
              <RegisterBox>
                <Button href="/admin/categoria" type="primary">
                  Cadastrar Categoria
                </Button>

                <Button href="/admin/faq" type="primary">
                  Cadastrar FAQ
                </Button>
              </RegisterBox>
            )}

            <Line>
              <StyledButton
                onClick={handleSearchSelected}
                active={active?.questions}
              >
                PERGUNTAS FREQUENTES
              </StyledButton>
              <StyledButton
                onClick={() =>
                  setActive({
                    questions: false,
                    categories: true,
                  })
                }
                active={active?.categories}
              >
                CATEGORIAS
              </StyledButton>
            </Line>

            <Line>
              <Form style={{ width: '50%' }}>
                <Form.Item label="Busca geral">
                  <Select
                    mode="multiple"
                    onSearch={async (value) => await debounceSearch(value)}
                    value={searchValue}
                    onChange={(e) => onChange(e)}
                    size="large"
                    placeholder="Selecione"
                  >
                    {options.length &&
                      options.map((key) => {
                        return (
                          <Option
                            onClick={() => {
                              window.location.href = `/faq/${key.palavraChave}`;
                            }}
                            key={`${key.questao}-${key.id}`}
                          >
                            {key.questao}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Form>
            </Line>
            <Line>
              {active?.questions && (
                <CommonQuestions {...{ palavraChave, admin }} />
              )}
              {active?.categories && <Categories {...{ admin }} />}
            </Line>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Faq;
