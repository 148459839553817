import React, { useState, useEffect } from 'react';
import {
  Header,
  Footer,
  library,
  Hero,
  Helmet,
  moment,
  FontAwesomeIcon,
  axios
} from '../../services/Gerais';
import api from '../../services/api';
import { faCheck, faStar, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { Select, Spin, Button } from 'antd';

import '../../styles/components/Agenda.scss';

import MiniCalendar from './components/MiniCalendar';
import Categorias from './components/Categorias';
import AgendaCalendar from './components/AgendaCalendar';
import AgendaDisplay from './components/AgendaDisplay';
import BotaoCapu from '../../components/BotaoCapu';
import Swal from 'sweetalert2';
import '../../styles/Agenda.scss';

const { Option } = Select;

library.add({ faCheck, faStar, faVideo, faBell });

const Agenda = (props) => {
  const baseUrl = JSON.parse(localStorage.getItem('cnfgDataCRP')).baseUrl;
  const [logInfo, setLogInfo] = useState(true);
  const [calendarRef, setCalendarRef] = useState('');
  const [agendaDisplay, setAgendaDisplay] = useState([]);
  const [conteudoLink, setConteudoLink] = useState('');
  const [idPolo, setIdPolo] = useState(null);
  const [poloId, setPoloId] = useState(JSON.parse(localStorage.getItem('userDataCRP-Polo')));
  const [perfilId, setPerfil] = useState(0);
  const [nomePolo, setNomePolo] = useState(null);
  const [perfilAdm, setPerfilAdm] = useState(false);
  const [carregando, carr] = useState(false);
  const [polosLista, setData] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [newAgenda, setNewAgenda] = useState(false);
  const [checkados, setCheckados] = useState([
    '#afd5ef',
    '#00DBB1',
    '#E9EFBE',
    '#33D4F1',
    '#FF8C00',
  ]);

  const colors = [
    {
      color: '#afd5ef',
      textColor: '#202121',
      classe: 'bt-treinamentos',
      label: 'Treinamentos',
    },
    {
      color: '#00DBB1',
      textColor: '#202121',
      classe: 'bt-webinars',
      label: 'Webinars',
    },
    {
      color: '#E9EFBE',
      textColor: '#202121',
      classe: 'bt-lembretes',
      label: 'Lembretes',
    },
    {
      color: '#33D4F1',
      textColor: '#202121',
      classe: 'bt-eventos',
      label: 'Eventos',
    },
    {
      color: '#FF8C00',
      textColor: '#202121',
      classe: 'bt-agenda',
      label: 'Agenda Polo',
    },
  ];

  useEffect(async () => {
    validaGestor();
    setLogInfo(JSON.parse(localStorage.getItem('userDataCRP')));
    let polo = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
    if (logInfo && !polo) {
      setPerfilAdm(true);
      getPoloLista();
    }
    const response = await api.get(`agenda/buscaAgendaTipo`);
    setCategoria(response.data);
    setPoloId(parseInt(localStorage.getItem('userDataCRP-Polo')));
  }, []);

  async function getPoloLista() {
    try {
      carr(true);
      const result = await api.get('polo/findAllIdNome');
      carr(false);
      setData(result.data);
    } catch (error) {
      erro();
    }
  }
  function erro() {
    carr(false);
    Swal.fire({
      title: 'Erro!',
      text: `Não foi possivel realizar operação`,
      icon: 'error',
      confirmButtonText: 'Ok'
    })
  }

  function changeAgendaDisplay(info) {
    setConteudoLink('is-active');
    let lst = {
      'conteudo': info.extendedProps.conteudo,
      'dataFim': info.extendedProps.dataFim,
      'dataInicio': info.extendedProps.dataInicio,
      'titleDisplay': info.extendedProps.titleDisplay,
      'urlSite': info.extendedProps.urlSite,
      'id': info.groupId,
      'isEdited': newAgenda,
      'type': info.ui.backgroundColor
    }
    setAgendaDisplay(lst);
  }

  function toggleNavedAgenda() {
    setConteudoLink('');
  }

  function validaGestor() {
    axios.get(`${baseUrl + `agenda/isUsuarioGestorPolo/${poloId}`}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      },
    }).then(res => {
      setNewAgenda(res.data.isUsuarioGestorPolo);
    })
  }

  async function getCalendarData(fetchInfo, successCallback) {
    let poloId = 1;
    if (idPolo) {
      poloId = idPolo;
    } else if (logInfo && logInfo.polo !== null) {
      poloId = logInfo.polo.id;
      setNomePolo(logInfo.polo.nome);
    }
    try {
      let dataInicial = moment(fetchInfo.startStr).format('YYYYMMDD');
      let dataFinal = moment(fetchInfo.endStr).format('YYYYMMDD');
      const response = await api.get(
        `agenda/agendaByPolo/${poloId}/${dataInicial}/${dataFinal}`
      );
      successCallback(retornaEventsCalendar(response.data.Agenda));
    } catch (error) { }
  }

  function retornaEventsCalendar(eventos) {
    return eventos.map((x) => {
      var obj = {
        id: x.agenda.id,
        groupId: x.agenda.id,
        title: x.agenda.titulo,
        titleDisplay: x.agenda.titulo,
        start: moment(x.agenda.dataInicio, 'DD-MM-YYYY HH:mm').format(
          'YYYY-MM-DDTHH:mm:ss.008'
        ),
        end: moment(x.agenda.dataFim, 'DD-MM-YYYY HH:mm').format(
          'YYYY-MM-DDTHH:mm:ss.008'
        ),
        urlSite: x.agenda.url,
        conteudo: x.agenda.conteudoNovo,
        color: colors[x.agenda.categoria.id - 1].color,
        textColor: colors[x.agenda.categoria.id - 1].textColor,
        dataFim: x.agenda.dataFim,
        dataInicio: x.agenda.dataInicio,
      };
      return obj;
    });
  }

  function filtroOpcoes() {
    const opcoes = polosLista.map((item, index) => (
      <Option key={index} value={[item.id, item.nome]}>
        {item.nome}
      </Option>
    ));
    return opcoes;
  }

  function changeFiltros(value) {
    const valueColor = value.target.value;
    if (checkados.includes(valueColor)) {
      setCheckados(checkados.filter((i) => i !== valueColor));
    } else {
      setCheckados([...checkados, valueColor]);
    }
  }

  function filtraPorCategoria(calEvent) {
    return checkados.indexOf(calEvent.event.backgroundColor) !== -1;
  }

  function filtrarAgenda(poloIdSelecionado) {
    setIdPolo(poloIdSelecionado[0]);
    setNomePolo(poloIdSelecionado[1]);
  }
  function agendaTitulo() {
    return nomePolo ? 'Agenda de ' + nomePolo : 'Agenda';
  }

  return (
    <>
      <Helmet
        title="Agenda - Portal Prisma - Kroton"
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'description',
            content: 'Agenda - Portal Unificado da Kroton Educacional',
          },
          { name: 'keywords', content: 'agenda, kroton, educacional, portal' },
        ]}
        key="titleAgenda"
      />
      <Header key="HeaderAgenda" />
      <Hero key="heroAgenda" pageName="Agenda" titulo={agendaTitulo()} />
      <section className="section agendaGeral" key="conteudoAgenda">
        <div className="container is-fullhd">
          <div className="columns">
            <div className="column">
              <MiniCalendar
                key={idPolo}
                calendarRef={calendarRef}
                poloIdSelecionado={idPolo}
              />
              <Categorias colors={categoria} changeFiltros={changeFiltros} />
            </div>
            <div className="column is-three-quarters">
              <div className="tabelaResponsivo ">
                <Spin tip="Loading..." spinning={carregando} delay={500}>
                  {perfilAdm && (
                    <div className="selecionarPolo">
                      <div class="selecionarPoloSelect">
                        <Select
                          showSearch
                          className='select-style'
                          style={{
                            width: '100%',
                          }}
                          placeholder="Escolher um polo"
                          optionFilterProp="children"
                          onChange={(value) => {
                            filtrarAgenda(value);
                          }}
                        >
                          {filtroOpcoes()}
                        </Select>
                      </div>
                    </div>
                  )}
                  <div className="new-agenda">
                    <Button
                      type="primary"
                      size="large"
                      className={(newAgenda == true ? "new-agenda-button" : "is-invisible")}
                      href={"./cadastro/agenda"}>
                      <FontAwesomeIcon icon={['far', 'calendar-alt']} /> &nbsp;
                      Nova Agenda
                    </Button>
                  </div>
                  <AgendaCalendar
                    setCalendarRef={setCalendarRef}
                    filtraPorCategoria={filtraPorCategoria}
                    getCalendarData={getCalendarData}
                    changeAgendaDisplay={changeAgendaDisplay}
                  />
                  <AgendaDisplay
                    agendaDisplay={agendaDisplay}
                    conteudoLink={conteudoLink}
                    toggleNavedAgenda={toggleNavedAgenda}
                    isEdit={newAgenda}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="hrEspacamento" key="hrEspacamentoAgenda" />
      <Footer key="FooterAgenda" />
      {(perfilId !== 504 ?
        <BotaoCapu key="BotaoCapuHome" /> : '')}
    </>
  );
};

export default Agenda;
