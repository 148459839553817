import {
  AGENDA_CHANGE,
  AGENDA_CHANGE_MARCA,
  AGENDA_CHANGE_POLO,
  AGENDA_SUCCESS,
  AGENDA_ERROR,
  AGENDA_CHANGE_MARCA_SELECIONADA,
  AGENDA_CHANGE_POLO_SELECIONADO
} from '../../../../store/actions/Administrativo/Agenda/AgendaType';
import moment from 'moment';
let initialState = {
  carregando: true,
  succes: false,
  error: null,
  botaoSalvarHabilitado: true,
  Polo:[],
  marcaSelecionada:[],
  polosSelecionados:[],
  agenda:{
    usuarioPortalLog:{
      id:1
   },
   titulo: "",
   conteudo:"",
   url:"",
   ativo:"S",
   dataInicio: moment(new Date(new Date().getTime()), 'dd-mm-yyyy hh:mm'),
   dataFim: moment(new Date(new Date().getTime()), 'dd-mm-yyyy hh:mm'),
   categoria:{
      id:""
    },
    poloProprio:"T"
  },
  lstAgendaMarca:[],
  lstAgendaPolo:[],
};

const agenda = (state = initialState, action) => {
  switch (action.type) {
    case AGENDA_SUCCESS:
      return { ...state, ...action.payload };
    case AGENDA_ERROR:
      return { ...state, ...action.payload };
    case AGENDA_CHANGE:
      return {
        ...state,
        agenda: {...state.agenda, [action.payload.prop]: action.payload.value}
      };
    case AGENDA_CHANGE_MARCA:
      return {
        ...state,
        lstAgendaMarca: action.payload
      };
    case AGENDA_CHANGE_POLO:
      return {
        ...state,
        lstAgendaPolo: action.payload
      };
    case AGENDA_CHANGE_MARCA_SELECIONADA:
      return {
        ...state,
        marcaSelecionada: action.payload
      };
    case AGENDA_CHANGE_POLO_SELECIONADO:
      return {
        ...state,
        polosSelecionados: action.payload
      };
    default:
      return state;
  }
};

export default agenda;
