import {
  COMUNICADO_CHANGE,
  COMUNICADO_ERROR,
  COMUNICADO_SUCCESS,
  COMUNICADO_SUCCESS_SAVE,
  COMUNICADO_DADOS_INICIAIS_SUCCESS,
  COMUNICADO_DADOS_INICIAIS_ERROR,
  COMUNICADO_UPLOAD_SUCCESS,
  COMUNICADO_UPLOAD_ERROR,
  COMUNICADO_POLO_SUCCESS,
  COMUNICADO_POLO_ERROR,
} from './ComunicadoType';

import api from '../../../../services/api';

export const getDadosIniciaisComunicado = async () => {
  return async function(dispatch) {
    try {
      var poloId = localStorage.getItem("userDataCRP-Polo");
      const res = await api.get(`comunicado/getDadosCadInitComunicado${(poloId > 0 ? `?poloId=${poloId}` : '')}`);

      return dispatch({
        type: COMUNICADO_DADOS_INICIAIS_SUCCESS,
        payload: { ...res.data },
      });
    } catch (error) {
      return dispatch({
        type: COMUNICADO_DADOS_INICIAIS_ERROR,
        payload: { error: error, carregando: false },
      });
    }
  };
};

export const getComunicadoId = async id => {
  return async function(dispatch) {
    try {
      const res = await api.get(`comunicado/getComunicado/${id}/`);

      return dispatch({
        type: COMUNICADO_SUCCESS,
        payload: { ...res.data.Comunicado },
      });
    } catch (error) {
      return dispatch({
        type: COMUNICADO_ERROR,
        payload: { error: error },
      });
    }
  };
};

export const salvarComunicado = async comunicado => {
  return async function(dispatch) {
    try {
      const res = await api.post(`comunicado/saveComunicado/`, comunicado);
      return dispatch({
        type: COMUNICADO_SUCCESS_SAVE,
        payload: {
          ...res.data.Comunicado,
        },
      });
    } catch (error) {
      return dispatch({
        type: COMUNICADO_ERROR,
        payload: { error, succes: false, botaoSalvarHabilitado: true },
      });
    }
  };
};

export const alterarComunicado = async (data, id) => {
  try {
    await api.put(`comunicado/saveComunicado/${id}`, data);

    return dispatch => {
      dispatch({
        type: COMUNICADO_SUCCESS_SAVE,
        payload: { succes: true, error: null, botaoSalvarHabilitado: true },
      });
    };
  } catch (error) {
    let erro = error;
    if (erro && erro.response && erro.response.data) {
      erro = error.response.data;
    }
    return dispatch => {
      dispatch({
        type: COMUNICADO_ERROR,
        payload: { error: erro, succes: false, botaoSalvarHabilitado: false },
      });
    };
  }
};

export const changeComunicado = ({ prop, value }) => {
  return dispatch => {
    dispatch({ type: COMUNICADO_CHANGE, payload: { prop, value } });
  };
};

export const uploadPolos = async csvPolos => {
  return async function(dispatch) {
    try {
      const formData = new FormData();
      formData.append('file', csvPolos);
      const res = await api.post(`comunicadoPolo/sendCsvPolos/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return dispatch({
        type: COMUNICADO_UPLOAD_SUCCESS,
        payload: {
          ...res.data,
        },
      });
    } catch (error) {
      return dispatch({
        type: COMUNICADO_UPLOAD_ERROR,
        payload: { error, succes: false, botaoSalvarHabilitado: true },
      });
    }
  };
};

export const getLstComunicadoPolo = async idComunicado => {
  return async function(dispatch) {
    try {
      const res = await api.get(
        `comunicadoPolo/getByComunicado/${idComunicado}`
      );
      return dispatch({
        type: COMUNICADO_POLO_SUCCESS,
        payload: {
          ...res.data,
        },
      });
    } catch (error) {
      return dispatch({
        type: COMUNICADO_POLO_ERROR,
        payload: { error, succes: false, botaoSalvarHabilitado: true },
      });
    }
  };
};

export const getSubCategoriaByCategoria = async idCategoria => {
  return async function(dispatch) {
    try {
      const res = await api.get(`subcategoria/getByCategoria/${idCategoria}`);
      return dispatch({
        type: COMUNICADO_POLO_SUCCESS,
        payload: {
          ...res.data,
        },
      });
    } catch (error) {
      return dispatch({
        type: COMUNICADO_POLO_ERROR,
        payload: { error, succes: false, botaoSalvarHabilitado: true },
      });
    }
  }
}
