import React, {Component} from 'reactn';
import './../../styles/Styles.scss';
import Helmet from 'react-helmet';
import logo from "../../img/prisma_logo.png";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import "../../styles/cookiesConsent.scss"

let isIE = /*@cc_on!@*/false || !!document.documentMode;

class Welcome extends Component {

    render() {
        localStorage.clear();
        return (
            <div className="container welcome-screen">
                <Helmet key="WelcomePage" title="Seja bem vindo ao Portal Prisma - Kroton"
                    meta={[
                        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                        { name: 'description', content: 'Portal Unificado da Kroton Educacional' },
                        { name: 'keywords', content: 'kroton, educacional, portal' },
                    ]} />
                <div className="column has-text-centered">
                    {isIE &&
                        <div className="notification is-badge-warning">
                            <strong>Só um ponto de atenção <span role="img" aria-label="❗">❗</span></strong>
                            <p>O Prisma não dá suporte ao Internet Explorer - <a href="https://www.microsoft.com/pt-br/windowsforbusiness/end-of-ie-support">Até a MS já está desistindo dele</a>, então... <span role="img" aria-label="😁">😁</span></p>
                        </div>
                    }
                    <img className="logoLogin" src={logo} alt="Kroton Educacional" width="25%"/>
                    <hr />
                    <h2 className="title" id="welcomeText">Seja bem vindo ao Portal Prisma.</h2>
                    <p>
                        O Portal Prisma é um Portal que centraliza as comunicações e informações relevantes para os polos da rede Kroton.<br />
                        Apresentaremos uma interface robusta, moderna, dinâmica e <em>user friendly</em>, trazendo o nível de sofisticação que a rede e a maturidade da operação exigem.
                    </p>
                    {/*{!link.includes("localhost") && <p className="notification is-badge-warning">*/}
                        {/*Ambiente de backend: {link} <br />*/}
                        {/*<small>Mensagem não estará na versão final</small>*/}
                    {/*</p>}*/}
                    <hr />
                    <a href="./login" className="button is-medium is-link">Acesse!</a>
                </div>
                <div>
                {!getCookieConsentValue('cookieConsent') ? (
                  <CookieConsent
                    location="bottom"
                    buttonText="ok"
                    cookieName="cookieConsent"
                    buttonWrapperClasses="cookieButtonWrapper"
                    buttonClasses="cookieButton"
                    disableButtonStyles='true'
                    contentStyle={{ 'flex': '1 0', 'width': '70%'}}
                  >
                      <h3>Esse Site usa Cookies</h3>

                      <p>Esse site utiliza cookies para oferecer uma melhor experiência de navegação, analisar como você interage em nosso site,
                      otimizar o desempenho e personalizar o conteúdo. Ao utilizar este site, você concorda com o uso de cookies.
                      Saiba mais em nosso <b style={{"color": "#3273dc"}}><a href="https://www.canaldatransparencia.com.br/cogna/" target='_blank'>Portal de Privacidade.</a></b></p>
                  </CookieConsent>
                  ) : null
                }
              </div>
            </div>
        );
    }
}

export default Welcome;
