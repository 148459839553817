import React from 'react';
import { TitleWrapper } from './styles';

function BreadcrumbCreator(variaveis) {
  const linkInterno = variaveis.variaveis;
  if (!linkInterno.pageLink) {
    return [
      <li key="{linkInterno.pageName}" className="is-active">
        <a
          href={linkInterno.pageLink}
          title={linkInterno.pageName}
          aria-current="page"
        >
          {linkInterno.pageName}
        </a>
      </li>,
    ];
  } else {
    return [
      <li key="{linkInterno.pageName}">
        <a href={linkInterno.pageLink} title={linkInterno.pageName}>
          {linkInterno.pageName}{' '}
        </a>
      </li>,
      <li key="{linkInterno.pageLink}" className="is-active">
        <a href=".." aria-current="page" title={linkInterno.titulo}>
          {linkInterno.titulo}
        </a>
      </li>,
    ];
  }
}

function Hero(props) {
  let paginaAdmin = false;
  if (
    window.location.pathname.split('/')[1] === 'admin' ||
    window.location.pathname.split('/')[1] === 'administracao' ||
    window.location.pathname.split('/')[1] === 'geradoc'
  ) {
    paginaAdmin = true;
  }
  return [
    <section
      className={'hero is-crp ' + (paginaAdmin && 'heroAdmin')}
      key="{props.key}"
    >
      <div className="hero-body">
        <div className={!paginaAdmin ? 'container is-fullhd' : ''}>
          <nav
            className="breadcrumb is-left"
            aria-label="breadcrumbs"
            key="navegacao"
          >
            <ul>
              <li>
                <a href="/home" title={'Home'}>
                  Home
                </a>
              </li>
              {window.location.pathname.split('/')[1] === 'admin' && (
                <li>
                  <a href="/administracao" title={'Administração'}>
                    Administração
                  </a>
                </li>
              )}
              <BreadcrumbCreator key="breadcrumbNoticia" variaveis={props} />
            </ul>
          </nav>
          {props.button ? (
            <TitleWrapper>
              <h1 className="title is-1">{props.titulo}</h1>
              {props.button}
            </TitleWrapper>
          ) : (
            <h1 className="title is-1">{props.titulo}</h1>
          )}
          {props.responsavel && props.pageName === "Relatório" ? (
            <TitleWrapper>
              <h3>Responsável: {props.responsavel}</h3>
            </TitleWrapper>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>,
  ];
}

export default Hero;
