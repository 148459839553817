import { createStore, combineReducers, applyMiddleware } from 'redux';
import promise from 'redux-promise';
import ReduxThunk from 'redux-thunk';

import Autenticacao from './Autenticacao';
import DadosPerfil from './Administrativo/Usuario/DadosPerfilReducer';
import Usuario from './Administrativo/Usuario/UsuarioReducer';
import Agenda from './Administrativo/Agenda/AgendaReducer';
import DadosAgenda from './Administrativo/Agenda/DadosAgendaReducer';
import Banner from './Administrativo/Banner/BannerReducer';
import Comunicado from './Administrativo/Comunicado/ComunicadoReducer';

const reducers = combineReducers({
  Autenticacao,
  DadosPerfil,
  Usuario,
  Agenda,
  DadosAgenda,
  Banner,
  Comunicado,
});

export const reduxStore = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(ReduxThunk, promise)
);
