import React, { useState, useEffect } from 'reactn';
import { Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import { authProvider } from '../../services/authProvider';
// import logo from '../../img/logo_unopar.png'
import crp from '../../img/prisma_logo.png';
import avatar from '../../img/avatar-48.png';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Tour from 'reactour';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MensagensImportantes from 'components/MensagensImportantes';
import { Icon } from 'antd';
import PersonifiedBanner from 'components/PersonifiedBanner';

import {
  faEnvelope,
  faBell,
  faEdit,
  faUser,
  faUserCircle,
  faBookmark,
} from '@fortawesome/free-regular-svg-icons';
import MenuRelatorio from './MenuRelatorio';
import MenuDefault from './MenuDefault';
import {
  faSearch,
  faSignOutAlt,
  faAdjust,
  faCog,
  faSync,
  faMapMarkedAlt,
  faArrowLeft,
  faArrowRight,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { listComunicados } from './service';
library.add({
  faSearch,
  faEnvelope,
  faBell,
  faEdit,
  faSignOutAlt,
  faAdjust,
  faCog,
  faUser,
  faUserCircle,
  faBookmark,
  faSync,
  faMapMarkedAlt,
  faArrowLeft,
  faArrowRight,
  faLayerGroup,
});


function Menu(props) {
  var verificaLink = false;
  var pathArray = window.location.pathname.split('/');
  var listaMenusCompleto = props.props.menuPrincipal;
  var isActive = true;
  let liberaAdminMenu = false;

  if (
    props.props.perfil[0] === 1 ||
    props.props.perfil[0] === 999 ||
    props.props.perfil[0] === 503
  ) {
    liberaAdminMenu = true;
  } else {
    liberaAdminMenu = false;
  }

  if (typeof listaMenusCompleto !== 'undefined') {
    localStorage.setItem(
      'userDataCRP-Menu',
      JSON.stringify(listaMenusCompleto)
    );

    // bloqueio de admin / user comum
    if (pathArray[1] === 'administracao' && !liberaAdminMenu) {
      return <Redirect to={'/home'} />;
    } else if (pathArray[1] === 'admin' && !liberaAdminMenu) {
      return <Redirect to={'/home'} />;
    }

    var row = listaMenusCompleto.map((m) => {
      if (m.menuFilho.length === 0) {
        if (pathArray[1] !== 'home' && verificaLink === false) {
          if (pathArray[1] === m.url) {
            verificaLink = true;
          }
        }

        if (m.descricao === 'Usuários (Polo)') {
          return (
            <>
              <MenuDefault item={m} />
            </>
          );
        }

        if (m.descricao == 'Relatório') {
          return <MenuRelatorio item={m} />;
        }
        return (
          <MenuDefault item={m} />
        );
      } else {
        if (
          pathArray[1] !== 'home' &&
          pathArray[1] !== 'admin' &&
          pathArray[1] !== 'administracao' &&
          pathArray[1] !== 'mockupcompleto' &&
          pathArray[1] !== 'lista-comunicados' &&
          pathArray[1] !== 'comunicados-tags' &&
          pathArray[1] !== 'mapa' &&
          pathArray[1] !== 'usuariosPolo' &&
          pathArray[1] !== 'usuarios' &&
          pathArray[1] !== 'agendar' &&
          pathArray[1] !== 'agenda' &&
          pathArray[1] !== 'busca' &&
          pathArray[1] !== 'geradoc' &&
          verificaLink === false
        ) {
          if (pathArray[1] === m.url) {
            verificaLink = true;
          }
        }
        return <MenuDefault item={m} />;
      }
    });

    if (
      pathArray[1] !== 'home' &&
      pathArray[1] !== 'admin' &&
      pathArray[1] !== 'administracao' &&
      pathArray[1] !== 'mockupcompleto' &&
      pathArray[1] !== 'comunicados' &&
      pathArray[1] !== 'lista-comunicados' &&
      pathArray[1] !== 'comunicados-tags' &&
      pathArray[1] !== 'usuariosPolo' &&
      pathArray[1] !== 'usuarios' &&
      pathArray[1] !== 'mapa' &&
      pathArray[1] !== 'mensagens' &&
      pathArray[1] !== 'relatorio' &&
      pathArray[1] !== 'agendar' &&
      pathArray[1] !== 'agenda' &&
      pathArray[1] !== 'busca' &&
      pathArray[1] !== 'portais' &&
      pathArray[1] !== 'estrutura' &&
      pathArray[1] !== 'usuario-estrutura' &&
      pathArray[1] !== 'cadastro' &&
      pathArray[1] !== 'FOCA' &&
      pathArray[1] !== 'faq' &&
      pathArray[1] !== 'polos' &&
      pathArray[1] !== 'geradoc' &&
      pathArray[1] !== 'workflow' &&
      pathArray[1] !== 'meusdados' &&
      pathArray[1] !== 'extracaoRelatorioFoca' &&
      pathArray[1] !== 'FocaPdf' &&
      pathArray[1] !== 'migracaoGn' &&
      verificaLink === false
    ) {
      return <Redirect to={'/home'} />;
    }
  } else {
    row = null;
  }
  return row;
}



class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      modalAtiva: false,
      menu: '',
      isTourOpen: false,
      isShowingMore: false,
      primeiroAcesso: JSON.parse(localStorage.getItem('primeiroAcesso')),
    };
    this.logout = this.logout.bind(this);
    this.modalPolos = this.modalPolos.bind(this);
    this.onChangePolo = this.onChangePolo.bind(this);
    this.altoContraste = this.altoContraste.bind(this);
    this.redirecionar = this.redirecionar.bind(this);
    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.poloEscolhido = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
    this.listaPolos = JSON.parse(
      localStorage.getItem('userDataCRP-ListaPolos')
    );
    this.listaPolosCRP = JSON.parse(localStorage.getItem('userDataCRP-Polos'));
    this.isDisabled = (this.listaPolos?.length === 1 ? true : false);
  }
  state = { isActive: false };



  modalPolos() {
    this.setState({ modalAtiva: true });
  }

  toggleNav = () => {
    this.setState((prevState) => ({ isActive: !prevState.isActive }));
  };

  onChangePolo(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value > 0) {
      localStorage.setItem('userDataCRP-Polo', e.target.value);
      this.verificarPolo = localStorage.getItem('userDataCRP-Polo');

      this.listaPolosCRP
        .filter((poloCRP) => poloCRP['polo']['id'] === parseInt(e.target.value))
        .map((poloCRP) => {
          // if(poloCRP['polo']['id'] === parseInt(e.target.value)){
          return localStorage.setItem('userDataCRP', JSON.stringify(poloCRP));
          // }
        });

      // {this.listaPolosCRP.map(poloCRP =>
      //     (poloCRP['polo']['id'] === e.target.value && localStorage.setItem('userDataCRP', JSON.stringify(poloCRP))
      // )}

      // localStorage.setItem('userDataCRP', JSON.stringify(responseJson[0]));
      localStorage.setItem('EstPolo', 0)
      this.setState({ modalAtiva: false });
      window.location.reload();
    }
  }

  altoContraste() {}

  logout(btnSair = false) {
    localStorage.setItem('userDataCRP', '');
    localStorage.setItem('userDataCRP-Login', '');
    localStorage.setItem('userDataCRP-Polo', '');
    localStorage.setItem('userDataCRP-Polos', '');
    localStorage.setItem('userDataCRP-AuthTk', '');
    localStorage.setItem('userDataCRP-Perfil', '');
    localStorage.setItem('EstPolo', '');
    localStorage.clear();
    btnSair && authProvider.logout();
    this.setState({ redirect: true });
    return <Redirect to={'/login'} />;
  }

  componentWillMount() {
    /**
     * Pega Sessão Auth Token
     */
    var dataAuthTK = localStorage.getItem('userDataCRP-AuthTk');

    // setTimeout(function() {
    //   notification['success']({
    //     message: 'Nova mensagem!',
    //     description: 'Você tem uma nova mensagem!',
    //   });
    // }, 3000);

    /**
     * Verifica Se o usuário esta Logado
     * Caso não executa logout()
     */

    if (dataAuthTK === null) {
      this.logout();
    }

    var dataCRP = localStorage.getItem('userDataCRP-Polo');
    var api = this.global.baseUrl + 'usuario/loginPolo/';

    this.setState({ isLoading: true });
    if (dataCRP !== '' && this.logInfo !== null) {
      axios
        .get(api + this.logInfo['id'], {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then(async (result) => {
          const menuComunicadaos = result.data.menuPrincipal.filter(
            (x) => x.descricao === 'Comunicados'
          );
          const listMenuFilho = [];
          menuComunicadaos[0].menuFilho.map((x) => {
            x.id = listComunicados(x);
            listMenuFilho.push(x);
          });
          menuComunicadaos[0].menuFilho = listMenuFilho
          this.setState(
            {
              // menu: result.data,
              menu: JSON.parse(
                JSON.stringify(result.data)
                  .split('"conta":')
                  .join('"accessCod":')
              ),
              isLoading: false,
            },
            localStorage.setItem(
              'userDataCRP-Perfil',
              JSON.stringify(result.data.perfil[0])
            )
          );
        })
        .catch((error) =>
          this.setState({
            error,
            isLoading: false,
          })
        );
    }

    if (!localStorage.getItem('userDataCRP')) {
      this.setState({ redirect: true });
    }

    // chamando acao do tour inicial se for primeiro acesso
    if (
      this.state.primeiroAcesso === 'S' ||
      this.state.primeiroAcesso !== null
    ) {
      $(window).on('load', function () {
        $('#tour-inicial').trigger('click');
      });
      localStorage.setItem('primeiroAcesso', JSON.stringify('N'));
      this.setState({ primeiroAcesso: 'N' });
      // document.getElementById("tour-inicial").click();
    }

    // fechando janela e zerando localstorage
    // window.addEventListener("beforeunload", (ev) => {
    //     ev.preventDefault()
    //     if(localStorage.getItem('userDataCRP-Lembrar') !== 'false'){
    //         localStorage.setItem("userDataCRP","");
    //         localStorage.setItem("userDataCRP-Login","");
    //         localStorage.setItem("userDataCRP-Polo","");
    //         localStorage.setItem("userDataCRP-Polos","");
    //         localStorage.setItem("userDataCRP-AuthTk","");
    //         localStorage.clear();
    //     }
    // });
  }

  componentDidMount() {
    window.addEventListener('keyup', this.keyHandling);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.keyHandling);
  }

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = (liberaAdmin) => {
    this.configTour(liberaAdmin);
    this.setState({ isTourOpen: true });
  };

  redirecionar() {
    const primeiroAcesso = JSON.parse(
      localStorage.getItem('userDataCRP-Login')
    );
    let bloqueio = false;

    if (primeiroAcesso) {
      if (!bloqueio) {
        // eslint-disable-next-line
        primeiroAcesso.map((v, ok) => {
          if (
            v['bloqTelaValidUser'] === 'S' ||
            v['usuarioPortal']['primeiroAcesso'] === 'S'
          ) {
            bloqueio = true;
          }
        });
      }
      return bloqueio;
    }
  }


  configTour(liberaAdmin) {
    tourConfig = [];

    tourConfig.push(
      {
        selector: '[data-tut="menu_prisma"]',
        content: () => (
          <p>
            Este é o menu <strong>principal</strong>.<br />
            Acesso completo a tudo que for liberado ao seu usuário. <br />
            Sempre teremos novidades! Então, fique atento: algo novo sempre pode
            aparecer por aqui!
          </p>
        ),
      },
      {
        selector: '[data-tut="menu_usuario"]',
        content: () => (
          <p>
            Este é o seu menu pessoal. <br />
            Aqui, você poderá acessar seu painel e executar ações de
            gerenciamento, se for seu caso.
          </p>
        ),
      },
      {
        selector: '[data-tut="carousel_comunicados"]',
        content: () => (
          <p>
            Na tela inicial, temos o carrossel de comunicados recentes -
            referentes a você e seu polo.
          </p>
        ),
      },
      {
        selector: '[data-tut="legenda_comunicados"]',
        content: () => (
          <p>Estas são as legendas de criticidade dos comunicados!</p>
        ),
      },
      {
        selector: '[data-tut="agenda"]',
        content: () => (
          <p>Aqui você pode acompanhar toda a sua agenda do dia!</p>
        ),
      }
    );

    if (liberaAdmin) {
      return;
    } else {
      tourConfig.push({
        selector: '[data-tut="menu_10"]',
        // content: 'Este é o menu usuários.',
        content: () => (
          <p>
            Este é o menu <strong>usuários</strong>.<br />
            Acesse aqui para gerenciar os usuários de seu polo.
          </p>
        ),
      });
    }
  }

  sorter(a, b) {
    if (a.nome.nome > b.nome.nome) return 1;
    if (a.nome.nome < b.nome.nome) return -1;
    return 0;
  }






  render() {
    const { isTourOpen } = this.state;
    localStorage.setItem('userDataCRP-Perfil', '');
    if (this.state && this.state.menu && this.state.menu.perfil[0]) {
      localStorage.setItem(
        'userDataCRP-Perfil',
        JSON.stringify(this.state.menu.perfil[0])
      );
    }

    // variaveis do tour
    const accentColor = '#0076D1';
    const statusBloqueio = this.redirecionar();

    // se logout ou inacessivel
    if (this.state.redirect) {
      return <Redirect to={'/login'} />;
    }

    if (statusBloqueio) {
      // return <Redirect to={'/polos'} />;
    }

    // se admin / gestor, para exibir painel administrativo
    let liberaAdmin = false;

    const liberaMenuGeraDoc = (() => {
      const ID_PERFIL_SUPER_ADMIN = 999;

      return this.state?.menu?.perfil?.[0] === ID_PERFIL_SUPER_ADMIN
    })()

    if (
      (this.state && this.state.menu && this.state.menu.perfil[0] === 1) ||
      (this.state && this.state.menu && this.state.menu.perfil[0] === 999) ||
      (this.state && this.state.menu && this.state.menu.perfil[0] === 503)
    ) {
      liberaAdmin = true;
    } else {
      liberaAdmin = false;
    }


    var shortText = this.logInfo.usuarioPortal.pessoa['nome'];
    if (this.logInfo.usuarioPortal.pessoa['nome'].length >= 18) {
      shortText = this.logInfo.usuarioPortal.pessoa['nome']
        .substring(0, 18)
        .concat('...');
    }


    return (
      <>
       <PersonifiedBanner />
        <nav id="navbar" className="navbar has-shadow is-spaced">

        <div className="container-header is-fullhd" data-tut={'menu_prisma'}>

          <div className="navbar-brand">
            <a
              className="navbar-item is-centered"
              href="/home"
              title={'Seja bem vindo ao Portal Prisma'}
            >
              <img
                className="logoKrotonCRP"
                src={crp}
                alt="Prisma - Kroton"
                height="52"
              />
              {/*<img className="logoKroton" src={logo} alt="Unopar" height="52" />*/}
            </a>

            <div
              className={
                this.state.isActive
                  ? 'navbar-burger burger is-active'
                  : 'navbar-burger burger'
              }
              data-target="navbarExampleTransparentExample"
              onClick={this.toggleNav}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div
            id="navbarBurger"
            key="navbarBurger"
            className={
              this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'
            }
          >
            <div className="navbar-start" key="novoMenuCRPDiv">
              {this.state && this.state.menu && (
                <Menu key="novoMenuCRP" props={this.state.menu} />
              )}
              {liberaAdmin && (
                <a
                  className="navbar-item is-hidden-desktop"
                  data-tut={'menu_admin'}
                  href={'/administracao'}
                  key={'linkMenuPai-admin'}
                  title={'Administração'}
                >
                  Administração
                </a>
              )}
            </div>
            <div className="navbar-end" key="menu">
              {/* futuras liberacoes */}
              {/*<a className="navbar-item menuIcones" href="./home">*/}
              {/*<FontAwesomeIcon icon={["fas", "search"]} />*/}
              {/*</a>*/}
              {window.location.pathname.split('/')[1] === 'home' && (
                //restringido à home pois algumas pages internas causam erro nesse componente
                <div data-tut={'menu_usuario'}>
                  <div className="navbar-item">
                    <MensagensImportantes />
                  </div>
                </div>
              )}
              <span
                className="has-text-danger navbar-item menuIcones is-hidden-desktop is-hidden-widescreen is-hidden-fullhd"
                title="Sair"
                onClick={this.logout}
              >
                Sair
              </span>
              {/* se mais de um polo, aparece select para escolha */}
              {this.listaPolos !== null && (
                <div className="field" key="selectListaPolos">
                  <div className="control">
                    <div className="select is-info is-fullwidth selectPoloHeader">
                      <select
                        name=""
                        id=""
                        onChange={this.onChangePolo}
                        value={this.poloEscolhido}
                        disabled={this.isDisabled}
                      >
                        <option value="0">Selecione outro polo</option>
                        {this.listaPolos.sort(this.sorter).map(
                          (pololst) =>
                            pololst.id !== null && (
                              <option
                                key={'poloList' + pololst.id.id}
                                value={pololst.id.id}
                              >
                                {pololst.id.nome}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="acessoUsuario is-hidden-touch"
                data-tut={'menu_usuario'}
              >
                <div className="navbar-item has-dropdown is-hoverable">
                  <span
                    className="navbar-link is-hidden-touch navbar-avatar"
                    title="Menu do usuário"
                  >
                    {/* <img
                      className="imagemAvatar"
                      src={avatar}
                      width="30"
                      alt={this.logInfo.usuarioPortal.pessoa['nome']}
                    /> */}
                    <FontAwesomeIcon icon={['fas', 'user-circle']} />
                  </span>
                  <div
                    className="navbar-dropdown is-right"
                    style={{ 'backgroundColor': 'rgb(233, 248, 247)' }}
                  >
                    <a
                      className="navbar-item has-text-centered headerNome"
                      href="/home"
                      title={
                        'Bem vindo, ' +
                        this.logInfo.usuarioPortal.pessoa['nome']
                      }
                    >
                      <FontAwesomeIcon icon={['far', 'user-circle']} /> &nbsp;{' '}
                      {this.logInfo.usuarioPortal.pessoa['nome']}
                    </a>
                    <span
                      onClick={() => this.openTour(liberaAdmin)}
                      id="tour-inicial"
                      className="navbar-item has-text-centered"
                      title={'Faça um tour inicial e confira as novidades'}
                    >
                      <FontAwesomeIcon icon={['fas', 'map-marked-alt']} />{' '}
                      &nbsp; Tour inicial
                    </span>
                    <a
                      id="meus-dados"
                      className="navbar-item has-text-centered"
                      title={'Meus Dados'}
                      href='/meusdados'
                    >
                      <Icon type="user" />{' '}
                      &nbsp; Meus Dados
                    </a>
                    {liberaAdmin && (
                      <div>
                        <a
                          className="navbar-item"
                          href="/administracao"
                          title={'Acesse o painel administrativo'}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={['fas', 'cog']} />
                          </span>{' '}
                          &nbsp; Administração
                        </a>
                      </div>
                    )}
                    {liberaMenuGeraDoc && (<a
                          className="navbar-item"
                          title={'Acesse a geração de documentos'}
                          href={'/geradoc/basePolos'}
                        >
                          <span className="icon">
                          <FontAwesomeIcon icon={['fas', 'file']} />
                        </span>{' '}
                        &nbsp; Geradora
                        </a> )}
                    <div>
                      <a
                        className="navbar-item"
                        href="/faq"
                        title={'Acesse o FAQ'}
                      >
                        <span className="icon">
                          <FontAwesomeIcon icon={['fas', 'question']} />
                        </span>{' '}
                        &nbsp; FAQ
                      </a>
                    </div>
                    <span
                      className="navbar-item"
                      onClick={this.logout}
                      title={'Faça o logout do portal'}
                    >
                      <span className="icon">
                        <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                      </span>{' '}
                      &nbsp; Sair
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* aplicacao do tour */}
        <Tour
          key="steps"
          onRequestClose={this.closeTour}
          steps={tourConfig}
          isOpen={isTourOpen}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={accentColor}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          updateDelay={3}
        />
      </nav>
      </>

    );
  }
}

let tourConfig = [];

export default withRouter(Header);
