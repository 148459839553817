import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Form, Input, Select, Upload, Button, Icon } from 'antd';
import { ToolbarDraft } from 'services/Gerais';
import api from 'services/api';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { changeComunicado } from 'store/actions/Administrativo/Comunicado/ComunicadoActions';
import Swal from 'sweetalert2';
import { alterarComunicado } from 'store/actions/Administrativo/Comunicado/ComunicadoActions';
import { uploadToS3 } from 'services/uploadS3/s3Service';

const { Option } = Select;

const ComunicadoConteudo = ({ formm }) => {
  const { getFieldDecorator, setFieldsValue } = formm;
  let { DadosComunicado, DadosIniciaisComunicado } = useSelector(
    (state) => state.Comunicado
  );
  const dispatch = useDispatch();
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [editorResume, setEditorResume] = useState(EditorState.createEmpty());
  const [novaUrlSalva, setNovaUrlSalva] = useState('');

  useEffect(() => {
    DadosComunicado.comunicado.area.id = 1;
    const conteudo =
      DadosComunicado &&
      DadosComunicado.comunicado &&
      DadosComunicado.comunicado.conteudoNovo
        ? DadosComunicado.comunicado.conteudoNovo
        : '';
    const conteudoResume =
      DadosComunicado &&
      DadosComunicado.comunicado &&
      DadosComunicado.comunicado.resumoNovo
        ? DadosComunicado.comunicado.resumoNovo
        : '';
    const blocksFromHTML = htmlToDraft(conteudo);
    const blocksFromHTMLResume = htmlToDraft(conteudoResume);

    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const contentStateResume = ContentState.createFromBlockArray(
      blocksFromHTMLResume.contentBlocks,
      blocksFromHTMLResume.entityMap
    );

    setEditor(EditorState.createWithContent(contentState));
    setEditorResume(EditorState.createWithContent(contentStateResume));
    // eslint-disable-next-line
  }, []);

  function onSearch(val) {
    console.log('search:', val);
  }

  function editorChange(editorState) {
    setEditor(editorState);
    setFieldsValue({ conteudo: 'conteudoOk' });
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          comunicado: {
            ...DadosComunicado.comunicado,
            conteudoNovo: draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            ),
          },
        },
      })
    );
  }

  function editorChangeResume(editorState) {
    setEditorResume(editorState);
    setFieldsValue({ conteudo: 'conteudoOk' });
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          comunicado: {
            ...DadosComunicado.comunicado,
            resumoNovo: draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            ),
          },
        },
      })
    );
  }

  async function uploadImageCallBack(file) {
    try {
      let dataImage = new FormData();
      dataImage.append('file', file);

      const res = await uploadToS3(dataImage);

      return {
        data: {
          link: res.data.link,
        },
      };
    } catch (error) {}
  }

  async function anexarArquivosRequest({ file, onSuccess }) {
    try {
      let dataFile = new FormData();
      dataFile.append('file', file);
      const res = await api.post(`comunicado/comunicadoUploadFile`, dataFile, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (res && res.data.link) {
        setNovaUrlSalva(res.data.link);
        setTimeout(() => {
          onSuccess('ok');
        }, 0);
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao anexar arquivo!',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  }

  function anexarArquivosOnChange(info) {
    let listaAtual = [...info.fileList];

    const listaAtualComUrl = listaAtual.map((file) => {
      if (file.response && novaUrlSalva && !file.url) {
        file.url = novaUrlSalva;
      }
      return file;
    });
    dispatch(
      changeComunicado({
        prop: 'DadosComunicado',
        value: {
          ...DadosComunicado,
          comunicado: {
            ...DadosComunicado.comunicado,
            anexos: JSON.stringify(listaAtualComUrl),
          },
        },
      })
    );
  }

  async function removerArquivo(info) {
    const filePath =
      'comunicados' +
      info.url.split('comunicados').pop() +
      `&comunicadoId=${DadosComunicado.comunicado.id}` +
      `&nome=${info.name}`;
    try {
      const res = await api.post(`comunicado/comunicadoDeleteFile`, filePath);
      /*dispatch(
        alterarComunicado(DadosComunicado, DadosComunicado.comunicado.id)
      );*/
      Swal.fire({
        title: 'Exclusão de anexo',
        text: 'Anexo excluido com sucesso',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
      console.log(res.data);
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao excluir arquivo!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24} className="gutter-row">
          <Form.Item label="Titulo" size="large">
            {getFieldDecorator('titulo', {
              initialValue:
                DadosComunicado &&
                DadosComunicado.comunicado &&
                DadosComunicado.comunicado.titulo,
              rules: [
                {
                  required: true,
                  message: 'Por favor insira uma titulo',
                },
              ],
            })(
              <Input
                placeholder="Titulo"
                onChange={(e) =>
                  dispatch(
                    changeComunicado({
                      prop: 'DadosComunicado',
                      value: {
                        ...DadosComunicado,
                        comunicado: {
                          ...DadosComunicado.comunicado,
                          titulo: e.target.value.trim(),
                        },
                      },
                    })
                  )
                }
                size="large"
                onBlur={(e) => (e.target.value = e.target.value.trim())}
                maxLength={100}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Conteúdo do comunicado" size="large">
            {getFieldDecorator('conteudoNovo', {
              initialValue:
                DadosComunicado &&
                DadosComunicado.comunicado &&
                DadosComunicado.comunicado.conteudoNovo,
              rules: [
                {
                  required: true,
                  message: 'Por favor insira um conteudo',
                },
              ],
            })(
              <>
                <Editor
                  editorState={editor}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={editorChange}
                  // defaultEditorState={DadosComunicado.comunicado.conteudo}
                  localization={{ locale: 'pt' }}
                  toolbar={{
                    ToolbarDraft,
                    image: {
                      className: undefined,
                      urlEnabled: true,
                      component: undefined,
                      popupClassName: undefined,
                      alignmentEnabled: true,
                      uploadEnabled: true,
                      uploadCallback: uploadImageCallBack,
                      previewImage: true,
                      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
                      alt: { present: true, mandatory: false },
                      defaultSize: {
                        height: 'auto',
                        width: 'auto',
                      },
                    },
                  }}
                />
                <textarea
                  className="textarea is-hidden"
                  style={{ border: '1px solid #F1F1F1' }}
                  value={draftToHtml(
                    convertToRaw(
                      editor &&
                        editor.getCurrentContent(
                          DadosComunicado.comunicado.conteudoNovo
                        )
                    )
                  )}
                  readOnly
                  required
                />
              </>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Resumo" size="large">
            {getFieldDecorator('resumoNovo', {
              initialValue:
                DadosComunicado &&
                DadosComunicado.comunicado &&
                DadosComunicado.comunicado.resumoNovo,
              rules: [
                {
                  required: true,
                  message: 'Por favor insira um resumo',
                },
              ],
            })(
              <>
                <Editor
                  editorState={editorResume}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={editorChangeResume}
                  // defaultEditorState={DadosComunicado.comunicado.conteudo}
                  localization={{ locale: 'pt' }}
                  toolbar={{
                    ToolbarDraft,
                    image: {
                      className: undefined,
                      urlEnabled: false,
                      component: undefined,
                      popupClassName: undefined,
                      alignmentEnabled: false,
                      uploadEnabled: false,
                      uploadCallback: uploadImageCallBack,
                      previewImage: false,
                      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: 'auto',
                        width: 'auto',
                      },
                    },
                  }}
                />
                <textarea
                  className="textarea is-hidden"
                  style={{ border: '1px solid #F1F1F1' }}
                  value={draftToHtml(
                    convertToRaw(
                      editor &&
                        editor.getCurrentContent(
                          DadosComunicado.comunicado.resumoNovo
                        )
                    )
                  )}
                  readOnly
                  required
                />
              </>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="notification is-warning">
            <p className=" margin-bottom-none">
              <Icon type="warning" className="alert-icon" />
              {'   '}O tamanho maximo do arquivo de upload é de 10MB.
            </p>
          </div>
          <Form.Item label="Arquivos anexos" size="large">
            <Upload
              customRequest={anexarArquivosRequest}
              onChange={anexarArquivosOnChange}
              fileList={
                DadosComunicado.comunicado.anexos
                  ? JSON.parse(DadosComunicado.comunicado.anexos)
                  : null
              }
              onRemove={removerArquivo}
              showUploadList={{ showRemoveIcon: true, showDownloadIcon: false }}
            >
              <Button>Anexar arquivo</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Form.create()(ComunicadoConteudo);
