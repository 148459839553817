import _debounce from 'lodash/debounce';
import { useState, useCallback } from 'react';
import Swal from 'sweetalert2';
import { getFaqSearch } from 'services/faq';

const useFaq = () => {
  const [searchValue, setSearchValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [active, setActive] = useState({ questions: true, categories: false });
  const handleOnSearch = async (value) => {
    try {
      const { data, status } = await getFaqSearch(value);
      if (status === 200) {
        setOptions(data);
      }
    } catch (error) {
      Swal.fire({
        text: 'Ocorreu um problema ao buscar os dados, tente novamente',
      });
    }
  };

  const onChange = (value) => {
    if (value && value.length) {
      setSearchValue([value[0]]);
      return;
    }
    setOptions([]);
    setSearchValue([]);
  };

  const debounceSearch = useCallback(_debounce(handleOnSearch, 1000), []);

  const perfil = localStorage.getItem('userDataCRP-Perfil');
  const perfilAdmin = ['1', '999', '503']; // admin e super admin

  const admin = perfilAdmin.includes(perfil);

  return {
    debounceSearch,
    onChange,
    searchValue,
    setActive,
    active,
    options,
    admin,
  };
};

export { useFaq };
