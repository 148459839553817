import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
} from 'antd';

import { useForm, Controller } from 'react-hook-form';

import { tableColumns } from './FiltersTableProps';
import { FiltersController } from './FiltersTableController';
import { Container, ButtonSubmit } from './styled';

const { Option } = Select;

const FiltersTable = () => {
  const { handleSubmit, control } = useForm();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);

  const props = {
    state:{
      data: {data, setData},
      loading: {loading, setLoading}
    }
  }

  const filtersTableController = new FiltersController(props);

  useEffect(() => {
    filtersTableController.hadleInitData()
  }, [])

  return (
    <Container key="HeaderSearchPortais">
      <Form
        onSubmit={handleSubmit((e) => filtersTableController.handleSubmit(e))}
        style={{ marginTop: 20 }}
      >
        <Row gutter={16}>
        <Col xs={24} sm={24} md={11}>
          <Form.Item label="Descricao" size="large">
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <Input {...field} data-testid="teste-titulo" placeholder="Descrição:" size="large" disabled={loading}/>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={10}>
          <Form.Item label="Observação" size="large">
              <Controller
                name="observacao"
                control={control}
                render={({ field }) => (
                  <Input {...field} data-testid="teste-titulo" placeholder="Observação:" size="large" disabled={loading}/>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={3} className="submitContainer">
            <Button
            htmlType="submit" loading={loading}
              style={{
                'background-color': '#52c41a',
                border: 'none',
                paddingLeft: "10",
                paddingRight: "10",
                width:"100%",
                flex: 1,
              }}
              type="primary"
              size='large'
            >
              Pesquisar
            </Button>
          </Col>
        </Row>

      </Form>
      <Table
        dataSource={data}
        loading={loading}
        columns={tableColumns}
        size="small"
      />
    </Container>
  );
};

export default FiltersTable;
