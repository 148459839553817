import styled from 'styled-components';

export const SystemDataContainer = styled.div`
  margin-bottom: 24px;
  div {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }

  p {
    margin: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  column-gap: 8px;
  row-gap: 24px;
  width: 100%;
  margin-bottom: 24px;
`;

export const Line = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;
