import "core-js/es6/map";
import "core-js/es6/set";
import "raf/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import  { reduxStore }   from "./store/reducers";
import "./config/config"
const supportsHistory = "pushState" in window.history;

ReactDOM.render(
  <BrowserRouter forceRefresh={!supportsHistory} key="browserRouter">
    <Provider store={reduxStore}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();