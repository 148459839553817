import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  Select,
  Table,
  Card,
  Button,
  Icon,
  AutoComplete,
  Divider,
  Input,
  Modal,
  DatePicker,
} from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SistemaCarousel from '../../components/SistemasCarousel';
import { ShareAltOutlined } from '@ant-design/icons';
import handshakeLogo from '../../img/handshake.png';
import { IconContainer, ImgLogo } from './styles';
import pLogo from '../../img/letter-p.png';
import revenueLogo from '../../img/revenue.png';
import { deletePerfil } from '../../components/Usuario/controller';
import {
  changeUsuarioPessoa,
  addLstPoloCommand,
  removeLstPoloCommand,
  changeLstPoloCommand,
  changeUsuarioPortal,
  getSetores,
  changeUsuario,
  getPolosByCanal,
  changeDadoPerfil,
} from '../../store/actions';
import '../../styles/components/Usuario.css';
import Swal from 'sweetalert2';
import { $, axios } from '../../services/Gerais';
import { getActiveFocalPoints } from '../../services/focalPoint';
import './style.css';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import e from 'cors';

const { Meta } = Card;
const { Option } = Select;
let hasFocalPoint;

function renderOption(item) {
  return (
    <Option key={item.id} text={item.nome}>
      {item['nome'] ? item.nome : item.descricao}
    </Option>
  );
}

async function getPontosFocais() {
  return getActiveFocalPoints().then((pontosFocais) => {
    return pontosFocais;
  });
}

let validaStringPolo = false;
let validaStringSetor = false;
class SessaoPerfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botaoNovaLinhaDisable: true,
      columns: [],
      perfis: '',
      disableds: [],
      defaultExpandAllRows: false,
      expandedRowKeys: [],
      checkNick: false,
      poloDinamico: [],
      listPoloDinamico: [],
      validIsNumero: true,
      getPolos: {},
      showModal: false,
      funcaoSelecionado: {},
      index: 0,
      isGN: false,
      isOpen: false,
      modal: {},
      record: {},
      pontoFocalDescricao: '',
      pontosFocais: [],
      isEmployee: false,
    };
    this.addLinhaPerfil = this.addLinhaPerfil.bind(this);
    this.deletaLinha = this.deletaLinha.bind(this);
    this.changeSelectPerfil = this.changeSelectPerfil.bind(this);
    this.changeSelectPolo = this.changeSelectPolo.bind(this);
    this.changeSelectSetor = this.changeSelectSetor.bind(this);
    this.changeSelectFuncao = this.changeSelectFuncao.bind(this);
    this.changePontoFocal = this.changePontoFocal.bind(this);
    this.validaAdmCorporativo = this.validaAdmCorporativo.bind(this);
    this.habilietaEDesabilitaSelects =
    this.habilietaEDesabilitaSelects.bind(this);
    this.buscaPolo = this.buscaPolo.bind(this);
    this.limpaCampos = this.limpaCampos.bind(this);
    this.poloProprio = '';
    this.baseUrl = JSON.parse(localStorage.getItem('cnfgDataCRP')).baseUrl;
  }

  componentDidMount() {
    let disabled = [];
    let expandedRowKeys = [];
    let lstUsuarioPoloCommand = this.props.usuario.lstUsuarioPoloCommand;
    this.setState({ isGN: this.props.perfilID == 501 || this.props.perfilID == 506 ? true : false });

    getPontosFocais().then((result) => {
      this.setState({ pontosFocais: result });
    });

    function findFocalPoint() {
      if(lstUsuarioPoloCommand.length > 0 && lstUsuarioPoloCommand[0].setores.length > 0) {
        return lstUsuarioPoloCommand.find((item) => {
          return item.pontoFocal.id !== 7;
        });
      }
    }

    hasFocalPoint = findFocalPoint();

    const userDataPerfil = this.props.perfilID;
    const userData = JSON.parse(localStorage.getItem('userDataCRP'));

    if (this.props.isEdit && lstUsuarioPoloCommand) {
      this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: false });

      lstUsuarioPoloCommand.map((item) => {
        let botoesHabilitados = {
          index: item.key,
          polo: false,
          setor: false,
          funcao: false,
        };

        if (item.lstUsuarioPoloPerfil) {
          if (
            item.lstUsuarioPoloPerfil[0].perfil.id === 1 ||
            item.lstUsuarioPoloPerfil[0].perfil.id === 2 ||
            item.lstUsuarioPoloPerfil[0].perfil.id === 999 ||
            item.lstUsuarioPoloPerfil[0].perfil.id === 502 ||
            item.lstUsuarioPoloPerfil[0].perfil.id === 503
          ) {
            botoesHabilitados.polo = true;
            // this.setState({ botaoNovaLinhaDisable: true });
            if (item.lstUsuarioPoloPerfil[0].perfil.id != 2) {
              this.props.changeUsuario({
                prop: 'botaoNovaLinhaDisable',
                value: true,
              });
            }
          }
        }
        disabled.push(botoesHabilitados);
        expandedRowKeys.push(item.key);
        return botoesHabilitados;
      });
    } else {
      this.buscaPolo('');
      lstUsuarioPoloCommand.forEach((item, index) => {
        let key = `${index}_${new Date().getTime()}`;
        disabled.push({ index: key, polo: true, setor: true, funcao: true });
        expandedRowKeys.push(key);
        item.key = key;
      });
    }

    this.setState({ disableds: disabled, expandedRowKeys: expandedRowKeys });
    let perfisSemAdmCorporativo = Object.assign(
      {},
      this.props.dadosPerfil.Perfil
    );

    if (userDataPerfil != 999 && userDataPerfil != 1) {
      delete perfisSemAdmCorporativo[999];
      delete perfisSemAdmCorporativo[1];
      delete perfisSemAdmCorporativo[2];
      delete perfisSemAdmCorporativo[500];
      delete perfisSemAdmCorporativo[501];
      delete perfisSemAdmCorporativo[502];
      delete perfisSemAdmCorporativo[503];
      delete perfisSemAdmCorporativo[380];
      delete perfisSemAdmCorporativo[504];
      delete perfisSemAdmCorporativo[372];
      delete perfisSemAdmCorporativo[371];
      delete perfisSemAdmCorporativo[506];
    }

    if (userDataPerfil != 376 || userDataPerfil != 59) {
      if (userData.usuarioPortal.funcionario != 'S') {
        delete perfisSemAdmCorporativo[505];
      }
    }

    if (userDataPerfil == 500 || userDataPerfil == 370) {
      delete perfisSemAdmCorporativo[376];
      delete perfisSemAdmCorporativo[370];
    }

    if (userDataPerfil == 370) {
      delete perfisSemAdmCorporativo[63];
    }

    if (userDataPerfil == 1) {
      delete perfisSemAdmCorporativo[999];
    }

    this.setState({
      columns: [
        {
          title: 'Perfil de usuário',
          dataIndex: 'perfilUsuario',
          key: 'perfilUsuario',
          width: 200,
          render: (text, reacord, index) => (
            <Form.Item size="large">
              {this.props.form.getFieldDecorator(`perfil_${reacord.key}`, {
                initialValue: reacord.lstUsuarioPoloPerfil[0].perfil.descricao,
                setFieldsValue:
                  reacord.lstUsuarioPoloPerfil[0].perfil.descricao,
                rules: [
                  {
                    required: false,
                  },
                  {
                    validator:
                      reacord.lstUsuarioPoloPerfil[0].perfil.descricao ===
                      undefined
                        ? this.validaIsNumero
                        : null,
                    message: ' Selecione um item',
                  },
                ],
              })(
                this.props.usuario.lstUsuarioPoloCommand.length === 1 &&
                  !this.props.isTelaPolo ? (
                  <AutoComplete
                    dataSource={Object.keys(perfisSemAdmCorporativo).map(
                      (key) => {
                        return (
                          <Option key={[key]}>
                            {perfisSemAdmCorporativo[key]}
                          </Option>
                        );
                      }
                    )}
                    // style={{ width: 200 }}
                    onChange={(e) => this.changeSelectPerfil(e, reacord, index)}
                    placeholder="Selecione perfil"
                    disabled={
                      reacord.lstUsuarioPoloPerfil &&
                      reacord.lstUsuarioPoloPerfil[0].perfil &&
                      reacord.lstUsuarioPoloPerfil[0].perfil.descricao
                    }
                  />
                ) : (
                  <AutoComplete
                    dataSource={Object.keys(perfisSemAdmCorporativo).map(
                      (key) => {
                        return (
                          <Option key={[key]}>
                            {perfisSemAdmCorporativo[key]}
                          </Option>
                        );
                      }
                    )}
                    onChange={(e) => this.changeSelectPerfil(e, reacord, index)}
                    placeholder="Selecione perfil"
                    disabled={
                      reacord.lstUsuarioPoloPerfil &&
                      reacord.lstUsuarioPoloPerfil[0].perfil &&
                      reacord.lstUsuarioPoloPerfil[0].perfil.descricao
                    }
                  />
                )
              )}
            </Form.Item>
          ),
        },
        {
          title: 'Polo',
          dataIndex: 'polo',
          key: 'polo',
          width: 500,
          render: (text, reacord, index) => {
            const poloJson = reacord?.usuarioPolo?.polo;
            return (
              <div
                style={{
                  display: 'flex',
                  columnGap: '14px',
                  alignItems: 'center',
                }}
              >
                <Form.Item style={{ width: '100%', marginTop: '-4px' }}>
                  {this.props.form.getFieldDecorator(`polo_${reacord.key}`, {
                    initialValue:
                      reacord.usuarioPolo.polo && reacord.usuarioPolo.polo.nome,
                    setFieldsValue:
                      reacord.usuarioPolo.polo && reacord.usuarioPolo.polo.nome,
                    rules: [
                      {
                        required: this.state.checkNick,
                        message: 'Digite nome do polo',
                      },
                      {
                        validator: (rule, value, callback) => {
                          try {
                            if (
                              parseInt(
                                reacord.lstUsuarioPoloPerfil[0].perfil.id
                              ) === 1 ||
                              parseInt(
                                reacord.lstUsuarioPoloPerfil[0].perfil.id
                              ) === 2 ||
                              parseInt(
                                reacord.lstUsuarioPoloPerfil[0].perfil.id
                              ) === 999 ||
                              parseInt(
                                reacord.lstUsuarioPoloPerfil[0].perfil.id
                              ) === 502 ||
                              parseInt(
                                reacord.lstUsuarioPoloPerfil[0].perfil.id
                              ) === 503
                            ) {
                              callback();
                              return;
                            } else {
                              if (validaStringPolo) {
                                throw new Error('');
                              } else {
                                callback();
                              }
                            }
                            callback();
                          } catch (err) {
                            callback(err);
                          }
                        },
                        message: ' Selecione um item',
                      },
                    ],
                  })(
                    <AutoComplete
                      dataSource={
                        this.props.dadosPerfil.poloDinamico &&
                        this.props.dadosPerfil.poloDinamico.map(renderOption)
                      }
                      // dataSource={this.state.poloDinamico.map(renderOption)}
                      onChange={(e) => this.changeSelectPolo(e, reacord, index)}
                      onSearch={this.buscaPolo}
                      disabled={
                        (this.state.disableds.length > 0 &&
                          this.state.disableds[index] &&
                          this.state.disableds[index].polo) ||
                        reacord.usuarioPolo.polo?.nome
                      }
                      placeholder="Pesquise o Polo"
                    />
                  )}
                </Form.Item>
                {(poloJson?.poloProprio === 'N' && (
                  <ImgLogo src={handshakeLogo} />
                )) ||
                  (poloJson?.nomeDnPolo?.includes('REVENUE SHARE') && (
                    <>
                      <IconContainer>
                        <ShareAltOutlined />
                      </IconContainer>
                    </>
                  )) ||
                  (poloJson?.poloProprio === 'S' && <ImgLogo src={pLogo} />)}

                {poloJson?.nomeDnPolo?.includes('REVENUE SHARE') && (
                  <>
                    <ImgLogo src={revenueLogo} />
                  </>
                )}
              </div>
            );
          },
        },
        {
          title: '',
          dataIndex: 'setor',
          key: 'setor',
          width: 0,
          render: (text, reacord, index) => {
            if (reacord.setores.length > 0) {
              reacord.setor.id = reacord.setores[0].id;
              reacord.setor.descricao = reacord.setores[0].descricao;
            }
          },
        },
        {
          title: 'Função',
          dataIndex: 'funcao',
          key: 'funcao',
          width: 180,
          render: (text, reacord, index) => {
            let setorSelecionado = [];
            setorSelecionado =
              reacord.setores &&
              reacord.setores.find(
                (setore) => setore.id === parseInt(reacord.setor.id)
              );
            if (setorSelecionado && setorSelecionado.funcoes.length === 1) {
              reacord.funcao.id = reacord.setores[0].funcoes[0].id;
              reacord.funcao.descricao =
                reacord.setores[0].funcoes[0].descricao;
            }
            let renderInputFuncao = '';
            if (setorSelecionado && setorSelecionado.funcoes.length === 1) {
              renderInputFuncao = (
                <Input
                  type="text"
                  value={
                    reacord.funcao.id > 0
                      ? reacord.funcao.descricao
                      : reacord.setores[0].funcoes[0].descricao
                  }
                  placeholder="Digite a Função"
                  disabled
                />
              );
            } else {
              renderInputFuncao = (
                <AutoComplete
                  dataSource={
                    setorSelecionado &&
                    setorSelecionado.funcoes &&
                    setorSelecionado.funcoes.map(renderOption)
                  }
                  defaultValue={
                    reacord.funcao &&
                    reacord.funcao.lastUpdated &&
                    reacord.setor.descricao &&
                    reacord.funcao.id > 0
                      ? reacord.funcao.descricao
                      : ''
                  }
                  onChange={(e) => this.changeSelectFuncao(e, reacord)}
                  disabled={
                    (this.state.disableds.length > 0 &&
                      this.state.disableds[index] &&
                      this.state.disableds[index].funcao) ||
                    this.state.isGN
                  }
                  placeholder="Digite ou selecione"
                />
              );
            }
            return <Form.Item>{renderInputFuncao}</Form.Item>;
          },
        },
        {
          title: 'Ações',
          dataIndex: '',
          key: 'x',
          width: 130,
          render: (text, record, index) => {
            return (
              <div className="acaoRow">
                <Button
                  title="Gestão de Perfis"
                  type="primary"
                  onClick={() => this.handleModal(index, record)}
                >
                  <Icon type="eye" />
                </Button>

                {this.state.isGN ? (
                  ''
                ) : (
                  <Button
                    title="Deletar Linha"
                    type="danger"
                    onClick={() => this.deletaLinha(record.key, index, record)}
                  >
                    <Icon type="delete" />
                  </Button>
                )}

                <Button
                  title="Sistemas disponíveis para esse perfil"
                  type="primary"
                  onClick={() => this.expandedRowRender(record)}
                >
                  <Icon type="down" />
                </Button>
              </div>
            );
          },
        },
      ],
    });
  }

  validaIsNumero = (rule, value, callback) => {
    try {
      let regra = /^[0-9]+$/;
      if (!value.match(regra)) {
        throw new Error('');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  buscaPolo = (search) => {
    if (search && search.length > 1) {
      let optionsArray = [];
      if (
        this.props.usuario.lstUsuarioPoloCommand[this.state.index].setor.id ===
        13
      ) {
        optionsArray = this.state.listPoloDinamico;
      } else {
        optionsArray = this.props.dadosPerfil.Polos;
      }

      const searchArray =
        optionsArray.length > 0
          ? optionsArray.filter((item) =>
              item.nome.includes(search.toUpperCase())
            )
          : [];

      this.props.changeDadoPerfil({ prop: 'poloDinamico', value: searchArray });
    } else {
      this.props.changeDadoPerfil({ prop: 'poloDinamico', value: [] });
    }
  };

  addLinhaPerfil = () => {
    const index = this.props.usuario.lstUsuarioPoloCommand.length;
    const key = `${index}_${new Date().getTime()}`;

    this.setState({
      // botaoNovaLinhaDisable: true,
      expandedRowKeys: [...this.state.expandedRowKeys, key],
      disableds: [
        ...this.state.disableds,
        { index: key, polo: true, setor: true, funcao: true },
      ],
    });
    this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: true });
    this.props.addLstPoloCommand({ key });
  };

  deletaLinha = (key, index, reacord) => {
    if (this.props.usuario.lstUsuarioPoloCommand.length > 0) {
      // this.setState({ botaoNovaLinhaDisable: false });
      this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: false });
    }
    this.openModal(key, index, this.props.usuario.lstUsuarioPoloCommand[index]);
    this.setState({
      disableds: this.state.disableds.filter((item) => item.index !== key),
      expandedRowKeys: this.state.expandedRowKeys.filter(
        (item) => item.id !== key
      ),
    });

    this.props.removeLstPoloCommand(key);
  };

  openModal = (key, index, reacord) => {
    Swal.fire({
      title: `Inativar?`,
      text: 'Deseja inativar este Perfil do usuário?',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    })
      .then((result) => {
        if (result.isConfirmed === true) {
          deletePerfil(reacord);
          this.deletaLinha(key, index, reacord);
          validaStringPolo = false;
          Swal.close();
        }
      })
      .catch();
  };

  changeSelectPerfil = async (e, reacord, index) => {
    let value = e.target ? e.target.value : e;
    this.limpaCampos(reacord, index, value);

    if (
      value === '1' ||
      value === '2' ||
      value == '999' ||
      value === '502' ||
      value === '503'
    ) {
      validaStringPolo = false;
      this.props.changeDadoPerfil({ prop: 'poloDinamico', value: [] });
      await this.habilietaEDesabilitaSelects(reacord.key, 'polo', true);
      this.changeSelectPolo('', reacord);
      await this.habilietaEDesabilitaSelects(reacord.key, 'setor', false);
    }

    this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: true });

    this.props.changeLstPoloCommand({
      key: reacord.key,
      lstUsuarioPoloPerfil: [
        {
          perfil: {
            id: value,
          },
        },
      ],
    });

    let regra = /^[0-9]+$/;
    if (value.match(regra)) {
      this.props.getSetores(value, reacord.key);
      this.changePontoFocal(7, reacord);
    }

    if (value == 504) {
      this.ampli();
    }
  };

  limpaCampos = async (reacord, index, value) => {
    await this.habilietaEDesabilitaSelects(reacord.key, 'polo', false);
    await this.habilietaEDesabilitaSelects(reacord.key, 'setor', true);
    await this.habilietaEDesabilitaSelects(reacord.key, 'funcao', true);
    this.changeSelectPolo('', reacord);
    this.changeSelectSetor('', reacord);
    this.changeSelectFuncao('', reacord);
    if (index === 0) {
      // this.setState({
      //   poloDinamico: [],
      // })
      this.props.changeDadoPerfil({ prop: 'poloDinamico', value: [] });
      this.setState(
        {
          checkNick:
            value === '1' ||
            value === '2' ||
            value === '999' ||
            value === '502' ||
            value === '503'
              ? false
              : true,
        },
        () => {
          this.props.form.validateFields([`polo_${reacord.key}`], {
            force: true,
          });
        }
      );
    }

    this.props.form.setFieldsValue({
      [`polo_${reacord.key}`]: '',
      [`setor_${reacord.key}`]: '',
      [`funcao_${reacord.key}`]: '',
    });
  };

  changeSelectPolo = async (e, reacord, index) => {
    let value = e.target ? e.target.value : e;
    let regra = /^[0-9]+$/;
    this.state.index = index;
    if (!value.match(regra)) {
      validaStringPolo = true;
    } else {
      validaStringPolo = false;
    }

    if (this.props.dadosPerfil.poloDinamico.length == 1) {
      this.poloProprio = this.props.dadosPerfil.poloDinamico[0].poloProprio;
    }
    reacord.lstUsuarioPoloPerfil.map((item) => {
      if (item.perfil.id == 370 && !isNaN(parseInt(value))) {
        this.validPoloByPerfilGestor(value, reacord);
      }
    });

    if (value) {
      await this.habilietaEDesabilitaSelects(reacord.key, 'funcao', false);
    }

    if (reacord.setor.id !== '' && reacord.funcao.id !== '') {
      // this.setState({ botaoNovaLinhaDisable: false });
      this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: false });
    }

    if (this.props.isEdit && !reacord.usuarioPolo.polo) {
      // this.setState({ botaoNovaLinhaDisable: false });
      this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: false });
    }

    this.props.changeLstPoloCommand({
      key: reacord.key,
      usuarioPolo: {
        ...reacord.usuarioPolo,
        polo: {
          id: value,
        },
        funcsituacao: this.props.isEdit ? 'A' : 'N',
      },
    });
  };

  changeSelectSetor = (e, reacord, index) => {
    let value = e.target ? e.target.value : e;
    let regra = /^[0-9]+$/;

    if (!value.match(regra)) {
      validaStringSetor = true;
    } else {
      validaStringSetor = false;
    }

    this.props.changeLstPoloCommand({
      key: reacord.key,
      setor: {
        id: parseInt(value),
      },
    });
    if (value) {
      this.habilietaEDesabilitaSelects(reacord.key, 'funcao', false);
      this.changeSelectFuncao('', reacord);
      this.props.form.setFieldsValue({
        [`funcao_${reacord.key}`]: '',
      });
    }
  };

  changeSelectFuncao = (e, reacord) => {
    let value = e.target ? e.target.value : e;
    this.validaAdmCorporativo(reacord, value);
    this.props.changeLstPoloCommand({
      key: reacord.key,
      funcao: {
        id: value,
      },
    });
  };

  changePontoFocal = (e, reacord) => {
    let value = e.target ? e.target.value : e;
    let objPontoFocal = this.state.pontosFocais.find(
      (item) => item.id === parseInt(value)
    );
    reacord.usuarioPolo.pontoFocal = objPontoFocal
    this.props.changeLstPoloCommand({
      key: reacord.key,
      pontoFocal: objPontoFocal,
      usuarioPolo: reacord.usuarioPolo
    });
  };

  changeDistrato = (e, reacord) => {
    reacord.usuarioPolo.dataDistrato = e.format('DD-MM-YYYY');
    this.props.changeLstPoloCommand({
      key: reacord.key,
      usuarioPolo: reacord.usuarioPolo,
    });
  };

  changeCod = (e, reacord) => {
    reacord.usuarioPolo.clieCd = e.target.value;
    this.props.changeLstPoloCommand({
      key: reacord.key,
      usuarioPolo: reacord.usuarioPolo,
    });
  };

  validPoloByPerfilGestor = async (poloId, reacord) => {
    await axios
      .get(this.baseUrl + `polo/getPoloValidGestor/${poloId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then((resp) => {
        if (resp.status == 202) {
          Swal.fire({
            title: `Já existe um usuário com o perfil Gestor Secundário atribuído para este polo, só será  permitido um único gestor secundário para cada Polo`,
            icon: 'warning',
            showConfirmButton: false,
            timer: 3000,
          });
          this.props.changeLstPoloCommand({
            key: reacord.key,
            isValidPolo: true,
          });
          this.render();
        } else {
          this.props.changeLstPoloCommand({
            key: reacord.key,
            isValidPolo: false,
          });
          this.render();
        }
      });
  };

  ampli = async () => {
    await axios
      .get(this.baseUrl + 'polo/getPoloByTipoPolo/A', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then((resp) => {
        this.props.dadosPerfil.poloDinamico = resp.data.Polos;
        this.setState({ listPoloDinamico: resp.data.Polos });
        this.render();
      });
  };

  habilietaEDesabilitaSelects = async (key, prop, value) => {
    let { disableds } = this.state;
    this.setState({
      disableds: disableds.map((item) =>
        item.index === key ? { ...item, [prop]: value } : item
      ),
    });
  };

  validaAdmCorporativo = (reacord, value) => {
    if (
      reacord.lstUsuarioPoloPerfil[0].perfil.id === '1' ||
      reacord.lstUsuarioPoloPerfil[0].perfil.id === '2' ||
      reacord.lstUsuarioPoloPerfil[0].perfil.id === '999' ||
      reacord.lstUsuarioPoloPerfil[0].perfil.id === '502' ||
      reacord.lstUsuarioPoloPerfil[0].perfil.id === '503' ||
      !value
    ) {
      this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: true });
    } else {
      this.props.changeUsuario({ prop: 'botaoNovaLinhaDisable', value: false });
    }

    validaStringPolo = false;
  };

  expandedRowRender = (record) => {
    let setorSelecionado,
      funcaoSelecionado = [];
    if (record.setores.length === 1 && record.setores[0].funcoes.length === 1) {
      record.funcao.id = record.setores[0].funcoes[0].id;
      record.funcao.descricao = record.setores[0].funcoes[0].descricao;
    }
    setorSelecionado =
      record.setores &&
      record.setores.find((setore) => setore.id === parseInt(record.setor.id));
    funcaoSelecionado =
      setorSelecionado &&
      setorSelecionado.funcoes &&
      setorSelecionado.funcoes.find(
        (funcao) => funcao.id === parseInt(record.funcao.id)
      );

    this.setState({
      funcaoSelecionado: funcaoSelecionado,
      showModal: true,
    });
  };

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  handleModal = (index, record) => {
    let pontoFocalDescricao = 'Não';
    if (this.state.isOpen == true) {
      this.setState({ isOpen: false, index: 0 });
    } else {
      pontoFocalDescricao = record.usuarioPolo.pontoFocal.descricao;
      this.poloProprio = record.usuarioPolo.polo.poloProprio;
      this.setState({
        modal: this.props.usuario.lstUsuarioPoloCommand[index],
        index: index,
        record: record,
        pontoFocalDescricao: pontoFocalDescricao,
        isOpen: true,
        isEmployee:
          this.props.usuario.usuarioPortal.funcionario == 'N' ? false : true,
      });
      this.render();
    }

    this.render();
  };

  orderByName = (a, b) => {
    if (a.usuarioPolo.polo?.nome == undefined) return 0;
    if (b.usuarioPolo.polo?.nome == undefined) return 0;
    if (a.usuarioPolo.polo.nome > b.usuarioPolo.polo.nome) return 1;
    if (a.usuarioPolo.polo.nome < b.usuarioPolo.polo.nome) return -1;
    return 0;
  };

  render() {
    const { columns, funcaoSelecionado, showModal } = this.state;
    const { usuario } = this.props;
    const { botaoNovaLinhaDisable } = usuario;
    return (
      <>
        <Row gutter={24}>
          <Col span={24} className="gutter-row">
            {this.state.isGN ? (
              ''
            ) : (
              <Row type="flex">
                <Col>
                  <Button
                    title="Adicionar perfil"
                    type="primary"
                    size="large"
                    disabled={botaoNovaLinhaDisable}
                    onClick={this.addLinhaPerfil}
                    style={{ marginBottom: 16, paddingLeft: 16 }}
                  >
                    Adicionar perfil
                  </Button>
                </Col>
              </Row>
            )}
            {usuario.lstUsuarioPoloCommand.length > 0 && (
              <Table
                loading={this.props.dadosPerfil.carregando}
                columns={columns}
                rowKey="key"
                expandedRowKeys={this.state.expandedRowKeys}
                dataSource={usuario.lstUsuarioPoloCommand.sort(
                  this.orderByName
                )}
                pagination={false}
                footer={() => (
                  <Row type="flex">
                    {this.state.isGN ? (
                      ''
                    ) : (
                      <Col span={3} offset={21}>
                        <Button
                          title="Adicionar perfil"
                          type="primary"
                          size="large"
                          disabled={botaoNovaLinhaDisable}
                          onClick={this.addLinhaPerfil}
                          style={{ marginBottom: 16 }}
                        >
                          Adicionar perfil
                        </Button>
                      </Col>
                    )}
                  </Row>
                )}
              />
            )}
            <Divider />
          </Col>
          <br />
          <div className={showModal ? 'modal is-active' : 'modal'}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">
                  Sistemas disponíveis para esse perfil
                </p>
              </header>
              <section className="modal-card-body">
                <SistemaCarousel
                  sistemas={funcaoSelecionado && funcaoSelecionado.sistemas}
                  // titulo={'Sistemas disponíveis para esse perfil:'}
                  isPortalOff={false}
                  widthCard={150}
                  isLink={true}
                />
              </section>
              <footer className="modal-card-foot">
                <button
                  type="button"
                  className="button voltar-modal"
                  onClick={this.toggleModal.bind(this)}
                >
                  Voltar
                </button>
              </footer>
            </div>
          </div>
        </Row>
        <Modal
          title="Gestão de perfis de acesso"
          visible={this.state.isOpen}
          onOk={this.handleModal}
          onCancel={this.handleModal}
          destroyOnClose={true}
          width={600}
          centered
        >
          <Row style={{ display: 'flex' }}>
            {hasFocalPoint != undefined && (
              <Col className="margin-1-left">
                <span>
                  Este usuário já é ponto focal em:{' '}
                  <b>{hasFocalPoint?.usuarioPolo?.polo?.nome}</b>.
                </span>
              </Col>
            )}
          </Row>
          <Row>
            <Form.Item
              label="Ponto Focal"
              style={{ width: '90%', marginLeft: '5%' }}
            >
              <Col xl={24}>
                <Select
                  placeholder="Selecione..."
                  style={{ width: '100%' }}
                  onChange={(e) => this.changePontoFocal(e, this.state.record)}
                  defaultValue={`Ponto focal: ${this.state.pontoFocalDescricao}`}
                  disabled={this.state.isGN}
                >
                  {hasFocalPoint?.usuarioPolo?.polo?.id ===
                    this.state.record.usuarioPolo?.polo?.id &&
                  this.state.pontosFocais.length > 0 ? (
                    <Option
                      key={this.state.pontosFocais[6].id}
                      text={this.state.pontosFocais[6].descricao}
                    >
                      {this.state.pontosFocais[6]['descricao']
                        ? this.state.pontosFocais[6].descricao
                        : this.state.pontosFocais[6].descricao}
                    </Option>
                  ) : (
                    this.state.pontosFocais.map((item) => {
                      return (
                        <Option key={item.id} text={item.descricao}>
                          {item['descricao'] ? item.descricao : item.descricao}
                        </Option>
                      );
                    })
                  )}
                </Select>
              </Col>
            </Form.Item>
          </Row>
          <Row
            style={{
              display: 'flex',
              visibility:
                this.props.perfilID == 999 || this.props.perfilID == 1
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <Form.Item label="Data Distrato" style={{ width: '45%' }}>
              {this.props.form.getFieldDecorator(`dataDistrato`, {
                initialValue:
                  this.state.modal?.usuarioPolo?.dataDistrato != null
                    ? moment(
                        this.state.modal?.usuarioPolo?.dataDistrato,
                        'DD-MM-YYYY'
                      )
                    : '',
              })(
                <DatePicker
                  size="large"
                  style={{ width: '100%' }}
                  locale={locale}
                  format="DD-MM-YYYY"
                  placeholder={['Data Distrato']}
                  onChange={(e) => this.changeDistrato(e, this.state.record)}
                />
              )}
            </Form.Item>
            <Form.Item
              label="CodClie"
              style={{ marginLeft: '5%', width: '45%' }}
            >
              {this.props.form.getFieldDecorator(`clieCd`, {
                initialValue: this.state.modal?.usuarioPolo?.clieCd,
              })(
                <Input
                  maxLength={10}
                  onChange={(e) => this.changeCod(e, this.state.record)}
                />
              )}
            </Form.Item>
          </Row>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  usuario: store.Usuario,
  dadosPerfil: store.DadosPerfil,
});

export default connect(mapStateToProps, {
  changeUsuarioPessoa,
  addLstPoloCommand,
  removeLstPoloCommand,
  changeLstPoloCommand,
  changeUsuarioPortal,
  getSetores,
  changeUsuario,
  getPolosByCanal,
  changeDadoPerfil,
})(SessaoPerfil);
