import { Form, Input, Select, Button, Table, Col, Row } from 'antd';
import React from 'react';
import { Line, RegisterBox } from './styles';
import { useListFaq } from './hook';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../../../services/Gerais';
import api from '../../../../../services/api';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

const ListFaq = () => {
  const {
    columns,
    filteredData,
    filterData,
    form,
    onChange,
    categoryOptions,
    cleanFields,
  } = useListFaq();

  async function exportCSV() {
    let obj = {
      "relatorioId": 44,
      "lstParametros": [
        {
          "id": 61,
          "nome": "Categoria ID",
          "valor": ''
        },
        {
          "id": 60,
          "nome": "questao",
          "valor": ''
        }
      ]
    }
    const resposta = await api.post('relatorio/exportCsv', obj);

    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Relatório gerado com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
    const BOM = '\uFEFF';
    const relatorioDados = BOM + resposta.data;
    const contentType = resposta.headers['content-type'];
    const filename = resposta.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
      .replace('"', '')
      .replace('"', '');
    const blob = new Blob([relatorioDados], {
      type: contentType,
    });
    saveAs(blob, filename);
  }

  return (
    <>
      <Helmet
        key="TitleAdminFaqList"
        title="FAQ Listagem - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Faq listagem' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'faq-list',
          },
        ]}
      />
      <HeaderAdmin key="HeaderFaqList" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminFaqList" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero key="heroFaq" pageName="FAQ-LIST" titulo="FAQ - Listagem" />
            </div>
          </div>
          <RegisterBox>
            <Button href="/admin/list/categoria" type="primary">
              Categorias
            </Button>
            <Button href="/admin/categoria" type="primary">
              Cadastrar Categoria
            </Button>

            <Button href="/admin/faq" type="primary">
              Cadastrar FAQ
            </Button>
          </RegisterBox>

          <Form style={{ width: '100%' }}>
            <Line>
              <Form.Item style={{ flex: '0.7' }} label="Questão">
                <Input
                  value={form.questao}
                  onChange={(event) => onChange(event.target.value, 'questao')}
                />
              </Form.Item>

              <Form.Item style={{ flex: '0.3' }} label="Categoria">
                <Select
                  value={form.categoria}
                  onChange={(event) => onChange(event, 'categoria')}
                >
                  {categoryOptions.map((item) => (
                    <Select.Option key={String(item.value)}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: '-14px' }}>
                <Button
                  type="primary"
                  style={{
                    'background-color': '#52c41a',
                    border: 'none',
                    marginRight: '8px',
                  }}
                  onClick={() => filterData()}
                >
                  Pesquisar
                </Button>
                <Button type="primary" onClick={cleanFields}>
                  Limpar
                </Button>
                <Button
                  title="Exportar CSV"
                  type="primary"
                  style={{
                    backgroundColor: '#52c41a',
                    border: 'none',
                    marginLeft: '8px',
                  }}
                  onClick={exportCSV}
                >
                  Exportar CSV
                </Button>
              </Form.Item>
            </Line>
          </Form>

          <Table
            loading={false}
            columns={columns}
            dataSource={filteredData}
            size="small"
          />
        </div>
      </section>
      <FooterAdmin key="FooterAdminAtribuiçõesCadastro" />
    </>
  );
};

export default ListFaq;
