import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import readXlsxFile from 'read-excel-file';
import config from 'config/config';
import Swal from 'sweetalert2';
import { notification } from 'antd';
import { moment } from 'services/Gerais';
import 'moment/locale/pt-br';
import {
  exportPDF,
  getCheckins,
  getFiltersReport,
  getPolo,
  getFilter,
  sendReport,
  importMigration,
  getImports,
  getDataImport,
  cancelImport
} from 'services/foca/FocaService';
import { BlobProvider, pdf } from '@react-pdf/renderer';
import TemplateFoca from './pdfFoca';
import { download } from '../Administrador/DocumentGenerationAndSubmission/service';

const useFOCA = () => {
  const userLogged = JSON.parse(localStorage.getItem('userDataCRP'));
  const [pagination, setPagination] = useState({ position: 'bottom' });
  const [search, setSearch] = useState({data: null, status: null, page: 0})
  const [fileList, setFileList] = useState([]);
  const [fileJson, setFileJson] = useState({ data: [] });
  const [fileName, setFileName] = useState('');
  const [migrations, setMigrations] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleImportFile = useCallback(async () => {
    try {
      const errors = [];
      if (!fileJson.data.length > 0 || errors.length > 0) {
        return Swal.fire({
          title: 'Atenção',
          text: `Formato do arquivo ${errors[0]} inválido!`,
        });
      }

      const { data } = await sendReport(fileJson.data);
      return data;
    } catch (error) {}
  }, [fileJson.data]);
  const handleImportMigrationGNs = useCallback(async () => {
    let params = {
      nomeArquivo: fileName,
      migracoesGnsDTOs: fileJson,
      usuarioInclusao: userLogged.usuarioPortal.id
    }
    await importMigration(params);
    await handleGetImport();
  })

  async function handleGetImport() {
    let queryString = [
      `${(search.data == null ? '' : `&data=${search.data}`)}`,
      `${(search.status == null ? '' : `&status=${search.status}`)}`,
      `&page=${search.page === undefined ? 0 : search.page}`,
      `&size=${10}`,
    ].join('');
    const resp = await getImports(2, queryString);
    let paginationObj = {
      total: resp.data.totalElements,
      current: resp.data.number + 1,
    };
    setPagination(paginationObj)
    setMigrations(resp.data.content.sort((a, b) => a.id < b.id ? 1 : -1))
  }

  async function handleCancelImport(id) {
    await cancelImport(id);
    await handleGetImport();
  }

  async function handleDownload(id, fileName) {
    const data = await getDataImport(id);
    await downloadXlsx(data.data, fileName);
  }

  async function downloadXlsx(data, fileName) {
    const XlsxPopulate = require('xlsx-populate');
    const workbook = await XlsxPopulate.fromBlankAsync();
    const sheet = workbook.sheet(0);

    sheet.cell('A1').value('ID');
    sheet.cell('B1').value('FOCA_CHECKIN_ID');
    sheet.cell('C1').value('IMPORTACAO_ID');
    sheet.cell('D1').value('NOVO_GN_ID');
    sheet.cell('E1').value('USUARIO_INCLUSAO');
    sheet.cell('F1').value('LOG_ERRO');

    let i = 2;
    data.forEach((item) => {
      sheet.cell(`${'A' + i}`).value(item.id);
      sheet.cell(`${'B' + i}`).value(item.focaCheckinId);
      sheet.cell(`${'C' + i}`).value(item.importacaoId);
      sheet.cell(`${'D' + i}`).value(item.novoGnId);
      sheet.cell(`${'E' + i}`).value(item.usuarioInclusao);
      sheet.cell(`${'F' + i}`).value(item.logErro);
      i++;
    });

     const blob = await workbook.outputAsync();

    //LINK PRA DOWNLOAD
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${fileName}`;
    a.click();
  }

  function objectToXlsx(data) {
    const rows = [];
    const headers = Object.keys(data[0]);
    rows.push(headers.join(','));
    data.forEach((item, index) => {
      const values = headers.map((header) => {
        const val = item[header];
        return val;
      });
      rows.push(values.join(','));
    });
    return rows.join('\n');
  }

  function changeDate(date) {
    setSearch((state) => ({
      ...state,
      data: (date == null ? null : moment(date).format('DD/MM/YYYY')),
    }));
  }

  function changeStatus(status) {
    setSearch((state) => ({
      ...state,
      status: status,
    }));
  }


  function clear() {
    window.location.reload();
  }

  const uploadProps = {
    name: 'file',
    action: `${config.baseUrl}file/upload`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`,
    },
    accept: '.xlsx',
    maxCount: 1,
    multiple: false,
    onChange(info) {
      const dataArray = [];
      readXlsxFile(info.file.originFileObj).then((rows) => {
        rows.map((item) => {
          if (item[1] === 'CHECKIN_ID') return;
          dataArray.push({
            acao: item[5],
            ativo: validActive(item[14]),
            checkinId: item[1],
            dataConclusao:
              item[11] != null
                ? item[11] instanceof Date
                  ? moment(item[11], 'DD/MM/YYYY')
                      .add(1, 'days')
                      .format('DD/MM/YYYY')
                  : moment(item[11], 'DD/MM/YYYY').format('DD/MM/YYYY')
                : null,
            foca: validFOCA(item[2]),
            ganhoEstimado: item[9],
            ganhoRealizado: item[10],
            id: item[0],
            modalidade: validModalidade(item[4]),
            pilar: validPilar(item[3]),
            prazo:
              item[8] != null
                ? item[8] instanceof Date
                  ? moment(item[8], 'DD/MM/YYYY')
                      .add(1, 'days')
                      .format('DD/MM/YYYY')
                  : moment(item[8], 'DD/MM/YYYY').format('DD/MM/YYYY')
                : null,
            link: item[6],
            responsavel: item[7],
            resultadoAtingido: item[12],
            status: validStatus(item[13]),
          });
          setFileJson({ data: dataArray });
        });
      });
      setFileList(info.fileList);
    },
  };

  const uploadPropsDocs = {
    name: 'file',
    action: `${config.baseUrl}file/upload`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`,
    },
    accept: '.xlsx',
    maxCount: 1,
    multiple: false,
    onChange(info) {
      const dataArray = [];
      readXlsxFile(info.file.originFileObj).then((rows) => {
        rows.map((item) => {
          if(item[0] == "ID_CHECKIN") return
          dataArray.push({
            focaCheckinId: item[0],
            novoGnId: item[1],
          })
        });
        setFileJson(dataArray);
      });
      setFileName(info.file.name)
      setFileList(info.fileList);
    },
  };

  useEffect(() => {
    handleGetImport();
  }, [])

  return {
    handleImportFile,
    handleImportMigrationGNs,
    handleDownload,
    changeDate,
    changeStatus,
    handleGetImport,
    handleCancelImport,
    clear,
    uploadPropsDocs,
    uploadProps,
    migrations,
    pagination,
    search
  };
};
export { useFOCA };


function validFOCA(value, isExport) {
  if (isExport) {
    if(value.toLowerCase() == undefined) return
    switch (value.toLowerCase()) {
      case 'f':
        return 'Financeiro';
      case 'o':
        return 'Operacional' ;
      case 'c':
        return 'Comercial';
      case 'a':
        return 'Academico';
      default:
        return '';
    }
  } else {
    if(value == null) {
      return null
    }
    switch (value.charAt(0).toLowerCase()) {
      case 'f':
        return 'F';
      case 'o':
        return 'O';
      case 'c':
        return 'C';
      case 'a':
        return 'A';
      default:
        return '';
    }
  }
}

function validActive(value, isExport) {
  if (isExport) {
    switch (value.toLowerCase()) {
      case 'n':
        return 'Não';
      case 's':
        return 'Sim';
      default:
        return '';
    }
  } else {
    if(value == null) {
      return null
    }
    switch (value.charAt(0).toLowerCase()) {
      case 'n':
        return 'N';
      case 's':
        return 'S';
      default:
        return '';
    }
  }
}

function validPilar(value, isExport) {
  if (isExport) {
    switch (value.toLowerCase()) {
      case 'v':
        return 'Volume';
      case 'i':
        return 'Inadimplência';
      case 'r':
        return 'Retenção';
      case 'e':
        return 'Estrutura Padrão';
      case 'l':
        return 'LTR';
      case 'o':
        return 'Outros';
      default:
        return '';
    }
  } else {
    if(value == null) {
      return null
    }
    switch (value.charAt(0).toLowerCase()) {
      case 'v':
        return 'V';
      case 'i':
        return 'I';
      case 'r':
        return 'R';
      case 'e':
        return 'E';
      case 'l':
        return 'L';
      case 'o':
        return 'O';
      default:
        return '';
    }
  }
}

function validModalidade(value) {
  if(value == null) {
    return null
  }
  switch (value.charAt(0).toLowerCase()) {
    case 'g':
      return 'Graduação';
    case 'p':
      return 'Pós Graduação';
    case 'e':
      return 'EJA';
    case 'c':
      return 'Cursos Técnicos';
    default:
      return '';
  }
}

function validStatus(value, isExport) {
  if (isExport) {
    switch (value.toLowerCase()) {
      case 'a':
        return 'Em acompanhamento';
      case 'c':
        return 'Concluido';
      case 'n':
        return 'Não Concluido';
      default:
        return '';
    }
  } else {
    if(value == null) {
      return null
    }
    switch (value.charAt(0).toLowerCase()) {
      case 'e':
        return 'A';
      case 'c':
        return 'C';
      case 'n':
        return 'N';
      default:
        return '';
    }
  }
}

const foca = ['Financeiro', 'Operacional', 'Comercial', 'Academico'];
const pilar = [
  'Volume',
  'Inadimplência',
  'Retenção',
  'Estrutura Padrão',
  'LTR',
  'Outros',
];
const modalidade = ['Graduação', 'Pós Graduação', 'EJA', 'Cursos Técnicos'];
const status = ['Em acompanhamento', 'Concluido', 'Não Concluido'];
const ativo = ['Sim', 'Não'];

export const exportXLSXRelatorio = async (data) => {
  const XlsxPopulate = require('xlsx-populate');
  const workbook = await XlsxPopulate.fromBlankAsync();
  const sheet = workbook.sheet(0);

  sheet.cell('A1').value('CHECKIN');
  sheet.cell('B1').value('DATA_VISITA');
  sheet.cell('C1').value('DATA_INCLUSAO');
  sheet.cell('D1').value('TIPO_VISITA');
  sheet.cell('E1').value('STATUS_GERAL');
  sheet.cell('F1').value('RITUAL');
  sheet.cell('G1').value('TEMPO_VISITA');
  sheet.cell('H1').value('QUER_GANHAR_QUANTO');
  sheet.cell('I1').value('ID_ACAO');
  sheet.cell('J1').value('LINK');
  sheet.cell('K1').value('PILAR_FOCA');
  sheet.cell('L1').value('PILAR_TIPO');
  sheet.cell('M1').value('MODALIDADE');
  sheet.cell('N1').value('DESCRICAO_ACAO');
  sheet.cell('O1').value('RESPONSAVEL_ACAO');
  sheet.cell('P1').value('PRAZO');
  sheet.cell('Q1').value('GANHO_ESTIMADO');
  sheet.cell('R1').value('GANHO_REALIZADO');
  sheet.cell('S1').value('RESULTADO_ATINGIDO');
  sheet.cell('T1').value('DATA_CONCLUSAO_ACAO');
  sheet.cell('U1').value('SITUACAO_ACAO');
  sheet.cell('V1').value('RESPONSAVEL_POLO');
  sheet.cell('W1').value('GERENTE_NEGOCIO');
  sheet.cell('X1').value('NOME_GN_POLO');
  sheet.cell('Y1').value('ID_POLO');
  sheet.cell('Z1').value('POLO');
  sheet.cell('AA1').value('GRUPO_ECONOMICO');
  sheet.cell('AB1').value('NOME_DN_POLO');

  let i = 2;
  data.forEach((item) => {
    sheet.cell(`${'A' + i}`).value(item.checkin);
    sheet.cell(`${'B' + i}`).value(item.dataVisita);
    sheet.cell(`${'C' + i}`).value(item.dataInclusao);
    sheet.cell(`${'D' + i}`).value(item.tipoVisita);
    sheet.cell(`${'E' + i}`).value(item.statusGeral);
    sheet.cell(`${'F' + i}`).value(item.ritual);
    sheet.cell(`${'G' + i}`).value(item.tempoVisita);
    sheet.cell(`${'H' + i}`).value(item.querGanharQuanto);
    sheet.cell(`${'I' + i}`).value(item.idAcao);
    sheet.cell(`${'J' + i}`).value(item.link);
    sheet.cell(`${'K' + i}`).value(item.pilarFoca);
    sheet.cell(`${'L' + i}`).value(item.pilarTipo);
    sheet.cell(`${'M' + i}`).value(item.modalidade);
    sheet.cell(`${'N' + i}`).value(item.descricaoAcao);
    sheet.cell(`${'O' + i}`).value(item.responsavelAcao);
    sheet.cell(`${'P' + i}`).value(item.prazo);
    sheet.cell(`${'Q' + i}`).value(item.ganhoEstimado);
    sheet.cell(`${'R' + i}`).value(item.ganhoRealizado);
    sheet.cell(`${'S' + i}`).value(item.resultadoAtingido);
    sheet.cell(`${'T' + i}`).value(item.dataConclusaoAcao);
    sheet.cell(`${'U' + i}`).value(item.situacaoAcao);
    sheet.cell(`${'V' + i}`).value(item.responsavelPolo);
    sheet.cell(`${'W' + i}`).value(item.gerenteNegocio);
    sheet.cell(`${'X' + i}`).value(item.nomeGnPolo);
    sheet.cell(`${'Y' + i}`).value(item.idPolo);
    sheet.cell(`${'Z' + i}`).value(item.polo);
    sheet.cell(`${'AA' + i}`).value(item.grupoEconomico);
    sheet.cell(`${'AB' + i}`).value(item.nomeDnPolo);
    i++;
  });

   const blob = await workbook.outputAsync();

  //LINK PRA DOWNLOAD
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = `Relatório_FOCA_${moment().format('DD/MM/YYYY HH:mm:ss').replaceAll('/', '').replaceAll(' ', '_').replaceAll(':','')}.xlsx`;
  a.click();
}

export const exportXLSX = async (listActions, fileName) => {
  const XlsxPopulate = require('xlsx-populate');
  const workbook = await XlsxPopulate.fromBlankAsync();
  const sheet = workbook.sheet(0);

  //sheet.column('H').type('Currency');
  //sheet.column('K').type('Currency');
  //HEADER
  sheet.cell('A1').value('ID');
  sheet.cell('B1').value('CHECKIN_ID');
  sheet.cell('C1').value('FOCA');
  sheet.cell('D1').value('PILAR');
  sheet.cell('E1').value('MODALIDADE');
  sheet.cell('F1').value('ACAO');
  sheet.cell('G1').value('LINK');
  sheet.cell('H1').value('RESPONSAVEL');
  sheet.cell('I1').value('PRAZO');
  sheet.cell('J1').value('GANHO_ESTIMADO');
  sheet.cell('K1').value('GANHO_REALIZADO');
  sheet.cell('L1').value('DATA_CONCLUSAO');
  sheet.cell('M1').value('RESULTADO_ATINGIDO');
  sheet.cell('N1').value('STATUS');
  sheet.cell('O1').value('ATIVO');

  const currencyFormat = '_("R$"* #,##0.00_);_("R$"* (#,##0.00);_("R$"* "-"??_);_(@_)';
  const brazilianDateFormat = 'dd/mm/yyyy';

  //CONTEUDO
  let i = 2;
  listActions.forEach((item) => {
    sheet.cell(`${'A' + i}`).value(item.id);
    sheet.cell(`${'B' + i}`).value(item.focaCheckin);
    sheet
      .cell(`${'C' + i}`)
      .dataValidation({
        type: 'list',
        showDropDown: true,
        formula1: '"' + foca.join(',') + '"',
      })
      .value(validFOCA(item.foca, true));
    sheet
      .cell(`${'D' + i}`)
      .dataValidation({
        type: 'list',
        showDropDown: true,
        formula1: '"' + pilar.join(',') + '"',
      })
      .value(validPilar(item.pilar, true));
    sheet
      .cell(`${'E' + i}`)
      .dataValidation({
        type: 'list',
        showDropDown: true,
        formula1: '"' + modalidade.join(',') + '"',
      })
      .value(item.modalidade);
    sheet.cell(`${'F' + i}`).style({ wrapText: true }).value(item.acao)
    sheet.cell(`${'G' + i}`).value(item.link);
    sheet.cell(`${'H' + i}`).value(item.responsavel);
    sheet
      .cell(`${'I' + i}`)
      .value(item.prazo != null ? item.prazo.replaceAll('-', '/') : item.prazo);
    sheet
      .cell(`${'J' + i}`)
      .value(item.ganhoEstimado)
      .style({ numberFormat: currencyFormat })
    sheet
      .cell(`${'K' + i}`)
      .value(item.ganhoRealizado)
      .style({ numberFormat: currencyFormat })
    sheet
      .cell(`${'L' + i}`)
      .value(
        item.dataConclusao != null
          ? item.dataConclusao.replaceAll('-', '/')
          : item.dataConclusao
      );
    sheet.cell(`${'M' + i}`).value(item.resultadoAtingido);
    sheet
      .cell(`${'N' + i}`)
      .dataValidation({
        type: 'list',
        showDropDown: true,
        formula1: '"' + status.join(',') + '"',
      })
      .value(validStatus(item.status, true));
    sheet
      .cell(`${'O' + i}`)
      .dataValidation({
        type: 'list',
        showDropDown: true,
        formula1: '"' + ativo.join(',') + '"',
      })
      .value(validActive(item.ativo, true));
    i++;
  });

  for (let index = i; index < i + 20; index++) {
    sheet.cell(`${'C' + index}`).dataValidation({
      type: 'list',
      showDropDown: true,
      formula1: '"' + foca.join(',') + '"',
    });
    sheet.cell(`${'D' + index}`).dataValidation({
      type: 'list',
      showDropDown: true,
      formula1: '"' + pilar.join(',') + '"',
    });
    sheet.cell(`${'E' + index}`).dataValidation({
      type: 'list',
      showDropDown: true,
      formula1: '"' + modalidade.join(',') + '"',
    });
    sheet
    .cell(`${'I' + index}`)
    .style({ numberFormat: brazilianDateFormat });
    sheet
    .cell(`${'L' + index}`)
    .style({ numberFormat: brazilianDateFormat })
  sheet
    .cell(`${'J' + index}`)
    .style({ numberFormat: currencyFormat })
    sheet
    .cell(`${'K' + index}`)
    .style({ numberFormat: currencyFormat })
    sheet.cell(`${'N' + index}`).dataValidation({
      type: 'list',
      showDropDown: true,
      formula1: '"' + status.join(',') + '"',
    });
    sheet.cell(`${'O' + index}`).dataValidation({
      type: 'list',
      showDropDown: true,
      formula1: '"' + ativo.join(',') + '"',
    });
  }

  const blob = await workbook.outputAsync();

  //LINK PRA DOWNLOAD
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = `${fileName}.xlsx`;
  a.click();
};

export const onGetPolos = async (userLogggedId) => {
  return await getPolo(userLogggedId);
};

export const onGetCheckins = async (userLogggedId, perfilID) => {
  return await getCheckins(userLogggedId, perfilID);
};

export const onFilter = async (searchFilter) => {
  return await getFilter(searchFilter);
};

export const onPdf = async (relatorioConfig) => {
  return await exportPDF(relatorioConfig);
};

export const onGetFiltersReport = async (idReport) => {
  return await getFiltersReport(idReport);
};
