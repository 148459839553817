import React, { Component } from 'reactn';
import { Redirect } from 'react-router-dom';
import {
  Error,
  FontAwesomeIcon,
  ComunicadoDisplay,
} from '../../services/Gerais';
import { Popover, Button, Row, Col, Icon, Input } from 'antd';
import ReactGA from 'react-ga';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from 'services/api';
import Swal from 'sweetalert2';

export default class ComunicadosCarouselDestaque extends Component {
  constructor() {
    super();
    this.state = {
      error: [],
      isActivedComunicHome: false,
      showComunicados: [],
      comunicados: [],
    };
    this.logInfoCarouselHome = JSON.parse(localStorage.getItem('userDataCRP'));
    this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this);
    this.poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
  }

  copyCodeToClipboard = comunicado => {
    let textField = document.createElement('textarea');
    textField.innerText = `${window.location.origin}/comunicados/${
      comunicado.id
    }`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    Swal.fire({
      title:  `Copiado!`,
      text: `URL do comunicado foi copiada.`,
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  };

  toggleNaved = () => {
    this.setState(prevState => ({
      isActivedComunicHome: !prevState.isActivedComunicHome,
    }));
  };

  async componentDidMount() {
    if (!localStorage.getItem('userDataCRP')) {
      return <Redirect to={'/login'} />;
    }
  }

  geraComunicado = async (e, comunicado, btn) => {
    e.preventDefault();

    let lido = comunicado.lido;
    let favorito = comunicado.favorito;

    if (btn === 'like') {
      favorito = favorito !== 'S' ? 'S' : 'N';
      lido = favorito === 'S' ? 'S' : lido;
    } else {
      lido = 'S';
      this.toggleNaved();
    }

    // marcando como lido
    const dataComunicadoUsuario = [
      {
        usuarioPortal: { id: this.logInfoCarouselHome['usuarioPortal']['id'] },
        comunicado: { id: comunicado.id },
        favorito: favorito,
        comunicadoLido: lido,
        usuarioPortalLog: {
          id: this.logInfoCarouselHome['usuarioPortal']['id'],
        },
      },
    ];

    try {
      await api.post(
        'comunicadoUsuario/saveComunicadoUsuario',
        dataComunicadoUsuario[0]
      );

      ReactGA.modalview('/comunicado/' + comunicado.codigo);
      const usuarioPortalId = this.logInfoCarouselHome['usuarioPortal']['id'];
      const res = await api.get(
        'comunicadoUsuario/getComunicadoByComunicadoAndUsuario/' +
          usuarioPortalId +
          '/' +
          comunicado.id
      );

      this.setState({
        showComunicados: res.data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        error,
        isLoading: false,
      });
    }
    this.componentDidMount();
  };

  render() {
    if(this.props.comunicados && this.props.comunicados.Comunicados && this.state.comunicados.length == 0) {
      this.setState({comunicados: this.props.comunicados})
    }
    const { error, comunicados, showComunicados } = this.state;
    let settings = {
      infinite: true,
      slidesToShow: 5,
      arrows: true,
      dots: false,
      slickPause: true,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1360,
          settings: {
            slidesToShow: 5,
            infinite: true,
            dots: false,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            infinite: true,
            dots: false,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 900,
          settings: { slidesToShow: 2, initialSlide: 2, slidesToScroll: 2 },
        },
        {
          breakpoint: 599,
          settings: { slidesToScroll: 1, slidesToShow: 1 },
        },
      ],
    };

    return (
      <>
        {comunicados.MinutoOperacao && comunicados.MinutoOperacao.TotalDestaques > 0 ? (
          <div
            className="container-header is-fullhd comunicadosDestaque listaCategoriaComunicados"
            key="comunicadosDestaque"
          >
            {error.response ? <Error {...error} /> : null}
            <div className="columns " style={{ padding: 10 }}>
              <div className="column"  >
                <h4 className="title">Minuto Operação</h4>
              </div>
            </div>
            <Slider {...settings} className={(comunicados.MinutoOperacao.Comunicados.length > 0 ?
            'sliderBox' : '')}>
              {comunicados.MinutoOperacao.Comunicados.map(comun => {
                let comunicado = comun['comunicado'];
                let listaTags;
                let inicioTags = 0;
                let maxTags = 3;
                let errata = '';

                if (comunicado.errata === 'S') {
                  errata = 'ATUALIZAÇÃO - ';
                }

                if (comun['lstComunicadoTag']) {
                  listaTags = comun['lstComunicadoTag'].map(function(tag) {
                    ++inicioTags;
                    if (inicioTags <= maxTags) {
                      if (inicioTags === maxTags) {
                        return (
                          <a
                            href={'./comunicados-tags/' + tag.id}
                            key={'tag' + tag.id}
                            title={
                              'mais ' +
                              (comun['lstComunicadoTag'].length -
                                inicioTags +
                                1) +
                              ' tags'
                            }
                            className="button is-small is-rounded is-link tagsComunicadoMais"
                          >
                            +{comun['lstComunicadoTag'].length - inicioTags + 1}
                          </a>
                        );
                      } else {
                        return (
                          <a
                            href={'./comunicados-tags/' + tag['tag']['id']}
                            key={'tag' + tag['tag']['id']}
                            title={tag['tag']['descricao']}
                            className="button is-small is-rounded is-outlined tagsComunicado"
                          >
                            {tag['tag']['descricao']}
                          </a>
                        );
                      }
                    } else {
                      return '';
                    }
                  });
                }

                return (
                  <div
                    className="column item"
                    data-index={comunicado.codigo}
                    key={comunicado.codigo}
                  >
                    <span
                      className="salvarComunicadoRelativoDestaque"
                      data-comunicado-favorito={comunicado.id}
                      onClick={e => this.geraComunicado(e, comunicado, 'like')}
                      title={
                        comunicado.favorito === 'S'
                          ? 'Desmarcar favorito'
                          : 'Marcar como favorito'
                      }
                    >
                      <FontAwesomeIcon
                        icon={[
                          comunicado.favorito === 'S' ? 'fas' : 'far',
                          'bookmark',
                        ]}
                      />
                    </span>
                    <div className="box bgComunicadosDestaque">
                      <div
                        onClick={e =>
                          this.geraComunicado(e, comunicado, 'read')
                        }
                      >
                        <article className="media">
                          <div className="media-content">
                            <div className="content">
                              {comunicado.path_thumbnail ? (
                                <img
                                  src={comunicado.path_thumbnail}
                                  alt="new"
                                  style={{ height: 181, width: '100%' }}
                                />
                              ) : null}
                              <div
                                className="container"
                                style={{ padding: 12 }}
                              >
                                {comunicado.dataComunicado.slice(0, 10)}

                                <h1
                                  className=" is-6 is-spaced"
                                  data-comunicado={comunicado.id}
                                >
                                  <span
                                    style={{ float: 'left' }}
                                    title={comunicado.titulo}
                                  >
                                    {errata}
                                    {comunicado.titulo &&
                                    comunicado.titulo.length > 60
                                      ? comunicado.titulo
                                          .slice(0, 60)
                                          .concat('...')
                                      : comunicado.titulo}
                                  </span>
                                </h1>
                                <small className="smallComunicadoDestaque"
                                dangerouslySetInnerHTML={{ __html: comunicado.resumoNovo}}>
                                </small>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="content is-small referenciaComunicadosDestaque">
                        {listaTags}
                        <hr />
                          {/*<Button
                            key={`edit_${comunicado.id}`}
                            title={'Compartilhar esse comunicado'}
                            size="small"
                            type="primary"
                            onClick={() =>
                              this.copyCodeToClipboard(comunicado)
                            }
                          >
                            <FontAwesomeIcon icon={['fas', 'share-alt']} />
                            <span style={{ paddingLeft: 5 }}>Compartilhar</span>
                          </Button>*/}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div
              className={
                this.state.isActivedComunicHome
                  ? 'conteudoLink is-active'
                  : 'conteudoLink'
              }
            >
              <div className="is-fullhd">
                <div className="columns is-gapless">
                  <div
                    className="is-transparent"
                    style={{ width: '15%',}}
                    id="columnCloseDestaque"
                    onClick={this.toggleNaved}
                  />
                  <ComunicadoDisplay
                    comunicado={showComunicados}
                    isDestaque={true}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
