import { Button, Tag, Icon } from 'antd';
import React from 'react';
import { ActionContainer } from './styled';

export const tableColumns = [
  {
    title: 'ID',
    key: 'id',
    width: 50,
    dataIndex: 'id',
    valueType: 'indexBorder',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'ID Relatorio',
    key: 'idRelatorio',
    width: 100,
    dataIndex: 'idRelatorio',
    valueType: 'idRelatorio',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Nome',
    key: 'nomeExibicao',
    dataIndex: 'nomeExibicao',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Condição Where',
    key: 'condicaoWhere',
    dataIndex: 'condicaoWhere',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Tipo',
    key: 'tipo',
    dataIndex: 'tipo',
    copyable: true,
    ellipsis: true,
    width: 160
  },
  {
    title: 'Ativo',
    key: 'status',
    dataIndex: 'status',
    width: 100,
    render: (_, record) => {
      const objTag =
      record.status === 'ATIVO'
          ? { tag: 'Ativo', color: '#87d068' }
          : { tag: 'Inativo', color: 'volcano' };
      return (<span>
        <Tag color={objTag.color} key={objTag.tag}>
          {objTag.tag.toUpperCase()}
        </Tag>
      </span>)
    },
  },
  {
    title: 'Ação',
    valueType: 'action',
    dataIndex: 'id',
    key: 'edit',
    width: 130,
    render: (_, record) => (
      <ActionContainer key={`action_${record.id}`}>
        <Button
          href={`/admin/filters/edit/${record.idRelatorio}/${record?.id}`}
          type="dashed"
          className="button is-small is-warning"
          size="small"
        >
          <Icon type="file-search" />
          Editar
        </Button>
      </ActionContainer>
    ),
  },
];
