import api from 'services/api';

export async function getActiveFocalPoints() {
    try {
      const response = await api.get(`pontoFocal/A`); 
      return response.data;  
      
    } catch (error) {
       throw new Error('Erro ao buscar lista de pontos focais');
    }
  }