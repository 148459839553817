import {
  AGENDA_CHANGE,
  AGENDA_CHANGE_MARCA,
  AGENDA_CHANGE_POLO,
  AGENDA_ERROR,
  AGENDA_SUCCESS,
  DADO_AGENDA_SUCCESS,
  DADO_AGENDA_CHANGE_POLO,
  AGENDA_CHANGE_MARCA_SELECIONADA,
  AGENDA_CHANGE_POLO_SELECIONADO,
} from './AgendaType';

import api from '../../../../services/api';

const trataPolo = polos => {
  return polos.map(polo => {
    return { value: polo.id, label: polo.nome };
  });
};

export const getDadosCadInitAgenda = async () => {
  return async function(dispatch) {
    try {
      const res = await api.get(`agenda/getDadosCadInitAgenda/`);

      if (res.data.Polo) {
        res.data.Polo = trataPolo(res.data.Polo);
      }

      return dispatch({
        type: DADO_AGENDA_SUCCESS,
        payload: { ...res.data, carregando: false },
      });
    } catch (error) {
      return dispatch({
        type: AGENDA_ERROR,
        payload: { error: error, carregando: false },
      });
    }
  };
};

export const getAgenda = async id => {
  return async function(dispatch) {
    try {
      const res = await api.get(`agenda/${id}/`);

      if (res.data.Agenda.lstAgendaPolo) {
        res.data.Agenda.polosSelecionados = res.data.Agenda.lstAgendaPolo.map(
          a => a.polo.id
        );
      }

      if (res.data.Agenda.lstAgendaMarca) {
        res.data.Agenda.marcaSelecionada = res.data.Agenda.lstAgendaMarca.map(
          a => String(a.marca.id)
        );
      }

      return dispatch({
        type: AGENDA_SUCCESS,
        payload: { ...res.data.Agenda, carregando: false },
      });
    } catch (error) {
      return dispatch({
        type: AGENDA_ERROR,
        payload: { error: error, carregando: false },
      });
    }
  };
};

export const findAllByTipoPoloAndMarca = async polos => {
  return async function(dispatch) {
    try {
      const res = await api.post(`polo/findAllByTipoPoloAndMarca`, polos);

      if (res.data.Polos) {
        res.data.Polos = trataPolo(res.data.Polos);
      }

      return dispatch({
        type: DADO_AGENDA_CHANGE_POLO,
        payload: res.data.Polos,
      });
    } catch (error) {
      return dispatch({
        type: AGENDA_ERROR,
        payload: { error: error, carregando: false },
      });
    }
  };
};

export const salvaAgenda = async agenda => {
  return async function(dispatch) {
    try {
      const res = await api.post(`agenda/saveAgenda`, agenda);
      const userLoggedId = localStorage.getItem('userDataCRP-Perfil')
      if(userLoggedId == 999 || userLoggedId == 1 || userLoggedId == 2){
        window.location.replace('/admin/agenda');
      } else {
        window.location.replace('/agenda');
      }
      return dispatch({
        type: AGENDA_SUCCESS,
        payload: { ...res.data.Agenda,succes:true,  botaoSalvarHabilitado:true }
      });
    } catch (error) {
      let erro = error;
      if (erro && erro.response && erro.response.data) {
        erro = error.response.data;
      }
      return dispatch => {
        dispatch({
          type: AGENDA_ERROR,
          payload: { error: erro, succes: false, botaoSalvarHabilitado:false  },
        });
      };
    }
  };
};

export const alteraAgenda = async (data, id) => {
  try {
    await api.put(`agenda/saveAgenda/${id}`, data);

    return dispatch => {
      dispatch({
        type: AGENDA_SUCCESS,
        payload: { succes: true, error: null,  botaoSalvarHabilitado:true   }
      });
      const userLoggedId = localStorage.getItem('userDataCRP-Perfil')
      if(userLoggedId == 999 || userLoggedId == 1 || userLoggedId == 2){
        window.location.replace('/admin/agenda');
      } else {
        window.location.replace('/agenda');
      }
    };
  } catch (error) {
    let erro = error;
    if (erro && erro.response && erro.response.data) {
      erro = error.response.data;
    }
    return dispatch => {
      dispatch({
        type: AGENDA_ERROR,
        payload: { error: erro, succes: false,botaoSalvarHabilitado:false  },
      });
    };
  }
};

export const changeAgenda = ({ prop, value }) => {
  return dispatch => {
    dispatch({ type: AGENDA_CHANGE, payload: { prop, value } });
  };
};

export const changeLstMarca = lstMarca => {
  return dispatch => {
    dispatch({
      type: AGENDA_CHANGE_MARCA,
      payload: lstMarca,
    });
  };
};

export const changeLstMarcaSelecionada = lstMarca => {
  return dispatch => {
    dispatch({
      type: AGENDA_CHANGE_MARCA_SELECIONADA,
      payload: lstMarca,
    });
  };
};

export const changeLstPolo = lstPolo => {
  return dispatch => {
    dispatch({
      type: AGENDA_CHANGE_POLO,
      payload: lstPolo,
    });
  };
};

export const changeLstPoloSelecionado = lstPolo => {
  return dispatch => {
    dispatch({
      type: AGENDA_CHANGE_POLO_SELECIONADO,
      payload: lstPolo,
    });
  };
};
