import React from 'reactn';
import { cortarComReticencias } from '../../utils/tools';
import { axios, ComunicadoDisplay, $ } from '../../services/Gerais';
import api from 'services/api';
import { notification } from 'antd';
import Swal from 'sweetalert2';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
class MensagensImportantes extends React.Component {
  constructor() {
    super();
    this.state = {
      comunicados: [],
      pesquisa: {},
      isActived: false,
      showComunicado: [],
    };
    this.loginInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
  }

  componentDidMount() {
    axios
      .get(
        this.global.baseUrl +
          'comunicado/getComunicadoUsuario?id=' +
          this.loginInfo['id'] +
          '&page=0&linesPerPage=5&tipoComunicado=M' +
          (this.poloId > 0 ? `&poloId=${this.poloId}` : ''),
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then((result) => {
        this.setState({
          comunicados: result.data['Comunicados'],
        });
        this.renderMensagensImportantes();
      })
      .catch((error) =>
        this.setState({
          error: error,
          isLoading: false,
        })
      );

    axios
      .get(
        this.global.baseUrl +
          'pesquisa/' +
          this.loginInfo['usuarioPortal']['id'],
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then((result) => {
        this.setState({
          pesquisa: result.data,
        });
        this.swalPesquisa();
      })
      .catch((error) =>
        this.setState({
          error: error,
          isLoading: false,
        })
      );
  }

  comunicadoHandler = async (index) => {
    const comunicado = this.comunicadosNaoLidos('lista')[index].comunicado;
    const data = {
      usuarioPortal: { id: this.loginInfo.usuarioPortal.id },
      comunicado: { id: comunicado.id },
      favorito: comunicado.favorito,
      comunicadoLido: 'S',
      usuarioPortalLog: {
        id: this.loginInfo.usuarioPortal.id,
      },
    };
    try {
      //salvar estado do comunicado como "lido" e navegar para a página do comunicado
      await api.post('comunicadoUsuario/saveComunicadoUsuario', data);
      return (window.location = '/comunicados/' + comunicado.id);
    } catch (error) {
      Swal.fire({
        title: `Ops!`,
        text: `Algo errado aconteceu!`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  mensagemImportante = (index, cabecalho, resumo, tempo) => {
    const comunicado = this.comunicadosNaoLidos('lista')[index].comunicado;
    var iconAlert = '';
    switch (comunicado.prioridade) {
      case 'C':
        iconAlert = (
          <FontAwesomeIcon
            icon={['fas', 'thermometer-full']}
            className="icon-thermometer-pop"
            color="#ff0000"
          />
        );
        break;
      case 'A':
        iconAlert = (
          <FontAwesomeIcon
            icon={['fas', 'thermometer-three-quarters']}
            className="icon-thermometer-pop"
            color="#ff4500"
          />
        );
        break;
      case 'I':
        iconAlert = (
          <FontAwesomeIcon
            icon={['fas', 'thermometer-half']}
            className="icon-thermometer-pop"
            color="#248eff"
          />
        );
        break;
      case 'R':
        iconAlert = (
          <FontAwesomeIcon
            icon={['fas', 'thermometer-quarter']}
            className="icon-thermometer-pop"
            color="#00FF7F"
          />
        );
        break;
      default:
        iconAlert = (
          <FontAwesomeIcon
            icon={['fas', 'thermometer-half']}
            className="icon-thermometer-pop"
            color="#248eff"
          />
        );
        break;
    }

    if (comunicado.visualizaPopup) {
      setTimeout(
        function () {
          notification['info']({
            message: cabecalho,
            description: parse(resumo),
            duration: 0,
            icon: iconAlert,
            style: {
              cursor: 'pointer',
            },
            onClick: () => this.openMensagemImportante(comunicado.id),
          });
        }.bind(this),
        tempo
      );
    }
  };

  async openMensagemImportante(idComunicado) {
    try {
      const response = await api.get(
        `comunicado/getComunicadoUsuarioLink?idUsuarioPolo=${this.loginInfo['id']}&idComunicado=${idComunicado}`
      );

      if (!response) {
        Swal.fire({
          title: `Ops...!`,
          text: `Comunicado não encontrado!`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return;
      }

      this.setState({
        showComunicado: response.data,
      });

      this.toggleNaved();
    } catch (error) {
      let msg = '';
      if (error && error.response && error.response.data) {
        msg = error.response.data.error ? error.response.data.error : '';
      }
      Swal.fire({
        title: `Ops...!`,
        text: `Comunicado não encontrado! ${msg}`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  toggleNaved = () => {
    this.setState((prevState) => ({ isActived: !prevState.isActived }));
  };

  comunicadosNaoLidos = (tipo) => {
    const dados = this.state.comunicados['Comunicados'];
    if (dados) {
      const comunicadosNaoLidosArray = dados.filter((objeto) => {
        return objeto.comunicado.lido !== 'S';
      });
      const quantidade = comunicadosNaoLidosArray.length;
      let resultado = comunicadosNaoLidosArray;
      if (tipo === 'quantidade') {
        resultado = quantidade;
      } else if (tipo === 'point') {
        resultado = quantidade ? (
          <span className="point">{quantidade}</span>
        ) : null;
      }
      return resultado;
    }
  };

  renderMensagensImportantes = async () => {
    const mensagensImportantesLista = this.comunicadosNaoLidos();
    if (mensagensImportantesLista) {
      mensagensImportantesLista.map((item, index) => {
        const titulo = item.comunicado.titulo;
        const data = item.comunicado.dataComunicado;
        const cabecalho = (
          <div>
            <h1 style={{ 'font-weight': 'bold', color: '#0074D2' }}>
              {titulo}
            </h1>
            <h2>{data}</h2>
          </div>
        );
        const resumo = cortarComReticencias(item.comunicado.resumoNovo, 110);
        const tempo = index * 700;
        return (
          <div key={index}>
            {this.mensagemImportante(index, cabecalho, item.comunicado.resumoNovo, tempo)}
          </div>
        );
      });
    }
  };

  swalPesquisa = () => {
    let pesquisa = this.state.pesquisa;
    let i = 0;

    function showSwal(pesq, self) {
      if (!pesq) return;

      if (pesq.respondeu === 'N') {
        Swal.fire({
          title: pesq.tituloPesquisa || `Pesquisa de Satisfação`,
          text:
            pesq.textoPesquisa ||
            'Sua opinião faz a diferença. Responda antes que o prazo acabe!',
          showCancelButton: true,
          showDenyButton: true,
          showConfirmButton: !!pesq.link,
          confirmButtonText: pesq.botao1 || 'Responder',
          cancelButtonText: pesq.botao3 || 'Responder mais tarde',
          denyButtonText: pesq.botao2 || 'Já respondi',
          allowOutsideClick: false,
          customClass: 'swalHome',
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(pesq.link);
          } else if (result.isDenied) {
            axios({
              method: 'PATCH',
              url: self.global.baseUrl + 'pesquisa/' + pesq.id,
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
                'Content-Type': 'application/json;charset=UTF-8',
              },
              data: '',
            });
          }
          showSwal(pesquisa[++i], self);
        });
      } else if (pesq.respondeu === 'S') {
        showSwal(pesquisa[++i], self);
      }
    }

    showSwal(pesquisa[0], this);
  };

  render() {
    const { showComunicado } = this.state;
    return (
      <div>
        <div>
          <a
            className="navbar-item menuIcones notify"
            title="Comunicados"
            href="/busca/comunicados"
          >
            <FontAwesomeIcon icon={['far', 'bell']} />
            {this.comunicadosNaoLidos('point')}
          </a>
        </div>
        <div
          className={
            this.state.isActived ? 'conteudoLink is-active' : 'conteudoLink'
          }
        >
          <div className=" is-fullhd">
            <div className="columns is-gapless">
              <div
                className="is-transparent"
                style={{ width: '15%',}}
                id={'columnClose_' + showComunicado.id}
                onClick={this.toggleNaved}
              />
              <ComunicadoDisplay
                comunicado={showComunicado}
                categoriaID={showComunicado.id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MensagensImportantes;
