import styled from 'styled-components';

export const Container = styled.div``;

export const Box = styled.div`
  margin-bottom: 24px;
  div {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    justify-content: center;
  }

  p {
    margin: 0;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  a {
    all: unset;
  }
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
