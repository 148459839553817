import React, { Component } from "reactn";
import { Redirect } from "react-router-dom";
import "../../styles/Styles.scss";
import crp from '../../img/prisma_logo.png';
import { Helmet, axios, FontAwesomeIcon, library, $ } from "../../services/Gerais";
import avatar from "../../img/loadingtwo.gif";
import "form-serializer/dist/jquery.serialize-object.min";
import "jquery-validation/dist/jquery.validate.min";
import "jquery-validation/dist/localization/messages_pt_BR.min";
import { faCheck, faExchangeAlt, faStar, faVideo, faUser, faEnvelope, faExclamationTriangle, faBan } from '@fortawesome/free-solid-svg-icons';
import { faBell, faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { Table, Tag } from 'antd';
import { authProvider } from "../../services/authProvider";
import Swal from 'sweetalert2'
import { Spin, Icon } from 'antd';
import { findByFilters } from "services/userPolo/service";

library.add({ faCheck, faExchangeAlt, faStar, faVideo, faBell, faUser, faEnvelope, faExclamationTriangle, faPlusSquare, faBan })

let idPerfil = 0;
class Polos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isActiveOk: false,
            primeiroPasso: true,
            proximoPasso: false,
            isLoading: false,
            primeiroAcesso: false,
            tour: false,
            redirect: false,
            error: null,
            salvarURL: '',
            usuarios: [],
            usersSelected: [],
            selectedRowKeys: [],
            page: 0,
            selecteds: [],
            isCallGetUser: false
        }
        this.passGen = this.passGen.bind(this);
        this.logout = this.logout.bind(this);
        this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
        this.logInfoLogin = JSON.parse(localStorage.getItem('userDataCRP-Login'));
        this.listPolos = JSON.parse(localStorage.getItem('userDataCRP-ListaPolos'));
        this.polosIDS = [];
        this.pagination = { position: 'bottom' };
    }

    componentDidMount() {

        this.setState({
            isLoading: true,
            // salvarURL: this.global.baseUrl+'usuario/resetUsuario/'+this.logInfo.usuarioPortal.id,
            salvarURL: this.global.baseUrl + 'usuario/setSenhaUsuario/' + this.logInfo.usuarioPortal.id,
        });

        $.validator.addMethod("padraoSenha", function (value, element) {
            if (element.name === "senhaAlterar" && value === "") {
                return true
            } else {
                // var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
                var reg = /^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/gm;
                if (value.match(reg)) {
                    return true
                }
                return false
            }
        }, "Inserir senha conforme as regras acima");

        $("form").validate({
            lang: 'pt_BR',
            rules: {
                senhaAlterar: {
                    padraoSenha: true,
                    required: true,
                    minlength: 6
                },
                repetirSenha: {
                    required: true,
                    minlength: 6,
                    equalTo: "#senhaAlterar"
                }
            },
            submitHandler: function () {

                $('#sendButton').attr('disabled', 'disabled');

                $.ajax({
                    type: 'PUT',
                    url: $('#dataForm').attr('action'),
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
                        "Access-Control-Allow-Origin": "*"
                    },
                    data: $('#dataForm').serializeJSON(),
                    processData: false,
                    // complete: callback,
                    success: function (data, textStatus, jQxhr) {

                        // atualizando as sessoes de primeiro acesso
                        var atualizaSessao = JSON.parse(localStorage.getItem('userDataCRP-Login'))
                        for (var j = 0, len = atualizaSessao.length; j < len; j++) {
                            atualizaSessao[j]['usuarioPortal']['primeiroAcesso'] = 'N'
                        }
                        localStorage.setItem('userDataCRP-Login', JSON.stringify(atualizaSessao));

                        $("html, body, .AppContent").animate({ scrollTop: 0 }, "slow");
                        $('#return').html('<div class="notification is-primary"><span class="delete deleteNotification"></span><strong>Salvo com sucesso! Aguarde...</strong></div>');
                        window.setTimeout(function () {
                            $('.formSenha').addClass('is-hidden');
                            $('.apresentacaoConfirmacao').removeClass('is-hidden');
                            $('.deleteNotification').trigger('click');
                        }, 2000);
                        return false
                    },
                    error: (errormessage) => {
                        // var msgErro = ''
                        // if(errormessage.responseJSON){
                        //     msgErro = errormessage.responseJSON.error
                        // }
                        $('.formSenha').addClass('is-hidden');
                        $('.apresentacaoConfirmacao').removeClass('is-hidden');
                        var msgErro = ''
                        if (errormessage.responseJSON) {
                            msgErro = errormessage.responseJSON.message
                        }
                        $("html, body, .AppContent").animate({ scrollTop: 0 }, "slow");
                        $('#return').html('<div class="notification is-danger"><span class="delete deleteNotification"></span><strong>Erro ao salvar.</strong><br />Verifique as informações inseridas e tente novamente!<input type="hidden" value="' + msgErro + '"/></div>');
                        return false
                    }
                });

            }
        });

        $(document).on('click', '.deleteNotification', function (e) {
            e.preventDefault();
            $('.deleteNotification').parent().remove();
        });

        this.setState({ isLoading: false });

    }

    getUsers = async () => {
      let queryString = [
        `?isTelaUsuariosPolo=${true}`,
        `&isFirstOpen=${false}`,
        `&page=${this.state.page}`,
        `&size=${10}`,
        `&perfis=${[idPerfil]}`,
        `&polosIds=${this.polosIDS}`,
      ].join('');
      const data = await findByFilters(queryString);
      this.pagination = {
        total: data.data.totalElements,
        current: data.data.number + 1,
      }
      this.setState({
        usuarios: data.data.content,
        tour: true,
        isLoading: false,
      })
    }

    handleTableChange = (pagination) =>{
      this.state.isLoading = true;
      const pager = { ...pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.state.page = pagination.current - 1;
      this.getUsers();
    }

    componentWillMount() {
        const acesso = JSON.parse(localStorage.getItem('userDataCRP-Login'));

        this.listPolos.forEach((item) => {
          this.polosIDS.push(item.id.id)
        });
        let isCallGetUser = false;
        acesso.map((item) => {
          if (item['usuarioPortal']['primeiroAcesso'] === 'S') {
            this.setState({
                primeiroAcesso: true
            });
          }
          if(item.tour === 'S'){
            isCallGetUser = true;
            this.setState({ isCallGetUser: true });
          }
        })

        if(!isCallGetUser){
          window.location.href = "../home";
        }

        this.getPerfil();
    }

    getPerfil = async () => {
      var apiHeaderAdmin = this.global.baseUrl + "usuario/loginPolo/";
      await axios
      .get(apiHeaderAdmin + this.logInfo["id"], {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("userDataCRP-AuthTk")
        }
      })
      .then(resultHeaderAdmin =>
        idPerfil = resultHeaderAdmin.data.perfil[0],
      )

      if(this.state.isCallGetUser){
        this.getUsers();
      }

    }

    passGen(e) {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.value !== "") {
            var crypto = require('crypto');
            $('#senhaPassGen').val(crypto.createHash('md5').update(e.target.value).digest("hex"));
            // $('#senhaPassGen').val(e.target.value);
        }
    }

    toggleNaved = () => {
        this.setState(prevState => ({
            isActive: !prevState.isActive
        }))
    }

    toggleNavedOk = () => {
        this.setState(prevState => ({
            isActiveOk: !prevState.isActiveOk
        }))
    }

    findUserIndex = async () => {
      let isFinish = false
      this.state.selecteds.forEach(item => {
        this.state.usersSelected.push(this.state.usuarios[item].id)

      });
      isFinish = true
      await (isFinish === true);
    }

    unbindUsers = () => {
      if(this.state.selectedRowKeys.length > 0) {
        this.rowsSelected(this.state.selectedRowKeys)
      }
      this.findUserIndex();
      this.setState({ selectedRowKeys: [] })

        axios.put(this.global.baseUrl+`usuario/gestor/${this.logInfo['usuarioPortal']['id']}/polo`, this.state.usersSelected, {
          headers: { 'Authorization': 'Bearer '+ localStorage.getItem('userDataCRP-AuthTk') }
        }).then(result => {
          Swal.fire({
            position: 'top-end',
            title: `Feito!`,
            text: 'Os usuarios foram desvinculados da sua gerencia',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          window.setTimeout(function(){ window.location.href = "../polos"; }, 2000);
        })
    }

    confirmarDados = () => {
        let dataUsuario = [{ "id": this.logInfo['usuarioPortal']['id'] }]
        axios.post(this.global.baseUrl + 'ctrValPoloUsuarios/', dataUsuario[0], {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk') }
        }).then(result => {
            $("html, body, .AppContent").animate({ scrollTop: 0 }, "slow");
            $('#return').html('<div class="notification is-primary"><span class="delete deleteNotification"></span><strong>' + result.data["mensagem"] + ' Redirecionado...</strong></div>');

            // atualizando as sessoes de primeiro acesso
            var atualizaSessao = JSON.parse(localStorage.getItem('userDataCRP-Login'))
            for (var j = 0, len = atualizaSessao.length; j < len; j++) {
                atualizaSessao[j]['tour'] = 'N'
                atualizaSessao[j]['bloqTelaValidUser'] = 'N'
            }
            localStorage.setItem('userDataCRP-Login', JSON.stringify(atualizaSessao));

            window.setTimeout(function () { window.location.href = "../home"; }, 2000);
        }).catch(error => {
            $("html, body, .AppContent").animate({ scrollTop: 0 }, "slow");
            $('#return').html('<div class="notification is-danger"><span class="delete deleteNotification"></span><strong>Erro ao salvar.</strong><br />Verifique as informações inseridas e tente novamente!</div>');
            return false;
        });

        $(document).on('click', '.deleteNotification', function (e) {
            e.preventDefault(); $('.deleteNotification').parent().remove();
        });
    }

    bloqueioBotoes() {

        const primeiroAcessoBotoes = JSON.parse(localStorage.getItem('userDataCRP-Login'));
        let bloqueio = false

        if (primeiroAcessoBotoes) {
            if (!bloqueio) {
                // eslint-disable-next-line
                primeiroAcessoBotoes.map((v, ok) => {
                    if (v['bloqTelaValidUser'] === 'S' || v['usuarioPortal']['primeiroAcesso'] === 'S') {
                        bloqueio = true
                    }
                })
            }
            return bloqueio
        }
    }

    logout() {
        localStorage.setItem("userDataCRP", "");
        localStorage.setItem("userDataCRP-Login", "");
        localStorage.setItem("userDataCRP-Polo", "");
        localStorage.setItem("userDataCRP-Polos", "");
        localStorage.clear();
        authProvider.logout();
        this.setState({ redirect: true });
        return (<Redirect to={'/login'} />)
    }

    onSelectChange = selectedRowKeys => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
        });
    };

    changePage = e => {
        if(this.state.selectedRowKeys.length > 0) {
          this.rowsSelected(this.state.selectedRowKeys)
        }
        this.setState({ page: e.current, selectedRowKeys: [] })
        this.getUsers();
    }

    rowsSelected = async (selectedRowKeys) => {
        let finish = false;
        let page = this.state.page;
        for (let i = 0; i < selectedRowKeys.length; i++) {
            if (page > 1) {
                let id = `${(page - 1)}${selectedRowKeys[i]}`
                this.state.selecteds.push(parseInt(id))
            } else if (page == 1) {
                this.state.selecteds.push((selectedRowKeys[i] * page))
            }
        }
        finish = true
        await finish;
    }

    render() {
        const antIcon = <Icon type="loading" style={{ fontSize: 48 }} spin />;
        const tableColumns = [
            {
                title: 'Nome',
                key: 'nome',
                dataIndex: 'nome',
            },
            {
                title: 'Email',
                key: 'conta',
                dataIndex: 'conta',
            },
            {
                title: 'Polos',
                key: '',
                dataIndex: '',
                render: (polos) => {
                    let result = []
                    {
                        polos.lstPolos && polos.lstPolos.map(polo => [
                            result.push(<small key={"polo-gen-" + polo.id}>{polo.nome}</small>,
                                <br key={"polo-quebra-" + polo.id} />)
                        ])
                    }
                    return result;
                },
            },
            {
                title: 'Acesso Sistêmico',
                key: 'situacaoUsuarioId',
                dataIndex: 'situacaoUsuarioId',
                render: (acesso) => {
                    if (acesso !== null) {
                        let objTag = {};
                        switch (acesso) {
                            case 1:
                                objTag = { tag: 'ativo', color: '#87d068' };
                                break;
                            case 2:
                                  objTag = { tag: 'inativo', color: '#727272' };
                                  break;
                            case 3:
                                objTag = { tag: 'bloqueado', color: 'purple' };
                                break;
                            default:
                                break;
                        }
                        return (
                            <span>
                                <Tag color={objTag.color} key={objTag.tag}>
                                    {objTag?.tag?.toUpperCase()}
                                </Tag>
                            </span>
                        );
                    }
                },
            },
            {
                title: 'Ultimo acesso',
                key: 'ultimoAcesso',
                dataIndex: 'ultimoAcesso',
                render: (acesso) => {
                  return <span>{acesso == null ? 'N/A' : acesso}</span>
                }
            }
          ];

        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
        };
        const hasSelected = selectedRowKeys.length > 0;
        const { isLoading, error, primeiroAcesso, tour, usuarios } = this.state;
        const bloqueado = this.bloqueioBotoes()
        let tourHidden = (tour && primeiroAcesso ? 'is-hidden' : '')

        if (!tour && usuarios.length > 0) {
            tourHidden = 'is-hidden'
        }

        if (this.state.redirect) { return (<Redirect to={'/login'} />) }

        var tituloPagina = ''
        if (primeiroAcesso) {
            tituloPagina = 'Olha, primeiro acesso!'
        } else {
            tituloPagina = 'Vamos atualizar a base de dados?'
        }

        if (error) { return <div className="container is-danger is-text-centered">{error.message}</div>; }

        if (isLoading) { return (<div className="CRP-loading"><img src={avatar} width="100" alt="Carregando..." /></div>) }

        return [
            <Helmet
                key="TitleLogin"
                title={tituloPagina + " | Portal Prisma - Kroton"}
                meta={[
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'description', content: 'Portal Unificado da Kroton Educacional' },
                    { name: 'keywords', content: 'kroton, educacional, portal' },
                ]}
            />,
            <section className="section conteudoAdmin primeiroAcesso" key="conteudoPortais">
                <div className="container">
                    <div className="animation-box">
                        <div className="header">
                            <div className="col-4">
                                <img src={crp} alt="Portal Prisma - Kroton" width="120" />
                            </div>
                            <div className="col-8 text-end">
                                <p className="margin-1em-top">Seja bem vindo, {this.logInfo.usuarioPortal.pessoa['nome']} <span role="img" aria-label="😀">😀</span><br /></p>
                            </div>

                        </div>

                    </div>
                    {/* se primeiro acesso */}
                    {primeiroAcesso &&
                        <form
                            id="dataForm" autoComplete="no"
                            encType='application/json'
                            action={this.state.salvarURL}
                            className={"apresentacaoDescricao delay formSenha"}
                        >
                            <div className="columns">
                                <div className="column is-full has-text-centered">
                                    <h5 className="title is-5">Primeiro, precisaremos atualizar a senha padrão. Só um detalhe, a nova senha deve:</h5>
                                    <ul>
                                        <li>- Ter minimo de 6 caracteres</li>
                                        <li>- Conter uma letra maiúscula</li>
                                        <li>- Conter um caracter especial</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="columns is-multiline is-mobile">
                                <div className="column"></div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label" htmlFor="password">Senha</label>
                                        <input name="senhaAlterar"
                                            id="senhaAlterar"
                                            type="password"
                                            className="input"
                                            placeholder="******"
                                            maxLength={14}
                                            required
                                            onBlur={this.passGen}
                                            autoComplete="off"
                                        // value={fields.password}
                                        />
                                        <input name="senha"
                                            id="senhaPassGen"
                                            type="hidden"
                                        />
                                        <input name="usuarioPortalLog[id]"
                                            value={this.logInfo['usuarioPortal']['id']}
                                            type="hidden"
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label" htmlFor="repetirSenha">Repita a senha</label>
                                        <input name="repetirSenha"
                                            id="repetirSenha"
                                            type="password"
                                            className="input"
                                            placeholder="******"
                                            maxLength={14}
                                            required
                                        // value={fields.password}
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">&nbsp;</label>
                                        <button className="button is-success" id="sendButton">
                                            <i><FontAwesomeIcon icon={["fas", "check"]} /></i> &nbsp; Ok, próximo passo!
                                        </button>
                                    </div>
                                </div>
                                <div className="column"></div>
                            </div>
                        </form>}

                    {/* acrescentar class is-hidden no processo de primeira troca de senha */}
                    {tour && <div className={"apresentacaoConfirmacao delay " + tourHidden}>
                        {usuarios.length > 0 &&
                            <div className="columns is-multiline">
                                <div className="column is-full has-text-centered">
                                    <h5 className="title is-5">
                                        Prezado(a) Gestor(a), por favor valide os colaboradores abaixo que estão sob sua gestão, inative os
                                        que não prestam mais serviçoes ao Polo ou não reconhece como funcionário do Polo.
                                    </h5>
                                </div>
                                <div className="column is-full has-text-centered">
                                    <Table
                                        pagination={this.pagination}
                                        rowSelection={rowSelection}
                                        dataSource={usuarios}
                                        columns={tableColumns}
                                        onChange={this.handleTableChange}
                                        disabled={true}
                                        size="small"
                                    />
                                    <button onClick={this.unbindUsers} className="button"
                                        style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', color: '#FFFFFF', marginBottom: '1rem'}}>
                                        <i><FontAwesomeIcon icon={["fas", "check"]} /></i> &nbsp; Desvincular usúario(s) selecionado(s)
                                    </button>
                                    <div className="field buttons is-centered padding-20-bottom">
                                        <div className="control">
                                            {!bloqueado &&
                                                <button onClick={this.toggleNaved} className="button is-danger">
                                                    <i><FontAwesomeIcon icon={["fas", "exchange-alt"]} /></i> &nbsp;
                                                    Posso fazer isso depois?!
                                                </button>}
                                            <button onClick={this.confirmarDados} className="button is-success cadastrarComunicado">
                                                <i><FontAwesomeIcon icon={["fas", "check"]} /></i> &nbsp; Confirmo os dados!
                                            </button>
                                            {bloqueado &&
                                                <button className="button is-danger" onClick={this.logout}>
                                                    <i><FontAwesomeIcon icon={["fas", "sign-out-alt"]} /></i> &nbsp; Sair
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="apresentacaoDescricao delay has-text-centered delay-ocult">
                                        <p>Obrigado por acessar o Prisma, um portal feito especialmente para facilitar a sua vida.</p>

                                        <p className="has-text-danger sair">
                                            <small>Deseja entrar com outro usuário? <strong onClick={this.logout}>Clique aqui</strong></small>
                                        </p>

                                        <br />
                                        {/* se for primeiro acesso */}
                                        {primeiroAcesso && <p>
                                            Como é seu primeiro acesso, precisamos confirmar ou atualizar algumas informações antes de você acessar todo o nosso conteudo. Vamos lá?!
                                        </p>}
                                    </div>
                                </div>
                            </div>
                        }
                        {usuarios.length <= 0 &&
                            <div className="field buttons is-centered">
                                <div className="column is-full has-text-centered">
                                    <h5 className="title is-5">Você ainda não tem usuários sob sua gestão.</h5>
                                    <p>Agora é só acessar o portal.</p>
                                </div>
                                <div className="control">
                                    {!bloqueado &&
                                        <button onClick={this.toggleNaved} className="button is-danger">
                                            <i><FontAwesomeIcon icon={["fas", "exchange-alt"]} /></i> &nbsp;
                                            Posso fazer isso depois?!
                                        </button>}
                                    {!bloqueado &&
                                        <a href="./usuariosPolo" className="button is-warning">
                                            <i><FontAwesomeIcon icon={["fas", "exclamation-triangle"]} /></i> &nbsp;
                                            Existem alterações
                                        </a>
                                    }
                                    <button onClick={this.confirmarDados} className="button is-success cadastrarComunicado">
                                        <i><FontAwesomeIcon icon={["fas", "check"]} /></i> &nbsp; Confirmo os dados, quero acessar o Prisma
                                    </button>
                                    {bloqueado &&
                                        <button className="button is-danger" onClick={this.logout}>
                                            <i><FontAwesomeIcon icon={["fas", "sign-out-alt"]} /></i> &nbsp; Sair
                                        </button>
                                    }
                                </div>
                            </div>}
                    </div>}
                    {!tour &&
                        <div className={"apresentacaoConfirmacao delay " + tourHidden}>
                            <div className="columns is-multiline">
                                <div className="column is-full has-text-centered">
                                    <h5 className="title is-5">Auditoria de Usuários (remover Listagem de Usuários)!</h5>
                                    <hr />
                                    <Spin indicator={antIcon} />
                                </div>
                            </div>
                        </div>
                    }

                    {!bloqueado &&
                        <div className={this.state.isActive ? 'modal is-active' : "modal"}>
                            <div className="modal-background"></div>
                            <div className="modal-card">
                                <header className="modal-card-head">
                                    <p className="modal-card-title">Sem problema!</p>
                                    <button className="delete" aria-label="close" onClick={this.toggleNaved}></button>
                                </header>
                                <section className="modal-card-body">
                                    Você ainda terá alguns dias para fazer essa checagem, até lá você poderá acessar normalmente!
                                </section>
                                <footer className="modal-card-foot">
                                    <a href="./home" className="button is-success">Entendi, eu faço isso em outro momento...</a>
                                    <button className="button" onClick={this.toggleNaved}>Voltar</button>
                                </footer>
                            </div>
                        </div>}

                    <div className={this.state.isActiveOk ? 'modal is-active' : "modal"}>
                        <div className="modal-background"></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Ótimo!</p>
                                <button className="delete" aria-label="close" onClick={this.toggleNavedOk}></button>
                            </header>
                            <section className="modal-card-body">
                                Clique no botão abaixo para ser redirecionado a tela de gerenciamento de usuários.
                            </section>
                            <footer className="modal-card-foot">
                                <a href="./usuariosPolo" className="button is-success">Gerenciar usuários</a>
                                <button className="button" onClick={this.toggleNavedOk}>Voltar</button>
                            </footer>
                        </div>
                    </div>

                </div>
            </section>
        ];
    }

}

export default Polos;
