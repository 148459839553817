import React, { useCallback, useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Table,
  Modal,
  Upload,
  Icon
} from 'antd';
import {
  HeaderAdmin,
  FooterAdmin,
  Hero,
  Helmet,
  FontAwesomeIcon,
  MenuDoc
} from '../../../../services/Gerais';
import '../../../../pages/Administrador/Workflow/styles.scss';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { onGetDocs, onGetCSVs, handleDownload } from '../../../../pages/Administrador/Workflow/FileImport/service.js';
import { saveAs } from 'file-saver';
import { useSearch } from './service.js';
import { csv2json } from '../../../../utils/csvToJson';
import config from '../../../../config/config';


const { Option } = Select;


const WorkFlow = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listImport, setListImport] = useState([]);
  const [search, setSearch] = useState({ date: '', status: '' })

  const urlFileDemo = process.env.PUBLIC_URL + '/arquivo-demostracao-status-documento.csv'

  const {
    uploadPropsDocs,
    handleImportFile,
    isDisabled
  } = useSearch();

  const handleModal = () => {
    if (!isModalOpen) {
      setIsModalOpen(true)
    } else {
      setIsModalOpen(false)
    }
  }

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Arquivo',
      key: 'nomeArquivo',
      dataIndex: 'nomeArquivo'
    },
    {
      title: 'Data Importação',
      key: 'dataInclusao',
      dataIndex: 'dataInclusao'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'Ação',
      key: 'id',
      dataIndex: 'id',
      render: (row, record, index) => {
        return (
          <Button
            type="primary"
            size="default"
            className="is-warning"
            onClick={() => handleDownloadCSV(row, record.nomeArquivo)}
          >
            <FontAwesomeIcon icon={['fas', 'download']} /> &nbsp;
            Download
          </Button>
        )
      }
    }
  ]

  useEffect(() => {
    onSearch();
  }, []);

  function handleSearch(type, value) {
    if (type === 'date') {
      search.date = value;
    } else {
      search.status = value;
    }
  }

  async function onSearch() {
    const data = await onGetCSVs(search);
    setListImport(data.data.sort(sortData))
  }

  function sortData(a, b) {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
    return 0;
  }

  async function handleDownloadCSV(id, fileName) {
    await handleDownload(id, fileName);
  }

  return (
    <>
      <Helmet
        key="Importação status de documento"
        title="Importação status de documento"
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1"
          },
          {
            name: "description",
            content: 'Importação status de documento' + '- Portal Prisma'
          },
          { name: "keywords", content: "kroton, educacional, portal" }
        ]}
      />
      <HeaderAdmin key="HeaderImportDoc" />
      <section className="columns is-multiline conteudoAdmin">
        <MenuDoc key="MenuImportDoc" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroImportDoc"
                pageName={`Importação de status de documentos do polo`}
                titulo={`Importação de arquivos`}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div className="button-wrapper">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => handleModal()}
                  >
                    <FontAwesomeIcon icon={['fas', 'file-import']} /> &nbsp;
                    Importar arquivo
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Row xl={24}>
            <Col xl={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
                locale={locale}
                size="default"
                style={{ width: '100%' }}
                onChange={(e) => handleSearch("date", e.format('DD/MM/YYYY'))}
              />
            </Col>
            <Col xl={6} className="margin-1-left">
              <Select
                size="default"
                placeholder="Selecione..."
                onChange={(e) => handleSearch("status", e)}
              >
                <Option key="AGUARDANDO_PROCESSAMENTO">Aguardando processamento</Option>
                <Option key="PROCESSADO_SUCESSO">Processado - Sucesso</Option>
                <Option key="PROCESSADO_ERRO">Processado - Erro</Option>
              </Select>
            </Col>
            <Col xl={4} className="margin-1-left">
              <Button
                type="primary"
                style={{
                  'background-color': '#52c41a',
                  border: 'none',
                  marginRight: '8px',
                }}
                onClick={onSearch}
              >
                <FontAwesomeIcon icon={['fas', 'search']} /> &nbsp;
                Pesquisar
              </Button>
            </Col>
          </Row>
          <Row xl={24} className="margin-1-top">
            <Table
              dataSource={listImport}
              columns={columns}
              size="small"
            />
          </Row>
        </div>
        <Modal
          title="Importação status documentos"
          visible={isModalOpen}
          okText="Enviar arquivo"
          onOk={async () => await handleImportFile()}
          onCancel={handleModal}
          destroyOnClose={true}
          width={600}
          centered>
          <>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label={<span>Documento</span>} size="large" style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <Upload {...uploadPropsDocs} /**/>
                    <Button disabled={isDisabled}>
                      <Icon type="upload" /> Importar documento
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <a
                href={urlFileDemo}
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                target='_blank'>
                  Baixe aqui o template do arquivo de importação!
              </a>
            </Row>
          </>
        </Modal>
      </section>
    </>
  );
}

export default WorkFlow;
