
export const listComunicados = (data) => {
  switch (data.descricao) {
    case 'Comercial':
      return 1;
    case 'Operações':
      return 2;
    case 'Acadêmico':
      return 3;
    case 'Financeiro':
      return 4;
    case 'Pós-Graduação':
      return 5;
  }
};
