import api from '../api';

export const sendReport = async (params) => {
  return await api.post(`/status/documentos/upload`, params.body)
}

export const onGetDocsStructure = async (params) => {
  let requestParams = [
    params.documento !== "" ? `&documento=${params.documento}` : '',
    params.polo !== "" ? `&polo=${params.polo}` : '',
    params.status !== "" ? `&status=${params.status}` : '',
    params.validade !== "" ? `&validade=${params.validade}` : '',
  ].join('');

  return await api.get(`/status/documentos/filter${requestParams.replace('&' , '?')}`)
}

export const onGetPolosAdmin = async () => {
  let typeUser = JSON.parse(localStorage.getItem('userDataCRP-Polos'))[0].usuarioPortal.funcionario
  return await api.get(`polo/getPoloByTipoPolo/${typeUser}`);
}

export const onUpdateDocsStructure = async (id, params) => {
  return await api.patch(`status/documentos/${id}`, params);
}

export const onExportCSV = async (relatorioConfig) => {
  return await api.post('relatorio/exportCsv', relatorioConfig);
}

export const onGetCSV = async ({date, status}) => {
  let requestParams = [
    date !== "" ? `&data=${date}` : '',
    status !== "" ? `&status=${status}` : '',
  ].join('');

  return await api.get(`/status/documentos/importacoes${requestParams.replace('&' , '?')}`)
}

export const download = async (id) => {
  return await api.get(`/status/documentos/${id}`)
}

export const onGetDocsOnBase = async () => {
  return await api.get(`/status/documentos/tiposDocsOnbase`)
}

export const sendDocument = async (form) => {
  return await api.post(`/status/documentos/uploadImageOrPdf`, form, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const deleteSubscribe= async (id, idTipoDocumento) => {
  return await api.delete(`/geradoc/assinantes/${id}/${idTipoDocumento}`)
}


