import React from 'reactn';
import AgendaHome from '../../pages/Agenda/AgendaHome';
import api from 'services/api';
import $ from 'jquery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';

import Carousel from 'nuka-carousel';
import { logBanner } from '../../config/logs';
class BannerHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: false,
      isShowingMore: false,
      isAgenda: false,
      banners: [],
      carregando: true,
    };
  }

  openTour() {
    $('#tour-inicial').trigger('click');
  }

  componentDidMount() {
    this.getIsMenuAgenda();
  }

  getIsMenuAgenda = async () => {
    setTimeout(() => {
      let menuStorage = JSON.parse(localStorage.getItem('userDataCRP-Menu'));
      let boolIsAgenda = false;

      if (menuStorage) {
        menuStorage.map((menu, i) => {
          if (menu.url === 'agenda') {
            boolIsAgenda = true;
          }
          return menu;
        });
      }
      return this.setState({ isAgenda: boolIsAgenda });
    }, 1000);

    let logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    if (logInfo) {
      try {
        const res = await api.get(
          `banner/findByUsuarioPoloAndAtivo/${logInfo.id}`
        );

        this.setState({ banners: res.data.Banners, carregando: false });
      } catch (error) {
        this.setState({ carregando: false });
      }
    }
  };
  render() {
    const { banners, carregando } = this.state;

    return [
      <div style={{ display: 'block'}}>
        <div
          className="container-header is-fullhd BannerHomeContainer"
          key="bannerList"
        >
          {carregando ? (
            <Skeleton />
          ) : (
            <>
              {banners && banners.length === 1 ? (
                banners[0].linkBanner ? (
                  <a
                    target="_blank"
                    style={{ display: 'flex' }}
                    href={banners[0].linkBanner}
                    rel="noopener noreferrer"
                  >
                    <img src={banners[0].pathImagem} alt="Banner 01" />
                  </a>
                ) : (
                  <img src={banners[0].pathImagem} alt="Banner 01" />
                )
              ) : (
                <div className="mini-agenda">
                  <div className="column" style={{ padding: '0.3rem' }}>
                    <AgendaHome
                      key={'agenda-home-call'}
                      isAgenda={this.state.isAgenda}
                    />
                  </div>
                  <Carousel
                    slidesToShow={1}
                    width={'100%'}
                    cellAlign="center"
                    cellSpacing={90}
                    autoplay={false}
                    autoplayInterval={5000}
                    wrapAround={false}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <button
                        onClick={previousSlide}
                        style={{ borderRadius: '50%' }}
                      >
                        <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                      </button>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <button
                        onClick={nextSlide}
                        style={{ borderRadius: '50%' }}
                      >
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                      </button>
                    )}
                  >
                    {banners &&
                      banners.map((banner) => {
                        if (banner.linkBanner) {
                          if (banner.novaAba === 'S') {
                            return (
                              <a
                                target="_blank"
                                style={{ display: 'flex' }}
                                href={banner.linkBanner}
                              onClick={() => logBanner(banner.id)}
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={banner.pathImagem}
                                  alt=""
                                  style={{ width: '100%', height: '100%' }}
                                />
                              </a>
                            );
                          } else {
                            return (
                              <a
                                style={{ display: 'flex' }}
                                href={banner.linkBanner}
                              >
                                <img
                                  src={banner.pathImagem}
                                  alt=""
                                  style={{ width: '100%', height: '100%' }}
                                />
                              </a>
                            );
                          }
                        } else {
                          return (
                            <img
                              alt=""
                              src={banner.pathImagem}
                              style={{ width: '100%', height: '100%' }}
                            />
                          );
                        }
                      })}
                  </Carousel>
                </div>
              )}
            </>
          )}
        </div>
      </div>,
    ];
  }
}
export default BannerHome;
