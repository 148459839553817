import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 10px;
  line-height: 40px;
`;

export const Title = styled.div`
  background-color: #084982;
  color: #fff;
  padding: 0 10px;
  border: 1px solid #084982;
`;

export const News = styled.div`
    padding: 0 95px 0 15px;
    border: 1px solid #59bde9;
    background: #59bde9;
    flex:1;
    a {
      color: #011e4d;
    }
`

export const Arrows = styled.div`
    display: flex;
    button {
        background-color: #084982;
        color: #fff;
        padding: 0 15px;
        border: 1px solid #eee;
        cursor:pointer;

        &:disabled{
            background-color: #eee;
        }
    }
    button:active {
      transform: translateY(2px);
    }

`
