import React, { useState, useEffect } from 'react';
import { Form, Button, Icon } from 'antd';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
  FontAwesomeIcon,
} from '../../../../services/Gerais';

import './styles.css';
import UsuariosSection from './UsuariosSection';

const Lista = (props) => {
  const [tituloPagina, setTituloPagina] = useState(0);
  useEffect(() => {
    setTituloPagina('Listar Usuários');
  }, []);

  return (
    <>
      <Helmet
        key="TitleAdminUsuarioLista"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, usuarios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAdminUsuarioLista" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminUsuarioLista" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminListaUsuarios"
                pageName="Administração - Usuários"
                titulo={tituloPagina}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div
                  className="button-wrapper"
                  id="components-dropdown-demo-dropdown-button"
                >
                  <Button
                    type="primary"
                    size="large"
                    href={`/admin/importacao-usuarios`}
                  >
                    <FontAwesomeIcon icon={['fas', 'user-plus']} /> &nbsp;
                    Importação de Usuários
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    href={`/admin/usuarios/cadastro`}
                  >
                    <FontAwesomeIcon icon={['fas', 'user-plus']} /> &nbsp; Novo
                    Usuario
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    href={`/admin/usuarios/vincular-gn`}
                  >
                    <FontAwesomeIcon icon={['fas', 'user-plus']} /> &nbsp;
                    Vincular GN
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <UsuariosSection form={props.form} history={props.history} />
        </div>
      </section>
      <FooterAdmin key="FooterAdminUsuarioLista" />
    </>
  );
};

export default Form.create()(Lista);
