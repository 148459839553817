import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
    Error,
    $,
    Helmet,
    Header,
    Footer,
    Hero,
    axios,
    FontAwesomeIcon,
    moment,
} from '../../services/Gerais';
import { Switch, Table } from 'antd';
import { Calendar } from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import gregorian_br_lowercase from "../../utils/gregorian_br_lowercase"
import Swal from 'sweetalert2';
import '../../styles/AgendaProvas.scss';
import ListaSala from './components/ListaSala'

export default class AgendaProvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      horarios: [],
      quantidadeSalas: 1,
      salasBuscadas: [],
      activePage: true,
      tableHead: [],
      tableRows: [],
    }
    this.poloId = '';
    this.baseUrl = '';
    this.diasSelecionados = [];
    this.horariosSelecionados = [];
    this.salas = [];

    this.setDias = this.setDias.bind(this);
    this.setHorarios = this.setHorarios.bind(this);
    this.setSalas = this.setSalas.bind(this);
    this.saveAgendaProva = this.saveAgendaProva.bind(this);
    this.adcionarSala = this.adcionarSala.bind(this);
    this.limpaSalas = this.limpaSalas.bind(this);
    this.trocaPagina = this.trocaPagina.bind(this);
    this.buscaProvas = this.buscaProvas.bind(this);
    this.montaTabela = this.montaTabela.bind(this);
    this.changeCapacidade = this.changeCapacidade.bind(this);
  }

  componentDidMount() {
    if (!localStorage.getItem('userDataCRP')) {
      return <Redirect to={'/login'} />;
    }

    this.baseUrl = JSON.parse(localStorage.getItem('cnfgDataCRP')).baseUrl
    this.poloId =  $('.selectPoloHeader select').val() || localStorage.getItem('userDataCRP-Polo');

    axios
      .get(this.baseUrl + 'agendaProvaHorario/' + this.poloId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
        data: {}
      }).then(res => {
        this.setState({
          horarios: res.data.horarios || [],
        });
      }).catch(error => {
        this.setState({
          error: error
        });
      });

    axios
      .get(this.baseUrl + 'agendaProvaSala/' + this.poloId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
        data: {}
      }).then(res => {
        this.setState({
          salasBuscadas: res.data.horarios || [],
        })
      })
  }

  setDias(e) {
    this.diasSelecionados = [];
    e.forEach(dia => {
      this.diasSelecionados.push(`${dia.year}-${dia.month.number.toString().padStart(2, '0')}-${dia.day.toString().padStart(2, '0')}`);
    })
  }

  setHorarios(checked, target) {
    this.horariosSelecionados = [];
    let self = this;
    let check = $(target.currentTarget);
    let valores = $(check.siblings('input'))
    checked ? valores.addClass('horario-checked') : valores.removeClass('horario-checked')

    $('.horario-checked').each((i, horario) => {
      self.horariosSelecionados.push({
        "id": horario.id,
        "idPolo": self.poloId,
        "horario": horario.value
      })
    })
  }

  setSalas() {
    let self = this;
    let arraySalas = []

    $('.salaRow').each((i, salaRow) => {
      let select = $(salaRow).find('#sala');
      let nome = $(salaRow).find('#nomeSala');
      let qnt = $(salaRow).find('#capacidade');

      if(!select.val() || !nome.val() || !qnt.val())
        return false;

      arraySalas.push({
        "id": select.val() == 'new' ? '' : select.val(),
        "idPolo": self.poloId,
        "capacidade": qnt.val().toString(),
        "nomeSala": nome.val().trim()
      })
    })

    this.salas = arraySalas;

    return true;
  }

  adcionarSala() {
    this.setState({
      quantidadeSalas: ++this.state.quantidadeSalas,
    })
  }

  limpaSalas() {
    this.setState({
      quantidadeSalas: 0,
    })
  }

  saveAgendaProva(e) {
    let botao = $(e.target);

    if(!this.setSalas()) {
      Swal.fire({
        title:  'Atenção!',
        text: 'Preencha todos os campos!',
        icon: 'error',
      })
      return;
    }

    if(this.poloId && this.diasSelecionados.length && this.horariosSelecionados.length && this.salas.length) {
      let json = {
        "idPolo": this.poloId,
        "lstDataProva": this.diasSelecionados,
        "lstHorarios": this.horariosSelecionados,
        "lstSalas": this.salas,
      }

      botao.attr('disabled', true).html('Processando, aguarde');

      axios
        .post(this.baseUrl + 'agendaProva/', json,{
          headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }).then(res => {
          let erros = res.data.Errors;

          if(erros.length) {
            Swal.fire({
              title:  'Salvo Parcialmente!',
              html: `Alguns agendamentos foram salvos, entretanto, ocorreram as seguintes excessões:
                <textarea style="width: 100%; height: 132px" readonly>${erros.toString().replace(/,/g, '\n')}</textarea>`,
              icon: 'warning',
            }).then(r => window.location.reload())
          } else {
            Swal.fire({
              title:  'Salvo!',
              text: 'Agendamento salvo com sucesso!',
              icon: 'success',
            }).then(r => window.location.reload())
          }
          botao.attr('disabled', false).html('Salvar');
        }).catch(error => {
          let erros = error.response.data.Errors;

          if(erros.length) {
            Swal.fire({
              title:  'Atenção!',
              html: `Não foi possível salvar os dados.
                <textarea style="width: 100%; height: 132px" readonly>${erros.toString().replace(/,/g, '\n')}</textarea>`,
              icon: 'warning',
            })
          } else {
            Swal.fire({
              title:  'Erro!',
              text: 'Erro ao salvar! Verifique as informações ou tente novamente mais tarde!',
              icon: 'error',
            })
          }

          botao.attr('disabled', false).html('Salvar');
        })
    } else {
      Swal.fire({
        title:  'Atenção!',
        text: 'Preencha todos os campos!',
        icon: 'error',
      })
    }
  }

  trocaPagina() {
    // true = editar, false = agendar
    this.setState({
      activePage: !this.state.activePage,
    })
  }

  buscaProvas(e, page) {
    let [dataInicial, dataFinal] = [ $('#dataInicial').val(), $('#dataFinal').val() ];
    let salaSelecionada = $('#sala').val();
    if(!(dataInicial && dataFinal)) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Preencha todos os campos obrigatórios!',
        icon: 'error',
      })
    }
    dataInicial = dataInicial.split('/').reverse().join('-');
    dataFinal = dataFinal.split('/').reverse().join('-');

    let url = ['agendaProva/', this.poloId, '/', dataInicial, '/', dataFinal, '/', salaSelecionada, '?linesPerPage=10000'].join('');

    axios.get(this.baseUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      },
      data: {}
    }).then(res => {
      this.montaTabela(res.data)
    })
  }

  changeCapacidade(e) {
    let button = $(e.target).closest('.icon');
    let input = button.siblings();
    let newVal = input.val();
    let oldVal = input[0].defaultValue;
    let id = input.parent().prev().prev().html();

    if(newVal < 0) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Valor não pode ser menor que 0!',
        icon: 'error',
      })
      input.val(oldVal);
    } else {
      let body = {
        "id": id,
        "capacidade": newVal
      }
      axios.
        put(this.baseUrl + 'agendaProva/', body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          }
        }).then(res => {
          Swal.fire({
            title: 'Sucesso',
            text: 'Salvo com sucesso!',
            icon: 'info',
          })
        }).catch(err => {
          Swal.fire({
            title: 'Atenção!',
            text: err.response.data.Mensagem,
            icon: 'error',
          })
        })
    }
  }

  montaTabela(response) {
    let self = this;

    let head = [
      {
        title: 'Código',
        dataIndex: 'id',
        key: 'id',
        width: '100px',
      },
      {
        title: 'Sala',
        dataIndex: 'sala',
        key: 'sala',
        width: '150px',
      },
      {
        title: 'Capacidade de Pessoas',
        dataIndex: 'capacidade',
        key: 'capacidade',
        render: text =>
          <>
            <input type="number"
              className="is-noborder"
              defaultValue={text}>
            </input>
            <span className="icon" onClick={this.changeCapacidade}>
              <FontAwesomeIcon icon={["fas", "save"]}/>
            </span>
          </>,
        width: '400px',
      },
      {
        title: 'Data da Prova',
        dataIndex: 'data',
        key: 'data',
        width: '150px',
      },
      {
        title: 'Horário',
        dataIndex: 'horario',
        key: 'horario',
        width: '150px',
      },
    ]

    let rows = response.AgendaProva.content.map((agenda, index) => {
      return {
        key: index + 1,
        id: agenda.id,
        sala: agenda.agendaProvaSala.nomeSala,
        capacidade: agenda.capacidade,
        data: agenda.dataProva.replace(/-/g, '/'),
        horario: agenda.agendaProvaHorario.horario.split(' ')[1].substring(5, 0),
      }
    })

    self.setState({
      tableHead: head,
      tableRows: rows
    })
  }

  render() {
    const { error,
            horarios,
            quantidadeSalas,
            salasBuscadas,
            activePage,
            tableHead,
            tableRows,
          } = this.state;

    return(
      <>
        <Helmet
          key="TitleAdminAgendaCadastro"
          title="Agendamento de Provas | Portal Prisma"
          meta={[
            {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
            },
            {
            name: 'description',
            content: 'Cadastro de agenda - Portal Prisma',
            },
            {
            name: 'keywords',
            content: 'cadastro, agenda, kroton, educacional, portal',
            },
          ]}
        />
        <Header key="HeaderAgendaProvas" />
        <section className="columns is-multiline">
          <div className="column is-12 is-fullheight conteudoAdminPagina">
            <Hero
              key="heroAgendaProvas"
              pageName="Agendamento Provas"
              titulo="Agendamento"
            />
          </div>
        </section>
        <div className="container is-fullhd agendamentoProvas" id="agenda-provas">
          {error.response ? <Error  {...error}/> : null}
          <a
            href='javascript:void(0)'
            className={"button " + (activePage ? 'is-outlined' : 'is-info')}
            onClick={activePage ? this.trocaPagina : null}>
              Agendar
          </a>
          <a
            href='javascript:void(0)'
            className={"button " + (activePage ? 'is-info' : 'is-outlined')}
            onClick={!activePage ? this.trocaPagina : null}>
              Consultar/Editar
          </a>
          {activePage ? (
            <div className="columns is-multiline searchProvas">
              <div className="column is-3">
                <div className="field">
                  <label htmlFor="sala">Sala</label>
                  <div className="control">
                    <div className="select fullwidth">
                      <select name="sala" id="sala" className="fullwidth" onChange={this.handleSelect}>
                        <option value="">Selecione...</option>
                        {salasBuscadas.map(sala => {
                          return (
                            <option value={sala.id}>{sala.nomeSala}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <span className="is-ptop-39">Período*: </span>
              <div className="column is-2">
                <div className="field">
                  <label for="dataInicial" className="is-vhidden">Data</label>
                  <input name="dataInicial" id="dataInicial" type="date" className="input"/>
                </div>
              </div>
              <span class="is-ptop-39">à</span>
              <div className="column is-2">
                <div className="field">
                  <label for="dataFinal" className="is-vhidden">Data</label>
                  <input name="dataFinal" id="dataFinal" type="date" className="input"/>
                </div>
              </div>
              <div className="column is-2">
                <a href='javascript:void(0)'
                  onClick={this.buscaProvas}
                  className="button is-info btnBuscarProvas"
                >
                  <FontAwesomeIcon icon={["fas", "search"]}/>
                  Pesquisar
                </a>
              </div>

              {tableHead.length && tableRows.length ? (
                <Table
                  columns={tableHead}
                  dataSource={tableRows}
                  size="middle"
                  pagination={{ pageSize: 13 }}
                />
              ) : null}
            </div>
          ) : null}
          {!activePage ? (
            horarios.length ? (
            <div>
              <section className="columns is-multiline agendaProvas">
                <div className="column is-12">
                  <span style={{ 'color': '#209cee' }}>
                    Selecione os dias e horários de provas, em seguida a Sala e a Capacidade
                  </span>
                </div>
                <div className="column is-4">
                  <div className="is-blue">
                    Selecione os dias de prova desejados:
                  </div>
                  <Calendar
                    multiple={true}
                    onChange={this.setDias}
                    format={'DD/MM/YYYY'}
                    locale={gregorian_br_lowercase}
                    minDate={moment().toDate()}
                    plugins={[
                      <DatePanel/>
                    ]}
                  />
                </div>
                <div className="column is-2">
                  <div className="is-blue">
                    Escolha os Horários:
                  </div>
                  <div className='columns is-multiline'>
                    {horarios.map(horario => {
                      return (
                        <label className={"column is-" + (horarios.length > 7 ? '6' : '12') + " horario-box"}>
                          <Switch onChange={this.setHorarios}/>
                          <input type="hidden" id={horario.id} idPolo={horario.idPolo} value={horario.horario}></input>
                          <span>{horario.horario.split(' ')[1].substring(0,5)}</span>
                        </label>
                      )
                    })}
                  </div>
                </div>
                <div className="column is-6">
                  <div className="is-blue">
                    Selecione a Sala e a Capacidade:
                  </div>
                  <div className="button-bar">
                    <a href='javascript:void(0)' className="button is-info" onClick={this.adcionarSala}>Adicionar Sala</a>
                    <a href='javascript:void(0)' className="button is-outlined" onClick={this.limpaSalas}>Limpar</a>
                  </div>
                  <div id="containerSalas">
                    {
                      Array.from(Array(quantidadeSalas), e => {
                        return (
                          <ListaSala lista={salasBuscadas}/>
                        )
                      })
                    }
                  </div>
                </div>
              </section>
              <div className="button-bar">
                <a href='javascript:void(0)'
                  onClick={this.saveAgendaProva}
                  className="button is-info is-aligned-down-right"
                >Salvar</a>
              </div>
            </div>
          ) :
          <div className="notification is-warning">
            <strong>
              Sentimos muito...
            </strong>
            <p>Não existem dados de agendamento para este polo!</p>
          </div>) : null}
        </div>
        <Footer key="FooterAgendaProvas" />
      </>
    )
  }
}
