import React, { Component } from 'reactn';
import {
  Error,
  Header,
  Footer,
  library,
  FontAwesomeIcon,
  $,
  Helmet,
  axios,
  TAGS,
  ReactTags,
  ComunicadoDisplay,
} from '../../services/Gerais';
import {
  faCheck,
  faArrowLeft,
  faArrowRight,
  faUserCircle,
  faShare,
  faBookmark,
} from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
import { Popover, Button, Row, Col, Icon, Input } from 'antd';
import Swal from 'sweetalert2';
library.add({
  faCheck,
  faBookmark,
  faArrowLeft,
  faArrowRight,
  faUserCircle,
  faShare,
});

class Sidebar extends Component {
  render() {
    const activeCateg = window.location.pathname.split('/').pop();
    return (
      <ul>
        <TabLabel
          active={activeCateg === 'comunicados'}
          text="Mais recentes"
          slug="comunicados"
        />
        <TabLabel
          active={activeCateg === 'financeiro'}
          text="Financeiro"
          slug="financeiro"
        />
        <TabLabel
          active={activeCateg === 'operacoes'}
          text="Operações"
          slug="operacoes"
        />
        <TabLabel
          active={activeCateg === 'comercial'}
          text="Comercial"
          slug="comercial"
        />
        <TabLabel
          active={activeCateg === 'academico'}
          text="Acadêmico"
          slug="academico"
        />
        <TabLabel
          active={activeCateg === 'pos'}
          text="Pós Graduação"
          slug="pos"
        />
      </ul>
    );
  }
}

const TabLabel = ({ active, text, onClick, slug }) => {
  if (active === false && slug === 'comunicados') {
    return (
      <li onClick={onClick} className={active ? 'is-active' : null}>
        <a href={'./../comunicados/'}>{text}</a>
      </li>
    );
  } else if (active === true && slug === 'comunicados') {
    return (
      <li onClick={onClick} className={active ? 'is-active' : null}>
        <a href={'./../comunicados/' + slug}>{text}</a>
      </li>
    );
  } else {
    return (
      <li onClick={onClick} className={active ? 'is-active' : null}>
        <a href={'./../lista-comunicados/' + slug}>{text}</a>
      </li>
    );
  }
};

const sugestoesTags = TAGS.map(x => {
  return { id: x.id, name: x.descricao };
});

export default class Categoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comunicados: [],
      showComunicado: [],
      error: [],
      props: props,
      isActived: false,
      tags: [],
      sugestoesTags: sugestoesTags,
      getComunicados: [],
      tituloComunicados: '',
      pagina: 1,
      paginaAnterior: 0,
      areas: [],
      pathArray: window.location.pathname.split('/'),
      isChecked: false,
      linkPaginacao: '',
    };
    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.mudarPagina = this.mudarPagina.bind(this);
    this.paginacaoCategoria = this.paginacaoCategoria.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.ordenarComunicados = this.ordenarComunicados.bind(this);
    this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this);
  }

  copyCodeToClipboard = comunicado => {
    let textField = document.createElement('textarea');
    textField.innerText = `${window.location.origin}/comunicados/${
      comunicado.id
    }`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    Swal.fire({
      position: 'top-end',
      title:  `Copiado!`,
      text: 'URL do comunicado foi copiada.',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  };

  geraComunicado = (e, comunicado, btn) => {
    e.preventDefault();

    let lido = comunicado.lido;
    let favorito = comunicado.favorito;

    if (btn === 'like') {
      if (favorito !== 'S') {
        favorito = 'S';
        lido = 'S';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'fas');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z'
          );
      } else {
        favorito = 'N';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'far');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z'
          );
      }
    } else {
      lido = 'S';
      $(document)
        .find('[data-comunicado-lido="' + comunicado.id + '"]')
        .addClass('lido');
      this.toggleNaved();
    }

    // marcando como lido
    const dataComunicadoUsuario = [
      {
        usuarioPortal: { id: this.logInfo['usuarioPortal']['id'] },
        comunicado: { id: comunicado.id },
        favorito: favorito,
        comunicadoLido: lido,
        usuarioPortalLog: { id: this.logInfo['usuarioPortal']['id'] },
      },
    ];

    axios
      .post(
        this.global.baseUrl + 'comunicadoUsuario/saveComunicadoUsuario',
        dataComunicadoUsuario[0],
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then(result => {
        ReactGA.modalview('/comunicado/' + comunicado.codigo);
        axios
          .get(
            this.global.baseUrl +
              'comunicadoUsuario/getComunicadoByComunicadoAndUsuario/' +
              this.logInfo['usuarioPortal']['id'] +
              '/' +
              comunicado.id,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
              },
            }
          )
          .then(infosRes =>
            this.setState({
              showComunicado: infosRes.data,
              isLoading: false,
            })
          )
          .catch(error =>
            this.setState({
              error,
              isLoading: false,
            })
          );
      })
      .catch(error => {
        // console.error(error)
      });

    this.componentDidMount();
  };

  getComunicadoParametros() {
    var tituloComunicados = '';
    if (this.state.props.match.params.binId === 'comercial') {
      tituloComunicados = 'Comercial';
    } else if (this.state.props.match.params.binId === 'operacoes') {
      tituloComunicados = 'Operações';
    } else if (this.state.props.match.params.binId === 'academico') {
      tituloComunicados = 'Acadêmico';
    } else if (this.state.props.match.params.binId === 'financeiro') {
      tituloComunicados = 'Financeiro';
    } else if (this.state.props.match.params.binId === 'pos') {
      tituloComunicados = 'Pos';
    } else {
      tituloComunicados = 'Comunicado';
    }

    // ajustando os resultados conforme FOCA ou tags
    var linkAcesso =
      this.global.baseUrl +
      'comunicado/getComunicadoUsuarioCategoria?id=' +
      this.logInfo['id'] +
      '&page=0&linesPerPage=9';
    var linkPaginacao =
      this.global.baseUrl +
      'comunicado/getComunicadoUsuarioCategoria?id=' +
      this.logInfo['id'];
    if (this.state.pathArray[1] === 'comunicados-tags') {
      linkAcesso =
        this.global.baseUrl +
        'comunicado/getComunicadoUsuarioTag?id=' +
        this.logInfo['id'] +
        '&tagId=' +
        this.state.pathArray[2] +
        '&page=0&linesPerPage=9';
      linkPaginacao =
        this.global.baseUrl +
        'comunicado/getComunicadoUsuarioTag?id=' +
        this.logInfo['id'] +
        '&tagId=' +
        this.state.pathArray[2];
      // this.setState({ tags: [this.state.pathArray[2]] })

      axios
        .get(this.global.baseUrl + 'tag/' + this.state.pathArray[2], {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then(infosResDisplay => {
          this.setState({
            tags: [
              JSON.parse(
                JSON.stringify(infosResDisplay.data)
                  .split('"descricao":')
                  .join('"name":')
              ),
            ],
          });
        })
        .catch(errorDisplay =>
          this.setState({
            errorDisplay,
            isLoading: false,
          })
        );
    }

    axios
      .all([
        axios.get(linkAcesso, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }),
        axios.get(
          this.global.baseUrl + 'comunicado/getDadosCadInitComunicado/',
          {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
          }
        ),
      ])
      .then(
        axios.spread((infosRes, areasRes) => {
          this.setState({
            getComunicados: infosRes.data,
            tituloComunicados: tituloComunicados,
            areas: areasRes.data['Area'],
            isLoading: false,
            linkPaginacao: linkPaginacao,
          });
        })
      )
      .catch(error =>
        this.setState({
          error: error,
          isLoading: false,
        })
      );
  }

  componentDidMount() {
    this.getComunicadoParametros();
    $('.react-tags__selected-tag').prop('title', 'Clique para excluir');
  }

  toggleNaved = () => {
    this.setState(prevState => ({ isActived: !prevState.isActived }));
  };

  handleDelete(i) {
    const { tags } = this.state;
    if (this.state.tags[i]) {
      $('#tagsComunCat')
        .find('input[value="' + this.state.tags[i]['id'] + '"]')
        .remove();
      $('#tagsComunCat')
        .find('input[value="' + this.state.tags[i]['descricao'] + '"]')
        .remove();
      this.setState({
        tags: tags.filter((tag, index) => index !== i),
      });

      var novoArray = tags.filter((tag, index) => index !== i);
      var novoLinkPaginacao,
        novoLinkGet = '';
      let idsRemocao = novoArray.map(a => parseInt(a.id));

      if (idsRemocao.length >= 1) {
        novoLinkGet =
          this.global.baseUrl +
          'comunicado/getComunicadoUsuarioTag?id=' +
          this.logInfo['id'] +
          '&tagId=' +
          idsRemocao +
          '&page=0&linesPerPage=9';
        novoLinkPaginacao =
          this.global.baseUrl +
          'comunicado/getComunicadoUsuarioTag?id=' +
          this.logInfo['id'] +
          '&tagId=' +
          idsRemocao;
      } else {
        novoLinkGet = this.state.linkPaginacao + '&page=0&linesPerPage=9';
        novoLinkPaginacao = this.state.linkPaginacao + '&page=0&linesPerPage=9';
      }

      axios
        .get(novoLinkGet, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then(removes =>
          this.setState({
            getComunicados: removes.data,
            tituloComunicados: 'Comunicado',
            linkPaginacao: novoLinkPaginacao,
          })
        )
        .catch(error =>
          this.setState({
            error,
            isLoading: false,
          })
        );
    }
    // acrescentar tag zero
    if (this.state.tags.length <= 1) {
      $('#tagsComunCat').append(
        '<input type="hidden" id="comunCatZero" name="lstComunicadoTag[][tag][id]" value="0" />'
      );
      this.getComunicadoParametros();
    }
  }

  handleAddition(tag) {
    var newArray = this.state.tags.slice();
    // if(newArray.length >= 1){
    //     $('.react-tags__selected.tagsComunicadoClass button:eq(0)').remove();
    // }
    // this.handleDelete(newArray[0])
    newArray.push(tag);
    this.setState({ tags: newArray });
    newArray.slice();

    let ids = newArray.map(a => parseInt(a.id));

    axios
      .get(
        this.global.baseUrl +
          'comunicado/getComunicadoUsuarioTag?id=' +
          this.logInfo['id'] +
          '&tagId=' +
          ids +
          '&page=0&linesPerPage=9',
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then(result =>
        this.setState({
          getComunicados: result.data,
          tituloComunicados: 'Comunicado',
          linkPaginacao:
            this.global.baseUrl +
            'comunicado/getComunicadoUsuarioTag?id=' +
            this.logInfo['id'] +
            '&tagId=' +
            ids,
        })
      )
      .catch(error =>
        this.setState({
          error,
          isLoading: false,
        })
      );
  }

  mudarPagina = (e, pg) => {
    e.preventDefault();
    axios
      .all([
        // this.global.baseUrl+'comunicado/getComunicadoUsuarioCategoria?id='+this.logInfo['id']+'&page='+(pg-1)+'&linesPerPage=9'
        axios.get(
          this.state.linkPaginacao + '&page=' + (pg - 1) + '&linesPerPage=9',
          {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
          }
        ),
      ])
      .then(
        axios.spread(infosRes => {
          this.setState({
            getComunicados: infosRes.data,
            isLoading: false,
            pagina: pg,
            paginaAnterior: pg <= 1 ? 1 : pg,
          });
        })
      )
      .catch(error =>
        this.setState({
          error: error,
          isLoading: false,
        })
      );
  };

  paginacaoCategoria = (pagina, total) => {
    let lista = [];
    let children = [];
    let calculo = total / 9;
    for (let j = 0; j < calculo; j++) {
      children.push(
        <a
          href="./"
          onClick={e => this.mudarPagina(e, j + 1)}
          className={'pagination-link ' + (pagina === j + 1 && 'is-current')}
          key={'paginationList' + (j + 1)}
        >
          {j + 1}
        </a>
      );
    }
    lista.push(
      <ul key={'paginationList'} className="pagination-list">
        {children}
      </ul>
    );
    return lista;
  };

  handleChecked = event => {
    this.setState({ isChecked: event.target.isChecked });

    var checkboxes = document.getElementsByName('areaInput[]');
    var checkboxesChecked = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxesChecked.push(parseInt(checkboxes[i].value));
      }
    }

    if (checkboxesChecked.length > 0) {
      axios
        .get(
          this.global.baseUrl +
            'comunicado/getComunicadoUsuarioArea?id=' +
            this.logInfo['id'] +
            '&areaId=' +
            checkboxesChecked +
            '&page=0&linesPerPage=9',
          {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
            },
          }
        )
        .then(result =>
          this.setState({
            getComunicados: result.data,
            tituloComunicados: 'Comunicado',
            linkPaginacao:
              this.global.baseUrl +
              'comunicado/getComunicadoUsuarioArea?id=' +
              this.logInfo['id'] +
              '&areaId=' +
              checkboxesChecked,
          })
        )
        .catch(error =>
          this.setState({
            error,
            isLoading: false,
          })
        );
    } else {
      this.getComunicadoParametros();
    }
  };

  ordenarComunicados = event => {
    function compareValues(key, order = 'asc') {
      return function(a, b) {
        if (
          !a['comunicado'].hasOwnProperty(key) ||
          !b['comunicado'].hasOwnProperty(key)
        ) {
          return 0;
        }

        const varA =
          typeof a['comunicado'][key] === 'string'
            ? a['comunicado'][key].toUpperCase()
            : a['comunicado'][key];
        const varB =
          typeof b['comunicado'][key] === 'string'
            ? b['comunicado'][key].toUpperCase()
            : b['comunicado'][key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
      };
    }

    // ordenacao forcada por conta de lido = null
    var alphabetically = function(ascending) {
      return function(a, b) {
        if (a === null) {
          return 1;
        } else if (b === null) {
          return -1;
        } else if (a === b) {
          return 0;
        } else if (ascending) {
          return a < b ? -1 : 1;
        } else if (!ascending) {
          return a < b ? 1 : -1;
        }
      };
    };

    let lista = this.state.getComunicados[this.state.tituloComunicados][
      'Comunicados'
    ];

    let novaLista = [];
    let listaOrdenada = lista.sort(compareValues('codigo', 'desc'));

    var filtro = event.target.value;
    var ordem = 'asc';

    if (filtro === 'prioridade') {
      ordem = 'desc';
    }
    if (filtro === 'lido') {
      ordem = 'desc';
    }
    if (filtro === 'naolido') {
      filtro = 'lido';
      listaOrdenada = lista.sort(alphabetically(false));
    }
    if (filtro === 'naocritico') {
      filtro = 'prioridade';
    }
    if (filtro === 'lastUpdated') {
      filtro = 'lastUpdated';
      ordem = 'desc';
    }
    if (filtro === 'lastUpdatedAsc') {
      filtro = 'lastUpdated';
      ordem = 'asc';
    }

    if (this.state.tituloComunicados === 'Acadêmico') {
      novaLista = {
        Acadêmico: {
          Comunicados: listaOrdenada.sort(compareValues(filtro, ordem)),
          TotalComunicados: listaOrdenada.length,
        },
      };
    } else if (this.state.tituloComunicados === 'Comercial') {
      novaLista = {
        Comercial: {
          Comunicados: listaOrdenada.sort(compareValues(filtro, ordem)),
          TotalComunicados: listaOrdenada.length,
        },
      };
    } else if (this.state.tituloComunicados === 'Financeiro') {
      novaLista = {
        Financeiro: {
          Comunicados: listaOrdenada.sort(compareValues(filtro, ordem)),
          TotalComunicados: listaOrdenada.length,
        },
      };
    } else if (this.state.tituloComunicados === 'Operações') {
      novaLista = {
        Operações: {
          Comunicados: listaOrdenada.sort(compareValues(filtro, ordem)),
          TotalComunicados: listaOrdenada.length,
        },
      };
    } else if (this.state.tituloComunicados === 'Pós Graduação') {
      novaLista = {
        Pos: {
          Comunicados: listaOrdenada.sort(compareValues(filtro, ordem)),
          TotalComunicados: listaOrdenada.length,
        },
      };
    } else {
      novaLista = {
        Comunicado: {
          Comunicados: listaOrdenada.sort(compareValues(filtro, ordem)),
          TotalComunicados: listaOrdenada.length,
        },
      };
    }
    this.setState({ getComunicados: novaLista });
  };

  render() {
    const {
      error,
      pathArray,
      tags,
      sugestoesTags,
      getComunicados,
      tituloComunicados,
      showComunicado,
      pagina,
      paginaAnterior,
      areas,
    } = this.state;
    return [
      <Helmet
        title="Comunicados por categoria - Portal Prisma - Kroton"
        key="titleComunicadosCategoria"
        meta={[
          {
            name: 'description',
            content:
              'Comunicados por categoria - Portal Unificado da Kroton Educacional',
          },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'keywords',
            content: 'comunicados, categoria, kroton, educacional, portal',
          },
        ]}
      />,
      <Header key="HeaderComunicadosCategorias" />,
      <section className="hero is-crp" key="heroComunicados">
        <div className="hero-body">
          <div className="container is-fullhd">
            <nav
              className="breadcrumb is-left"
              aria-label="breadcrumbs"
              key={'navegacao'}
            >
              <ul>
                <li>
                  <a href="./home">Home</a>
                </li>
                <li>
                  <a href="./comunicados" aria-current="page">
                    Comunicados (Geral)
                  </a>
                </li>
                <li className="is-active">
                  <a href="./" aria-current="page">
                    Comunicados por{' '}
                    {pathArray[1] === 'lista-comunicados'
                      ? 'categoria'
                      : 'tags'}{' '}
                    {pathArray[1] === 'lista-comunicados' &&
                      ' - ' + tituloComunicados}
                  </a>
                </li>
              </ul>
            </nav>
            <h1 className="title is-1">
              Comunicados por{' '}
              {pathArray[1] === 'lista-comunicados' ? 'categoria' : 'tags'}{' '}
              {pathArray[1] === 'lista-comunicados' &&
                ' - ' + tituloComunicados}
            </h1>
          </div>
        </div>
        {pathArray[1] === 'lista-comunicados' && (
          <div className="hero-foot">
            <nav className="tabs is-boxed is-fullwidth">
              <div className="container">
                <div className="columns is-gapless">
                  <div className="column">
                    <Sidebar />
                  </div>
                  <div className="column" />
                </div>
              </div>
            </nav>
          </div>
        )}
      </section>,
      <section
        className="section comunicadosDestaque comunicadosCategoria"
        key="conteudoComunicados"
      >
        {error.response ? <Error {...error} /> : null}
        {getComunicados[tituloComunicados] ? (
          <div className="columns is-multiline listaCategoriaComunicados">
            <div className="column is-one-fifth">
              <div className="field buscaComunTags">
                <ReactTags
                  tags={tags}
                  suggestions={sugestoesTags}
                  inline={false}
                  autoComplete={true}
                  allowNew={false}
                  handleDelete={this.handleDelete.bind(this)}
                  delimiterChars={[',', ' ']}
                  handleAddition={this.handleAddition.bind(this)}
                  inputFieldPosition={'top'}
                  placeholder={'Insira uma tag para filtrar'}
                  classNames={{
                    root: 'react-tags',
                    rootFocused: 'is-focused',
                    selected: 'react-tags__selected tagsComunicadoClass',
                    selectedTag: 'react-tags__selected-tag tags',
                    selectedTagName:
                      'react-tags__selected-tag-name tag is-link',
                    search:
                      'react-tags__search tags tagsComunicadoClass tagsComunicadoClassPesquisa',
                    searchInput:
                      'react-tags__search-input input tagInputFieldComunClass',
                    suggestions:
                      'react-tags__suggestions notification suggestionsClass',
                    suggestionActive: 'is-active',
                    suggestionDisabled: 'is-disabled',
                  }}
                  renderSuggestion={({ text }, query) => (
                    <div
                      style={{
                        textDecoration: 'underline',
                        textDecorationStyle: 'wavy',
                      }}
                    >
                      {text} ({query})
                    </div>
                  )}
                />
                <div id="tagsComunCat">
                  <input
                    type="hidden"
                    id="comunCatZero"
                    name="lstComunicadoTag[]"
                  />
                </div>
                <div className="control has-icons-left has-icons-right iconeBusca">
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={['fas', 'search']} />
                  </span>
                </div>
              </div>
              {/*<h6>Grau Acadêmico</h6>*/}
              {/*<div className="field">*/}
              {/*<input className="is-checkradio is-small has-background-color" id="exampleCheckboxBackgroundColorSuccess" type="checkbox" name="exampleCheckboxBackgroundColorSuccess" />*/}
              {/*<label htmlFor="exampleCheckboxBackgroundColorSuccess">Checkbox</label>*/}
              {/*</div>*/}
              {/*<div className="field">*/}
              {/*<input className="is-checkradio is-small has-background-color is-success" id="exampleCheckboxBackgroundColorSuccess" type="checkbox" name="exampleCheckboxBackgroundColorSuccess" checked="checked" readOnly />*/}
              {/*<label htmlFor="exampleCheckboxBackgroundColorSuccess">Checkbox - success</label>*/}
              {/*</div>*/}
              <h6>Autor / Área</h6>
              {areas &&
                Object.keys(areas).map(k => {
                  var resp = areas[k];
                  return Object.keys(resp).map((ak, ia) => {
                    return (
                      <div className="field" key={'areaOption-' + [ak]}>
                        <input
                          type="checkbox"
                          name="areaInput[]"
                          className={
                            'is-checkradio is-small has-background-color '
                          }
                          // checked={isChecked}
                          onChange={this.handleChecked}
                          id={'areaInput-' + [ak]}
                          value={ak}
                        />
                        <label htmlFor={'areaInput-' + [ak]}>{resp[ak]}</label>
                      </div>
                    );
                  });
                })}
            </div>
            <div className="column">
              <div className="columns is-multiline listaCategoriaComunicados">
                <div className="column is-full">
                  <nav className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <p className="subtitle is-5">
                          <strong>
                            {
                              getComunicados[tituloComunicados][
                                'TotalComunicados'
                              ]
                            }{' '}
                            {getComunicados[tituloComunicados][
                              'TotalComunicados'
                            ] > 1
                              ? 'comunicados'
                              : 'comunicado'}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        <div className="select">
                          <select onChange={this.ordenarComunicados}>
                            <option>Ordenar por...</option>
                            <option value="prioridade">Críticos</option>
                            <option value="naocritico">Não Críticos</option>
                            <option value="lido">Lidos</option>
                            <option value="naolido">Não lidos</option>
                            <option value="lastUpdated">Mais Recentes</option>
                            <option value="lastUpdatedAsc">Mais Antigos</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
                {getComunicados[tituloComunicados]['Comunicados'].map(comun => {
                  let comunicado = comun['comunicado'];
                  let boxAlerta = '';
                  let boxAlertaSpan = ''; //let favorito = "far";
                  let listaTags;
                  let inicioTagsCat = 0;
                  let inicioTags = 0;
                  let maxTagsCat = 3;
                  let errata = '';
                  let lido = 'check';

                  if (comunicado.lido === 'S') {
                    lido = 'check lido';
                  } else {
                    lido = 'check';
                  }

                  switch (comunicado.prioridade) {
                    case 'C':
                      boxAlertaSpan =
                      <span className="alerta alerta-red">
                        <FontAwesomeIcon icon={['fas', 'thermometer-full']} className="icon-thermometer"color="#ffffff"/>
                      </span>;
                      boxAlerta = 'boxAlerta-red boxAlertaSemCarousel';
                      break;
                    case 'A':
                      boxAlertaSpan =
                      <span className="alerta alerta-orange">
                        <FontAwesomeIcon icon={['fas', 'thermometer-three-quarters']} className="icon-thermometer" color="#ffffff"/>
                      </span>;
                      boxAlerta = 'boxAlerta-orange boxAlertaSemCarousel';
                      break;
                    case 'I':
                      boxAlertaSpan =
                      <span className="alerta alerta-blue">
                        <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff"/>
                      </span>;
                      boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                      break;
                    case 'R':
                      boxAlertaSpan =
                      <span className="alerta alerta-green">
                        <FontAwesomeIcon icon={['fas', 'thermometer-quarter']} className="icon-thermometer" color="#ffffff"/>
                      </span>;
                      boxAlerta = 'boxAlerta-green boxAlertaSemCarousel';
                      break;
                    default:
                      boxAlertaSpan =
                      <span className="alerta alerta-blue">
                        <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff"/>
                      </span>;
                      boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                      break;
                  }

                  if (comunicado.errata === 'S') {
                    errata = 'ATUALIZAÇÃO - ';
                  }

                  if (comun['lstComunicadoTag']) {
                    listaTags = comun['lstComunicadoTag'].map(function(tagCat) {
                      ++inicioTagsCat;
                      if (inicioTagsCat <= maxTagsCat) {
                        if (inicioTagsCat === maxTagsCat) {
                          return (
                            <a
                              href={
                                './../comunicados-tags/' + tagCat['tag']['id']
                              }
                              key={'tagCat' + tagCat['tag']['id']}
                              className="button is-small is-rounded is-link tagsComunicadoMais"
                            >
                              +{tags - inicioTags + 1}
                            </a>
                          );
                        } else {
                          return (
                            <a
                              href={
                                './../comunicados-tags/' + tagCat['tag']['id']
                              }
                              key={'tagCat' + tagCat['tag']['id']}
                              className={
                                'button is-small is-rounded is-outlined tagsComunicado tagtagCat' +
                                tagCat['tag']['id']
                              }
                            >
                              {tagCat['tag']['descricao']}
                            </a>
                          );
                        }
                      } else {
                        return '';
                      }
                    });
                  }

                  return (
                    <div
                      className="column is-one-third item"
                      data-index={comunicado.codigo}
                      key={comunicado.codigo}
                    >
                      <span
                        className="salvarComunicadoRelativo"
                        data-comunicado-favorito={comunicado.id}
                        onClick={e =>
                          this.geraComunicado(e, comunicado, 'like')
                        }
                        title={
                          comunicado.favorito === 'S'
                            ? 'Desmarcar favorito'
                            : 'Marcar como favorito'
                        }
                      >
                        <FontAwesomeIcon
                          icon={[
                            comunicado.favorito === 'S' ? 'fas' : 'far',
                            'bookmark',
                          ]}
                        />
                      </span>
                      <div className={'box bgComunicados ' + boxAlerta}>
                        <div
                          onClick={e =>
                            this.geraComunicado(e, comunicado, 'titulo')
                          }
                        >
                          {boxAlertaSpan}
                          <article className="media">
                            <div className="media-content">
                              <div className="content">
                                <b className="title is-6 is-spaced comunicadosInfos">
                                  <label
                                    className={lido}
                                    data-comunicado-lido={comunicado.id}
                                  >
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                  </label>
                                  <small>{comunicado.codigo}</small>
                                </b>
                                <hr className="hrNoBorder" />
                                <h5
                                  className="title is-6 is-spaced"
                                  data-comunicado={comunicado.id}
                                >
                                  <span>
                                    {errata}
                                    {comunicado.titulo &&
                                    comunicado.titulo.length > 60
                                      ? comunicado.titulo
                                          .slice(0, 60)
                                          .concat('...')
                                      : comunicado.titulo}
                                  </span>
                                </h5>
                                <small className="smallComunicado"
                                dangerouslySetInnerHTML={{
                                  __html: comunicado.resumoNovo,
                                }}>
                                </small>
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="content is-small referenciaComunicados">
                          {listaTags}
                          <hr />
                          {/* <Popover
                            content={
                              <>
                                <Row>
                                  <Col span={24}>
                                    Links:
                                    <Input
                                      defaultValue={`${
                                        window.location.origin
                                      }/comunicados/${comunicado.id}`}
                                      block="true"
                                      readOnly
                                    />
                                  </Col>
                                  <Col span={24}>
                                    <Button
                                      onClick={() =>
                                        this.copyCodeToClipboard(comunicado)
                                      }
                                      type="primary"
                                      block
                                    >
                                      <Icon type="copy" />
                                      Copiar
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            }
                            title="Copiar Link de compartilhamento"
                            trigger="click"
                          > */}
                            <Button
                              key={`edit_${comunicado.id}`}
                              title={'Compartilhar esse comunicado'}
                              size="small"
                              type="primary"
                              onClick={() =>
                                this.copyCodeToClipboard(comunicado)
                              }
                            >
                              <FontAwesomeIcon icon={['fas', 'share-alt']} />
                              <span style={{ paddingLeft: 5 }}>
                                Compartilhar
                              </span>
                            </Button>
                          {/* </Popover> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="columns">
                <div className="column">
                  <nav
                    className="pagination is-centered"
                    role="navigation"
                    aria-label="pagination"
                  >
                    {paginaAnterior > 1 && (
                      <a
                        href="./"
                        onClick={e => this.mudarPagina(e, pagina - 1)}
                        className="pagination-previous"
                      >
                        Página anterior ({pagina - 1})
                      </a>
                    )}
                    {pagina !==
                      getComunicados[tituloComunicados]['TotalComunicados'] &&
                    getComunicados[tituloComunicados]['TotalComunicados'] > 9 &&
                    pagina <=
                      getComunicados[tituloComunicados]['TotalComunicados'] /
                        9 ? (
                      <a
                        href="./"
                        onClick={e => this.mudarPagina(e, pagina + 1)}
                        className="pagination-next"
                      >
                        Próxima página ({pagina + 1})
                      </a>
                    ) : null}
                    {this.paginacaoCategoria(
                      pagina,
                      getComunicados[tituloComunicados]['TotalComunicados']
                    )}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={
            this.state.isActived ? 'conteudoLink is-active' : 'conteudoLink'
          }
        >
          <div className=" is-fullhd">
            <div className="columns is-gapless">
              <div
                className="is-transparent"
                id="columnClose"
                style={{ width: '15%',}}
                onClick={this.toggleNaved}
              />
              <ComunicadoDisplay comunicado={showComunicado} isDestaque={false}/>
            </div>
          </div>
        </div>
      </section>,
      <hr className="hrEspacamento" key="hrEspacamentoCategorias" />,
      <Footer key="FooterCategoria" />,
    ];
  }
}
