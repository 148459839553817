import $ from 'jquery';

let BaseURL = 'http://10.201.40.37:8080/api/';
const urlLocal = window.location.protocol + '//' + window.location.hostname;
const poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));

if (urlLocal.includes('prisma.kroton')) {
  BaseURL = 'https://apiprisma.kroton.com.br/api/';
} else if (urlLocal.includes('prismadevfront.krthomolog.com.br')) {
  BaseURL = window.location.protocol + '//prismadevback.krthomolog.com.br/api/';
} else if (urlLocal.includes('prismatstfront.krthomolog.com.br')) {
  BaseURL = window.location.protocol + '//prismatstback.krthomolog.com.br/api/';
} else if (urlLocal.includes('prismafrontstg.krthomolog.com.br')) {
  BaseURL = window.location.protocol + '//prismabackstg.krthomolog.com.br/api/';
} else {
  BaseURL = urlLocal + ':8080/api/';
}
if(urlLocal.includes('prismamtc.krthomolog')) {
  BaseURL = 'https://prismabackmtc.krthomolog.com.br/api/';
}

function havePolos() {
  let poloInfo = JSON.parse(localStorage.getItem('userDataCRP'));
  if (poloInfo !== null) {
    var result = false;
    $.ajax({
      type: 'GET',
      url: BaseURL + 'usuario/cadastrarUsuario/' + poloInfo.usuarioPortal.id + `${(poloId > 0 ? `?poloId=${poloId}` : '')}`,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      async: false,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
      },
      success: function (data, textStatus, jQxhr) {
        result = JSON.parse(JSON.stringify(data.Polo), (key, val) =>
          typeof val !== 'object' && val !== null ? String(val) : val
        );
      },
      error: function (errormessage) {
        result = [];
      },
    });
    return result;
  } else {
    return null;
  }
}

export const POLOS = havePolos();
