import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row,
  Form,
  Button,
  Col,
  Select,
  Tabs,
  Table,
  notification,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useForm, Controller } from 'react-hook-form';
import readXlsxFile from 'read-excel-file';

import { FormController } from './FormController';
import { UploadForm } from 'components/uploadForm/UploadForm';
import { tableColumns } from './FormProps';
import { validaPolo } from '../service';
import { Container } from './styles';
import 'antd/dist/antd.css';

const FormComponent = ({ id, historys }) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TabPane } = Tabs;
  const {
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm();

  const [data, setData] = useState([]);
  const [validData, setValidData] = useState([]);
  const [dataSourceTable, setDataSourceTable] = useState([]);
  const [loading, setLoading] = useState([]);
  const [registerRemoved, setRegisterRemoved] = useState([]);
  const [cofre, setCofre] = useState([]);
  const [typeDocument, setTypeDocument] = useState([]);
  const [arrayListPolo, setArrayListPolo] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const props = {
    state: {
      data: { data, setData },
      validData: { validData, setValidData },
      registerRemoved: { registerRemoved, setRegisterRemoved },
      loading: { loading, setLoading },
      cofre: { cofre, setCofre },
      typeDocument: { typeDocument, setTypeDocument },
    },
    history: historys,
    form: {
      handleSubmit,
      control,
      reset,
    },
    dispatch,
  };

  const controller = new FormController(props);
  useEffect(() => {
    if (id.length > 0) {
      controller.get(id);
    }

    if (cofre.length <= 0 && typeDocument.length <= 0) {
      controller.inititalValue();
    }
  }, [id]);

  useEffect(() => {
    let list = []
    dataSourceTable.forEach(item => {
      if(item.poloId !== registerRemoved[0].poloId) {
        list.push(item);
      }
    })
    setDataSourceTable(list);
  }, [registerRemoved])

  const [action, dispatchState] = useState({
    type: 'none',
  });

  useEffect(() => {
    onValid(data);
  }, [data])

  useEffect(() => {
    typeDocument.sort((a, b) => a.tipoDocumento > b.tipoDocumento)
  }, [typeDocument])

  useEffect(() => {
    cofre.sort((a, b) => a.cofreNome > b.cofreNome)
  }, [cofre])

  async function onValid(params) {
    const data = await validaPolo(params);
    let listValid = [];
    data.data.forEach(item => {
      if(item.localizado){
        listValid.push(item);
      }
    })
    if(listValid.length == 0){
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setDataSourceTable(data.data)
    setValidData(listValid);
  }

  useEffect(() => {
    if (action.type === 'none') return;
    if (action.type === 'add') {
      setValue('tipoDocumento', action.payload);
    }
    dispatchState({ type: 'none' });
  }, [action]);

  const watchTypeDocument = watch('tipoDocumento');
  const parsedTypeDocument = watchTypeDocument
    ? JSON.parse(watchTypeDocument)
    : undefined;

  return (
    <Container>
      <Form onSubmit={handleSubmit((e) => controller.handleSubmit(e, id))}>
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Tipo de Documento" size="large">
              <Controller
                name="tipoDocumento"
                rules={{ required: true }}
                control={control}
                render={({ field }) => {
                  const hasDefaultValue = getValues('tipoDocumentoId');
                  const findItem = typeDocument?.find(
                    (item) => hasDefaultValue === item.id
                  );
                  if (findItem && !field.value) {
                    dispatchState({
                      type: 'add',
                      payload: JSON.stringify(findItem),
                    });
                  }

                  return (
                    <Select
                      size="large"
                      {...field}
                      placeholder="Tipo de Documento"
                      disabled={loading || validData == []}
                    >
                      {typeDocument.length > 0 &&
                        typeDocument.map((item, index) => (
                          <Option value={JSON.stringify(item)}>
                            {item.tipoDocumento}
                          </Option>
                        ))}
                    </Select>
                  );
                }}
              />
            </Form.Item>
          </Col>
          {parsedTypeDocument?.signatarios && (
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Signatários:" size="large">
                <Controller
                  name="signatarios"
                  control={control}
                  defaultValue={parsedTypeDocument?.signatarios}
                  render={({ field }) => (
                    <Select
                      mode='tags'
                      size="large"
                      {...field}
                      placeholder="Signatários"
                      disabled
                    />
                  )}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Cofre D4Sign" size="large">
              <Controller
                name="cofre"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="large"
                    {...field}
                    placeholder="Cofre D4Sign"
                    disabled={loading}
                  >
                    {cofre.length > 0 &&
                      cofre.map((item) => (
                        <Option value={item.id}>{item.cofreNome}</Option>
                      ))}
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Template de Contrato:" size="large">
              <Controller
                name="files"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <UploadForm
                    {...field}
                    name="file"
                    accept=".docx"
                    listType="defaultFileList"
                    initialValue={field.value}
                    typeFormat={[
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    ]}
                    api="file/upload"
                  >
                    <Button
                      icon={<UploadOutlined />}
                      size="large"
                      disabled={loading}
                    >
                      Localizar Arquivo
                    </Button>
                  </UploadForm>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" gutter={16} style={{ textAlign: 'left' }}>
          <Col xs={24} sm={24} md={24}>
            <div className="card-container">
              <Tabs type="card">
                <TabPane tab="Base de Polos" key="1">
                  <Row type="flex" gutter={16} style={{ textAlign: 'left' }}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label="Importar Polos:" size="large">
                        <Controller
                          name="basePolos"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <UploadForm
                              {...field}
                              typeFormat={[
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                              ]}
                              accept=".xlsx"
                              limitSize={50}
                              name="file"
                              listType="defaultFileList"
                              api="file/upload"
                              removeOn={() => {
                                setData([]);
                              }}
                              onChange={async (info) => {
                                if (
                                  info.file.status !== 'uploading' &&
                                  info.file.status !== 'removed'
                                ) {
                                  readXlsxFile(info.file.originFileObj).then(
                                    (rows) => {
                                      let dataArray = [];

                                      rows.map((item) => {
                                        if (item.length === 3) {
                                          if (
                                            !item.includes(
                                              'POLO',
                                              'ID',
                                              'CHAMADOS'
                                            )
                                          ) {
                                            dataArray.push({
                                              polo: item[0],
                                              poloId: Number(item[1]),
                                              chamado: item[2],
                                            });
                                          }
                                        } else {
                                          notification['error']({
                                            message:
                                              'Formato de documento inválido',
                                          });
                                        }
                                      });
                                      setValue('basePolos', dataArray);
                                      setData(dataArray)
                                    }
                                  );
                                }
                              }}
                            >
                              <Button
                                size="large"
                                icon={<UploadOutlined />}
                                disabled={loading}
                              >
                                Importar Lista de Polos
                              </Button>
                            </UploadForm>
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={16} style={{ textAlign: 'left' }}>
                    <Col xs={24} sm={24} md={24}>
                      <Table
                        loading={loading}
                        dataSource={dataSourceTable}
                        columns={tableColumns(props.state)}
                        size="small"
                      />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
        <Row type="flex" gutter={16} style={{ textAlign: 'right' }}>
          <Col xs={24} sm={24} md={24}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={isValid}
            >
              Gerar Documento
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default FormComponent;
