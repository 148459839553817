import React, { useState } from 'react';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../../services/Gerais';
import { FieldBox, Column } from './styles';
import { useReportInclusion } from './hook';
import { Button, Form, Input, Select, Transfer, Tooltip, Table, AutoComplete } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const ReportInclusion = ({
  match: {
    params: { reportId },
  },
}) => {
  const paginaTitulo = 'Relatórios';
  const {
    loading,
    disabledSaveButton,
    loadingProfiles,
    categoryOptions,
    columns,
    profiles,
    form,
    selectedKeys,
    listUsers,
    usersSelected,
    isReadOnly,
    isDisabled,
    postOrUpdateReport,
    onChange,
    onSelectChange,
    filterUsers,
    handleSelectedUser
  } = useReportInclusion(reportId);

  return (
    <>
      <Helmet
        title={paginaTitulo}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: paginaTitulo + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'relatorios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin />
      <section className="columns is-multiline conteudoAdmin" id="admin-app">
        <MenuAdmin />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminRelatoriosInclusao"
                pageName={paginaTitulo}
                titulo={`${paginaTitulo} - ${reportId ? 'Edição' : 'Inclusão'}`}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div
                  className="button-wrapper"
                  id="components-dropdown-demo-dropdown-button"
                >
                  {form.administrativo === 'S' && reportId && !isReadOnly && (
                    <Button
                      size="large"
                      type="primary"
                      href={`/admin/filters/${reportId}`}
                    >
                      Filtros
                    </Button>
                  )}
                  <Button size="large" type="primary" href="/admin/relatorios">
                    Voltar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Column>
            <FieldBox>
              <Form.Item style={{ flex: 1 }} label="Tipo de Relatório" required>
                <Select
                  value={form.administrativo}
                  onChange={(value) => onChange(value, 'administrativo')}
                  placeholder="Selecione"
                  disabled={isReadOnly}
                >
                  <Select.Option key="N">POWER BI</Select.Option>
                  <Select.Option key="S">Administrativo</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ flex: 1 }} label="Categoria" required>
                <Select
                  value={form.categoria}
                  onChange={(value) => onChange(value, 'categoria')}
                  placeholder="Selecione"
                  disabled={isReadOnly}
                >
                  {categoryOptions.map((item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ flex: 1 }} label="Status" required>
                <Select
                  value={form.status}
                  onChange={(value) => onChange(value, 'status')}
                  placeholder="Selecione"
                  disabled={isReadOnly}
                >
                  <Select.Option key="S">ATIVO</Select.Option>
                  <Select.Option key="N">INATIVO</Select.Option>
                </Select>
              </Form.Item>
              {form.administrativo === 'N' ? (
                <>
                  <Form.Item style={{ flex: 1 }} label="Posição">
                    <Input
                      type="number"
                      value={form.posicao}
                      onChange={(event) =>
                        onChange(parseInt(event.target.value), 'posicao')
                      }
                      placeholder="Digite a posição"
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </>
              ) : (
                ''
              )}
            </FieldBox>
            <FieldBox>
              <Form.Item style={{ flex: 1 }} label="Nome do Relatório" required>
                <Input
                  value={form.nomeRelatorio}
                  onChange={(event) =>
                    onChange(event.target.value, 'nomeRelatorio')
                  }
                  placeholder="Digite o nome do relatório"
                  disabled={isReadOnly}
                />
              </Form.Item>
              <Form.Item style={{ flex: 1 }} label="Responsavel" required>
                <Input
                  value={form.responsavel}
                  type="email"
                  onChange={(event) =>
                    onChange(event.target.value, 'responsavel')
                  }
                  placeholder="Digite o e-mail do responsavel"
                  disabled={isReadOnly}
                />
              </Form.Item>
            </FieldBox>
            {form.administrativo === 'S' ? (
              <>
                <FieldBox>
                  <Form.Item style={{ flex: 1 }} label="Liberação de Usuários" required>
                  <AutoComplete
                    dataSource={listUsers.map((item) => {
                      return (
                        <Option key={item.id}>
                          {item.conta}
                        </Option>
                      );
                    })}
                    onSearch={filterUsers}
                    onSelect={handleSelectedUser}
                    placeholder="Selecione o usuário"
                    disabled={isReadOnly}
                  />
                  </Form.Item>
                </FieldBox>
                <FieldBox>
                  <Form.Item style={{ flex: 1 }} label="Lista de usuários">
                    <Table
                      columns={columns}
                      dataSource={usersSelected}
                      size="small"
                      className="margin-1rem-top"
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </FieldBox>
              </>
            ) : (
              ''
            )}
            {form.administrativo === 'S' ? (
              <FieldBox>
                <Form.Item style={{ flex: 1 }} label="Querie (SQL)" required>
                  <Input.TextArea
                    value={form.query}
                    onChange={(event) => onChange(event.target.value, 'query')}
                    style={{ resize: 'none' }}
                    disabled={isReadOnly}
                  />
                </Form.Item>
              </FieldBox>
            ) : (
              <>
                <FieldBox>
                  <Form.Item style={{ flex: 1 }} label="Código do Grupo" required>
                    <Input
                      value={form.codigoGrupo}
                      onChange={(event) =>
                        onChange(event.target.value, 'codigoGrupo')
                      }
                      placeholder="Digite o código do grupo"
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                  <Form.Item style={{ flex: 1 }} label="Código do Relatório" required>
                    <Input
                      value={form.codigoRelatorio}
                      onChange={(event) =>
                        onChange(event.target.value, 'codigoRelatorio')
                      }
                      placeholder="Digite o código do relatório"
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </FieldBox>

                <FieldBox>
                  <Form.Item>
                    <Transfer
                      dataSource={profiles}
                      titles={[
                        loadingProfiles
                          ? 'Carregando...'
                          : 'Perfis disponiveis',
                        'Perfis liberados',
                      ]}
                      targetKeys={form.perfilRelatorio}
                      selectedKeys={selectedKeys}
                      onChange={(targetKeys) =>
                        onChange(targetKeys, 'perfilRelatorio')
                      }
                      onSelectChange={onSelectChange}
                      render={(item) => item.perfil}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </FieldBox>
              </>
            )}
            <Tooltip
              title={
                isDisabled ? 'Todos campos devem ser preenchidos!' : ''
              }
            >
              <Button
                disabled={isDisabled || isReadOnly}
                loading={loading}
                onClick={async () => await postOrUpdateReport()}
                type="primary"
              >
                Salvar
              </Button>
            </Tooltip>
          </Column>
        </div>
      </section>
      <FooterAdmin />
    </>
  );
};

export default ReportInclusion;
