import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
  FontAwesomeIcon,
} from 'services/Gerais';

import './styles.css';
import { BannersSection } from './BannersSection';

const Lista = props => {
  const [tituloPagina, setTituloPagina] = useState(0);

  useEffect(() => {
    setTituloPagina('Listar Banners');
  }, []);

  return (
    <>
      <Helmet
        key="TitleAdminBannerLista"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, banners, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAdminBannerLista" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminBannerLista" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminListaBanners"
                pageName="Administração - Banners"
                titulo={tituloPagina}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div id="components-dropdown-demo-dropdown-button">
                  <Button
                    type="primary"
                    size="large"
                    href={`/admin/banner/cadastro`}
                  >
                    <FontAwesomeIcon icon={['far', 'images']} /> &nbsp; Novo
                    Banner
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <BannersSection form={props.form} history={props.history} />
        </div>
      </section>
      <FooterAdmin key="FooterAdminBannerLista" />
    </>
  );
};

export default Form.create()(Lista);
