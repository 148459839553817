import React from 'reactn';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
} from '../../../../../services/Gerais';
import { Button, Table } from 'antd';
import { useFilters } from './hook';

const Filters = ({
  match: {
    params: { id },
  },
}) => {
  const paginaTitulo = 'Filtros';
  const { columns, dataSource, loading } = useFilters(id);
  return (
    <>
      <Helmet
        title={paginaTitulo}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: paginaTitulo + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'filtros, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin />
      <section className="columns is-multiline conteudoAdmin" id="admin-app">
        <MenuAdmin />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminFiltros"
                pageName="Relatórios - Filtros"
                titulo={paginaTitulo}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div
                  className="button-wrapper"
                  id="components-dropdown-demo-dropdown-button"
                >
                  <Button
                    size="large"
                    type="primary"
                    href={`/admin/incluir-filtro/${id}`}
                  >
                    Incluir filtro
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    href={`/admin/incluir-relatorio/${id}`}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Table {...{ columns, dataSource, loading }} size="small" />
        </div>
      </section>
      <FooterAdmin />
    </>
  );
};

export default Filters;
