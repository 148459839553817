import api from '../api';
import Swal from 'sweetalert2';

const createImport = async (params) => {
  return await api.post(`/pesquisa/upload`, params.body).catch(async (error) => {
    Swal.fire({
      text: error.response.data.error,
    });
  });;
};

const exportCsv = async (params) => {
  return await api.post('/relatorio/exportCsv', params.body);
};

export { createImport, exportCsv };
