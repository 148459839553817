import {notification} from 'antd';
import { registerFilter, filterItem, updatePortal } from '../service';

export class FormController {
  constructor(props) {
    this.state = props.state;
    this.history = props.history
    this.form = props.form;
  }

  async get(id, idRelatorioFilter) {
    this.state.loading.setLoading(true)
    const item = await filterItem(idRelatorioFilter, id);
    this.form.reset(item);
    this.state.loading.setLoading(false)

  }

  async handleSubmit(e, edit, idRelatorio) {
    try {
      e.idRelatorio = idRelatorio
      this.state.loading.setLoading(true)

      if (edit) {
        await updatePortal(e);
      } else {
        await registerFilter(e);
      }

      notification["success"]({
        message: `${edit ? 'Atualização' : 'Cadastro'} foi feito(a) com sucesso!`,
        description:
          `O filtro ${e.nomeExibicao} foi ${edit ? 'atualizado' : 'cadastrado'} com sucesso`,
      });
      this.state.loading.setLoading(false)
      this.history.push(`/admin/filters/${idRelatorio}`)

    } catch (error) {
      notification["error"]({
        message: `Erro ao ${edit ? 'Atualizar' : 'Cadastra'} filtros`,
        description:
        error.message ? error.message[0] : 'Por favor verificar com a equipe tecnica',
      });
      this.state.loading.setLoading(false)
    }
  }
}
