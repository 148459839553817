import styled from 'styled-components';

const IconContainer = styled.div`
  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const ImgLogo = styled.img`
  height: 25px;
  width: 25px;
  margin-bottom: 22px;
`;

const Sphere = styled.div`
  width: 8px;
  height: 8px;
  background: ${(props) => props.background};
  border-radius: 50%;
  margin: auto 0.4rem;
`;

const ContainerSelect = styled.div`
  .ant-select-selection-selected-value {
    display: flex !important;
    align-items: center !important;
    column-gap: 4px !important;
  }
`;

const HandleCaptarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: bottom;
  gap: 8px;
  margin: 0;

`

export { ImgLogo, IconContainer, Sphere, ContainerSelect, HandleCaptarContainer };
