import React, { useState, useEffect } from 'react';
import { Table, Form, Row, Col, DatePicker, Select, AutoComplete, Modal } from 'antd';

import { useForm, Controller } from 'react-hook-form';
import { polo as getPoloService } from '../../service';
import { tableColumns } from './TableProps';
import { TableController } from './TableController';
import { Container, ButtonSubmit } from './styled';
import _debounce from 'lodash/debounce';

const { Option } = Select;

const DocumentGenerationAndSubmission = () => {
  const { handleSubmit, control, watch } = useForm({ reValidateMode: 'all' });
  const [data, setData] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [polo, setPolo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  let docGererar = {};
  const props = {
    state: {
      data: { data, setData },
      loading: { loading, setLoading },
      tipodocumento: { tipoDocumento, setTipoDocumento },
      polo: { polo, setPolo },
    },
  };

  const tableController = new TableController(props);

  const filterPolo = async ({ nomePolo }) => {
    try {
      setLoadingAutoComplete(true);
      const result = await getPoloService({ nomePolo });
      setPolo(result?.data?.slice(0, 10));
      setLoadingAutoComplete(false);
    } catch (error) {
      return error;
    } finally {
      setLoadingAutoComplete(false);
    }
  };

  const getFilterPolo = _debounce(filterPolo, 1000);

  useEffect(() => {
    tableController.hadleInitData();
  }, []);

  useEffect(() => {
    tipoDocumento.sort((a, b) => a.tipoDocumento > b.tipoDocumento)
  },[tipoDocumento])

  function renderOption(item) {
    return (
      <Option key={item.idPolo} text={item.nomePolo}>
        {item.nomePolo}
      </Option>
    );
  }

  return (
    <Container key="HeaderSearchPortais">
      <Form
        onSubmit={handleSubmit((e) => tableController.handleSubmit(e))}
        style={{ marginTop: 20 }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6}>
            <Form.Item label="Tipo de Documento" size="large">
              <Controller
                name="tipoDocumento"
                control={control}
                render={({ field }) => (
                  <Select
                    size="large"
                    {...field}
                    placeholder="Tipo de Documento"
                    disabled={loading}
                  >
                    {tipoDocumento.map((item) => (
                      <Option value={item.id}>{item.tipoDocumento}</Option>
                    ))}
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item label="Polo" size="large">
              <Controller
                name="polo"
                control={control}
                render={({ field }) => (
                  <AutoComplete
                    {...field}
                    disabled={loading || loadingAutoComplete}
                    dataSource={polo?.map(renderOption)}
                    onSearch={async (value) =>
                      await getFilterPolo({ nomePolo: value })
                    }
                    placeholder="Polo"
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <Form.Item label="Status" size="large">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    size="large"
                    {...field}
                    placeholder="Status"
                    disabled={loading}
                  >
                    <Option value="ENVIADO">Enviado</Option>
                    <Option value="ERRO GERAÇÃO">Erro Geração</Option>
                    <Option value="ERRO ENVIO">Erro Envio</Option>
                    <Option value="GERADO">Gerado</Option>
                    <Option value="PROCESSAMENTO">Processamento</Option>
                    <Option value="PENDENTE ENVIO">Pendente Envio</Option>
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <Form.Item label="Data Geração" size="large">
              <Controller
                name="dataGeracao"
                control={control}
                render={({ field }) => (
                  <DatePicker {...field} disabled={loading} format="DD-MM-YYYY"/>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} className="submitContainer">
            <ButtonSubmit type="primary" htmlType="submit" loading={loading}>
              Pesquisar
            </ButtonSubmit>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        dataSource={data}
        columns={tableColumns}
        size="small"
      />

    </Container>

  );
};

export default DocumentGenerationAndSubmission;
