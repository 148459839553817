import React from 'react'
import { Header, Footer, Helmet, Hero } from "../../services/Gerais";

const Transmissoes = () => [
    <Helmet title="Transmissões - Portal Prisma - Kroton" meta = {[
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'description', content: 'Transmissões - Portal Unificado da Kroton Educacional' },
            { name: 'keywords', content: 'kroton, educacional, portal, teste' },
        ]} key = "titleTransmissoes" />,
    <Header key="HeaderTransmissoes" />,
    <Hero key="heroAdminTransmissoes" pageName="Transmissões" titulo="Transmissões" />,
    <section className="section noticiasDestaquePagina" key="conteudoNoticias">
        <div className="container" key="conteudoSobre">
            <div className="column">
                <p className="has-text-centered">
                    <iframe title="iframe" width="560" height="315" src="https://www.youtube.com/embed/BV8H_XB4Bdo" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                </p>
                <hr />
                <p className="has-text-justified">
                    Com as transmissões online através da plataforma Eventials, você acompanha todas as atualizadas, tira dúvidas e recebe instruções com total confiança para conduzir a operação EAD.
                </p>
                <p className="has-text-justified">
                    Esses rituais foram planejados para atender exclusivamente as suas necessidades. Com sua presença recorrente, você perceberá o quanto é importante.
                </p>
                <br />
                <p className="has-text-justified">
                    Além disso, é muito fácil de acompanhar!
                </p>
                <p className="has-text-justified">
                    Periodicamente, nós avisamos sobre as transmissões e os temas que serão abordados ao vivo, conforme o horário oficial de Brasília.
                </p>
                <p className="has-text-justified">
                    Se perder alguma transmissão, basta assistir o quanto antes às gravações que ficam armazenadas.
                </p>
                <br />
                <p className="has-text-justified">
                    São 4 transmissões principais, baseadas nos pilares da operação:
                </p>
                <hr />
                <p className="has-text-justified">
                    <strong>Satelitária Comercial:</strong><br />
                    Mensal<br />
                    Temas relacionados à campanhas, vestibular, metas e resultados de captação <br />
                    Terça-feira às 14h<br />
                    <br />
                    Clique aqui para entrar no grupo:<br />
                    <br />
                    <strong>Anhanguera</strong><br />
                    <a href="https://www.eventials.com/CRP-EAD/groups/anhanguera-comercial/">https://www.eventials.com/CRP-EAD/groups/anhanguera-comercial/</a><br />
                    <br />
                    <strong>Unopar</strong><br />
                    <a href="https://www.eventials.com/CRP-EAD/groups/unopar-comercial/">https://www.eventials.com/CRP-EAD/groups/unopar-comercial/</a>
                </p>
                <hr />
                <p className="has-text-justified">
                    <strong>Satelitária de Operações EAD</strong><br />
                    Mensal<br />
                    Temas importantes e estratégicos para toda rede de Polos<br />
                    Quarta-feira, às 14h<br />
                    <br />
                    Clique aqui para entrar no grupo:<br />
                    <br />
                    <strong>Anhanguera</strong><br />
                    <a href="https://www.eventials.com/CRP-EAD/groups/anhanguera-operacoes/">https://www.eventials.com/CRP-EAD/groups/anhanguera-operacoes/</a><br />
                    <br />
                    <strong>Unopar</strong><br />
                    <a href="https://www.eventials.com/CRP-EAD/groups/unopar-operacoes/">https://www.eventials.com/CRP-EAD/groups/unopar-operacoes/</a>
                </p>
                <hr />
                <p className="has-text-justified">
                    <strong>Satelitária Acadêmica</strong><br />
                    Semanal<br />
                    Temas pedagógicos e administrativos relacionados aos alunos<br />
                    Quinta-feira, às 17h<br />
                    <br />
                    Clique aqui para entrar no grupo:<br />
                    <br />
                    <strong>Anhanguera</strong><br />
                    <a href="https://www.eventials.com/CRP-EAD/groups/anhanguera-academico/">https://www.eventials.com/CRP-EAD/groups/anhanguera-academico/</a><br />
                    <br />
                    <strong>Unopar:</strong><br />
                    <a href="https://www.eventials.com/CRP-EAD/groups/unopar-academico/">https://www.eventials.com/CRP-EAD/groups/unopar-academico/</a>
                </p>
                <hr />
                <p className="has-text-justified">
                    <strong>EAD Premium*</strong><br />
                    Semanal<br />
                    Procedimentos e obrigações relacionados aos Cursos EAD Premium<br />
                    Quarta-feira, às 16h30<br />
                    <br />
                    Clique aqui para entrar no grupo:<br />
                    <a href="https://www.eventials.com/CRP-EAD/groups/capacitacoes-dos-cursos-premium/">https://www.eventials.com/CRP-EAD/groups/capacitacoes-dos-cursos-premium/</a><br />
                    <br />
                    <small>*A convocação para este ritual é segmentada, conforme oferta de cursos EAD Premium</small>
                </p>
                <hr />
                <p className="has-text-justified">
                    Clique aqui para acessar o passo a passo para se inscrever nos grupos.
                </p>
                <hr />
            </div>
        </div>
    </section>,
    <hr className="hrEspacamento" key="hrEspacamentoTransmissoes" />,
    <Footer key="FooterTransmissoes" />
]

export default Transmissoes