import React, { Component } from 'reactn';
import { withRouter } from 'react-router-dom';
import { AzureAD, LoginType, AuthenticationState } from 'react-aad-msal';

import './../../styles/Styles.scss';
import {
  FontAwesomeIcon,
  ReactCardFlip,
  axios,
  $,
} from '../../services/Gerais';
import { PostAuthPrisma } from '../../services/PostAuthPrisma';
import logo from '../../img/prisma_logo.png';
import { Form, Input, Button, Icon, Collapse } from 'antd';
import api from '../../services/api';
import Swal from 'sweetalert2';
import { reduxStore } from '../../store/reducers';
// Import the authentication provider which holds the default settings
import { authProvider } from '../../services/authProvider';
import jwt from 'jwt-decode';
import { getParameters } from 'services/resetSenha/service';

const { Panel } = Collapse;

class ValidaLoginAzureAd extends Component {
  constructor(props) {
    super(props);

    // Change the login type to execute in a Redirect
    authProvider.setLoginType(LoginType.Redirect);

    this.interval = null;
    let redirectEnabled = sessionStorage.getItem('redirectEnabled') || false;
    this.state = {
      counter: 5,
      redirectEnabled: redirectEnabled,
      ipLocate: '',
      isFlipped: false,
      redirect: true,

      loginError: false,
      loginSuccess: false,
      isLoginPending: false,
      redirectToReferrer: false,
      isLoginInvalid: false,

      respostaServidor: '',

      contador: 1,
      UsuarioPolo: [],

      inputEmailReset: '',
      carregandoReset: false,

      showModal: false,
      tokenDecode: '',
      isValidResetPassword: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.loginAction = this.loginAction.bind(this);
    this.responseAuthPrisma = this.responseAuthPrisma.bind(this);
    this.verificarConexao = this.verificarConexao.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoginPending: true, loginError: false });
    this.responseAuthPrisma();
    this.bindOnclickLink();
    this.getIp();
    this.validParametersOfValidResetPassword();
  }

  // acao do flip no form
  handleClick() {
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  async getIp() {
    let ip = '';

    await $.getJSON('https://api.db-ip.com/v2/free/self', function (data) {
      let locateIP = JSON.parse(JSON.stringify(data, null, 2));
      ip = locateIP.ipAddress;
    });
    this.setState({ ipLocate: ip });
  }

  bindOnclickLink() {
    let self = this;
    document.addEventListener('click', function (e) {
      if (e.target && e.target.id == 'linkOpenModal') {
        self.toggleModal();
      }
    });
  }

  handleKeyPress = (event) => {
    event.preventDefault();
    this.handleClick();
  };

  handleCheck = () => {
    this.setState(
      (state) => ({
        ...state,
        redirectEnabled: !state.redirectEnabled,
      }),
      () => {
        if (!this.state.redirectEnabled) {
          this.clearRedirectInterval();
        } else {
          sessionStorage.setItem('redirectEnabled', true);
        }
      }
    );
  };

  countdownToLogin = (loginFunction) => {
    if (this.state.redirectEnabled && !this.interval) {
      this.interval = setInterval(() => {
        if (this.state.counter > 0) {
          this.setState({ counter: this.state.counter - 1 });
        } else {
          this.clearRedirectInterval();
          this.setState({ redirectEnabled: false });
          loginFunction();
        }
      }, 1000);
    }
  };

  validParametersOfValidResetPassword = async () => {
    const data = await getParameters();
    this.setState({ isValidResetPassword: (data.data.valor == 'S' ? true : false) })
    this.render();
  };

  clearRedirectInterval() {
    clearInterval(this.interval);
    this.setState({ counter: 5 });
    sessionStorage.removeItem('redirectEnabled');
    this.interval = null;
  }

  responseAuthPrisma = async () => {
    let response, resLogin;
    try {
      this.setState({
        loginSuccess: true,
        loginError: false,
        isLoginInvalid: false,
        respostaServidor: 'Conectando...',
      });

      this.verificarConexao();

      if (!this.verificarConexao()) {
        this.setState({
          loginError: true,
          isLoginInvalid: false,
          loginSuccess: false,
          respostaServidor: 'Verifique a conexão de sua internet',
        });
        return false;
      } else {
        response = await authProvider.getAccessToken();
        resLogin = this.loginAction(response.accessToken);
      }
    } catch (e) {
      if (e.errorCode === 'user_login_error') {
        response = e.errorMessage;
        this.setState({
          isLoginPending: false,
          loginError: true,
          isLoginInvalid: false,
          loginSuccess: false,
          respostaServidor: '', //User login is required.
        });
      } else {
        response = JSON.stringify(e);
        this.setState({
          respostaServidor: response,
        });
      }
    }
    return resLogin;
  };

  loginAction(jwtAccessToken) {
    if (jwtAccessToken !== undefined) {
      PostAuthPrisma('usuario/loginPrisma', jwtAccessToken).then((result) => {
        this.setState({
          isLoginPending: true,
          loginError: false,
          isLoginInvalid: false,
          respostaServidor: 'Validando Token...',
          tokenDecode: jwt(jwtAccessToken),
        });
        var resposta = '';
        if (result.status === 200) {
          if (this.state.contador <= 3) {
            this.setState({
              isLoginPending: false,
              loginSuccess: true,
              loginError: false,
              isLoginInvalid: false,
              respostaServidor: 'Coletando dados usuário',
            });
            api.get('usuario/loginToken')
              .then((response) => {
                this.setState(
                  {
                    isLoginPending: false,
                    loginSuccess: true,
                    loginError: false,
                    isLoginInvalid: false,
                    respostaServidor: 'Gravando session',
                    UsuarioPolo: response.data.UsuarioPolo,
                  },
                  () => {
                    this.setStorage();
                  }
                );
                if(response.data.UsuarioPolo[0].usuarioPortal.personificado == "S"){
                  localStorage.setItem('userDataCRP-UsuarioLogado', JSON.stringify(response.data.UsuarioPortalImpersonamento))
                }
              })
              .catch((error) => {
                this.setState({
                  contador: this.state.contador + 1,
                  loginError: true,
                });
              });
          } else {
            this.setState({
              isLoginPending: false,
              loginError: true,
              isLoginInvalid: false,
              loginSuccess: false,
              respostaServidor:
                'Você ultrapassou o limite de tentativas, acesse o portal mais tarde para continuar!',
            });
          }
        } else if (result.status === 401 || result.status === 500) {
          resposta = result.message;
          this.setState({
            isLoginPending: false,
            loginError: false,
            isLoginInvalid: true,
            loginSuccess: false,
            respostaServidor: resposta,
          });
        } else if (result.status === 400) {
          this.setState({
            isLoginPending: false,
            loginError: true,
            isLoginInvalid: false,
            loginSuccess: false,
            respostaServidor: 'Sistema temporariamente indisponivel',
          });
        }
      });
    }
  }

  setStorage = () => {
    // armazenando dados no storage
    const { UsuarioPolo } = this.state;
    if (UsuarioPolo.length === 0) {
      window.location.href = '/error';
    } else {
      localStorage.setItem('userDataCRP', JSON.stringify(UsuarioPolo[0]));
      localStorage.setItem('userDataCRP-Login', JSON.stringify(UsuarioPolo));
      localStorage.setItem(
        'userDataCRP-Polo',
        JSON.stringify(
          UsuarioPolo[0]['polo'] !== null ? UsuarioPolo[0]['polo']['id'] : ''
        )
      );
      localStorage.setItem('userDataCRP-Polos', JSON.stringify(UsuarioPolo));
      localStorage.setItem('EstPolo', '0');
    }
    if (UsuarioPolo.length > 0) {
      var polosList = [];
      UsuarioPolo.forEach(function (entry) {
        var obj = {};
        if (entry.polo == null) return;
        obj['id'] = entry.polo;
        obj['nome'] = entry.polo;
        polosList.push(obj);
      });
      if (polosList.length > 0 && polosList[0].id != null) {
        localStorage.setItem(
          'userDataCRP-ListaPolos',
          JSON.stringify(polosList)
        );
      }
    } else {
      localStorage.setItem('userDataCRP-ListaPolos', null);
    }

    this.setState(
      {
        redirectToReferrer: true,
        isLoginPending: false,
        loginSuccess: true,
        loginError: false,
        isLoginInvalid: false,
        respostaServidor: 'Autorizado ... Redirecionando ...',
      },
      () => {
        this.redirUser();
      }
    );
  };

  verificarConexao() {
    let statusConexao = navigator.onLine;
    return statusConexao;
  }

  //Nao utilizado
  loginOnPrisma(jwtAccessToken) {
    this.setState({
      loginSuccess: true,
      loginError: false,
      isLoginInvalid: false,
      respostaServidor: 'Conectando...',
    });

    this.verificarConexao();

    if (!this.verificarConexao()) {
      this.setState({
        loginError: true,
        isLoginInvalid: false,
        loginSuccess: false,
        respostaServidor: 'Verifique a conexão de sua internet',
      });
      return false;
    } else {
      return this.loginAction(jwtAccessToken);
    }
  }

  logout = () => {
    localStorage.setItem('userDataCRP', '');
    localStorage.setItem('userDataCRP-Login', '');
    localStorage.setItem('userDataCRP-Polo', '');
    localStorage.setItem('userDataCRP-Polos', '');
    localStorage.setItem('userDataCRP-AuthTk', '');
    localStorage.setItem('EstPolo', '');

    localStorage.clear();
    sessionStorage.clear();
    authProvider.logout();

    this.setState({ redirect: true });
    this.props.history.push('/polos');
  };

  redirUser = () => {
    // redirecionamento para home ou tela de atualização de polos, baseada nos dados em banco
    if (localStorage.getItem('userDataCRP')) {
      const primeiroAcesso = JSON.parse(
        localStorage.getItem('userDataCRP-Login')
      );
      let bloqueio = false;

      if (!bloqueio) {
        // eslint-disable-next-line
        primeiroAcesso.map((v) => {
          if (
            v['bloqTelaValidUser'] === 'S' ||
            v['usuarioPortal']['primeiroAcesso'] === 'S'
          ) {
            bloqueio = true;
          }
        });
      }

      // entra na tela para atualizar usuarios polo ou atualizar a senha
      if (bloqueio) {
        bloqueio = false;
        this.props.history.push('/polos');
      } else {
        bloqueio = false;
        const redirect = JSON.parse(sessionStorage.getItem('userDataCRP-URL'));
        if (redirect && redirect.pathname) {
          sessionStorage.removeItem('userDataCRP-URL');
          this.props.history.push(redirect.pathname);
        } else {
          this.props.history.push('/home');
        }
      }
    }
  };

  resetaSenhaSubmit = (e) => {
    e.preventDefault();

    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({ carregandoReset: true });
          const { status } = await api.post('usuario/resetSenha', {
            email: this.state.inputEmailReset,
            ipSolicitante: this.state.ipLocate,
            browser: browserName,
          });
          if (status === 200 || status === 201) {
            Swal.fire({
              position: 'top-end',
              title: `Feito!`,
              text:
                'Sua nova senha de acesso foi enviada para o e-mail Prisma do gestor do seu Polo. Solicite seus dados!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            });
          }
          this.setState({ carregandoReset: false });
          this.handleClick();
        } catch (error) {
          this.setState({ carregandoReset: false });
          if (error.response) {
            const textError = error.response.data.error || error.response.data.mensagem;
            Swal.fire({
              title: 'Atenção',
              text: textError,
              confirmButtonText: 'Ok',
            });
            return;
          } else if (error.mensagem) {
            Swal.fire({
              title: 'Atenção',
              text: error.mensagem,
              confirmButtonText: 'Ok',
            });
            return;
          } else {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao realizar reset de senha.`,
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          }
        }
      }
    });
  };

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    const { redirectEnabled } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <AzureAD provider={authProvider} reduxStore={reduxStore}>
        {({ login, logout, authenticationState, accountInfo }) => {
          if (authenticationState === AuthenticationState.Authenticated) {
            return (
              <React.Fragment>
                <section className="card back has-background-white" key="back">
                  <img
                    className="logoLogin"
                    src={logo}
                    alt="Kroton Educacional"
                    width="60%"
                  />
                  {this.state.loginSuccess && (
                    <div className="notification is-success">
                      <button className="delete" />
                      Conectando...
                    </div>
                  )}
                  {this.state.loginError && (
                    <div className="notification is-danger">
                      <button className="delete" onClick={logout} />
                      <p>
                        Sistema temporariamente indisponivel, tente mais tarde.
                      </p>
                      <p>
                        Para maiores dúvidas ou problemas com login, <a href="https://krotoneducacional.virtualinteractions.com.br/pt_BR/avi.html?id=238&source=1&target=1&channel=1&nome=USER&email=errologin@email.com.br&launchType=popup&ms=285#forward" target="blank">acesse aqui nosso Chat.</a>
                      </p>
                    </div>
                  )}
                  {this.state.isLoginInvalid && (
                    <div className="notification is-success">
                      <button className="delete" onClick={logout} />
                      <p>Não existe liberação de acesso para o Email:</p>
                      <p>({this.state.tokenDecode.unique_name})</p>
                      <p>
                        Para solicitar um acesso Corporativo{' '}
                        <a href="/precadastro">clique aqui</a>
                      </p>
                      <p>
                        Para solicitar acesso a outros perfis, consulte as orientações <a id="linkOpenModal">aqui neste link.</a>
                      </p>
                      <p>
                        Para maiores dúvidas ou problemas com login, <a href="https://krotoneducacional.virtualinteractions.com.br/pt_BR/avi.html?id=238&source=1&target=1&channel=1&nome=USER&email=errologin@email.com.br&launchType=popup&ms=285#forward" target="blank">acesse aqui nosso Chat.</a>
                      </p>
                    </div>
                  )}
                  {
                    /*this.state.respostaServidor ? (
                    <button
                      className="button is-medium is-fullwidth is-link acessar"
                      type="submit"
                      block="true"
                      id="acessar"
                      onClick={login}
                      tabIndex={4}
                    >
                      ACESSAR{" "}
                      <FontAwesomeIcon
                        className="seta"
                        icon={["fas", "arrow-right"]}
                      />
                    </button>
                  ) : (
                    <button
                      onClick={logout}
                      className="button is-medium is-fullwidth is-link acessar"
                    >
                      Sair
                      <FontAwesomeIcon
                        className="seta"
                        icon={["fas", "sign-out-alt"]}
                      />
                    </button>)*/
                    <button
                      className="button is-medium is-fullwidth is-link acessar"
                      type="submit"
                      block="true"
                      id="acessar"
                      onClick={login}
                      tabIndex={4}
                    >
                      ACESSAR{' '}
                      <FontAwesomeIcon
                        className="seta"
                        icon={['fas', 'arrow-right']}
                      />
                    </button>
                  }
                </section>
                <div
                  className={this.state.showModal ? 'modal is-active' : 'modal'}
                >
                  <div className="modal-background" />
                  <div className="modal-card">
                    <header className="modal-card-head">
                      <p className="modal-card-title">Suporte ao Login</p>
                    </header>
                    <section className="modal-card-body">
                      <p>Selecione seu Perfil de Acesso:</p>
                      <Collapse accordion>
                        <Panel header="Sou de um Polo Parceiro" key="1">
                          <p>
                            Utilize o e-mail e senha enviados pelo seu Gestor;
                          </p>
                          <p>
                            Solicite uma nova senha realizando o seguinte
                            procedimento: Na tela de Login, clique no link
                            "Esqueci minha senha", informe seu e-mail e
                            confirme. Uma nova senha será gerada e encaminhada
                            ao Gestor do Usuário;
                          </p>
                          <p>
                            <a
                              href="javascript: void(0)"
                              onClick={this.toggleModal.bind(this)}
                            >
                              Clique aqui
                            </a>
                            para voltar à tela de Login.
                          </p>
                          <p>
                            Para mais informações solicite o apoio da{' '}
                            <a
                              href="https://krotoneducacional.virtualinteractions.com.br/pt_BR/avi.html?id=238&source=1&target=1&channel=1"
                              target="_blank"
                            >
                              CAPU
                            </a>
                            ;
                          </p>
                        </Panel>
                        <Panel header="Sou de um Polo Próprio" key="2">
                          <p>
                            Realize o login no seu Webmail e em uma nova aba
                            acesse novamente o Prisma
                          </p>
                          <strong>
                            Realizou o procedimento acima e mesmo assim não
                            conseguiu acesso ao prisma? Solicite seu acesso
                            confome orientações abaixo:
                          </strong>
                          <Collapse accordion>
                            <Panel
                              header="Coordenador ou Diretor de unidade"
                              key="1"
                            >
                              <p>
                                Abra um chamado no{' '}
                                <a
                                  target="_blank"
                                  href="https://kroton.service-now.com/now/nav/ui/classic/params/target/%24pa_dashboard.do%3Fsysparm_dashboard%3Df63e8180dbb07380973f87b3049619db%26sysparm_tab%3D3e3e8180dbb07380973f87b3049619df"
                                >
                                  Portal de Atendimento PAD
                                </a>{' '}
                                - No seguinte caminho: Solicitações Corporativas{' '}
                                {'>'} Dados Cadastrais e Acessos {'>'} Dados
                                Cadastrais Do Polo - Graduação {'>'} Alteração
                                Cadastral Do Polo {'>'}
                                Vinculação acesso gestor Prisma.
                              </p>
                              <p>
                                Informe no chamado o Nome Completo do Gestor,
                                CPF e os Polos que precisa de acesso
                              </p>
                            </Panel>
                            <Panel
                              header="Demais colaboradores da Unidade"
                              key="2"
                            >
                              <p>
                                Solicitar a liberação de acesso para o Diretor
                                da sua Unidade
                              </p>
                            </Panel>
                            <Panel
                              header="Utilize seu e-mail/senha Corporativo para acessar o Prisma"
                              key="3"
                            >
                              <p>
                                Não é possível realizar reset de senha para
                                e-mails corporativos aqui no Prisma:      Caso
                                tenha esquecido sua senha, uma nova senha pode
                                ser solicitada neste{' '}
                                <a
                                  href="https://trocadesenha.cogna.com.br/"
                                  target="_blank"
                                >
                                  link
                                </a>
                                ou através da abertura de chamado no{' '}
                                <a
                                  href="https://kroton.service-now.com/pdc"
                                  target="_blank"
                                >
                                  Portal PDC
                                </a>
                              </p>
                            </Panel>
                          </Collapse>
                        </Panel>
                      </Collapse>
                    </section>
                    <footer className="modal-card-foot">
                      <button
                        className="button voltar-modal"
                        onClick={this.toggleModal.bind(this)}
                      >
                        Voltar
                      </button>
                    </footer>
                  </div>
                </div>
              </React.Fragment>
            );
          } else if (
            authenticationState === AuthenticationState.Unauthenticated
          ) {
            this.countdownToLogin(login);
            return (
              <div>
                <ReactCardFlip isFlipped={this.state.isFlipped}>
                  <section className="card front" key="front">
                    <img
                      className="logoLogin"
                      src={logo}
                      alt="Kroton Educacional"
                      width="60%"
                    />
                    {this.state.loginSuccess && (
                      <div className="notification is-success">
                        <button className="delete" />
                        Conectando...
                      </div>
                    )}
                    {this.state.loginError && (
                      <div>
                        <span className="mensagemRetorno is-danger">
                          {this.state.respostaServidor}
                        </span>
                        {!this.state.isLoginPending && (
                          <div className="field">
                            <div className="control has-text-centered">
                              <button
                                className="button is-medium is-fullwidth is-link acessar"
                                type="submit"
                                block="true"
                                id="acessar"
                                onClick={login}
                                tabIndex={4}
                              >
                                ACESSAR{' '}
                                <FontAwesomeIcon
                                  className="seta"
                                  icon={['fas', 'arrow-right']}
                                />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      className="control has-text-centered esqueciSenha"
                      onClick={this.handleClick}
                      onKeyPress={this.handleKeyPress}
                      tabIndex={5}
                    >
                      <span>Esqueceu a senha?</span>
                    </div>
                    <br></br>
                    <a
                      href="https://drive.google.com/file/d/1V4wGtSbgesuhln_a-IbGyRlGUBu4omjN/view"
                      target="_blank"
                    >
                      Guia do Portal Prisma: veja dúvidas sobre acesso
                    </a>
                  </section>
                  <select>
                    <span>
                      Guia do Portal Prisma: veja dúvidas sobre acesso
                    </span>
                  </select>
                  <section
                    className="card back has-background-white"
                    key="back"
                  >

                      <div className="textoBack">
                        <div
                          className="control has-text-right fechar"
                          onClick={this.handleClick}
                        >
                          <h2>X</h2>
                        </div>
                        {this.state.isValidResetPassword ? (<>
                        <h4 className="title is-4 has-text-left">
                          Reset de senha
                        </h4>


                        <p className="has-text-justified has-background-white">
                          <strong>ATENÇÃO:</strong> O reset de senha é válido
                          somente para POLOS PARCEIROS! FUNCIONÁRIOS dos
                          Corporativos, POLOS PRÓPRIOS e Unidades Presenciais
                          utilizar o e-mail e senha de rede.
                        </p>
                        <p className="has-text-justified has-background-white">
                          <strong>FUNCIONÁRIOS DE POLO:</strong> Informe seu
                          e-mail no campo abaixo, que enviaremos a nova senha
                          para o seu gestor!
                        </p>
                        <p className="has-text-justified has-background-white">
                          <strong>GESTORES:</strong> Informe seu e-mail no campo
                          abaixo, e logo após o Reset entre em contato com seu
                          Gerente de Negócios para recupera-la!
                        </p>


                        <Form onSubmit={this.resetaSenhaSubmit}>
                          <Form.Item>
                            {getFieldDecorator('email', {
                              initialValue: this.state.inputEmailReset,
                              rules: [
                                {
                                  required: true,
                                  message: 'Por favor insira um e-mail valido',
                                },
                                {
                                  type: 'email',
                                  message: 'Por favor insira um e-mail valido',
                                },
                              ],
                            })(
                              <Input
                                placeholder="E-mail"
                                disabled={this.state.carregandoReset}
                                onChange={(e) =>
                                  this.setState({
                                    inputEmailReset: e.target.value.trim(),
                                  })
                                }
                                onBlur={(e) =>
                                  (e.target.value = e.target.value.trim())
                                }
                              />
                            )}
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              loading={this.state.carregandoReset}
                              block
                              htmlType="submit"
                            >
                              {this.state.carregandoReset
                                ? 'Enviando'
                                : 'Resetar senha'}
                            </Button>
                          </Form.Item>
                        </Form>
                        </>) : (
                          <>
                          <hr />
                          <span className="mensagemRetorno is-danger">
                            Serviço do Reset de Senha Temporariamente Indisponível,
                            tente mais tarde
                          </span>

                          </>
                        )}
                        <div
                          className="control has-text-centered fechar fecharLink"
                          onClick={this.handleClick}
                          onKeyPress={this.handleKeyPress}
                          tabIndex={7}
                        >
                          <FontAwesomeIcon
                            className="seta"
                            icon={['fas', 'arrow-left']}
                          />{' '}
                          Voltar
                        </div>
                      </div>
                  </section>
                </ReactCardFlip>
              </div>
            );
          }
        }}
      </AzureAD>
    );
  }
}
// Form.create()(withRouter(ValidaLoginAzureAd))
export default withRouter(Form.create()(ValidaLoginAzureAd));
