import React from 'react';

import styles from './style.module.css';
import { BannersTable } from '../BannersTable';

const BannersSection = props => (
  <section className={styles.container}>
    <BannersTable form={props.form} />
  </section>
);

export { BannersSection };
