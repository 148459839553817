import React from 'react';
import {
  HeaderAdmin,
  Helmet,
  Hero,
  MenuAdmin,
  FooterAdmin,
} from 'services/Gerais';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Container, Box, Line } from './styles';
import { useManagerBinding } from './hook';

const ManagerBinding = ({
  match: {
    params: { userId },
  },
}) => {
  const {
    businessDirectorData,
    businessManagerData,
    columns,
  } = useManagerBinding(userId);

  return (
    <Container>
      <Helmet
        key="TitleAdminVinculosDn"
        title="Vinculos DN - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Vinculos DN' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content:
              'lista, vinculos diretor de negócio, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderVinculosDN" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminVinculosDN" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              {Hero({
                key: 'heroAdminVinculosDn',
                pageName: 'Vinculos DN',
                titulo: 'Vinculos Diretor de Negócio',
              })}
            </div>
          </div>
          <Line>
            <Box>
              <div>
                <strong>Diretor de Negócio</strong>
                <p>{businessDirectorData?.nome}</p>
              </div>
            </Box>

            <Link to="/admin/usuarios/vincular-gn">
              <Button type="primary" size="default">
                Vincular GN
              </Button>
            </Link>
          </Line>

          <Table
            loading={false}
            columns={columns}
            dataSource={businessManagerData}
            size="small"
          />
        </div>
      </section>
      <FooterAdmin key="FooterAdminVinculosDN" />
    </Container>
  );
};

export default ManagerBinding;
