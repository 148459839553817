import api from 'services/api';

export const uploadToS3 = async (file) => {
  return await api.post(`file/upload`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`,
    },
  });
};
