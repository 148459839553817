import React from 'react';
import {
  HeaderAdmin,
  Helmet,
  Hero,
  MenuAdmin,
  FooterAdmin,
} from 'services/Gerais';
import { Button, Form, Select } from 'antd';
import { Container } from './styles';
import { useLinkBusinessManager } from './hook';

const ManagerBinding = () => {
  const {
    businessDirectorOptions,
    businessManagerOptions,
    onChangeBusinessManager,
    onChangeBusinessDirector,
    debounceOnSearchDirector,
    debounceOnSearchManager,
    directorValue,
    handleSaveLink,
    buttonDisabled,
    loading,
    listNamesDNs,
    listNamesGNs
  } = useLinkBusinessManager();

  const { Option } = Select;
  return (
    <Container>
      <Helmet
        key="TitleVincularGn"
        title="Perfis - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Vincular GN' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'vincular, gestor de negócio, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderVincularGestor" />
      <section
        key="SectionAdminVincularGestor"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminVincularGn" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              {Hero({
                key: 'heroAdminVincularGN',
                pageName: 'Viculação de Gestor de negócio',
                titulo: 'Viculação de Gestor de negócio',
              })}
            </div>
          </div>
          <Form>
            <Form.Item label="Diretor de Negócio">
              <Select
                onChange={(e) => onChangeBusinessDirector(e)}
                size="large"
                placeholder="Selecione o Diretor de Negócio"
              >
                {listNamesDNs.map((key) => {
                  return (
                    <Option key={`${key.usuarioId}`}>{key.nome}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Gerente de Negócio">
              <Select
                size="large"
                mode="multiple"
                placeholder="Selecione os Gerentes de Negócio"
                onChange={(e) => onChangeBusinessManager(e)}
              >
                {listNamesGNs.map((key) => {
                  return (
                    <Option key={`${key.usuarioId}`}>{key.nome}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            size="large"
            loading={loading}
            disabled={buttonDisabled}
            onClick={async () => await handleSaveLink()}
          >
            Adicionar GN
          </Button>
        </div>
      </section>

      <FooterAdmin key="FooterAdminVincularGn" />
    </Container>
  );
};

export default ManagerBinding;
