import React from 'react';
import { useImportUsers } from './hook';
import { Container, TopLine, Line } from './styles';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../services/Gerais';
import { Button, Form, Select, Input, Table } from 'antd';


const ImportUsers = () => {
  const {
    columns,
    filteredData,
    form,
    onChange,
    cleanFields,
    getData,
    loading,
  } = useImportUsers();
  return (
    <Container>
      <Helmet
        key="TitleAdminImportUsers"
        title="Importação de usuários - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Importação de usuários' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'importUsers',
          },
        ]}
      />
      <HeaderAdmin key="HeaderImportUsers" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        
        <MenuAdmin key="MenuAdminImportUsers" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroImportUsers"
                pageName="Importação de usuários"
                titulo="Importação de usuários"
              />
            </div>
          </div>
          <TopLine>
            <Button href="/admin/importacao-arquivos" type="primary">
              Importar arquivo
            </Button>
          </TopLine>
          <div style={{ width: '100%' }}>
            <Form style={{ width: '100%' }}>
              <Line>
                <Form.Item style={{ flex: '0.3' }} label="Data">
                  <Input
                    value={form.data}
                    onChange={(event) => onChange(event.target.value, 'data')}
                    type="date"
                  />
                </Form.Item>

                <Form.Item style={{ flex: '0.3' }} label="Status">
                  <Select
                    value={form.status}
                    onChange={(value) => onChange(value, 'status')}
                  >
                    <Select.Option key="AGUARDANDO_PROCESSAMENTO">
                      Aguardando processamento
                    </Select.Option>
                    <Select.Option key="EM_ANDAMENTO">
                      Em andamento
                    </Select.Option>
                    <Select.Option key="PROCESSADO_ERRO">
                      Processado - Erro
                    </Select.Option>
                    <Select.Option key="PROCESSADO_SUCESSO">
                      Processado - Sucesso
                    </Select.Option>
                    <Select.Option key="PROCESSAMENTO_CANCELADO">
                      Processamento cancelado
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: '-14px' }}>
                  <Button
                    type="primary"
                    style={{
                      'background-color': '#52c41a',
                      border: 'none',
                      marginRight: '8px',
                    }}
                    onClick={async () => await getData()}
                  >
                    Pesquisar
                  </Button>
                  <Button type="primary" onClick={cleanFields}>
                    Limpar
                  </Button>
                </Form.Item>
              </Line>
            </Form>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredData}
              rowKey="ID"  
            />
          </div>
        </div>
        
      </section>
      <FooterAdmin key="FooterAdminImportUsers" />
    </Container>
  );
};

export default ImportUsers;
