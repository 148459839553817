import { Button, Tag, Icon } from 'antd';
import React from 'react';
import { ActionContainer } from './styles';

function handleDelete(params, state) {
  let list = [];
  let listRemoved = [];
  state.validData.validData.forEach(item => {
    if(item.poloId !== params.poloId) {
      list.push(item);
    } else {
      listRemoved.push(item)
      state.registerRemoved.setRegisterRemoved(listRemoved);
    }
  })
  return list;
}

export const tableColumns = (state) => [
  {
    title: 'Polo',
    key: 'polo',
    dataIndex: 'polo',
    valueType: 'polo',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'ID Polo',
    key: 'poloId',
    dataIndex: 'poloId',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Chamado',
    key: 'chamado',
    dataIndex: 'chamado',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Localizado',
    key: 'localizado',
    dataIndex: 'localizado',
    copyable: true,
    ellipsis: true,
    render: (isValid) => {
      if (isValid !== null) {
        let objTag = {};
        switch (isValid) {
          case true:
            objTag = { tag: 'Localizado', color: '#87d068' };
            break;
          case false:
            objTag = { tag: 'Não Localizado', color: '#FF3860' };
            break;
        }
        return (
          <span>
            <Tag color={objTag.color} key={objTag.tag}>
              {objTag?.tag?.toUpperCase()}
            </Tag>
          </span>
        );
      }
    },
  },
  {
    title: 'Ação',
    valueType: 'action',
    dataIndex: 'id',
    key: 'edit',
    width: 100,
    copyable: true,
    ellipsis: true,
    render: (_, record) => (
      record.localizado ?
      <ActionContainer key={`action_${record.id}`}>
        <Button
          onClick={() => state.validData.setValidData(handleDelete(record, state))}
          type="dashed"
          className="button is-small is-danger"
          size="small"
        >
          <Icon type="file-search" />
          Deletar
        </Button>
      </ActionContainer>
      : ''
    ),
  },
];
