import React from 'reactn';
import {
  axios,
  moment,
  FontAwesomeIcon,
  Scrollbar,
} from '../../services/Gerais';

export default class AgendaDia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poloSelecionado: this.props.poloSelecionado,
      diarios: [],
      local: window.location.href.substr(
        window.location.href.lastIndexOf('/') + 1
      ),
    };
    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
  }
  componentDidMount() {
    if (this.logInfo !== null) {
      let poloId = 1;
      if (this.state.poloSelecionado) {
        poloId = this.state.poloSelecionado;
      } else if (this.logInfo && this.logInfo['polo'] !== null) {
        poloId = this.logInfo['polo']['id'];
      }
      axios
        .get(this.global.baseUrl + 'agenda/agendaByPoloDiaAtual/' + poloId, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then((resultDia) =>
          this.setState({
            diarios: resultDia.data['Agenda'],
          })
        )
        .catch((error) => {
          this.setState({
            error,
          });
        });
    }
  }

  render() {
    const { diarios, local } = this.state;
    let msgLimpo = [
      <p key={'agenda-limpa'}>
        Não há eventos para hoje{' '}
        <span role="img" aria-label="😁">
          😁
        </span>
      </p>,
    ];

    if (local !== 'agenda') {
      msgLimpo = [
        <div className="timeline-item" key={'agenda-limpa'}>
          <div className="timeline-marker" />
          <div className="timeline-content">
            <p className="heading">Tudo ok!</p>
            <small>
              Não há eventos para hoje{' '}
              <span role="img" aria-label="😁">
                😁
              </span>
            </small>
          </div>
        </div>,
      ];
    }

    let conteudo = (
      <div className="timeline" key={'timeline-agenda' + Math.random()}>
        {diarios.length > 0
          ? diarios.map((dia) => {
              let agora = moment(new Date());
              let tz = dia['agenda']['dataInicio']
                .replace(' ', 'T')
                .replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1');
              let data = moment(new Date(tz));
              let lista = [];

              if (local === 'agenda') {
                lista = [
                  <div
                    className="timeline-item is-info"
                    key={'agenda-dia-' + dia['agenda']['id']}
                  >
                    <div className="timeline-marker is-icon has-text-white">
                      {moment(data).isSameOrBefore(agora) && (
                        <FontAwesomeIcon icon={['fas', 'check']} />
                      )}
                    </div>
                    <div className="timeline-content">
                      <span>
                        <strong>
                          {moment(tz).format('HH.mm').replace('.', 'h')}{' '}
                        </strong>{' '}
                        - {dia['agenda']['titulo']}
                      </span>
                    </div>
                  </div>,
                ];
              } else {
                lista = [
                  <div className="timeline-item">
                    <div className="timeline-marker" />
                    <div className="timeline-content">
                      <p className="heading">
                        Dia {moment(tz).format('DD/MM').replace('.', 'h')}
                      </p>
                      <small>{dia['agenda']['titulo']}</small>
                    </div>
                  </div>,
                ];
              }

              return lista;
            })
          : msgLimpo}
      </div>
    );

    return diarios.length > 1 ? (
      <Scrollbar style={{ height: 180 }}>{conteudo}</Scrollbar>
    ) : (
      <>{conteudo}</>
    );
  }
}
