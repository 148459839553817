import React, { useRef, useState, useEffect } from 'react';
import api from 'services/api';
import { logRelatorio } from '../../config/logs';
import { Menu, Spin } from 'antd';

function MenuRelatorio({ item }) {
  const menuRef = useRef();
  const [carregando, setCarregando] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getRelatorios() {
      try {
        setCarregando(true);
        const usuario = JSON.parse(localStorage.getItem('userDataCRP'));
        if (usuario) {
          const res = await api.get(`relatorio/usuariopolo/${usuario.id}`);
          setData(
            res.data.CategoriaRelatorio.sort((a, b) => {
              return a.posicao - b.posicao;
            })
          );
        }

        setCarregando(false);
      } catch (error) {
        setCarregando(false);
      }
    }

    getRelatorios();
  }, []);

  if (carregando) {
    return (
      <div className="is-centered">
        <Spin size="small" />
      </div>
    );
  }

  if (data && data.length > 0) {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.SubMenu
            title={item.descricao}
          >
            {
              data.map((item) => {
                return (
                  <Menu.SubMenu title={item.categoria}>
                    {item.relatorios.map((relatorio) => {
                      return (
                        <Menu.Item key={relatorio.nomeRelatorio}>
                          <a
                            href={'/relatorio/' + relatorio.id}
                            title={relatorio.nomeRelatorio}
                            onClick={() => logRelatorio(relatorio.id)}
                          >
                            {relatorio.nomeRelatorio}
                          </a>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                )
              })
            }
          </Menu.SubMenu>
        </Menu>
      </>
    );
  } else {
    return '';
  }
}

export default MenuRelatorio;
