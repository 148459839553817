import React from 'react';
import { useState } from 'react';
import { Header, Hero, Helmet } from '../../services/Gerais';
import { Row, Col, Input, Form, Button, Table } from 'antd';
import './styles.scss';
import { useEffect } from 'react';
import { getuserData } from 'services/MeusDados/service';
import { cpfMask, telefoneMask } from 'utils/masks';

const MeusDados = () => {
  const [titlePage, setTitlePage] = useState('Meus Dados');
  const [userData, setUserData] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: 'Login Place',
      key: 'loginPlace',
      dataIndex: 'loginPlace',
    },
    {
      title: 'Polo',
      key: 'polo',
      dataIndex: 'polo',
    },
  ]);
  const [isInit, setIsInit] = useState(true);

  const userLogged = JSON.parse(localStorage.getItem('userDataCRP-Login'));

  useEffect(() => {
    if (isInit) {
      handleUserData();
    }
    setIsInit(false);
  });

  async function handleUserData() {
    const data = await getuserData(userLogged[0].usuarioPortal.id);
    console.log('data', data);
    setUserData(data.data);
  }

  return (
    console.log('userData', userData),
    (
      <>
        <Helmet
          title={titlePage}
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: titlePage + '- Portal Prisma',
            },
            { name: 'keywords', content: 'kroton, educacional, portal, teste' },
          ]}
          key="titleEstruturaUsuario"
        />
        <Header key="headerEstruturaUsuario" />
        <Hero
          key="heroEstruturaUsuario"
          pageName={titlePage}
          titulo={titlePage}
        />
        <section className="container">
          <Row xs={24} className="aling-right">
            <Col xs={3}>
              <Form.Item label="Código de Usuario" size="large">
                <Input readOnly value={userData.codigoUsuario} />
              </Form.Item>
            </Col>
          </Row>
          <Row xs={24}>
            <Col xs={24}>
              <Form.Item label="Nome" size="large">
                <Input readOnly value={userData.nome} />
              </Form.Item>
            </Col>
          </Row>
          <Row xs={24}>
            <Col xs={12}>
              <Form.Item label="E-mail" size="large">
                <Input readOnly value={userData.email} />
              </Form.Item>
            </Col>
            <Col xs={6} className="padding-1-left">
              <Form.Item label="CPF" size="large">
                <Input readOnly value={(userData.cpf != undefined ? cpfMask(userData.cpf) : '')} />
              </Form.Item>
            </Col>
            <Col xs={6} className="padding-1-left">
              <Form.Item label="Telefone" size="large">
                <Input readOnly value={(userData.telefone != undefined ? telefoneMask(userData.telefone) : '')} />
              </Form.Item>
            </Col>
          </Row>
          {
            (userData?.acessosPlace?.length == 0 ? '' :
              <>
                <Row>
            <strong className="fontTitle">Meus Acessos - Portal Place</strong>
          </Row>
          <Row>
            <Table
              columns={columns}
              dataSource={userData.acessosPlace}
              size="small"
            />
          </Row>
          <Row>
            <Col xs={15} className="margin-1-top">
              <Form.Item size="large">
                <u>
                  No place você consegue gerênciar as aulas práticas e
                  auditorias nos laborátorios
                </u>
              </Form.Item>
            </Col>
            <Col xs={9} className="aling-right">
              <Form.Item size="large">
                <Button
                  className="margin-1-top"
                  type="primary"
                  size="large"
                  href={`http://place.kroton.com.br/`}
                  target="_blank"
                >
                  Acessar o Portal Place
                </Button>
              </Form.Item>
            </Col>
          </Row>
              </>
            )
          }
        </section>
      </>
    )
  );
};

export default MeusDados;
