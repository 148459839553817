import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { changeUsuarioPessoa, changeUsuarioPortal } from 'store/actions';

function EmailUsuarioAdmin({ form, isEdit, validaFuncionarioAzureAD, disabled }) {
  const dispatch = useDispatch();
  let { usuarioPortal } = useSelector(state => state.Usuario);

  const { getFieldDecorator } = form;

  return (
    <Form.Item label="E-mail" size="large" hasFeedback>
      {getFieldDecorator('email', {
        initialValue: usuarioPortal.pessoa.email,
        rules: [
          {
            type: 'email',
            message: 'Por favor insira um e-mail valido',
          },
          {
            validator: validaFuncionarioAzureAD,
          },
          {
            required: true,
            message: 'Por favor insira um e-mail',
          },
        ],
      })(
        <Input
          placeholder="email@email.com"
          disabled={isEdit || disabled}
          onChange={e => {
            dispatch(
              changeUsuarioPessoa({
                prop: 'email',
                value: e.target.value.trim(),
              })
            );

            if (!isEdit) {
              dispatch(
                changeUsuarioPortal({
                  prop: 'conta',
                  value: e.target.value,
                })
              );
            }
          }}
          size="large"
        />
      )}
    </Form.Item>
  );
}

EmailUsuarioAdmin.propTypes = {
  form: PropTypes.object,
  isEdit: PropTypes.bool,
  validaFuncionarioAzureAD: PropTypes.func,
};

export default EmailUsuarioAdmin;
