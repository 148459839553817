import { useCallback, useEffect, useState } from 'react';
import {
  fetchCategory,
  createCategory as _createCategory,
  updateCategory as _updateCategory,
} from 'services/category';
import Swal from 'sweetalert2';

const useCategory = ({ categoryId }) => {
  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({
    categoria: '',
    ativo: '',
    palavraChave: '',
  });

  useEffect(() => {
    if (categoryId) {
      setCategoryData((state) => ({
        ...state,
        usuarioAlteracao: parseSession?.[0]?.id,
      }));
      return;
    }
    setCategoryData((state) => ({
      ...state,
      usuarioInclusao: parseSession?.[0]?.id,
    }));
  }, []);
  const getData = async () => {
    try {
      const { status, data } = await fetchCategory({ id: categoryId });
      if (status === 200) {
        const {
          usuarioAlteracao,
          usuarioInclusao,
          categoria,
          ativo,
          palavraChave,
        } = data;
        setCategoryData({
          categoria,
          ativo,
          palavraChave,
          usuarioAlteracao: usuarioAlteracao ?? usuarioInclusao,
        });
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    categoryId && getData();
  }, []);

  const updateCategory = useCallback(async () => {
    try {
      setLoading(true);
      const { status } = await _updateCategory({
        body: { ...categoryData },
        id: categoryId,
      });
      if (status === 200 || status === 201) {
        window.location.href = '/admin/list/categoria';
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        text: 'Ocorreu um problema ao criar a Categoria, tente novamente',
      });
    }
  }, [categoryData]);

  const createCategory = useCallback(async () => {
    try {
      setLoading(true);
      const { status } = await _createCategory({ body: { ...categoryData } });
      if (status === 200 || status === 201) {
        window.location.href = '/admin/list/categoria';
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        text: 'Ocorreu um problema ao criar a Categoria, tente novamente',
      });
    }
  }, [categoryData]);

  const onChange = (value, field) => {
    setCategoryData((state) => ({
      ...state,
      [field]: value,
    }));
  };
  const allFieldsFilled = () => {
    const keys = Object.keys(categoryData);
    return keys.every(
      (item) =>
        (categoryData?.[item] && categoryData[item] !== '') ||
        categoryData?.[item] === 0
    );
  };
  const buttonDisabled = !allFieldsFilled();
  const finish = !categoryId ? createCategory : updateCategory;
  return { finish, onChange, buttonDisabled, categoryData, loading };
};

export { useCategory };
