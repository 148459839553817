import {
  DADO_PERFIL_SUCCESS,
  DADO_PERFIL_ERRO,
  DADO_PERFIL,
  DADO_SETOR,
  DADO_PERFIL_CHANGE,
  DADO_POLO,
} from '../../../actions/Administrativo/Usuario/DadosPerfilType';

const initialState = {
  carregandoInicial: true,
  poloDinamico: [],
  carregando: true,
  error: '',
  Funcao: '',
  Perfil: '',
  Polos: '',
  Setor: [],
  SetorFuncao: '',
  SituacaoUsuario: '',
};

const dadosPerfil = (state = initialState, action) => {
  switch (action.type) {
    case DADO_PERFIL_SUCCESS:
      if (action.payload.Setor) {
        action.payload.Setor = [];
      }
      return { ...state, ...action.payload };
    case DADO_PERFIL_ERRO:
      return { ...state, ...action.payload };
    case DADO_PERFIL:
      return {
        ...state,
        Perfil: [...state.Perfil, action.payload],
      };
    case DADO_POLO:
      return {
        ...state,
        Polos: action.payload.Polos,
        poloDinamico: action.payload.Polos,
        carregando: action.payload.carregando,
      };
    case DADO_PERFIL_CHANGE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case DADO_SETOR:
      return {
        ...state,
        Setor: action.payload,
      };
    default:
      return state;
  }
};

export default dadosPerfil;
