import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  width: 100%;
  padding: 30px;
  line-height: 1.5;
  font-size: 14px;

  h1 {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }

  h2 {
    font-weight: bold;
    margin-top: 10px;
  }

  p > strong,
  p > b {
    font-weight: bold;
  }
`;

export const RegisterBox = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;
