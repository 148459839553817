import React, { Component } from 'reactn';
import { Redirect } from 'react-router-dom';
import {
  Error,
  axios,
  $,
  FontAwesomeIcon,
  ComunicadoDisplay,
} from '../../services/Gerais';
import { Popover, Button, Row, Col, Icon, Input } from 'antd';
import ReactGA from 'react-ga';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from 'services/api';
import Swal from 'sweetalert2';

class ComunicadosCarouselDiversos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comunicados: [],
      mensagensImportantes: [],
      showComunicado: [],
      error: [],
      props: props,
      isActived: false,
    };
    this.logInfo = JSON.parse(localStorage.getItem('userDataCRP'));
    this.buscaComunicadoRedirecionado = this.buscaComunicadoRedirecionado.bind(
      this
    );
    this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this);
    this.poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
    this.labelCaption = (
      <div className="label-box-announcements" style={{ justifyContent: 'end', marginTop: 0 }}>
        <div className="border-box-announcements" data-tut="legenda_comunicados">
          <div className="box-label-announcements">
            <div className="box-announcements critical" />
            <p>CRÍTICO</p>
          </div>
          <div className="box-label-announcements">
            <div className="box-announcements action" />
            <p>AÇÃO</p>
          </div>
          <div className="box-label-announcements">
            <div className="box-announcements info" />
            <p>INFORMAÇÃO</p>
          </div>
          <div className="box-label-announcements">
            <div className="box-announcements reinforcement" />
            <p>REFORÇO</p>
          </div>
        </div>
      </div>
    );
  }

  copyCodeToClipboard = comunicado => {
    let textField = document.createElement('textarea');
    textField.innerText = `${window.location.origin}/comunicados/${comunicado.id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    Swal.fire({
      title:  `Copiado!`,
      text: `URL do comunicado foi copiada.`,
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  };

  async buscaComunicadoRedirecionado(id) {
    try {
      const response = await api.get(
        `comunicado/getComunicadoUsuarioLink?idUsuarioPolo=${this.logInfo['id']}&idComunicado=${id}`
      );

      if (!response) {
        Swal.fire({
          title:  `Ops...!`,
          text: `Comunicado não encontrado!`,
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }

      this.setState({
        showComunicado: response.data,
        isLoading: false,
      });

      this.toggleNaved();
    } catch (error) {
      let msg = '';
      if (error && error.response && error.response.data) {
        msg = error.response.data.error ? error.response.data.error : '';
      }
      Swal.fire({
        title:  `Ops...!`,
        text: `Comunicado não encontrado! ${msg}`,
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }
  }

  toggleNaved = () => {
    this.setState(prevState => ({ isActived: !prevState.isActived }));
  };

  componentDidMount() {
    if (!localStorage.getItem('userDataCRP')) {
      return <Redirect to={'/login'} />;
    }

    if (this.props.idRedirecinamento) {
      if (localStorage.getItem('userDataCRP-Login')) {
        this.buscaComunicadoRedirecionado(this.props.idRedirecinamento);
      }
    }

    axios
      .get(
        this.global.baseUrl +
          'comunicado/getComunicadoUsuarioCategoria?id=' +
          this.logInfo['id'] +
          '&page=0&linesPerPage=8',
        {
          // axios.get(this.global.baseUrl+'comunicado/getComunicadoUsuarioCategoria?id=40196&page=0&linesPerPage=8', {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then(result =>
        this.setState({
          comunicados: result.data,
        })
      )
      .catch(error =>
        this.setState({
          error,
          isLoading: false,
        })
      );
  }

  geraComunicado = (e, comunicado, btn) => {
    if (e) e.preventDefault();

    let lido = comunicado.lido;
    let favorito = comunicado.favorito;

    if (btn === 'like') {
      if (favorito !== 'S') {
        favorito = 'S';
        lido = 'S';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'fas');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z'
          );
      } else {
        favorito = 'N';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'far');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z'
          );
      }
    } else {
      lido = 'S';
      $(document)
        .find('[data-comunicado-lido="' + comunicado.id + '"]')
        .addClass('lido');
      this.toggleNaved();
    }

    // marcando como lido
    const dataComunicadoUsuario = [
      {
        usuarioPortal: { id: this.logInfo['usuarioPortal']['id'] },
        comunicado: { id: comunicado.id },
        favorito: favorito,
        comunicadoLido: lido,
        usuarioPortalLog: { id: this.logInfo['usuarioPortal']['id'] },
      },
    ];

    axios
      .post(
        this.global.baseUrl + 'comunicadoUsuario/saveComunicadoUsuario',
        dataComunicadoUsuario[0],
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then(result => {
        ReactGA.modalview('/comunicado/' + comunicado.codigo);
        axios
          .get(
            this.global.baseUrl +
              'comunicadoUsuario/getComunicadoByComunicadoAndUsuario/' +
              this.logInfo['usuarioPortal']['id'] +
              '/' +
              comunicado.id,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
              },
            }
          )
          .then(infosRes =>
            this.setState({
              showComunicado: infosRes.data,
              isLoading: false,
            })
          )
          .catch(error =>
            this.setState({
              error,
              isLoading: false,
            })
          );
      })
      .catch(error => {
        // console.error(error)
      });

    this.componentDidMount();
  };

  render() {
    if(this.props.comunicados && this.props.comunicados.Comunicados && this.state.mensagensImportantes.length == 0) {
      this.setState({mensagensImportantes: this.props.comunicados})
    }
    const {
      error,
      props,
      comunicados,
      showComunicado,
      mensagensImportantes,
    } = this.state;

    var getComunicados = [];

    if (props.categoria === 'Comercial') {
      getComunicados = comunicados.Comercial;
    } else if (props.categoria === 'Operações') {
      getComunicados = comunicados.Operações;
    } else if (props.categoria === 'Acadêmico') {
      getComunicados = comunicados.Acadêmico;
    } else if (props.categoria === 'Financeiro') {
      getComunicados = comunicados.Financeiro;
    } else if (props.categoria === 'Pós Graduação') {
      getComunicados = comunicados.Pos;
    } else if (props.categoria === 'Mensagens Importantes') {
      getComunicados = mensagensImportantes.Comunicados;
    } else if (props.categoria === 'Comunicados Pós Graduação') {
      getComunicados = mensagensImportantes.PosGraduacao;
    }

    var settings = {
      arrows: true,
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      slickPause: true,
      responsive: [
        {
          breakpoint: 1360,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div
        className="container-header is-fullhd comunicadosDestaque listaCategoriaComunicados"
        key="comunicadosDestaque"
        style={{ marginTop: 20 }}
      >
        <div className="columns">
          <div className="column" style={{ margin: 10}}>
            <a id='link-comunicados'
              href={
                './lista-comunicados/' +
                props.categoria
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/\s(.*)/g, '')
              }
            >
              <h4 className="title">{props.categoria}</h4>
              {this.labelCaption}
            </a>

          </div>
        </div>

        {error.response ? <Error {...error} /> : null}

        {getComunicados ? (
          <Slider {...settings}>
            {getComunicados['Comunicados'].map(comun => {
              let comunicado = comun['comunicado'];
              // eslint-disable-next-line
              let favorito = 'far';
              let boxAlerta = '';
              let boxAlertaSpan = '';
              let listaTags;
              let inicioTags = 0;
              let maxTags = 3;
              let lido = 'check';
              // let comunicadoUser = 0;
              let errata = '';

              // if (comunicadoUser > 0) {
              //     if (comunicado.comunicadoUsuarios[0].favorito === "S") {
              //         favorito = 'fas'
              //     }
              // }

              if (comunicado.lido === 'S') {
                lido = 'check lido';
              } else {
                lido = 'check';
              }

              switch (comunicado.prioridade) {
                case 'C':
                  boxAlertaSpan =
                  <span className="alerta alerta-red">
                    <FontAwesomeIcon icon={['fas', 'thermometer-full']} className="icon-thermometer"color="#ffffff"/>
                  </span>;
                  boxAlerta = 'boxAlerta-red boxAlertaSemCarousel';
                  break;
                case 'A':
                  boxAlertaSpan =
                  <span className="alerta alerta-orange">
                    <FontAwesomeIcon icon={['fas', 'thermometer-three-quarters']} className="icon-thermometer" color="#ffffff"/>
                  </span>;
                  boxAlerta = 'boxAlerta-orange boxAlertaSemCarousel';
                  break;
                case 'I':
                  boxAlertaSpan =
                  <span className="alerta alerta-blue">
                    <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff"/>
                  </span>;
                  boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                  break;
                case 'R':
                  boxAlertaSpan =
                  <span className="alerta alerta-green">
                    <FontAwesomeIcon icon={['fas', 'thermometer-quarter']} className="icon-thermometer" color="#ffffff"/>
                  </span>;
                  boxAlerta = 'boxAlerta-green boxAlertaSemCarousel';
                  break;
                default:
                  boxAlertaSpan =
                  <span className="alerta alerta-blue">
                    <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff"/>
                  </span>;
                  boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                  break;
              }

              if (comunicado.errata === 'S') {
                errata = 'ATUALIZAÇÃO - ';
              }

              if (comun['lstComunicadoTag']) {
                listaTags = comun['lstComunicadoTag'].map(function(tag) {
                  ++inicioTags;
                  if (inicioTags <= maxTags) {
                    if (inicioTags === maxTags) {
                      return (
                        <a
                          href={'./comunicados-tags/' + tag['tag']['id']}
                          key={'tag' + tag['tag']['id']}
                          title={
                            'mais ' +
                            (comun['lstComunicadoTag'].length -
                              inicioTags +
                              1) +
                            ' tags'
                          }
                          className="button is-small is-rounded is-link tagsComunicadoMais"
                        >
                          +{comun['lstComunicadoTag'].length - inicioTags + 1}
                        </a>
                      );
                    } else {
                      return (
                        <a
                          href={'./comunicados-tags/' + tag['tag']['id']}
                          key={'tag' + tag['tag']['id']}
                          title={tag['tag']['descricao']}
                          className="button is-small is-rounded is-outlined tagsComunicado"
                        >
                          {tag['tag']['descricao']}
                        </a>
                      );
                    }
                  } else {
                    return '';
                  }
                });
              }

              return (
                <div
                  className="column item"
                  data-index={comunicado.codigo}
                  key={comunicado.codigo}
                >
                  <span
                    className="salvarComunicadoRelativo"
                    data-comunicado-favorito={comunicado.id}
                    onClick={e => this.geraComunicado(e, comunicado, 'like')}
                    title={
                      comunicado.favorito === 'S'
                        ? 'Desmarcar favorito'
                        : 'Marcar como favorito'
                    }
                  >
                    <FontAwesomeIcon
                      icon={[
                        comunicado.favorito === 'S' ? 'fas' : 'far',
                        'bookmark',
                      ]}
                    />
                  </span>

                  <div className={'box bgComunicados ' + boxAlerta}>
                    <div
                      onClick={e => this.geraComunicado(e, comunicado, 'read')}
                    >
                      {boxAlertaSpan}
                      <article className="media">
                        <div className="media-content">
                          <div className="content">
                            <b className="title is-6 is-spaced comunicadosInfos">
                              <label
                                className={lido}
                                title={
                                  lido === 'check'
                                    ? 'Comunicado não lido'
                                    : 'Comunicado lido'
                                }
                                data-comunicado-lido={comunicado.id}
                              >
                                <FontAwesomeIcon icon={['fas', 'check']} />
                                <FontAwesomeIcon icon={['fas', 'check']} />
                              </label>
                              <small
                                className="cursorPointer"
                                onClick={e =>
                                  this.geraComunicado(
                                    e,
                                    comunicado,
                                    'codComunicado'
                                  )
                                }
                              >
                                {comunicado.codigo}
                              </small>
                            </b>
                            <hr className="hrNoBorder" />
                            <h5
                              className="title is-6 is-spaced cursorPointer"
                              data-comunicado={comunicado.id}
                            >
                              <span title={comunicado.titulo}>
                                {errata}
                                {comunicado.titulo &&
                                comunicado.titulo.length > 60
                                  ? comunicado.titulo.slice(0, 60).concat('...')
                                  : comunicado.titulo}
                              </span>
                            </h5>
                            <small className="smallComunicado cursorPointer"
                             dangerouslySetInnerHTML={{
                              __html: comunicado.resumoNovo,
                            }}>
                            </small>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="content is-small referenciaComunicados">
                      {listaTags}
                      <hr />
                      {/* <Popover
                        content={
                          <>
                            <Row>
                              <Col span={24}>
                                Links:
                                <Input
                                  defaultValue={`${window.location.origin}/comunicados/${comunicado.id}`}
                                  block="true"
                                  readOnly
                                />
                              </Col>
                              <Col span={24}>
                                <Button
                                  onClick={() =>
                                    this.copyCodeToClipboard(comunicado)
                                  }
                                  type="primary"
                                  block
                                >
                                  <Icon type="copy" />
                                  Copiar
                                </Button>
                              </Col>
                            </Row>
                          </>
                        }
                        title="Copiar Link de compartilhamento"
                        trigger="click"
                      > */}
                        <Button
                          key={`edit_${comunicado.id}`}
                          title={'Compartilhar esse comunicado'}
                          size="small"
                          type="primary"
                          onClick={() =>
                            this.copyCodeToClipboard(comunicado)
                          }
                        >
                          <FontAwesomeIcon icon={['fas', 'share-alt']} />
                          <span style={{ paddingLeft: 5 }}>Compartilhar</span>
                        </Button>
                      {/* </Popover> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : null}

        <div
          className={
            this.state.isActived ? 'conteudoLink is-active' : 'conteudoLink'
          }
        >
          <div className=" is-fullhd">
            <div className="columns is-gapless">
              <div
                className="is-transparent"
                style={{ width: '15%',}}
                id={'columnClose_' + props.id}
                onClick={this.toggleNaved}
              />
              <ComunicadoDisplay
                comunicado={showComunicado}
                categoriaID={props.id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComunicadosCarouselDiversos;
