import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import {
  Helmet,
  HeaderAdmin,
  FooterAdmin,
  Hero,
  MenuAdmin,
  FontAwesomeIcon,
} from '../../../../services/Gerais';

import './styles.css';
import FiltersTable from './FiltersSection';

const Lista = (props) => {
  const [tituloPagina, setTituloPagina] = useState(0);
  const [id, setId] = useState();
  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      setId(props.match.params.id);
    }
    setTituloPagina('Listar Filtros');
  }, []);

  return (
    <>
      <Helmet
        key="TitleAdmiFiltrosLista"
        title={tituloPagina}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: tituloPagina + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, usuarios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderAdminPortaisLista" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminPortaisLista" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminListaPortais"
                pageName={`Administração - ${tituloPagina}`}
                titulo={tituloPagina}
              />
            </div>
            <div className="columns botoesAcaoCadastro">
              <div className="column">
                <div
                  className="button-wrapper"
                  id="components-dropdown-demo-dropdown-button"
                >
                  <Button
                    type="primary"
                    size="large"
                    href={`/admin/filters/cadastroRelatorio/${id}`}
                  >
                    <FontAwesomeIcon icon={['fa', 'plus']} /> &nbsp; Novo Filtro
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    href={`/admin/incluir-relatorio/${id}`}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <FiltersTable form={props.form} history={props.history} id={id} />
        </div>
      </section>
      <FooterAdmin key="FooterAdminUsuarioLista" />
    </>
  );
};

export default Form.create()(Lista);
