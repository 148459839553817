
import React, { useState, useEffect } from 'react';
import { useImportFiles } from './hook';
import { Container } from './styles';
import {
  HeaderAdmin,
  FooterAdmin,
  MenuAdmin,
  Hero,
  Helmet,
} from '../../../services/Gerais';
import { Button, Form, Upload, Select, Row, Col, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import ShowImportedFieldsOnScreen from './showImportedFieldsOnScreen';


const ImportFiles = () => {
  const {
    uploadProps,
    disableFileButton,
    fileJson,
    form,
    buttonDisabled,
    functionOptions,
    profileOptions,
    handleSectorByProfile,
    changeSetorFuncaoId,
    onChange,
    importFile
  } = useImportFiles();

  const [isFuncaoSetorDisabled, setIsFuncaoSetorDisabled] = useState(true);



  useEffect(() => {

    setIsFuncaoSetorDisabled(form.perfilId === '');


  }, [form.perfilId]);



  const handlePerfilChange = async (event) => {
    await handleSectorByProfile(event);
    onChange(event, 'perfilId');

    onChange('', 'funcaoSetorId');
  };

  return (
    <Container>
      <Helmet
        key="TitleAdminImportFiles"
        title="Importação de usuários em lote - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Importação de usuários em lote' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'importFiles',
          },
        ]}
      />
      <HeaderAdmin key="HeaderImportFiles" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminImportFiles" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroImportFiles"
                pageName="Importação de usuários em lote"
                titulo="Importação de usuários em lote"
              />
            </div>
          </div>
          <Form>
            <Col span={32}>
            <Row>
                    <Col>
                    <span><a href="../../../../arquivo-template-import-usuarios-prisma.xlsx" title="Arquivo template de importação de usuários para o Prisma" target="_blank">Atenção: utilize este template para importar corretamente.</a></span>
                    </Col>
                  </Row>
              <Row gutter={32}>
                <Col span={8}>

                  <Form.Item label="Arquivo XLSX">
                    <Upload {...uploadProps}>
                      <Button disabled={disableFileButton}>
                        <UploadOutlined />
                        Selecione o arquivo .xlsx para importar
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
                              </Row>

              <Row gutter={32}>
                <Col span={8}>
                  <Form.Item label="Atualizar dados de pessoa cadastrada?">
                    <Select
                      value={form.atualizarDadosPessoa}
                      onChange={(event) =>
                        onChange(event, 'atualizarDadosPessoa')
                      }
                    >
                      <Select.Option key="S">SIM</Select.Option>
                      <Select.Option key="N">NAO</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>


                <Col span={8}>
                  <Form.Item label="Perfil">
                    <Select
                      value={form.perfilId}
                      onChange={handlePerfilChange}
                    >
                      {profileOptions.map((item, index) => (
                        <Select.Option key={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Função">
                    <Select
                      disabled={isFuncaoSetorDisabled}
                      onChange={(event) => changeSetorFuncaoId(event)}
                    >
                      {
                      functionOptions.map(function(item) {
                        return (
                          <Select.Option key={item.funcao.id} title={item.funcao.descricao}>
                          {item.funcao.descricao}

                        </Select.Option>
                        ) }
                      )}
                      </Select>

                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              span={32}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                columnGap: '14px',
              }}
            >
              <Button
                disabled={buttonDisabled}
                onClick={importFile}
                type="primary"
                style={{
                  'background-color': '#52c41a',
                  border: 'none',
                }}
              >
                Importar Arquivo
              </Button>
              <Button
                onClick={() => {
                  window.location.href = '/admin/importacao-usuarios';
                }}
                type="primary"
              >
                Voltar
              </Button>
            </Col>
          </Form>

            <Table dataSource={fileJson.data} columns={ShowImportedFieldsOnScreen} rowKey="cpf"
            style={{marginTop: '25px'}}/>


        </div>

      </section>
      <FooterAdmin key="FooterAdminImportFiles" />
      </Container>
  );
};

export default ImportFiles;
