import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Upload, message, Button, Icon, Form } from 'antd';

import {
  uploadPolos,
  changeComunicado,
} from '../../../../../../../store/actions/Administrativo/Comunicado/ComunicadoActions';
import { COMUNICADO_UPLOAD_SUCCESS } from '../../../../../../../store/actions/Administrativo/Comunicado/ComunicadoType';

const UploadPolos = ({ form }) => {
  const { setFieldsValue, getFieldDecorator } = form;

  let { DadosComunicado, carregandoUpload } = useSelector(
    (state) => state.Comunicado
  );

  useEffect(() => {
    if (DadosComunicado && DadosComunicado.lstComunicadoPolo.length > 0) {
      setFieldsValue({ lotePorPolo: [{}] });
    }
    // eslint-disable-next-line
  }, []);

  const configs = JSON.parse(localStorage.getItem('cnfgDataCRP'));
  const dispatch = useDispatch();
  const props = {
    name: 'file',
    multiple: false,
    action: `${configs.baseUrl}comunicadoPolo/sendCsvPolos`,
    showUploadList: {
      showDownloadIcon: false,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`,
    },
    onRemove() {
      dispatch(
        changeComunicado({
          prop: 'DadosComunicado',
          value: {
            ...DadosComunicado,
            lstComunicadoPolo: [],
          },
        })
      );
    },
    onChange(info) {
      dispatch(
        changeComunicado({
          prop: 'carregandoUpload',
          value: true,
        })
      );
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        dispatch({
          type: COMUNICADO_UPLOAD_SUCCESS,
          payload: {
            ...info.file.response,
          },
        });
        message.success(`${info.file.name} arquivo enviado com sucesso`);
        setFieldsValue({ lotePorPolo: [{}] });
      } else if (info.file.status === 'error') {
        setFieldsValue({ lotePorPolo: '' });
        message.error(`${info.file.name} erro ao carregar arquivo.`);
      } else {
        dispatch(
          changeComunicado({
            prop: 'carregandoUpload',
            value: false,
          })
        );
      }
    },
  };
  return (
    <Form.Item label="Upload do arquivo" size="large">
      {getFieldDecorator('lotePorPolo', {
        rules: [
          {
            required: true,
            message: 'Por favor Selecione o arquivo',
          },
        ],
      })(
        <Upload disabled={carregandoUpload} {...props}>
          <Button>
            <Icon type="upload" /> Enviar arquivo
          </Button>
        </Upload>
      )}
    </Form.Item>
  );
};

export default UploadPolos;
