import React from 'reactn';
import {
  Header,
  Footer,
  library,
  Helmet,
  MapaDisplay,
  $,
  axios,
  moment,
} from '../../services/Gerais';
import { faCheck, faStar, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import filtrar from '../../img/filtrar.png';
import print from '../../img/print.png';
library.add({ faCheck, faStar, faVideo, faBell });

class SidebarMapa extends React.Component {
  constructor() {
    super();
    this.state = {
      active: 'maisRecentes',
    };
  }
  toggleClassComunicados(tab) {
    this.setState({ active: tab });
  }

  render() {
    const { active } = this.state;

    return (
      <ul className="acoesMapaOperacoes">
        <TabLabel
          onClick={this.toggleClassComunicados.bind(this, 'maisRecentes')}
          active={active === 'maisRecentes'}
          text="Unidades Parceiras"
          slug="parceiros"
        />
        <TabLabel
          active={active === 'distancia'}
          text="Unidades Próprias"
          slug="propria"
        />
      </ul>
    );
  }
}

const TabLabel = ({ active, text, onClick, slug }) => {
  if (active === true && slug === 'comunicados') {
    //return ( <li onClick={onClick} className={active ? 'is-active' : null}><a href={"./comunicados/"}>{text}</a></li> )
    return (
      <li onClick={onClick} className={active ? 'is-active' : null}>
        <a href={'#/'}>{text}</a>
      </li>
    );
  } else {
    //return ( <li onClick={onClick} className={active ? 'is-active' : null}><a href={"./../lista-comunicados/"+slug}>{text}</a></li> )
    return (
      <li onClick={onClick} className={active ? 'is-active' : null}>
        <a href={'#/'}>{text}</a>
      </li>
    );
  }
};

export default class MapaOperacoes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActivedMapa: false,
      listMapaOperacoes: [],
      listEventos: [],
    };
    this.getListMapaOperacoes = this.getListMapaOperacoes.bind(this);
  }

  print() {
    window.print();
  }

  toggleNavedMapa = () => {
    this.setState(prevState => ({ isActivedMapa: !prevState.isActivedMapa }));
    return false;
  };

  getListMapaOperacoes() {
    var urlListMapaOperacoes =
      this.global.baseUrl + 'mapaOperacoes/consultarMapaOperacoes';
    axios
      .get(urlListMapaOperacoes, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then(respMap => {
        this.setState({
          listMapaOperacoes: respMap.data.MacroAtividade,
          listEventos: respMap.data.Evento,
        });
      })
      .catch(errorDisplay =>
        this.setState({
          errorDisplay,
          isLoading: false,
        })
      );
  }
  /**
   * Funções para Exibição Mapa
   */
  funcoesMapa() {
    let agora = moment(new Date());
    let tz = agora
      .format('YYYY-DDD-MM')
      .replace(' ', 'T')
      .replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1');
    let dataAtual = moment(new Date(tz));
    $(function() {
      $('.listaAtividades, .mapaOperacoesNiveis a').hover(function() {
        $(this).toggleClass('pulse animated');
      });
      function showCalendario() {
        function diasAno(ano) {
          var anoAtual = ano % 400 === 0 || (ano % 100 !== 0 && ano % 4 === 0);
          return anoAtual ? 366 : 365;
        }

        var alturaListaAtividade = $('.listaAtividades').outerHeight();
        var larguraCalendario = parseInt($('.calendario').width());
        var dias = larguraCalendario / diasAno(dataAtual.format('Y'));
        var fev = diasAno(dataAtual.format('Y')) === 365 ? 28 : 29;
        var diasMeses = [
          { dias: 31 },
          { dias: fev },
          { dias: 31 },
          { dias: 30 },
          { dias: 31 },
          { dias: 30 },
          { dias: 31 },
          { dias: 31 },
          { dias: 30 },
          { dias: 31 },
          { dias: 30 },
          { dias: 31 },
        ];

        function dayNo(y, m, d) {
          // eslint-disable-next-line
          return (
            parseInt(
              --m * 31 -
                (m > 1
                  ? ((1054267675 >> (m * 3 - 6)) & 7) -
                    parseInt(y & 3 || (!(y % 25) && y & 15) ? 0 : 1)
                  : 0)
            ) +
            parseInt(d) -
            1
          );
        }

        $(diasMeses).each(function(e, index) {
          var alturaCalendarioOperacao = $('.calendarioMeses .mes')
            .parent()
            .parent()
            .outerHeight();
          $('.mes')
            .eq(e)
            .css({ width: dias * index.dias });
          $('.calendarioMeses .mes')
            .eq(e)
            .css({
              minWidth: dias * index.dias,
              minHeight: alturaCalendarioOperacao,
            });
          $('.eventosDiarios').css({
            minHeight: alturaCalendarioOperacao - alturaListaAtividade,
          });
        });

        $('.mapaAtividades').each(function() {
          var iMc =
            $(this)
              .find('.macroAtividade')
              .data('inicio')
              .match(/(-?\d+)[^\d]+(\d\d?)[^\d]+(\d\d?)/) || [];
          var fMc =
            $(this)
              .find('.macroAtividade')
              .data('fim')
              .match(/(-?\d+)[^\d]+(\d\d?)[^\d]+(\d\d?)/) || [];

          var inicioMc = dayNo(iMc[1], iMc[2], iMc[3]);
          var finalMc = dayNo(fMc[1], fMc[2], fMc[3]);
          $(this).css({ width: dias * (finalMc - inicioMc), marginLeft: dias });

          $(this)
            .find('.listaAtividades')
            .each(function() {
              var i =
                $(this)
                  .data('inicio')
                  .match(/(-?\d+)[^\d]+(\d\d?)[^\d]+(\d\d?)/) || [];
              var f =
                $(this)
                  .data('fim')
                  .match(/(-?\d+)[^\d]+(\d\d?)[^\d]+(\d\d?)/) || [];
              var inicio = dayNo(i[1], i[2], i[3]);
              var final = dayNo(f[1], f[2], f[3]);

              $(this).css({
                width: dias * (final - inicio),
                left: inicio * dias,
              });
            });
        });

        $('.eventosDiarios, .eventosDiversos').each(function() {
          var iMc =
            $(this)
              .data('evento')
              .match(/(-?\d+)[^\d]+(\d\d?)[^\d]+(\d\d?)/) || [];

          var inicioMc = dayNo(iMc[1], iMc[2], iMc[3]);
          $(this).css({ left: inicioMc * dias });
        });
      }

      showCalendario();

      $(window).resize(function() {
        showCalendario();
      });

      $('.tag').on('click', function() {
        var operacao = $(this)
          .attr('class')
          .split(' ')[0];
        $('.mapaAtividades.' + operacao).each(function() {
          $(this).toggleClass('is-hidden');
        });
        $(this)
          .find('.delete')
          .toggleClass('rotate');
        $(this).toggleClass('opacity');
      });

      $('.linhaMacroAtividades').on('click', function() {
        if (
          !$(this)
            .next()
            .next('div.linhaMicroAtividades')
            .hasClass('is-hidden')
        ) {
          $(this)
            .next()
            .next('div.linhaMicroAtividades')
            .addClass('is-hidden');
        }
        $(this)
          .next('div.linhaAtividades')
          .toggleClass('is-hidden');
      });

      $('.linhaAtividades').on('click', function() {
        $(this)
          .next('div.linhaMicroAtividades')
          .toggleClass('is-hidden');
      });

      $('.informacaoMA').on('click', () => {
        // $(this).next('.message').toggleClass('is-hidden');
        // return false;
      });
    });
  }

  componentDidMount() {
    this.getListMapaOperacoes();
    //this.funcoesMapa();
  }

  componentDidUpdate() {
    this.funcoesMapa();
  }

  render() {
    const { listMapaOperacoes, listEventos } = this.state;

    let monthsShort = moment.monthsShort();

    let eventoVGClean = [];

    let msgLimpo = [];

    return [
      <Helmet
        title="Mapa de Operações - Portal Prisma - Kroton"
        meta={[
          {
            name: 'description',
            content:
              'Mapa de Operações - Portal Unificado da Kroton Educacional',
          },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          {
            name: 'keywords',
            content: 'mapa de operações, kroton, educacional, portal',
          },
        ]}
        key="titleMapa"
      />,
      <Header key="HeaderMapa" />,
      <section className="hero is-crp" key="heroMapa">
        <div className="hero-body">
          <div className="container is-fullhd">
            <nav
              className="breadcrumb is-left"
              aria-label="breadcrumbs"
              key={'navegacao'}
            >
              <ul>
                <li>
                  <a href="./home">Home</a>
                </li>
                <li className="is-active">
                  <a href="./mapa" aria-current="page">
                    Mapa de Operações
                  </a>
                </li>
              </ul>
            </nav>
            <h1 className="title is-1">Mapa de Operações</h1>
          </div>
        </div>
        <div className="hero-foot">
          <nav className="tabs is-boxed is-fullwidth">
            <div className="container">
              <div className="columns is-gapless">
                <div className="column">
                  <SidebarMapa />
                </div>
                <div className="column" />
              </div>
            </div>
          </nav>
        </div>
      </section>,
      <section className="section mapaOperacoes" key="mapaOperacoes">
        <div className="container is-fullhd">
          <div className="columns">
            <div className="column">
              <img src={filtrar} className="filtro" alt="Filtros" />{' '}
              <strong>Filtrar</strong>
              <span className="financeiro tag is-info is-medium">
                Financeiro <button className="delete is-small financeiro" />
              </span>
              <span className="operacional tag is-info is-medium">
                Operacional <button className="delete is-small operacional" />
              </span>
              <span className="comercial tag is-info is-medium">
                Comercial <button className="delete is-small comercial" />
              </span>
              <span className="academico tag is-info is-medium">
                Acadêmico <button className="delete is-small academico" />
              </span>
              <span className="pos tag is-info is-medium">
                Pós Graduação <button className="delete is-small pos" />
              </span>
            </div>
            <div className="column is-one-fifth is-text-right">
              <div className="field">
                {/*<input id="switchRoundedInfo" type="checkbox" name="switchRoundedInfo" className="switch is-rounded is-info" />
                                <label htmlFor="switchRoundedInfo"><small>Ver menos eventos</small></label>*/}
                <span onClick={this.print}>
                  <img
                    src={print}
                    className="print"
                    alt="Imprimir"
                    title="Imprimir"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container is-fullhd">
          <div className="fHeader" id="fHeader">
            <div className="columns calendario meses is-desktop is-mobile">
              {monthsShort.map(mes => (
                <div className="column" key={mes}>
                  {mes.toUpperCase()}
                </div>
              ))}
            </div>

            {listEventos.length > 0
              ? listEventos.map((eventoVG, index) => {
                  let listEvento = [];
                  let strEvDataInicio = moment(
                    eventoVG.dataInicio,
                    'DD-MM-YYYY'
                  );

                  listEvento = [
                    <span
                      className="eventosDiarios eventoVG"
                      data-evento={strEvDataInicio.format('YYYY-MM-DD')}
                    >
                      <span>{eventoVG.titulo}</span>
                    </span>,
                  ];
                  return listEvento;
                })
              : eventoVGClean}
          </div>
          <div className="MapaContent">
            {/* Macro Atividades */}
            {listMapaOperacoes.length > 0
              ? listMapaOperacoes.map((mapaAtividades, index) => {
                  let lista = [];

                  let intMapaAtividadeID = mapaAtividades.id;
                  let strTitulo = mapaAtividades.titulo;

                  let strClassMapaAtividade = 'mapaAtividades';
                  let strClassMacroAtividade = 'macroAtividade';
                  let strClassListaAtividades = 'listaAtividades';

                  let strCategoriaDesc = mapaAtividades.categoria.descricao.toLowerCase();

                  let strDataInicio = moment(
                    mapaAtividades.dataInicio,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD');
                  let strDataFim = moment(
                    mapaAtividades.dataFim,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD');

                  let strColor = mapaAtividades.categoria.cor;

                  let mapListAtividade = mapaAtividades.lstAtividade;

                  let arrMicroAtividade = [];

                  lista = [
                    <div
                      className="columns"
                      key={index}
                      data-reg={intMapaAtividadeID}
                      data-between={strDataInicio.concat(' ', strDataFim)}
                    >
                      <div
                        className={strClassMapaAtividade.concat(
                          ' ',
                          strCategoriaDesc
                        )}
                      >
                        <div className="linhaMacroAtividades">
                          <div
                            className={strClassListaAtividades.concat(
                              ' ',
                              strClassMacroAtividade,
                              ' ',
                              strCategoriaDesc,
                              ' tooltip'
                            )}
                            data-inicio={strDataInicio}
                            data-fim={strDataFim}
                            style={{ backgroundColor: strColor }}
                            data-tooltip={strTitulo}
                          >
                            <div>{strTitulo}</div>
                            {/*strCiclo !== 'A' &&
                                                        <span className={'ciclo ' + strCiclo.toLowerCase()}></span>
                                */}
                          </div>
                        </div>
                        {/*Atividades */}
                        {mapListAtividade.length > 0 && (
                          <div className="linhaAtividades is-hidden">
                            {mapListAtividade.map((listaAtividades, index) => {
                              let arrAtividade = [];
                              let strAtividadeIni = moment(
                                listaAtividades.dataInicio,
                                'DD-MM-YYYY'
                              ).format('YYYY-MM-DD');
                              let strAtividadeFim = moment(
                                listaAtividades.dataFim,
                                'DD-MM-YYYY'
                              ).format('YYYY-MM-DD');

                              let strAtividadeTitulo = listaAtividades.titulo;
                              let mapMicroAtividade =
                                listaAtividades.lstMicroAtividade;
                              let strAtividadeCiclo = listaAtividades.ciclo;

                              mapMicroAtividade.length > 0 &&
                                arrMicroAtividade.push(mapMicroAtividade);

                              arrAtividade = [
                                <div
                                  key={index}
                                  className={
                                    'listaAtividades atividade ' +
                                    strCategoriaDesc +
                                    ' tooltip'
                                  }
                                  data-inicio={strAtividadeIni}
                                  data-fim={strAtividadeFim}
                                  data-tooltip={strAtividadeTitulo}
                                >
                                  <div>{strAtividadeTitulo}</div>
                                  {strAtividadeCiclo !== 'A' && (
                                    <span
                                      className={
                                        'ciclo ' +
                                        strAtividadeCiclo.toLowerCase()
                                      }
                                    />
                                  )}
                                </div>,
                              ];

                              return arrAtividade;
                            })}
                          </div>
                        )}
                        {arrMicroAtividade.length > 0 && (
                          <div className="linhaMicroAtividades is-hidden">
                            {arrMicroAtividade[0].map(
                              (listaMicroAtividades, index) => {
                                let arrMicroAtividadeResponse = [];

                                let strMicroAtividadeIni = moment(
                                  listaMicroAtividades.dataInicio,
                                  'DD-MM-YYYY'
                                ).format('YYYY-MM-DD');
                                let strMicroAtividadeFim = moment(
                                  listaMicroAtividades.dataFim,
                                  'DD-MM-YYYY'
                                ).format('YYYY-MM-DD');

                                let strMicroAtividadeTitulo =
                                  listaMicroAtividades.titulo;
                                let boolEventoContiuo = false;
                                let strEventoContinuo =
                                  boolEventoContiuo !== false &&
                                  'eventoContinuo';

                                arrMicroAtividadeResponse = [
                                  <div
                                    key={index}
                                    className={
                                      'listaAtividades microAtividade ' +
                                      strEventoContinuo +
                                      ' tooltip'
                                    }
                                    data-inicio={strMicroAtividadeIni}
                                    data-fim={strMicroAtividadeFim}
                                    data-tooltip={strMicroAtividadeTitulo}
                                  >
                                    <div>{strMicroAtividadeTitulo}</div>
                                  </div>,
                                ];
                                return arrMicroAtividadeResponse;
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </div>,
                  ];
                  return lista;
                })
              : msgLimpo}
          </div>
        </div>
        <div
          className={
            this.state.isActivedMapa ? 'conteudoLink is-active' : 'conteudoLink'
          }
        >
          <div className="container is-fullhd">
            <div className="columns is-gapless">
              <div
                className="column is-transparent"
                id="columnClose"
                onClick={this.toggleNavedMapa}
              />
              {/*<MapaDisplay operacao={showOperacao} isActivedMapaDisplay={this.state.isActivedMapa} />*/}
              <MapaDisplay isActivedMapaDisplay={this.state.isActivedMapa} />
            </div>
          </div>
        </div>
      </section>,
      <hr className="hrEspacamento" key="hrEspacamento" />,
      <Footer key="FooterMapa" />,
    ];
  }
}
