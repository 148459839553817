import api from '../../../services/api';

export const paramLimit = async () => {
  try {
    const res = await api.get(`usuario/listUsuario/paramLimit`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const listPortais = async () => {
  try {
    const res = await api.get(`sistema/`);
    return res.data
  } catch (error) {
    throw error;
  }
};

export const portalItem = async (id) => {
  try {
    const res = await api.get(`sistema/${id}`);
    return res.data
  } catch (error) {
    throw error;
  }
};

export const findByFilters = async (params) => {
  try {
    const res = await api.get(`sistema/filter/`, { params: params });
    return res.data
  } catch (error) {
    return error;
  }
};

export const deleteFile = async (response) => {
  try {
    const res = await api.post(`file/delete?filePath=${response}`);
    return res;
  } catch (error) {
    return error;
  }
};


export const registerPortal = async (body) => {
  try {
    const res = await api.post(`sistema/`, body);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export const updatePortal = async (body, id) => {
  try {
    const res = await api.put(`sistema/${id}`, body);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};
