import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

import { csvJSON } from '../../../../utils/formatters/csv';
import {
  sendReport,
  onGetDocsStructure,
  onGetPolosAdmin,
  onUpdateDocsStructure,
  onExportCSV,
  onGetCSV,
  download,
  onGetDocsOnBase,
  sendDocument
} from '../../../../services/workFlow/service.js';
import { csv2json } from '../../../../utils/csvToJson';
import config from '../../../../config/config';

const useSearch = () => {
  const [fileList, setFileList] = useState([]);
  const [fileJson, setFileJson] = useState({ data: [] });
  const [isDisabled, setIsDisabled] = useState(false);
  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;
  const handleImportFile = useCallback(async () => {
    try {
      const allUploads = [];
      const errors = [];
      fileJson.data.forEach((item) => {
        if (item.name && !item.data.length) {
          errors.push(item.name);
        }
        item.data.forEach((data) => {
          allUploads.push({
            polo: data.polo,
            documento: data.documento,
            validade: moment(data.validade)._i,
            status: data.status,
            observacao: data.observacao,
            usuarioInclusao: JSON.parse(userSession)[0].id,
            idImportacao: (data.id_importacao == '' ? null : data.id_importacao),
            idDocumento: (data.id_documento == '' ? null : data.id_documento),
            ativo: 'S'
          });
        });
      });
      if (!allUploads.length > 0 || errors.length > 0) {
        return Swal.fire({
          title: 'Atenção',
          text: `Não foi possível importar o ${errors[0]} pois ele se encontra vazio!`,
        });
      }
      const body = {
        nomeArquivo: fileList[0].name,
        statusDocumentos: allUploads,
        usuarioInclusao: JSON.parse(userSession)[0].id.toString(),
      };

      const { data } = await sendReport({ body });
      Swal.fire({
        position: 'top-end',
        title: `Importação realizada com sucesso!`,
        icon: 'success',
        showConfirmButton: false,
        timer: 3000,
      }).then(result => {
        window.location.reload();
      });
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        title: 'Atenção',
        text: error?.response?.data?.error,
      });
    }
  }, [fileJson.data]);

  useEffect(() => {
    if (fileList.length > 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [fileList]);


  const uploadPropsDocs = {
    name: 'file',
    action: `${config.baseUrl}file/upload`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`
    },
    accept: '.csv',
    maxCount: 1,
    multiple: false,
    onChange(info) {
      const result = [];
      info.fileList.forEach((item) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csv = e.target.result;
          result.push({
            name: item.name,
            data: csv2json(csv, { parseNumbers: true }),
          });
        };
        reader.readAsText(item.originFileObj, 'ISO-8859-1');
      });
      setFileJson({ data: result });
      setFileList(info.fileList);
    },
  };

  return {
    isDisabled,
    uploadPropsDocs,
    handleImportFile
  };
};
export { useSearch };

export const onGetDocs = async (params) => {
  return await onGetDocsStructure(params);

}

export const onGetPolos = async () => {
  return await onGetPolosAdmin();
}

export const onUpdateDocs = async (id, type, value) => {
  let params = {
    "validade": (type === "validade" ? value : null),
    "status": (type === "status" ? value : null),
    "observacao": (type === "observacao" ? value : null),
  }

  return await onUpdateDocsStructure(id, params)
}

export const exportCSV = async ({ documento, polo, status, validade }) => {
  let relatorioConfig = {
    relatorioId: 47,
    lstParametros: [
      {
        id: 62,
        nome: 'Documento',
        valor: documento,
      },
      {
        id: 63,
        nome: 'Polo',
        valor: polo,
      },
      {
        id: 64,
        nome: 'Status',
        valor: status,
      },
      {
        id: 65,
        nome: 'Validade',
        valor: validade,
      },
    ],
  };
  try {
    const resposta = await onExportCSV(relatorioConfig);
    Swal.fire({
      position: 'top-end',
      title: `Sucesso!`,
      text: 'Relatório gerado com sucesso!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
    const BOM = '\uFEFF';
    let conteudo = resposta.data.replace(/\n/gm, '');
    const relatorioDados = BOM + conteudo.replace(/,/g, ' ');
    const contentType = resposta.headers['content-type'];
    const filename = resposta.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
      .replace('"', '')
      .replace('"', '');
    const blob = new Blob([relatorioDados], {
      type: 'text/csv',
    });
    saveAs(blob, filename);
  } catch (err) {
    Swal.fire({
      title: 'Erro',
      text: 'Não foi possivel realizar operação',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
  }
};

export const onGetCSVs = async (params) => {
  return await onGetCSV(params);
}

export const handleDownload = async (id, fileName) => {
  const data = await download(id);
  await downloadData(data.data, fileName);
}

const objectToCsv = function (data) {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));
  data.forEach((item, index) => {
    const values = headers.map((header) => {
      const val = item[header];
      return val;
    });
    csvRows.push(values.join(','));
  });
  return csvRows.join('\n');
};

export const downloadData = async (jsonCsv, fileName) => {
  if (jsonCsv.length > 0) {
    const csv = objectToCsv(jsonCsv);
    var myBlob = new Blob([csv], { type: 'text/csv' });
    var url = window.URL.createObjectURL(myBlob);
    var anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(url);
    anchor.remove();
  } else {
    Swal.fire({
      title: 'Atenção',
      text: 'Não há registros nesse arquivo para realizar o download',
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  }

};

export const onDocsOnBase = async () => {
  return await onGetDocsOnBase();
}

export const onSendDocument = async (codTipoDocOnbase, idDocumento, file) => {
  let dataFile = new FormData();
  dataFile.append('file', file);
  dataFile.append("codTipoDocOnbase", codTipoDocOnbase);
  dataFile.append("idDocumento", idDocumento);
  return await sendDocument(dataFile);
}
