import { useCallback, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { read,  utils } from 'xlsx';
import config from '../../config/config';
import { importPolos } from './service';

const ControllerD4 = () => {
  const [fileList, setFileList] = useState([]);
  const [fileJson, setFileJson] = useState({ data: [] });
  const [link, setLink] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const userSession = localStorage.getItem('userDataCRP-Login');
  const parseSession = userSession ? JSON.parse(userSession) : undefined;
  const handleImportPolos = useCallback(async () => {
    try {
      const allUploads = [];
      const errors = [];
      console.log('fileJson', fileJson.data)
      fileJson.data.forEach((item) => {
        if (item.name && !item.data.length) {
          errors.push(item.name);
        }
        allUploads.push({
          idPolo: item.ID_POLO,
          nomePolo: item.NOME_POLO,
          razaoSocialEmpresa: item.RAZAO_SOCIAL_EMPRESA,
          municipioRazaoSocial: item.MUNICIPIO_RAZAO_SOCIAL,
          ufRazaoSocial: item.UF_RAZAO_SOCIAL,
          enderecoRazaoSocial: item.ENDERECO_RAZAO_SOCIAL,
          numeroRazaoSocial: item.NUMERO_RAZAO_SOCIAL,
          bairroRazaoSocial: item.BAIRRO_RAZAO_SOCIAL,
          cepRazaoSocial: item.CEP_RAZAO_SOCIAL,
          cnpj: item.CNPJ,
          cpfGestorPolo: item.CPF_GESTOR_POLO,
          nomeGestorPolo: item.NOME_GESTOR_POLO,
          dataRepasse: item.DATA_REPASSE,
          dataAssinaturaAtualModeloCp: item.DATA_ASSIN_ATUAL_MODELO_CP,
          razaoSocialNovo: item.RAZAO_SOCIAL_NOVO,
          cidadeRazaoNovo: item.CIDADE_RAZAO_NOVO,
          estadoRazaoNovo: item.ESTADO_RAZAO_NOVO,
          enderecoRazaoNovo: item.ENDERECO_RAZAO_NOVO,
          bairroRazaoNovo: item.BAIRRO_RAZAO_NOVO,
          cepRazaoNovo: item.CEP_RAZAO_NOVO,
          cnpjRazaoNovo: item.CNPJ_RAZAO_NOVO,
          representanteLegalNovo: item.REPRESENTANTE_LEGAL_NOVO,
          enderecoTeleaula: item.ENDERECO_TELEAULA,
          numeroTeleaula: item.NUMERO_TELEAULA,
          complementoTeleaula: item.COMPLEMENTO_TELEAULA,
          bairroTeleaula: item.BAIRRO_TELEAULA,
          cepTeleaula: item.CEP_TELEAULA,
          municipioTeleaula: item.MUNICIPIO_TELEAULA,
          estadoTeleaula: item.ENDERECO_TELEAULA,
          enderecoPoloNovo: item.ENDERECO_POLO_NOVO,
          complementoPoloNovo: item.COMPLEMENTO_POLO_NOVO,
          bairroPoloNovo: item.BAIRRO_POLO_NOVO,
          cepPoloNovo: item.CEP_POLO_NOVO,
          cidadePoloNovo: item.CIDADE_POLO_NOVO,
          ufPoloNovo: item.UF_POLO_NOVO,
          cicloAtivacao: item.CICLO_DE_ATIVACAO,
          dataDisparo: item.DATA_DISPARO,
          dataSistema: item.DATA_SISTEMA,
          ciclo1: item.CICLO1,
          ciclo2: item.CICLO2,
          ciclo3: item.CICLO3,
          queda1: item.QUEDA1,
          queda2: item.QUEDA2,
          queda3: item.QUEDA3,
          enderecoPolo: item.ENDERECO_POLO,
          numeroPolo: item.NUMERO_POLO,
          complementoPolo: item.COMPLEMENTO_POLO,
          bairroPolo: item.BAIRRO_POLO,
          cepPolo: item.CEP_POLO,
          cidadePolo: item.CIDADE_POLO,
          ufPolo: item.UF_POLO,
          complementoRazaoSocial: item.COMPLEMENTO_RAZAO_SOCIAL,
          cpfRepresentanteLegal: item.CPF_REPRESENTANTE_LEGAL,
          nomeRepresentanteLegal: item.NOME_REPRESENTANTE_LEGAL,
          complementoRazaoNovo: item.COMPLEMENTO_RAZAO_NOVO,
          cpfRepresentanteLegalNovo: item.CPF_REPRESENTANTE_LEGAL_NOVO,
          emailGestorPolo: item.EMAIL_GESTOR_POLO,
        })

      });
      if (!allUploads.length > 0 || errors.length > 0) {
        return Swal.fire({
          title: 'Atenção',
          text: `Não foi possível importar o ${errors[0]} pois ele se encontra vazio!`,
        });
      }

      const { data } = await await importPolos(allUploads);
      Swal.fire({
        position: 'top-end',
        title: `Importação realizada com sucesso!`,
        icon: 'success',
        showConfirmButton: false,
        timer: 3000,
      }).then(result => {
        window.location.reload();
      });
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        title: 'Atenção',
        text: error?.response?.data?.error,
      });
    }
  }, [fileJson]);

  useEffect(() => {
    if (fileList.length > 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [fileList]);

  useEffect(() => { (async() => {
    if(link == '') return
    const response = await (await fetch(link)).arrayBuffer();
    const fileParsed = read(response);
    const fileLocated = fileParsed.Sheets[fileParsed.SheetNames[0]];
    const data = utils.sheet_to_json(fileLocated);
    setFileJson({ data: data });


  })(); }, [link]);

  const uploadPropsDocs = {
    name: 'file',
    action: `${config.baseUrl}file/upload`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('userDataCRP-AuthTk')}`
    },
    accept: '.xlsx',
    maxCount: 1,
    multiple: false,
    onChange(info) {
      info.fileList.forEach((item) => {
        if(item.response?.link) {
          setLink(item.response.link)
          setFileList(info.fileList);
        }
      });
    },
  };

  return {
    handleImportPolos,
    uploadPropsDocs
  };
};
export { ControllerD4 };

