import { Button, Tag, Icon } from 'antd';
import React from 'react';
import { ActionContainer } from './styled';

export const tableColumns = [
  {
    title: 'ID',
    key: 'id',
    width: 50,
    dataIndex: 'id',
    valueType: 'indexBorder',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Sistema/Portal',
    key: 'portal',
    dataIndex: 'portal',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'SSO',
    key: 'sso',
    dataIndex: 'sso',
    copyable: true,
    ellipsis: true,
    width: 100,
    render: (_, record) => {
      const objTag =
      record.sso === 'SIM'
          ? { tag: 'SIM', color: '#87d068' }
          : { tag: 'NÃO', color: 'volcano' };
      return (<span>
        <Tag color={objTag.color} key={objTag.tag}>
          {objTag.tag.toUpperCase()}
        </Tag>
      </span>
    )}
  },
  {
    title: 'Permissão',
    key: 'permissao',
    dataIndex: 'permissao',
    copyable: true,
    ellipsis: true,
    width: 160,
    render: (_, record) => {
      const objTag =
      record.permissao === 'TODOS'
          ? { tag: 'Todos', color: '#7fb2ff' }
          : { tag: 'Polos Próprios', color: '#a384b2' };
      return (<span>
        <Tag color={objTag.color} key={objTag.tag}>
          {objTag.tag.toUpperCase()}
        </Tag>
      </span>)
    },
  },
  {
    title: 'Status',
    key: 'ativo',
    dataIndex: 'ativo',
    width: 100,
    render: (_, record) => {
      const objTag =
      record.ativo === 'ATIVO'
          ? { tag: 'Ativo', color: '#87d068' }
          : { tag: 'Inativo', color: 'volcano' };
      return (<span>
        <Tag color={objTag.color} key={objTag.tag}>
          {objTag.tag.toUpperCase()}
        </Tag>
      </span>)
    },
  },
  {
    title: 'Ação',
    valueType: 'action',
    dataIndex: 'id',
    key: 'edit',
    width: 130,
    render: (_, record) => (
      <ActionContainer key={`action_${record.id}`}>
        <Button
          href={`/admin/portais/edit/${record?.id}`}
          type="dashed"
          className="button is-small is-warning"
          size="small"
        >
          <Icon type="file-search" />
          Editar
        </Button>
      </ActionContainer>
    ),
  },
];
