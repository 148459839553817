import React, { Component } from 'reactn';
import {
  Header,
  Footer,
  Hero,
  Helmet,
  axios,
  $,
  FontAwesomeIcon,
  moment,
} from '../../services/Gerais';
import '../../styles/Styles.scss';
import '../../styles/Foca.scss';
import 'antd/dist/antd.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Icon,
  Table,
  Transfer,
  Upload,
  Modal,
  TimePicker,
  Spin,
} from 'antd';

import api from '../../services/api';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { func } from 'prop-types';
import { getUsersGestor } from 'services/foca/FocaService';
import { exportXLSX } from './FocaController';
import { item } from '../Documentos/service';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { TransferItem } = Transfer;
const locale = 'pt-BR';
let planosDeAcao = [];

class Checkin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      expense: {},
      columns: [],
      columnsSearch: [],
      dataIndex: [{ prazo: 'teste' }],
      expandedRowKeys: [],
      pagination: { position: 'bottom' },
      initial: [],
      focaCheckin: {},
      polos: [],
      users: [],
      lstUsers: [],
      plano: [],
      lista: [],
      search: [],
      serchAction: '',
      transferData: [],
      lstPolos: [],
      count: 0,
      poloId: null,
      loading: false,
      isOpen: false,
      isOpenSearch: false,
      index: null,
      dataInicio: null,
      dataFim: null,
      isEdit: false,
      isRequired: true,
      visible: false,
      isModal: false,
      modal: {},
      record: {},
      disabled: false,
      isUpdate: true,
      readOnlyWithoutWallet: false,
      readOnly:
        localStorage.getItem('userDataCRP-Perfil') == 376 ||
        localStorage.getItem('userDataCRP-Perfil') == 370
          ? true
          : false,
      userOwner: {},
      countRestResume: 600,
      countRestParticipants: 600,
      countRestAction: 600,
      countRestResult: 600,
    };
    this.titlePage = 'FOCA Check-in';
    this.url = JSON.parse(localStorage.getItem('cnfgDataCRP'));
    this.user = JSON.parse(localStorage.getItem('userDataCRP-Login'));
    this.lstPolosUserLogged = JSON.parse(localStorage.getItem('userDataCRP-ListaPolos'));
    this.plano = {};
    this.novoPlano = {
      foca: '',
      pilar: '',
      acao: '',
      responsavel: '',
      prazo: '',
      usuarioInclusao: '',
      dataInclusao: '',
      ganhoEstimado: 0,
    };
    this.location = window.location.href.substr(
      window.location.href.lastIndexOf('/') + 1
    );
    this.lstUsers = [];
    this.nomePolo = '';
  }

  componentDidMount() {
    this.state.focaCheckin.planosDeAcao = [];
    this.setState({
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'FOCA',
          dataIndex: 'foca',
          key: 'foca',
          render: (text, record, index) => {
            switch (text) {
              case 'F':
                return <label>Financeiro</label>;
              case 'O':
                return <label>Operacional</label>;
              case 'C':
                return <label>Comercial</label>;
              case 'A':
                return <label>Acadêmico</label>;
            }
          },
        },
        {
          title: 'PILAR',
          dataIndex: 'pilar',
          key: 'pilar',
          render: (text, record, index) => {
            switch (text) {
              case 'V':
                return <label>Volume</label>;
              case 'I':
                return <label>Inadimplência</label>;
              case 'R':
                return <label>Retenção</label>;
              case 'E':
                return <label>Estrutura Padrão</label>;
              case 'L':
                return <label>LRT</label>;
              case 'O':
                return <label>Outros</label>;
            }
          },
        },
        {
          title: 'PRAZO',
          dataIndex: 'prazo',
          key: 'prazo',
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          render: (text, record, index) => {
            switch (text) {
              case 'A':
                return <label>Em acompanhamento</label>;
              case 'C':
                return <label>Concluido</label>;
              case 'N':
                return <label>Não Concluido</label>;
              case 'G':
                return <label>Concluido pelo Gestor</label>;
            }
          },
        },
        {
          title: 'EDITAR',
          dataIndex: 'id',
          key: 'x',
          render: (text, record, index) => (
            <Button
              title="Editar "
              className="button is-small is-warning"
              onClick={() => this.handleModal(index, record)}
              disabled={(record.status === 'A' ? false : this.state.readOnly)}
            >
              <Icon type="save" />
              Editar
            </Button>
          ),
        },
        {
          title: 'REMOVER',
          dataIndex: 'id',
          key: 'x',
          render: (text, record, index) => (
            <div className="acao">
              <Button
                title="Modal"
                type="danger"
                onClick={(e) => this.openModal(record.id, index)}
                disabled={this.state.readOnly || this.state.readOnlyWithoutWallet}
              >
                <Icon type="delete" />
              </Button>
            </div>
          ),
        },
      ],
      columnsSearch: [
        {
          title: 'FOCA',
          dataIndex: 'foca',
          key: 'foca',
          render: (text, record, index) => {
            switch (text) {
              case 'F':
                return <label>Financeiro</label>;
              case 'O':
                return <label>Operacional</label>;
              case 'C':
                return <label>Comercial</label>;
              case 'A':
                return <label>Acadêmico</label>;
            }
          },
        },
        {
          title: 'PILAR',
          dataIndex: 'pilar',
          key: 'pilar',
          render: (text, record, index) => {
            switch (text) {
              case 'V':
                return <label>Volume</label>;
              case 'I':
                return <label>Inadimplência</label>;
              case 'R':
                return <label>Retenção</label>;
              case 'E':
                return <label>Estrutura Padrão</label>;
              case 'L':
                return <label>LRT</label>;
              case 'O':
                return <label>Outros</label>;
            }
          },
        },
        {
          title: 'AÇÃO',
          dataIndex: 'acao',
          key: 'acao',
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          render: (text, record, index) => {
            switch (text) {
              case 'A':
                return <label>Em acompanhamento</label>;
              case 'C':
                return <label>Concluido</label>;
              case 'N':
                return <label>Não Concluido</label>;
              case 'G':
                return <label>Concluido pelo Gestor</label>;
            }
          },
        },
        {
          title: 'EDITAR',
          dataIndex: 'id',
          key: 'x',
          render: (text, record, index) => (
            <Button
              title="Editar "
              className="button is-small is-warning"
              onClick={() => this.getActionById(record)}
              disabled={(record.status === 'A' ? false : this.state.readOnly)}
            >
              <Icon type="save" />
              Editar
            </Button>
          ),
        },
        {
          title: 'REMOVER',
          dataIndex: 'id',
          key: 'x',
          render: (text, record, index) => (
            <div className="acao">
              <Button
                title="Modal"
                type="danger"
                onClick={(e) => this.openModal(record.id, index)}
                disabled={this.state.readOnly}
              >
                <Icon type="delete" />
              </Button>
            </div>
          ),
        },
      ],
    });
    this.getInitial();
    this.getUser();
    let hour = moment(new Date(), 'DD-MM-YYYY');
    this.state.focaCheckin.dataVisita = hour.format('DD-MM-YYYY');
    this.state.focaCheckin.liberarPdf = 'N';
    this.render();
  }

  saveAcao = () => {
    this.setState({ lista: planosDeAcao });
    this.render();
    this.handleModal();
  };

  getUser = async () => {
    
    if (this.location > 0) {
      await axios
        .get(`${this.url.baseUrl}foca/${this.location}`, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then((result) => {
          planosDeAcao = result.data.planosDeAcao; 
          const userCreatedCheckin = (result.data.usuarioInclusao.id == this.user[0].usuarioPortal.id)

          this.setState({
            focaCheckin: result.data,
            lista: result.data.planosDeAcao,
            search: result.data.planosDeAcao,
            isEdit: true,
            loading: false,
            count: result.data.planosDeAcao
              ? result.data.planosDeAcao.length - 1
              : 0,
            userOwner: result.data.usuarioInclusao,
          });
          this.countRestString(result.data.participantes, "participantes");
          this.countRestString(result.data.resumo, "resumo");
          const perfilId = localStorage.getItem('userDataCRP-Perfil');
          if (perfilId == 376 || perfilId == 999 || perfilId == 2) {
            this.setState({ readOnly: true });
            this.setState({ readOnlyWithoutWallet: true });
            return;
          };
          this.lstPolosUserLogged.forEach(item => {
            const isYourWallet = result.data.checkinPolos.filter((polo) => polo.id == item.id.id)
              if(isYourWallet.length == 0 || localStorage.getItem('userDataCRP-Perfil') == 376) {
              this.setState({ readOnly: true })
              this.setState({ readOnlyWithoutWallet: true })
            }
          });
          if( userCreatedCheckin == true) {
            this.setState({ readOnly: false })
            this.setState({ readOnlyWithoutWallet: false })
          }
        });
    }
    this.render();
  };

  getPolos = async () => {
    if (this.location > 0) {
      await axios
        .get(`${this.url.baseUrl}foca/polos/${this.location}`, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then((result) => {
          this.handleChange(result.data.toString().split(','));
        });
    }
    this.render();
  };

  filterActions = () => {
    axios
      .get(
        `${this.url.baseUrl}foca/findPlanosAcoes?checkinId=${this.location}&acao=${this.state.serchAction}`,
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then((result) => {
        this.setState({
          search: result.data,
        });
      });
    this.render();
  };

  openModal = (id, index) => {
    if (id != undefined) {
      Swal.fire({
        title: `Inativar?`,
        text: 'Deseja inativar este plano de ação?',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      })
        .then((result) => {
          if (result.isConfirmed === true) {
            $.ajax({
              url: `${this.url.baseUrl}foca/${id}`,
              type: 'PATCH',
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
                'Content-Type': 'application/json',
              },
              success: function (data) {
                Swal.fire({
                  position: 'top-end',
                  title: `Plano de ação inativado com successo !`,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                });
              },
              error: function (error) {
                Swal.fire({
                  position: 'top-end',
                  title: 'Ops...!',
                  text: 'Não foi possivel inativar',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 1500,
                });
              },
            });
          }
        })
        .catch();
    }

    this.removeLine(index);
  };

  configRangeDate = () => {
    const { dataInicio, dataFim } = this.state;
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Por Favor selecione um periodo',
        },
      ],
    };
    if (dataInicio !== null && dataFim !== '') {
      let starDate = moment(dataInicio, 'DD-MM-YYYY');
      let endDate = moment(dataFim, 'DD-MM-YYYY');
      Object.assign(rangeConfig, {
        initialValue: [starDate, endDate],
      });
    }

    return rangeConfig;
  };

  getInitial = async () => {
    await axios
      .get(
        `${this.url.baseUrl}foca/dadosCadastro/${this.user[0].usuarioPortal.id}`,
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then((result) => {
        let transferData = [];
        result.data.polos.forEach(function (item) {
          transferData.push({
            key: item.id.toString(),
            title: item.nome,
          });
        });
        this.setState({
          polos: result.data.polos.sort(this.sorter),
          poloId: result?.data?.polos?.[0]?.id,
          transferData: transferData,
        });
        this.setState({ loading: false });
        this.getPolos();
        this.changePolo(this.state.targetKeys);

      });

    this.render();
  };

  currencyFormatter = (value) => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  dataVisita = (data) => {
    this.state.focaCheckin.dataVisita = data.format('DD-MM-YYYY');
    let dataAtual = new Date();
    let dataVisita = new Date(data);
    if (dataAtual >= dataVisita) {
      this.state.isRequired = true;
      this.render();
    } else {
      this.state.isRequired = false;
      this.render();
    }
  };

  changeMoney = (val) => {
    if (val == undefined) return;
    const isNumber = typeof val === 'number';
    if (isNumber) val = val * 100;
    var campo = val.toString();
    campo = campo.replace(/\D/g, '');
    const valor = parseInt(campo) / 100;
    return valor;
  };

  currencyString = (val) => {
    if (val == undefined) return;
    const isNumber = typeof val === 'number';
    if (isNumber) val = val * 100;
    var campo = val.toString();
    campo = campo.replace(/\D/g, '');
    const valor = parseInt(campo) / 100;
    campo =
      campo.length > 8
        ? campo.replace(/(\d)(\d{3})(\d{5})$/, '$1.$2.$3')
        : campo.replace(/(\d)(\d{5})$/, '$1.$2');
    campo = campo.replace(/(\d)/, 'R$ $1');
    campo = campo.replace(/(\d)(\d{2})$/, '$1,$2');
    return campo;
  };

  currencyParser = (val, type) => {
    if (val == undefined) return;
    const isNumber = typeof val === 'number';
    if (isNumber) val = Math.floor(val * 100);
    var campo = val.toString();
    campo = campo.replace(/\D/g, '');
    const valor = parseInt(campo) / 100;
    campo =
      campo.length > 8
        ? campo.replace(/(\d)(\d{3})(\d{5})$/, '$1.$2.$3')
        : campo.replace(/(\d)(\d{5})$/, '$1.$2');
    campo = campo.replace(/(\d)/, 'R$ $1');
    campo = campo.replace(/(\d)(\d{2})$/, '$1,$2');
    if (type == 'ganhoEstimado') {
      this.plano.ganhoEstimado = valor;
      if (this.state.index != null) {
        if (document.getElementById(`ganhoEstimado`) !== null) {
          document.getElementById(`ganhoEstimado`).value = campo;
        }
        return campo;
      } else {
        return campo;
      }
    } else if (type == 'ganhoRealizado') {
      this.plano.ganhoRealizado = valor;
      if (this.state.index != null) {
        if (document.getElementById(`ganhoRealizado`) !== null) {
          document.getElementById(`ganhoRealizado`).value = campo;
        }
        return campo;
      } else {
        return campo;
      }
    } else {
      document.getElementById('ganho').value = campo;
      this.state.focaCheckin.ganho = valor;
      return campo;
    }
  };

  sorter = (a, b) => {
    if (a.nome > b.nome) return 1;
    if (a.nome < b.nome) return -1;
    return 0;
  };

  newLine = () => {
    const date =
      this.state?.modal?.prazo != undefined
        ? moment(this.state.modal.prazo, 'DD-MM-YYYY')
        : moment(this.state.focaCheckin.dataVisita, 'DD-MM-YYYY');

    this.plano.prazo = date.format('DD-MM-YYYY');
    this.novoPlano.prazo = date.format('DD-MM-YYYY');
    this.novoPlano.ativo = 'S';
    this.plano.ativo = 'S';

    const { count, lista, index } = this.state;
    if (count > 0) {
      //planosDeAcao.push({})
    }
    this.novoPlano.ganhoEstimado = '';
    this.setState({
      lista: [...lista, this.novoPlano],
      count: count + 1,
    });
    this.handleModal(planosDeAcao.length, this.novoPlano);
    this.render();
  };

  export = async () => {
    await exportXLSX(this.state.search, `FOCA_PLANODEACAO_${this.location}`)
  }

  exportCSV = async () => {
    let relatorioConfig = {
      relatorioId: 28,
      lstParametros: [
        {
          id: 13,
          nome: 'Checkin id',
          valor: this.state.focaCheckin.id,
        },
      ],
    };
    try {
      const resposta = await api.post('relatorio/exportCsv', relatorioConfig);
      Swal.fire({
        position: 'top-end',
        title: `Sucesso!`,
        text: 'Relatório gerado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
      const BOM = '\uFEFF';

      let conteudoAux = resposta.data.replace(/\n/gm, '');
      let conteudo = conteudoAux.replaceAll(/[\\"]/g, '');
      const relatorioDados = BOM + conteudo.replace(/,/g, ' ');
      const contentType = resposta.headers['content-type'];
      const filename = resposta.headers['content-disposition']
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim()
        .replace('"', '')
        .replace('"', '');
      const blob = new Blob([relatorioDados], {
        type: 'text/csv',
      });
      saveAs(blob, filename);
    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi possivel realizar operação',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  removeLine = (index) => {
    if (planosDeAcao[index] != undefined) {
      delete planosDeAcao[index];
      planosDeAcao.length = planosDeAcao.length - 1;
    }
    let count = 0;
    let list = [];
    delete this.state.lista[index];
    this.render();
    this.setState({
      lista: this.state.lista,
    });
    this.state.lista.forEach(function (item) {
      if (index != count) {
        list.push(item);
      }
      count += 1;
    });
    this.setState({
      lista: list,
      count: this.state.count - 1,
    });
    this.render();
  };

  insertPlano = (index, reacord) => {
    planosDeAcao[index] = reacord.id > 0 ? reacord : this.plano;
    this.plano = {};
    this.render();
  };

  changeByField = (value, field, index, reacord) => {
    if (planosDeAcao[index] == undefined) {
      planosDeAcao.push({});
    }
    index = planosDeAcao.length - 1;
    if (reacord.id == undefined && planosDeAcao[index].prazo == undefined) {
      planosDeAcao[index].prazo = this.plano.prazo;
    }
    switch (field) {
      case 'foca':
        if (reacord.id > 0) {
          reacord.foca = value;
        } else {
          planosDeAcao[index].foca = value;
          planosDeAcao[index].ativo = 'S';
        }
        break;
      case 'pilar':
        if (reacord.id > 0) {
          reacord.pilar = value;
        } else {
          planosDeAcao[index].pilar = value;
        }
        break;
      case 'acao':
        if (reacord.id > 0) {
          reacord.acao = value;
        } else {
          planosDeAcao[index].acao = value;
        }
        break;
      case 'responsavel':
        if (reacord.id > 0) {
          reacord.responsavel = value;
        } else {
          planosDeAcao[index].responsavel = value;
        }
        break;
      case 'prazo':
        if (reacord.id > 0) {
          reacord.prazo = value;
        } else {
          planosDeAcao[index].prazo = value;
        }
        break;
      case 'dataConclusao':
        if (reacord.id > 0) {
          reacord.dataConclusao = value;
        } else {
          planosDeAcao[index].dataConclusao = value;
        }
        break;
      case 'resultadoAtingido':
        if (reacord.id > 0) {
          reacord.resultadoAtingido = value;
        } else {
          planosDeAcao[index].resultadoAtingido = value;
        }
        break;
      case 'status':
        if (reacord.id > 0) {
          reacord.status = value;
        } else {
          planosDeAcao[index].status = value;
        }
        break;
      case 'ganhoEstimado':
        this.state.index = index;
        this.currencyParser(value, 'ganhoEstimado');
        let valor = this.changeMoney(value);
        if (reacord.id > 0) {
          reacord.ganhoEstimado = valor;
        } else {
          planosDeAcao[index].ganhoEstimado = valor;
          return;
        }
        reacord.ganhoEstimado = valor;
        break;
      case 'ganhoRealizado':
        this.state.index = index;
        this.currencyParser(value, 'ganhoRealizado');
        let price = this.changeMoney(value);
        if (reacord.id > 0) {
          reacord.ganhoRealizado = price;
        } else {
          planosDeAcao[index].ganhoRealizado = price;
          return;
        }
        reacord.ganhoRealizado = price;
        break;
        case 'modalidade':
          if (reacord.id > 0) {
            reacord.modalidade = value;
          } else {
            planosDeAcao[index].modalidade = value;
          }
          break;
          case 'link':
            if (reacord.id > 0) {
              reacord.link = value;
            } else {
              planosDeAcao[index].link = value;
            }
            break;
      default:
        break;
    }
    this.render();
  };

  renderOption = (item) => {
    return (
      <Option key={item.id} text={item.nome}>
        {item['nome'] ? item.nome : item.nome}
      </Option>
    );
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.onSearchUsers(nextTargetKeys)
    this.setState({
      targetKeys: nextTargetKeys,
      lstPolos: nextTargetKeys,
    });
    this.changePolo(nextTargetKeys);
    this.render();
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  onSearchUsers = async (listPolosIDs) => {
    let polosIds = [];
    listPolosIDs.forEach(item => {
      polosIds.push(parseInt(item))
    })
    const result = await getUsersGestor(listPolosIDs)
    this.setState({ user: result.data.usuariosGestoresCordenadoresPorPolo })
    this.changePolo(listPolosIDs)
    this.render();
  }

  renderUser = (item) => {
    return (
      <Option key={item.usuarioPortalId} text={item.nome}>
        {item['nome'] ? item.nome + ' - ' + item.descricaoPerfil : item.nome}
      </Option>
    );
  };

  cancelModal = () => {
    this.handleModal();
    this.render();
  };

  handleModal = (index, record) => {
    if (this.state.isOpen == true) {
      this.setState({ isOpen: false, index: 0 });
    } else {
      this.setState({
        isOpen: true,
        modal: planosDeAcao[index],
        index: index,
        record: record,
      });
    }
    this.render();
  };

  handleModalSearch = () => {
    if (this.state.isOpenSearch == true) {
      this.setState({ isOpenSearch: false });
    } else {
      this.setState({
        isOpenSearch: true,
      });
    }
    this.render();
  };

  getActionById = (record) => {
    let index = 0;
    this.state.lista.forEach((item) => {
      if (item.id === record.id) {
        this.handleModal(index, record);
      }
      index++;
    });
  };

  handleModal = (index, record) => {
    if (this.state.isOpen == true) {
      this.setState({ isOpen: false, index: 0 });
    } else {
      this.setState({
        isOpen: true,
        modal: planosDeAcao[index],
        index: index,
        record: record,
      });
      if(planosDeAcao[index] == undefined) return
      this.countRestString(planosDeAcao[index].acao, "acao");
      this.countRestString(planosDeAcao[index].resultadoAtingido, "resultadoAtingido");
    }

    if(this.state.readOnly) {
      let list = []
      this.state.lista.forEach(item => {
        if(item.id != undefined) {
          list.push(item)
        }
      })
      this.setState({lista: list})
    }

    this.render();
  };

  changePolo = (listId) => {
    if (listId === undefined || this.state.user === undefined) return;
    let lstUser = this.state.user;
    let list = [];
    for (let i = 0; i < listId.length; i++) {
      if (lstUser[listId[i]] != undefined) {
        lstUser[listId[i]].forEach(function (item) {
          list.push(item);
        });
      }
    }

    list = this.removeDuplicates(list);
    this.lstUsers = list.sort(this.orderByName);
    this.render();
  };

  removeDuplicates = (list) => {
    for (let i = 0; i < list.length; ++i)
      for (let j = 0; j < list.length; ++j)
        if (i !== j && list[i]?.usuarioPortalId === list[j]?.usuarioPortalId)
          list.splice(j, 1);
    return list;
  };

  hasErrors = (fieldsError) => {
    // removendo campos de plano de ação para validação.
    delete fieldsError.acao;
    delete fieldsError.foca;
    delete fieldsError.ganhoEstimado;
    delete fieldsError.pilar;
    delete fieldsError.responsavel;
    delete fieldsError.statusFOCA;
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  validField = () => {
    // valida os campos de checkin
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (
        err === null ||
        err.polo === undefined ||
        (err.dataVisita === undefined &&
          err.tipoVisita === undefined &&
          err.ritual === undefined &&
          err.status === undefined &&
          err.participantes === undefined &&
          err.nome === undefined &&
          err.tempoVisita === undefined) ||
        err.resumo === undefined
      ) {
        this.save();
      }
    });
  };

  validFieldAction = () => {
    // valida apenas os campos do plano de ação
    if(this.state.readOnly){
      this.state.lista[this.state.index].status = 'G'
      this.state.lista[this.state.index].validacaoPolo = 'S'
    }
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (
        err === null ||
        (err.foca === undefined &&
          err.pilar === undefined &&
          err.prazo === undefined &&
          err.acao === undefined &&
          err.ganhoEstimado === undefined &&
          err.responsavel === undefined &&
          err.statusFOCA === undefined)

      ) {
        this.saveAcao();
      } else if (this.state.modal === undefined) {
        this.render();
        this.removeLine(this.state.count);
        this.setState({ isOpen: false });
      }
    });
  };

  save = () => {
    let listPlans = [];
    planosDeAcao.forEach(item => {
      if(item.foca != undefined) {
        listPlans.push(item)
      }
    })
    this.state.focaCheckin.planosDeAcao = listPlans;
    this.state.focaCheckin.ativo = 'S';
    let obj = {};
    obj = this.state.focaCheckin;
    obj.polosIds = this.state.lstPolos;
    this.state.focaCheckin.idUsuarioLogado = this.user[0].usuarioPortal.id;
    this.setState({ loading: true });
    if (!this.state.isEdit) {
      axios
        .post(`${this.url.baseUrl}foca/`, obj, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then((result) => {
          Swal.fire({
            position: 'top-end',
            title: `Salvo com sucesso!`,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })
          window.setTimeout(function(){ window.location.replace(`/cadastro/FOCA/${result.data.id}`) }, 2500);
        })
        .catch((error) => {
          this.setState({ loading: false });
          if (
            error.response.data &&
            error.response.data.message[0] ==
              'Erro na Constraint: PORTALPOLOS.FOCA_CHECKIN_UK_01'
          ) {
            Swal.fire({
              position: 'top-end',
              title: `Ja existe um Checkin cadastrado para este polo com esta data, favor verificar`,
              icon: 'error',
              showConfirmButton: false,
              timer: 3000,
            });
          }
        });
    } else {
      obj.usuarioAlteracao = this.user[0].usuarioPortal.id;

      axios
        .put(`${this.url.baseUrl}foca/${this.state.focaCheckin.id}`, obj, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        })
        .then((result) => {
          this.setState({ loading: false });
          Swal.fire({
            position: 'top-end',
            title: `Salvo com sucesso!`,
            icon: 'success',
            showConfirmButton: false,
            timer: 3000,
          })
          window.setTimeout(function(){ window.location.reload()}, 2500);
        });
    }
  };

  orderByPolo = (a, b) => {
    if (a.title > b.title) return 1;
    if (a.title < b.title) return -1;
    return 0;
  };

  orderByName = (a, b) => {
    if (a.nome > b.nome) return 1;
    if (a.nome < b.nome) return -1;
    return 0;
  };

  disabledEndDate = (prazo) => {
    const { dataVisita } = this.state.focaCheckin;
    let date = '';
    if(dataVisita._d == undefined) {
      const dateSplit = dataVisita.split('-');
      date = new Date(`${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`);
    } else {
      date = moment(dataVisita, "DD/MM/YYYY")
    }

    if (!prazo || !date) {
      return false;
    }
    return prazo.valueOf() < date.valueOf();
  };

  countRestString = (value, field) => {
    let rest = 0
    if(value == undefined) return
    switch (field) {
      case 'resumo':
        this.state.focaCheckin.resumo = value;
        rest = 600 - value.length
        this.setState({ countRestResume: rest });
        this.render();
        break;
      case 'participantes':
        this.state.focaCheckin.participantes = value;
        rest = 600 - value.length
        this.setState({ countRestParticipants: rest });
        this.render();
        break;
      case 'acao':
        this.changeByField(
          value,
          'acao',
          this.state.index,
          this.state.record
        )
        rest = 600 - value.length
        this.setState({ countRestAction: rest });
        this.render();
        break
      case 'resultadoAtingido':
        this.changeByField(
          value,
          'resultadoAtingido',
          this.state.index,
          this.state.record
        )
        rest = 600 - value.length
        this.setState({ countRestResult: rest });
        this.render();
        break
      default:
        break;
    }

  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { targetKeys, selectedKeys, disabled } = this.state;
    let responsive = window.innerWidth < 1250 ? true : false;
    if (this.lstUsers.length > 0 && this.state.isUpdate) {
      this.setState({ isUpdate: false });
    }
    return (
      <>
        <Helmet
          title={this.titlePage}
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content: this.titlePage + '- Portal Prisma',
            },
            { name: 'keywords', content: 'kroton, educacional, portal, teste' },
          ]}
          key="titleEstruturaUsuario"
        />
        <Header key="headerEstruturaUsuario" />
        <Hero
          key="heroEstruturaUsuario"
          pageName={this.state?.isEdit ? 'Editar check-in' : 'Novo check-in'}
          titulo={this.state?.isEdit ? 'Editar check-in' : 'Novo check-in'}
          email={this.state?.userOwner?.conta}
        />

        <section
          className="section conteudoPortais foca-display"
          key="conteudoFoca"
        >
          <Spin spinning={this.state.loading}>
            <div className="width-100">
              <Row xl={24}>
                <Col xl={15} lg={24} className="gutter-row">
                  <Form.Item size="large">
                    {getFieldDecorator('polo', {
                      initialValue:
                        this.state.focaCheckin &&
                        this.state.focaCheckin.polo &&
                        this.state.focaCheckin.polo.nome,
                      rules: [
                        {
                          required: targetKeys != undefined ? false : true,
                          message: 'Por Favor inserir o polo',
                        },
                      ],
                    })(
                      <>
                        <strong>Selecione o(s) polo(s) desejado(s):</strong>
                        <strong className="margin-160-left">
                          Polo(s) vinculado(s):
                        </strong>
                        <Transfer
                          dataSource={this.state.transferData.sort(
                            this.orderByPolo
                          )}
                          style={{ width: '100%' }}
                          showSearch
                          titles={['Source', 'Target']}
                          targetKeys={targetKeys}
                          selectedKeys={selectedKeys}
                          onChange={this.handleChange}
                          onSelectChange={this.handleSelectChange}
                          render={(item) => item.title}
                          disabled={this.state.readOnly}
                        />
                      </>
                    )}
                  </Form.Item>
                </Col>
                <div className="ant-col-xl-9 ant-col-lg-24">
                  <Row>
                    <Col xl={11} lg={5}>
                      <Form.Item label="Data visita" size="large">
                        {getFieldDecorator('dataVisita', {
                          initialValue:
                            this?.state?.focaCheckin?.dataVisita &&
                            moment(
                              this.state.focaCheckin.dataVisita,
                              'DD-MM-YYYY'
                            ),
                          rules: [
                            {
                              required: true,
                              message: 'Por Favor inserir a data da visita',
                            },
                          ],
                        })(
                          <DatePicker
                            format="DD-MM-YYYY"
                            className="width-100"
                            placeholder="DD-MM-YYYY"
                            allowClear={false}
                            locale={locale}
                            disabled={this.state.isEdit}
                            size="large"
                            onChange={(e) => this.dataVisita(e)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xl={11} lg={5} className="margin-1-left">
                      <Form.Item label="Tipo" size="large">
                        {getFieldDecorator('tipoVisita', {
                          initialValue:
                            this.state.focaCheckin &&
                            this.state.focaCheckin.tipoVisita,
                          rules: [
                            {
                              required: true,
                              message: 'Por Favor selecione o tipo da visita',
                            },
                          ],
                        })(
                          <Select
                            placeholder="Selecione..."
                            size="large"
                            onChange={(e) =>
                              (this.state.focaCheckin.tipoVisita = e)
                            }
                            disabled={this.state.readOnly}
                          >
                            <Option value="P">Presencial</Option>
                            <Option value="O">Online</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={11} lg={5} className="margin-1-left-resp ">
                      <Form.Item label="Ritual" size="large">
                        {getFieldDecorator('ritual', {
                          initialValue:
                            this.state.focaCheckin &&
                            this.state.focaCheckin.ritual,
                          rules: [
                            {
                              required: true,
                              message: 'Por Favor selecione o ritual',
                            },
                          ],
                        })(
                          <Select
                            placeholder="Selecione..."
                            size="large"
                            onChange={(e) =>
                              (this.state.focaCheckin.ritual = e)
                            }
                            disabled={this.state.readOnly}
                          >
                            <Option value="RI">Radar de Indicadores</Option>
                            <Option value="RM">Reunião de Monitoramento</Option>
                            <Option value="VT">Visita Técnica</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xl={11} lg={5} className="margin-1-left">
                      <Form.Item label="Status" size="large">
                        {getFieldDecorator('status', {
                          initialValue:
                            this.state.focaCheckin &&
                            this.state.focaCheckin.status,
                          rules: [
                            {
                              required: true,
                              message: 'Por Favor selecione o status',
                            },
                          ],
                        })(
                          <Select
                            placeholder="Selecione..."
                            size="large"
                            onChange={(e) =>
                              (this.state.focaCheckin.status = e)
                            }
                            disabled={this.state.readOnly}
                          >
                            <Option value="A">Agendado</Option>
                            <Option value="E">Em acompanhamento</Option>
                            <Option value="C">Concluido</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row xl={24}>
                <Col xl={9} lg={4} className="gutter-row">
                  <Form.Item label="Responsável" size="large">
                    {getFieldDecorator('nome', {
                      initialValue:
                        (this.state.focaCheckin &&
                          this.state.focaCheckin.usuarioPortal &&
                          this.state.focaCheckin.usuarioPortal.pessoa &&
                          this.state.focaCheckin.usuarioPortal.pessoa.nome) ||
                        this.state.lstUsers,

                      rules:
                        this.state.isRequired == true
                          ? [
                              {
                                required: true,
                                message: 'Por Favor inserir o nome',
                              },
                            ]
                          : '',
                    })(
                      <Select
                        size="large"
                        placeholder="Pesquise o Nome"
                        onChange={(e) =>
                          (this.state.focaCheckin.usuarioPortal = {
                            id: parseInt(e),
                          })
                        }
                        disabled={this.state.readOnly}
                      >
                        {this.lstUsers.map((e) => this.renderUser(e))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={5} lg={5} className="gutter-row margin-1-left">
                  <Form.Item label="Quer ganhar quanto?" size="large">
                    {getFieldDecorator('ganho', {
                      initialValue:
                        this.state.focaCheckin &&
                        this.currencyParser(
                          this.state.focaCheckin.ganho,
                          'ganho'
                        ),
                      rules:
                        this.state.isRequired == true
                          ? [
                              {
                                required: true,
                                message: 'Por Favor inserir o valor',
                              },
                            ]
                          : '',
                    })(
                      <Input
                        type="text"
                        size="large"
                        className="ant-input height-40"
                        formatter={(e) => this.currencyFormatter(e)}
                        onChange={(e) =>
                          this.currencyParser(e.target.value, 'ganho')
                        }
                        disabled={this.state.readOnly}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xl={5} lg={5} className="gutter-row margin-1-left">
                  <Form.Item
                    label="Liberar PDF ao Gestor do Polo?"
                    size="large"
                  >
                    {getFieldDecorator('liberarPdf', {
                      initialValue: this.state.focaCheckin.liberarPdf,
                    })(
                      <Select
                        placeholder="Selecione..."
                        size="large"
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          (this.state.focaCheckin.liberarPdf = e)
                        }
                        disabled={this.state.readOnly}
                      >
                        <Option value="S">Sim</Option>
                        <Option value="N">Não</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={4} lg={5} className="gutter-row margin-1-left">
                  <Form.Item label="Tempo de visita" size="large">
                    {getFieldDecorator('tempoVisita', {
                      initialValue:
                        this.state.focaCheckin &&
                        this.state.focaCheckin.tempoVisita != undefined
                          ? moment(this.state.focaCheckin.tempoVisita, 'HH:mm')
                          : moment('00:00', 'HH:mm'),
                      rules:
                        this.state.isRequired == true
                          ? [
                              {
                                required: true,
                                message: 'Por Favor inserir o valor',
                              },
                            ]
                          : '',
                    })(
                      <TimePicker
                        placeholder="Tempo de visita"
                        format={'HH:mm'}
                        size="large"
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          (this.state.focaCheckin.tempoVisita = e.format(
                            'HH:mm'
                          ))
                        }
                        disabled={this.state.readOnly}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Participantes" size="large">
                    {getFieldDecorator('participantes', {
                      initialValue:
                        this.state.focaCheckin &&
                        this.state.focaCheckin.participantes,
                      rules:
                        this.state.isRequired == true
                          ? [
                              {
                                required: true,
                                message: 'Por Favor inserir os participantes',
                              },
                            ]
                          : '',
                    })(
                      <TextArea
                        maxLength={600}
                        className="size-text-area"
                        autoSize={{ minRows: 8, maxRows: 50 }}
                        onChange={(e) => this.countRestString(e.target.value, "participantes")}
                        disabled={this.state.readOnly}
                      />
                    )}
                    <span>Restam: {this.state.countRestParticipants} caracteres</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Resumo" size="large">
                    {getFieldDecorator('resumo', {
                      initialValue:
                        this.state.focaCheckin && this.state.focaCheckin.resumo,
                      rules:
                        this.state.isRequired == true
                          ? [
                              {
                                required: true,
                                message: 'Por Favor inserir o resumo',
                              },
                            ]
                          : '',
                    })(
                      <TextArea
                        maxLength={600}
                        autoSize={{ minRows: 8, maxRows: 50 }}
                        onChange={(e) => this.countRestString(e.target.value, "resumo")}
                        disabled={this.state.readOnly}
                      />
                    )}
                    <span>Restam: {this.state.countRestResume} caracteres</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="margin-1-top">
                <span className="title-foca-info">
                  <strong>Planos de Ação</strong>
                </span>
                <Button
                  title="Adicionar Ação"
                  type="primary"
                  size="large"
                  className="pull-right margin-05-left"
                  onClick={this.newLine}
                  disabled={targetKeys == undefined || this.state.readOnly}
                >
                  Adicionar Ação
                </Button>
                {this.state.isEdit ? (
                  <Button
                    title="Exportar .XLSX"
                    type="primary"
                    style={{
                      backgroundColor: '#52c41a',
                      border: 'none',
                    }}
                    size="large"
                    className="pull-right "
                    onClick={this.export}
                    disabled={this.state.readOnly}
                  >
                    Exportar .XLSX
                  </Button>
                ) : (
                  ''
                )}

                <Button
                  title="Pesquisa por ação"
                  type="primary"
                  size="large"
                  className="pull-right margin-05-right"
                  onClick={this.handleModalSearch}
                >
                  Pesquisa por ação
                </Button>
              </Row>
              <Table
                className="margin-1-top"
                columns={this.state.columns}
                dataSource={this.state.lista}
                rowKey="key"
                expandedRowKeys={this.state.expandedRowKeys}
                pagination={false}
                disabled={this.state.readOnly}
              />
              <Row>
                <Button
                  title="Salvar"
                  type="primary"
                  style={{
                    backgroundColor: '#52c41a',
                    border: 'none',
                  }}
                  size="large"
                  className="pull-right margin-1-top"
                  disabled={this.hasErrors(getFieldsError()) || this.state.readOnlyWithoutWallet}
                  onClick={() => this.validField()}
                >
                  Salvar
                </Button>
                <Button
                  title="Salvar"
                  type="primary"
                  style={{
                    border: 'none',
                  }}
                  size="large"
                  className=" margin-1-top"
                  href='/FOCA'
                >
                  Voltar
                </Button>
              </Row>
            </div>
          </Spin>
        </section>
        <Footer key="FooterEstruturaUsuario" />
        <Modal
          title="PLANO DE AÇÃO"
          visible={this.state.isOpen}
          onOk={this.validFieldAction}
          onCancel={this.cancelModal}
          width={1000}
          destroyOnClose={true}
          className="foca-display"
        >
          <Row span={24}>
            <Col span={2}>
              <Form.Item label="ID" size="large">
                {this.props.form.getFieldDecorator(`id`, {
                  initialValue: this.state.modal && this.state.modal.id,
                })(
                  <Input
                    style={{ width: '70%', height: '33px !important' }}
                    disabled
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="FOCA" size="large">
                {this.props.form.getFieldDecorator(`foca`, {
                  initialValue: this.state.modal && this.state.modal.foca,
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor selecione uma opção',
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecione..."
                    style={{ width: '90%' }}
                    onChange={(e) =>
                      this.changeByField(
                        e,
                        'foca',
                        this.state.index,
                        this.state.record
                      )
                    }
                    disabled={this.state.readOnly}
                  >
                    <Option key="F" value="F">
                      Financeiro
                    </Option>
                    <Option key="O" value="O">
                      Operacional
                    </Option>
                    <Option key="C" value="C">
                      Comercial
                    </Option>
                    <Option key="A" value="A">
                      Acadêmico
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="PILAR" size="large">
                {this.props.form.getFieldDecorator(`pilar`, {
                  initialValue: this.state.modal && this.state.modal.pilar,
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor selecione uma opção',
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecione..."
                    style={{ width: '90%' }}
                    onChange={(e) =>
                      this.changeByField(
                        e,
                        'pilar',
                        this.state.index,
                        this.state.record
                      )
                    }
                    disabled={this.state.readOnly}
                  >
                    <Option key="V" value="V">
                      Volume
                    </Option>
                    <Option key="I" value="I">
                      Inadimplência
                    </Option>
                    <Option key="R" value="R">
                      Retenção
                    </Option>
                    <Option key="E" value="E">
                      Estrutura Padrão
                    </Option>
                    <Option key="L" value="L">
                      LTR
                    </Option>
                    <Option key="O" value="O">
                      Outros
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item size="large" label="MODALIDADE">
                {this.props.form.getFieldDecorator(`modalidade`, {
                  initialValue: this.state.modal && this.state.modal.modalidade,
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor selecionar uma opção',
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecione..."
                    style={{ width: '90%' }}
                    onChange={(e) =>
                      this.changeByField(
                        e,
                        'modalidade',
                        this.state.index,
                        this.state.record
                      )
                    }
                    disabled={this.state.readOnly}
                  >
                    <Option value="Graduação">Graduação</Option>
                    <Option value="Pós Graduação">Pós Graduação</Option>
                    <Option value="EJA">EJA</Option>
                    <Option value="Cursos Técnicos">Cursos Técnicos</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item size="large" label="STATUS">
                {this.props.form.getFieldDecorator(`statusFOCA`, {
                  initialValue: this.state.modal && this.state.modal.status,
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor selecionar uma opção',
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecione..."
                    className="width-100"
                    onChange={(e) =>
                      this.changeByField(
                        e,
                        'status',
                        this.state.index,
                        this.state.record
                      )
                    }
                    disabled={this.state.readOnly}
                  >
                    <Option value="A">Em acompanhamento</Option>
                    <Option value="C">Concluido</Option>
                    <Option value="G">Concluido pelo Gestor</Option>
                    <Option value="N">Não Concluido</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item size="large" label="GANHO ESTIMADO">
                {this.props.form.getFieldDecorator(`ganhoEstimado`, {
                  initialValue: this.currencyParser(
                    this.state.modal && this.state.modal.ganhoEstimado,
                    'ganhoEstimado'
                  ),
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor inserir o ganho estimado',
                    },
                  ],
                })(
                  <Input
                    type="text"
                    placeholder="Ganho estimado"
                    className="ant-input height-40"
                    maxLength={14}
                    disabled={
                      this.state.modal && this.state.modal.id > 0 ? true : false
                    }
                    formatter={(e) => this.currencyFormatter(e)}
                    style={{ width: '95%' }}
                    onChange={(e) =>
                      this.changeByField(
                        e.target.value,
                        'ganhoEstimado',
                        this.state.index,
                        this.state.record
                      )
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="PRAZO" size="large">
                {this.props.form.getFieldDecorator(`prazo`, {
                  initialValue:
                    this.state.modal && this.state.modal.prazo != undefined
                      ? moment(this.state.modal.prazo, 'DD-MM-YYYY')
                      : moment(this.state.focaCheckin.dataVisita, 'DD-MM-YYYY'),
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor inserir o prazo',
                    },
                  ],
                })(
                  <DatePicker
                    disabledDate={this.disabledEndDate}
                    format="DD-MM-YYYY"
                    allowClear={false}
                    placeholder="DD-MM-YYYY"
                    locale={locale}
                    style={{ width: '90%' }}
                    onChange={(e) =>
                      this.changeByField(
                        e.format('DD-MM-YYYY'),
                        'prazo',
                        this.state.index,
                        this.state.record
                      )
                    }
                    disabled={this.state.readOnly}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RESPONSÝVEL" size="large">
                {this.props.form.getFieldDecorator(`responsavel`, {
                  initialValue:
                    this.state.modal && this.state.modal.responsavel,
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor inserir o responsavel',
                    },
                  ],
                })(
                  <Input
                    type="text"
                    placeholder="Digite o nome do responsavel"
                    onChange={(e) =>
                      this.changeByField(
                        e.target.value,
                        'responsavel',
                        this.state.index,
                        this.state.record
                      )
                    }
                    disabled={this.state.readOnly}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                  size="large"
                  label="LINK"
                  className="large"
                >
                  {this.props.form.getFieldDecorator(`link`, {
                  initialValue:
                    this.state.modal && this.state.modal.link,
                })(
                    <Input
                      type="text"
                      placeholder="Caso seu Plano de Ação possua links (treinamentos, cursos, comunicados) utilize esse campo"
                      onChange={(e) =>
                        this.changeByField(
                          e.target.value,
                          'link',
                          this.state.index,
                          this.state.record
                        )
                      }
                      className="ant-input height-40"
                      style={{ width: '100%' }}
                      disabled={this.state.readOnly}
                    />
                    )}
                </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="AÇÃO" size="large" className="size-text-area">
                {this.props.form.getFieldDecorator(`acao`, {
                  initialValue: this.state.modal && this.state.modal.acao,
                  rules: [
                    {
                      required: true,
                      message: 'Por Favor inserir a ação',
                    },
                  ],
                })(
                  <TextArea
                    type="text"
                    maxLength={600}
                    placeholder="Digite a Ação"
                    onChange={(e) => this.countRestString(e.target.value, "acao")}
                    disabled={this.state.readOnly}
                  />
                )}
                <span>Restam: {this.state.countRestAction} caracteres</span>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                size="large"
                label="RESULTADO"
                className="size-text-area"
              >
                {this.props.form.getFieldDecorator(`resultadoAtingido`, {
                  initialValue:
                    this.state.modal && this.state.modal.resultadoAtingido,
                    rules: [
                      {
                        required: this.state.readOnly,
                      },
                    ],
                })(
                  <TextArea
                    type="text"
                    maxLength={600}
                    placeholder="Digite o Resultado"
                    onChange={(e) => this.countRestString(e.target.value, "resultadoAtingido")}
                    disabled={this.state.readOnlyWithoutWallet}
                  />
                )}
                <span>Restam: {this.state.countRestResult} caracteres</span>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <Form.Item size="large" label="CONCLUSÃO">
                {this.props.form.getFieldDecorator(`dataConclusao`, {
                  initialValue:
                    this.state.modal &&
                    this.state.modal.dataConclusao != undefined
                      ? moment(this.state.modal.dataConclusao, 'DD-MM-YYYY')
                      : '',
                      rules: [
                        {
                          required: this.state.readOnly,
                        },
                      ],
                })(
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="DD-MM-YYYY"
                    allowClear={false}
                    locale={locale}
                    style={{ width: '90%' }}
                    disabled={this.state.readOnlyWithoutWallet}
                    onChange={(e) =>
                      this.changeByField(
                        e.format('DD-MM-YYYY'),
                        'dataConclusao',
                        this.state.index,
                        this.state.record
                      )
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5} className="float-right">
              <Form.Item size="large" label="GANHO REALIZADO">
                {this.props.form.getFieldDecorator(`ganhoRealizado`, {
                  initialValue: this.currencyParser(
                    this.state.modal && this.state.modal.ganhoRealizado,
                    'ganhoRealizado'
                  ),
                  rules: [
                    {
                      required: this.state.readOnly,
                    },
                  ],
                })(
                  <Input
                    type="text"
                    placeholder="Ganho realizado"
                    className="ant-input height-40"
                    disabled={this.state.readOnlyWithoutWallet}
                    formatter={(e) => this.currencyFormatter(e)}
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      this.changeByField(
                        e.target.value,
                        'ganhoRealizado',
                        this.state.index,
                        this.state.record
                      )
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
        <Modal
          title="PESQUISA DE PLANO DE AÇÃO"
          visible={this.state.isOpenSearch}
          onOk={this.handleModalSearch}
          onCancel={this.handleModalSearch}
          width={1000}
          destroyOnClose={true}
          className="foca-display"
        >
          <Row gutter={24}>
            <Col span={20}>
              <Form.Item label="Pesquisar por ação" size="large">
                <Input
                  type="text"
                  placeholder="Digite a ação"
                  onChange={(e) =>
                    this.setState({ serchAction: e.target.value })
                  }
                  disabled={this.state.readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                style={{ marginTop: '2.6rem' }}
                type="primary"
                onClick={() => this.filterActions()}
                disabled={this.state.readOnly}
              >
                <FontAwesomeIcon icon={['fas', 'search']} /> Pesquisar
              </Button>
            </Col>
          </Row>
          <Table
            className="margin-1-top"
            columns={this.state.columnsSearch}
            dataSource={this.state.search}
            rowKey="key"
            expandedRowKeys={this.state.expandedRowKeys}
            pagination={false}
          />
        </Modal>
      </>
    );
  }
}

export default Form.create()(Checkin);
