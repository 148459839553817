export const USUARIO_SUCCESS = 'USUARIO_SUCCESS';
export const USUARIO_CHANGE_PESSOA = 'USUARIO_CHANGE_PESSOA';
export const USUARIO_SITUACAO_USUARIO_CHANGE =
  'USUARIO_SITUACAO_USUARIO_CHANGE';
export const USUARIO_ERROR = 'USUARIO_ERROR';
export const USUARIO_ALERTA = 'USUARIO_ALERTA';
export const USUARIO_SAVE = 'USUARIO_SAVE';
export const USUARIO_EDITA = 'USUARIO_EDITA';
export const USUARIO_CHANGE = 'USUARIO_CHANGE';

export const USUARIO_LIST_POLO_COMMAND_CHANGE =
  'USUARIO_LIST_POLO_COMMAND_CHANGE';
export const USUARIO_LIST_POLO_COMMAND_ADD = 'USUARIO_LIST_POLO_COMMAND_ADD';
export const USUARIO_LIST_POLO_COMMAND_REMOVE =
  'USUARIO_LIST_POLO_COMMAND_REMOVE';

export const USUARIO_PORTAL_CHANGE = 'USUARIO_PORTAL_CHANGE';
export const USUARIO_LIST_POLO_COMMAND_CLEAN =
  'USUARIO_LIST_POLO_COMMAND_CLEAN';
