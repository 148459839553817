import React, { useEffect } from 'react';
import {
  HeaderAdmin,
  FooterAdmin,
  Hero,
  Helmet,
  MenuAdmin,
} from '../../../services/Gerais';
import { Table, Modal, Form, Input, Select, Row, Col, Button } from 'antd';
import { useAccessProfiles } from './hook';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const { Option } = Select;

const AccessProfiles = () => {
  const {
    columns,
    dataSource,
    isLoading,
    isActive,
    perfilAux,
    getProfiles,
    handleModal,
    handleSaveProfile,
    handleGetProfileById,
    handleUpdateProfile,
  } = useAccessProfiles();
  const [perfil, setPerfil] = useState({
    usuarioPortalLog: JSON.parse(localStorage.getItem('userDataCRP'))
      .usuarioPortal,
  });

  useEffect(() => {
    getProfiles();
  }, []);

  useEffect(() => {
    setPerfil(perfilAux);
  }, [perfilAux]);

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const onChange = (value, field) =>
    setPerfil((state) => ({ ...state, [field]: value }));

  function saveProfile() {
    if (perfil.id > 0) {
      handleUpdateProfile(perfil);
    } else {
      handleSaveProfile(perfil);
    }

    handleModal();
  }

  return (
    <>
      <Helmet
        key="TitleAdminPerfis"
        title="Perfis - Portal Prisma - Kroton"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'description',
            content: 'Perfis' + '- Portal Prisma',
          },
          {
            name: 'keywords',
            content: 'lista, usuarios, kroton, educacional, portal',
          },
        ]}
      />
      <HeaderAdmin key="HeaderPermissoes" />
      <section
        key="SectionAdmin"
        className="columns is-multiline conteudoAdmin"
        id="admin-app"
      >
        <MenuAdmin key="MenuAdminPerfis" />
        <div className="column is-10 is-fullheight conteudoAdminPagina">
          <div className="columns">
            <div className="column">
              <Hero
                key="heroAdminListaPerfis"
                pageName="Perfis"
                titulo="Perfis de acesso"
              />
            </div>
          </div>
          <Button
            type="primary"
            size="default"
            style={{ zIndex: 99 }}
            className="pull-right margin-1rem-bottom"
            onClick={handleModal}
          >
            Novo Perfil
          </Button>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={dataSource}
            size="small"
            expandedRowRender={(record) => {
              const systems =
                dataSource &&
                dataSource.find((item) => item.perfil.id === record.perfil.id);
              if (systems) {
                const systemsWithoutDuplicates = systems.sistema.filter(
                  (value, index, self) =>
                    index === self.findIndex((t) => t.nome === value.nome)
                );
                return (
                  <>
                    {systemsWithoutDuplicates.map((system) => (
                      <Link
                        to={`/admin/atribuicao/${system.id}/${
                          system.descricao
                        }/${record.perfil.descricao.replaceAll('/', '-')}/${
                          record.perfil.id
                        }`}
                      >
                        <p key={system.id}>{system.nome}</p>
                      </Link>
                    ))}
                  </>
                );
              }
            }}
          />
        </div>
        <Modal
          title="Cadastro/Alteração de Perfil"
          visible={isActive}
          onOk={saveProfile}
          onCancel={handleModal}
          destroyOnClose={true}
          width={600}
          centered
        >
          <Row xs={24}>
            <Col xs={8}>
              <Form.Item label="Perfil">
                <Input
                  placeholder="Digite o perfil"
                  defaultValue={perfil.descricao}
                  onChange={(e) => onChange(e.target.value, 'descricao')}
                />
              </Form.Item>
            </Col>
            <Col xs={7} className="margin-1-left">
              <Form.Item label="Habilita polos?">
                <Select
                  placeholder="Habilita polos"
                  defaultValue={perfil.habilitaPolo}
                  onChange={(e) => onChange(e, 'habilitaPolo')}
                >
                  <Option value="S">Sim</Option>
                  <Option value="N">Não</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={7} className="margin-1-left">
              <Form.Item label="Status">
                <Select
                  placeholder="Status"
                  defaultValue={perfil.status}
                  onChange={(e) => onChange(e, 'status')}
                >
                  <Option value="S">Ativo</Option>
                  <Option value="N">Inativo</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </section>
      <FooterAdmin key="FooterAdminPerfisLista" />
    </>
  );
};
export default AccessProfiles;
