import React, { Component } from 'reactn';
import { Redirect } from 'react-router-dom';
import {
  Error,
  axios,
  $,
  FontAwesomeIcon,
  ComunicadoDisplay,
} from '../../services/Gerais';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga';
import { Popover, Button, Row, Col, Icon, Input } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class ComunicadosCarousel extends Component {
  constructor() {
    super();
    this.state = {
      error: [],
      isActivedComunicHome: false,
      showComunicados: [],
      comunicados: [],
    };
    this.logInfoCarouselHome = JSON.parse(localStorage.getItem('userDataCRP'));
    this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this);
    this.poloId = JSON.parse(localStorage.getItem('userDataCRP-Polo'));
  }

  copyCodeToClipboard = comunicado => {
    let textField = document.createElement('textarea');
    textField.innerText = `${window.location.origin}/comunicados/${comunicado.id
      }`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    Swal.fire({
      title: `Copiado!`,
      text: `URL do comunicado foi copiada.`,
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    })
  };

  toggleNaved = () => {
    this.setState(prevState => ({
      isActivedComunicHome: !prevState.isActivedComunicHome,
    }));
    this.render();
  };

  componentDidMount() {
    if (!localStorage.getItem('userDataCRP')) {
      return <Redirect to={'/login'} />;
    }
  }

  geraComunicado = (e, comunicado, btn) => {
    e.preventDefault();

    let lido = comunicado.lido;
    let favorito = comunicado.favorito;

    if (btn === 'like') {
      if (favorito !== 'S') {
        favorito = 'S';
        lido = 'S';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'fas');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z'
          );
      } else {
        favorito = 'N';
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg')
          .data('prefix', 'far');
        $(document)
          .find('[data-comunicado-favorito="' + comunicado.id + '"] svg path')
          .attr(
            'd',
            'M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z'
          );
      }
    } else {
      lido = 'S';
      $(document)
        .find('[data-comunicado-lido="' + comunicado.id + '"]')
        .addClass('lido');
      this.toggleNaved();
    }

    // marcando como lido
    const dataComunicadoUsuario = [
      {
        usuarioPortal: { id: this.logInfoCarouselHome['usuarioPortal']['id'] },
        comunicado: { id: comunicado.id },
        favorito: favorito,
        comunicadoLido: lido,
        usuarioPortalLog: {
          id: this.logInfoCarouselHome['usuarioPortal']['id'],
        },
      },
    ];

    axios
      .post(
        this.global.baseUrl + 'comunicadoUsuario/saveComunicadoUsuario',
        dataComunicadoUsuario[0],
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
          },
        }
      )
      .then(result => {
        ReactGA.modalview('/comunicado/' + comunicado.codigo);
        axios
          .get(
            this.global.baseUrl +
            'comunicadoUsuario/getComunicadoByComunicadoAndUsuario/' +
            this.logInfoCarouselHome['usuarioPortal']['id'] +
            '/' +
            comunicado.id,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
              },
            }
          )
          .then(infosRes =>
            this.setState({
              showComunicados: infosRes.data,
              isLoading: false,
            })
          )
          .catch(error =>
            this.setState({
              error,
              isLoading: false,
            })
          );
      })
      .catch(error => {
        // console.error(error)
      });

    this.componentDidMount();
  };

  render() {
    if(this.props.comunicados && this.props.comunicados.Comunicados && this.state.comunicados.length == 0) {
      this.setState({comunicados: this.props.comunicados['Comunicados']})
    }
    console.log('isActivedComunicHome', this.state.isActivedComunicHome)

    const { error, comunicados, showComunicados } = this.state;
    var settings = {
      infinite: true,
      slidesToShow: 5,
      arrows: true,
      dots: false,
      slickPause: true,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1360,
          settings: {
            slidesToShow: 5,
            infinite: true,
            dots: false,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            infinite: true,
            dots: false,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 900,
          settings: { slidesToShow: 2, initialSlide: 2, slidesToScroll: 2 },
        },
        {
          breakpoint: 599,
          settings: { slidesToScroll: 1, slidesToShow: 1 },
        },
      ],
    };

    return (
      <>
        <div
          className="container-header is-fullhd comunicadosDestaque"
          key="comunicadosDestaque"
          data-tut="carousel_comunicados"
        >
          <div className="columns" style={{ padding: 10 }} >
            <div className="column">
              <h4 className="title">Comunicados</h4>
            </div>
          </div>
          {error.response ? <Error {...error} /> : null}
          {comunicados['Comunicados'] ? (
            <Slider {...settings}>
              {comunicados['Comunicados'].map(comun => {
                let comunicado = comun['comunicado'];
                let listaTags;
                let inicioTags = 0;
                let maxTags = 3;
                // eslint-disable-next-line
                let favorito = 'far';
                let boxAlerta = '';
                let boxAlertaSpan = '';
                let lido = 'check';
                // let comunicadoUser = 0;
                let errata = '';

                // if(comunicadoUser > 0) {
                //     if(comunicado.comunicadoUsuarios[0].favorito === "S"){ favorito = 'fas' }
                //     if(comunicado.lido === "S"){ lido = 'check lido'; } else { lido = 'check'; }
                // }

                if (comunicado.lido === 'S') {
                  lido = 'check lido';
                } else {
                  lido = 'check';
                }

                switch (comunicado.prioridade) {
                  case 'C':
                    boxAlertaSpan =
                      <span className="alerta alerta-red">
                        <FontAwesomeIcon icon={['fas', 'thermometer-full']} className="icon-thermometer" color="#ffffff" />
                      </span>;
                    boxAlerta = 'boxAlerta-red boxAlertaSemCarousel';
                    break;
                  case 'A':
                    boxAlertaSpan =
                      <span className="alerta alerta-orange">
                        <FontAwesomeIcon icon={['fas', 'thermometer-three-quarters']} className="icon-thermometer" color="#ffffff" />
                      </span>;
                    boxAlerta = 'boxAlerta-orange boxAlertaSemCarousel';
                    break;
                  case 'I':
                    boxAlertaSpan =
                      <span className="alerta alerta-blue">
                        <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff" />
                      </span>;
                    boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                    break;
                  case 'R':
                    boxAlertaSpan =
                      <span className="alerta alerta-green">
                        <FontAwesomeIcon icon={['fas', 'thermometer-quarter']} className="icon-thermometer" color="#ffffff" />
                      </span>;
                    boxAlerta = 'boxAlerta-green boxAlertaSemCarousel';
                    break;
                  default:
                    boxAlertaSpan =
                      <span className="alerta alerta-blue">
                        <FontAwesomeIcon icon={['fas', 'thermometer-half']} className="icon-thermometer" color="#ffffff" />
                      </span>;
                    boxAlerta = 'boxAlerta-blue boxAlertaSemCarousel';
                    break;
                }

                if (comunicado.errata === 'S') {
                  errata = 'ATUALIZAÇÃO - ';
                }

                if (comun['lstComunicadoTag']) {
                  listaTags = comun['lstComunicadoTag'].map(function (tag) {
                    ++inicioTags;
                    if (inicioTags <= maxTags) {
                      if (inicioTags === maxTags) {
                        return (
                          <a
                            href={'./comunicados-tags/' + tag.id}
                            key={'tag' + tag.id}
                            title={
                              'mais ' +
                              (comun['lstComunicadoTag'].length -
                                inicioTags +
                                1) +
                              ' tags'
                            }
                            className="button is-small is-rounded is-link tagsComunicadoMais"
                          >
                            +{comun['lstComunicadoTag'].length - inicioTags + 1}
                          </a>
                        );
                      } else {
                        return (
                          <a
                            href={'./comunicados-tags/' + tag['tag']['id']}
                            key={'tag' + tag['tag']['id']}
                            title={tag['tag']['descricao']}
                            className="button is-small is-rounded is-outlined tagsComunicado"
                          >
                            {tag['tag']['descricao']}
                          </a>
                        );
                      }
                    } else {
                      return '';
                    }
                  });
                }

                return (
                  //aqui comunicado
                  <div
                    className="column item"
                    data-index={comunicado.codigo}
                    key={comunicado.codigo}
                  >
                    <span
                      className="salvarComunicadoRelativo"
                      data-comunicado-favorito={comunicado.id}
                      onClick={e => this.geraComunicado(e, comunicado, 'like')}
                      title={
                        comunicado.favorito === 'S'
                          ? 'Desmarcar favorito'
                          : 'Marcar como favorito'
                      }
                    >
                      <FontAwesomeIcon
                        icon={[
                          comunicado.favorito === 'S' ? 'fas' : 'far',
                          'bookmark',
                        ]}
                      />
                    </span>
                    <div className={'box bgComunicados ' + boxAlerta}>
                      <div
                        onClick={e => this.geraComunicado(e, comunicado, 'read')}
                      >
                        {boxAlertaSpan}
                        <article className="media">
                          <div className="media-content">
                            <div className="content">
                              <b className="title is-6 is-spaced comunicadosInfos">
                                <label
                                  className={lido}
                                  title={
                                    lido === 'check'
                                      ? 'Comunicado não lido'
                                      : 'Comunicado lido'
                                  }
                                  data-comunicado-lido={comunicado.id}
                                >
                                  <FontAwesomeIcon icon={['fas', 'check']} />
                                  <FontAwesomeIcon icon={['fas', 'check']} />
                                </label>
                                <small>{comunicado.codigo}</small>
                              </b>
                              <hr className="hrNoBorder" />
                              <h5
                                className="title is-6 is-spaced"
                                data-comunicado={comunicado.id}
                              >
                                <span title={comunicado.titulo}>
                                  {errata}
                                  {comunicado.titulo &&
                                    comunicado.titulo.length > 60
                                    ? comunicado.titulo.slice(0, 60).concat('...')
                                    : comunicado.titulo}
                                </span>
                              </h5>
                              <small className="smallComunicado"
                                dangerouslySetInnerHTML={{ __html: comunicado.resumoNovo}}
                              >
                              </small>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="content is-small referenciaComunicados">
                        {listaTags}
                        <hr />
                        {/* <Popover
                    content={
                      <>
                        <Row>
                          <Col span={24}>
                            Links:
                            <Input
                              defaultValue={`${
                                window.location.origin
                              }/comunicados/${comunicado.id}`}
                              block="true"
                              readOnly
                            />
                          </Col>
                          <Col span={24}>
                            <Button
                              onClick={() =>
                                this.copyCodeToClipboard(comunicado)
                              }
                              type="primary"
                              block
                            >
                              <Icon type="copy" />
                              Copiar
                            </Button>
                          </Col>
                        </Row>
                      </>
                    }
                    title="Copiar Link de compartilhamento"
                    trigger="click"
                  > */}
                        <Button
                          key={`edit_${comunicado.id}`}
                          title={'Compartilhar esse comunicado'}
                          size="small"
                          type="primary"
                          onClick={() =>
                            this.copyCodeToClipboard(comunicado)
                          }
                        >
                          <FontAwesomeIcon icon={['fas', 'share-alt']} />
                          <span style={{ paddingLeft: 5 }}>Compartilhar</span>
                        </Button>
                        {/* </Popover> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : null}

          <div
            className={
              this.state.isActivedComunicHome
                ? 'conteudoLink is-active'
                : 'conteudoLink'
            }
          >
            <div className="is-fullhd">
              <div className="columns is-gapless">
                <div
                  className="is-transparent"
                  style={{ width: '15%',}}
                  id="columnClose"
                  onClick={this.toggleNaved}
                />
                <ComunicadoDisplay
                  comunicado={showComunicados}

                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
