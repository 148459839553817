import React, { useState, useEffect } from 'react';
import ReportContent from './ReportContent';
import { Spin } from 'antd';
import api from '../../../services/api';

const Main = ({ props }) => {
  const [carregando, setCarregando] = useState(false);
  const [categoria, setCategoria] = useState('');
  const [titulo, setTitulo] = useState('');
  const [report, setReport] = useState({ visible: false });

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      getReport(props.match.params.id);
    }

    // getAccessTokenReport(
    //   'cbb0ad0f-b192-4728-9520-a5a45f1a1321',
    //   '64d33d2d-0d91-44a7-9322-c59534fecd21'
    // );
    // eslint-disable-next-line
  }, []);

  async function getReport(idReport) {
    try {
      setCarregando(true);
      const resRelatorio = await api.get(`relatorio/${idReport}`);
      const res = await api.get(`relatorio/pwrBi/${idReport}`);

      if (resRelatorio.data) {
        setTitulo(resRelatorio.data.nomeRelatorio);
        setCategoria(resRelatorio.data.categoriaRelatorio.categoria);
      }

      setReport({
        visible: true,
        embedUrl: res.data.embedUrl,
        embedToken: res.data.embedToken,
        reportId: res.data.reportId,
        // filtroRelatorio,
      });

      setCarregando(false);
    } catch (error) {
      setCarregando(false);
    }
  }

  if (carregando) {
    return (
      <div style={{ padding: 15 }}>
        <Spin />
      </div>
    );
  }

  if (report && report.visible) {
    return (
      <div>
        <ReportContent report={report} />
      </div>
    );
  }

  return (
    <div style={{ padding: 15 }}>
      <h1 style={{ fontSize: 17 }}>Ops... Relatório não encontrado.</h1>
    </div>
  );

  return (
    <>
      {!carregando && report && report.visible ? (
        <div style={{ padding: 15 }}>
          <h1 style={{ fontSize: 17 }}>
            {titulo} | {categoria}
          </h1>
          <ReportContent report={report} />
        </div>
      ) : (
        <div style={{ padding: 15 }}>
          <Spin />
        </div>
      )}
    </>
  );
};

export default Main;
