import React from 'react';

import { Card, Row, Col, Collapse } from 'antd';
import ImagemSemFoto from 'img/semfoto.jpg';
import Office from 'img/Logo-Office-365.png'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/components/Portais.scss';
import { logRelatorio } from '../../config/logs';

const { Meta } = Card;
const { Panel } = Collapse;

function Content({ sistema, widthCard, image, isLink, isPortalOff }) {
  if(isLink) {
    return (
      <a
        data-index={sistema.id}
        target={'_blank'}
        href={isLink ? sistema.link : null}
        key={sistema.id}
        className={isPortalOff ? 'portalOff' : ''}
        style={{ cursor: isLink ? 'pointer' : 'auto' }}
      >
        <Col span={4}>
          <Card
            hoverable={isLink}

            style={{
              width: widthCard,
              height: 230,
              textAlign: 'center',
            }}
            className='card-carrossel'
            cover={<img alt="example" src={image} />}
          >
            <Meta title={sistema.nome} description={sistema.descricao} />
          </Card>
        </Col>
      </a>
    );
  } else {
    return (
      <a
        data-index={sistema.id}
        key={sistema.id}
        className={isPortalOff ? 'portalOff' : ''}
        style={{ cursor: isLink ? 'pointer' : 'auto' }}
        onClick={() => logRelatorio(sistema.id)}
      >
        <Col span={4}>
          <Card
          className='card-carrossel'
            hoverable={true}
            style={{
              width: widthCard,
              height: 130,
              textAlign: 'center',
            }}
          >
            <Meta title={sistema.nomeRelatorio} description={sistema.nomeRelatorio} />
          </Card>
        </Col>
      </a>
    );
  }
}
function Slide({ sistemas, titulo, isPortalOff, widthCard, isLink, isColapse }) {
  let settings = {
    infinite: false,
    slidesToShow: 6,
    arrows: true,
    dots: false,
    slickPause: true,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 4,
          infinite: sistemas && sistemas.length > 4,
          dots: false,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: sistemas && sistemas.length > 3,
          dots: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 2, initialSlide: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 599,
        settings: { slidesToScroll: 1, slidesToShow: 1 },
      },
    ],
  };

  return (
    <>
    {isColapse ?
      (<>
        {sistemas && sistemas.length > 0 && (
          <Row id="slidePortais" span={24} style={{ paddingBottom: 15 }}>
            <Collapse bordered={false} ghost>
              <Panel showArrow={false} header={titulo} key="1">
                <Slider {...settings}>
                  {sistemas &&
                    sistemas.map(sistema => {
                      let image = sistema.imagem;
                      if (!image) {
                        image = ImagemSemFoto;
                      }
                      if(sistema.chave === 'OFFICE'){
                        image = Office;
                      }
                      return (
                        <Content
                          sistema={sistema}
                          widthCard={widthCard}
                          image={image}
                          isLink={isLink}
                          isPortalOff={isPortalOff}

                        />
                      );
                    })}
                </Slider>
              </Panel>
            </Collapse>
          </Row>
        )}
      </>)
      :
      (<>
        <Row span={24} style={{ paddingBottom: 15 }}>
          <b style={{ fontSize: 17, color: '#228d89', fontSize: '1.3em' }}>{titulo}</b>
        </Row>
        {sistemas && sistemas.length > 0 && (
          <Row id="slidePortais" span={24} style={{ paddingBottom: 15 }}>
            <Slider {...settings}>
              {sistemas &&
                sistemas.map(sistema => {
                  let image = sistema.imagem;
                  if (!image) {
                    image = ImagemSemFoto;
                  }
                  if(sistema.chave === 'OFFICE'){
                    image = Office;
                  }
                  return (
                    <Content
                      sistema={sistema}
                      widthCard={widthCard}
                      image={image}
                      isLink={isLink}
                      isPortalOff={isPortalOff}
                    />
                  );
                })}
            </Slider>
          </Row>
        )}
      </>)}
    </>
  );
}

export default Slide;
