import React from 'react'

var d = new Date();
var n = d.getFullYear();

const FooterAdmin = () => (
    <footer className="footerAdmin">
        <div className="container">
            <div className="columns">
                <div className="column has-text-right">
                    <small className={"has-text-white"}>&copy; {n} - Portal Prisma</small>
                </div>
            </div>
        </div>
    </footer>
)

export default FooterAdmin