import React, { useState, useEffect } from 'react';
import { Table, Form, Row, Col, Input, Select } from 'antd';

import { useForm, Controller } from 'react-hook-form';

import { tableColumns } from './FiltersTableProps';
import { FiltersController } from './FiltersTableController';
import { Container, ButtonSubmit } from './styled';

const { Option } = Select;

const FiltersTable = ({ id }) => {
  const { handleSubmit, control } = useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const props = {
    state: {
      data: { data, setData },
      loading: {loading, setLoading}
    },
  };

  const filtersTableController = new FiltersController(props);

  useEffect(() => {
    if (id && id.length > 0) {
      filtersTableController.hadleInitData(id);
    }
  }, [id]);

  return (
    <Container key="HeaderSearchPortais">
      <Form
        onSubmit={handleSubmit((e) => filtersTableController.handleSubmit(e))}
        style={{ marginTop: 20 }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={4}>
            <Form.Item label="Nome da Coluna:" size="large">
              <Controller
                name="nomeExibicao"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    disabled={loading}
                    data-testid="teste-titulo"
                    placeholder="Nome da Coluna"
                    size="large"
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <Form.Item label="Condição Where:" size="large">
              <Controller
                name="condicaoWhere"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    disabled={loading}
                    data-testid="teste-titulo"
                    placeholder="Condição Where"
                    size="large"
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Tipo:" size="large">
              <Controller
                name="tipo"
                control={control}
                render={({ field }) => (
                  <Select {...field} size="large" placeholder="Tipo" disabled={loading}>
                    <Option value="DATE">DATE</Option>
                    <Option value="SELECT">SELECT</Option>
                    <Option value="BOOLEAN">BOOLEAN</Option>
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={3}>
            <Form.Item label="Posição:" size="large">
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <Input {...field} data-testid="teste-titulo" placeholder="Posição" size="large" />
                )}
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={4}>
            <Form.Item label="Ativo" size="large">
              <Controller
                name="ativo"
                control={control}
                render={({ field }) => (
                  <Select size="large" {...field} placeholder="Ativo" disabled={loading}>
                    <Option value="ATIVO">Ativo</Option>
                    <Option value="INATIVO">Inativo</Option>
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} className="submitContainer">
            <ButtonSubmit type="primary" htmlType="submit" loading={loading}>
              Pesquisar
            </ButtonSubmit>
          </Col>
        </Row>
      </Form>
      <Table dataSource={data} columns={tableColumns} size="small" loading={loading} />
    </Container>
  );
};

export default FiltersTable;
