import React, { Component } from 'reactn';
import { withRouter } from 'react-router-dom';
import { axios, moment, FontAwesomeIcon } from '../../../../services/Gerais';
import { Table, Tabs, Icon, Tooltip } from 'antd';

import 'antd/dist/antd.css';

const { TabPane } = Tabs;

const pagination = { position: 'both' };
class Lista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: this.setColumns(),
      listMapaOperacoes: [],
      listMicroByAtividade: [],
      listAtividadesByMacro: [],
      listEventos: [],
      arrDataTable: [],
      anoVigente: this.props.anoVigente,
      searchText: '',
      isLoading: true,

      loading: false,
      pagination,
      size: 'small',
      tipo: this.props.tipo,
      id: this.props.id,
      baseUr: this.global.baseUrl,
    };

    this.getListMapaOperacoes = this.getListMapaOperacoes.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true, loading: true });
    this.setTypeActionRequest();
  }

  setTypeActionRequest = () => {
    const { tipo, id } = this.props;
    switch (tipo) {
      case 'macro-atividade' || 'evento':
        return this.getListMapaOperacoes();
      case 'atividade':
        return this.getAtividadesBYMacro(id);
      case 'micro-atividade':
        return this.getMicroByAtividade(id);
      default:
        return this.getListMapaOperacoes();
    }
  };

  getListMapaOperacoes() {
    var urlListMapaOperacoes =
      this.global.baseUrl + 'mapaOperacoes/consultarMapaOperacoes';
    axios
      .get(urlListMapaOperacoes, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then(respMap => {
        this.setState({
          listMapaOperacoes: respMap.data.MacroAtividade,
          listEventos: respMap.data.Evento,
          isLoading: false,
          loading: false,
        });
      })
      .catch(errorDisplay =>
        this.setState({
          errorDisplay,
          isLoading: false,
          loading: false,
        })
      );
  }

  getAtividadesBYMacro = IdMacro => {
    var url = this.global.baseUrl + `atividade/findByMacroAtividade/${IdMacro}`;
    axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then(respMap => {
        this.setState({
          listAtividadesByMacro: respMap.data,
          isLoading: false,
          loading: false,
        });
      })
      .catch(errorDisplay =>
        this.setState({
          errorDisplay,
          isLoading: false,
          loading: false,
        })
      );
  };

  getMicroByAtividade = IdAtividade => {
    var url =
      this.global.baseUrl + `microAtividade/findByAtividade/${IdAtividade}`;
    axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userDataCRP-AuthTk'),
        },
      })
      .then(respMap => {
        this.setState({
          listMicroByAtividade: respMap.data,
          isLoading: false,
          loading: false,
        });
      })
      .catch(errorDisplay =>
        this.setState({
          errorDisplay,
          isLoading: false,
          loading: false,
        })
      );
  };

  setEvent = (mapEvent, type = null, children = [], parent = {}) => {
    const objResponse = {
      key: `${mapEvent.id}-${type}`,
      id: mapEvent.id,
      titulo: mapEvent.titulo,
      type: type,
      ciclo: mapEvent.ciclo,
      datIni: moment(mapEvent.dataInicio, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      datFim: moment(mapEvent.dataFim, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    };

    (type === 'macroAtividade' || type === 'eventoVG') &&
      Object.assign(objResponse, {
        categoria: mapEvent.categoria.descricao,
        anoVigente: mapEvent.anoVigente,
      });

    type === 'listaAtividades' &&
      Object.assign(objResponse, {
        macroID: parent.macroID,
      });

    type === 'microAtividades' &&
      Object.assign(objResponse, {
        macroID: parent.macroID,
        atividadeID: parent.atividadeID,
      });

    children.length > 0 &&
      Object.assign(objResponse, {
        children: children,
      });

    return objResponse;
  };

  getDataEventosVG = () => {
    const { listEventos } = this.state;
    const arrEventosVG = [];
    if (listEventos.length) {
      listEventos.map(eventosVG => {
        arrEventosVG.push(this.setEvent(eventosVG, 'eventoVG'));
        return eventosVG;
      });
    }
    return arrEventosVG;
  };

  getDataMicroAtividade = () => {
    const { listMicroByAtividade } = this.state;
    const arrMicro = [];
    if (listMicroByAtividade.length) {
      listMicroByAtividade.map(micro => {
        arrMicro.push(this.setEvent(micro));

        return micro;
      });
    }
    return arrMicro;
  };

  getDataMacroAtividade = () => {
    const { listMapaOperacoes } = this.state;
    const arrMacroAtividade = [];

    if (listMapaOperacoes.length > 0) {
      listMapaOperacoes.map(macroAtividade => {
        let mapListAtividade = macroAtividade.lstAtividade;
        const macroID = macroAtividade.id;

        const arrAtividade = [];

        if (mapListAtividade.length > 0) {
          mapListAtividade.map(listAtividade => {
            const arrMicroAtividade = [];
            let mapMicroAtividade = listAtividade.lstMicroAtividade;
            const atividadeID = listAtividade.id;

            if (mapMicroAtividade.length > 0) {
              mapMicroAtividade.map(microAtividade => {
                arrMicroAtividade.push(
                  this.setEvent(microAtividade, 'microAtividades', [], {
                    macroID: macroID,
                    atividadeID: atividadeID,
                  })
                );
                return microAtividade;
              });
            }

            arrAtividade.push(
              this.setEvent(
                listAtividade,
                'listaAtividades',
                arrMicroAtividade,
                {
                  macroID: macroID,
                }
              )
            );

            return listAtividade;
          });
        }
        arrMacroAtividade.push(
          this.setEvent(macroAtividade, 'macroAtividade', arrAtividade)
        );

        return macroAtividade;
      });
    }
    return arrMacroAtividade;
  };

  getDataAtividade = () => {
    const { listAtividadesByMacro } = this.state;
    const arrAtividade = [];

    if (listAtividadesByMacro.length > 0) {
      listAtividadesByMacro.map(listAtividade => {
        const arrMicroAtividade = [];
        let mapMicroAtividade = listAtividade.lstMicroAtividade;
        const atividadeID = listAtividade.id;

        if (mapMicroAtividade.length > 0) {
          mapMicroAtividade.map(microAtividade => {
            arrMicroAtividade.push(
              this.setEvent(microAtividade, 'microAtividades', [], {
                macroID: this.id,
                atividadeID: atividadeID,
              })
            );
            return microAtividade;
          });
        }

        arrAtividade.push(
          this.setEvent(listAtividade, 'listaAtividades', arrMicroAtividade, {
            macroID: this.id,
          })
        );
        return listAtividade;
      });
    }
    return arrAtividade;
  };

  rowSelection = () => {
    return {
      onChange: (selectedRowKeys, selectedRows) => {},
      onSelect: (record, selected, selectedRows) => {},
      onSelectAll: (selected, selectedRows, changeRows) => {},
    };
  };

  setColumns = () => {
    return [
      { title: 'ID', dataIndex: 'id', key: 'key' },
      {
        title: 'Evento',
        dataIndex: 'titulo',
        key: 'titulo',
        render: titulo => {
          const string = titulo;
          let length = 50;
          return (
            <Tooltip title={titulo}>
              {titulo.length > length
                ? `${string.substring(0, length)} ...`
                : titulo}
            </Tooltip>
          );
        },
      },
      //{ title: "Categoria", dataIndex: "categoria", key: "categoria" },
      { title: 'Tipo Evento', dataIndex: 'type', key: 'type' },
      {
        title: 'Ciclo',
        dataIndex: 'ciclo',
        key: 'ciclo',
        render: ciclo => {
          return ciclo === 'I' ? (
            <Tooltip title="Inverno">
              <FontAwesomeIcon icon={['far', 'snowflake']} color="#008dff" />
            </Tooltip>
          ) : (
            <Tooltip title="Verão">
              <FontAwesomeIcon icon={['fas', 'sun']} color="#ffd43b" />
            </Tooltip>
          );
        },
      },
      { title: 'Data Inicio', dataIndex: 'datIni', key: 'datIni' },
      { title: 'Data Fim', dataIndex: 'datFim', key: 'datFim' },
      {
        title: 'Ações',
        dataIndex: 'type',
        key: 'x',
        render: (type, rsObj) => {
          return this.getButtonAction(type, rsObj);
        },
      },
    ];
  };

  getButtonAction = (type, rsObj) => {
    switch (rsObj.type) {
      case 'macroAtividade':
        return (
          <div className="buttons has-addons is-centered">
            <a
              className="button is-primary is-small"
              href={`/admin/mapaoperacoes/cadastro/atividade/${rsObj.id}`}
            >
              <FontAwesomeIcon icon={['fas', 'calendar-week']} />
              &nbsp; Nova Atividade
            </a>
            <a
              className="button is-warning is-small"
              href={`/admin/mapaoperacoes/editar/macro-atividade/${rsObj.id}`}
            >
              <Icon type="form" /> Editar
            </a>
          </div>
        );
      case 'listaAtividades':
        return (
          <div className="buttons has-addons is-centered">
            <a
              className="button is-info is-small"
              href={`/admin/mapaoperacoes/cadastro/micro-atividade/${
                rsObj.macroID
              }/${rsObj.id}/`}
            >
              <FontAwesomeIcon icon={['fas', 'calendar-day']} />
              &nbsp; Nova Micro Atividade
            </a>
            <a
              className="button is-warning is-small"
              href={`/admin/mapaoperacoes/editar/atividade/${rsObj.id}`}
            >
              <Icon type="form" /> Editar
            </a>
          </div>
        );
      case 'microAtividades':
        return (
          <a
            className="button is-warning is-small"
            href={`/admin/mapaoperacoes/editar/micro-atividade/${rsObj.id}`}
          >
            <Icon type="form" /> Editar
          </a>
        );
      case 'eventoVG':
        return (
          <a
            className="button is-warning is-small"
            href={`/admin/mapaoperacoes/editar/evento/${rsObj.id}`}
          >
            <Icon type="form" /> Editar
          </a>
        );
      default:
        return null;
    }
  };

  getData = () => {
    const { tipo } = this.props;

    switch (tipo) {
      case 'macro-atividade':
        return this.getDataMacroAtividade();
      case 'atividade':
        return this.getDataAtividade();
      case 'micro-atividade':
        return this.getDataMicroAtividade();
      case 'evento':
        return this.getDataEventosVG();
      default:
        return this.getDataMacroAtividade();
    }
  };

  handleMenuClick = e => {};

  handleChange = val => {};

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {});
  };

  onChange = value => {};

  onClick = value => {};

  onBlur = () => {};

  onFocus = () => {};

  onSearch = val => {};

  render() {
    const { columns } = this.state;
    return (
      <div>
        {this.props.id ? (
          <Table
            {...this.state}
            columns={columns}
            dataSource={this.getData()}
          />
        ) : (
          <Tabs
            defaultActiveKey="macroAtividade"
            //tabBarExtraContent={operations}
          >
            <TabPane
              tab={
                <span>
                  <FontAwesomeIcon icon={['far', 'flag']} /> Eventos VG
                </span>
              }
              key="eventosVG"
            >
              <Table
                {...this.state}
                columns={columns}
                dataSource={this.getDataEventosVG()}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <FontAwesomeIcon icon={['far', 'calendar-alt']} /> Macro
                  Atividades
                </span>
              }
              key="macroAtividade"
            >
              <Table
                {...this.state}
                columns={columns}
                dataSource={this.getData()}
              />
            </TabPane>
          </Tabs>
        )}
      </div>
    );
  }
}
export default withRouter(Lista);
