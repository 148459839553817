import {
  BANNER_CHANGE,
  BANNER_SUCCESS,
  BANNER_ERROR,
} from '../../../actions/Administrativo/Banner/BannerType';

const userData = JSON.parse(localStorage.getItem('userDataCRP'));

const initialState = {
  carregando: true,
  succes: false,
  error: null,
  botaoSalvarHabilitado: true,
  Banners: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: '',
    size: 0,
    number: 0,
    sort: {
      sorted: '',
      unsorted: '',
    },
    numberOfElements: 0,
    first: '',
  },
  banner: {
    titulo: '',
    descricao: '',
    pathImagem: '',
    linkBanner: '',
    novaAba: 'S',
    ativo: 'S',
    canal: '',
    prioritario: '',
    usuarioPortalLog: {
      id: userData && userData.usuarioPortalLog && userData.usuarioPortalLog.id,
    },
    bannerPerfis: [],
    bannerSetores: [],
  },
};

const banner = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_SUCCESS:
      return { ...state, ...action.payload };
    case BANNER_ERROR:
      return { ...state, ...action.payload };
    case BANNER_CHANGE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    default:
      return state;
  }
};

export default banner;
