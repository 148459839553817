import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  Input,
  Divider,
  Icon,
  Upload,
  Tooltip,
  Skeleton,
} from 'antd';
import Swal from 'sweetalert2';
import { buscaDadosIniciaisBanner } from '../service';
import {
  salvarBanner,
  alterarBanner,
  getBannerId,
  changeBanner,
} from 'store/actions/Administrativo/Banner/BannerActions';
import 'antd/dist/antd.css';
import 'styles/Banner.scss';
import { UploadForm } from '../../../../components/uploadForm/UploadForm';

const ButtonGroup = Button.Group;
const { TextArea } = Input;
const { Option } = Select;

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};
const MsgSalvoComSucesso = ({ history, changeBanner }) => {
  const dispatch = useDispatch();
  if (changeBanner) {
    dispatch(
      changeBanner({
        prop: 'succes',
        value: false,
      })
    );
  }

  Swal.fire({
    position: 'top-end',
    title: `Salvo com sucesso! Redirecionando...`,
    icon: 'success',
    showConfirmButton: false,
    timer: 1500,
  }).then(result => {
    window.location.replace('/admin/banners')
  })

  return null;
};

const MgErroAoSalvar = ({ error, changeBanner }) => {
  const dispatch = useDispatch();
  if (changeBanner) {
    dispatch(
      changeBanner({
        prop: 'error',
        value: null,
      })
    );
  }

  if (error) {
    Swal.fire({
      title: 'Erro',
      text: error,
      icon: 'error',
      confirmButtonText: 'Ok'
    })
  }
  return null;
};
const BannerForm = ({ form, isEdit, history, idBanner }) => {
  const { getFieldDecorator, getFieldsError } = form;
  let {
    banner,
    botaoSalvarHabilitado,
    error,
    succes,
    carregando,
  } = useSelector(state => state.Banner);
  const dispatch = useDispatch();

  const [loadingFile, setLoadingFile] = useState(false);
  const [lstSetor, setLstSetor] = useState([]);
  const [lstPerfil, setLstPerfil] = useState([]);
  const [pathImagem, setPathImagem] = useState('');

  const buscaDados = async () => {
    try {
      const res = await buscaDadosIniciaisBanner();
      dispatch(
        changeBanner({
          prop: 'carregando',
          value: false,
        })
      );
      setLstSetor(res.lstSetor);
      setLstPerfil(res.lstPerfil);
    } catch (error) { }
  };

  useEffect(() => {
    dispatch(
      changeBanner({
        prop: 'carregando',
        value: true,
      })
    );
    buscaDados();
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (idBanner) {
        dispatch(
          changeBanner({
            prop: 'carregando',
            value: true,
          })
        );
        dispatch(getBannerId(idBanner));
      }
    },
    // eslint-disable-next-line
    [idBanner]
  );

  useEffect(
    () => {
      setPathImagem(banner.pathImagem);
    },
    [banner.pathImagem]
  );

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch(changeBanner({ prop: 'botaoSalvarHabilitado', value: false }));
        banner.pathImagem = pathImagem;
        if (isEdit) {
          dispatch(alterarBanner(banner, idBanner));
        } else {
          dispatch(salvarBanner(banner));
        }
      }
    });
  };

  if (carregando) {
    return <Skeleton active />;
  }

  return (
    <div>
      {succes && (
        <MsgSalvoComSucesso history={history} changeBanner={changeBanner} />
      )}
      {!succes && error && (
        <MgErroAoSalvar error={error} changeBanner={changeBanner} />
      )}

      <br />
      <div>
        <Form onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col span={12} className="gutter-row">
              <Form.Item label="Titulo Banner" size="large">
                {getFieldDecorator('titulo', {
                  initialValue: banner && banner.titulo,
                  setFieldsValue: banner && banner.titulo,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor insira um titulo',
                    },
                    {
                      max: 30,
                      message: 'Quantidade maxima de caracteres e 30',
                    },
                  ],
                })(
                  <Input
                    placeholder="Titulo banner"
                    data-testid="teste-titulo"
                    q
                    onChange={e =>
                      dispatch(
                        changeBanner({
                          prop: 'banner',
                          value: { ...banner, titulo: e.target.value.trim() },
                        })
                      )
                    }
                    size="large"
                    onBlur={e => e.target.value = e.target.value.trim()}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8} className="gutter-row">
              <Form.Item label="Link" size="large">
                {getFieldDecorator('linkBanner', {
                  initialValue: banner && banner.linkBanner,
                  rules: [
                    {
                      type: 'url',
                      message:
                        'O link do banner tem que seguir este padrão: https://seusite.com',
                    },
                  ],
                })(
                  <Input
                    placeholder="https://seusite.com"
                    onChange={e => {
                      dispatch(
                        changeBanner({
                          prop: 'banner',
                          value: { ...banner, linkBanner: e.target.value.trim() },
                        })
                      );
                    }}
                    size="large"
                    onBlur={e => e.target.value = e.target.value.trim()}
                  />
                )}
              </Form.Item>
            </Col>
            <Tooltip title={`Deseja abrir o link em uma nova aba?`}>
              <Col span={4} className="gutter-row">
                <Form.Item label="Nova aba?" size="large">
                  {getFieldDecorator('novaAba', {
                    initialValue: banner && banner.novaAba,
                    rules: [
                      {
                        required: banner && banner.linkBanner ? true : false,
                        message: 'Deseja abrir nova aba para o link?',
                      },
                    ],
                  })(
                    <Select
                      disabled={banner && banner.linkBanner ? false : true}
                      placeholder="Abrir nova aba?"
                      size="large"
                      onChange={e =>
                        dispatch(
                          changeBanner({
                            prop: 'banner',
                            value: { ...banner, novaAba: e },
                          })
                        )
                      }
                    >
                      <Option value="S">Sim</Option>
                      <Option value="N">Não</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Tooltip>
          </Row>
          <Row gutter={16}>
            <Col span={12} className="gutter-row">
              <Form.Item label="Descrição" size="large">
                {getFieldDecorator('descricao', {
                  initialValue: banner && banner.descricao,
                  rules: [
                    {
                      max: 130,
                      message: 'Quantidade maxima de caracteres e 130',
                    },
                  ],
                })(
                  <TextArea
                    size="large"
                    placeholder="Inclua aqui a descrição do banner"
                    rows={5}
                    onChange={e =>
                      dispatch(
                        changeBanner({
                          prop: 'banner',
                          value: { ...banner, descricao: e.target.value },
                        })
                      )
                    }
                    style={{ height: 128 }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="gutter-row">
              <Form.Item label="Imagem" size="large">
                {getFieldDecorator('imagem', {
                  initialValue: banner && banner.pathImagem,
                  rules: [
                    {
                      required: true,
                      message: 'A imagem e obrigatoria',
                    },
                  ],
                })(
                  <UploadForm
                    initialValue={banner.pathImagem}
                    onChange={e => {
                      if (e.fileList.length === 1) {
                        dispatch(
                          changeBanner({
                            prop: 'banner',
                            value: { ...banner, pathImagem: e.fileList[0]?.response?.link },
                          })
                        )
                      }
                    }} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={5} className="gutter-row">
              <Form.Item label="Canal" size="large">
                {getFieldDecorator('Canal', {
                  initialValue: banner.canal || [],
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecione um Canal',
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecione um Canal"
                    onChange={e =>
                      dispatch(
                        changeBanner({
                          prop: 'banner',
                          value: { ...banner, canal: e },
                        })
                      )
                    }
                    size="large"
                  >
                    <Option value="T">Todas</Option>
                    <Option value="S">Próprio</Option>
                    <Option value="N">Parceiro</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5} className="gutter-row">
              <Form.Item label="Perfil" size="large">
                {getFieldDecorator('lstBannerPerfilBanner', {
                  initialValue:
                    banner &&
                    banner.bannerPerfis &&
                    banner.bannerPerfis.map(i => i.perfil.id.toString()),
                  rules: [
                    {
                      required: true,
                      message: 'Por favor insira um ou mais perfis',
                    },
                  ],
                })(
                  <Select
                    size="large"
                    mode="multiple"
                    placeholder="Selecione os perfis"
                    onChange={e => {
                      if (e) {
                        let perfis = [];
                        e.map(perfilId =>
                          perfis.push({ perfil: { id: perfilId } })
                        );
                        dispatch(
                          changeBanner({
                            prop: 'banner',
                            value: { ...banner, bannerPerfis: perfis },
                          })
                        );
                      }
                    }}
                  >
                    {lstPerfil &&
                      lstPerfil.length > 0 &&
                      lstPerfil.map(key => {
                        return (
                          <Option
                            key={'perfil-' + [key.id]}
                            value={key.id.toString()}
                          >
                            {key.descricao}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5} className="gutter-row">
              <Form.Item label="Setor" size="large">
                {getFieldDecorator('lstBannerSetorBanner', {
                  initialValue:
                    banner &&
                    banner.bannerSetores &&
                    banner.bannerSetores.map(i => i.setor.id.toString()),
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecione um setor',
                    },
                  ],
                })(
                  <Select
                    size="large"
                    mode="multiple"
                    placeholder="Selecione os setores"
                    onChange={e => {
                      if (e) {
                        let setores = [];
                        e.map(setorId =>
                          setores.push({ setor: { id: setorId } })
                        );
                        dispatch(
                          changeBanner({
                            prop: 'banner',
                            value: { ...banner, bannerSetores: setores },
                          })
                        );
                      }
                    }}
                  >
                    {lstSetor &&
                      lstSetor.length > 0 &&
                      lstSetor.map(key => {
                        return (
                          <Option
                            key={'setor-' + [key.id]}
                            value={key.id.toString()}
                          >
                            {key.descricao}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Tooltip title="Esse banner cadastrado e prioritário visualizar em 1º lugar?">
              <Col span={5} className="gutter-row">
                <Form.Item label="Prioritário" size="large">
                  {getFieldDecorator('prioritario', {
                    initialValue: (banner && banner.prioritario) || [],
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecione se e prioritário',
                      },
                    ],
                  })(
                    <Select
                      placeholder="SIM / NÃO"
                      onChange={e =>
                        dispatch(
                          changeBanner({
                            prop: 'banner',
                            value: { ...banner, prioritario: e },
                          })
                        )
                      }
                      size="large"
                    >
                      <Option value="S">Sim</Option>
                      <Option value="N">Não</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Tooltip>
            <Col span={4} className="gutter-row">
              <Form.Item label="Ativo" size="large">
                {getFieldDecorator('ativo', {
                  initialValue: (banner && banner.ativo) || [],
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecione um Status',
                    },
                  ],
                })(
                  <Select
                    placeholder="Ativo / Inativo"
                    onChange={e =>
                      dispatch(
                        changeBanner({
                          prop: 'banner',
                          value: { ...banner, ativo: e },
                        })
                      )
                    }
                    size="large"
                  >
                    <Option value="S">Sim</Option>
                    <Option value="N">Não</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row type="flex" justify="end" gutter={16}>
            <Col span={6} justify="end" className="gutter-row">
              <Form.Item size="large">
                <ButtonGroup size="large">
                  <Button
                    onClick={() => {
                      history.push('/admin/banners');
                    }}
                  >
                    <Icon type="stop" />
                    Cancelar
                  </Button>
                  {!botaoSalvarHabilitado || loadingFile ? (
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        backgroundColor: '#52c41a',
                        border: 'none',
                      }}
                      loading
                    >
                      Carregando
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        backgroundColor: '#52c41a',
                        border: 'none',
                      }}
                      htmlType="submit"
                      disabled={hasErrors(getFieldsError())}
                    >
                      <Icon type="save" /> Salvar
                    </Button>
                  )}
                </ButtonGroup>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default BannerForm;
